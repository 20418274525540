// 공통모듈
import React, { useState, useEffect } from 'react';
import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import ButtonDropdown from '../../../fnComponents/ButtonDropdown';
import DataTable from '../../../fnComponents/_DataTable';
import SingleDatePicker from '../../../fnComponents/SingleDatePicker.1';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as actions from './store/action';
import * as selectors from './store/selector';
//
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import MarkDownEditor from './MarkDownEditor';

import Octicon, { Plus } from '@primer/octicons-react';

const VersionManaging = ({
  // selector props
  versionList,
  isMakeVersionDone,
  isUpdateVersionDone,
  isDeleteVersionDone,
  selectedVersion,
  onDeleteVersion,
  // func props
  onMakeVersion,
  onInitVersionList,
  onOpenVersionModal,
  onUpdateVersion,
}) => {
  // shouldInit : select box 초기화 변수
  const [shouldInit, setShouldInit] = useState(false);
  useEffect(() => {
    if (isMakeVersionDone) {
      handleCloseMakeVersionModal();
      setShouldInit(true);
    }
    if (isUpdateVersionDone) {
      handleCloseMakeVersionModal();
      setShouldInit(true);
    }
    if (isDeleteVersionDone) {
      handleCloseMakeVersionModal();
      setShouldInit(true);
    }
  }, [isMakeVersionDone, isDeleteVersionDone, isUpdateVersionDone]);

  const [isMsgModalOpen, setIsMsgModalOpen] = useState(false);
  const [whichIsOpen, setWhichIsOpen] = useState(null);

  const onChangeBtnDropdown = (obj) => {
    console.log(obj);
    const { status } = obj;
    onInitVersionList(
      status === '뒤끝펑션SDK'
        ? { versionType: 1 }
        : status === '뒤끝SDK'
        ? { versionType: 0 }
        : null
    );
  };

  const handleOpenMakeVersionModal = (e) => {
    e.preventDefault();
    setIsMsgModalOpen(true);
  };
  const handleCloseMakeVersionModal = () => {
    setIsMsgModalOpen(false);
    setWhichIsOpen(null);
    onOpenVersionModal(null);
  };

  useEffect(() => {
    if (selectedVersion) {
      console.log('selectedVersion', selectedVersion);
      const { versionType, version, isPublic, content, summary, deployDate } =
        selectedVersion;
      // title : 분류
      setContent({ content: content.S });
      setDeployDate(deployDate.S);
      setTitle(version.S);
      setSummary(summary ? { content: summary.S } : '');
      setIsPublic(isPublic ? isPublic.BOOL : false);
      setVersionType(versionType.N || versionType.S);
      setIsMsgModalOpen(true);
    } else {
      setContent('');
      setDeployDate(null);
      setTitle('');
      setSummary('');
      setIsPublic(false);
      setVersionType(0);
    }
  }, [selectedVersion]);

  const handleClickVersionRow = (obj) => {
    console.log(obj, 'in handleClickVersionRow');
    onOpenVersionModal(obj.version);
    setWhichIsOpen(obj.version);
  };

  const handleDeleteVersion = (obj) => {
    console.log(whichIsOpen, 'whichIsOpen in handleDeleteVersion');
    onDeleteVersion(whichIsOpen);
  };

  const [isPublic, setIsPublic] = useState(false);
  const [versionType, setVersionType] = useState(0);
  const handleRadioBtnChange = (e) => {
    console.log(e.target.id, 'in handleRadioBtnChange');
    console.log(e.target.name, 'in handleRadioBtnChange');

    switch (e.target.name) {
      case 'versionType':
        setVersionType(e.target.id);
        break;

      case 'isPublic':
        setIsPublic(e.target.id);
        break;

      default:
        break;
    }

    setIsPublic(!isPublic);
  };

  const [summary, setSummary] = useState('');
  const handleEditorSummaryChange = (content) => {
    console.log(content, 'in handleEditorSummaryChange');
    setSummary({ content });
  };
  const [content, setContent] = useState('');
  const handleEditorContentChange = (content) => {
    console.log(content, 'in handleEditorContentChange');
    setContent({ content });
  };

  const [deployDate, setDeployDate] = useState(null);
  const handleChangeDate = (obj) => {
    console.log(obj, 'in handleChangeDate');
    const { date } = obj;
    setDeployDate(date);
  };

  const [title, setTitle] = useState('');
  const handleChange = (e) => {
    console.log(e.target.value, 'in handleChange');
    setTitle(e.target.value);
  };

  const [errors, setErrors] = useState(null);
  const handleMakeVersion = () => {
    console.log(
      versionType,
      title,
      deployDate,
      summary,
      content,
      isPublic,
      'in handleMakeVersion'
    );

    let isErr = false;
    let _errors = {};
    // validation
    if (!title) {
      isErr = true;
      _errors = {
        ..._errors,
        title: '버전명을 입력해주세요.',
      };
    }
    if (!summary) {
      isErr = true;
      _errors = {
        ..._errors,
        summary: '요약을 입력해주세요.',
      };
    }
    if (!content) {
      isErr = true;
      _errors = {
        ..._errors,
        content: '내용을 입력해주세요.',
      };
    }
    if (isErr) {
      setErrors(_errors);
      return;
    }
    if (whichIsOpen) {
      const { inDate } = selectedVersion;

      onUpdateVersion(inDate.S, {
        title,
        deployDate,
        summary: summary.content,
        content: content.content,
        isPublic,
        versionType,
      });
    } else
      onMakeVersion({
        title,
        deployDate,
        summary: summary.content,
        content: content.content,
        isPublic,
        versionType,
      });
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <LeftAsideMunu />
        {/* 버전 등록 모달 */}
        <Modal isOpen={isMsgModalOpen} size="xl">
          <ModalHeader>
            {whichIsOpen ? '버전 수정/삭제' : '버전 등록'}
          </ModalHeader>
          <ModalBody>
            <div className="required form-group m-form__group row">
              <label htmlFor="creditAmount" className="col-lg-2 col-form-label">
                분류
              </label>
              <div className="col-lg-10">
                <div className="m-radio-inline">
                  <label className="m-radio">
                    <input
                      id={0}
                      onChange={handleRadioBtnChange}
                      type="radio"
                      name="versionType"
                      checked={versionType === 0 || versionType === '0'}
                    />
                    뒤끝SDK
                    <span />
                  </label>
                  <label className="m-radio">
                    <input
                      id={1}
                      onChange={handleRadioBtnChange}
                      type="radio"
                      name="versionType"
                      checked={versionType === 1 || versionType === '1'}
                    />
                    뒤끝펑션SDK
                    <span />
                  </label>
                </div>
              </div>
            </div>
            <div className="required form-group m-form__group row">
              <label htmlFor="creditAmount" className="col-lg-2 col-form-label">
                버전명 *
              </label>
              <div className="col-lg-9">
                <input
                  onChange={handleChange}
                  type="type"
                  className="form-control"
                  id="title"
                  required
                  value={title}
                />
                {errors && errors.title ? (
                  <div className="error message visible">{errors.title}</div>
                ) : null}
              </div>
            </div>
            <div className="required form-group m-form__group row">
              <label htmlFor="creditAmount" className="col-lg-2 col-form-label">
                배포일
              </label>
              <div className="col-lg-9">
                <SingleDatePicker
                  searchParam="date"
                  onGetSearchKeywords={handleChangeDate}
                  where="versionManaging"
                  value={selectedVersion ? selectedVersion.deployDate.S : null}
                />
              </div>
            </div>
            <div className="required form-group m-form__group row">
              <label htmlFor="creditAmount" className="col-lg-2 col-form-label">
                요약 *
              </label>
            </div>
            <MarkDownEditor
              handleChange={handleEditorSummaryChange}
              _content={summary}
            />
            <div className="error-wrapper">
              {errors && errors.summary ? (
                <div className="error message visible">{errors.summary}</div>
              ) : null}
            </div>
            <div className="required form-group m-form__group row">
              <label htmlFor="creditAmount" className="col-lg-2 col-form-label">
                내용 *
              </label>
            </div>
            <MarkDownEditor
              handleChange={handleEditorContentChange}
              _content={content}
            />
            <div className="error-wrapper">
              {errors && errors.content ? (
                <div className="error message visible">{errors.content}</div>
              ) : null}
            </div>
            <div className="required form-group m-form__group row">
              <label htmlFor="creditAmount" className="col-lg-2 col-form-label">
                노출
              </label>
              <div className="col-lg-10">
                <div className="m-radio-inline">
                  <label className="m-radio">
                    <input
                      id={true}
                      onChange={handleRadioBtnChange}
                      type="radio"
                      name="isPublic"
                      checked={isPublic}
                    />
                    공개
                    <span />
                  </label>
                  <label className="m-radio">
                    <input
                      id={false}
                      onChange={handleRadioBtnChange}
                      type="radio"
                      name="isPublic"
                      checked={!isPublic}
                    />
                    비공개
                    <span />
                  </label>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleMakeVersion}>
              확인
            </Button>
            <Button
              id="close"
              color="primary"
              onClick={() => handleCloseMakeVersionModal()}
            >
              취소
            </Button>
            {whichIsOpen ? (
              <Button
                color="danger"
                className="float-right"
                onClick={handleDeleteVersion}
              >
                삭제
              </Button>
            ) : null}
          </ModalFooter>
        </Modal>

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title m-subheader__title--separator">
                  {/* {whereTo !== 'deleteAccount' ? '일반 계정' : '탈퇴 계정'} */}
                  업데이트 노트
                </h3>
                <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
                  <li className="m-nav__item m-nav__item--home">
                    <a href="dummy" className="m-nav__link m-nav__link--icon">
                      <i className="m-nav__link-icon la la-home" />
                    </a>
                  </li>
                  <li className="m-nav__separator">-</li>
                  <li className="m-nav__item">
                    <a href="dummy" className="m-nav__link">
                      <span className="m-nav__link-text">계정 관리</span>
                    </a>
                  </li>
                  <li className="m-nav__separator">-</li>
                  <li className="m-nav__item">
                    <a href="dummy" className="m-nav__link">
                      <span className="m-nav__link-text">일반 계정</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* 탈퇴 회원 컴포넌트는 조건부 렌더링 - 탈퇴계정 서브메뉴 클릭 시 ACTION 호출해서 조건부 렌더링 */}

          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <form className="m-form m-form--fit m--margin-bottom-20">
                      <div className="row m--margin-bottom-20">
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>상태</label>
                          <ButtonDropdown
                            initItem="전체"
                            initSearchFilter={shouldInit}
                            dropdownItems={[
                              { name: '전체' },
                              { name: '뒤끝SDK' },
                              { name: '뒤끝펑션SDK' },
                            ]}
                            onChangeBtnDropdown={onChangeBtnDropdown}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <button
                            onClick={handleOpenMakeVersionModal}
                            className="btn btn-secondary m-btn m-btn--icon"
                            id="m_reset"
                          >
                            <span>
                              <Octicon icon={Plus} />
                              <span>버전 등록</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </form>
                    {console.log(versionList, '1120 versionList')}
                    <DataTable
                      //   isLast={isLast}
                      //   requestAction={getAccountList}
                      //   sortingParam={sortingParam}
                      customClassName={
                        versionList.length > 13
                          ? 'column-5 table-scrollable'
                          : 'column-5'
                      }
                      requiredAction={handleClickVersionRow}
                      rows={versionList}
                      actualData={versionList}
                      columns={[
                        {
                          label: '버전명',
                        },
                        {
                          label: '등록일시',
                        },
                        {
                          label: '배포일',
                        },
                        {
                          label: '분류',
                        },
                        {
                          label: '노출',
                        },
                      ]}
                      //   onSortAsc={onSortAscNormalAccounts}
                      //   onSortDesc={onSortDescNormalAccounts}
                      //   sortingColumns={[]}
                      clickableIndex={[0]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  // showcaseList: selectors.showcaseListSelectorForView,
  versionList: selectors.versionListSelector,
  isMakeVersionDone: selectors.isMakeVersionDoneSelector,
  isUpdateVersionDone: selectors.isUpdateVersionDoneSelector,
  selectedVersion: selectors.selectedVersionSelector,
  isDeleteVersionDone: selectors.isDeleteVersionDoneSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onInitVersionList: (sdkType) => dispatch(actions.initVersionList(sdkType)),
  onGetVersionList: (param) => dispatch(actions.getVersionList(param)),
  onMakeVersion: (obj) => dispatch(actions.makeVersion(obj)),
  onUpdateVersion: (inDate, obj) =>
    dispatch(actions.updateVersion(inDate, obj)),
  onDeleteVersion: (version) => dispatch(actions.deleteVersion(version)),
  onOpenVersionModal: (version) => dispatch(actions.openVersionModal(version)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VersionManaging);
