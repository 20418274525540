import moment from 'moment';
import { LOCATION_CHANGE } from 'react-router-redux';

// import * as selectors from './selector';
import * as types from './constant';

const initialState = {
	isLoading: false,
	salesList: [],
	salesListForView: [],
	sortingParam: false, // , true 오름차순 정렬 후, false 내림차순,
};

const makeSalesListForView = (data) =>
	data.map((res) => ({
		Date: typeof res.Date === 'string' ? res.Date : res.Date.format('YYYY-MM'),
		total: res.total.toLocaleString(),
		per: res.per ? res.per.toFixed(3) : '-',
		pass: res.pass.toLocaleString(),
		db: res.db.toLocaleString(),
		dau: res.dau.toLocaleString(),
		extra: res.extra.toLocaleString(),
	}));

function theBackendSales(state = initialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return {
				...initialState,
			};
		case types.GET_THEBACKEND_SALES_SUCCESS:
			return {
				...state,
				listForCSV: action.data.results.map((res) => ({
					['일자']: res.Date,
					['매출(원)']: res.total,
					['거래액 대비 비율(%)']: res.per,
					['패스 요금(원)']: res.pass,
					['DB 요금(원)']: res.db,
					['DAU 요금(원)']: res.dau,
					['기술지원료(원)']: res.extra,
				})),
				salesList: action.data.results.map((res) => ({
					...res,
					Date: moment(res.Date),
				})),
				salesListForView: makeSalesListForView(action.data.results),
				isLast: action.data.isLast,
			};

		case types.GET_NEXT_THEBACKEND_SALES_SUCCESS:
			return {
				...state,
				salesList: [
					...state.salesList,
					...action.data.results.map((res) => ({
						...res,
						Date: moment(res.Date),
					})),
				],
				salesListForView: [
					...state.salesListForView,
					...makeSalesListForView(action.data.results),
				],
			};

		case types.SORT_ASC_ROWS_THEBACKEND_SALES:
			return {
				...state,
				sortingParam: true,
				salesListForView: makeSalesListForView(action.data),
				// salesList,
			};
		case types.SORT_DESC_ROWS_THEBACKEND_SALES:
			return {
				...state,
				sortingParam: false,
				salesListForView: makeSalesListForView(action.data),
				// salesList,
			};

		default:
			return state;
	}
}

export default theBackendSales;
