// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import * as types from './constant';

// export function initGameStat(gameId, date, year) {
// 	return {
// 		type: types.INIT_GAME_STAT,
// 		gameId,
// 		date,
// 		year,
// 		MAU: false,
// 	};
// }

export function initCurrentChargeList() {
  return {
    type: types.INIT_CHARGED_BILL_LIST,
  };
}
export function clickChargedBillRow(id, arr) {
  return {
    type: types.CLICK_CHARGED_BILL_ROW,
    id,
    arr,
  };
}
export function getBackToMainTable() {
  return {
    type: types.GET_BACK_TO_MAIN_TABLE,
  };
}
export function handleOpenLayerdModal() {
  return {
    type: types.HANDLE_OPEN_LAYERD_MODAL,
  };
}
export function handleCloseLayerdModal() {
  return {
    type: types.HANDLE_CLOSE_LAYERD_MODAL,
  };
}
export function getBill(obj) {
  return {
    type: types.GET_BILL,
    obj,
  };
}
export function getBillError(obj) {
  return {
    type: types.GET_BILL_ERROR,
    obj,
  };
}
export function getBillSuccess(rows, isLast, isLastNum, totalAmount) {
  return {
    type: types.GET_BILL_SUCCESS,
    rows,
    isLast,
    isLastNum,
    totalAmount,
  };
}
export function getAccountBill(id) {
  return {
    type: types.GET_ACCOUNT_BILL,
    id,
  };
}
export function getAccountBillSuccess({ result, year, month }) {
  return {
    type: types.GET_ACCOUNT_BILL_SUCCESS,
    accountBill: result,
    year,
    month,
  };
}
export function getGameBill(id, billType, gameTitle, date) {
  return {
    type: types.GET_GAME_BILL,
    id,
    billType,
    gameTitle,
    date,
  };
}
export function getGamePPUBill(id, billType, gameTitle, selectedDate) {
  return {
    type: types.GET_GAME_PPU_BILL,
    id,
    billType,
    gameTitle,
    selectedDate,
  };
}
export function getGamePPUBillSuccess(id, billType, gameTitle, data) {
  return {
    type: types.GET_GAME_PPU_BILL_SUCCESS,
    id,
    billType,
    gameTitle,
    data,
  };
}
export function getGameBillSuccess(gameBill, gameTitle, id) {
  return {
    type: types.GET_GAME_BILL_SUCCESS,
    gameBill,
    gameTitle,
    id,
  };
}
export function getSearchKeywords(obj) {
  return {
    type: types.GET_SEARCH_KEYWORDS_BILL,
    obj,
  };
}

export function getAccountStatusSuccess(status) {
  return {
    type: types.GET_ACCOUNT_STATUS_SUCCESS,
    status,
  };
}

export function handleManageBillModal(id, value) {
  return {
    type: types.HANDLE_MANAGE_BILL_MODAL,
    id,
    value,
  };
}

export function updateAccountBill(obj) {
  return {
    type: types.UPDATE_ACCOUNT_BILL,
    obj,
  };
}

export function updateAccountBillSuccess() {
  return {
    type: types.UPDATE_ACCOUNT_BILL_SUCCESS,
  };
}

export function sortAscChargedBill(rows, isSearched, tableType) {
  return {
    type: types.SORT_ASC_CHARGED_BILL,
    rows,
    isSearched,
    tableType,
  };
}
export function sortDescChargedBill(rows, isSearched, tableType) {
  return {
    type: types.SORT_DESC_CHARGED_BILL,
    rows,
    isSearched,
    tableType,
  };
}

export function getSomeOfBill(obj) {
  return {
    type: types.GET_SOME_OF_BILL,
    obj,
  };
}

export function updateGameChargingPrice(billDate, chargingPrice) {
  return {
    type: types.UPDATE_GAME_CHARGING_PRICE,
    billDate,
    chargingPrice,
  };
}

export function updateGameChargingPriceSuccess() {
  return {
    type: types.UPDATE_GAME_CHARGING_PRICE_SUCCESS,
  };
}

// export function confirmDeleteAccount(id) {
//   return {
//     type: types.CONFIRM_DELETE_ACCOUNT,
//     id
//   };
// }

// export function saveInput(value) {
//   return {
//     type: types.SAVE_INPUT,
//     value
//   };
// }
