import { call, put, takeLatest, select } from 'redux-saga/effects';

import * as types from './constant';
import * as actions from './action';
import { LOCATION_CHANGE } from 'react-router-redux';
import { openSubMenu } from '../../../view/store/action';
import aws from 'aws-sdk';

import * as api from '../../../service/API';

function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split('/');
    if (pathArray[1] === 'popup') {
      console.log('LOCATION_CHANGEd');

      yield put(openSubMenu('popup'));
      yield put(actions.initPopupList());
    }
  } catch (err) {
    console.log('LOCATION_CHANGE', err);
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* initPopupList() {
  try {
    yield put(actions.getPopupList());
  } catch (error) {
    console.log('INIT_CONSOLE_POPUP_LIST', error);
  }
}

export function* watcherInitPopupList() {
  yield takeLatest(types.INIT_CONSOLE_POPUP_LIST, initPopupList);
}

function* getPopupList() {
  try {
    const {
      data: { popupList, totalCount },
    } = yield call(api.consolePopup.getPopupList);

    yield put(actions.getPopupListSuccess(popupList, totalCount));
  } catch (error) {
    console.log('GET_CONSOLE_POPUP_LIST', error);
  }
}

export function* watcherGetPopupList() {
  yield takeLatest(types.GET_CONSOLE_POPUP_LIST, getPopupList);
}

function* insertPopup({ obj }) {
  try {
    yield call(api.consolePopup.insertPopup, {
      ...obj,
      // startDate: obj.startDate.toISOString(),
      // endDate: obj.endDate.toISOString(),
    });
    yield put(actions.insertPopupSuccess());
    yield put(actions.initPopupList());
  } catch (error) {
    console.log('INSERT_POPUP', error);
    if (error && error.response.status === 412) {
      console.log('duration duplicated');
      yield put(
        actions.handlePopupErrors({
          duplicated: '선택한 기간과 중복되는 팝업이 존재합니다.',
        })
      );
    }
  }
}

export function* watcherInsertPopup() {
  yield takeLatest(types.INSERT_CONSOLE_POPUP, insertPopup);
}

function* updatePopup({ id, data }) {
  try {
    yield call(api.consolePopup.updatePopup, id, data);
    yield put(actions.updatePopupSuccess());
    window.location.reload(true);
    yield put(actions.initPopupList());
  } catch (error) {
    console.log('UPDATE_POPUP', error);
    if (error && error.response.status === 412) {
      console.log('duration duplicated');
      yield put(
        actions.handlePopupErrors({
          duplicated: '선택한 기간과 중복되는 팝업이 존재합니다.',
        })
      );
    }
  }
}

export function* watcherUpdatePopup() {
  yield takeLatest(types.UPDATE_CONSOLE_POPUP, updatePopup);
}

function* deletePopup({ id }) {
  try {
    yield call(api.consolePopup.deletePopup, id);
    yield put(actions.initPopupList());
  } catch (error) {
    console.log('DELETE_POPUP', error);
  }
}

export function* watcherDeletePopup() {
  yield takeLatest(types.DELETE_CONSOLE_POPUP, deletePopup);
}
