// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { createSelector } from 'reselect';
import moment from 'moment';

export const popupListSelectorForView = createSelector(
  (state) => state.popup.popupList,
  (popupList) => {
    // console.log(popupList);
    /**
      image: {,…}
      endDate: {S: "2020-04-19T06:16:33.379Z"}
      inDate: {S: "2020-04-07T06:18:28.950Z"}
      startDate: {S: "2020-04-05T06:16:33.379Z"}
      admin_name: {S: "admin"}
      partition: {S: "_popup"}
      admin_id: {N: "53"}
      env: {S: "alpha"}
      title: {S: "뒤끝매치 베타테스트 참여 개발사 모집"}
     *
     */
    const today = moment();

    return popupList.map((popup) => {
      const utcStartDate = moment.utc(popup.startDate);
      const utcEndDate = moment.utc(popup.endDate);
      const startDate = moment(popup.startDate).format('YYYY-MM-DD HH:mm');
      const endDate = moment(popup.endDate).format('YYYY-MM-DD HH:mm');
      const inDate = moment(popup.inDate).format('YYYY.MM.DD HH:mm');
      console.log(startDate);
      console.log(endDate);

      //   console.log(newDate, "newDate");
      return {
        title: popup.title,
        inDate,
        _inDate: { noDisplay: true, inDate: popup.inDate },
        date: `${startDate} - ${endDate}`,
        status: today.isBefore(startDate)
          ? '예약'
          : today.isAfter(startDate) && today.isBefore(endDate)
          ? '진행'
          : '종료',
      };
    });
  }
);
export const popupSelector = createSelector(
  (state) => state.popup.selectedPopup,
  (selectedPopup) => {
    if (selectedPopup)
      return {
        title: selectedPopup.title,
        image: selectedPopup.image,
        startDate: moment(selectedPopup.startDate).format('YYYY-MM-DDTHH:mm'),
        endDate: moment(selectedPopup.endDate).format('YYYY-MM-DDTHH:mm'),
        linkURI: selectedPopup.linkURI,
        linkOpen: selectedPopup.linkOpen,
        cookieDuration: selectedPopup.cookieDuration,
      };
    return null;
  }
);

export const popupInDateSelector = createSelector(
  (state) => state.popup.selectedPopup,
  (selectedPopup) => {
    if (selectedPopup) return selectedPopup.inDate;
    return null;
  }
);

export const insertDoneSelector = createSelector(
  (state) => state.popup.insertDone,
  (insertDone) => insertDone
);

export const lastEvaluatedKeySelector = createSelector(
  (state) => state.popup.lastEvaluatedKey,
  (lastEvaluatedKey) => lastEvaluatedKey
);

export const errorsSelector = createSelector(
  (state) => state.popup.errors,
  (errors) => errors
);
