import * as types from './constant';

// export function initGameStat(gameId, date, year) {
// 	return {
// 		type: types.INIT_GAME_STAT,
// 		gameId,
// 		date,
// 		year,
// 		MAU: false,
// 	};
// }
export function initSleepingCandidates() {
	return {
		type: types.INIT_SLEEPING_CANDIDATES,
	};
}
export function initSleepingAccounts() {
	return {
		type: types.INIT_SLEEPING_ACCOUNTS,
	};
}
export function getSleepingCandidates(param, isSearching, isPaging) {
	return {
		type: types.GET_SLEEPING_CANDIDATES,
		param,
		isSearching,
		isPaging,
	};
}
export function getSleepingAccounts(param, isSearching, isPaging) {
	return {
		type: types.GET_SLEEPING_ACCOUNTS,
		param,
		isSearching,
		isPaging,
	};
}
export function getSleepingCandidatesSuccess(data, isSearching, isPaging) {
	return {
		type: types.GET_SLEEPING_CANDIDATES_SUCCESS,
		data,
		isSearching,
		isPaging,
	};
}

export function makeSleepingCandidateNot(id) {
	return {
		type: types.MAKE_SLEEPING_CANDIDATE_NOT,
		id,
	};
}

export function makeSleepingAccountNot(id) {
	return {
		type: types.MAKE_SLEEPING_ACCOUNT_NOT,
		id,
	};
}

export function makeSleepingAccountNotSuccess(id) {
	return {
		type: types.MAKE_SLEEPING_ACCOUNT_NOT_SUCCESS,
		id,
	};
}

export function makeSleepingCandidateNotSuccess(data) {
	return {
		type: types.MAKE_SLEEPING_CANDIDATE_NOT_SUCCESS,
		data,
	};
}

export function sortAscSleepingCandidates(rows, isSearched, tableType) {
	return {
		type: types.SORT_ASC_SLEEPING_CANDIDATES,
		rows,
		isSearched,
		tableType,
	};
}

export function sortDescSleepingCandidates(rows, isSearched, tableType) {
	return {
		type: types.SORT_DESC_SLEEPING_CANDIDATES,
		rows,
		isSearched,
		tableType,
	};
}

export function getSearchKeywords(obj) {
	return {
		type: types.GET_SEARCH_KEYWORDS,
		obj,
	};
}

export function setSearching() {
	return {
		type: types.SET_SEARCHING,
	};
}
