import { createSelector } from "reselect";

// import aws from 'aws-sdk';
// import moment from 'moment';

export const isLoginedSelector = createSelector(
  state => state.login.isLogined,
  isLogined => isLogined
);

export const nameSelector = createSelector(
  state => state.login.name,
  name => name
);

export const errMsgSelector = createSelector(
  state => state.login.errMsg,
  errMsg => errMsg
);

export const userNameSelector = createSelector(
  state => state.login.userName,
  userName => userName
);
