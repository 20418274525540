// 공통모듈
import React, { useState, useEffect } from 'react';
import Header from '../../view/header';
import LeftAsideMunu from '../../view/leftAsideMenu';

import DataTable from '../../fnComponents/_DataTable';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as actions from './store/action';
import * as selectors from './store/selector';
import DraftEditor from '../notice/_mdEditor';
import moment from 'moment';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ImageUploadBox from '../../fnComponents/ImageUploadBox';
import MsgModal from '../../fnComponents/MsgModal';

const ConsolePopup = ({
  popupList,
  popup,
  insertDone,
  lastEvaluatedKey,
  errorsInSaga,
  onGetPopupList,
  onGetPopup,
  onDeletePopup,
  onInsertPopup,
  onUpdatePopup,
  onUpdatePopupSuccess,
}) => {
  const [isPopupModalOpen, handleOpen] = useState(false);
  const [isMsgModalOpen, handleMsgModal] = useState(false);

  const [startDate, setStartDate] = useState(
    moment().format('YYYY-MM-DDTHH:mm')
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DDTHH:mm'));

  const [errors, handleErrors] = useState({
    name: null,
    title: null,
    date: null,
  });

  const [inputs, handleInputs] = useState({
    name: null,
    title: null,
    url: null,
  });

  const [radioBtn, handleRadio] = useState({
    linkOpen: 'c',
    language: '국문',
    isTest: 'y',
  });

  const [imgSrcFromLocal, handleImg] = useState('');
  const [inDateToDelete, setInDateToDelete] = useState('');
  const [popupContents, setPopupContents] = useState('');
  const [isImportant, setIsImportant] = useState('n');

  useEffect(() => {
    if (popup) {
      handleInputs({
        name: popup.name,
        title: popup.title,
        url: popup.url,
      });
      handleRadio({
        linkOpen: popup.linkOpen,
        language: popup.language,
        isTest: popup.isTest,
      });
      handleImg({ image: popup.image });
      setStartDate(popup.startDate);
      setEndDate(popup.endDate);
      handleOpen(true);
      setPopupContents(popup.popupContents);
      setIsImportant(popup.isImportant);
    }
  }, [popup]);

  useEffect(() => {
    if (insertDone) {
      handleOpen(false);
      handleInputs({
        name: null,
        title: null,
        url: null,
      });
      handleImg('');
      handleRadio({ linkOpen: 'c', language: '국문', isTest: 'y' });
      setEndDate(moment().format('YYYY-MM-DDTHH:mm'));
      setStartDate(moment().format('YYYY-MM-DDTHH:mm'));
      setIsImportant('n');
      setPopupContents('');
    }
  }, [insertDone]);

  const [, setImgBoxFocusedOut] = useState(false);

  const handleInputChange = (e) => {
    handleInputs({
      ...inputs,
      [e.target.id]: e.target.value,
    });
  };

  const handleImage = (image) => {
    handleImg({ image });
  };

  const handleRadioBtnChange = (e) => {
    handleRadio({
      ...radioBtn,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (errorsInSaga) handleErrors({ ...errors, ...errorsInSaga });
  }, [errorsInSaga]);

  const handlePostPopup = async () => {
    const { title, url, name } = inputs;
    const { image } = imgSrcFromLocal;

    const imgSize = document.getElementById('myImg');

    const errors = {
      title: null,
      date: null,
      image: null,
      name: null,
      popupContents: null,
    };
    let regex =
      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    const urlTest = regex.test(url);

    if (!name) errors.name = '팝업 이름을 입력해주세요.';
    if (!title) errors.title = '팝업 제목을 입력해주세요.';
    if (!startDate) errors.date = '기간을 선택해주세요.';
    if (!popupContents) errors.popupContents = '내용을 입력해주세요.';

    if (!image) errors.image = '이미지를 등록해주세요.';
    else if (imgSize.naturalWidth > 944 || imgSize.naturalHeight > 560)
      errors.image = '이미지 규격이 맞지 않습니다.';
    if (url && !urlTest) errors.url = 'URL 형식이 올바르지 않습니다.';
    const isErr = Object.values(errors).filter((err) => !!err).length;
    if (isErr) return handleErrors(errors);
    else console.log('정상');

    if (popup) {
      onUpdatePopup(popup.id, {
        ...inputs,
        ...radioBtn,
        image,
        startDate,
        endDate,
        imageWidth: imgSize.naturalWidth,
        imageHeight: imgSize.naturalHeight,
        content: popupContents,
        isImportant,
      });

      return;
    }
    await onInsertPopup({
      ...inputs,
      ...radioBtn,
      image,
      startDate,
      endDate,
      imageWidth: imgSize.naturalWidth,
      imageHeight: imgSize.naturalHeight,
      content: popupContents,
      isImportant,
    });

    // 사용자 입력값 등 초기화 로직 --> useEffect로 대체
    // handleOpen(false);
    // handleInputs({
    //   title: null,
    //   url: null,
    // });
    // handleImg("");
    // handleRadio({ linkOpen: "c" });
    // setEndDate(null);
    // setStartDate(null);
  };

  const onHandleCloseMsgModal = () => {
    handleMsgModal(false);
  };

  const handlePopupModal = (e) => {
    if (e.target.id === 'open') return handleOpen(true);
    else {
      onUpdatePopupSuccess();
      handleOpen(false);
      handleInputs({
        name: null,
        title: null,
        url: null,
      });
      handleImg('');
      handleErrors({
        title: null,
        date: null,
        image: null,
      });
      handleRadio({ linkOpen: 'c', language: '국문', isTest: 'y' });
      setStartDate(null);
      setEndDate(null);
      setIsImportant('n');
      setPopupContents('');
    }
  };

  const handleImgUpload = async (fileChooser) => {
    const data = new FormData();
    data.append('imageType', 'project');
    data.append('uploadImage', fileChooser.files[0]);

    // const { onUploadProjectImg } = this.props;
    // onUploadProjectImg(data);
  };

  const handleDeletePopup = (a, arr) => {
    const { value } = arr[10].id;
    handleMsgModal(true);
    setInDateToDelete(value);
  };

  const deletePopup = () => {
    onDeletePopup(inDateToDelete);
    handleMsgModal(false);
  };

  const handleDateTimeChange = (e) => {
    if (e.target.id.includes('startDate')) setStartDate(e.target.value);
    if (e.target.id.includes('endDate')) setEndDate(e.target.value);
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title ">콘솔 - 팝업 관리</h3>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <Modal isOpen={isPopupModalOpen} size="xl">
                      <ModalHeader>팝업 등록</ModalHeader>
                      <ModalBody>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="name"
                            className="col-lg-2 col-form-label"
                          >
                            * 팝업 이름
                          </label>
                          <div className="col-lg-10">
                            <input
                              onChange={handleInputChange}
                              type="text"
                              className="form-control"
                              id="name"
                              required
                              value={inputs.name}
                            />
                            <div className="error message visible">
                              {errors.name}
                            </div>
                          </div>
                        </div>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="title"
                            className="col-lg-2 col-form-label"
                          >
                            * 팝업 제목
                          </label>
                          <div className="col-lg-10">
                            <input
                              onChange={handleInputChange}
                              type="text"
                              className="form-control"
                              id="title"
                              required
                              value={inputs.title}
                            />
                            <div className="error message visible">
                              {errors.title}
                            </div>
                          </div>
                        </div>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="title"
                            className="col-lg-2 col-form-label"
                          >
                            * 시작/종료 기간
                          </label>
                          <div className="col-lg-10">
                            <input
                              onChange={handleDateTimeChange}
                              type="datetime-local"
                              className="form-control birthdaytime birthdaytime-mr-10"
                              id="birthdaytime-startDate"
                              name="birthdaytime"
                              value={startDate}
                            />
                            <input
                              onChange={handleDateTimeChange}
                              type="datetime-local"
                              className="form-control birthdaytime"
                              id="birthdaytime-endDate"
                              name="birthdaytime"
                              value={endDate}
                            />
                            <div className="error message visible">
                              {errors.duplicated}
                            </div>
                          </div>
                        </div>
                        <div className="form-group m-form__group row">
                          <label
                            for="language"
                            className="col-lg-2 col-form-label"
                          >
                            언어
                          </label>
                          <div className="col-lg-10">
                            <div className="m-radio-inline">
                              <label className="m-radio">
                                <input
                                  type="radio"
                                  name="language"
                                  onChange={handleRadioBtnChange}
                                  checked={radioBtn.language === '국문'}
                                  value="국문"
                                />
                                국문
                                <span />
                              </label>
                              <label className="m-radio">
                                <input
                                  type="radio"
                                  name="language"
                                  onChange={handleRadioBtnChange}
                                  checked={radioBtn.language === '영문'}
                                  value="영문"
                                />
                                영문
                                <span />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="form-group m-form__group row mt-30 popupImg">
                          <label
                            htmlFor="image"
                            className="col-lg-2 col-form-label"
                          >
                            * 이미지
                          </label>
                          <ImageUploadBox
                            imgSize
                            handleImage={handleImage}
                            onUpload={handleImgUpload}
                            src={imgSrcFromLocal.image || inputs.image}
                            error={errors.image}
                          />
                        </div>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="url"
                            className="col-lg-2 col-form-label"
                          >
                            이미지 URL
                          </label>
                          <div className="col-lg-10">
                            <input
                              onFocus={() => {
                                setImgBoxFocusedOut(true);
                              }}
                              onChange={handleInputChange}
                              className="form-control"
                              id="url"
                              required
                              value={inputs.url}
                              type="url"
                            />
                            <div className="error message visible">
                              {errors.url}
                            </div>
                          </div>
                        </div>
                        <DraftEditor
                          content={{ content: popupContents }}
                          onHandleOnChange={(value) => {
                            console.log(value);
                            setPopupContents(value);
                          }}
                        />
                        <div className="error message visible">
                          {errors.popupContents}
                        </div>

                        <div className="form-group m-form__group row">
                          <label
                            for="isTest"
                            className="col-lg-2 col-form-label"
                          >
                            노출
                          </label>
                          <div className="col-lg-10">
                            <div className="m-radio-inline">
                              <label className="m-radio">
                                <input
                                  type="radio"
                                  name="isTest"
                                  onChange={handleRadioBtnChange}
                                  checked={radioBtn.isTest === 'y'}
                                  value="y"
                                />
                                테스트 계정
                                <span />
                              </label>
                              <label className="m-radio">
                                <input
                                  type="radio"
                                  name="isTest"
                                  onChange={handleRadioBtnChange}
                                  checked={radioBtn.isTest === 'n'}
                                  value="n"
                                />
                                라이브
                                <span />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group m-form__group row">
                          <label
                            for="isImportant"
                            className="col-lg-2 col-form-label"
                          >
                            순서
                          </label>
                          <div className="col-lg-10 flex">
                            <div className="popup-important">
                              <input
                                checked={isImportant === 'y'}
                                type="checkbox"
                                name="isImportant"
                                id="isImportant"
                                onChange={() =>
                                  setIsImportant(
                                    isImportant === 'y' ? 'n' : 'y'
                                  )
                                }
                              />
                              맨 처음
                            </div>
                          </div>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={handlePostPopup}>
                          확인
                        </Button>
                        <Button
                          id="close"
                          color="primary"
                          onClick={handlePopupModal}
                        >
                          취소
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <Button id="open" onClick={handlePopupModal}>
                      등록
                    </Button>
                    <DataTable
                      isLast={!lastEvaluatedKey}
                      requestAction={onGetPopupList}
                      deleteRow
                      requiredAction={onGetPopup}
                      secondRequiredAction={handleDeletePopup}
                      rows={popupList}
                      columns={[
                        { label: '팝업 이름' },
                        { label: '팝업 제목' },
                        { label: '작성자' },
                        { label: '등록일시' },
                        { label: '기간' },
                        { label: '언어' },
                        { label: '노출' },
                        { label: '순서' },
                        { label: '상태' },
                        { label: '' },
                      ]}
                      customClassName={
                        popupList.length > 13
                          ? 'consolePopupList-table column-7 table-scrollable'
                          : 'consolePopupList-table column-7'
                      }
                      clickableIndex={[0, 10]}
                    />
                    <MsgModal
                      content="삭제하시겠습니까?"
                      isMsgModalOpen={isMsgModalOpen}
                      onHandleCloseMsgModal={onHandleCloseMsgModal}
                      onHandleDelete={deletePopup}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  popupList: selectors.popupListSelectorForView,
  popup: selectors.popupSelector,
  insertDone: selectors.insertDoneSelector,
  lastEvaluatedKey: selectors.lastEvaluatedKeySelector,
  errorsInSaga: selectors.errorsSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPopupList: (title, arr) => dispatch(actions.getPopupList(title, arr)),
  onGetPopup: (title, arr) => dispatch(actions.getPopup(title, arr)),
  onDeletePopup: (id) => dispatch(actions.deletePopup(id)),

  onInsertPopup: (obj) => dispatch(actions.insertPopup(obj)),
  onUpdatePopup: (id, data) => dispatch(actions.updatePopup(id, data)),
  onUpdatePopupSuccess: () => dispatch(actions.updatePopupSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsolePopup);
