// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import * as types from './constant';

/**
 *
 * @param {search} : 검색어
 * @param {isNext} : LastEval Key 필요 여부에 대한 FLAG
 *
 */
export function getInquiriesList(search, isNext) {
  return {
    type: types.GET_INQUIRIES_LIST,
    search,
    isNext,
  };
}

export function getInquiriesListSuccess(data, isSearching, isNext) {
  return {
    type: types.GET_INQUIRIES_LIST_SUCCESS,
    data,
    isSearching,
    isNext,
  };
}

export function requestListSuccess(lastEvaluatedKey) {
  return {
    type: types.REQUEST_LIST_SUCCESS,
    lastEvaluatedKey,
  };
}

export function sendResponse(inDate, response) {
  return {
    type: types.SEND_RESPONSE,
    inDate,
    response,
  };
}

export function sendResponseSuccess() {
  return {
    type: types.SEND_RESPONSE_SUCCESS,
  };
}

export function deleteInquiry(inDate) {
  return {
    type: types.DELETE_INQUIRY,
    inDate,
  };
}

export function deleteInquirySuccess() {
  return {
    type: types.DELETE_INQUIRY_SUCCESS,
  };
}
