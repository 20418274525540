import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { isArray, isPlainObject } from 'lodash';
import * as types from './constant';
import * as globalActions from '../../globalStore/action';
import * as api from '../../../service/API';

import json2csv, { download } from '../../../utils/json2csv';

function* getColumns(data, csvName) {
	if (isArray(data)) {
		if (csvName === 'mail') {
			return {
				columns: ['이름', '이메일'],
				rows: data.map((obj) => ({
					['이름']: obj.companyName,
					['이메일']: obj.email,
				})),
			};
		}

		const columns = yield Object.keys(data[0]);
		return { columns, rows: data };
	}
	yield put(
		globalActions.globalErrorHandling('데이터 형식이 올바르지 않습니다.')
	);
}

/**
 *
 * @param {string} name 다운로드할 csv 종류
 */
function* getCsvData({ name }) {
	try {
		yield put(globalActions.globalLoading());
		const obj = {};

		const {
			data: { results },
		} = yield call(api.dataExtraction.getAccountsMarketing, name);

		const { columns, rows } = yield getColumns(results, name);

		const csv = yield json2csv(columns, rows);
		yield download(csv, name);

		yield put(globalActions.globalLoadingSuccess());
	} catch (error) {
		console.log('GET_CSV_DATA error', error);
		yield put(globalActions.globalErrorHandling(error));
	}
}

function* watcherGetCsvDatas() {
	yield takeLatest(types.GET_CSV_DATA, getCsvData);
}

export default [fork(watcherGetCsvDatas)];
