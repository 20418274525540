import { createSelector } from 'reselect';

import moment from 'moment';

export const clickedIdSelector = createSelector(
  (state) => state.notice.detail,
  (detail) => detail
);

export const columnsSelector = createSelector(
  (state) => state.notice.columns,
  (columns) => columns
);

export const noticeListSelector = createSelector(
  (state) => state.notice.noticeList,
  (noticeList) => {
    return noticeList.map((obj) => ({
      ...obj,
      indate: moment(obj.indate),
    }));
  }
);
export const importantNoticeListSelectorForView = createSelector(
  (state) => state.notice.importantNoticeList,
  (importantNoticeList) => {
    const _noticeList = importantNoticeList.map((_unmarshalled) => {
      return {
        type: _unmarshalled.type,
        title: _unmarshalled.title,
        content: { noDisplay: true, content: _unmarshalled.content },
        isImportant: {
          noDisplay: true,
          isImportant: _unmarshalled.isImportant,
        },
        adminEmail: _unmarshalled.admin.email,
        indate: moment(_unmarshalled.indate).format('YYYY.MM.DD HH:mm'), // view용
        _indate: { noDisplay: true, _indate: _unmarshalled.indate },
        views: _unmarshalled.views,
        isPublic: _unmarshalled.isPublic
          ? _unmarshalled.isPublic === 'y'
            ? '공개'
            : '비공개'
          : '비공개',
        language: _unmarshalled.language,
        isTest: _unmarshalled.isTest === 'y' ? '테스트 계정' : '라이브',
        id: { noDisplay: true, id: _unmarshalled.id },
      };
    });
    return _noticeList;
  }
);
export const noticeListSelectorForView = createSelector(
  (state) => state.notice.noticeList,
  (noticeList) => {
    const _noticeList = noticeList.map((_unmarshalled) => {
      return {
        type: _unmarshalled.type,
        title: _unmarshalled.title,
        content: { noDisplay: true, content: _unmarshalled.content },
        isImportant: {
          noDisplay: true,
          isImportant: _unmarshalled.isImportant,
        },
        adminEmail: _unmarshalled.admin.email,
        indate: moment(_unmarshalled.indate).format('YYYY.MM.DD HH:mm'), // view용
        _indate: { noDisplay: true, _indate: _unmarshalled.indate },
        views: _unmarshalled.views,
        isPublic: _unmarshalled.isPublic
          ? _unmarshalled.isPublic === 'y'
            ? '공개'
            : '비공개'
          : '비공개',
        language: _unmarshalled.language,
        isTest: _unmarshalled.isTest === 'y' ? '테스트 계정' : '라이브',
        id: { noDisplay: true, id: _unmarshalled.id },
      };
    });
    return _noticeList;
  }
);
export const tryPostSelector = createSelector(
  (state) => state.notice.tryPost,
  (tryPost) => tryPost
);

export const sortingParamSelector = createSelector(
  (state) => state.notice.sortingParam,
  (sortingParam) => sortingParam
);

export const editableSelector = createSelector(
  (state) => state.notice.content,
  (content) => ({ contents: content })
);

export const noticeParamsSelector = createSelector(
  (state) => state.notice.noticeParams,
  (noticeParams) => noticeParams
);

export const contentSelector = createSelector(
  // 생성시 내용을 셀렉트
  (state) => state.notice.content,
  (content) => content
);
export const _contentSelector = createSelector(
  // 수정시 내용 및 기타 셀렉트
  (state) => state.notice,
  ({ selectedNotice }) => selectedNotice
);

export const isNoticeCreateModalCloseSelector = createSelector(
  (state) => state.notice.isNoticeCreateModalClose,
  (isNoticeCreateModalClose) => isNoticeCreateModalClose
);

export const isEditModeSelector = createSelector(
  (state) => state.notice.isEditMode,
  (isEditMode) => isEditMode
);

export const isDeleteDoneSelector = createSelector(
  (state) => state.notice.isDeleteDone,
  (isDeleteDone) => isDeleteDone
);
export const importantNoticeListSelector = createSelector(
  (state) => state.notice.importantNoticeList,
  (importantNoticeList) => importantNoticeList
);

export const isMsgModalOpenSelector = createSelector(
  (state) => state.coupon.isMsgModalOpen,
  (isMsgModalOpen) => isMsgModalOpen
);

export const searchKeywordsSelector = createSelector(
  (state) => state.notice.searchKeywords,
  (searchKeywords) => searchKeywords
);

export const errMsgSelector = createSelector(
  (state) => state.notice.errMsg,
  (errMsg) => errMsg
);

export const lastEvaluatedKeySelector = createSelector(
  (state) => state.notice.lastEvaluatedKey,
  (lastEvaluatedKey) => lastEvaluatedKey
);
