import React, { useEffect, useState } from 'react';
import {
	Modal,
	ModalBody,
	Popover,
	PopoverHeader,
	PopoverBody,
} from 'reactstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import ButtonsGroup from '../../../fnComponents/buttonGroup';
import DataTable from '../../../fnComponents/_DataTable';
import * as actions from './store/action';

const TheBackendSales = () => {
	const dispatch = useDispatch();
	const { salesListForView, salesList, isLast, sortingParam, listForCSV } =
		useSelector((state) => state.theBackendSales);

	const onSortAscRows = (rows, isSearched, tableType) => {
		dispatch(actions.sortAscRows(rows));
	};
	const onSortDescRows = (rows, isSearched, tableType) => {
		dispatch(actions.sortDescRows(rows));
	};

	const handleRequestAction = () => {
		dispatch(actions.getNextTheBackendSales());
	};

	return (
		<div className="m-grid m-grid--hor m-grid--root m-page">
			<Header />
			<div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
				<button
					className="m-aside-left-close m-aside-left-close--skin-dark"
					id="m_aside_left_close_btn">
					<i className="la la-close" />
				</button>
				<LeftAsideMunu />

				<div className="m-grid__item m-grid__item--fluid m-wrapper">
					<div className="m-subheader">
						<div className="d-flex align-items-center">
							<div className="mr-auto">
								<h3 className="m-subheader__title">통계 - 매출</h3>
							</div>
						</div>
					</div>
					<div className="m-content">
						<div className="row">
							<div className="col-md-12">
								<div className="m-portlet">
									<div className="m-portlet__body">
										<ButtonsGroup
											csvData={listForCSV}
											csvFileName={`매출_통계_${moment().format(
												'YYYY-MM-DD HH:mm'
											)}`}
										/>
										<DataTable
											isLast={isLast}
											sortingParam={sortingParam}
											customClassName={
												salesListForView && salesListForView.length > 12
													? 'table-scrollable theBackendSales-table'
													: 'theBackendSales-table'
											}
											rows={salesListForView}
											actualData={salesList}
											columns={[
												{ label: '일자', field: 'Date' },
												{ label: '매출(원)', field: 'total' },
												{ label: '거래액 대비 비율(%)', field: 'per' },
												{ label: '패스 요금(원)', field: 'pass' },
												{ label: 'DB 요금(원)', field: 'db' },
												{ label: 'DAU 요금(원)', field: 'dau' },
												{ label: '기술지원료(원)', field: 'extra' },
											]}
											onSortAsc={onSortAscRows}
											onSortDesc={onSortDescRows}
											sortingColumns={[
												'일자',
												'매출(원)',
												'거래액 대비 비율(%)',
												'패스 요금(원)',
												'DB 요금(원)',
												'DAU 요금(원)',
												'기술지원료(원)',
											]}
											requestAction={handleRequestAction}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TheBackendSales;
