// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useRef } from 'react';
import jschardet from 'jschardet';
import XLSX from 'xlsx';
import iconv from 'iconv-lite';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import * as globalAction from '../containers/globalStore/action';

const CsvUploadButton = ({}) => {
  const dispatch = useDispatch();

  const asyncFileReader = (file, mode = 'buf') =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(reader.error);
      };

      switch (mode) {
        case 'buf':
        default:
          reader.readAsArrayBuffer(file);
          break;

        case 'bin':
          reader.readAsBinaryString(file);
          break;

        case 'url':
          reader.readAsDataURL(file);
          break;

        case 'text':
          reader.readAsText(file);
          break;
      }
    });

  const loadCSV = async (file) => {
    try {
      // 파일 읽기
      const buf = await asyncFileReader(file, 'buf');
      const data = Buffer.from(buf);
      const chardet = jschardet.detect(data).encoding;
      // csv는 한글 깨짐 문제가 있어서 라이브러리로 문자열 변환
      const decode = chardet
        ? // 일부 ANSI 파일을 엉뚱한 인코딩으로 인식하는 문제가 있음. UTF-8이 아니면 전부 EUC-KR로 처리하도록 땜빵
          iconv.decode(data, chardet.startsWith('UTF') ? chardet : 'EUC-KR')
        : data.toString('binary');
      const workbook = XLSX.read(decode, { type: 'binary', raw: true });
      return workbook;
    } catch (error) {
      throw error;
    }
  };

  const uploadCsvFile = async (file) => {
    const extension = file.name.split('.').pop().toLowerCase();
    if (!['xlsx', 'xls', 'csv'].includes(extension)) {
      throw new Error('file extension error');
    }

    // 업로드 용량 제한 5MB
    if (file.size > 5242880) {
      // 5 MB == 5242880 bytes
      throw new Error('file size error');
    }

    const workbook = await loadCSV(file);
    // 첫번째 시트 읽기
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const columns = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0];

    let isErr = false;

    if (
      !columns.includes('뒤끝 아이디(email)') ||
      !columns.includes('유효 기간') ||
      !columns.includes('크레딧') ||
      !columns.includes('내용')
    ) {
      dispatch(
        globalAction.globalErrorHandling({
          errMsg: '',
          message: '업로드를 실패했습니다.',
          shouldOpenModal: false,
        })
      );
      isErr = true;
      return;
    }

    const json = XLSX.utils
      .sheet_to_json(sheet, {
        raw: false,
        defval: '',
      })
      .map((item) => {
        const isFalse = !!Object.values(item).filter((item) => !item).length;

        if (isFalse) {
          dispatch(
            globalAction.globalErrorHandling({
              errMsg: '',
              message: '빈 컬럼이 존재합니다.',
              shouldOpenModal: false,
            })
          );
          isErr = true;
          return;
        }
        return item;
      });

    if (!isErr) dispatch(globalAction.uploadCsvFile(json));
  };

  const inputRef = useRef(null);

  const handleClickButton = () => {
    inputRef.current.click();
    const csvUploadButton = document.getElementById('csvUploadButton');

    csvUploadButton.value = null;
  };

  return (
    <Button
      style={{ marginRight: '20px' }}
      color="primary"
      onClick={handleClickButton}
    >
      업로드
      <input
        id="csvUploadButton"
        ref={inputRef}
        style={{ display: 'none' }}
        className="btn btn-primary"
        type="file"
        onChange={({ target: { files } }) => uploadCsvFile(files[0])}
      />
    </Button>
  );
};

export default CsvUploadButton;
