/*
 * Copyright 2013-2022 AFI, Inc. All Rights Reserved.
 */

import React from 'react';
import { DateRangePicker } from 'react-dates';

import { ICON_AFTER_POSITION } from 'react-dates/constants';
import Octicon, { Calendar } from '@primer/octicons-react';
const _DateRangePicker = ({
  startDate,
  endDate,
  focusedInput,
  setFocusedInput,
  setStartDate,
  setEndDate,
  disabled,
  onGetSearchItem,
  displayFormat,
  deletedProjects,
  isOutsideRange = () => false,
}) => {
  return (
    <DateRangePicker
      enableOutsideDays
      disabled={disabled}
      displayFormat={displayFormat ? displayFormat : 'YYYY.MM.DD HH:mm'}
      monthFormat="YYYY[년] MM[월]"
      startDatePlaceholderText="시작일"
      endDatePlaceholderText="종료일"
      customInputIcon={<Octicon icon={Calendar} />}
      inputIconPosition={ICON_AFTER_POSITION}
      startDate={startDate}
      endDate={endDate}
      startDateId="20010812"
      endDateId="21190312"
      onDatesChange={({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
        if (onGetSearchItem) {
          if (deletedProjects)
            onGetSearchItem({
              deleteStartDate: startDate,
              deleteEndDate: endDate,
            });
          else onGetSearchItem({ startDate, endDate });
        }
      }}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      isOutsideRange={isOutsideRange}
      small
      minimumNights={0}
    />
  );
};

export default _DateRangePicker;
