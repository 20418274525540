// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { requestGet, requestPost, requestPut } from '../../utils/request';
import Qs from 'qs';

export const getStatusList = (id) => {
  const url = `project/${id}/status`;

  return requestGet(url);
};

export const updateStatus = (id, data) => {
  const url = `project/${id}/status`;

  return requestPost(url, data);
};

export const getProjectsCount = () => {
  const url = `project/count`;
  return requestGet(url);
};

export const updatePayPlan = (id, obj) => {
  // const url = `project/${id}/paymentPlan`;
  const url = `project/${id}/paymentPlan/v1.3`;
  return requestPost(url, obj);
};

export const getProjects = (params) => {
  const url = 'project/v1.3';

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  return requestGet(url, options);
};

export const getProject = (gameId) => {
  const url = `project/${gameId}`;

  return requestGet(url);
};

export const getDeletedProjects = (params) => {
  const url = `project/deleted/v1.2`;

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  return requestGet(url, options);
};

export const updateGameReleasingData = (gameId, params) => {
  const key = Object.keys(params)[0];
  const value = Object.values(params)[0];
  const url = `project/${gameId}/release?${key}=${value}`;

  return requestPost(url);
};

export const getRatePlan = (gameId) => {
  const url = `project/ratePlan/${gameId}`;
  return requestGet(url);
};

export const putRatePlan = (gameId, body) => {
  const url = `project/ratePlan/${gameId}`;
  return requestPut(url, body);
};

export const postDiscount = (body) => {
  const url = `project/discount`;
  return requestPost(url, body);
};
