import { LOCATION_CHANGE } from 'react-router-redux';
import { cancel, fork, put, take, takeLatest } from 'redux-saga/effects';
import * as types from './constant';
import * as actions from './action';
import * as globalActions from '../../globalStore/action';

import DefaultAxios from '../../../utils/DefaultAxios';
import BASE_URL from '../../../shared/baseUrl';
import Qs from 'qs';

function* getDashBoards({ params }) {
	try {
		yield put(globalActions.globalLoading());
		let options = null;
		if (params) {
			options = {
				params,
				paramsSerializer(serializingParams) {
					return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
				},
			};
		}
		const { data } = yield DefaultAxios.get(
			`${BASE_URL.DASHBOARD_API}/dashboard`,
			options
		);

		yield put(actions.getDashboardSuccess(data));
		yield put(globalActions.globalLoadingSuccess());
	} catch (error) {}
}

function* watcherGetDashBoards() {
	yield takeLatest(types.GET_DASHBOARD, getDashBoards);
}

function* handleLocationChange({ payload }) {
	try {
		const { pathname } = payload;
		if (pathname.includes('dashboard')) yield put(actions.getDashboard());
	} catch (error) {}
}

function* watcherLocationChange() {
	yield takeLatest(LOCATION_CHANGE, handleLocationChange);
}

export default [fork(watcherLocationChange), fork(watcherGetDashBoards)];
