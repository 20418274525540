export const INIT_TOPICS_CONSOLE_GUIDE = "INIT_TOPICS_CONSOLE_GUIDE";
export const GET_TOPICS_CONSOLE_GUIDE = "GET_TOPICS_CONSOLE_GUIDE";
export const GET_TOPICS_CONSOLE_GUIDE_SUCCESS = "GET_TOPICS_CONSOLE_GUIDE_SUCCESS";
export const MAKE_CLASS_CONSOLE_GUIDE = "MAKE_CLASS_CONSOLE_GUIDE";
export const MAKE_CLASS_CONSOLE_GUIDE_SUCCESS = "MAKE_CLASS_CONSOLE_GUIDE_SUCCESS";
export const MAKE_TOPIC_CONSOLE_GUIDE = "MAKE_TOPIC_CONSOLE_GUIDE";
export const MAKE_TOPIC_CONSOLE_GUIDE_SUCCESS = "MAKE_TOPIC_CONSOLE_GUIDE_SUCCESS";
export const MAKE_DOCUMENT_CONSOLE_GUIDE = "MAKE_DOCUMENT_CONSOLE_GUIDE";
export const MAKE_DOCUMENT_CONSOLE_GUIDE_SUCCESS = "MAKE_DOCUMENT_CONSOLE_GUIDE_SUCCESS";
export const MAKE_VERSION = "MAKE_VERSION";
export const MAKE_VERSION_SUCCESS = "MAKE_VERSION_SUCCESS";
export const GET_LIST_CONSOLE_GUIDE = "GET_LIST_CONSOLE_GUIDE";
export const GET_CLASS_PARENT_LIST_CONSOLE_GUIDE = "GET_CLASS_PARENT_LIST_CONSOLE_GUIDE";
export const GET_DOCUMENT_CONSOLE_GUIDE = "GET_DOCUMENT_CONSOLE_GUIDE";
export const GET_VERSION_LIST = "GET_VERSION_LIST";
export const GET_VERSION_LIST_SUCCESS = "GET_VERSION_LIST_SUCCESS";
export const GET_LIST_CONSOLE_GUIDE_SUCCESS = "GET_LIST_CONSOLE_GUIDE_SUCCESS";
export const GET_CLASS_PARENT_LIST_CONSOLE_GUIDE_SUCCESS = "GET_CLASS_PARENT_LIST_CONSOLE_GUIDE_SUCCESS";
export const GET_DOCUMENT_CONSOLE_GUIDE_SUCCESS = "GET_DOCUMENT_CONSOLE_GUIDE_SUCCESS";

export const UPDATE_VERSION = "UPDATE_VERSION";
export const UPDATE_VERSION_SUCCESS = "UPDATE_VERSION_SUCCESS";
export const UPDATE_TOPIC_CONSOLE_GUIDE = "UPDATE_TOPIC_CONSOLE_GUIDE";
export const UPDATE_TOPIC_CONSOLE_GUIDE_SUCCESS = "UPDATE_TOPIC_CONSOLE_GUIDE_SUCCESS";
export const UPDATE_CLASS_CONSOLE_GUIDE = "UPDATE_CLASS_CONSOLE_GUIDE";
export const UPDATE_CLASS_CONSOLE_GUIDE_SUCCESS = "UPDATE_CLASS_CONSOLE_GUIDE_SUCCESS";
export const UPDATE_DOCUMENT_CONSOLE_GUIDE = "UPDATE_DOCUMENT_CONSOLE_GUIDE";
export const UPDATE_DOCUMENT_CONSOLE_GUIDE_SUCCESS = "UPDATE_DOCUMENT_CONSOLE_GUIDE_SUCCESS";
export const UPDATE_ORDER_CONSOLE_GUIDE = "UPDATE_ORDER_CONSOLE_GUIDE";
export const UPDATE_ORDER_CONSOLE_GUIDE_SUCCESS = "UPDATE_ORDER_CONSOLE_GUIDE_SUCCESS";
export const DELETE_VERSION = "DELETE_VERSION";
export const DELETE_VERSION_SUCCESS = "DELETE_VERSION_SUCCESS";
export const DELETE_TOPIC_CONSOLE_GUIDE = "DELETE_TOPIC_CONSOLE_GUIDE";
export const DELETE_TOPIC_CONSOLE_GUIDE_SUCCESS = "DELETE_TOPIC_CONSOLE_GUIDE_SUCCESS";
export const DELETE_CLASS_CONSOLE_GUIDE = "DELETE_CLASS_CONSOLE_GUIDE";
export const DELETE_CLASS_CONSOLE_GUIDE_SUCCESS = "DELETE_CLASS_CONSOLE_GUIDE_SUCCESS";
export const DELETE_DOCUMENT_CONSOLE_GUIDE = "DELETE_DOCUMENT_CONSOLE_GUIDE";
export const DELETE_DOCUMENT_CONSOLE_GUIDE_SUCCESS = "DELETE_DOCUMENT_CONSOLE_GUIDE_SUCCESS";
export const INIT_GET_LIST_KEY_CONSOLE_GUIDE = "INIT_GET_LIST_KEY_CONSOLE_GUIDE";
export const MAKE_DOCUMENT_ERROR = "MAKE_DOCUMENT_ERROR";

// ******************
export const GET_SHOWCASE_LIST = "GET_SHOWCASE_LIST";
export const GET_MORE_SHOWCASE_LIST = "GET_MORE_SHOWCASE_LIST";
export const GET_MORE_SHOWCASE_LIST_SUCCESS = "GET_MORE_SHOWCASE_LIST_SUCCESS";
export const GET_SHOWCASE_LIST_SUCCESS = "GET_SHOWCASE_LIST_SUCCESS";

export const GET_SHOWCASE = "GET_SHOWCASE";
export const DELETE_SHOWCASE = "DELETE_SHOWCASE";
export const INSERT_SHOWCASE = "INSERT_SHOWCASE";
export const INSERT_SHOWCASE_SUCCESS = "INSERT_SHOWCASE_SUCCESS";
export const UPDATE_SHOWCASE = "UPDATE_SHOWCASE";
export const UPDATE_SHOWCASE_SUCCESS = "UPDATE_SHOWCASE_SUCCESS";
export const UPDATE_SHOWCASE_ORDER = "UPDATE_SHOWCASE_ORDER";
