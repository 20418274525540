import { put, takeLatest, select, call } from "redux-saga/effects";
import { LOCATION_CHANGE, push } from "react-router-redux";

import moment from "moment";

import * as types from "./constant";

import * as actions from "./action";
import * as selectors from "./selector";
import * as api from "../../../service/API";

function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split("/");
    console.log(pathArray[1], "path");
    if (pathArray[1] === "coupon") {
      yield put(actions.getCouponList());
    }
  } catch (err) {
    console.log("saga err", err);
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* getCouponList() {
  try {
    const searchKeywords = yield select(selectors.searchKeywordsSelector);
    const isSearching = yield select(selectors.isSearchingSelector); // 삭제 예정?
    
    const { couponTitle, startDate, endDate, admin } = searchKeywords;
    // 검색 키워드가 하나라도 존재하면 검색 상태인 것으로 간주함
    // 검색 결과가 15개 초과여서 isLast가 false로 오면 더보기 버튼이 활성화 될 것이고
    // 더보기 클릭 시 해당 saga 호출되면 검색어가 존재하는지 확인해서 검색어 + 증가된 offset으로 요청 
    //  

    const offset = yield select(selectors.couponListOffsetSelector);
    const limit = 10;

    const _searchKeywords = {
      couponTitle,
      admin,
      startDate: startDate
        ? // ? startDate.toISOString()
          startDate.set({ hour: 0 }).toISOString()
        : undefined,
      endDate: endDate
        ? endDate.set({ hour: 23, minute: 59, second: 59 }).toISOString()
        : undefined,
        offset: isSearching ? 0 : offset,
      limit,
    };

    const params = { offset, limit };

    const {
      data: { couponMetas, isLast },
    } = yield call(api.coupon.getCouponList, _searchKeywords, params);

    yield put(
      actions.getCouponListSuccess(
        couponMetas.map((row) => ({
          ...row,
          issuedDate: moment(row.issuedDate),
        })),
        isLast
      )
    );
  } catch (error) {
    console.log("GET_COUPON_LIST ERR", error);
  }
}

export function* watcherGetCouponList() {
  yield takeLatest(types.GET_COUPON_LIST, getCouponList);
}
function* initCouponList() {
  try {
    const offset = 0;
    const limit = 10;

    const params = { offset, limit };

    const {
      data: { couponMetas, isLast },
    } = yield call(api.coupon.getCouponList, params);

    yield put(
      actions.initCouponListSuccess(
        couponMetas.map((row) => ({
          ...row,
          issuedDate: moment(row.issuedDate),
        })),
        isLast
      )
    );
  } catch (error) {
    console.log("INIT_COUPON_LIST ERR", error);
  }
}

export function* watcherInitCouponList() {
  yield takeLatest(types.INIT_COUPON_LIST, initCouponList);
}

function* getCouponDetails({ id, arr, param }) {
  try {
    const {
      id: { id },
    } = arr[1];

    const offset = yield select(selectors.detailOffsetSelector);
    const limit = 10;
    const params = { offset, limit, ...param };

    const {
      data: { coupons, isLast },
    } = yield call(api.coupon.getCouponDetails, id, params);
    yield put(actions.getCouponDetailsSuccess(coupons, isLast));
  } catch (error) {
    console.log("CLICK_COUPON_DETAIL_ROW", error);
  }
}

export function* watcherGetCouponDetails() {
  yield takeLatest(types.CLICK_COUPON_DETAIL_ROW, getCouponDetails);
}

function* issueCoupon({ value }) {
  try {
    yield call(api.coupon.issueCoupon, value);
    yield put(actions.initCouponList());
  } catch (error) {
    console.log("ISSUE_COUPON err", error);
  }
}

export function* watcherIssueCoupon() {
  yield takeLatest(types.ISSUE_COUPON, issueCoupon);
}

function* deleteCoupon({ id }) {
  try {
    yield call(api.coupon.deleteCoupon, id);
    yield put(actions.initCouponList());
  } catch (error) {
    console.log("DELETE_COUPON err", error);
  }
}

export function* watcherDeleteCoupon() {
  yield takeLatest(types.DELETE_COUPON, deleteCoupon);
}

// function* getOverdueManageRow({ id, arr }) {
// 	const accountList = yield select(selectors.accountListSelector);
// 	const filtered = accountList.filter(account => account.ID === arr[0].ID)[0];
// 	yield put(actions.getOverdueManageRowSuccess(filtered));
// }

// export function* watcherGetOverdueManageRow() {
// 	yield takeLatest(types.CLICK_OVERDUE_BILL_ROW, getOverdueManageRow);
// }
