export const GET_SEND_TO_COUNT = 'GET_SEND_TO_COUNT';
export const GET_SEND_TO_COUNT_SUCCESS = 'GET_SEND_TO_COUNT_SUCCESS';
export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_TEST_EMAIL = 'SEND_TEST_EMAIL';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_TEST_EMAIL_SUCCESS = 'SEND_TEST_EMAIL_SUCCESS';
export const READ_FILES = 'READ_FILES';
export const DELETE_READ_FILES = 'DELETE_READ_FILES';
export const READ_FILES_SUCCESS = 'READ_FILES_SUCCESS';
export const RESET_FILES = 'RESET_FILES';
export const INIT_SEND_EMAIL_SUCCESS = 'INIT_SEND_EMAIL_SUCCESS';
export const INIT_SEND_TEST_EMAIL_SUCCESS = 'INIT_SEND_TEST_EMAIL_SUCCESS';
export const HANDLE_FILE_ERRORS = 'HANDLE_FILE_ERRORS';
