import { createSelector } from "reselect";
import moment from "moment";

// import aws from 'aws-sdk';

export const clickedIdSelector = createSelector(
  (state) => state.coupon.detail,
  (detail) => detail
);

export const billInfoSelector = createSelector(
  (state) => state.coupon.billInfo,
  (billInfo) => billInfo
);

export const columnsSelector = createSelector(
  (state) => state.coupon.columns,
  (columns) => columns
);

export const detailColumnsSelector = createSelector(
  (state) => state.coupon.detailColumns,
  (detailColumns) => detailColumns
);

export const couponListSelector = createSelector(
  (state) => state.coupon.couponList,
  (couponList) => couponList
);
export const detailCouponListSelector = createSelector(
  (state) => state.coupon.detailCouponList,
  (detailCouponList) => detailCouponList
);

export const isMsgModalOpenSelector = createSelector(
  (state) => state.coupon.isMsgModalOpen,
  (isMsgModalOpen) => isMsgModalOpen
);

export const managecouponSelector = createSelector(
  (state) => state.coupon.manageOverdueBill,
  (manageOverdueBill) => manageOverdueBill
);

export const overdueBillInfoSelector = createSelector(
  (state) => state.coupon.overdueBillInfo,
  (overdueBillInfo) => overdueBillInfo
);

// ***************** selectors in use
export const couponMetaSelectorForView = createSelector(
  (state) => state.coupon.couponMeta,
  (couponMeta) => {
    const rows = couponMeta.map((row, i) => ({
      couponTitle: row.couponTitle, // 쿠폰명
      id: { id: row.id, noDisplay: true },
      creditPrice: row.creditPrice, //크레딧
      numberOfCoupon: row.numberOfCoupon, // 발행수
      numberOfUsed: row.numberOfUsed, // 사용수
      recovery: row.recovery, // 회수율
      issuedDate: row.issuedDate.format("YYYY.MM.DD"), // 발행일
      admin: row.admin, // 작성자
    }));
    return rows;
  }
);
export const couponMetaSelector = createSelector(
  (state) => state.coupon.couponMeta,
  (couponMeta) => couponMeta
);
export const isLastSelector = createSelector(
  (state) => state.coupon.isLast,
  (isLast) => isLast
);
export const isDetailLastSelector = createSelector(
  (state) => state.coupon.isDetailLast,
  (isDetailLast) => isDetailLast
);

export const detailOffsetSelector = createSelector(
  (state) => state.coupon.detailOffset,
  (detailOffset) => detailOffset
);
export const couponListOffsetSelector = createSelector(
  (state) => state.coupon.couponListOffset,
  (couponListOffset) => couponListOffset
);

export const sortingParamSelector = createSelector(
  (state) => state.coupon.sortingParam,
  (sortingParam) => sortingParam
);
export const couponTitleSelector = createSelector(
  (state) => state.coupon.couponTitle,
  (couponTitle) => couponTitle
);
export const couponIdSelector = createSelector(
  (state) => state.coupon.id,
  (id) => id
);
export const errorsSelector = createSelector(
  (state) => state.coupon,
  ({ isErr, errors }) => ({ isErr, errors })
);

export const searchKeywordsSelector = createSelector(
  (state) => state.coupon.searchKeywords,
  (searchKeywords) => searchKeywords
);

export const isSearchingSelector = createSelector(
  (state) => state.coupon.isSearching,
  (isSearching) => isSearching
);

export const couponDetailsSelectorForView = createSelector(
  (state) => state.coupon.couponDetails,
  (couponDetails) => {
    return couponDetails.map((row) => ({
      couponCode: row.couponCode,
      accountEmail: row.accountEmail || "-",
      registerationDate: row.registerationDate
        ? moment(row.registerationDate).format("YYYY.MM.DD")
        : "-",
      expirationDate: row.expirationDate
        ? moment(row.expirationDate).format("YYYY.MM.DD")
        : "-",
    }));
  }
);
