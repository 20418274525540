export const INIT_POPUP_LIST = "INIT_POPUP_LIST";
export const GET_POPUP_LIST = "GET_POPUP_LIST";
export const GET_POPUP_LIST_SUCCESS = "GET_POPUP_LIST_SUCCESS";

export const GET_POPUP = "GET_POPUP";
export const DELETE_POPUP = "DELETE_POPUP";
export const INSERT_POPUP = "INSERT_POPUP";
export const INSERT_POPUP_SUCCESS = "INSERT_POPUP_SUCCESS";
export const UPDATE_POPUP = "UPDATE_POPUP";
export const UPDATE_POPUP_SUCCESS = "UPDATE_POPUP_SUCCESS";

export const HANDLE_POPUP_ERRORS = "HANDLE_POPUP_ERRORS";
