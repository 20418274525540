import { call, put, takeLatest, select } from "redux-saga/effects";

import * as selectors from "./selector";
import * as types from "./constant";
import * as actions from "./action";
import { LOCATION_CHANGE } from "react-router-redux";
import { openSubMenu } from "../../../../view/store/action";
import aws from "aws-sdk";

import * as api from "../../../../service/API";
import moment from "moment";

function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split("/");
    // console.log(pathArray, "*** pathArray ***");
    if (pathArray[1] === "landing" && pathArray[2] === "showcase") {
      yield put(openSubMenu("showcase"));
      yield put(actions.initShowcaseList());
    }
  } catch (err) {
    console.log("LOCATION_CHANGE", err);
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* initShowcaseList() {
  try {
    yield put(actions.getShowcaseList());
  } catch (error) {
    console.log("INIT_SHOWCASE_LIST", error);
  }
}

export function* watcherInitShowcaseList() {
  yield takeLatest(types.INIT_SHOWCASE_LIST, initShowcaseList);
}

function* getShowcaseList(lastEvaluatedKey) {
  try {
    const {
      data: { Items, LastEvaluatedKey },
    } = yield call(api.showcase.getShowcaseList);

    yield put(
      actions.getShowcaseListSuccess(
        Items.map((item) => aws.DynamoDB.Converter.unmarshall(item)),
        LastEvaluatedKey
      )
    );
  } catch (error) {
    console.log("GET_SHOWCASE_LIST", error);
  }
}

export function* watcherGetShowcaseList() {
  yield takeLatest(types.GET_SHOWCASE_LIST, getShowcaseList);
}

function* getMoreShowcaseList({ lastEvaluatedKey }) {
  try {
    const {
      data: { Items, LastEvaluatedKey },
    } = yield call(api.showcase.getShowcaseList, {
      LastEvaluatedKey: lastEvaluatedKey,
      limit: 20,
    });

    yield put(
      actions.getMoreShowcaseListSuccess(
        Items.map((item) => aws.DynamoDB.Converter.unmarshall(item)),
        LastEvaluatedKey
      )
    );
  } catch (error) {
    console.log("GET_SHOWCASE_LIST", error);
  }
}

export function* watcherGetMoreShowcaseList() {
  yield takeLatest(types.GET_MORE_SHOWCASE_LIST, getMoreShowcaseList);
}

function* updateShowcaseOrder({ inDate, updown }) {
  try {
    yield call(api.showcase.updateShowcaseOrder, inDate, updown);
    yield put(actions.initShowcaseList());
  } catch (error) {
    console.log("UPDATE_SHOWCASE_ORDER", error);
  }
}

export function* watcherUpdateShowcaseOrder() {
  yield takeLatest(types.UPDATE_SHOWCASE_ORDER, updateShowcaseOrder);
}

function* insertShowcase({ obj }) {
  try {
    yield call(api.showcase.insertShowcase, obj);
    yield put(actions.insertShowcaseSuccess());
    yield put(actions.initShowcaseList());
  } catch (error) {
    console.log("INSERT_SHOWCASE", error.response);
    if(error && error.response && error.response.status === 404 && error.response.data.message === 'image not found, image을(를) 찾을 수 없습니다') yield put(actions.insertShowcaseError({imageErr: true}));
    if(error && error.response && error.response.status === 404 && error.response.data.message === 'game not found, game을(를) 찾을 수 없습니다') yield put(actions.insertShowcaseError({gameErr: true}));
  }
}

export function* watcherInsertShowcase() {
  yield takeLatest(types.INSERT_SHOWCASE, insertShowcase);
}

function* updateShowcase({ inDate, data }) {
  try {
    yield call(api.showcase.updateShowcase, inDate, data);
    yield put(actions.initShowcaseList());
    yield put(actions.updateShowcaseSuccess());
  } catch (error) {
    console.log("UPDATE_SHOWCASE", error);
  }
}

export function* watcherUpdateShowcase() {
  yield takeLatest(types.UPDATE_SHOWCASE, updateShowcase);
}

function* deleteShowcase({ inDate }) {
  try {
    yield call(api.showcase.deleteShowcase, inDate);
    yield put(actions.initShowcaseList());
  } catch (error) {
    console.log("DELETE_SHOWCASE", error);
  }
}

export function* watcherDeleteShowcase() {
  yield takeLatest(types.DELETE_SHOWCASE, deleteShowcase);
}
