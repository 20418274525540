import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import { Button } from 'reactstrap';
import moment from 'moment';

import MsgModal from '../../../fnComponents/MsgModal';
import MarkDownEditor from './MarkDownEditor';

import * as actions from './store/action';

const Sla = () => {
  const { lastModified, contents } = useSelector((state) => state.sla);

  const dispatch = useDispatch();

  const handleOnChange = (html) => {
    dispatch(actions.catchHTML(html));
  };

  const [msgModal, handleMsgModal] = useState({
    isModalOpen: false,
    msg: null,
    func: null,
  });

  const handleControlModal = () => {
    handleMsgModal({
      isModalOpen: false,
      msg: null,
      func: null,
    });
  };

  const handleButtons = (e) => {
    if (e.target.id === 'submit')
      return handleMsgModal({
        isModalOpen: true,
        msg: '수정 사항을 적용 하시겠습니까?',
        func: () => {
          dispatch(actions.updateSLAPolicy());
          handleControlModal();
        },
      });
    else if (e.target.id === 'initialize')
      return handleMsgModal({
        isModalOpen: true,
        msg: '수정 사항을 초기화 하시겠습니까?',
        func: () => {
          dispatch(actions.getSLAPolicy());
          handleControlModal();
        },
      });
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />
        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title ">정책 관리</h3>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <h4>서비스수준협약(SLA)</h4>
                    <br />
                    <h5>
                      최종 업데이트{' '}
                      {lastModified && lastModified.includes('Invalid')
                        ? ''
                        : moment(lastModified).format('YYYY.MM.DD HH:mm')}
                    </h5>
                    <br />
                    <MarkDownEditor
                      _content={{ content: contents }}
                      onHandleOnChange={handleOnChange}
                    />
                    <div className="terms-button-group" onClick={handleButtons}>
                      <Button id="submit">적용</Button>
                      <Button id="initialize" className="lt-margin-30">
                        초기화
                      </Button>
                    </div>
                    <MsgModal
                      content={msgModal && msgModal.msg}
                      isMsgModalOpen={msgModal && msgModal.isModalOpen}
                      onHandleCloseMsgModal={handleControlModal}
                      onConfirmAction={msgModal && msgModal.func}
                      confirmOrCancel
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sla;
