import * as types from "./constant";
/** 
export function getPrivacyPolicy() {
	return {
		type: types.GET_PRIVACY_POLICY,
		
	};
}

export function getPrivacyPolicySuccess() {
	return {
		type: types.GET_PRIVACY_POLICY_SUCCESS,
		
	};
}


export function updatePrivacyPolicy() {
	return {
		type: types.UPDATE_PRIVACY_POLICY,
		
	};
}


export function updatePrivacyPolicySuccess() {
	return {
		type: types.UPDATE_PRIVACY_POLICY_SUCCESS,
		
	};
}


export function initPopupList() {
	return {
		type: types.GET_TERMS_POLICY,
		
	};
}


export function initPopupList() {
	return {
		type: types.GET_TERMS_POLICY_SUCCESS,
		
	};
}


export function initPopupList() {
	return {
		type: types.UPDATE_TERMS_POLICY,
		
	};
}


export function initPopupList() {
	return {
		type: types.INIT_TERMS_POLICY_STATE,
		
	};
}
*/

// terms
export function getTermsPolicy() {
	return {
		type: types.GET_TERMS_POLICY,
		
	};
}

export function getTermsPolicySuccess(data) {
	return {
		type: types.GET_TERMS_POLICY_SUCCESS,data
		
	};
}


export function updateTermsPolicy(contents) {
	return {
		type: types.UPDATE_TERMS_POLICY,contents
		
	};
}


export function updateTermsPolicySuccess() {
	return {
		type: types.UPDATE_TERMS_POLICY_SUCCESS,
		
	};
}


export function initTermsPolicyState() {
	return {
		type: types.INIT_TERMS_POLICY_STATE,
		
	};
}

export function handleOnChange(value) {
	return {
	  type: types.HANDLE_ON_CHANGE,
	  value
	};
  }





