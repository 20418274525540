// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { createStructuredSelector } from 'reselect';
import { Modal, ModalBody } from 'reactstrap';

import Cookies from 'js-cookie';
import MsgModal from './fnComponents/MsgModal';
import * as globalActions from './containers/globalStore/action';
import * as globalSelector from './containers/globalStore/selector';
import SpinnerCompo from './fnComponents/Spinner';

const CheckLogin = ({
	children,
	isErrSelector,
	isLoadingSelector,
	errCodeSelector,
	errMsgSelector,
	onHandleCloseMsgModal,
}) => {
	const [isLoginChecked, setIsLoginChecked] = useState('');

	const {
    isLoading,
    isErr,
    errType,
    errCode,
    errMsg,
    shouldOpenModal,
    globalMessage,
    globalMsgModalOpen,
    confirmAction,
  } = useSelector((state) => state.globalStore);

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname === '/login') {
      // this.setState({ isLoginChecked: true });
      setIsLoginChecked(true);
      return;
    }

    const accessToken = Cookies.get('access_token');
    const refreshToken = Cookies.get('refreshToken');
    //   console.log(accessToken, refreshToken, "accessToken   refreshToken");

    if (!accessToken || !refreshToken) {
      console.log('No accessToken');
      window.location.href = '/login';
      return;
    }
    //   this.setState({ isLoginChecked: true }); // 쿠키가 있을 때
    setIsLoginChecked(true);
  }, []);

  return (
    <div>
      {isLoading ? (
        <Dimmer page active>
          <Loader>잠시만 기다려주세요!</Loader>
        </Dimmer>
      ) : null}
      <MsgModal
        onlyCanClose
        isMsgModalOpen={shouldOpenModal === false ? false : isErr}
        onHandleCloseMsgModal={() =>
          dispatch(globalActions.globalErrorHandlingDone())
        }
        header={`에러 발생 : Action Type is ${errType}`}
        content={`${errCode} : ${errMsg} `}
      />

      <MsgModal
        header={globalMessage}
        isMsgModalOpen={globalMsgModalOpen}
        confirmOrCancel
        onConfirmAction={confirmAction}
        onHandleCloseMsgModal={() =>
          dispatch(globalActions.globalMsgModalOpen(false))
        }
      />
      {isLoginChecked ? children : null}
    </div>
  );
};

export default CheckLogin;
