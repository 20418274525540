// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { LOCATION_CHANGE } from 'react-router-redux';
import * as types from './types';

const initialState = {
	indicators: {},
};

function indicators(state = initialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case types.INDICATORS_GETTER_SUCCESS:
			const { base, push, chat, db, match } = action.params;
			const indicators = {
				...action.params,
				base: {
					...base,
					...push,
				},
				function: {
					basic: {
						callLimit: action.params.function
							? action.params.function.call.basic
							: 0,
						callPrice: action.params.function
							? action.params.function.call.price
							: 0,
						durationLimit: action.params.function
							? action.params.function.duration.basic
							: 0,
						durationPrice: action.params.function
							? action.params.function.duration.price
							: 0,
					},
				},
				chat: {
					basic: {
						fee: chat.fee.basic || 0,
						trafficLimit: chat.traffic.limitAmount.basic / 1024 || 0,
						trafficPrice: chat.traffic.fee.basic || 0,
						logPrice: chat.log.fee || 0,
					},
					business: {
						fee: chat.fee.business || 0,
						trafficLimit: chat.traffic.limitAmount.business / 1024 || 0,
						trafficPrice: chat.traffic.fee.business || 0,
						logPrice: chat.log.fee || 0,
					},
				},
				match: {
					basic: {
						fee: match.fee.basic || 0,
						matchCountLimit: match.matchCount.limitAmount.basic,
						matchCountPrice: match.matchCount.fee.basic,
						durationLimit: match.duration.limitAmount.basic / 60,
						durationPrice: match.duration.fee.basic,
						logPrice: match.log.fee,
					},
					business: {
						fee: match.fee.business || 0,
						matchCountLimit: match.matchCount.limitAmount.business,
						matchCountPrice: match.matchCount.fee.business,
						durationLimit: match.duration.limitAmount.business / 60,
						durationPrice: match.duration.fee.business,
						logPrice: match.log.fee,
					},
				},
			};
			delete indicators.push;
			delete indicators.base.ignore;
			return {
				...state,
				indicators,
				isSetterSuccess: false,
			};
		case types.INDICATORS_SETTER_SUCCESS:
			return {
				...state,
				isSetterSuccess: true,
			};
		case types.INDICATORS_PUTTER_SUCCESS:
			return {
				...state,
			};

		default:
			return state;
	}
}

export default indicators;
