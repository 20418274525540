/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'; // redux side-effect 관리(i.e. 서버와 api 통신 등)
import logger from 'redux-logger'; // redux 사용시 console에 log 제공
import { composeWithDevTools } from 'redux-devtools-extension'; // 브라우저의 redux-devtools-extension 사용 가능
// import createReducer from './reducers';
import createReducer from './routes/reducers';
import createSaga from './routes/sagas';

const sagaMiddleware = createSagaMiddleware();

// export default function configureStore() {
function configureStore() {
	let middlewares = [sagaMiddleware];
	if (process.env.NODE_ENV === 'development') {
		middlewares = [...middlewares, logger];
	}
	const enhancers = [applyMiddleware(...middlewares)];

	const actionSanitizer = action =>
		action.type === 'LOG_IN' && action.payload
			? { ...action, payload: '<<LONG_BLOB>>' }
			: action;
	const options = { actionSanitizer };
	const composeEnhancers =
		process.env.NODE_ENV !== 'production'
			? composeWithDevTools(options)
			: compose;

	const store = createStore(createReducer, composeEnhancers(...enhancers));
	sagaMiddleware.run(createSaga);

	return store;
}

const store = configureStore()
export default store;
