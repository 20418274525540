import { requestPost, requestGet, requestDelete } from '../../utils/request';
import Qs from 'qs';

export const getPopupList = (params) => {
  const url = `popup`;
  // const url = `${BaseUrl.LANDING_API}/popup`;

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  return requestGet(url, options);
};

export const updatePopup = (id, data) => {
  const url = `popup/${id}/`;

  return requestPost(url, data);
};

export const insertPopup = (data) => {
  const url = `popup`;

  return requestPost(url, data);
};

export const deletePopup = (inDate) => {
  const url = `popup/${inDate}`;

  return requestDelete(url);
};
