// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { requestGet, requestPost } from '../../utils/request';

export const getSendToCount = () => {
  const url = 'email/byadmin/sendToCount';
  return requestGet(url);
};

export const sendEmail = (payload) => {
  const url = 'email/byadmin/send/v1.1';
  return requestPost(url, payload);
};

export const sendTestEmail = (payload) => {
  const url = 'email/byadmin/sendTest';
  return requestPost(url, payload);
};
