// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { createSelector } from 'reselect';
// import aws from 'aws-sdk';
import moment from 'moment';

export const retentionSelector = createSelector(
  (state) => state.retention.retention,
  (retention) => {
    const sorted = retention.sort((a, b) => {
      if (moment(a.date).isAfter(moment(b.date))) {
        return -1;
      }
    });

    const arr = sorted.map((ret, i) => {
      let obj = {};
      Object.keys(ret).forEach((key) => {
        const value = ret[key];
        if (key === 'date') {
          obj = {
            ...obj,
            [key]: moment(value).format('YYYY.MM.DD'),
          };
        } else if (key !== 'date' && key !== 'userCount') {
          obj = {
            ...obj,
            [key]:
              value !== '-'
                ? `${Number(parseFloat(value) * 100).toFixed(2)}%`
                : value,
          };
        } else {
          obj = {
            ...obj,
            [key]: value,
          };
        }
      });
      return obj;
    });
    // console.log(arr);
    return arr;
  }
);

export const retentionCSVSelector = createSelector(
  (state) => state.retention.retention,
  (retention) => {
    const sorted = retention.sort((a, b) => {
      if (moment(a.date).isAfter(moment(b.date))) {
        return -1;
      }
    });

    const arr = sorted.map((ret, i) => {
      let _obj = { date: '', userCount: '' };
      //
      const datesArr = ['date', 'userCount', 1, 2, 3, 4, 5, 6, 7, 15, 30];
      const dates = Object.keys(ret);
      // console.log('dates', dates);

      datesArr.forEach((item, i) => {
        _obj = {
          ..._obj,
          [item]: ret[dates[i]],
        };
      });

      return _obj;
    });
    return arr;
  }
);

export const searchKeywordsSelector = createSelector(
  (state) => state.retention.searchKeywords,
  (searchKeywords) => searchKeywords
);

// 프로젝트 검색 결과가 2개 이상일 때 사용
export const projectsSelector = createSelector(
  (state) => state.retention.projects,
  (projects) => {
    if (projects) {
      return projects.map((pjt) => ({
        title: pjt.title,
        email: pjt.email,
        companyName: pjt.companyName,
        inDate: moment(pjt.inDate).format('YYYY.MM.DD HH:mm'),
        projectId: { noDisplay: true, value: pjt.projectId },
      }));
    }
  }
);
