import { call, put, takeLatest, select } from 'redux-saga/effects';

import * as selectors from './selector';
// import { LOCATION_CHANGE, push } from 'react-router-redux';

// import moment from 'moment';

import * as types from './constant';

import * as actions from './action';
import { openSubMenu, handleClickSubMenu } from '../../../../view/store/action';
import { LOCATION_CHANGE } from 'react-router-redux';

// import * as selectors from './selector';

import * as api from '../../../../service/API';
import moment from 'moment';

function* locationChange({ payload }) {
	try {
		const { pathname } = yield payload;
		const pathArray = pathname.split('/');
		// console.log(pathArray[1], "*** pathArray ***");
		if (pathArray[1] === 'sleepingCandidates') {
			yield put(openSubMenu('account'));
			yield put(handleClickSubMenu('sleepingCandidates'));
			yield put(actions.initSleepingCandidates());
		}
		if (pathArray[1] === 'sleepers') {
			yield put(openSubMenu('account'));
			yield put(handleClickSubMenu('sleepers'));
			yield put(actions.initSleepingAccounts());
		}
	} catch (err) {
		console.log('saga err', err);
	}
}

export function* watcherLocationChange() {
	yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* initSleepingCandidates() {
	try {
		yield put(actions.getSleepingCandidates());
	} catch (error) {}
}

export function* watcherInitSleepingCandidates() {
	yield takeLatest(types.INIT_SLEEPING_CANDIDATES, initSleepingCandidates);
}
function* initSleepingAccounts() {
	try {
		yield put(actions.getSleepingAccounts());
	} catch (error) {}
}

export function* watcherInitSleepingAccounts() {
	yield takeLatest(types.INIT_SLEEPING_ACCOUNTS, initSleepingAccounts);
}

function* getSleepingAccounts({ param, isSearching, isPaging }) {
	try {
		let searchKeywords = '';
		let searchingOffset = '';

		const offset = yield select(selectors.offsetSelector);
		let _date = { joinEndDate: '', joinStartDate: '' };

		if (isSearching) {
			searchKeywords = yield select(selectors.searchKeywordsSelector);
			const {
				joinStartDate,
				joinEndDate,
				sleeperDueStartDate,
				sleeperDueEndDate,
			} = searchKeywords;

			if (joinStartDate && joinEndDate) {
				_date = {
					joinEndDate: moment(joinEndDate)
						.set({
							hour: 23,
							minute: 59,
							second: 59,
						})
						.toISOString(),
					joinStartDate: moment(joinStartDate)
						.set({
							hour: 0,
						})
						.toISOString(),
				};
			}
			if (sleeperDueEndDate && sleeperDueStartDate) {
				_date = {
					..._date,
					sleeperDueEndDate: moment(sleeperDueEndDate)
						.set({
							hour: 23,
							minute: 59,
							second: 59,
						})
						.toISOString(),
					sleeperDueStartDate: moment(sleeperDueStartDate)
						.set({
							hour: 0,
						})
						.toISOString(),
				};
			}

			searchingOffset = yield select(selectors.searchingOffsetSelector);
		}

		const { data } = yield call(api.account.getSleepingAccounts, {
			...searchKeywords,
			..._date,
			offset: isSearching ? searchingOffset : offset,
		});
		yield put(
			actions.getSleepingCandidatesSuccess(data, isSearching, isPaging)
		);
	} catch (error) {
		console.log(error, 'GET SLEEPING CANDIDATES ERROR');
	}
}

export function* watcherGetSleepingAccounts() {
	yield takeLatest(types.GET_SLEEPING_ACCOUNTS, getSleepingAccounts);
}
function* getSleepingCandidates({ param, isSearching, isPaging }) {
	try {
		let searchKeywords;
		let searchingOffset = '';

		const offset = yield select(selectors.offsetSelector);
		let _date = { joinEndDate: '', joinStartDate: '' };

		if (isSearching) {
			searchKeywords = yield select(selectors.searchKeywordsSelector);
			const {
				joinStartDate,
				joinEndDate,
				sleeperDueStartDate,
				sleeperDueEndDate,
			} = searchKeywords;

			if (joinStartDate && joinEndDate) {
				_date = {
					joinEndDate: moment(joinEndDate)
						.set({
							hour: 23,
							minute: 59,
							second: 59,
							millisecond: 999,
						})
						.toISOString(),
					joinStartDate: moment(joinStartDate)
						.set({
							hour: 0,
						})
						.toISOString(),
				};
			}
			if (sleeperDueEndDate && sleeperDueStartDate) {
				_date = {
					..._date,
					sleeperDueEndDate: moment(sleeperDueEndDate)
						.set({
							hour: 23,
							minute: 59,
							second: 59,
							millisecond: 999,
						})
						.toISOString(),
					sleeperDueStartDate: moment(sleeperDueStartDate)
						.set({
							hour: 0,
						})
						.toISOString(),
				};
			}

			searchingOffset = yield select(selectors.searchingOffsetSelector);
		}

		const { data } = yield call(api.account.getSleepingCandidates, {
			...searchKeywords,
			..._date,
			offset: isSearching ? searchingOffset : offset,
		});
		yield put(
			actions.getSleepingCandidatesSuccess(data, isSearching, isPaging)
		);
	} catch (error) {
		console.log(error, 'GET SLEEPING CANDIDATES ERROR');
	}
}

export function* watcherGetSleepingCandidates() {
	yield takeLatest(types.GET_SLEEPING_CANDIDATES, getSleepingCandidates);
}

function* makeSleepingCandidateNot({ id }) {
	try {
		console.log(id);
		yield call(api.account.makeSleepingCandidateNot, id);
		yield put(actions.makeSleepingCandidateNotSuccess());
		yield put(actions.initSleepingCandidates());
	} catch (error) {
		console.log('MAKE_SLEEPING_CANDIDATE_NOT error', error);
	}
}

export function* watcherMakeSleepingCandidateNot() {
	yield takeLatest(types.MAKE_SLEEPING_CANDIDATE_NOT, makeSleepingCandidateNot);
}

function* makeSleepingAccountNot({ id }) {
	try {
		console.log(id);
		yield call(api.account.makeSleepingAccountNot, id);
		yield put(actions.makeSleepingAccountNotSuccess());
		yield put(actions.initSleepingAccounts());
	} catch (error) {
		console.log('MAKE_SLEEPING_ACCOUNT_NOT error', error);
	}
}

export function* watcherMakeSleepingAccountNot() {
	yield takeLatest(types.MAKE_SLEEPING_ACCOUNT_NOT, makeSleepingAccountNot);
}
