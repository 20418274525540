import React, { useState } from 'react';
import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import ButtonsGroup from '../../../fnComponents/buttonGroup';
import DataTable from '../../../fnComponents/_DataTable';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import DateRangePicker from '../../../fnComponents/DateRangePicker';

import ButtonDropdown from '../../../fnComponents/ButtonDropdown';

import * as selectors from '../store/selector';
import * as actions from '../store/action';

const DeleteAccount = ({
  // state prop
  deletedAccountList,
  deletedAccountListForView,
  searchedDelAccountList,
  actualSearchedDelAccountListSelector,
  columns,
  LastEvaluatedKey,
  sortingParam,
  // func prop
  onGetSearchItem,
  onSearchDelAccount,
  onSortAscNormalAccounts,
  onSortDescNormalAccounts,
  onGetDeletedAccountList,
}) => {
  const initInputObj = {
    id: '',
    company: '',
    signUpDate: '',
    lastLoginDate: '',
    status: '',
    init: false,
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState('');
  const [inputObj, handleInput] = useState(initInputObj);
  const [focusByIcon, setFocus] = useState(null);

  const dropdownItems = [
    { name: '전체' },
    {
      name: '일반탈퇴',
    },
    {
      name: '강제탈퇴',
    },
  ];

  const handleGetDeletedAccountList = () => {
    // 더보기 이벤트일 때, 추가 param으로 redux store에서 분기처리할 수 있도록 한다
    const param = 'getMore';
    onGetDeletedAccountList(param);
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        />
        <LeftAsideMunu />
        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title m-subheader__title--separator">
                  탈퇴 계정
                </h3>
                <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
                  <li className="m-nav__item m-nav__item--home">
                    <a href="dummy" className="m-nav__link m-nav__link--icon">
                      <i className="m-nav__link-icon la la-home" />
                    </a>
                  </li>
                  <li className="m-nav__separator">-</li>
                  <li className="m-nav__item">
                    <a href="dummy" className="m-nav__link">
                      <span className="m-nav__link-text">계정 관리</span>
                    </a>
                  </li>
                  <li className="m-nav__separator">-</li>
                  <li className="m-nav__item">
                    <a href="dummy" className="m-nav__link">
                      <span className="m-nav__link-text">탈퇴 계정</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <form className="m-form m-form--fit m--margin-bottom-20">
                      <div className="row m--margin-bottom-20">
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>아이디</label>
                          <input
                            onKeyUp={(e) => {
                              if (e.key === 'Enter') onSearchDelAccount();
                            }}
                            type="text"
                            className="form-control m-input"
                            placeholder=""
                            data-col-index="0"
                            onChange={(e) =>
                              onGetSearchItem({
                                email: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>탈퇴일</label>
                          <div
                            className="input-group pull-right"
                            id="m_daterangepicker1"
                          >
                            <DateRangePicker
                              startDate={startDate}
                              endDate={endDate}
                              focusedInput={focusedInput}
                              setFocusedInput={setFocusedInput}
                              setStartDate={setStartDate}
                              setEndDate={setEndDate}
                              initInputObj={initInputObj}
                              initSearchFilter={inputObj.init}
                              searchParam="withdrawalDate"
                              setFocus={setFocus}
                              focusByIcon={
                                focusByIcon && focusByIcon.singleDatePicker1
                              }
                              onGetSearchItem={onGetSearchItem}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>구분</label>
                          <ButtonDropdown
                            initItem="전체"
                            dropdownItems={dropdownItems}
                            onChangeBtnDropdown={onGetSearchItem}
                          />
                        </div>
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>관리자</label>
                          <input
                            onKeyUp={(e) => {
                              if (e.key === 'Enter') onSearchDelAccount();
                            }}
                            type="text"
                            className="form-control m-input"
                            placeholder=""
                            data-col-index="3"
                            onChange={(e) =>
                              onGetSearchItem({
                                admin: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="row m--margin-bottom-20">
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>사유</label>
                          <input
                            onKeyUp={(e) => {
                              if (e.key === 'Enter') onSearchDelAccount();
                            }}
                            type="text"
                            className="form-control m-input"
                            placeholder=""
                            data-col-index="4"
                            onChange={(e) =>
                              onGetSearchItem({ reason: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              onSearchDelAccount();
                            }}
                            className="btn btn-brand m-btn m-btn--icon"
                            id="m_search"
                          >
                            <span>
                              <i className="la la-search" />
                              <span>검색</span>
                            </span>
                          </button>
                          &nbsp;&nbsp;
                          <button
                            className="btn btn-secondary m-btn m-btn--icon"
                            id="m_reset"
                          >
                            <span>
                              <i className="la la-close" />
                              <span>초기화</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </form>
                    <ButtonsGroup />
                    {searchedDelAccountList ? (
                      <DataTable
                        isLast={!LastEvaluatedKey}
                        requestAction={handleGetDeletedAccountList}
                        sortingParam={sortingParam}
                        customClassName={
                          searchedDelAccountList.length > 13
                            ? 'table-scrollable column-5 leave-account-table'
                            : 'column-5 leave-account-table'
                        }
                        isSearched={!!searchedDelAccountList}
                        tableType="deletedAccount"
                        columns={[
                          { label: '아이디', field: '' },
                          { label: '탈퇴일시', field: 'inDate' },
                          { label: '구분', field: '' },
                          { label: '관리자', field: '' },
                          { label: '사유', field: '' },
                        ]}
                        rows={searchedDelAccountList}
                        onSortAsc={onSortAscNormalAccounts}
                        onSortDesc={onSortDescNormalAccounts}
                        sortingColumns={['탈퇴일시']}
                        actualData={actualSearchedDelAccountListSelector}
                      />
                    ) : (
                      <DataTable
                        isLast={!LastEvaluatedKey}
                        requestAction={handleGetDeletedAccountList}
                        sortingParam={sortingParam}
                        customClassName={
                          deletedAccountListForView &&
                          deletedAccountListForView.length > 13
                            ? 'table-scrollable column-5 leave-account-table'
                            : 'column-5 leave-account-table'
                        }
                        tableType="deletedAccount"
                        columns={[
                          { label: '아이디', field: '' },
                          { label: '탈퇴일시', field: 'inDate' },
                          { label: '구분', field: '' },
                          { label: '관리자', field: '' },
                          { label: '사유', field: '' },
                        ]}
                        rows={deletedAccountListForView}
                        onSortAsc={onSortAscNormalAccounts}
                        onSortDesc={onSortDescNormalAccounts}
                        sortingColumns={['탈퇴일시']}
                        actualData={deletedAccountList}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  deletedAccountListForView: selectors.deletedAccountListSelectorForView,
  deletedAccountList: selectors.deletedAccountListSelector,
  searchedDelAccountList: selectors.searchedDelAccountListSelector,
  actualSearchedDelAccountListSelector:
    selectors.actualSearchedDelAccountListSelector,
  sortingParam: selectors.sortingParamSelector,
  LastEvaluatedKey: selectors.LastEvaluatedKeySelector,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSearchItem: (obj) => dispatch(actions.getSearchItem(obj)),
  onGetDeletedAccountList: (obj) =>
    dispatch(actions.getDeletedAccountList(obj)),
  onSearchDelAccount: () => dispatch(actions.searchDelAccount()),
  onSortAscNormalAccounts: (rows, isSearched, tableType) =>
    dispatch(actions.sortAscNormalAccounts(rows, isSearched, tableType)),
  onSortDescNormalAccounts: (rows, isSearched, tableType) =>
    dispatch(actions.sortDescNormalAccounts(rows, isSearched, tableType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);
