// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { createSelector } from 'reselect';
import clone from 'ramda/src/clone';
import moment from 'moment';

export const clickedIdSelector = createSelector(
  (state) => state.projectManaging.detail,
  (detail) => detail
);

export const columnsSelector = createSelector(
  (state) => state.sleeping,
  ({ columns, isSleepingAccounts }) => {
    if (isSleepingAccounts) {
      let _columns = clone(columns);

      _columns[4] = {
        label: '휴면전환일',
        field: 'sleeperDue',
        sort: 'asc',
        width: 150,
      };
      return _columns;
    } else return columns;
  }
);

export const sleepingCandidatesListSelectorForView = createSelector(
  (state) => state.sleeping,
  ({ sleepingCandidatesList, isSleepingAccounts }) => {
    if (isSleepingAccounts) {
      return sleepingCandidatesList.accountList.map((account) => ({
        id: {
          noDisplay: true,
          id: account.id,
        },
        email: account.email,
        companyName: account.companyName,
        inDate: moment(account.inDate).format('YYYY.MM.DD'),
        latestConnect:
          account.latestConnect === '-'
            ? '-'
            : moment(account.latestConnect).format('YYYY.MM.DD'),
        sleeperInDate: moment(account.sleeperInDate).format('YYYY.MM.DD'),
        makeSleepingCandidates: '휴면 해제',
      }));
    }
    return sleepingCandidatesList.accountList.map((account) => ({
      id: {
        noDisplay: true,
        id: account.id,
      },
      email: account.email,
      companyName: account.companyName,
      inDate: moment(account.inDate).format('YYYY.MM.DD'),
      latestConnect:
        account.latestConnect === '-'
          ? '-'
          : moment(account.latestConnect).format('YYYY.MM.DD'),
      sleeperDue: moment(account.sleeperDue).format('YYYY.MM.DD'),
      makeSleepingCandidates: '휴면 예정 해제',
    }));
  }
);
export const sleepingCandidatesListSelector = createSelector(
  (state) => state.sleeping.sleepingCandidatesList,
  (sleepingCandidatesList) => {
    return sleepingCandidatesList.accountList.map((account) => ({
      email: account.email,
      companyName: account.companyName,
      inDate: moment(account.inDate),
      latestConnect:
        account.latestConnect === '-' ? '-' : moment(account.latestConnect),
      sleeperDue: moment(account.sleeperDue),
    }));
  }
);

export const sortingParamSelector = createSelector(
  (state) => state.sleeping.sortingParam,
  (sortingParam) => {
    console.log(sortingParam);
    return sortingParam;
  }
);

export const offsetSelector = createSelector(
  (state) => state.sleeping.offset,
  (offset) => offset
);
export const searchingOffsetSelector = createSelector(
  (state) => state.sleeping.searchingOffset,
  (searchingOffset) => searchingOffset
);

export const searchKeywordsSelector = createSelector(
  (state) => state.sleeping.searchKeywords,
  (searchKeywords) => searchKeywords
);

export const isSleepingAccountsSelector = createSelector(
  (state) => state.sleeping.isSleepingAccounts,
  (isSleepingAccounts) => isSleepingAccounts
);

export const isLastSelector = createSelector(
  (state) => state.sleeping.isLast,
  (isLast) => isLast
);
