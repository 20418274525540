import React, { useState, useEffect } from 'react';
import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import ButtonsGroup from '../../../fnComponents/buttonGroup';
import DataTable from '../../../fnComponents/_DataTable';

import SearchForm from '../../../fnComponents/SearchForm';
import moment from 'moment';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from 'reactstrap';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from './store/action';
import * as selectors from './store/selector';

const CurrentCharge = ({
  columns,
  accountList,
  overdueBill,
  searchKeywords,
  //
  onClickChargedBillRow,
  onGetBackToMainTable,
  onGetOverdueBill,
  onInitOverdueBill,
  onGetSearchKeywords,
}) => {
  useEffect(() => {
    const { date } = searchKeywords;

    if (date) {
      const billDate = moment(date, 'YYYY-MM').format('YYYY.MM');
      handleInput({
        ...userInput,
        billDate,
      });
    }
  }, [searchKeywords]);

  const [userInput, handleInput] = useState({ payMethod: 'etc' });

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />
        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title m-subheader__title--separator">
                  결제 관리
                </h3>
                <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
                  <li className="m-nav__item m-nav__item--home">
                    <a href="dummy" className="m-nav__link m-nav__link--icon">
                      <i className="m-nav__link-icon la la-home" />
                    </a>
                  </li>
                  <li className="m-nav__separator">-</li>
                  <li className="m-nav__item">
                    <a href="dummy" className="m-nav__link">
                      <span className="m-nav__link-text">결제 관리</span>
                    </a>
                  </li>
                  <li className="m-nav__separator">-</li>
                  <li className="m-nav__item">
                    <a href="dummy" className="m-nav__link">
                      <span className="m-nav__link-text">현재 요금</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <SearchForm
                      keywords={['아이디', '회사명', '연체발생일', '처리상태']}
                      mainlyRequiredAction={onGetOverdueBill}
                      initAction={onInitOverdueBill}
                      onGetSearchKeywords={onGetSearchKeywords}
                      selectOpts={{
                        // billDate: {
                        //   name: "청구년월",
                        //   options: makeBillDate()
                        // },
                        status: {
                          name: '처리상태',
                          options: [
                            { name: '전체' },
                            { name: '연체중' },
                            { name: '납부완료' },
                          ],
                        },
                      }}
                    />
                    <ButtonsGroup />
                    <DataTable
                      requiredAction={onClickChargedBillRow}
                      rows={overdueBill}
                      columns={columns}
                      // isLast={isLast}
                      sortingColumns={[
                        '연체요금(원)',
                        '연체발생일',
                        '연체해제일',
                      ]}
                      // requestAction={onGetProjectList}
                      // onSortAsc={onSortAscProjectList}
                      // onSortDesc={onSortDescProjectList}
                      // sortingParam={sortingParam}
                      // secondRequiredAction={onClickProjectRow}
                      // actualData={originalProjectList}
                      customClassName={
                        overdueBill.length > 13
                          ? //   false
                            'table-scrollable overdue-bill-table'
                          : 'column-6 overdue-bill-table'
                      }
                      clickableIndex={[null]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  columns: selectors.columnsSelector,
  detail: selectors.clickedIdSelector,

  searchKeywords: selectors.searchKeywordsSelector,
  //   overdue bill
  overdueBill: selectors.overdueBillSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onClickChargedBillRow: (id, arr) =>
    dispatch(actions.clickChargedBillRow(id, arr)),
  onGetBackToMainTable: () => dispatch(actions.getBackToMainTable()),
  onGetSearchKeywords: (obj) => dispatch(actions.getSearchKeywords(obj)),
  onGetOverdueBill: () => dispatch(actions.getOverdueBill()),
  onInitOverdueBill: () => dispatch(actions.initOverdueBill()),
  //   overdue bill success
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentCharge);
