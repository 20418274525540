import * as types from './constant';

export function getCsvData(name) {
	return {
		type: types.GET_CSV_DATA,
		name,
	};
}

export function getCsvDataSuccess(payload) {
	return {
		type: types.GET_CSV_DATA_SUCCESS,
		payload,
	};
}
