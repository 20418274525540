// 공통모듈
import React, { useState, useEffect } from 'react';
import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';

import DataTable from '../../../fnComponents/_DataTable';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as actions from './store/action';
import * as selectors from './store/selector';

import moment from 'moment';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ImageUploadBox from '../../../fnComponents/ImageUploadBox';
import MsgModal from '../../../fnComponents/MsgModal';

const Showcase = ({
  popupList,
  popup,
  insertDone,
  lastEvaluatedKey,
  popupInDate,
  errorsInSaga,
  onGetPopupList,
  onGetPopup,
  onDeletePopup,
  onInsertPopup,
  onUpdatePopup,
  onUpdatePopupSuccess,
}) => {
  const [isPopupModalOpen, handleOpen] = useState(false);
  const [isMsgModalOpen, handleMsgModal] = useState(false);

  const initInputObj = {
    id: '',
    company: '',
    signUpDate: '',
    lastLoginDate: '',
    status: '',
    init: false,
  };

  const [startDate, setStartDate] = useState(
    moment().format('YYYY-MM-DDTHH:mm')
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DDTHH:mm'));

  const [errors, handleErrors] = useState({
    title: null,
    date: null,
  });

  const [inputs, handleInputs] = useState({
    title: null,
    linkURI: null,
  });

  const [radioBtn, handleRadio] = useState({
    linkOpen: 'c',
    cookieDuration: '1',
  });
  const [imgSrcFromLocal, handleImg] = useState('');
  const [inDateToDelete, setInDateToDelete] = useState('');

  useEffect(() => {
    if (popup) {
      handleInputs({
        title: popup.title,
        linkURI: popup.linkURI,
      });
      handleRadio({
        linkOpen: popup.linkOpen,
        cookieDuration: popup.cookieDuration,
      });
      handleImg({ image: popup.image });
      setStartDate(popup.startDate);
      setEndDate(popup.endDate);
      handleOpen(true);
    }
  }, [popup]);

  useEffect(() => {
    if (insertDone) {
      handleOpen(false);
      handleInputs({
        title: null,
        linkURI: null,
      });
      handleImg('');
      handleRadio({ linkOpen: 'c', cookieDuration: '1' });
      setEndDate(moment().format('YYYY-MM-DDTHH:mm'));
      setStartDate(moment().format('YYYY-MM-DDTHH:mm'));
    }
  }, [insertDone]);

  const [imgBoxFocusedOut, setImgBoxFocusedOut] = useState(false);

  const handleInputChange = (e) => {
    handleInputs({
      ...inputs,
      [e.target.id]: e.target.value,
    });
  };

  const handleImage = (image) => {
    handleImg({ image });
  };

  const handleRadioBtnChange = (e) => {
    handleRadio({
      ...radioBtn,
      [e.target.name]: e.target.id,
    });
  };

  useEffect(() => {
    console.log('useEffect', errorsInSaga);
    if (errorsInSaga) handleErrors({ ...errors, ...errorsInSaga });
  }, [errorsInSaga]);

  const handlePostPopup = async () => {
    const { title, linkURI } = inputs;
    const { image } = imgSrcFromLocal;
    const imgSize = document.getElementById('myImg');

    const errors = {
      title: null,
      date: null,
      image: null,
    };
    let regex =
      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    const urlTest = regex.test(linkURI);

    if (!title) errors.title = '팝업명을 입력해주세요.';
    if (!startDate) errors.date = '기간을 선택해주세요.';
    // if(!startDate) errors.date = '기간을 선택해주세요.'
    if (!image) errors.image = '이미지를 등록해주세요.';
    if (linkURI && !urlTest) errors.linkURI = 'URL 형식이 올바르지 않습니다.';
    const isErr = Object.values(errors).filter((err) => !!err).length;
    if (isErr) return handleErrors(errors);
    else console.log('정상');

    if (popup) {
      // updateShowcase
      // console.log(showcase.image === image)
      // console.log(inputs)

      onUpdatePopup(
        popupInDate,
        popup.image === image
          ? {
              ...inputs,
              ...radioBtn,
              startDate,
              endDate,
              imageWidth: imgSize.naturalWidth,
              imageHeight: imgSize.naturalHeight,
            }
          : {
              ...inputs,
              ...radioBtn,
              image,
              startDate,
              endDate,
              imageWidth: imgSize.naturalWidth,
              imageHeight: imgSize.naturalHeight,
            }
      );

      //   handleOpen(false);
      //   handleInputs({
      //     title: null,
      //     linkURI: null,
      //   });
      //   handleImg("");
      //   handleRadio({ linkOpen: "c", cookieDuration: "1" });
      //   setEndDate(null);
      //   setStartDate(null);
      return;
    }
    await onInsertPopup({
      ...inputs,
      startDate,
      endDate,
      image,
      ...radioBtn,
      imageWidth: imgSize.naturalWidth,
      imageHeight: imgSize.naturalHeight,
    });

    // 사용자 입력값 등 초기화 로직 --> useEffect로 대체
    // handleOpen(false);
    // handleInputs({
    //   title: null,
    //   linkURI: null,
    // });
    // handleImg("");
    // handleRadio({ linkOpen: "c", cookieDuration: "1" });
    // setEndDate(null);
    // setStartDate(null);
  };

  const onHandleCloseMsgModal = () => {
    handleMsgModal(false);
  };

  const handlePopupModal = (e) => {
    if (e.target.id === 'open') return handleOpen(true);
    else {
      onUpdatePopupSuccess();
      handleOpen(false);
      handleInputs({
        title: null,
        linkURI: null,
      });
      handleImg('');
      handleErrors({
        title: null,
        date: null,
        image: null,
      });
      handleRadio({ linkOpen: 'c', cookieDuration: '1' });
      setEndDate(null);
      setStartDate(null);
    }
  };

  const handleImgUpload = async (fileChooser) => {
    const data = new FormData();
    data.append('imageType', 'project');
    data.append('uploadImage', fileChooser.files[0]);

    // const { onUploadProjectImg } = this.props;
    // onUploadProjectImg(data);
  };

  const handleDeletePopup = (a, arr, c, d) => {
    console.log(arr);
    const { inDate } = arr[2]._inDate;
    handleMsgModal(true);
    setInDateToDelete(inDate);
  };

  const deletePopup = () => {
    console.log(inDateToDelete, 'in deletePopup func');
    onDeletePopup(inDateToDelete);
    handleMsgModal(false);
  };

  const handleDateTimeChange = (e) => {
    console.log(e.target.value);
    if (e.target.id.includes('startDate')) setStartDate(e.target.value);
    if (e.target.id.includes('endDate')) setEndDate(e.target.value);
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title ">팝업 관리</h3>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <Modal isOpen={isPopupModalOpen} size="lg">
                      <ModalHeader>팝업 등록</ModalHeader>
                      <ModalBody>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="title"
                            className="col-lg-2 col-form-label"
                          >
                            * 팝업명
                          </label>
                          <div className="col-lg-10">
                            <input
                              onChange={handleInputChange}
                              type="text"
                              className="form-control"
                              id="title"
                              required
                              value={inputs.title}
                            />
                            <div className="error message visible">
                              {errors.title}
                            </div>
                          </div>
                        </div>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="title"
                            className="col-lg-2 col-form-label"
                          >
                            * 시작/종료 시간
                          </label>
                          <div className="col-lg-10">
                            <input
                              onChange={handleDateTimeChange}
                              type="datetime-local"
                              className="form-control birthdaytime birthdaytime-mr-10"
                              id="birthdaytime-startDate"
                              name="birthdaytime"
                              value={startDate}
                            />
                            <input
                              onChange={handleDateTimeChange}
                              type="datetime-local"
                              className="form-control birthdaytime"
                              id="birthdaytime-endDate"
                              name="birthdaytime"
                              value={endDate}
                            />
                            <div className="error message visible">
                              {errors.duplicated}
                            </div>
                          </div>
                        </div>
                        <div className="form-group m-form__group row mt-30 popupImg">
                          <label
                            htmlFor="image"
                            className="col-lg-2 col-form-label"
                          >
                            * 이미지
                          </label>
                          <ImageUploadBox
                            imgSize
                            handleImage={handleImage}
                            onUpload={handleImgUpload}
                            src={imgSrcFromLocal.image || inputs.image}
                            // isLoading={this.props.isLoading}
                            // onDelete={this.props.onDeleteProjectImg}
                            error={errors.image}
                          />
                        </div>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="linkURI"
                            className="col-lg-2 col-form-label"
                          >
                            URL
                          </label>
                          <div className="col-lg-10">
                            <input
                              onFocus={() => {
                                setImgBoxFocusedOut(true);
                              }}
                              onChange={handleInputChange}
                              className="form-control"
                              id="linkURI"
                              required
                              value={inputs.linkURI}
                              type="url"
                              imgBoxFocusedOut={imgBoxFocusedOut}
                            />
                            <div className="error message visible">
                              {errors.linkURI}
                            </div>
                          </div>
                        </div>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="linkURI"
                            className="col-lg-2 col-form-label"
                          >
                            {' '}
                          </label>
                          <div className="col-lg-10">
                            <div className="m-radio-inline">
                              <label className="m-radio">
                                <input
                                  id="c"
                                  onChange={handleRadioBtnChange}
                                  type="radio"
                                  name="linkOpen"
                                  checked={radioBtn.linkOpen === 'c'}
                                />
                                현재창
                                <span />
                              </label>
                              <label className="m-radio">
                                <input
                                  id="n"
                                  onChange={handleRadioBtnChange}
                                  type="radio"
                                  name="linkOpen"
                                  checked={radioBtn.linkOpen === 'n'}
                                />
                                새창
                                <span />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            for="statusReason"
                            className="col-lg-2 col-form-label"
                          >
                            쿠키
                          </label>
                          <div className="col-lg-10">
                            <div className="m-radio-inline">
                              <label className="m-radio">
                                <input
                                  id="1"
                                  onChange={handleRadioBtnChange}
                                  type="radio"
                                  name="cookieDuration"
                                  checked={radioBtn.cookieDuration === '1'}
                                />
                                1일
                                <span />
                              </label>
                              <label className="m-radio">
                                <input
                                  id="3"
                                  onChange={handleRadioBtnChange}
                                  type="radio"
                                  name="cookieDuration"
                                  checked={radioBtn.cookieDuration === '3'}
                                />
                                3일
                                <span />
                              </label>
                              <label className="m-radio">
                                <input
                                  id="7"
                                  onChange={handleRadioBtnChange}
                                  type="radio"
                                  name="cookieDuration"
                                  checked={radioBtn.cookieDuration === '7'}
                                />
                                7일
                                <span />
                              </label>
                            </div>

                            {/* <div className="error message visible">{errMsg && errMsg}</div> */}
                          </div>
                        </div>
                        {/* </div> */}
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={handlePostPopup}>
                          확인
                        </Button>
                        <Button
                          id="close"
                          color="primary"
                          onClick={handlePopupModal}
                        >
                          취소
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <Button id="open" onClick={handlePopupModal}>
                      등록
                    </Button>
                    <DataTable
                      isLast={!lastEvaluatedKey}
                      requestAction={onGetPopupList}
                      deleteRow
                      requiredAction={onGetPopup}
                      secondRequiredAction={handleDeletePopup}
                      rows={popupList}
                      columns={[
                        { label: '팝업명' },
                        { label: '등록일시', field: 'creditPrice' },
                        { label: '기간', field: 'numberOfCoupon' },
                        { label: '상태', field: 'numberOfUsed' },
                        { label: '' },
                      ]}
                      customClassName={
                        popupList.length > 13
                          ? 'popupList-table column-5 table-scrollable'
                          : 'popupList-table column-5'
                      }
                      clickableIndex={[0]}
                    />
                    <MsgModal
                      content="삭제하시겠습니까?"
                      isMsgModalOpen={isMsgModalOpen}
                      onHandleCloseMsgModal={onHandleCloseMsgModal}
                      onHandleDelete={deletePopup}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  popupList: selectors.popupListSelectorForView,
  popup: selectors.popupSelector,
  insertDone: selectors.insertDoneSelector,
  lastEvaluatedKey: selectors.lastEvaluatedKeySelector,
  popupInDate: selectors.popupInDateSelector,
  errorsInSaga: selectors.errorsSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPopupList: (title, arr) => dispatch(actions.getPopupList(title, arr)),
  onGetPopup: (title, arr) => dispatch(actions.getPopup(title, arr)),
  onDeletePopup: (title, arr) => dispatch(actions.deletePopup(title, arr)),

  onInsertPopup: (obj) => dispatch(actions.insertPopup(obj)),
  onUpdatePopup: (inDate, data) => dispatch(actions.updatePopup(inDate, data)),
  onUpdatePopupSuccess: () => dispatch(actions.updatePopupSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Showcase);
