import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Table,
	Pagination,
	PaginationItem,
	PaginationLink,
} from 'reactstrap';

import moment from 'moment';
import Octicon, { X } from '@primer/octicons-react';
import DataTable from '../../fnComponents/_DataTable';

import Header from '../../view/header';
import LeftAsideMunu from '../../view/leftAsideMenu';

import * as actions from './store/action';
import * as selectors from './store/selector';
import MsgModal from '../../fnComponents/MsgModal';
import ButtonsGroup from '../../fnComponents/radioButtonGroup';

const Emails = ({
	// state props
	columns,
	detail,
	isLast,
	isAdmin,
	sentMailDetails,
	emailListForView,
	emailList,
	selectedInDate,
	deleteSentMailSuccess,
	// action props
	handleIsAdmin,
	onGetSentMails,
	onGetSentMailDetails,
	onDeleteSentMail,
	onSearchSentMailDetails,
}) => {
	useEffect(() => {
		if (deleteSentMailSuccess) {
			setShouldDeleteSentMail({
				shouldMsgModalOpen: false,
				whichSentMail: '',
				msgModalContent: '',
			});
		}
	}, [deleteSentMailSuccess]);

	const handleNextPage = () => {
		const { _lastEvaluatedKey, page } = sentMailDetails;
		// console.log('##details', _lastEvaluatedKey[page]);
		onGetSentMailDetails(
			selectedInDate,
			{
				lastEvaluatedKey: _lastEvaluatedKey[page],
			},
			{ next: true }
		);
	};
	const handlePrevPage = () => {
		const { _lastEvaluatedKey, page } = sentMailDetails;
		// console.log('##details', _lastEvaluatedKey[page - 2]);
		onGetSentMailDetails(
			selectedInDate,
			{
				lastEvaluatedKey: _lastEvaluatedKey[page - 2],
			},
			{ prev: true }
		);
	};

	const [searchKeyword, setSearchKeyword] = useState('');

	const handleInput = (e) => {
		setSearchKeyword(e.target.value);
	};

	const handleSearch = () => {
		// e.target.value
		// 최초 검색 결과에서 LastKey가 리턴되면 검색어+lastKey로 쿼리 가능해야 함
		// 검색 시 기존 lastKey 객체 데이터를 지우고 거기에 lastKey 할당
		onSearchSentMailDetails(selectedInDate, { email: searchKeyword });
	};

	useEffect(() => {
		const { details } = sentMailDetails;
		if (Object.keys(details).length) {
			setMailDetail({
				title: '발송 대상 확인',
				shouldOpenDetailModal: true,
			});
		}
	}, [sentMailDetails]);

	const [_emailList, set_emailList] = useState([]);
	const [shouldDeleteSentMail, setShouldDeleteSentMail] = useState({
		shouldMsgModalOpen: false,
		whichSentMail: '',
		msgModalContent: '',
	});

	useEffect(() => {
		if (emailListForView && emailListForView.length) {
			const _emailList = emailListForView.map((email) => ({
				...email,
				deleteBtn: <Octicon verticalAlign="middle" icon={X} />,
			}));
			set_emailList(_emailList);
		}
	}, [emailListForView]);

	const [mailDetail, setMailDetail] = useState({
		title: '',
		contents: '',
		shouldOpenDetailModal: false,
		attachments: [],
	});

	const handleGetSentMails = () => {
		onGetSentMails(isLast, isAdmin);
	};

	const handleGetSentMailDetails = (arr) => {
		// 발송대상 상세 모달
		let param = '';
		arr.forEach((obj) => {
			const keyName = Object.keys(obj)[0];
			if (keyName === 'param') {
				param = obj[keyName];
			}
		});
		// console.log('param', param);
		const { inDate } = param;
		onGetSentMailDetails(inDate);
	};

	const handleSentMailDetails = (arr) => {
		// 메일내용 상세 모달
		let param = '';
		arr.forEach((obj) => {
			const keyName = Object.keys(obj)[0];
			if (keyName === 'param') {
				param = obj[keyName];
			}
		});
		// console.log('param', param);
		// console.log('emailList', emailList);

		const found = emailList.find((email) => email.inDate === param.inDate);

		// console.log('found', found);
		if (found) {
			const { title, contents, attachments } = found;
			setMailDetail({
				title,
				contents,
				attachments: attachments || [],
				shouldOpenDetailModal: true,
			});
		}
		// onGetSentMailDetails()
	};

	const handleCloseMsgModal = () => {
		setShouldDeleteSentMail({
			shouldMsgModalOpen: false,
			whichSentMail: '',
			msgModalContent: '',
		});
	};

	const handleDeleteSentMail = (arr) => {
		let isSent = '';
		let param = '';
		arr.forEach((obj) => {
			const keyName = Object.keys(obj)[0];
			if (keyName === 'isSent') {
				isSent = obj[keyName];
			}
			if (keyName === 'param') {
				param = obj[keyName];
			}
		});
		// console.log('isSent', isSent);
		setShouldDeleteSentMail({
			whichSentMail: isSent,
			msgModalContent:
				isSent === '발송완료'
					? '발송 내역을 삭제 하시겠습니까?'
					: '예약 발송을 취소 하시겠습니까?',
			shouldMsgModalOpen: true,
			toDeleteSentEmail: param.inDate,
		});
	};

	const handleOnDeleteSentMail = () => {
		onDeleteSentMail(shouldDeleteSentMail.toDeleteSentEmail);
	};

	const { details, page } = sentMailDetails;

	return (
		<div className="m-grid m-grid--hor m-grid--root m-page">
			<Header />
			<div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
				<button
					type="button"
					className="m-aside-left-close m-aside-left-close--skin-dark"
					id="m_aside_left_close_btn">
					<i className="la la-close" />
				</button>
				<LeftAsideMunu />
				{/* 삭제 확인 모달 */}
				<MsgModal
					header={shouldDeleteSentMail.msgModalContent}
					isMsgModalOpen={shouldDeleteSentMail.shouldMsgModalOpen}
					onHandleCloseMsgModal={handleCloseMsgModal}
					confirmOrCancel
					onConfirmAction={handleOnDeleteSentMail}
					contentsObject
				/>
				{/* 메일 내용 및 발송대상 내역 확인 모달 */}
				<Modal
					className="sentMailDetail"
					centered
					size="lg"
					isOpen={mailDetail.shouldOpenDetailModal}>
					<ModalHeader>{mailDetail.title}</ModalHeader>
					<ModalBody>
						<div>
							{mailDetail.attachments
								? mailDetail.attachments.map((file, i) => (
										<a href={file.s3}>
											{i + 1}. {file.key}
										</a>
								  ))
								: null}
						</div>
						{mailDetail.title === '발송 대상 확인' ? (
							<>
								<div className="form-group m-form__group row">
									<div className="col-lg-11">
										<input
											onChange={handleInput}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													handleSearch();
												}
											}}
											type="email"
											className="form-control"
											id="email"
											required
											value={searchKeyword}
										/>
										<div className="error message visible">
											검색어를 입력해주세요.
										</div>
									</div>
									<Button color="primary" onClick={handleSearch}>
										검색
									</Button>
								</div>
								<Table className="sentMailDetail">
									<thead className="table-header-group">
										<tr>
											<th>발송대상</th>
											<th>수신 상태</th>
											<th>발송 상태</th>
										</tr>
									</thead>
									<tbody>
										{details[page]
											? details[page].map((item) => (
													<tr key={item.inDate}>
														<td>{item.email}</td>
														<td>
															{item.isRead
																? moment(item.isRead).format('YYYY.MM.DD HH:mm')
																: '읽지 않음'}
														</td>
														<td>{item.isSent ? '성공' : '실패'}</td>
													</tr>
											  ))
											: null}
									</tbody>
									<tfoot>
										<tr />
									</tfoot>
								</Table>
								<Pagination aria-label="Page navigation">
									<PaginationItem>
										<PaginationLink onClick={handlePrevPage} previous />
									</PaginationItem>
									<PaginationItem>
										<PaginationLink>{sentMailDetails.page}</PaginationLink>
									</PaginationItem>
									<PaginationItem>
										<PaginationLink onClick={handleNextPage} next />
									</PaginationItem>
								</Pagination>
							</>
						) : (
							<div
								dangerouslySetInnerHTML={{
									__html: mailDetail.contents,
								}}
							/>
						)}
					</ModalBody>
					<ModalFooter>
						<Button
							color="primary"
							onClick={() => {
								setMailDetail({
									title: '',
									contents: '',
									shouldOpenDetailModal: false,
									attachments: [],
								});
							}}>
							확인
						</Button>
					</ModalFooter>
				</Modal>
				<div className="m-grid__item m-grid__item--fluid m-wrapper">
					<div className="m-subheader">
						<div className="d-flex align-items-center">
							<div className="mr-auto">
								<h3 className="m-subheader__title">메일 관리</h3>
							</div>
						</div>
					</div>
					<div className="m-content">
						<div className="row">
							<div className="col-md-12">
								<div className="m-portlet">
									<div className="m-portlet__body">
										<div className="row">
											<div className="col-lg-12">
												<ButtonsGroup
													handleIsAdmin={handleIsAdmin}
													isAdmin={isAdmin}
													leftRdBtn="직접발송"
													rightRdBtn="자동발송"
												/>
											</div>
										</div>
										{/* {console.log('&&*', _emailList)} */}
										<DataTable
											isLast={!isLast}
											customClassName={
												_emailList.length > 13
													? 'column-6 sentMails'
													: 'column-6'
											}
											indexMethodArr={
												isAdmin
													? [
															{ index: 0, method: handleSentMailDetails },
															{ index: 1, method: handleGetSentMailDetails },
															{
																index: 6,
																method: handleDeleteSentMail,
															},
													  ]
													: []
											}
											clickableIndex={isAdmin ? [0, 1] : []}
											requestAction={handleGetSentMails}
											// requiredAction={onGetSentMailDetails}
											// secondRequiredAction={handleDeleteSentMail}
											rows={_emailList}
											columns={columns}
											shouldNotScroll
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	columns: selectors.columnsSelector,
	detail: selectors.clickedIdSelector,
	emailList: selectors.emailListSelector,
	isLast: selectors.lastEvaluatedKeySelector,
	isAdmin: selectors.isAdminSelector,
	emailListForView: selectors.emailListForViewSelector,
	sentMailDetails: selectors.sentMailDetailsSelector,
	selectedInDate: selectors.selectedInDateSelector,
	deleteSentMailSuccess: selectors.deleteSentMailSuccessSelector,
});

const mapDispatchToProps = (dispatch) => ({
	onGetSentMails: (key, arr) => dispatch(actions.getSentMails(key, arr)),
	onGetSentMailDetails: (inDate, query, paging) =>
		dispatch(actions.getSentMailDetails(inDate, query, paging)),
	onDeleteSentMail: (key, arr) => dispatch(actions.deleteSentMail(key, arr)),
	handleIsAdmin: (key, arr) => dispatch(actions.handleIsAdmin(key, arr)),
	onSearchSentMailDetails: (inDate, query) =>
		dispatch(actions.searchSentMailDetails(inDate, query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Emails);
