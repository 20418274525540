// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useState, useRef } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createStructuredSelector } from 'reselect';

import DataTable from '../../../fnComponents/_DataTable';
import validationCheck from '../../../fnComponents/ValidationCheck';
import errMsg from '../../../fnComponents/errMsg';
import MsgModal from './../../../fnComponents/MsgModal';

import * as actions from '../store/action';
import * as selectors from '../store/selector';

const CreditModal = ({
  isCreditModalOpen,
  handleOpenCreditModal,
  onSaveInput,
  creditInfo,
  selectedAccount,
}) => {
  const dispatch = useDispatch();

  const [radioBtn, handleChange] = useState();
  const [creditAmount, handleCreditAmountInput] = useState('');
  const [creditReason, handleCreditReasonInput] = useState('');
  const [customMonth, handleCustomMonthInput] = useState('');
  const [isErr, handleErr] = useState({
    err: false,
    errRes: {},
  });

  const handleCreditAmountInputChange = (e) => {
    handleCreditAmountInput(e.target.value);
  };
  const handleCreditReasonInputChange = (e) => {
    handleCreditReasonInput(e.target.value);
  };

  const handleCustomMonthInputChange = (e) => {
    handleCustomMonthInput(e.target.value);
  };
  const handleRadioBtnChange = (e) => {
    // handleChange({ [e.target.id]: true });
    handleChange(e.target.id);
  };

  const creditId = useRef();

  const [msgModalOpen, setMsgModalOpen] = useState(false);
  const handleMsgModal = (a, obj) => {
    setMsgModalOpen(true);
    const {
      id: { value },
    } = obj[0];
    creditId.current = value;
  };

  const onDeleteRow = () => {
    dispatch(
      actions.deleteCredit({
        id: selectedAccount.ID,
        usagehistoryId: creditId.current,
      })
    );
    setMsgModalOpen(false);
  };
  return (
    <Modal scrollable size="lg" isOpen={isCreditModalOpen}>
      <ModalHeader>크레딧 지급</ModalHeader>
      <MsgModal
        confirmOrCancel
        onConfirmAction={onDeleteRow}
        onHandleCloseMsgModal={() => setMsgModalOpen(false)}
        isMsgModalOpen={msgModalOpen}
        content={
          <>
            지급 내역을 삭제하고
            <br />
            크레딧을 회수 하시겠습니까?
          </>
        }
      />
      <ModalBody>
        <div className="form-group m-form__group row">
          <label for="credit-reason" className="col-lg-2 col-form-label">
            대상계정
          </label>
          <div className="col-lg-10">
            <span className="form_text">{selectedAccount.email}</span>
          </div>
        </div>
        <div className="form-group m-form__group row">
          <label for="email-contents" className="col-lg-2 col-form-label">
            유효기간
          </label>
          <div className="col-lg-10">
            <div className="m-radio-inline">
              <label className="m-radio">
                <input
                  id="3"
                  onChange={(e) => handleRadioBtnChange(e)}
                  type="radio"
                  name="credit-radio"
                  checked={radioBtn === '3'}
                />
                3개월
                <span />
              </label>
              <label className="m-radio">
                <input
                  id="6"
                  onChange={(e) => handleRadioBtnChange(e)}
                  type="radio"
                  name="credit-radio"
                  checked={radioBtn === '6'}
                />
                6개월
                <span />
              </label>
              <label className="m-radio">
                <input
                  id="12"
                  onChange={(e) => handleRadioBtnChange(e)}
                  type="radio"
                  name="credit-radio"
                  checked={radioBtn === '12'}
                />
                12개월
                <span />
              </label>
              <label className="m-radio">
                <input
                  id="24"
                  onChange={(e) => handleRadioBtnChange(e)}
                  type="radio"
                  name="credit-radio"
                  checked={radioBtn === '24'}
                />
                24개월
                <span />
              </label>
              <label className="m-radio">
                <input
                  id="36"
                  onChange={(e) => handleRadioBtnChange(e)}
                  type="radio"
                  name="credit-radio"
                  checked={radioBtn === '36'}
                />
                36개월
                <span />
              </label>
              <label className="m-radio">
                <input
                  id="custom"
                  onChange={(e) => handleRadioBtnChange(e)}
                  type="radio"
                  name="credit-radio"
                  checked={radioBtn === 'custom'}
                />
                직접입력
                <span />
              </label>
              <input
                id="customMonth"
                className="credit-custom-month"
                onChange={handleCustomMonthInputChange}
                required
                disabled={radioBtn !== 'custom'}
              />
              개월
            </div>
            {/* {isErr.err ? <div>에러</div> : null} */}
            {isErr.err ? (
              <div className="error message visible">
                {isErr.errRes.expirationDuration}
              </div>
            ) : null}
          </div>
        </div>
        {/* a row for INPUT */}
        <div className="form-group m-form__group row">
          <label for="creditAmount" className="col-lg-2 col-form-label">
            크레딧 *
          </label>
          <div className="col-lg-9">
            <input
              onChange={handleCreditAmountInputChange}
              type="number"
              className="form-control"
              id="creditAmount"
              required
            />
            {isErr.err ? (
              <div className="error message visible">
                {isErr.errRes.creditAmount}
              </div>
            ) : null}
          </div>
        </div>
        <div className="form-group m-form__group row">
          <label for="creditReason" className="col-lg-2 col-form-label">
            내용 *
          </label>
          <div className="col-lg-9">
            <input
              onChange={handleCreditReasonInputChange}
              type="type"
              className="form-control"
              id="creditReason"
              required
            />
            {isErr.err ? (
              <div className="error message visible">
                {isErr.errRes.details}
              </div>
            ) : null}
          </div>
        </div>
        <DataTable
          customClassName="column-5 credit-table"
          columns={[
            { label: '일시' },
            { label: '크레딧' },
            { label: '내용' },
            { label: '관리자' },
            { label: '' },
          ]}
          rows={creditInfo}
          deleteRow
          secondRequiredAction={handleMsgModal}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            const obj = {
              type: 'credit',
              expirationDuration:
                radioBtn === 'custom' ? customMonth : radioBtn,
              creditAmount,
              details: creditReason,
            };
            const _obj = {
              type: 'credit',
              expirationDuration: {
                expirationDuration:
                  radioBtn === 'custom' ? customMonth : radioBtn,
                rangeCheck: false,
              },
              creditAmount: {
                creditAmount,
                rangeCheck: true,
                startPoint: 1000,
                endPoint: 10000000,
              },
              details: {
                details: creditReason,
                rangeCheck: true,
                startPoint: 1,
                endPoint: 30,
              },
            };

            const paramSet = {
              emptyCheck: (str) => {
                if (!str) return '필수입력 항목입니다.';
              },
              rangeCheck: (str, paramRange) => {
                // console.log(typeof str, 'typeof');
                if (paramRange && paramRange.startPoint === 1000)
                  console.log('check STR', typeof str);
                if (
                  str &&
                  (str < paramRange.startPoint || str > paramRange.endPoint)
                )
                  return `${paramRange.startPoint
                    .toString()
                    .replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ','
                    )}-${paramRange.endPoint
                    .toString()
                    .replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ','
                    )}까지 입력 가능합니다.`;
              },
            };

            const res = validationCheck(_obj, paramSet);
            // console.log('check res', res);
            // 에러 발생시 useState는 개별 모듈에서 수행, 에레메시지를 리턴하는 함수는 modul화 해서 재사용
            const _res = errMsg(res);
            handleErr({ err: true, errRes: _res });

            if (!res) {
              // 에러 없을 경우 초기화 필요
              onSaveInput(selectedAccount.ID, obj);
              handleOpenCreditModal(false);
            }
          }}
        >
          적용
        </Button>{' '}
        <Button color="primary" onClick={() => handleOpenCreditModal(false)}>
          닫기
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  whereTo: selectors.deleteAccountSelector,
  columns: selectors.columnsSelector,
  accountList: selectors.accountListSelector,
  creditInfo: selectors.creditInfoSelector,
});

const mapDispatchToProps = (dispatch) => ({
  // onHandleOpenModal: () => dispatch(actions.handleOpenModal()),
  onConfirmDeleteAccount: (id) => dispatch(actions.confirmDeleteAccount(id)),
  onSaveInput: (id, value) => dispatch(actions.saveInput(id, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditModal);
