import * as types from "./constant";

export function getPrivacyPolicy() {
	return {
		type: types.GET_PRIVACY_POLICY,
		
	};
}

export function getPrivacyPolicySuccess(data) {
	return {
		type: types.GET_PRIVACY_POLICY_SUCCESS,data
		
	};
}


export function updatePrivacyPolicy(contents) {
	return {
		type: types.UPDATE_PRIVACY_POLICY,contents
		
	};
}


export function updatePrivacyPolicySuccess() {
	return {
		type: types.UPDATE_PRIVACY_POLICY_SUCCESS,
		
	};
}


export function initPrivacyPolicyState() {
	return {
		type: types.INIT_PRIVACY_POLICY_STATE,
		
	};
}


export function handleOnChange(value) {
	return {
		type: types.HANDLE_ON_CHANGE,value
		
	};
}
