import * as types from './constant';

export function getSendToCount() {
	return {
		type: types.GET_SEND_TO_COUNT,
	};
}

export function getSendToCountSuccess(payload) {
	return {
		type: types.GET_SEND_TO_COUNT_SUCCESS,
		payload,
	};
}
export function sendEmail(payload) {
	return {
		type: types.SEND_EMAIL,
		payload,
	};
}

export function sendTestEmail(payload) {
	return {
		type: types.SEND_TEST_EMAIL,
		payload,
	};
}

export function readFiles(payload) {
	return {
		type: types.READ_FILES,
		payload,
	};
}

export function deleteReadFiles(payload) {
	return {
		type: types.DELETE_READ_FILES,
		payload,
	};
}

export function readFilesSuccess(payload) {
	return {
		type: types.READ_FILES_SUCCESS,
		payload,
	};
}

export function resetFiles() {
	return {
		type: types.RESET_FILES,
	};
}

export function sendEmailSuccess(payload) {
	return {
		type: types.SEND_EMAIL_SUCCESS,
		payload,
	};
}

export function sendTestEmailSuccess(payload) {
	return {
		type: types.SEND_TEST_EMAIL_SUCCESS,
		payload,
	};
}

export function initSendEmailSuccess(payload) {
	return {
		type: types.INIT_SEND_EMAIL_SUCCESS,
		payload,
	};
}
export function initSendTestEmailSuccess(payload) {
	return {
		type: types.INIT_SEND_TEST_EMAIL_SUCCESS,
		payload,
	};
}

export function handleFileErrors(payload) {
	return {
		type: types.HANDLE_FILE_ERRORS,
		payload,
	};
}
