import { LOCATION_CHANGE } from "react-router-redux";

// import * as selectors from './selector';
import * as types from "./constant";

const initialState = {
  currentBill: [],
  columns: [
    {
      label: "아이디"
    },
    {
      label: "회사명"
    },
    {
      label: "연체요금(원)"
    },
    {
      label: "연체발생일"
    },
    {
      label: "연체해제일"
    },
    {
      label: "상태"
    }
  ],
  //   completeCharge: true,
  //   sumResults: undefined,
  searchKeywords: {},
  lastEvaluatedKey: undefined
};

function currentCharge(state = initialState, action) {
  switch (action.type) {
    case types.INIT_OVERDUE_BILL_LIST:
      return {
        // ...initialState,
        ...state,
        currentBill: []
      };
    case types.GET_COLUMNS:
      return {
        ...state,
        columns: action.columns
      };

    case types.GET_SEARCH_KEYWORDS_OVERDUE_BILL:
      return {
        ...state,
        searchKeywords: {
          ...state.searchKeywords,
          ...action.obj
        }
      };

    // ******************************

    //   overdue bill

    case types.GET_OVERDUE_BILL_SUCCESS:
      return {
        ...state,
        currentBill: action.rows,
        lastEvaluatedKey: action.lastEvaluatedKey
      };

    default:
      return state;
  }
}

export default currentCharge;
