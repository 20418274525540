// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import * as types from './constant';

export function globalErrorHandling(params) {
	return {
		type: types.GLOBAL_ERROR_HANDLING,
		params,
	};
}

export function globalErrorHandlingDone(params) {
	return {
		type: types.GLOBAL_ERROR_HANDLING_DONE,
		params,
	};
}

export function globalLoading(params) {
	return {
		type: types.GLOBAL_LOADING,
		params,
	};
}

export function globalLoadingSuccess(params) {
	return {
		type: types.GLOBAL_LOADING_SUCCESS,
		params,
	};
}

export function uploadCsvFile(params) {
	return {
		type: types.UPLOAD_CSV_FILE,
		params,
	};
}

export function uploadCsvFileSuccess(params) {
	return {
		type: types.UPLOAD_CSV_FILE_SUCCESS,
	};
}

/**
 * 
 * @param {} globalMessage
 * @param {} globalMsgModalOpen
 * @param {} confirmAction
 * @returns 
 */
export function globalMsgModalOpen(params) {
  return {
    type: types.GLOBAL_MSG_MODAL_OPEN,
    params,
  };
}
