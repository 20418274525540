import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import DraftEditor from '../../fnComponents/MarkDownEditor';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from './store/action';
import * as selectors from './store/selector';

const EmailCreateModal = ({
  isEmailCreateModalOpen,
  onHandleOpenEmailCreateModal,
  editable,
  onHandleCreateNotice,
  tryPost,
  onSetTryPostTrue,
  contents,
  onHandleOnChange,
  emailEtc,
  onUpdateEmail,
}) => {
  const [contentsInput, handleContentsChange] = useState('');
  // const [titleInput, handleTitleChange] = useState('');
  const _onHandleOnChange = (e, name) => {
    if (name === 'title') {
      return onHandleOnChange({ [name]: e.target.value });
    }
    onHandleOnChange({ [name]: e });
  };
  return (
    <Modal size="xl" isOpen={isEmailCreateModalOpen}>
      <ModalHeader>발송 메일 수정</ModalHeader>
      <ModalBody>
        {/* a row for INPUT */}
        <div className="form-group m-form__group row">
          <label for="noticeTitle" className="col-lg-2 col-form-label">
            항목
          </label>
          <div className="col-lg-10">
            <span className="form_text">{emailEtc ? emailEtc.type : null}</span>
          </div>
        </div>
        <div className="form-group m-form__group row">
          <label for="credit-name" className="col-lg-2 col-form-label">
            제목
          </label>
          <div className="col-lg-10">
            <input
              onChange={(e) => _onHandleOnChange(e, 'title')}
              type="type"
              className="form-control"
              id="emailTitle"
              required
              value={emailEtc ? emailEtc.title : null}
            />
          </div>
        </div>
        <DraftEditor
          onHandleOnChange={(e) => _onHandleOnChange(e, 'contents')}
          contents={contents}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onUpdateEmail}>
          적용
        </Button>{' '}
        <Button color="primary" onClick={onHandleOpenEmailCreateModal}>
          취소
        </Button>{' '}
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  isMsgModalOpen: selectors.isMsgModalOpenSelector,
  contents: selectors.contentsSelector,
  emailEtc: selectors.emailEtcSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onHandleCloseMsgModal: () => dispatch(actions.handleCloseMsgModal()),
  onHandleOpenEmailCreateModal: () =>
    dispatch(actions.handleOpenEmailCreateModal()),
  onHandleOnChange: (value) => dispatch(actions.handleOnChange(value)),
  onUpdateEmail: () => dispatch(actions.updateEmail()),
  // onSetTryPostTrue: () => dispatch(actions.setTryPostTrue()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailCreateModal);
