import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Nav, NavItem, NavLink, Collapse } from 'reactstrap';

import { createStructuredSelector } from 'reselect';

import {
  moveToLandingMenu,
  moveToConsoleMenu,
  moveToDeveloperMenu,
} from './store/action';

import * as loginActions from '../containers/login/store/action';
import { nameSelector } from '../containers/login/store/selector';

import logoBasic from '../images/logos/logo_basic.png';
import Cookies from 'js-cookie';

const Header = ({
  onLogOut,
  name,
  onMoveToLandingMenu,
  onMoveToConsoleMenu,
  onMoveToDeveloperMenu,
}) => {
  const usernameToDisplay = Cookies.get('usernameToDisplay');

  return (
    <header
      id="m_header"
      className="m-grid__item m-header"
      m-minimize-offset="200"
      m-minimize-mobile-offset="200"
    >
      <div className="m-container m-container--fluid m-container--full-height">
        <div className="m-stack m-stack--ver m-stack--desktop">
          <div className="m-stack__item m-brand m-brand--skin-dark">
            <div className="m-stack m-stack--ver m-stack--general">
              <div className="m-stack__item m-stack__item--middle m-brand__logo">
                <a href="/dashboard" className="m-brand__logo-wrapper">
                  <img alt="" src={logoBasic} />
                </a>
              </div>
              {/* <div className="m-stack__item m-stack__item--middle m-brand__tools">
                <a
                  href="dummy"
                  id="m_aside_left_minimize_toggle"
                  className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block"
                >
                  <span />
                </a>
                <a
                  href="dummy"
                  id="m_aside_left_offcanvas_toggle"
                  className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block"
                >
                  <span />
                </a>
                <a
                  id="m_aside_header_menu_mobile_toggle"
                  href="dummy"
                  className="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block"
                >
                  <span />
                </a>

                <a
                  id="m_aside_header_topbar_mobile_toggle"
                  href="dummy"
                  className="m-brand__icon m--visible-tablet-and-mobile-inline-block"
                >
                  <i className="flaticon-more" />
                </a>
              </div> */}
            </div>
          </div>
          <div
            className="m-stack__item m-stack__item--fluid m-header-head"
            id="m_header_nav"
          >
            <button
              className="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-dark "
              id="m_aside_header_menu_mobile_close_btn"
            >
              <i className="la la-close" />
            </button>
            <div
              id="m_header_menu"
              className="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark"
            >
              <Nav className="m-menu__nav m-menu__nav--submenu-arrow">
                <NavItem className="m-menu__item  m-menu__item--submenu m-menu__item--rel">
                  <Link to="/accountManaging" className="m-menu__link">
                    <i className="m-menu__link-icon la la-cubes" />
                    <span
                      onClick={onMoveToConsoleMenu}
                      className="m-menu__link-text"
                    >
                      콘솔
                    </span>
                  </Link>
                </NavItem>
                <NavItem className="m-menu__item  m-menu__item--submenu m-menu__item--rel">
                  <Link
                    to="/developer/getStarted"
                    className="m-menu__link alrinfo"
                  >
                    <i className="m-menu__link-icon la la-code" />
                    <span
                      onClick={onMoveToDeveloperMenu}
                      className="m-menu__link-text"
                    >
                      개발자
                    </span>
                  </Link>
                </NavItem>
                <NavItem className="m-menu__item  m-menu__item--submenu m-menu__item--rel">
                  <Link to="/landing/showcase" className="m-menu__link alrinfo">
                    <i className="m-menu__link-icon la la-plane" />
                    <span
                      onClick={onMoveToLandingMenu}
                      className="m-menu__link-text"
                    >
                      랜딩
                    </span>
                  </Link>
                </NavItem>
              </Nav>
            </div>

            <div
              id="m_header_topbar"
              className="m-topbar  m-stack m-stack--ver m-stack--general m-stack--fluid"
            >
              <div className="m-stack__item m-topbar__nav-wrapper">
                <ul className="m-topbar__nav m-nav m-nav--inline">
                  <li className="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light">
                    <span
                      className="m-nav__link"
                      data-toggle="modal"
                      data-target="#modal_profile"
                    >
                      <span className="m-topbar__userpic">
                        <i className="flaticon-user" /> {usernameToDisplay}
                      </span>
                      <span className="m-topbar__username">
                        <button
                          onClick={onLogOut}
                          type="button"
                          className="btn btn-sm m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand"
                        >
                          로그아웃
                        </button>
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = createStructuredSelector({
  name: nameSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onLogOut: () => dispatch(loginActions.handleLogout()),
  onMoveToLandingMenu: () => dispatch(moveToLandingMenu()),
  onMoveToConsoleMenu: () => dispatch(moveToConsoleMenu()),
  onMoveToDeveloperMenu: () => dispatch(moveToDeveloperMenu()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
