import * as types from "./constant";

export function initTopics() {
  return {
    type: types.INIT_TOPICS,
  };
}
export function getTopics(param) {
  return {
    type: types.GET_TOPICS,
    param,
  };
}
export function getTopicsSuccess(data) {
  return {
    type: types.GET_TOPICS_SUCCESS,
    data,
  };
}
export function getList(param, listIndex) {
  return {
    type: types.GET_LIST,
    param,
    listIndex,
  };
}
export function getListSuccess(data, listIndex) {
  return {
    type: types.GET_LIST_SUCCESS,
    data,
    listIndex,
  };
}
export function getClassParentList(param) {
  return {
    type: types.GET_CLASS_PARENT_LIST,
    param,
  };
}
export function getClassParentListSuccess(data) {
  return {
    type: types.GET_CLASS_PARENT_LIST_SUCCESS,
    data,
  };
}

export function getDocument(inDate) {
  return {
    type: types.GET_DOCUMENT,
    inDate,
  };
}
export function getDocumentSuccess(data) {
  return {
    type: types.GET_DOCUMENT_SUCCESS,
    data,
  };
}
export function getVersionList(param) {
  return {
    type: types.GET_VERSION_LIST,
    param,
  };
}
export function getVersionListSuccess(data) {
  return {
    type: types.GET_VERSION_LIST_SUCCESS,
    data,
  };
}

export function makeTopic(obj) {
  return {
    type: types.MAKE_TOPIC,
    obj,
  };
}
export function makeTopicSuccess() {
  return {
    type: types.MAKE_TOPIC_SUCCESS,
  };
}
export function makeClass(obj) {
  return {
    type: types.MAKE_CLASS,
    obj,
  };
}
export function makeClassSuccess(obj) {
  return {
    type: types.MAKE_CLASS_SUCCESS,
    obj,
  };
}
export function makeDocument(obj) {
  return {
    type: types.MAKE_DOCUMENT,
    obj,
  };
}
export function makeDocumentErr(obj) {
  return {
    type: types.MAKE_DOCUMENT_ERROR,
    obj,
  };
}
export function makeDocumentSuccess(obj) {
  return {
    type: types.MAKE_DOCUMENT_SUCCESS,
    obj,
  };
}
export function makeVersion(obj) {
  return {
    type: types.MAKE_VERSION,
    obj,
  };
}
export function makeVersionSuccess(obj) {
  return {
    type: types.MAKE_VERSION_SUCCESS,
    obj,
  };
}
// UPDATE METHOD
export function updateVersion(obj) {
  return {
    type: types.UPDATE_VERSION,
    obj,
  };
}
export function updateVersionSuccess(obj) {
  return {
    type: types.UPDATE_VERSION_SUCCESS,
    obj,
  };
}
export function updateTopic(inDate,topic) {
  return {
    type: types.UPDATE_TOPIC,
    inDate,topic
  };
}
export function updateTopicSuccess(obj) {
  return {
    type: types.UPDATE_TOPIC_SUCCESS,
    obj,
  };
}
export function updateClass(obj, inDate) {
  return {
    type: types.UPDATE_CLASS,
    obj,
    inDate,
  };
}
export function updateClassSuccess(obj) {
  return {
    type: types.UPDATE_CLASS_SUCCESS,
    obj,
  };
}
export function updateDocument(obj, inDate) {
  return {
    type: types.UPDATE_DOCUMENT,
    obj,
    inDate,
  };
}
export function updateDocumentSuccess(obj) {
  return {
    type: types.UPDATE_DOCUMENT_SUCCESS,
    obj,
  };
}
export function updateOrder(inDate, upDown) {
  return {
    type: types.UPDATE_ORDER,
    inDate,
    upDown,
  };
}
export function updateOrderSuccess(obj) {
  return {
    type: types.UPDATE_ORDER_SUCCESS,
    obj,
  };
}
// DELETE METHOD
export function deleteVersion(obj) {
  return {
    type: types.DELETE_VERSION,
    obj,
  };
}
export function deleteVersionSuccess(obj) {
  return {
    type: types.DELETE_VERSION_SUCCESS,
    obj,
  };
}
export function deleteTopic(inDate) {
  return {
    type: types.DELETE_TOPIC,
    inDate,
  };
}
export function deleteTopicSuccess(obj) {
  return {
    type: types.DELETE_TOPIC_SUCCESS,
    obj,
  };
}
export function deleteClass(inDate) {
  return {
    type: types.DELETE_CLASS,
    inDate,
  };
}
export function deleteClassSuccess(obj) {
  return {
    type: types.DELETE_CLASS_SUCCESS,
    obj,
  };
}
export function deleteDocument(inDate) {
  return {
    type: types.DELETE_DOCUMENT,
    inDate,
  };
}
export function deleteDocumentSuccess(obj) {
  return {
    type: types.DELETE_DOCUMENT_SUCCESS,
    obj,
  };
}
export function initGetListKey() {
  return {
    type: types.INIT_GET_LIST_KEY,
  };
}

// **************
export function getShowcaseList(lastEvaluatedKey) {
  return {
    type: types.GET_SHOWCASE_LIST,
    lastEvaluatedKey,
  };
}

export function getMoreShowcaseList(lastEvaluatedKey) {
  return {
    type: types.GET_MORE_SHOWCASE_LIST,
    lastEvaluatedKey,
  };
}

export function getMoreShowcaseListSuccess(rows, lastEvaluatedKey) {
  return {
    type: types.GET_MORE_SHOWCASE_LIST_SUCCESS,
    rows,
    lastEvaluatedKey,
  };
}

export function getShowcaseListSuccess(rows, lastEvaluatedKey) {
  return {
    type: types.GET_SHOWCASE_LIST_SUCCESS,
    rows,
    lastEvaluatedKey,
  };
}

export function updateShowcaseOrder(updown, inDate) {
  return {
    type: types.UPDATE_SHOWCASE_ORDER,
    inDate,
    updown,
  };
}

export function insertShowcase(obj) {
  return {
    type: types.INSERT_SHOWCASE,
    obj,
  };
}

export function insertShowcaseSuccess() {
  return {
    type: types.INSERT_SHOWCASE_SUCCESS,
  };
}
export function updateShowcase(inDate, data) {
  return {
    type: types.UPDATE_SHOWCASE,
    inDate,
    data,
  };
}
export function updateShowcaseSuccess() {
  return {
    type: types.UPDATE_SHOWCASE_SUCCESS,
  };
}

export function deleteShowcase(inDate) {
  return {
    type: types.DELETE_SHOWCASE,
    inDate,
  };
}

export function getShowcase(title, arr) {
  return {
    type: types.GET_SHOWCASE,
    title,
    arr,
  };
}

// export function initGameStat(gameId, date, year) {
// 	return {
// 		type: types.INIT_GAME_STAT,
// 		gameId,
// 		date,
// 		year,
// 		MAU: false,
// 	};
// }
