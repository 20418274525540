import { call, put, takeLatest, select } from "redux-saga/effects";

import * as types from "./constant";
import * as actions from "./action";
import { LOCATION_CHANGE } from "react-router-redux";

import aws from 'aws-sdk';
import { openSubMenu, handleClickSubMenu } from "../../../../view/store/action";

import * as api from "../../../../service/API";


function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split("/");
    if (pathArray[1] === "landing" && pathArray[2] === "terms") {
      yield put(openSubMenu("policy"));
      yield put(handleClickSubMenu("terms"));      
      yield put(actions.getTermsPolicy());
      // yield put(actions.updateTermsPolicy({contents:'<p>empty</p>'}))
    }
  } catch (err) {
    console.log('LOCATION_CHANGE', err)
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}



function* getTermsPolicy() {
  try {
    
    const {data} = yield call(api.terms.getTermsPolicy);

    yield put(actions.getTermsPolicySuccess(data.map(row => aws.DynamoDB.Converter.unmarshall(row))))
  } catch (error) {
    console.log('GET_TERMS_POLICY', error)
  }
}

export function* watcherGetTermsPolicy() {
  yield takeLatest(types.GET_TERMS_POLICY, getTermsPolicy);
}


function* updateTermsPolicy({contents}) {
  try {
    yield call(api.terms.updateTermsPolicy, contents);
    yield put(actions.updateTermsPolicySuccess())
    yield put(actions.getTermsPolicy())
    

  } catch (error) {
    console.log('UPDATE_TERMS_POLICY', error)
  }
}

export function* watcherUpdateTermsPolicy() {
  yield takeLatest(types.UPDATE_TERMS_POLICY, updateTermsPolicy);
}


