import { LOCATION_CHANGE } from 'react-router-redux';
import * as types from './constant';

const initialState = {
	dashboards: null,
};

function dashboards(state = initialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;
		case types.GET_DASHBOARD_SUCCESS:
			return {
				...state,
				dashboards: action.payload,
			};

		default:
			return state;
	}
}

export default dashboards;
