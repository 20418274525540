import * as types from "./constant";

export function initVersionList(sdkType) {
  return {
    type: types.INIT_VERSION_LIST,sdkType
  };
}

export function getVersionList(sdkType) {
  return {
    type: types.GET_VERSION_LIST,
    sdkType,
  };
}
export function getVersionListSuccess(obj) {
  return {
    type: types.GET_VERSION_LIST_SUCCESS,
    obj,
  };
}

export function makeVersion(obj) {
  return {
    type: types.MAKE_VERSION,
    obj,
  };
}
export function makeVersionSuccess() {
  return {
    type: types.MAKE_VERSION_SUCCESS,
  };
}
export function updateVersion(inDate, obj) {
  return {
    type: types.UPDATE_VERSION,
    inDate,
    obj,
  };
}
export function updateVersionSuccess() {
  return {
    type: types.UPDATE_VERSION_SUCCESS,
  };
}
export function deleteVersion(version) {
  return {
    type: types.DELETE_VERSION,
    version,
  };
}
export function deleteVersionSuccess() {
  return {
    type: types.DELETE_VERSION_SUCCESS,
  };
}

export function openVersionModal(version) {
  return {
    type: types.OPEN_VERSION_MODAL,version
  };
}
