import { call, put, takeLatest, select } from "redux-saga/effects";

import * as types from "./constant";
import * as actions from "./action";
import { LOCATION_CHANGE } from "react-router-redux";

import aws from 'aws-sdk';
import { openSubMenu, handleClickSubMenu } from "../../../../view/store/action";

import * as api from "../../../../service/API";


function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split("/");
    if (pathArray[1] === "landing" && pathArray[2] === "privacy") {
      yield put(openSubMenu("policy"));
      yield put(handleClickSubMenu("privacy"));      
      yield put(actions.getPrivacyPolicy());
      
    }
  } catch (err) {
    console.log('LOCATION_CHANGE', err)
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}



function* getPrivacyPolicy() {
  try {
    
    const {data} = yield call(api.privacy.getPrivacyPolicy);

    yield put(actions.getPrivacyPolicySuccess(data.map(row => aws.DynamoDB.Converter.unmarshall(row))))
  } catch (error) {
    console.log('GET_PRIVACY_POLICY', error)
  }
}

export function* watcherGetPrivacyPolicy() {
  yield takeLatest(types.GET_PRIVACY_POLICY, getPrivacyPolicy);
}


function* updatePrivacyPolicy({contents}) {
  try {
    yield call(api.privacy.updatePrivacyPolicy, contents);
    yield put(actions.updatePrivacyPolicySuccess())
    yield put(actions.getPrivacyPolicy())
    

  } catch (error) {
    console.log('UPDATE_PRIVACY_POLICY', error)
  }
}

export function* watcherUpdatePrivacyPolicy() {
  yield takeLatest(types.UPDATE_PRIVACY_POLICY, updatePrivacyPolicy);
}


