import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  Popover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Octicon, { Search, X, ChevronDown } from '@primer/octicons-react';

import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import ButtonsGroup from '../../../fnComponents/buttonGroup';
import DataTable from '../../../fnComponents/_DataTable';
import SingleDatePicker from '../../../fnComponents/SingleDatePicker.1';

import * as actions from './store/action';
import * as selectors from './store/selector';

import SpinnerCompo from '../../../fnComponents/Spinner';

const Projects = ({
  columns,
  projectList,
  _projectList,
  onGetDailyStatisticsByProject,
  // 하단 review 필요

  onHandleSearchProject,
  onChangeStatType,

  onClickProjectRow,

  onGetDailyStatisticsProjects,
  onGetMonthlyStatisticsProjects,

  onSortAscRows,
  onSortDescRows,

  sortingParam,
  onInitDataTables,
  isLoading,

  isLast,
  //
  onGetSearchKeywords,
  onSetRevenue,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [inputSet, handleInputChange] = useState({ title: '' });
  const [errMsg, handleError] = useState('');

  const getDailyStatisticsByProject = (e) => {
    e.preventDefault();
    onGetDailyStatisticsByProject();
  };

  const [revenue, setRevenue] = useState(false);

  const handlePopOver = (arr) => {
    // target example : clickable-td 2,418,900

    let obj = {};

    arr.forEach((item) => {
      obj = {
        ...obj,
        ...item,
      };
    });

    const {
      Revenue,
      specificRevenue: { iOSRevenue, AndroidRevenue, id },
    } = obj;
    setRevenue({
      Revenue: Revenue.replace(/,/g, ''),
      iOSRevenue,
      AndroidRevenue,
      id,
    });
    handleOverlay('block');
    setPopOver(true);
  };

  const [isPopOverOpen, setPopOver] = useState(false);
  const toggle = () => {
    setPopOver(!isPopOverOpen);
  };
  const handleOverlay = (param) => {
    document.querySelector('div.overlay').style.display = param;
  };
  const showPopOver = () => (
    <Popover
      fade={false}
      placement="left"
      isOpen={isPopOverOpen}
      target={`R${revenue.Revenue}${revenue.id}`}
      toggle={toggle}
    >
      <PopoverHeader>거래액정보</PopoverHeader>
      <PopoverBody>
        구글 : {revenue.AndroidRevenue}
        <br />
        애플 : {revenue.iOSRevenue}
      </PopoverBody>
    </Popover>
  );

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title">통계 - 거래액</h3>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <form className="m-form m-form--fit m--margin-bottom-20">
                      <div className="row m--margin-bottom-20">
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>일자</label>
                          <SingleDatePicker
                            value={moment()
                              .subtract(2, 'day')
                              .format('YYYY-MM-DD')}
                            searchParam="date"
                            onGetSearchKeywords={onGetSearchKeywords}
                          />
                        </div>
                      </div>
                      <div className="row m--margin-bottom-20">
                        <div className="col-lg-12">
                          <button
                            onClick={getDailyStatisticsByProject}
                            className="btn btn-brand m-btn m-btn--icon"
                            id="m_search"
                          >
                            <span>
                              <Octicon icon={Search} />
                              <span>검색</span>
                            </span>
                          </button>
                          &nbsp;&nbsp;
                          <button
                            className="btn btn-secondary m-btn m-btn--icon"
                            id="m_reset"
                          >
                            <span>
                              <Octicon icon={X} />
                              <span>초기화</span>
                            </span>
                          </button>
                          <div className="error message visible">{errMsg}</div>
                        </div>
                      </div>
                    </form>
                    <ButtonsGroup
                      csvData={projectList}
                      csvFileName={`거래액_통계_${moment().format(
                        'YYYY-MM-DD HH:mm'
                      )}`}
                    />
                    {isPopOverOpen && showPopOver()}
                    <div
                      onClick={() => {
                        handleOverlay('none');
                        setPopOver(false);
                      }}
                      className="overlay"
                      style={{
                        width: '100%',
                        height: '100%',
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        zIndex: '100',
                        opacity: '0.5',
                        backgroundColor: 'rgb(0,0,0)',
                        display: 'none',
                      }}
                    ></div>
                    <DataTable
                      tdPopupContents={revenue}
                      canTdPopup={[2]}
                      isLast={isLast}
                      indexMethodArr={[
                        {
                          index: 2,
                          method: handlePopOver,
                        },
                      ]}
                      sortingParam={sortingParam}
                      customClassName="table-scrollable sales-table"
                      rows={projectList}
                      actualData={_projectList}
                      columns={columns}
                      onSortAsc={onSortAscRows}
                      onSortDesc={onSortDescRows}
                      sortingColumns={[
                        '거래액(원)',
                        'DAU',
                        'WAU',
                        'NRU',
                        'RU',
                        'Stickiness',
                        'PU',
                        'ARPDAU',
                        'ARPPU',
                        'P.R(%)',
                      ]}
                      clickableIndex={[2]}
                    />
                    {/* <Modal centered isOpen={isLoading}>
											<ModalBody>
												<SpinnerCompo children="LOADING" />
											</ModalBody>
										</Modal> */}
                    {/* 프로젝트 검색 결과가 1이어서 통계 지표가 출력되는 경우 DataTable은 별도로 렌더링 */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  columns: selectors.columnsSelector,
  projectList: selectors.projectListSelector,
  // 하단 review 필요
  sortingParam: selectors.sortingParamSelector,
  isLoading: selectors.isLoadingSelector,
  isLast: selectors.isLastSelector,
  _projectList: selectors._projectListSelector,
  searchingKeywords: selectors.searchingKeywordsSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onGetDailyStatisticsByProject: () =>
    dispatch(actions.getDailyStatisticsByProject()),
  // 하단 review 필요
  onHandleSearchProject: (title, date, opt) =>
    dispatch(actions.handleSearchProject(title, date, opt)),
  onChangeStatType: (v) => dispatch(actions.changeStatType(v)),
  onClickProjectRow: (key, arr) => dispatch(actions.clickProjectRow(key, arr)),
  onGetDailyStatisticsProjects: (projectId, date, opt) =>
    dispatch(actions.getDailyStatisticsProjects(projectId, date, opt)),
  onGetMonthlyStatisticsProjects: (projectId, date, opt) =>
    dispatch(actions.getMonthlyStatisticsProjects(projectId, date, opt)),
  onSortAscRows: (rows, isSearched, tableType) =>
    dispatch(actions.sortAscRows(rows, isSearched, tableType)),
  onSortDescRows: (rows, isSearched, tableType) =>
    dispatch(actions.sortDescRows(rows, isSearched, tableType)),
  onInitDataTables: () => dispatch(actions.initDataTables()),
  //
  onGetSearchKeywords: (obj) => dispatch(actions.getSearchKeywords(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
