// privacy
export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY "
export const GET_PRIVACY_POLICY_SUCCESS= "GET_PRIVACY_POLICY_SUCCESS"
export const UPDATE_PRIVACY_POLICY = "UPDATE_PRIVACY_POLICY "
export const UPDATE_PRIVACY_POLICY_SUCCESS= "UPDATE_PRIVACY_POLICY_SUCCESS"
export const GET_TERMS_POLICY = "GET_TERMS_POLICY "
export const GET_TERMS_POLICY_SUCCESS= "GET_TERMS_POLICY_SUCCESS"
export const UPDATE_TERMS_POLICY= "UPDATE_TERMS_POLICY"
export const UPDATE_TERMS_POLICY_SUCCESS= "UPDATE_TERMS_POLICY_SUCCESS"
export const INIT_TERMS_POLICY_STATE = "INIT_TERMS_POLICY_STATE"
export const HANDLE_ON_CHANGE = "HANDLE_ON_CHANGE"
