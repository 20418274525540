import { createSelector } from "reselect";
import aws from "aws-sdk";
import moment from "moment";

export const versionListSelector = createSelector(
  (state) => state.versionManaging.versionList,
  (versionList) => {
    return versionList.map((ver) => {
      const temp = aws.DynamoDB.Converter.unmarshall(ver);
      console.log(temp, "1120 temp");
      return {
        version: temp.version,
        inDate: moment(temp.inDate).format("YYYY.MM.DD HH:mm"),
        inDateObj: {
          noDisplay: true,
          inDate: temp.inDate,
        },
        deployDate: temp.deployDate,
        versionType: temp.versionType === '1' ? '뒤끝펑션 SDK': '뒤끝 SDK',
        isPublic: temp.isPublic ? "공개" : "비공개",
      };
    });
  }
);

export const isMakeVersionDoneSelector =  createSelector(
  state => state.versionManaging.isMakeVersionDone,
  isMakeVersionDone => isMakeVersionDone
)

export const isUpdateVersionDoneSelector =  createSelector(
  state => state.versionManaging.isUpdateVersionDone,
  isUpdateVersionDone => isUpdateVersionDone
)

export const isDeleteVersionDoneSelector =  createSelector(
  state => state.versionManaging.isDeleteVersionDone,
  isDeleteVersionDone => isDeleteVersionDone
)

export const selectedVersionSelector =  createSelector(
  state => state.versionManaging.selectedVersion,
  selectedVersion => selectedVersion
)