// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { select, call, put, takeLatest, race } from 'redux-saga/effects';
import { LOCATION_CHANGE, push } from 'react-router-redux';

// import moment from 'moment';
import { openSubMenu, handleClickSubMenu } from '../../../../view/store/action';
import * as types from './constant';

import * as actions from './action';
import * as globalActions from '../../../globalStore/action';
import * as selectors from './selector';
import * as api from '../../../../service/API';
import { delay } from 'redux-saga';
import moment from 'moment';

function* initCurrentChargeList({ payload }) {
  try {
    const { pathname } = payload;

    const pathArr = pathname.split('/');

    if (pathArr[1] === 'currentChargePayPerUse') {
      //   yield put(actions.getCurrentBill());
      yield put(openSubMenu('payment'));
      yield put(handleClickSubMenu('currentChargePayPerUse'));
    }
  } catch (error) {
    console.log('initCurrentChargeList --> ', error);
  }
}

export function* watcherInitCurrentChargeList() {
  yield takeLatest(LOCATION_CHANGE, initCurrentChargeList);
}

function* getCurrentBill({ obj }) {
  try {
    const searchingKeywords = yield select(selectors.searchKeywordsSelector);
    const offset = yield select(selectors.offsetSelector);

    const isSearching =
      Object.keys(searchingKeywords).length > 1 || obj.isSearching;

    const { id, companyName, status } = searchingKeywords;

    const isLastMonth = !(status === '이달');

    const {
      res: { data },
      timeout,
    } = yield race({
      res: call(api.paymentNew.getCurrentBill, {
        email: id,
        companyName,
        offset: isSearching ? undefined : offset,
        isLastMonth,
      }),
      timeout: call(delay, 1000 * 60 * 5),
    });

    console.log('@@timeout', data);
    console.log('@@timeout', timeout);

    if (timeout) {
      yield put(
        globalActions.globalErrorHandling({
          errMsg: '응답 시간이 초과되었습니다.',
          errCode: 'timeout',
        })
      );
      throw {};
    }

    const _data = {
      AccountList: data.AccountList.map((row) => ({
        ...row,
        id: { id: row.id, noDisplay: true },
      })),
      isLast: data.isLast,
    };
    // console.log(test)

    yield put(
      actions.getCurrentBillSuccess(_data, isSearching ? undefined : true)
    );
  } catch (error) {
    console.log('ERR: GET_CURRENT_PAYPERUSE_BILL', error && error.response);
    yield put(actions.getCurrentBillSuccess({ AccountList: [] }));
  }
}

export function* watcherGetCurrentBill() {
  yield takeLatest(types.GET_CURRENT_PAYPERUSE_BILL, getCurrentBill);
}

function* clickCurrentChargeRow({ id, arr }) {
  try {
    const { id } = arr[1].id;
    const {
      data: {
        accountInfo: { status },
      },
    } = yield call(api.account.getAccountInfo, id);
    yield put(actions.getAccountStatusSuccess(status));
    yield put(actions.getCurrentAccountBill(id));
  } catch (error) {
    console.log(
      'ERR: CLICK_CURRENT_CHARGE_PAYPERUSE_ROW',
      error && error.response
    );
  }
}

export function* watcherClickCurrentChargeRow() {
  yield takeLatest(
    types.CLICK_CURRENT_CHARGE_PAYPERUSE_ROW,
    clickCurrentChargeRow
  );
}

function* getCurrentAccountBill({ id, query }) {
  try {
    const searchingKeywords = yield select(selectors.searchKeywordsSelector);
    const { status } = searchingKeywords;

    const isLastMonth = !(status === '이달');
    // 이달/지난달 분기
    let month = moment().month();
    let year = moment().year();

    if (status === '이달') {
      month += 1;
    } else {
      // 현재월이 1월이면 지난달 조회는 12월 -> 따라서 year도 -1
      if (month === 0) {
        year -= 1;
        month = 12;
      }
    }

    const params = { isLastMonth, month, year };

    const { data } = yield call(
      api.paymentNew.getCurrentAccountBill,
      id,
      params
    );
    yield put(actions.getCurrentAccountBillSuccess(data));
  } catch (error) {
    console.log(
      'ERR :GET_CURRENT_ACCOUNT_BILL_PAYPERUSE',
      error && error.response
    );
  }
}

export function* watcherGetCurrentAccountBill() {
  yield takeLatest(
    types.GET_CURRENT_ACCOUNT_BILL_PAYPERUSE,
    getCurrentAccountBill
  );
}

function* getCurrentGameBill({ id, billType, gameTitle, date }) {
  try {
    const searchingKeywords = yield select(selectors.searchKeywordsSelector);
    const { status } = searchingKeywords;

    let month = moment().month();
    let year = moment().year();

    if (status === '이달') {
      month += 1;
    } else {
      if (month === 0) {
        year -= 1;
        month = 12;
      }
    }

    const query = { year, month, type: billType, date };

    const { data } = yield call(api.paymentNew.getCurrentGameBill, id, query);
    
    // billType ( dau / share / db) 분기해서 리턴 값 처리

    yield put(actions.getCurrentGameBillSuccess(data, gameTitle));
  } catch (error) {}
}

export function* watcherGetCurrentGameBill() {
  yield takeLatest(types.GET_CURRENT_GAME_BILL_PAYPERUSE, getCurrentGameBill);
}

function* addExtraBill({ obj }) {
  try {
    const searchingKeywords = yield select(selectors.searchKeywordsSelector);

    const { status } = searchingKeywords;
    const isLastMonth = !(status === '이달');

    yield call(api.paymentNew.addExtraBill, {
      ...obj,
      isLastMonth,
    });
    yield put(actions.addExtraBillSuccess());
    const accountId = yield select(selectors.accountIdSelector);

    yield put(actions.getCurrentAccountBill(accountId));
  } catch (error) {
    console.log('ADD_EXTRA_BILL_ERROR', error);
    console.log('ADD_EXTRA_BILL_ERROR', error.response);
  }
}

export function* watcherAddExtraBill() {
  yield takeLatest(types.ADD_EXTRA_BILL_PAYPERUSE, addExtraBill);
}

function* updateExtraBill({ gameId, inDate, obj }) {
  try {
    yield call(api.paymentNew.updateExtraBill, gameId, inDate, obj);
    yield put(actions.updateExtraBillSuccess());
    const accountId = yield select(selectors.accountIdSelector);

    yield put(actions.getCurrentAccountBill(accountId));
  } catch (error) {
    console.log('UPDATE_EXTRA_BILL_ERROR', error);
    console.log('UPDATE_EXTRA_BILL_ERROR', error.response);
  }
}

export function* watcherUpdateExtraBill() {
  yield takeLatest(types.UPDATE_EXTRA_BILL_PAYPERUSE, updateExtraBill);
}

function* deleteExtraBill({ gameId, inDate }) {
  try {
    yield call(api.paymentNew.deleteExtraBill, gameId, inDate);
    yield put(actions.deleteExtraBillSuccess());
    const accountId = yield select(selectors.accountIdSelector);
    yield put(actions.getCurrentAccountBill(accountId));
  } catch (error) {
    console.log('DELETE_EXTRA_BILL_ERROR', error);
    console.log('DELETE_EXTRA_BILL_ERROR', error.response);
  }
}

export function* watcherDeleteExtraBill() {
  yield takeLatest(types.DELETE_EXTRA_BILL_PAYPERUSE, deleteExtraBill);
}
