// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import login from '../containers/login/store/reducer';
import coupon from '../containers/coupon/store/reducer';
import chargedBill from '../containers/payment/ChargedBill/store/reducer';
import currentCharge from '../containers/payment/CurrentCharge/store/reducer';
import currentChargePayPerUse from '../containers/payment/CurrentChargePayPerUse/store/reducer';
import accountManaging from '../containers/account/store/reducer';
import sleeping from '../containers/account/sleeping/store/reducer';
import projectManaging from '../containers/projects/store/reducer';
import overdueBill from '../containers/payment/OverdueBill/store/reducer';
import notice from '../containers/notice/store/reducer';
import consolePopup from '../containers/popup/store/reducer';
import emails from '../containers/emails/store/reducer';
import sendEmail from '../containers/sendEmail/store/reducer';
import emailList from '../containers/emailList/store/reducer';
import customers from '../containers/stat/customers/store/reducer';
import projects from '../containers/stat/projects/store/reducer';
import sales from '../containers/stat/sales/store/reducer';
import theBackendSales from '../containers/stat/theBackendSales/store/reducer';
import retention from '../containers/stat/retention/store/reducer';
import leftAsideMenu from '../view/store/reducer';
import showcase from '../containers/landing/showcase/store/reducer';
import popup from '../containers/landing/popup/store/reducer';
import terms from '../containers/landing/terms/store/reducer';
import privacy from '../containers/landing/privacy/store/reducer';
import inquiry from '../containers/landing/inquiry/store/reducer';
import sla from '../containers/landing/sla/store/reducer';
import inquiryGDC from '../containers/landing/inquiryGDC/store/reducer';
import getStarted from '../containers/developer/getStarted/store/reducer';
import consoleGuide from '../containers/developer/consoleGuide/store/reducer';
import sdkDocs from '../containers/developer/sdkDocs/store/reducer';
import versionManaging from '../containers/developer/versionManaging/store/reducer';
import globalStore from '../containers/globalStore/reducer';
import dashboards from '../containers/dashboard/store/reducer';
import kibana from '../containers/kibana/store/reducer';
import indicators from '../containers/indicators/store/reducer';

export default combineReducers({
  routing: routerReducer,
  accountManaging,
  // deletedAccountManaging,
  projectManaging,
  leftAsideMenu,
  currentCharge,
  currentChargePayPerUse,
  chargedBill,
  overdueBill,
  coupon,
  notice,
  consolePopup,
  emails,
  sendEmail,
  emailList,
  customers,
  projects,
  login,
  sales,
  theBackendSales,
  showcase,
  popup,
  terms,
  privacy,
  inquiry,
  inquiryGDC,
  getStarted,
  consoleGuide,
  sdkDocs,
  versionManaging,
  sleeping,
  globalStore,
  retention,
  sla,
  dashboards,
  kibana,
  indicators,
});
