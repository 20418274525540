import { createSelector } from "reselect";
import moment from "moment";

// 원본
export const inquiriesListSelector = createSelector(
  state => state.inquiry.inquiriesList,
  inquiriesList => inquiriesList
);

// 테이블용
export const inquiriesListSelectorForView = createSelector(
  state => state.inquiry.inquiriesList,
  inquiriesList => {
    console.log('inquiriesList', inquiriesList)
    let _inquiriesList = [
        // {
        //     title: 1,
        //     name: 2,
        //     inDate: '임시',
        //     status: '임시',
        // },{
        //     title: 1,
        //     name: 2,
        //     inDate: '임시',
        //     status: '임시',
        // },{
        //     title: 1,
        //     name: 2,
        //     inDate: '임시',
        //     status: '임시',
        // },{
        //     title: 1,
        //     name: 2,
        //     inDate: '임시',
        //     status: '임시',
        // },{
        //     title: 1,
        //     name: 2,
        //     inDate: '임시',
        //     status: '임시',
        // },{
        //     title: 1,
        //     name: 2,
        //     inDate: '임시',
        //     status: '임시',
        // },{
        //     title: 1,
        //     name: 2,
        //     inDate: '임시',
        //     status: '임시',
        // },{
        //     title: 1,
        //     name: 2,
        //     inDate: '임시',
        //     status: '임시',
        // },
        ...inquiriesList
    ]
    return _inquiriesList.map((inquiry, i) => {
        return {
            title: inquiry.title,
            id: {
                noDisplay: true,
                inDate: inquiry.inDate,
              },
            name: inquiry.name,
            inDate: moment(inquiry.inDate).format('YYYY.MM.DD HH:mm'),
            status: inquiry.status === 'y' ? '답변완료' : '답변대기' ,
        }
    })
  }
);

// desc : 페이징 키 lastEvaluatedKey
export const lastEvaluatedKeySelector = createSelector(
  state => state.inquiry.lastEvaluatedKey,
  lastEvaluatedKey => lastEvaluatedKey
);

// desc
// export const contentSelector = createSelector(
//   state => state.inquiry.contents,
//   contents => ({content: contents})
// );

