import * as types from "./constant";

export function initNoticeList() {
  return {
    type: types.INIT_NOTICE_LIST
  };
}
export function initNoticeListBeforeSearching() {
  return {
    type: types.INIT_NOTICE_LIST_BEFORE_SEARCHING
  };
}

export function getNoticeList(data) {
  return {
    type: types.GET_NOTICE_LIST,
    data
  };
}
export function getNoticeListSuccess(
  importantList,
  noticeList,
  lastEvaluatedKey,
  notPaging
) {
  return {
    type: types.GET_NOTICE_LIST_SUCCESS,
    importantList,
    noticeList,
    lastEvaluatedKey,
    notPaging
  };
}
export function clickNoticeEdit({ content }, arr) {
  return {
    type: types.CLICK_NOTICE_EDIT,
    content,
    arr
  };
}

export function clickNoticeDelete(id, arr) {
  return {
    type: types.CLICK_NOTICE_DELETE,
    id,
    arr
  };
}

export function handleCreateNotice(isEditMode) {
  return {
    type: types.POST_CREATE_NOTICE,
    isEditMode
  };
}

export function postCreateNoticeSuccess() {
  return {
    type: types.POST_CREATE_NOTICE_SUCCESS
  };
}

export function setTryPostTrue(obj) {
  return {
    type: types.SET_TRY_POST_TRUE,
    obj
  };
}

export function handleCloseMsgModal() {
  return {
    type: types.HANDLE_CLOSE_MSG_MODAL
  };
}

export function confirmChangeStatus(value) {
  return {
    type: types.CONFIRM_CHANGE_STATUS,
    value
  };
}

export function saveInput(value) {
  return {
    type: types.SAVE_INPUT,
    value
  };
}

export function sortAscNotice(rows, isSearched, tableType) {
  return {
    type: types.SORT_ASC_NOTICE,
    rows,
    isSearched,
    tableType
  };
}

export function sortDescNotice(rows, isSearched, tableType) {
  return {
    type: types.SORT_DESC_NOTICE,
    rows,
    isSearched,
    tableType
  };
}

export function handleOnChange(value) {
  return {
    type: types.HANDLE_ON_CHANGE,
    value
  };
}

export function setCreateMode() {
  return {
    type: types.SET_CREATE_MODE
  };
}

export function setNoticeParams(obj) {
  return {
    type: types.SET_NOTICE_PARAMS,
    obj
  };
}

export function deleteNoticeSuccess() {
  return {
    type: types.DELETE_NOTICE_SUCCESS
  };
}

export function handleOpenMsgModal() {
  return {
    type: types.HANDLE_OPEN_MSG_MODAL
  };
}

export function getSearchKeywords(obj) {
  return {
    type: types.GET_SEARCH_KEYWORDS_NOTICE,
    obj
  };
}

export function handleErrors(obj) {
  return {
    type: types.HANDLE_ERRORS,
    obj
  };
}

export function setIsNoticeCreateModalClose(isNoticeCreateModalClose) {
  return {
    type: types.SET_IS_NOTICE_CREATE_MODAL_CLOSE,
    isNoticeCreateModalClose,
  };
}
