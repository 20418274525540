// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { LOCATION_CHANGE } from 'react-router-redux';

import * as types from './constant';

const initialState = {
  topics: [],
  list: [],
  document: undefined,
  versionList: [],
  classParentList: [],
  isClassDone: false,
  isDeleteClassDone: false,
};

function getStarted(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...initialState,
      };

    case types.INIT_GET_LIST_KEY_CONSOLE_GUIDE:
      return {
        ...state,
        isNewEachList: false,
      };

    // case types.INIT_TOPICS_CONSOLE_GUIDE:
    //   return {
    //     ...state,
    //     topics: initialState.topics,
    //     list: initialState.list,
    //     classParentList: initialState.classParentList,
    //   };

    case types.GET_TOPICS_CONSOLE_GUIDE_SUCCESS:
      return {
        ...state,
        topics: action.data,
        isClassDone: false,
        isDeleteClassDone: false,
        isDocDone: false,
        isUpdateDocDone: false,
        isDeleteDocDone: false,
        isMakeTopicDone: false,
        isDeleteTopicDone: false,
      };
    case types.GET_LIST_CONSOLE_GUIDE_SUCCESS:
      if (action.listIndex === '뒤끝 활용하기')
        console.log('action.data.data', action.data.data);
      return {
        ...state,
        isNewEachList: true,
        list: action.data.data,
        // test: { ...state.test, [action.listIndex]: action.data.data },
        test: action.data,
      };
    case types.GET_CLASS_PARENT_LIST_CONSOLE_GUIDE_SUCCESS:
      return {
        ...state,
        classParentList: action.data,
      };
    case types.GET_DOCUMENT_CONSOLE_GUIDE_SUCCESS:
      return {
        ...state,
        document: action.data,
      };
    // case types.GET_VERSION_LIST_CONSOLE_GUIDE_SUCCESS:
    //   return {
    //     ...state,
    //     versionList: action.data,
    //   };
    case types.MAKE_CLASS_CONSOLE_GUIDE_SUCCESS:
      return {
        ...state,
        isClassDone: true,
      };
    case types.UPDATE_CLASS_CONSOLE_GUIDE_SUCCESS:
      return {
        ...state,
        isClassDone: true,
      };
    case types.MAKE_DOCUMENT_CONSOLE_GUIDE_SUCCESS:
      return {
        ...state,
        isDocDone: true,
      };
    case types.DELETE_CLASS_CONSOLE_GUIDE_SUCCESS:
      return {
        ...state,
        isDeleteClassDone: true,
      };
    case types.UPDATE_DOCUMENT_CONSOLE_GUIDE_SUCCESS:
      return {
        ...state,
        isUpdateDocDone: true,
      };
    case types.DELETE_DOCUMENT_CONSOLE_GUIDE_SUCCESS:
      return {
        ...state,
        isDeleteDocDone: true,
      };

    case types.MAKE_TOPIC_CONSOLE_GUIDE_SUCCESS:
      return {
        ...state,
        isMakeTopicDone: true,
      };

    case types.UPDATE_TOPIC_CONSOLE_GUIDE_SUCCESS:
      return {
        ...state,
        isMakeTopicDone: true,
      };

    case types.DELETE_TOPIC_CONSOLE_GUIDE_SUCCESS:
      return {
        ...state,
        isDeleteTopicDone: true,
      };

    default:
      return state;
  }
}

export default getStarted;
