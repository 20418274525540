// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Nav, NavItem, NavLink, Collapse } from 'reactstrap';

import * as actions from './store/action';
import * as accountActions from '../containers/account/store/action';
import * as selectors from './store/selector';

const LeftAsideMenu = ({
  onOpenSubMenu,
  onHandleClickSubMenu,
  subMenuOpen,
  clickedSubMenu,
}) => {
  const handleClickSubMenu = (e) => {
    onHandleClickSubMenu(e.target.id);
  };

  const { href } = window.location;

  return (
    <div
      id="m_aside_left"
      className="m-grid__item m-aside-left m-aside-left--skin-dark "
    >
      <div
        id="m_ver_menu"
        className="m-aside-menu m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark "
        m-menu-vertical="1"
        m-menu-scrollable="1"
        m-menu-dropdown-timeout="500"
        style={{ position: 'relative' }}
      >
        {href.includes('landing') ? (
          // {/* 랜딩 어드민 메뉴 추가 */}
          <Nav
            className="m-menu__nav m-menu__nav--dropdown-submenu-arrow"
            vertical
          >
            <NavItem className="m-menu__item m-menu__item--submenu">
              <Link
                name="showcase"
                to="/landing/showcase"
                className={
                  subMenuOpen === 'showcase'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-connectdevelop" />
                <span className="m-menu__link-text">쇼케이스 관리</span>
              </Link>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <Link
                name="popup"
                to="/landing/popup"
                className={
                  subMenuOpen === 'popup'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-connectdevelop" />
                <span className="m-menu__link-text">팝업 관리</span>
              </Link>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <NavLink
                name="policy"
                onClick={(e) => {
                  // openPaymentSubMenu(!isPaymentSubMenuOpen);
                  onOpenSubMenu(e.target.parentNode.name);
                }}
                className={
                  subMenuOpen === 'policy'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-credit-card" />
                <span className="m-menu__link-text">정책 관리</span>
                <i className="m-menu__ver-arrow la la-angle-right" />
              </NavLink>
              <Collapse isOpen={subMenuOpen === 'policy'}>
                <div className="m-menu__subnav">
                  <Nav>
                    <NavItem className="m-menu__item ">
                      <Link to="/landing/terms" className="m-menu__link ">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="terms"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'terms'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          이용약관
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link
                        to="/landing/privacy"
                        onClick={() => {}}
                        className="m-menu__link "
                      >
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="privacy"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'privacy'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          개인정보보호정책
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link
                        to="/landing/sla"
                        onClick={() => {}}
                        className="m-menu__link "
                      >
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="sla"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'sla'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          서비스수준협약(SLA)
                        </span>
                      </Link>
                    </NavItem>
                  </Nav>
                </div>
              </Collapse>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <NavLink
                name="inquiry"
                onClick={(e) => {
                  // openPaymentSubMenu(!isPaymentSubMenuOpen);
                  onOpenSubMenu(e.target.parentNode.name);
                }}
                className={
                  subMenuOpen === 'inquiry'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-credit-card" />
                <span className="m-menu__link-text">문의 관리</span>
                <i className="m-menu__ver-arrow la la-angle-right" />
              </NavLink>
              <Collapse isOpen={subMenuOpen === 'inquiry'}>
                <div className="m-menu__subnav">
                  <Nav>
                    <NavItem className="m-menu__item ">
                      <Link to="/landing/inquiry" className="m-menu__link ">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="inquiry"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'inquiry'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          일반 문의
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link to="/landing/inquiryGDC" className="m-menu__link ">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="inquiryGDC"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'inquiryGDC'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          GDC 문의
                        </span>
                      </Link>
                    </NavItem>
                  </Nav>
                </div>
              </Collapse>
            </NavItem>
          </Nav>
        ) : href.includes('developer') ? (
          <Nav
            className="m-menu__nav m-menu__nav--dropdown-submenu-arrow"
            vertical
          >
            <NavItem className="m-menu__item m-menu__item--submenu">
              <Link
                name="getStarted"
                to="/developer/getStarted"
                className={
                  subMenuOpen === 'getStarted'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-connectdevelop" />
                <span className="m-menu__link-text">시작하기</span>
              </Link>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <Link
                name="docs"
                to="/developer/consoleGuide"
                className={
                  subMenuOpen === 'consoleGuide'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-connectdevelop" />
                <span className="m-menu__link-text">콘솔 가이드</span>
              </Link>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <NavLink
                name="sdkDocs"
                onClick={(e) => {
                  // openPaymentSubMenu(!isPaymentSubMenuOpen);
                  onOpenSubMenu(e.target.parentNode.name);
                }}
                className={
                  subMenuOpen === 'sdkDocs'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-credit-card" />
                <span className="m-menu__link-text">SDK 문서</span>
                <i className="m-menu__ver-arrow la la-angle-right" />
              </NavLink>
              <Collapse isOpen={subMenuOpen === 'sdkDocs'}>
                <div className="m-menu__subnav">
                  <Nav>
                    <NavItem className="m-menu__item ">
                      <Link
                        to="/developer/sdkDocs/backend"
                        className="m-menu__link "
                      >
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="backend"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'backend'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          뒤끝 SDK
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link
                        to="/developer/sdkDocs/backendFunction"
                        onClick={() => {}}
                        className="m-menu__link "
                      >
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="backendFunction"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'backendFunction'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          뒤끝펑션 SDK
                        </span>
                      </Link>
                    </NavItem>
                  </Nav>
                </div>
              </Collapse>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <Link
                name="docs"
                to="/developer/versionManaging"
                className={
                  subMenuOpen === 'versionManaging'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-connectdevelop" />
                <span className="m-menu__link-text">업데이트 노트</span>
              </Link>
            </NavItem>
          </Nav>
        ) : (
          <Nav
            className="m-menu__nav m-menu__nav--dropdown-submenu-arrow"
            vertical
          >
            <NavItem className="m-menu__item m-menu__item--submenu">
              <NavLink
                className={
                  subMenuOpen === 'account'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
                name="account"
                onClick={(e) => {
                  // openSubMenu(!isAccountSubMenuOpen);
                  onOpenSubMenu(e.target.parentNode.name);
                }}
              >
                <i className="m-menu__link-icon la la-user" />
                <span className="m-menu__link-text">계정 관리</span>
                <i className="m-menu__ver-arrow la la-angle-right" />
              </NavLink>
              <Collapse isOpen={subMenuOpen === 'account'}>
                <div className="m-menu__subnav">
                  <Nav>
                    <NavItem className="m-menu__item ">
                      <Link to="/accountManaging" className="m-menu__link ">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="generalAccount"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'generalAccount'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          일반 계정
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link to="/sleepingCandidates" className="m-menu__link ">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="sleepingCandidates"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'sleepingCandidates'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          휴면 예정 계정
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link to="/sleepers" className="m-menu__link ">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="sleepers"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'sleepers'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          휴면 계정
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link to="/deleteAccount" className="m-menu__link ">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="deleteAccount"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'deleteAccount'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          탈퇴 계정
                        </span>
                      </Link>
                    </NavItem>
                  </Nav>
                </div>
              </Collapse>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <NavLink
                className={
                  subMenuOpen === 'projects'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
                name="projects"
                onClick={(e) => {
                  // openSubMenu(!isAccountSubMenuOpen);
                  onOpenSubMenu(e.target.parentNode.name);
                }}
              >
                <i className="m-menu__link-icon la la-user" />
                <span className="m-menu__link-text">프로젝트 관리</span>
                <i className="m-menu__ver-arrow la la-angle-right" />
              </NavLink>
              <Collapse isOpen={subMenuOpen === 'projects'}>
                <div className="m-menu__subnav">
                  <Nav>
                    <NavItem className="m-menu__item ">
                      <Link to="/projects" className="m-menu__link ">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="projects"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'projects'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          정상 프로젝트
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link to="/deletedProjects" className="m-menu__link ">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="deletedProjects"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'deletedProjects'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          삭제 프로젝트
                        </span>
                      </Link>
                    </NavItem>
                  </Nav>
                </div>
              </Collapse>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <NavLink
                name="payment"
                onClick={(e) => {
                  // openPaymentSubMenu(!isPaymentSubMenuOpen);
                  onOpenSubMenu(e.target.parentNode.name);
                }}
                className={
                  subMenuOpen === 'payment'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-credit-card" />
                <span className="m-menu__link-text">결제 관리</span>
                <i className="m-menu__ver-arrow la la-angle-right" />
              </NavLink>
              <Collapse isOpen={subMenuOpen === 'payment'}>
                <div className="m-menu__subnav">
                  <Nav>
                    <NavItem className="m-menu__item ">
                      <Link to="/currentCharge" className="m-menu__link ">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="currentCharge"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'currentCharge'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          현재 요금(수익쉐어)
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link
                        to="/currentChargePayPerUse"
                        className="m-menu__link "
                      >
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="currentChargePayPerUse"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'currentChargePayPerUse'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          현재 요금(종량제)
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link
                        to="/chargedBill"
                        onClick={() => {}}
                        className="m-menu__link "
                      >
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="chargedBill"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'chargedBill'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          청구 내역
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link
                        to="/overdueBill"
                        onClick={() => {}}
                        className="m-menu__link "
                      >
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="overdueBill"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'overdueBill'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          연체 내역
                        </span>
                      </Link>
                    </NavItem>
                  </Nav>
                </div>
              </Collapse>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <Link
                to="/indicators"
                name="indicators"
                onClick={(e) => {
                  onOpenSubMenu(e.target.parentNode.name);
                }}
                className={
                  subMenuOpen === 'indicators'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-ticket" />
                <span className="m-menu__link-text">요금 관리</span>
              </Link>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <Link
                name="coupon"
                onClick={(e) => {
                  // openSubMenu(!isAccountSubMenuOpen);
                  onOpenSubMenu(e.target.parentNode.name);
                }}
                to="/coupon"
                className={
                  subMenuOpen === 'coupon'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-ticket" />
                <span className="m-menu__link-text">쿠폰 관리</span>
              </Link>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <Link
                name="notice"
                onClick={(e) => {
                  // openSubMenu(!isAccountSubMenuOpen);
                  onOpenSubMenu(e.target.parentNode.name);
                }}
                to="/notice"
                className={
                  subMenuOpen === 'notice'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-ticket" />
                <span className="m-menu__link-text">공지사항 관리</span>
              </Link>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <Link
                name="popup"
                onClick={(e) => {
                  onOpenSubMenu(e.target.parentNode.name);
                }}
                to="/popup"
                className={
                  subMenuOpen === 'popup'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-ticket" />
                <span className="m-menu__link-text">팝업 관리</span>
              </Link>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <NavLink
                name="email"
                onClick={(e) => {
                  // openSubMenu(!isAccountSubMenuOpen);
                  onOpenSubMenu(e.target.parentNode.name);
                }}
                className={
                  subMenuOpen === 'email'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-credit-card" />
                <span className="m-menu__link-text">메일 관리</span>
                <i className="m-menu__ver-arrow la la-angle-right" />
              </NavLink>
              <Collapse isOpen={subMenuOpen === 'email'}>
                <div className="m-menu__subnav">
                  <Nav>
                    <NavItem className="m-menu__item ">
                      <Link to="/sendEmail" className="m-menu__link ">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="sendEmail"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'sendEmail'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          메일 발송
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link
                        to="/emailList"
                        onClick={() => {}}
                        className="m-menu__link "
                      >
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="emailList"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'emailList'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          발송 내역
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link
                        to="/email"
                        onClick={() => {}}
                        className="m-menu__link "
                      >
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="email"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'email'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          자동 발송
                        </span>
                      </Link>
                    </NavItem>
                  </Nav>
                </div>
              </Collapse>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <NavLink
                name="statistics"
                onClick={(e) => {
                  // openStatSubMenu(!isStatSubMenuOpen);
                  onOpenSubMenu(e.target.parentNode.name);
                }}
                className={
                  subMenuOpen === 'statistics'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-credit-card" />
                <span className="m-menu__link-text">통계</span>
                <i className="m-menu__ver-arrow la la-angle-right" />
              </NavLink>
              <Collapse isOpen={subMenuOpen === 'statistics'}>
                <div className="m-menu__subnav">
                  <Nav>
                    <NavItem className="m-menu__item ">
                      <Link
                        to="/customers"
                        onClick={() => {}}
                        className="m-menu__link "
                      >
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="totalStat"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'totalStat'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          종합지표
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link
                        to="/statOfProjects"
                        onClick={() => {}}
                        className="m-menu__link "
                      >
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="pjtStat"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'pjtStat'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          프로젝트
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link
                        to="/retention"
                        onClick={() => {}}
                        className="m-menu__link "
                      >
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="retention"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'retention'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          리텐션
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link
                        to="/sales"
                        onClick={() => {}}
                        className="m-menu__link "
                      >
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="sales"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'sales'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          거래액
                        </span>
                      </Link>
                    </NavItem>
                    <NavItem className="m-menu__item ">
                      <Link
                        to="/theBackendSales"
                        onClick={() => {}}
                        className="m-menu__link "
                      >
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span />
                        </i>
                        <span
                          id="theBackendSales"
                          onClick={handleClickSubMenu}
                          className={
                            clickedSubMenu === 'theBackendSales'
                              ? 'm-menu__link-text subText clickedSubMenu'
                              : 'm-menu__link-text subText'
                          }
                        >
                          매출
                        </span>
                      </Link>
                    </NavItem>
                  </Nav>
                </div>
              </Collapse>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <Link
                to="/decoding"
                name="decoding"
                onClick={(e) => {
                  // openSubMenu(!isAccountSubMenuOpen);
                  onOpenSubMenu(e.target.parentNode.name);
                }}
                className={
                  subMenuOpen === 'decoding'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-ticket" />
                <span className="m-menu__link-text">키바나 디코더</span>
              </Link>
            </NavItem>
            <NavItem className="m-menu__item m-menu__item--submenu">
              <Link
                to="/dataExtraction"
                name="dataExtraction"
                onClick={(e) => {
                  onOpenSubMenu(e.target.parentNode.name);
                }}
                className={
                  subMenuOpen === 'dataExtraction'
                    ? 'm-menu__link m-menu__toggle clickedMenu'
                    : 'm-menu__link m-menu__toggle'
                }
              >
                <i className="m-menu__link-icon la la-ticket" />
                <span className="m-menu__link-text">데이터 추출</span>
              </Link>
            </NavItem>
          </Nav>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  statisticsData: selectors.initGameStatSelector,
  subMenuOpen: selectors.openSubMenuSelector,
  clickedSubMenu: selectors.clickedSubMenuSelector,
  isLanding: selectors.isLandingSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onMoveToDeleteAccount: (type) => dispatch(actions.moveToDeleteAccount(type)),
  onInitAccountList: () => dispatch(accountActions.initAccountList()),
  onInitDeletedAccountList: () =>
    dispatch(accountActions.initDeletedAccountList()),
  onOpenSubMenu: (obj) => dispatch(actions.openSubMenu(obj)),
  onHandleClickSubMenu: (obj) => dispatch(actions.handleClickSubMenu(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftAsideMenu);
