import { LOCATION_CHANGE } from 'react-router-redux';

const initialState = {
	decodedStr: [],
};

function kibana(state = initialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case 'SAVE_DECOMPRESSED':
			return {
				...state,
				decodedStr: action.params
					? [...state.decodedStr, action.params]
					: initialState.decodedStr,
			};

		default:
			return state;
	}
}

export default kibana;
