import { call, put, takeLatest, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import clone from 'ramda/src/clone';
import moment from 'moment';

import * as types from './constant';

import * as actions from './action';
import { openSubMenu, handleClickSubMenu } from '../../../../view/store/action';
import * as selectors from './selector';
import * as api from '../../../../service/API';

function* locationChange({ payload }) {
	try {
		const { pathname } = yield payload;
		const pathArray = pathname.split('/');
		// console.log(pathArray[1], "in projects saga");
		if (pathArray[1] === 'statOfProjects') {
			yield put(openSubMenu('statistics'));
			yield put(handleClickSubMenu('pjtStat'));
		}
	} catch (err) {
		console.log('saga err', err);
	}
}

export function* watcherLocationChange() {
	yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* handleSearchProject({ title, date, opt, gameId }) {
	try {
		const statType = yield select(selectors.statTypeSelector);

		if (gameId) {
			if (statType && statType.status === '월별') {
				yield put(actions.getMonthlyStatisticsProjects(gameId, date, opt));
			} else if (statType && statType.status === '일별') {
				yield put(actions.getDailyStatisticsProjects(gameId, date, opt));
			}
			return;
		}
		const {
			data: { projectList },
			//   data: { isLast, projectList }
		} = yield call(api.projects.getProjects, { title });

		if (projectList && projectList.length > 1) {
			// 동일 프로젝트명 다수 존재 시
			yield put(actions.handleMultipleSearch(projectList));
			return;
		}

		yield put(actions.handleSearchSuccess(projectList));
		const { projectId } = projectList[0];

		if (statType && statType.status === '월별') {
			yield put(actions.getMonthlyStatisticsProjects(projectId, date, opt));
		} else if (statType && statType.status === '일별') {
			yield put(actions.getDailyStatisticsProjects(projectId, date, opt));
		}
	} catch (error) {
		console.log(error);
		console.log(error && error.response);
	}
}

export function* watcherHandleSearchProject() {
	yield takeLatest(types.HANDLE_SEARCH_PROJECT, handleSearchProject);
}

function* getDailyStatisticsProjects({ projectId, date, opt }) {
	try {
		let dateChk;
		for (const key in date) {
			if (!date[key]) {
				dateChk = false;
			} else {
				dateChk = true;
			}
		}
		console.log('날짜 검색 여부:', dateChk);
		let params;
		if (!opt) {
			const _endDate = clone(date.endDate);
			params = {
				startDate:
					date.startDate && date.startDate.set({ hour: 0 }).toISOString(),
				endDate: _endDate.set({ hour: 0 }).toISOString(),
			};
		}
		if (dateChk && opt) {
			const _endDate = clone(date.endDate);
			params = {
				startDate:
					date.startDate && date.startDate.set({ hour: 0 }).toISOString(),
				endDate: _endDate.add(1, 'days').set({ hour: 0 }).toISOString(),
			};
		}

		const {
			data: { isLast, dailyStat },
		} = yield call(api.projects.getDailyStatisticsProjects, projectId, params);
		const _data = dailyStat.map((row) => ({ ...row, Date: moment(row.Date) }));
		// console.log('***opt 확인 필요***', opt);
		// if (opt)
		if (false)
			return yield put(
				actions.getSearchedDailyStatisticsProjectsSuccess(_data, opt)
			);

		yield put(
			actions.getDailyStatisticsProjectsSuccess(_data, opt, isLast, dateChk)
		);
	} catch (error) {
		console.log(error);
		console.log(error && error.response);
	}
}

export function* watcherGetDailyStatisticsProjects() {
	yield takeLatest(types.GET_DAILY_STAT_PROJECTS, getDailyStatisticsProjects);
}

function* getMonthlyStatisticsProjects({ projectId, date, opt }) {
	try {
		// console.log(date, "date 확인");

		const {
			data: { monthlyStat, isLast },
		} = yield call(api.projects.getMonthlyStatisticsProjects, projectId);
		const _data = monthlyStat.map((row) => ({
			...row,
			Date: moment(row.Date),
		}));
		yield put(actions.getMonthlyStatisticsProjectsSuccess(_data, opt, isLast));
	} catch (error) {
		console.log(error);
		console.log(error && error.response);
	}
}

export function* watcherGetMonthlyStatisticsProjects() {
	yield takeLatest(
		types.GET_MONTHLY_STAT_PROJECTS,
		getMonthlyStatisticsProjects
	);
}

function* clickProjectRow({ arr }) {
	try {
		const statType = yield select(selectors.statTypeSelector);
		const searching = yield select(selectors.searchingSelector);
		console.log(searching, 'CLICK_PROJECT_ROW');
		const date = searching ? searching.date : null;

		const {
			projectId: { projectId },
		} = arr.filter((obj) => obj.projectId)[0];
		if (statType === '월별' || statType.status === '월별')
			yield put(
				actions.getMonthlyStatisticsProjects(projectId, null, {
					selected: true,
				})
			);
		if (statType === '일별' || statType.status === '일별')
			yield put(
				actions.getDailyStatisticsProjects(projectId, date, { selected: true })
			);
		yield put(actions.clickProjectRowSuccess(projectId));
	} catch (error) {
		console.log(error);
	}
}

export function* watcherClickProjectRow() {
	yield takeLatest(types.CLICK_PROJECT_ROW, clickProjectRow);
}

// function* getOverdueManageRow({ id, arr }) {
// 	const accountList = yield select(selectors.accountListSelector);
// 	const filtered = accountList.filter(account => account.ID === arr[0].ID)[0];
// 	yield put(actions.getOverdueManageRowSuccess(filtered));
// }

// export function* watcherGetOverdueManageRow() {
// 	yield takeLatest(types.CLICK_OVERDUE_BILL_ROW, getOverdueManageRow);
// }
