// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { createSelector } from 'reselect';
import moment from 'moment';

export const popupListSelectorForView = createSelector(
  (state) => state.consolePopup.popupList,
  (popupList) => {
    const today = moment();

    return popupList.map((popup) => {
      const startDate = moment(popup.startDate).format('YYYY-MM-DD HH:mm');
      const endDate = moment(popup.endDate).format('YYYY-MM-DD HH:mm');
      const indate = moment(popup.indate).format('YYYY.MM.DD HH:mm');

      return {
        name: popup.name ?? 'temp name', // 팝업 이름
        title: popup.title, // 팝업 제목
        authorName: popup.authorName, // 작성자
        indate, // 등록일시
        _indate: { noDisplay: true, indate: popup.indate },
        date: `${startDate} - ${endDate}`, // 기간
        language: popup.language,
        isTest: popup.isTest === 'y' ? '테스트 계정' : '라이브',
        isImportant: popup.isImportant === 'y' ? '맨 처음' : '자동',
        status: today.isBefore(startDate)
          ? '예약'
          : today.isAfter(startDate) && today.isBefore(endDate)
          ? '진행중'
          : '종료',
        id: { noDisplay: true, value: popup.id },
      };
    });
  }
);
export const popupSelector = createSelector(
  (state) => state.consolePopup.selectedPopup,
  (selectedPopup) => {
    if (selectedPopup)
      return {
        id: selectedPopup.id,
        name: selectedPopup.name,
        title: selectedPopup.title,
        image: selectedPopup.image,
        startDate: moment(selectedPopup.startDate).format('YYYY-MM-DDTHH:mm'),
        endDate: moment(selectedPopup.endDate).format('YYYY-MM-DDTHH:mm'),
        url: selectedPopup.url,
        linkOpen: selectedPopup.linkOpen,
        language: selectedPopup.language ?? '국문',
        isTest: selectedPopup.isTest,
        isImportant: selectedPopup.isImportant,
        popupContents: selectedPopup.content,
      };
    return null;
  }
);

export const popupInDateSelector = createSelector(
  (state) => state.consolePopup.selectedPopup,
  (selectedPopup) => {
    if (selectedPopup) return selectedPopup.indate;
    return null;
  }
);

export const insertDoneSelector = createSelector(
  (state) => state.consolePopup.insertDone,
  (insertDone) => insertDone
);

export const lastEvaluatedKeySelector = createSelector(
  (state) => state.consolePopup.lastEvaluatedKey,
  (lastEvaluatedKey) => lastEvaluatedKey
);

export const errorsSelector = createSelector(
  (state) => state.consolePopup.errors,
  (errors) => errors
);
