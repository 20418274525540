/*
 * Copyright 2013-2022 AFI, Inc. All Rights Reserved.
 */

import React from 'react';
import { Button } from 'reactstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const ExportCSV = ({
  csvData,
  fileName,
  ids,
  turnoverAccount,
  turnoverGame,
}) => {
  const fileType = 'text/csv;charset=utf8';
  const fileExtension = '.csv';

  const exportToCSV = (csvData, fileName) => {
    const tmp = csvData.map((row) => Object.entries(row));

    const newArr = [];

    for (let index = 0; index < tmp.length; index++) {
      const element = tmp[index];

      let obj = {};

      element.forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
          const { csvValue } = value;
          value = csvValue;
        }
        obj[key] = value;
      });
      newArr.push(obj);
    }

    const ws = XLSX.utils.json_to_sheet(newArr);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const exportToCSVWithSheets = (obj, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const ws1 = XLSX.utils.json_to_sheet(obj.turnoverAccount);
    const ws2 = XLSX.utils.json_to_sheet(obj.turnoverGame);

    const wb = {
      Sheets: { data: ws },
      SheetNames: ['data'],
    };

    // XLSX.utils.book_append_sheet(wb, ws1, ws_name);
    XLSX.utils.book_append_sheet(wb, ws1, '거래액_회사');
    XLSX.utils.book_append_sheet(wb, ws2, '거래액_프로젝트');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    // console.log(data, "csvData");
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      variant="warning"
      onClick={(e) => {
        if (ids) ids();
        else if (turnoverAccount && turnoverGame) {
          exportToCSVWithSheets({ turnoverAccount, turnoverGame }, fileName);
        } else exportToCSV(csvData, fileName);
      }}
    >
      {ids ? '현황' : 'CSV'}
    </Button>
  );
};

export default ExportCSV;
