import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import DraftEditor from './_mdEditor';

import 'react-mde/lib/styles/css/react-mde-all.css';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from './store/action';
import * as selectors from './store/selector';

const CouponIssueModal = ({
  isNoticeCreateModalOpen,
  handleOpenNoticeCreateModal,
  isEditMode,
  onHandleCreateNotice,
  onHandleOnChange,
  _content,
  onSetNoticeParams,
  errMsg,
}) => {
  const setNoticeParams = (e) => {
    const obj = { [e.target.name]: e.target.value };

    switch (e.target.name) {
      case 'title':
      case 'type':
      case 'isPublic':
      case 'isTest':
      case 'language':
        onSetNoticeParams(obj);
        break;

      case 'isImportant':
        onSetNoticeParams({
          [e.target.name]: e.target.value === 'n' ? 'y' : 'n',
        });
        break;
      case 'isHTML':
        onSetNoticeParams({ [e.target.name]: !_content.isHTML });
        break;

      default:
        break;
    }
  };

  return (
    <Modal
      //   className="modal-xl"
      size="xl"
      isOpen={isNoticeCreateModalOpen}
    >
      <ModalHeader>공지 등록</ModalHeader>
      <ModalBody>
        {/* a row for INPUT */}
        <div className="form-group m-form__group row">
          <label for="noticeTitle" className="col-lg-2 col-form-label">
            제목 *
          </label>
          <div className="col-lg-10">
            <input
              onChange={setNoticeParams}
              type="type"
              className="form-control"
              id="noticeTitle"
              name="title"
              value={_content.title}
              required
            />
            <div className="error message visible">
              {errMsg && errMsg.title}
            </div>
          </div>
        </div>
        <div className="form-group m-form__group row">
          <label for="credit-name" className="col-lg-2 col-form-label">
            분류
          </label>
          <div className="col-lg-10">
            <select
              className="custom-select form-control m-input"
              data-col-index="8"
              name="type"
              onChange={setNoticeParams}
              value={_content.type}
            >
              <option value="공지">공지</option>
              <option value="긴급공지">긴급공지</option>
              <option value="업데이트">업데이트</option>
              <option value="이벤트">이벤트</option>
              <option value="안내">안내</option>
            </select>
          </div>
        </div>

        <div className="form-group m-form__group row">
          <label for="language" className="col-lg-2 col-form-label">
            언어
          </label>
          <div className="col-lg-10">
            <div className="m-radio-inline">
              <label className="m-radio">
                <input
                  type="radio"
                  name="language"
                  onChange={setNoticeParams}
                  checked={_content.language === '국문'}
                  value="국문"
                />
                국문
                <span />
              </label>
              <label className="m-radio">
                <input
                  type="radio"
                  name="language"
                  onChange={setNoticeParams}
                  checked={_content.language === '영문'}
                  value="영문"
                />
                영문
                <span />
              </label>
            </div>
          </div>
        </div>

        <DraftEditor content={_content} onHandleOnChange={onHandleOnChange} />
        <div className="error message visible">{errMsg && errMsg.content}</div>
        <div className="form-group m-form__group row">
          <label for="isTest" className="col-lg-2 col-form-label">
            노출
          </label>
          <div className="col-lg-10">
            <div className="m-radio-inline">
              <label className="m-radio">
                <input
                  type="radio"
                  name="isTest"
                  onChange={setNoticeParams}
                  checked={_content.isTest === 'y'}
                  value="y"
                />
                테스트 계정
                <span />
              </label>
              <label className="m-radio">
                <input
                  type="radio"
                  name="isTest"
                  onChange={setNoticeParams}
                  checked={_content.isTest === 'n'}
                  value="n"
                />
                라이브
                <span />
              </label>
            </div>
          </div>
        </div>
        <div className="form-group m-form__group row">
          <label for="email-contents" className="col-lg-2 col-form-label">
            상태
          </label>
          <div className="col-lg-10">
            <div className="m-radio-inline">
              <label className="m-radio">
                <input
                  type="radio"
                  name="isPublic"
                  onChange={setNoticeParams}
                  checked={_content.isPublic === 'y'}
                  value="y"
                />
                공개
                <span />
              </label>
              <label className="m-radio">
                <input
                  type="radio"
                  name="isPublic"
                  onChange={setNoticeParams}
                  checked={_content.isPublic === 'n'}
                  value="n"
                />
                비공개
                <span />
              </label>
              <div className="isImportant">
                <input
                  value={_content.isImportant}
                  checked={_content.isImportant === 'y'}
                  type="checkbox"
                  name="isImportant"
                  onChange={setNoticeParams}
                />
                상단 고정
                <span />
              </div>
              {/* </label> */}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() =>
            // onSetTryPostTrue({ title, type, isPublic, isImportant })
            onHandleCreateNotice(isEditMode)
          }
        >
          등록
        </Button>{' '}
        <Button
          color="primary"
          onClick={() => handleOpenNoticeCreateModal(false)}
        >
          취소
        </Button>{' '}
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  //   overdueBillInfo: selectors.overdueBillInfoSelector,
  isMsgModalOpen: selectors.isMsgModalOpenSelector,
  tryPost: selectors.tryPostSelector,
  editable: selectors.editableSelector,
  isNoticeCreateModalClose: selectors.isNoticeCreateModalCloseSelector,
  _content: selectors._contentSelector,
  errMsg: selectors.errMsgSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onHandleCloseMsgModal: () => dispatch(actions.handleCloseMsgModal()),
  onHandleCreateNotice: (obj, isEditMode) =>
    dispatch(actions.handleCreateNotice(obj, isEditMode)),
  onSetTryPostTrue: (obj) => dispatch(actions.setTryPostTrue(obj)),
  onHandleOnChange: (value) => dispatch(actions.handleOnChange(value)),
  onSetNoticeParams: (obj) => dispatch(actions.setNoticeParams(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponIssueModal);
