import React, { Component } from "react";

import { Table } from "reactstrap";
import Octicon, { X, TriangleUp, TriangleDown } from "@primer/octicons-react";

import clone from "ramda/src/clone";

class _DataTableCopy extends Component {
  sortAsc = param => {
    const {
      requiredAction,
      secondRequiredAction,
      accountDetail,
      onSortAsc,
      onSortDesc,
      sortingColumns,
      actualData,
      deleteRow,
      selectedId,
      isSearched,
      tableType,
      columns,
      sortingParam
    } = this.props;

    let sortingField;
    const clonedRows = clone(actualData);

    columns.forEach(elem => {
      if (elem.label === param) {
        sortingField = elem.field;
      }
    });

    // console.log('to sort getSortField', sortingField);
    // console.log('getSortField', actualData[0]);

    if (sortingField.includes("Date")) {
      // 날짜 열인 경우

      console.log(clonedRows[0], "to sort clonedRows");
      const _rows = clonedRows.sort((a, b) => {
        if (a[sortingField].isBefore(b[sortingField])) {
          console.log(a, b, "true일때");
          return -1;
        }

        if (a[sortingField].isAfter(b[sortingField])) {
          // console.log(a, b, 'false일때');
          return 1;
        }

        // return a[sortingField].isAfter(b[sortingField]);
      });
      onSortAsc(_rows, isSearched, tableType);
      return;
    }

    const _rows = clonedRows.sort((a, b) => {
      if (a[sortingField] === "-" && b[sortingField] !== "-") return -1;
      if (a[sortingField] !== "-" && b[sortingField] === "-") return 1;
      if (b[sortingField] === "-" && b[sortingField] === "-") return 0;

      return a[sortingField] - b[sortingField];
    });
    onSortAsc(_rows, isSearched, tableType);
  };

  sortDesc = param => {
    const {
      requiredAction,
      secondRequiredAction,
      accountDetail,
      onSortAsc,
      onSortDesc,
      sortingColumns,
      actualData,
      deleteRow,
      selectedId,
      isSearched,
      tableType,
      columns,
      sortingParam
    } = this.props;

    let sortingField;
    const clonedRows = clone(actualData);
    columns.forEach(elem => {
      if (elem.label === param) {
        sortingField = elem.field;
      }
    });

    // console.log("getSortField", sortingField);
    if (sortingField.includes("Date")) {
      // 날짜 열인 경우
      //   console.log(clonedRows, "clonedRows");
      const _rows = clonedRows.sort((a, b) => {
        // 최종 접속일 값이 null일 경우, 예외처리
        // console.log(a, 'a');
        // console.log(b, 'b');

        if (a[sortingField].isBefore(b[sortingField])) {
          return 1;
          // return -1;
        }

        if (a[sortingField].isAfter(b[sortingField])) {
          return -1;
          // return 1;
        }

        // return a[sortingField].isAfter(b[sortingField]);
      });
      onSortDesc(_rows, isSearched, tableType);
      return;
    }
    // const sortingField = getSortField;
    // const sortingField2 = 'endRank';
    const _rows = clonedRows.sort((a, b) => {
      if (a[sortingField] === "-" && b[sortingField] !== "-") return -1;
      if (a[sortingField] !== "-" && b[sortingField] === "-") return 1;
      if (b[sortingField] === "-" && b[sortingField] === "-") return 0;

      return b[sortingField] - a[sortingField];
    }); // sort 결과는 원본을 수정함 -> 리턴할 필요 없음
    onSortDesc(_rows, isSearched, tableType);
  };

  makeColumsRow = columns => {
    const {
      requiredAction,
      secondRequiredAction,
      accountDetail,
      onSortAsc,
      onSortDesc,
      sortingColumns,
      actualData,
      deleteRow,
      selectedId,
      isSearched,
      tableType,
      sortingParam
    } = this.props;

    if (!columns.length) return;
    if (columns.length)
      return columns.map((column, i) => {
        const forKey = i;

        const { label } = column;

        const isSortable = sortingColumns
          ? sortingColumns.includes(column.label)
          : null;
        return (
          <React.Fragment>
            <th className={label} key={forKey}>
              {label}
              {isSortable ? (
                <span className="sortingIconSet">
                  <span
                    // onClick={e => sortAsc(e.currentTarget.id)}
                    id={label}
                    className="sortingIcon"
                  >
                    <Octicon
                      // className={label}
                      verticalAlign="text-top"
                      icon={TriangleUp}
                    ></Octicon>
                  </span>
                  <span
                    onClick={e => {
                      if (!sortingParam)
                        return this.sortAsc(e.currentTarget.id);
                      // if (sortingParam) return sortDesc(e.currentTarget.id);
                      this.sortDesc(e.currentTarget.id);
                    }}
                    id={label}
                    className="sortingIcon"
                  >
                    <Octicon
                      onClick={this.sortDesc}
                      verticalAlign="text-bottom"
                      icon={TriangleDown}
                    ></Octicon>
                  </span>
                </span>
              ) : null}
            </th>
          </React.Fragment>
        );
      });
  };

  makeRows = rows => {
    const {
      requiredAction,
      secondRequiredAction,
      accountDetail,
      onSortAsc,
      onSortDesc,
      sortingColumns,
      actualData,
      deleteRow,
      selectedId,
      isSearched,
      tableType,
      columns,
      sortingParam
    } = this.props;

    if (!rows.length)
      return (
        <tr>
          <td>데이터가 없습니다.</td>
        </tr>
      );
    if (rows.length) {
      const rowsSet = rows.map(row => {
        let tempArr = [];
        for (let key in row) {
          tempArr = [...tempArr, { [key]: row[key] }];
        }

        if (deleteRow)
          tempArr.push({
            deleteRow: (
              <span>
                <Octicon icon={X} />
              </span>
            )
          });
        return tempArr.map((key, i, arr) => {
          const forKey = i;

          // key가 객체일 때 예외처리 -- 추후에 수정할 필요성이 발생할 수 있음 ㅜㅜㅜ
          if (!Object.values(key)[0])
            return <td key={forKey}>{Object.values(key)[0]}</td>;
          if (Object.values(key)[0] && !Object.values(key)[0].noDisplay)
            return (
              <td
                key={forKey}
                className={
                  (requiredAction && !i) ||
                  (secondRequiredAction && i === arr.length - 1)
                    ? "clickable-td"
                    : null
                }
                onClick={e => {
                  if (requiredAction && !i) {
                    // console.log(arr[1].account_id.account_id, '+arr+');
                    let id;
                    if (arr[1].account_id) id = arr[1].account_id.account_id;
                    if (accountDetail) return requiredAction(id, accountDetail);
                    else requiredAction(key, arr);
                  }
                  if (secondRequiredAction && i === arr.length - 1)
                    secondRequiredAction(key, arr, selectedId);
                }}
              >
                {Object.values(key)[0]}
              </td>
            );
        });
      });

      return rowsSet.map((row, i) => {
        const forKey = i;
        return <tr key={forKey}>{row}</tr>;
      });
    }
  };

  render() {
    const { columns, rows, id, customClassName } = this.props;
    return (
      <Table className={customClassName} id="table_dataTable">
        <thead className={customClassName} id={id ? id : null}>
          <tr>{columns && this.makeColumsRow(columns)}</tr>
        </thead>
        <tbody className={customClassName} id="tableBody">
          {rows && this.makeRows(rows)}
        </tbody>
      </Table>
    );
  }
}

export default _DataTableCopy;
