// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Table } from 'reactstrap';
import moment from 'moment';
import { DynamoDB } from 'aws-sdk';

import PayPerUseDetailModal from './PayPerUseDetailModal';
import MsgModal from '../../../fnComponents/MsgModal';
import ButtonDropdown from '../../../fnComponents/ButtonDropdown';

import * as actions from './store/action';
import * as selectors from './store/selector';
import { numberToString } from '../../../shared/common';

const CurrentChargeDetail = ({
  handleCloseProjectModal,
  onConfirmChangeStatus,
  onGetBackToMainTable,
  onGetCurrentGameBill,
  onAddExtraBill,
  onUpdateExtraBill,
  onDeleteExtraBill,
  detailColumns,
  projectChargeList,
  accountBill,
  gameBill,
  gameTitle,
  account,
  status,
  projectListForDropdown,
  searchKeywords,
}) => {
  const { list, chargePrice, beforeDC, freePrice, calculatedSum } = useSelector(
    selectors.currentChargeSelector
  );
  const [period, setPeriod] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      searchKeywords &&
      searchKeywords.status &&
      searchKeywords.status === '이달'
    ) {
      setPeriod(`${moment(1, 'DD').format('YYYY.MM.DD')} -
        ${moment().format('YYYY.MM.DD')}`);
    }
    if (
      searchKeywords &&
      searchKeywords.status &&
      searchKeywords.status === '지난달'
    ) {
      const now = moment().subtract(1, 'month');
      const endOfTheMonth = now.endOf('month').date();
      // const thisMonth = now.month()
      // console.log(endOfTheMonth, now, '날짜정보');

      setPeriod(`${moment(1, 'DD').subtract(1, 'month').format('YYYY.MM.DD')} -
        ${moment()
          .set({ date: endOfTheMonth, month: now.month() })
          .format('YYYY.MM.DD')}`);
    }
  }, [searchKeywords]);

  const [whatToShowInDetail, handleClick] = useState([]);
  const [isOpen, handleOpenModal] = useState(false);
  // const [billType, handleCheckBillType] = useState('');

  const handleCollapse = (e, detail) => {
    if (whatToShowInDetail.includes(e.target.id.toString())) {
      const filtered = whatToShowInDetail.filter(
        (id) => id !== e.target.id.toString()
      );
      return handleClick(filtered);
    } else handleClick([...whatToShowInDetail, e.target.id.toString()]);
  };

  const makeColumsRow = (columns) => {
    if (columns.length)
      return columns.map((column, i) => {
        const forKey = i;
        return <th key={forKey}>{column.label}</th>;
      });
  };

  const makeRows = (rows) => {
    if (rows.length) {
      const rowsSet = rows.map((row, i) => {
        const forKey = i;
        return (
          <React.Fragment key={forKey}>
            {/* 게임타이틀이 포함되는 로우 생성 */}
            <tr>
              <td className="clickable-td" id={forKey} onClick={handleCollapse}>
                {row.gameTitle}
              </td>
              <td>{replaceLocaleString(row.beforeDC, 0)}</td>
              <td>
                {row.freePrice > 0 ? '-' : null}{' '}
                {replaceLocaleString(row.freePrice, 0)}
              </td>
              <td>{replaceLocaleString(row.chargePrice, 0)}</td>
              <td>{row.discountRate ? '할인적용' : '할인미적용'}</td>
              <td>{replaceLocaleString(row.calculated, 0)}</td>
            </tr>
            {whatToShowInDetail.includes(forKey.toString()) ? (
              <>
                {handleSubChargeRow(
                  '뒤끝베이스',
                  row.base,
                  row.gameId,
                  row.gameTitle,
                  row.discountRate
                )}
                {handleSubChargeRow(
                  '푸시',
                  row.push,
                  row.gameId,
                  row.gameTitle,
                  row.discountRate
                )}
                {handleSubChargeRow(
                  '뒤끝펑션',
                  row.function,
                  row.gameId,
                  row.gameTitle,
                  row.discountRate
                )}
                {handleSubChargeRow(
                  'DB',
                  row.db,
                  row.gameId,
                  row.gameTitle,
                  row.discountRate
                )}
                {handleExtraBillRow(row)}
              </>
            ) : null}
          </React.Fragment>
        );
      });
      return rowsSet;
    }
  };

  const [shouldChargeBillModalOpen, setShouldChargeBillModalOpen] =
    useState(false);
  const [isExtraBillOpen, setIsExtraBillOpen] = useState({});

  const handleCloseMsgModal = () => {
    setShouldChargeBillModalOpen(false);
    handleInput({});
    setErrObj({});
    setIsExtraBillOpen({});
  };
  const handleOpenMsgModal = () => {
    setShouldChargeBillModalOpen(true);
  };

  const [inputs, handleInput] = useState({});
  const [errObj, setErrObj] = useState({});
  const handleChargeBill = () => {
    const { gameId, extraBillDetails, extraBillAmount } = inputs;

    const { isOpen, inDate } = isExtraBillOpen;

    let isErr = false;
    let errObj = {};
    if (!gameId) {
      isErr = true;
      errObj = {
        ...errObj,
        gameId: '프로젝트를 선택해주세요.',
      };
    }
    if (!extraBillDetails) {
      isErr = true;
      errObj = {
        ...errObj,
        extraBillDetails: '청구항목을 입력해 주세요.',
      };
    }
    if (!extraBillAmount) {
      isErr = true;
      errObj = {
        ...errObj,
        extraBillAmount: '청구금액을 입력해 주세요.',
      };
    }

    if (isErr) {
      setErrObj(errObj);
    } else {
      handleCloseMsgModal();
      if (isOpen)
        return onUpdateExtraBill(gameId, inDate, {
          extraBillDetails,
          extraBillAmount,
        });
      onAddExtraBill({ gameId, extraBillDetails, extraBillAmount });
    }
  };

  const handleChangeDropdown = (gameId) => {
    // console.log(gameId, 'handleChangeDropdown')
    handleInput({
      ...inputs,
      gameId,
    });
  };

  const chargeBillTable = (
    <React.Fragment>
      <div className="form-group m-form__group row">
        <label htmlFor="enterpriseRate" className="col-lg-2 col-form-label">
          * 프로젝트
        </label>
        <div className="col-lg-9">
          <ButtonDropdown
            disabled={isExtraBillOpen.isOpen}
            customKey="chargeExtraFee"
            // initSearchFilter={inputObj.init}
            // initInputObj={initInputObj}
            // handleInput={handleBtnDropdownInput}
            onChangeBtnDropdown={handleChangeDropdown}
            initItem={inputs.gameTitle}
            dropdownItems={projectListForDropdown}
          />
          <div className="error message visible">{errObj.gameId}</div>
        </div>
      </div>
      <div className="form-group m-form__group row">
        <label htmlFor="enterpriseRate" className="col-lg-2 col-form-label">
          * 청구항목
        </label>
        <div className="col-lg-9">
          <input
            onChange={(e) =>
              handleInput({ ...inputs, [e.target.id]: e.target.value })
            }
            type="type"
            className="form-control update-pay-plan-input"
            id="extraBillDetails"
            value={inputs.extraBillDetails}
            required
          />
          <div className="error message visible">{errObj.extraBillDetails}</div>
        </div>
      </div>
      <div className="form-group m-form__group row">
        <label htmlFor="extraBillAmount" className="col-lg-2 col-form-label">
          * 청구금액
        </label>
        <div className="col-lg-9">
          <input
            onChange={(e) =>
              handleInput({ ...inputs, [e.target.id]: e.target.value })
            }
            type="number"
            className="form-control update-pay-plan-input"
            id="extraBillAmount"
            required
            value={inputs.extraBillAmount}
          />
          <div className="col-lg-2 col-form-label update-pay-plan-label">
            원
          </div>
          <div className="error message visible">{errObj.extraBillAmount}</div>
        </div>
      </div>
    </React.Fragment>
  );

  const cantChargeBill = (
    <h3>이미 청구서가 발행되어 요금 청구를 할 수 없습니다.</h3>
  );

  const [isDeleteMsgModalOpen, setDeleteMsgModalOpen] = useState(false);

  const deleteExtraBill = () => {
    const { gameId, inDate } = isExtraBillOpen;

    onDeleteExtraBill(gameId, inDate);
    handleCloseMsgModal();
    handleCloseDeleteExtraBill();
  };

  const handleDeleteExtraBill = () => {
    setDeleteMsgModalOpen(true);
  };

  const handleCloseDeleteExtraBill = () => {
    setDeleteMsgModalOpen(false);
  };

  const replaceLocaleString = (
    param,
    minimumFractionDigits,
    maximumFractionDigits = 6
  ) => {
    if (param === 0) return 0;
    return Number(param).toLocaleString(undefined, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
    // .replace(/0*$/, '');
  };

  const billType = useRef(null);
  const gameId = useRef(null);

  const getCurrentGameBill = (_gameId, chargeType, gameTitle) => {
    switch (chargeType) {
      case '뒤끝베이스':
        billType.current = 'base';
        break;
      case '푸시':
        billType.current = 'push';
        break;
      case '뒤끝펑션':
        billType.current = 'function';
        break;
      case 'DB':
        billType.current = 'db';
        break;

      default:
        break;
    }

    handleOpenModal(true);

    gameId.current = _gameId;

    dispatch(actions.getCurrentGameBill(_gameId, billType.current, gameTitle));
  };

  const handleSubChargeRow = (
    chargeType,
    obj,
    gameId,
    gameTitle,
    discountRate
  ) => {
    return (
      <tr className="subChargeRow" key={Math.random()}>
        <td
          className="subCharge clickable-td"
          onClick={() => {
            getCurrentGameBill(gameId, chargeType, gameTitle);
          }}
        >
          {chargeType} 요금
        </td>
        <td>{replaceLocaleString(obj.usedPriceSum, 0)}</td>
        <td>
          {obj.freeCostSum > 0 ? '-' : null}
          {replaceLocaleString(obj.freeCostSum, 0)}
        </td>
        <td>{replaceLocaleString(obj.costSum, 0)}</td>
        <td>
          {discountRate ? discountRate * 100 : ''}
          {discountRate ? '%' : ''}
        </td>
        <td>{replaceLocaleString(obj.costSum * (1 - discountRate || 0), 0)}</td>
      </tr>
    );
  };
  const handleExtraBillRow = (row) =>
    row.extraItems.map((item) => {
      const unmarshalled = DynamoDB.Converter.unmarshall(item);
      return (
        <tr className="subChargeRow" key={Math.random()}>
          {/* TODO: 수동 청구요금에 대한 수정 로직 추가 */}
          <td
            style={{ width: '49.2%' }}
            className="subCharge clickable-td"
            onClick={async () => {
              handleOpenMsgModal();

              const { inDate, extraBillDetails, extraBillAmount } =
                unmarshalled;
              const { gameTitle, gameId } = row;

              handleInput({
                extraBillDetails,
                extraBillAmount,
                gameTitle,
                gameId,
              });
              setIsExtraBillOpen({
                isOpen: true,
                inDate,
                gameId,
              });
            }}
          >
            {unmarshalled.extraBillDetails}
          </td>
          <td> </td>
          <td> </td>
          <td>{replaceLocaleString(unmarshalled.extraBillAmount, 0)}</td>
        </tr>
      );
    });

  const handleCostSum = (price, option) => {
    const calculated = Math.floor(Math.floor(price) / 10) * 10;
    if (option) return numberToString(calculated, 6, true);
    return numberToString(price - calculated, 6, true);
  };

  return (
    <div className="m-content">
      <div className="row">
        <div className="col-md-12">
          <div className="m-portlet">
            <div className="m-portlet__body">
              <div className="m-portlet__body m-portlet__body--no-padding">
                <div className="m-invoice-2">
                  <div className="m-invoice__wrapper">
                    <div className="m-invoice__head">
                      <div className="m-invoice__container m-invoice__container--centered">
                        <div className="m-invoice__logo">
                          <div className="row">
                            <div className="col-lg-6">
                              <h5 className="modal-title">
                                이용기간
                                <i
                                  className="la la-question-circle"
                                  data-container="body"
                                  data-toggle="m-popover"
                                  data-placement="right"
                                  data-html="true"
                                  data-content="1일부터 오늘까지의 요금으로 부가세 및 할인(크레딧) 적용 전 금액입니다."
                                />{' '}
                                {period}
                              </h5>
                            </div>
                            <div className="col-lg-6 m--align-right">
                              <p>
                                {account}
                                <span className="m-badge m-badge--success m-badge--wide">
                                  {status === 'n' ? '정상' : '정지'}
                                </span>
                              </p>
                              <button
                                type="button"
                                onClick={onGetBackToMainTable}
                                className="btn btn-danger btn-sm cm_withdraw"
                              >
                                <i className="la" /> 목록
                              </button>
                            </div>
                          </div>
                          <h2>
                            현재 이용 요금{' '}
                            <span className="m--font-danger">
                              {/* {replaceLocaleString(chargePrice, 5)} */}
                              {handleCostSum(chargePrice, true)}
                            </span>{' '}
                            원{' '}
                            <button
                              type="button"
                              onClick={handleOpenMsgModal}
                              className="btn btn-danger btn-sm cm_withdraw"
                            >
                              <i className="la" /> 요금 청구
                            </button>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="m-invoice__body m-invoice__body--centered">
                      <Table className="current-charge-detail">
                        <thead className="main-table">
                          <tr>{makeColumsRow(detailColumns)}</tr>
                        </thead>
                        <tbody className="main-table">
                          {list.length ? (
                            makeRows(list)
                          ) : (
                            <tr>
                              <td colSpan="4">생성된 프로젝트가 없습니다.</td>
                            </tr>
                          )}
                          <tr className="subTotal-row">
                            <td colSpan="1">합계</td>
                            <td colSpan="1">
                              {replaceLocaleString(beforeDC, 5)}
                            </td>
                            <td colSpan="1">
                              {freePrice > 0 ? '-' : null}{' '}
                              {replaceLocaleString(freePrice, 0)}
                            </td>
                            <td colSpan="1">
                              {replaceLocaleString(chargePrice, 0)}
                            </td>
                            <td> </td>
                            <td>{replaceLocaleString(calculatedSum, 0)} </td>
                          </tr>
                          <tr className="subTotal-row">
                            <td colSpan="1">10원 미만 절사</td>
                            <td colSpan="1"> </td>
                            <td colSpan="1"> </td>
                            <td colSpan="1"> </td>
                            <td colSpan="1"> </td>
                            <td colSpan="1">
                              -{handleCostSum(calculatedSum, false)}
                            </td>
                          </tr>
                          <tr className="subTotal-row">
                            <td colSpan="1">예상 요금</td>
                            <td colSpan="1"> </td>
                            <td colSpan="1"> </td>
                            <td colSpan="1"> </td>
                            <td colSpan="1"> </td>
                            <td colSpan="1">
                              {handleCostSum(calculatedSum, true)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <PayPerUseDetailModal
                      gameId={gameId.current}
                      billType={billType.current}
                      isOpen={isOpen}
                      searchKeywords={searchKeywords}
                      gameTitle={gameTitle}
                      handleOpenModal={handleOpenModal}
                      gameBill={gameBill}
                    />
                    <MsgModal
                      withDelete={isExtraBillOpen.isOpen}
                      onlyCanClose={
                        projectChargeList && projectChargeList.billIssued
                      }
                      onDeleteAction={
                        projectChargeList && projectChargeList.billIssued
                          ? null
                          : handleDeleteExtraBill
                      }
                      isMsgModalOpen={shouldChargeBillModalOpen}
                      onHandleCloseMsgModal={handleCloseMsgModal}
                      onConfirmAction={
                        projectChargeList && projectChargeList.billIssued
                          ? null
                          : handleChargeBill
                      }
                      confirmOrCancel={
                        !(projectChargeList && projectChargeList.billIssued)
                      }
                      contentsObject
                      header="요금 청구"
                      content={
                        projectChargeList && projectChargeList.billIssued
                          ? cantChargeBill
                          : chargeBillTable
                      }
                      size="lg"
                    ></MsgModal>
                    <MsgModal
                      isMsgModalOpen={isDeleteMsgModalOpen}
                      onHandleCloseMsgModal={handleCloseDeleteExtraBill}
                      onConfirmAction={deleteExtraBill}
                      confirmOrCancel
                      // contentsObject
                      content="삭제하시겠습니까?"
                      // size="lg"
                    ></MsgModal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  detailColumns: selectors.detailColumnsSelector,
  // columns: selectors.columnsSelector,
  projectChargeList: selectors.projectChargeListSelector,
  //   accountBill: selectors.accountBillSelector,
  gameBill: selectors.gameBillSelector,
  gameTitle: selectors.gameTitleSelector,
  account: selectors.idSelector,
  status: selectors.statusSelector,
  projectListForDropdown: selectors.projectListForDropdownSelector,
  searchKeywords: selectors.searchKeywordsSelector,
});

const mapDispatchToProps = (dispatch) => ({
  // handleCloseProjectModal: () => dispatch(actions.handleCloseProjectModal()),
  // onConfirmChangeStatus: value => dispatch(actions.confirmChangeStatus(value)),
  // onSaveInput: value => dispatch(actions.saveInput(value)),
  onHandleOpenLayerdModal: () => dispatch(actions.handleOpenLayerdModal()),
  onHandleCloseLayerdModal: () => dispatch(actions.handleCloseLayerdModal()),
  onGetCurrentGameBill: (id, billType, gameTitle) =>
    dispatch(actions.getCurrentGameBill(id, billType, gameTitle)),
  onAddExtraBill: (obj) => dispatch(actions.addExtraBill(obj)),
  onUpdateExtraBill: (gameId, inDate, obj) =>
    dispatch(actions.updateExtraBill(gameId, inDate, obj)),
  onDeleteExtraBill: (gameId, inDate) =>
    dispatch(actions.deleteExtraBill(gameId, inDate)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentChargeDetail);
