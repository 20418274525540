
// 
export const GET_SEARCH_KEYWORDS_SALES = 'GET_SEARCH_KEYWORDS_SALES';
export const GET_DAILY_STAT_BY_PJT = 'GET_DAILY_STAT_BY_PJT';
export const GET_DAILY_STAT_BY_PJT_SUCCESS = 'GET_DAILY_STAT_BY_PJT_SUCCESS';
export const SORT_ASC_ROWS_SALES = 'SORT_ASC_ROWS_SALES';
export const SORT_DESC_ROWS_SALES = 'SORT_DESC_ROWS_SALES';
//
export const CLICK_NOTICE_EDIT = 'CLICK_NOTICE_EDIT';
export const CLICK_NOTICE_DELETE = 'CLICK_NOTICE_DELETE';
export const POST_CREATE_NOTICE = 'POST_CREATE_NOTICE';
export const SET_TRY_POST_TRUE = 'SET_TRY_POST_TRUE';

export const HANDLE_CLOSE_COUPON_DETAIL_MODAL =
	'HANDLE_CLOSE_COUPON_DETAIL_MODAL';

export const HANDLE_CLOSE_MSG_MODAL = 'HANDLE_CLOSE_MSG_MODAL';
export const CONFIRM_CHANGE_STATUS = 'CONFIRM_CHANGE_STATUS';
export const GET_ACCOUNTS_ROWS = 'GET_ACCOUNTS_ROWS';
export const GET_ACCOUNTS_ROWS_SUCCESS = 'GET_ACCOUNTS_ROWS_SUCCESS';
export const INIT_ACCOUNT_LIST = 'INIT_ACCOUNT_LIST';
export const INIT_DELETED_ACCOUNT_LIST = 'INIT_DELETED_ACCOUNT_LIST';
export const GET_COLUMNS = 'GET_COLUMNS';
export const CLICK_MANAGE_BILL = 'CLICK_MANAGE_BILL';
export const HANDLE_OPEN_MODAL = 'HANDLE_OPEN_MODAL';
export const CONFIRM_DELETE_ACCOUNT = 'CONFIRM_DELETE_ACCOUNT';
export const SAVE_INPUT = 'SAVE_INPUT';

export const HANDLE_SEARCH_PROJECT = 'HANDLE_SEARCH_PROJECT';
export const HANDLE_SEARCH_PROJECT_SUCCESS = 'HANDLE_SEARCH_PROJECT_SUCCESS';
export const HANDLE_MULTIPLE_SEARCH = 'HANDLE_MULTIPLE_SEARCH';

export const CHANGE_STAT_TYPE_PROJECTS = 'CHANGE_STAT_TYPE_PROJECTS';
export const GET_DAILY_STAT_PROJECTS = 'GET_DAILY_STAT_PROJECTS';
export const GET_MONTHLY_STAT_PROJECTS = 'GET_MONTHLY_STAT_PROJECTS';
export const GET_SEARCHED_DAILY_STAT_PROJECTS_SUCCESS =
	'GET_SEARCHED_DAILY_STAT_PROJECTS_SUCCESS';
export const GET_DAILY_STAT_PROJECTS_SUCCESS =
	'GET_DAILY_STAT_PROJECTS_SUCCESS';
export const GET_MONTHLY_STAT_PROJECTS_SUCCESS =
	'GET_MONTHLY_STAT_PROJECTS_SUCCESS';
export const CLICK_PROJECT_ROW = 'CLICK_PROJECT_ROW';
export const CLICK_PROJECT_ROW_SUCCESS = 'CLICK_PROJECT_ROW_SUCCESS';
export const SORT_ASC_ROWS_PROJECTS_STAT = 'SORT_ASC_ROWS_PROJECTS_STAT';
export const SORT_DESC_ROWS_PROJECTS_STAT = 'SORT_DESC_ROWS_PROJECTS_STAT';

export const INIT_DATA_TABLES_PROJECTS = 'INIT_DATA_TABLES_PROJECTS';
