import { LOCATION_CHANGE } from "react-router-redux";

// import * as selectors from './selector';
import * as types from "./constant";

const initialState = {
  emailList: [
    {
      type: "이메일 인증",
      condition: "회원가입시 인증 이메일"
    },
    {
      type: "회원가입 완료",
      condition: "회원가입 완료시"
    },
    {
      type: "비밀번호 초기화",
      condition: "비밀번호 초기화시"
    },
    {
      type: "회원탈퇴",
      condition: "회원이 탈퇴신청 했을 시"
    },
    {
      type: "청구서 발송",
      condition: "요금청구서 발송(매월 10일"
    },
    {
      type: "연체 안내",
      condition: "요금 연체 발생시"
    }
  ],
  columns: [{ label: "메일 항목" }, { label: "발송 조건" }, { label: "" }],
  detail: { clickedId: "", emailCreate: false },
  email: { contents: "" }
};

function emails(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...initialState
      };

    case types.GET_EMAIL:
      return {
        ...state,
        detail: { clickedId: "", emailCreate: true }
      };

    case types.GET_EMAIL_SUCCESS:
      return {
        ...state,
        email: action.obj
      };

    case types.GET_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        emailList: action.rows
      };

    case types.HANDLE_OPEN_EMAIL_CREATE_MODAL:
      return {
        ...state,
        detail: { clickedId: "", emailCreate: false }
      };

    case types.HANDLE_ON_CHANGE:
      return {
        ...state,
        email: {
          ...state.email,
          ...action.value
        },
        errMsg: {}
      };

    case types.UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        detail: { clickedId: "", emailCreate: false },
        email: initialState.email
      };
    // case types.SET_TRY_POST_TRUE:
    //   return {
    //     ...state,
    //     tryPost: true
    //   };

    // case types.HANDLE_CLOSE_MSG_MODAL:
    //   return {
    //     ...state,
    //     isMsgModalOpen: false
    //   };

    // case types.CONFIRM_CHANGE_STATUS:
    //   return {
    //     ...state,
    //     detail: { clickedId: "", billManage: false }
    //   };

    default:
      return state;
  }
}

export default emails;
