import * as types from "./constant";

export function initPopupList() {
  return {
    type: types.INIT_POPUP_LIST,
  };
}

export function getPopupList() {
  return {
    type: types.GET_POPUP_LIST,
  };
}

export function getPopupListSuccess(rows, lastEvaluatedKey) {
  return {
    type: types.GET_POPUP_LIST_SUCCESS,
    rows,
    lastEvaluatedKey,
  };
}

export function insertPopup(obj) {
  return {
    type: types.INSERT_POPUP,
    obj,
  };
}

export function insertPopupSuccess() {
  return {
    type: types.INSERT_POPUP_SUCCESS,
  };
}
export function updatePopup(inDate, data) {
  return {
    type: types.UPDATE_POPUP,
    inDate,
    data,
  };
}
export function updatePopupSuccess() {
  return {
    type: types.UPDATE_POPUP_SUCCESS,
  };
}

export function deletePopup(inDate) {
  return {
    type: types.DELETE_POPUP,
    inDate,
  };
}

export function getPopup(title, arr) {
  return {
    type: types.GET_POPUP,
    title,
    arr,
  };
}

export function handlePopupErrors(err) {
  return {
    type: types.HANDLE_POPUP_ERRORS,
    err,
  };
}
