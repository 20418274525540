// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import { createSelector } from 'reselect';
import moment from 'moment';

// 원본
export const inquiriesListSelector = createSelector(
  (state) => state.inquiryGDC.inquiriesList,
  (inquiriesList) => inquiriesList
);

// 테이블용
export const inquiriesListSelectorForView = createSelector(
  (state) => state.inquiryGDC.inquiriesList,
  (inquiriesList) => {
    let _inquiriesList = [...inquiriesList];
    return _inquiriesList.map((inquiry, i) => {
      return {
        email: inquiry.email,
        id: {
          noDisplay: true,
          inDate: inquiry.inDate,
        },
        name: inquiry.name,
        inDate: moment(inquiry.inDate).format('YYYY.MM.DD HH:mm'),
        status: inquiry.status === 'y' ? '답변완료' : '답변대기',
        company: inquiry.company,
      };
    });
  }
);

// desc : 페이징 키 lastEvaluatedKey
export const lastEvaluatedKeySelector = createSelector(
  (state) => state.inquiryGDC.lastEvaluatedKey,
  (lastEvaluatedKey) => lastEvaluatedKey
);
