// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import 'react-dates/initialize';
import 'semantic-ui-css/semantic.min.css';
import 'react-dates/lib/css/_datepicker.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';

import CheckLogin from './CheckLogin';

import routes from './routes';
import store from './configureStore';

import './css/style.bundle.css';
import './css/style.css';

const history = syncHistoryWithStore(browserHistory, store);

function RootApp() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Route component={CheckLogin}>
          <Route exact path="/" component={routes.Login} />
          <Route path="/login" exact component={routes.Login} />
          <Route
            exact
            path="/accountManaging"
            component={routes.AccountManaging}
          />
          <Route path="/dashboard" exact component={routes.Dashboard} />
          <Route exact path="/sleepingCandidates" component={routes.Sleeping} />
          <Route exact path="/sleepers" component={routes.Sleeping} />
          <Route path="/deleteAccount" component={routes.DeleteAccount} />
          <Route path="/projects" component={routes.ProjectsManaging} />
          <Route path="/deletedProjects" component={routes.ProjectsManaging} />
          <Route path="/currentCharge" component={routes.CurrentCharge} />
          <Route
            path="/CurrentChargePayPerUse"
            component={routes.CurrentChargePayPerUse}
          />
          <Route path="/chargedBill" component={routes.ChargedBill} />
          <Route path="/overdueBill" component={routes.OverdueBill} />
          <Route path="/coupon" component={routes.Coupon} />
          <Route path="/notice" component={routes.Notice} />
          <Route path="/popup" component={routes.ConsolePopup} />
          <Route path="/email" component={routes.Emails} />
          <Route path="/sendEmail" component={routes.SendEmail} />
          <Route path="/emailList" component={routes.EmailList} />
          <Route path="/customers" component={routes.Customers} />
          <Route path="/statOfProjects" component={routes.Projects} />
          <Route path="/sales" component={routes.Sales} />
          <Route path="/theBackendSales" component={routes.TheBackendSales} />
          <Route path="/retention" component={routes.Retention} />
          <Route path="/decoding" component={routes.Kibana} />
          <Route path="/indicators" component={routes.Indicators} />
          <Route path="/dataExtraction" component={routes.DataExtraction} />
          <Route path="/landing/showcase" component={routes.Showcase} />
          <Route path="/landing/popup" component={routes.Popup} />
          <Route path="/landing/terms" component={routes.Terms} />
          <Route path="/landing/privacy" component={routes.Privacy} />
          <Route path="/landing/inquiry" component={routes.Inquiry} />
          <Route path="/landing/inquiryGDC" component={routes.InquiryGDC} />
          <Route path="/landing/sla" component={routes.Sla} />
          {/* 개발자 */}
          <Route path="/developer/getStarted" component={routes.GetStarted} />
          <Route
            path="/developer/consoleGuide"
            component={routes.ConsoleGuide}
          />
          <Route path="/developer/sdkDocs/*" component={routes.SdkDocs} />
          <Route
            path="/developer/versionManaging"
            component={routes.VersionManaging}
          />

          {/* </Route> */}
        </Route>
      </Router>
    </Provider>
  );
}

export default RootApp;
