//
export const GET_THEBACKEND_SALES_SUCCESS = 'GET_THEBACKEND_SALES_SUCCESS';
export const GET_THEBACKEND_SALES = 'GET_THEBACKEND_SALES';

export const GET_NEXT_THEBACKEND_SALES = 'GET_NEXT_THEBACKEND_SALES';
export const GET_NEXT_THEBACKEND_SALES_SUCCESS =
	'GET_NEXT_THEBACKEND_SALES_SUCCESS';

export const SORT_ASC_ROWS_THEBACKEND_SALES = 'SORT_ASC_ROWS_THEBACKEND_SALES';
export const SORT_DESC_ROWS_THEBACKEND_SALES =
	'SORT_DESC_ROWS_THEBACKEND_SALES';
