import React, { Component } from 'react';
import { Icon, Dimmer, Loader } from 'semantic-ui-react';

const defaultProps = {
  hidden: { display: 'none' },
  uploadUrl: '',
  hasImage: false,
  src: null,
  isLoading: false,
  onUpload: () => console.warn('onUpload should be defined.'),
  onDelete: () => {},
};

class ImageUploadBox extends Component {
  state = {
    naturalHeight: 0,
    naturalWidth: 0,
  };

  handleChange = (e, a) => {
    const nextState = {};
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.props.handleImage(e.target.result);
        nextState.src = e.target.result;
        this.setState(nextState);
      };
      reader.readAsDataURL(file);

      this.upload();
      this.setState({ uploaded: true });
    } else {
      this.setState(nextState);
    }
  };

  componentWillUpdate(nextProps, nextState) {
    if (nextState && nextState.uploaded) {
      const myImg = document.getElementById('myImg');

      if (
        myImg &&
        this.state.naturalHeight === 0 &&
        this.state.naturalWidth === 0
      ) {
        console.log(
          'it rendered 2',
          myImg.naturalHeight,
          myImg.naturalWidth,
          'nextState'
        );
        const { naturalHeight, naturalWidth } = myImg;
        this.setState({
          naturalHeight,
          naturalWidth,
          uploaded: false,
        });
      }
    }
  }

  handleClick = () => {
    this.fileChooser.click();
  };

  handleDelete = () => {
    const nextState = {
      src: null,
    };

    this.setState(nextState);

    // 파일 초기화
    this.fileChooser.value = '';

    this.props.onDelete();
  };

  // 업로드하는 메서드
  upload = (result) => {
    console.log('chking img', result);
    this.props.onUpload(this.fileChooser);
  };

  render() {
    const { error, imgSize } = this.props;
    const uploadedImg = document.getElementById('myImg');

    console.log('rendering state', this.state);
    return (
      <div className="landing-img-container">
        {/* <!-- 이미지 첨부 버튼 --> */}
        <button
          type="button"
          className="upload button"
          style={
            this.props.src || this.props.isLoading ? this.props.hidden : {}
          }
          onClick={this.handleClick}
        >
          <i className="palegrey large plus icon" />
        </button>
        <input
          accept="image/*"
          type="file"
          style={{ display: 'none' }}
          ref={(input) => (this.fileChooser = input)}
          onChange={this.handleChange}
        />
        {/* <!-- 이미지 첨부 완료시 첨부 버튼이 첨부된 이미지로 교체 --> */}
        <div
          className="uploaded image"
          style={
            this.props.src || this.props.isLoading ? {} : this.props.hidden
          }
        >
          <Icon name="remove" className="circle" onClick={this.handleDelete} />
          {this.props.isLoading ? (
            <Dimmer active>
              <Loader inverted>Loading...</Loader>
            </Dimmer>
          ) : null}
          <img
            id="myImg"
            onClick={this.handleClick}
            src={this.props.src}
            alt=""
            ref={(img) => {
              return (this.image = img);
            }}
          />
        </div>

        {imgSize && uploadedImg ? (
          <div className="imgSize">
            {uploadedImg.naturalWidth} X {uploadedImg.naturalHeight}
          </div>
        ) : null}
        <div className="error message visible">{error}</div>
      </div>
    );
  }
}

ImageUploadBox.defaultProps = defaultProps;

export default ImageUploadBox;
