const { NODE_ENV, REACT_APP_ENV } = process.env;
const APP_ENV = NODE_ENV === 'test' ? 'alpha' : REACT_APP_ENV;

const baseUrl = {};

switch (APP_ENV) {
  // 로컬
  case 'local':
    baseUrl.DASHBOARD_API = 'http://localhost:12300';
    baseUrl.CONSOLE_API = 'http://localhost:12300/console/';
    baseUrl.DEVELOPER_API = 'http://localhost:12300/developer';
    baseUrl.LANDING_API = 'http://localhost:12300/landing';
    baseUrl.ADMIN_AUTH = 'http://localhost:23400';
    baseUrl.CONSOLE_UPLOAD_API =
      'https://mnc7sgj8ui.execute-api.ap-northeast-2.amazonaws.com/console/v1';
    baseUrl.CONSOLE_UPLOADED_IMAGE =
      'http://upload-console.alpha.thebackend.io.s3.ap-northeast-2.amazonaws.com';
    baseUrl.CONSOLE = 'https://console.alpha.thebackend.io/ko';
    break;

  // 알파
  case 'alpha':
    baseUrl.DASHBOARD_API = 'https://adminapi.alpha.thebackend.io';
    baseUrl.CONSOLE_API = 'https://adminapi.alpha.thebackend.io/console/';
    baseUrl.DEVELOPER_API = 'https://adminapi.alpha.thebackend.io/developer';
    baseUrl.LANDING_API = 'https://adminapi.alpha.thebackend.io/landing';
    baseUrl.ADMIN_AUTH = 'https://adminauth.alpha.thebackend.io';
    baseUrl.CONSOLE_UPLOAD_API =
      'https://mnc7sgj8ui.execute-api.ap-northeast-2.amazonaws.com/console/v1';
    baseUrl.CONSOLE_UPLOADED_IMAGE =
      'http://upload-console.alpha.thebackend.io.s3.ap-northeast-2.amazonaws.com';
    baseUrl.CONSOLE = 'https://console.alpha.thebackend.io/ko';
    break;

  // 라이브
  case 'live':
  default:
    baseUrl.DASHBOARD_API = 'https://adminapi.thebackend.io';
    baseUrl.CONSOLE_API = 'https://adminapi.thebackend.io/console/';
    baseUrl.DEVELOPER_API = 'https://adminapi.thebackend.io/developer';
    baseUrl.LANDING_API = 'https://adminapi.thebackend.io/landing';
    baseUrl.ADMIN_AUTH = 'https://adminauth.thebackend.io';
    baseUrl.CONSOLE_UPLOAD_API = 'https://uploadapi.thebackend.io';
    baseUrl.CONSOLE_UPLOADED_IMAGE = 'http://upload-console.thebackend.io';
    baseUrl.CONSOLE = 'https://console.thebackend.io/ko';
    break;
}
baseUrl.LOGIN = '/login';

export default baseUrl;
