// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import Qs from 'qs';

import { requestGet, requestPost, requestDelete } from '../../utils/request';
import BaseUrl from '../../shared/baseUrl';

export const getTopics = (params) => {
  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  const url = `${BaseUrl.DEVELOPER_API}/topic/v1.1`;

  return requestGet(url, options);
};
export const getList = (params) => {
  // 소분류 및 문서 목록 불러오기
  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  const url = `${BaseUrl.DEVELOPER_API}/list`;

  return requestGet(url, options);
};

export const getClassParentList = (params) => {
  // 소분류 목록 불러오기??

  const url = `${BaseUrl.DEVELOPER_API}/class/${params}`;

  return requestGet(url);
};

export const makeTopic = (data) => {
  const url = `${BaseUrl.DEVELOPER_API}/topic/v1.1`;

  return requestPost(url, data);
};

export const makeClass = (data) => {
  const url = `${BaseUrl.DEVELOPER_API}/class`;

  return requestPost(url, data);
};

export const makeDocument = (data) => {
  const url = `${BaseUrl.DEVELOPER_API}/document`;

  return requestPost(url, data);
};

export const getDocument = (params) => {
  const url = `${BaseUrl.DEVELOPER_API}/document/${params}`;
  return requestGet(url);
};

export const getVersionList = (params) => {
  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  const url = `${BaseUrl.DEVELOPER_API}/version/v1.1`;
  return requestGet(url, options);
};

export const updateVersion = (param, data) => {
  const url = `${BaseUrl.DEVELOPER_API}/version/${param}`;
  return requestPost(url, data);
};

export const updateTopic = (param, topic) => {
  const url = `${BaseUrl.DEVELOPER_API}/topic/${param}`;
  return requestPost(url, topic);
};

export const updateClass = (param, data) => {
  const url = `${BaseUrl.DEVELOPER_API}/class/${param}`;
  return requestPost(url, data);
};

export const updateDocument = (data, param) => {
  const url = `${BaseUrl.DEVELOPER_API}/document/${param}`;
  return requestPost(url, data);
};

export const updateOrder = (inDate, updown, data) => {
  const url = `${BaseUrl.DEVELOPER_API}/order/${inDate}/${updown}`;
  return requestPost(url, data);
};

export const makeVersion = (data) => {
  const url = `${BaseUrl.DEVELOPER_API}/version/v1.1`;
  return requestPost(url, data);
};

export const deleteVersion = (param) => {
  const url = `${BaseUrl.DEVELOPER_API}/version/${param}`;
  return requestDelete(url);
};

export const deleteTopic = (param) => {
  const url = `${BaseUrl.DEVELOPER_API}/topic/${param}`;
  return requestDelete(url);
};

export const deleteClass = (param) => {
  const url = `${BaseUrl.DEVELOPER_API}/class/${param}`;
  return requestDelete(url);
};

export const deleteDocument = (param) => {
  const url = `${BaseUrl.DEVELOPER_API}/document/${param}`;
  return requestDelete(url);
};

export const getDownloadTopic = (topicInDate) => {
  const url = `${BaseUrl.DEVELOPER_API}/topic/download/${topicInDate}`;
  return requestGet(url);
};
