import * as types from "./constant";

export function getEmail(key, arr) {
  return {
    type: types.GET_EMAIL,
    key,
    arr
  };
}
export function getEmailSuccess(obj) {
  return {
    type: types.GET_EMAIL_SUCCESS,
    obj
  };
}
export function getEmailList() {
  return {
    type: types.GET_EMAIL_LIST
  };
}
export function getEmailListSuccess(rows) {
  return {
    type: types.GET_EMAIL_LIST_SUCCESS,
    rows
  };
}

export function updateEmailUsing(key, arr, selectedId) {
  return {
    type: types.UPDATE_EMAIL_USING,
    key,
    arr,
    selectedId
  };
}

export function updateEmail() {
  return {
    type: types.UPDATE_EMAIL
  };
}

export function updateEmailSuccess() {
  return {
    type: types.UPDATE_EMAIL_SUCCESS
  };
}

export function handleCloseMsgModal() {
  return {
    type: types.HANDLE_CLOSE_MSG_MODAL
  };
}

export function handleOpenEmailCreateModal() {
  return {
    type: types.HANDLE_OPEN_EMAIL_CREATE_MODAL
  };
}

export function handleOnChange(value) {
  return {
    type: types.HANDLE_ON_CHANGE,
    value
  };
}

// export function confirmChangeStatus(value) {
// 	return {
// 		type: types.CONFIRM_CHANGE_STATUS,
// 		value,
// 	};
// }
