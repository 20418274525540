import * as types from "./constant";

// export function initGameStat(gameId, date, year) {
// 	return {
// 		type: types.INIT_GAME_STAT,
// 		gameId,
// 		date,
// 		year,
// 		MAU: false,
// 	};
// }

export function clickNoticeEdit(id, arr) {
  return {
    type: types.CLICK_NOTICE_EDIT,
    id,
    arr
  };
}

export function handleCloseCouponDetailModal() {
  return {
    type: types.HANDLE_CLOSE_COUPON_DETAIL_MODAL
  };
}

export function clickNoticeDelete(id, arr) {
  return {
    type: types.CLICK_NOTICE_DELETE,
    id,
    arr
  };
}

export function handleCreateNotice(content) {
  return {
    type: types.POST_CREATE_NOTICE,
    content
  };
}

export function setTryPostTrue() {
  return {
    type: types.SET_TRY_POST_TRUE
  };
}

export function handleCloseMsgModal() {
  return {
    type: types.HANDLE_CLOSE_MSG_MODAL
  };
}

export function confirmChangeStatus(value) {
  return {
    type: types.CONFIRM_CHANGE_STATUS,
    value
  };
}

export function initAccountList(gameId, date) {
  return {
    type: types.INIT_ACCOUNT_LIST,
    gameId,
    date
  };
}
export function initDeletedAccountList(gameId, date) {
  return {
    type: types.INIT_DELETED_ACCOUNT_LIST,
    gameId,
    date
  };
}

export function confirmDeleteAccount(id) {
  return {
    type: types.CONFIRM_DELETE_ACCOUNT,
    id
  };
}

export function saveInput(value) {
  return {
    type: types.SAVE_INPUT,
    value
  };
}

export function getMonthlyStatistics() {
  return {
    type: types.GET_MONTHLY_STAT
  };
}

export function getMonthlyStatisticsSuccess(data, isLast) {
  return {
    type: types.GET_MONTHLY_STAT_SUCCESS,
    data,
    isLast
  };
}

export function getDailyStatistics(id, date, isSearching) {
  return {
    type: types.GET_DAILY_STAT,
    id,
    date,
    isSearching
  };
}

export function getDailyStatisticsSuccess(data, isLast) {
  return {
    type: types.GET_DAILY_STAT_SUCCESS,
    data,
    isLast
  };
}

export function changeStatType(data) {
  return {
    type: types.CHANGE_STAT_TYPE_GENERAL_STAT,
    data
  };
}

export function searchStatistics(date) {
  return {
    type: types.SEARCH_STATISTICS,
    date
  };
}
export function sortAscRows(rows, isSearched, tableType) {
  return {
    type: types.SORT_ASC_ROWS,
    rows,
    isSearched,
    tableType
  };
}
export function sortDescRows(rows, isSearched, tableType) {
  return {
    type: types.SORT_DESC_ROWS,
    rows,
    isSearched,
    tableType
  };
}
export function getSearchedDailyStatisticsSuccess(rows, isLast) {
  return {
    type: types.GET_SEARCHED_DAILY_STAT_SUCCESS,
    rows,
    isLast
  };
}

export function initDataTables() {
  return {
    type: types.INIT_DATA_TABLES_ALL_STAT
  };
}

export function handleOpenReleasedProjectModal(pjts, date) {
  return {
    type: types.HANDLE_OPEN_RELEASED_PJT_MODAL,
    pjts,
    date
  };
}

export function handleCloseReleasedProjectModal(pjts) {
  return {
    type: types.HANDLE_CLOSE_RELEASED_PJT_MODAL,
    pjts
  };
}

export function showSpecificProjectInfo(pjtName) {
  return {
    type: types.SHOW_SPEC_PROJECT_INFO,
    pjtName
  };
}
