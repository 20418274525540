import React, { useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';

import ExcelExport from './ExcelExport';

import ButtonToPrintOut from './ButtonToPrintOut';

const ButtonsGroup = ({
	customClassName,
	leftRdBtn,
	rightRdBtn,
	isAdmin,
	handleIsAdmin,
}) => (
	<ButtonGroup className={`m--margin-bottom-20 float-right ${customClassName}`}>
		<Button onClick={() => handleIsAdmin(true)} active={isAdmin}>
			{leftRdBtn}
		</Button>
		<Button onClick={() => handleIsAdmin(false)} active={!isAdmin}>
			{rightRdBtn}
		</Button>
	</ButtonGroup>
);

export default ButtonsGroup;
