// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { createSelector } from 'reselect';

export const baseIndicatorsSelector = createSelector(
	(state) => state.indicators.indicators,
	({ base }) => base
);
export const functionIndicatorsSelector = createSelector(
	(state) => state.indicators.indicators,
	(indicators) => indicators.function
);
export const chatIndicatorsSelector = createSelector(
	(state) => state.indicators.indicators,
	({ chat }) => chat
);
export const matchIndicatorsSelector = createSelector(
	(state) => state.indicators.indicators,
	({ match }) => match
);
export const dbIndicatorsSelector = createSelector(
	(state) => state.indicators.indicators,
	({ db }) => db
);

export const isSetterSuccessSelector = createSelector(
	(state) => state.indicators,
	({ isSetterSuccess }) => isSetterSuccess
);
