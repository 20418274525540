import { LOCATION_CHANGE } from 'react-router-redux';
import * as types from './constant';

const initialState = {
	contents: '',
	lastModified: '',
};

function sla(state = initialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case types.GET_SLA_POLICY_SUCCESS:
			return {
				...state,
				contents: action.payload[0].contents,
				lastModified: action.payload[0].lastModified,
			};
		case types.CATCH_HTML:
			return {
				...state,
				contents: action.payload,
			};

		default:
			return state;
	}
}

export default sla;
