// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import * as types from './constant';

// export function initGameStat(gameId, date, year) {
// 	return {
// 		type: types.INIT_GAME_STAT,
// 		gameId,
// 		date,
// 		year,
// 		MAU: false,
// 	};
// }

export function initProjectList() {
  return {
    type: types.INIT_PROJECT_LIST,
  };
}

export function getProjectList(zeroOffset, toRefresh) {
  return {
    type: types.GET_PROJECT_LIST,
    zeroOffset,
    toRefresh,
  };
}

export function getProjectListSuccess(data, toRefresh) {
  return {
    type: types.GET_PROJECT_LIST_SUCCESS,
    data,
    toRefresh,
  };
}

export function clickProjectRow(arr) {
  return {
    type: types.MODIFY_PROJECT_ROW,
    arr,
  };
}

export function toggleAppReleaseInfo(id, arr) {
  return {
    type: types.TOGGLE_APP_RELEASE_INFO,
    id,
    arr,
  };
}

export function getStatusListSuccess(data) {
  return {
    type: types.GET_STATUS_LIST_SUCCESS,
    data,
  };
}

export function handleCloseProjectModal() {
  return {
    type: types.HANDLE_CLOSE_PROJECT_MODAL,
  };
}

export function confirmChangeStatus(value) {
  return {
    type: types.CONFIRM_CHANGE_STATUS,
    value,
  };
}

export function sortAscProjectList(rows, isSearched, tableType) {
  return {
    type: types.SORT_ASC_PROJECT_LIST,
    rows,
    isSearched,
    tableType,
  };
}

export function sortDescProjectList(rows, isSearched, tableType) {
  return {
    type: types.SORT_DESC_PROJECT_LIST,
    rows,
    isSearched,
    tableType,
  };
}

export function getSearchKeywords(obj) {
  return {
    type: types.GET_SEARCH_KEYWORDS_PROJECTS,
    obj,
  };
}

export function initProjectManaging() {
  return {
    type: types.INIT_PROJECT_MANAGING,
  };
}

export function unmountProjectList() {
  return {
    type: types.UNMOUNT_PROJECT_LIST,
  };
}

export function initCurrentProjectList() {
  return {
    type: types.INIT_CURRENT_PROJECT_LIST,
  };
}

export function getSearchedProjectListSuccess(data, toRefresh) {
  return {
    type: types.GET_SEARCHED_PROJECT_LIST_SUCCESS,
    data,
    toRefresh,
  };
}

export function getProjectsCountSuccess(data) {
  return {
    type: types.GET_PROJECTS_COUNT_SUCCESS,
    data,
  };
}

export function handleUpdatePayPlan(id, obj) {
  return {
    type: types.HANDLE_UPDATE_PAY_PLAN,
    id,
    obj,
  };
}

export function getEnterpriseInfo(id) {
  return {
    type: types.GET_ENTERPRISE_INFO,
    id,
  };
}

export function initEnterpriseInfo() {
  return {
    type: types.INIT_ENTERPRISE_INFO,
  };
}

export function getProject(arr) {
  return {
    type: types.GET_PROJECT,
    arr,
  };
}

export function getProjectSuccess(data) {
  return {
    type: types.GET_PROJECT_SUCCESS,
    data,
  };
}

export function getDeletedProjects() {
  return {
    type: types.GET_DELETED_PROJECT,
  };
}

export function getDeletedProjectsSuccess(data) {
  return {
    type: types.GET_DELETED_PROJECT_SUCCESS,
    data,
  };
}

export function updateGameReleasingData(data) {
  return {
    type: types.UPDATE_GAME_RELEASING_DATA,
    data,
  };
}

export function updateGameReleasingDataSuccess(data) {
  return {
    type: types.UPDATE_GAME_RELEASING_DATA_SUCCESS,
    data,
  };
}

export function getRatePlan(payload) {
  return {
    type: types.GET_RATE_PLAN,
    payload,
  };
}

export function getRatePlanSuccess(payload) {
  return {
    type: types.GET_RATE_PLAN_SUCCESS,
    payload,
  };
}

export function putRatePlan(payload) {
  return {
    type: types.PUT_RATE_PLAN,
    payload,
  };
}

export function putRatePlanSuccess(payload) {
  return {
    type: types.PUT_RATE_PLAN_SUCCESS,
    payload,
  };
}

export const toggleDiscountOpen = (payload) => ({
  type: types.TOGGLE_DISCOUNT_OPEN,
  payload,
});

export const postDiscount = (payload) => ({
  type: types.POST_DISCOUNT,
  payload,
});

export const postDiscountSuccess = (payload) => ({
  type: types.POST_DISCOUNT_SUCCESS,
  payload,
});
