// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Table } from 'reactstrap';
import Toggle from 'react-bootstrap-toggle';
import Octicon, {
  X,
  TriangleUp,
  TriangleDown,
  ChevronDown,
  CircleSlash,
  Gear,
  Question,
} from '@primer/octicons-react';
import clone from 'ramda/src/clone';
import isEqual from 'lodash/isEqual';

const DatatablePage = ({
  columns,
  rows = [],
  requiredAction,
  secondRequiredAction,
  accountDetail,
  projectRelease,
  onSortAsc,
  onSortDesc,
  sortingColumns,
  actualData,
  deleteRow,
  modifyRow,
  gear,
  selectedId,
  isSearched,
  tableType,
  id,
  customClassName,
  sortingParam,
  requestParams,
  actualLastItem,
  requestAction,
  isLast,
  //   출시 프로젝트 관련 액션
  additionalParamIndex,
  clickableIndex,
  nonClickableIndicator,
  additionalParam,
  importantListLength,
  updateEmailUsing,
  reorder,
  onUpdateShowcaseOrder,
  questionMark,
  shouldNotScroll,
  indexMethodArr,
  onCheck,
}) => {
  const [checkList, setCheckList] = useState(
    Array(rows.length || 0).fill(false)
  );
  const prevRows = useRef([]);

  const sortAsc = (param) => {
    let sortingField;
    const clonedRows = clone(actualData);

    columns.forEach((elem) => {
      if (elem.label === param) {
        sortingField = elem.field;
      }
    });

    if (sortingField.includes('Date')) {
      // 날짜 열인 경우
      const _rows = clonedRows.sort((a, b) => {
        if (
          typeof a[sortingField] === 'string' ||
          typeof b[sortingField] === 'string'
        ) {
          return 1;
        }

        if (a[sortingField].isBefore(b[sortingField])) {
          return -1;
        }

        if (a[sortingField].isAfter(b[sortingField])) {
          return 1;
        }

        return 0;
      });
      onSortAsc(_rows, isSearched, tableType);
      return;
    }

    const _rows = clonedRows.sort((a, b) => {
      if (a[sortingField] === '-' && b[sortingField] !== '-') {
        // console.log('=== - && !== - ');
        return -1;
      }
      if (a[sortingField] !== '-' && b[sortingField] === '-') {
        // console.log('!== - && === - ');
        return 1;
      }
      if (b[sortingField] === '-' && b[sortingField] === '-') {
        // console.log('=== - && === - ');
        return 0;
      }

      // DESC: 오리지날 데이터가 toLocaleString() 적용된 문자열일 경우 예외처리 구문
      if (
        typeof a[sortingField] === 'string' ||
        typeof b[sortingField] === 'string'
      ) {
        let aSortingField;
        let bSortingField;

        if (a[sortingField].includes(',')) {
          aSortingField = a[sortingField].replace(',', '');
        }
        if (b[sortingField].includes(',')) {
          bSortingField = b[sortingField].replace(',', '');
        }
        if (!aSortingField) aSortingField = a[sortingField];
        if (!bSortingField) bSortingField = b[sortingField];

        return Number(aSortingField) - Number(bSortingField);
      }
      return a[sortingField] - b[sortingField];
    });
    onSortAsc(_rows, isSearched, tableType);
  };

  const sortDesc = (param) => {
    let sortingField;
    const clonedRows = clone(actualData);
    columns.forEach((elem) => {
      if (elem.label === param) {
        sortingField = elem.field;
      }
    });

    if (sortingField.includes('Date')) {
      // 날짜 열인 경우

      const _rows = clonedRows.sort((a, b) => {
        // 최종 접속일 값이 null일 경우, 예외처리

        if (
          typeof a[sortingField] === 'string' ||
          typeof b[sortingField] === 'string'
        ) {
          return 1;
        }

        if (a[sortingField].isBefore(b[sortingField])) {
          return 1;
          // return -1;
        }

        if (a[sortingField].isAfter(b[sortingField])) {
          return -1;
          // return 1;
        }

        return 0;
      });
      onSortDesc(_rows, isSearched, tableType);
      return;
    }

    const _rows = clonedRows.sort((a, b) => {
      if (a[sortingField] === '-' && b[sortingField] !== '-') {
        console.log('=== - && !== - ');
        return 1;
      }
      if (a[sortingField] !== '-' && b[sortingField] === '-') {
        console.log('!== - && === - ');
        return -1;
      }
      if (b[sortingField] === '-' && b[sortingField] === '-') {
        console.log('=== - && === - ');
        return 0;
      }

      // DESC: 오리지날 데이터가 toLocaleString() 적용된 문자열일 경우 예외처리 구문
      if (
        typeof a[sortingField] === 'string' ||
        typeof b[sortingField] === 'string'
      ) {
        let aSortingField;
        let bSortingField;

        if (a[sortingField].includes(',')) {
          aSortingField = a[sortingField].replace(',', '');
        }
        if (b[sortingField].includes(',')) {
          bSortingField = b[sortingField].replace(',', '');
        }
        if (!aSortingField) aSortingField = a[sortingField];
        if (!bSortingField) bSortingField = b[sortingField];

        return Number(bSortingField) - Number(aSortingField);
      }

      return b[sortingField] - a[sortingField];
    }); // sort 결과는 원본을 수정함 -> 리턴할 필요 없음
    onSortDesc(_rows, isSearched, tableType);
  };

  const makeColumsRow = (columns) => {
    if (!columns.length) {
      return;
    }
    if (columns.length) {
      return columns.map((column, i) => {
        const forKey = i;

        const { label } = column;

        if (onCheck && forKey === 0) {
          return (
            <th className={label} key={forKey}>
              <input
                type="checkbox"
                checked={checkList.every((check) => check)}
                onChange={handleAllChange}
              />
            </th>
          );
        }

        const isSortable = sortingColumns
          ? sortingColumns.includes(column.label)
          : null;
        return (
          <th className={label} key={forKey}>
            {label}
            {isSortable ? (
              <span className="sortingIconSet">
                <span id={label} className="sortingIcon">
                  <Octicon verticalAlign="text-top" icon={TriangleUp} />
                </span>
                <span
                  onClick={(e) => {
                    if (!sortingParam) return sortAsc(e.currentTarget.id);
                    sortDesc(e.currentTarget.id);
                  }}
                  id={label}
                  className="sortingIcon"
                >
                  <Octicon
                    onClick={sortDesc}
                    verticalAlign="text-bottom"
                    icon={TriangleDown}
                  />
                </span>
              </span>
            ) : null}
          </th>
        );
      });
    }
  };

  const [, setScrollState] = useState(false);

  useEffect(() => {
    if (shouldNotScroll) {
      setScrollState(true);
    }
  }, [shouldNotScroll]);

  const handleCheck = useCallback(
    (list) => {
      if (onCheck) {
        onCheck(
          list
            .map((check, index) => (check ? index : null))
            .filter((item) => item !== null)
        );
      }
    },
    [onCheck]
  );

  useEffect(() => {
    // 목록이 바뀌지 않았는데 자꾸 rows를 새로운 값으로 가져오는 문제가 있어서 isEqual 사용
    if (!isEqual(rows, prevRows.current)) {
      const newCheckList = Array(rows.length || 0).fill(false);
      handleCheck(newCheckList);
      setCheckList(newCheckList);
    }
    prevRows.current = rows;
  }, [rows, handleCheck]);

  const handleAllChange = ({ target }) => {
    const newCheckList = checkList.map(() => target.checked);
    handleCheck(newCheckList);
    setCheckList(newCheckList);
  };

  const handleChange = ({ target }) => {
    const newCheckList = [...checkList];
    newCheckList[target.value] = target.checked;
    handleCheck(newCheckList);
    setCheckList(newCheckList);
  };

  const modElemClassName = (key, i, arr) => {
    let isClickable;
    // 셀 객체를 key param으로 전달 받는다

    if (clickableIndex && clickableIndex.includes(i)) {
      isClickable = 'clickable-td';

      const value = Object.values(key)[0];

      if (nonClickableIndicator && nonClickableIndicator.includes(value))
        return '';
      return isClickable;
    }
    if (secondRequiredAction && i === arr.length - 1) return 'clickable-td';
    if (!clickableIndex && requiredAction && i) return 'clickable-td';
    if (clickableIndex && clickableIndex.includes(i) && requiredAction) {
      return 'clickable-td';
    }
  };

  const handleOnClick = (e, key, arr, i) => {
    // console.log(i);
    if (indexMethodArr) {
      const found = indexMethodArr.find(
        (indexMethod) => Number(indexMethod.index) === Number(i)
      );
      if (found) return found.method(arr);
      return;
    }

    if (onUpdateShowcaseOrder && (i === 0 || i === 1)) {
      onUpdateShowcaseOrder(
        key.reorder.props.children.props.id,
        arr[5]._inDate.inDate
      );
    }

    if (requiredAction && (i === 9 || i === 11) && projectRelease) {
      if (secondRequiredAction && i === arr.length - 1)
        return secondRequiredAction(key, arr, selectedId);
      requiredAction(Object.keys(key)[0], arr);
      return;
    }
    if (!projectRelease && requiredAction && !i) {
      let id;
      if (arr[1].account_id) id = arr[1].account_id.account_id;
      if (accountDetail) return requiredAction(id, accountDetail);
      requiredAction(key, arr);
    }
    if (secondRequiredAction && i === arr.length - 1)
      secondRequiredAction(key, arr, selectedId);
    if (requiredAction && Object.keys(key)[0] === additionalParam) {
      const releasedPjts = arr[additionalParamIndex];
      requiredAction(releasedPjts, arr);
    }
    if (requiredAction && reorder && i === 2)
      requiredAction(key, arr[5]._inDate.inDate);
  };

  const makeRows = (rows) => {
    if (!rows.length)
      return (
        <tr>
          <td style={{ width: '100%', textAlign: 'left' }} colSpan="1000">
            데이터가 없습니다.
          </td>
        </tr>
      );

    const rowsSet = rows.map((row, i) => {
      let tempArr = [];
      for (const key in row) {
        tempArr = [...tempArr, { [key]: row[key] }];
      }

      if (onCheck) {
        tempArr[0] = {
          check: (
            <input
              type="checkbox"
              value={i}
              checked={checkList[i]}
              onChange={handleChange}
            />
          ),
        };
      }

      if (questionMark) {
        // console.log(questionMark, row.clientAppId, 'questionMark is what')
        tempArr[3] = {
          clientAppId: (
            <span id={`a${row.clientAppId}`}>
              <Octicon id="down" verticalAlign="text-top" icon={Question} />
            </span>
          ),
        };
      }

      if (reorder) {
        tempArr.unshift({
          reorder: (
            <span>
              <Octicon id="down" verticalAlign="text-top" icon={TriangleDown} />
            </span>
          ),
        });
        tempArr.unshift({
          reorder: (
            <span>
              <Octicon
                // className={label}
                id="up"
                verticalAlign="text-top"
                icon={TriangleUp}
              />
            </span>
          ),
        });
      }

      if (deleteRow)
        tempArr.push({
          deleteRow: (
            <span>
              <Octicon icon={X} />
            </span>
          ),
        });
      if (modifyRow) {
        if (gear) {
          tempArr.push({
            modifyRow: (
              <span>
                <Octicon icon={Gear} viewBox="0 0 0 0" />
              </span>
            ),
          });
        } else
          tempArr.push({
            modifyRow: (
              <span>
                <Octicon icon={CircleSlash} />
              </span>
            ),
          });
      }
      if (updateEmailUsing) {
        const { using } = tempArr[3];
        //   console.log(tempArr, "1234");
        tempArr[3] = {
          modifyRow: (
            <Toggle
              id={`updateEmailUsing_${i}`}
              // onClick={() => handleToggleActive(row)}
              on=" "
              off=" "
              width="null"
              height="null"
              size="xs"
              offstyle="danger"
              active={using}
            />
          ),
        };
      }

      return tempArr.map((key, i, arr) => {
        const forKey = i;

        // key가 객체일 때 예외처리 -- 추후에 수정할 필요성이 발생할 수 있음
        if (!Object.values(key)[0] || Object.values(key)[0] === '-') {
          return <td key={forKey}>{Object.values(key)[0]}</td>;
        }
        if (Object.values(key)[0] && !Object.values(key)[0].noDisplay) {
          return (
            <td
              id={
                Object.keys(key)[0] === 'google'
                  ? `google${arr[2].id}`
                  : Object.keys(key)[0] === 'apple'
                  ? `apple${arr[2].id}`
                  : Object.keys(key)[0] === 'Revenue' && arr[3].specificRevenue
                  ? `R${key.Revenue.replace(/,/g, '')}${
                      arr[3].specificRevenue.id
                    }`
                  : Object.keys(key)[0] === 'discountRate'
                  ? `discount${arr[2].id}`
                  : undefined
              }
              key={forKey}
              className={modElemClassName(key, i, arr)}
              onClick={(e) => {
                handleOnClick(e, key, arr, i);
              }}
            >
              {Object.values(key)[0]}
            </td>
          );
        }
        return null;
      });
    });

    return rowsSet.map((row, i) => {
      const forKey = i;
      return (
        <tr
          className={
            importantListLength && importantListLength > i ? 'fixed-row' : null
          }
          key={forKey}
        >
          {row}
        </tr>
      );
    });
  };

  const scrollOnDataTable = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    // console.log(scrollHeight,scrollTop,clientHeight, '1201 scrollHeight,scrollTop,clientHeight')
    // console.log(scrollTop, Math.floor(scrollTop), '1201 scrollTop')

    if (scrollTop === 0) return;
    if (scrollHeight > scrollTop + clientHeight) setScrollState(false);
    if (scrollHeight === scrollTop + clientHeight) {
      setScrollState(true);
    }
    if (scrollHeight === Math.ceil(scrollTop) + clientHeight)
      setScrollState(true);
  };

  const onShowMore = () => {
    let endDate;

    if (requestParams) {
      if (
        requestParams.params &&
        requestParams.params.statType &&
        requestParams.params.statType.status === '일별' &&
        actualLastItem
      ) {
        endDate = actualLastItem.Date ? actualLastItem.Date : actualLastItem;
        const { id } = requestParams.params;
        requestAction.method(id, { endDate }); // onGetDailyStatisticsProjects === requestAction
        return;
      }
      if (
        requestParams.params &&
        requestParams.params.statType &&
        requestParams.params.statType.status === '월별' &&
        actualLastItem
      ) {
        endDate = actualLastItem;

        requestAction.method({ endDate }); // onGetMonthlyStatisticsProjects === requestAction
        return;
      }
      requestAction(requestParams); // '더보기' 액션으로 분기처리하기 위한 파라미터 전달
    }
    if (!requestParams) requestAction();
  };

  return (
    <>
      <Table
        onScroll={scrollOnDataTable}
        className={customClassName}
        id="table_dataTable"
      >
        <thead className={`main-table ${customClassName}`} id={id || null}>
          <tr>{columns && makeColumsRow(columns)}</tr>
        </thead>
        <tbody className={`main-table ${customClassName}`} id="tableBody">
          {rows && makeRows(rows)}
        </tbody>
      </Table>
      <div className={!isLast && rows ? 'showMore' : 'showMore-hidden'}>
        <button
          className="btn btn-secondary m-btn m-btn--icon"
          onClick={onShowMore}
        >
          <span>
            <Octicon icon={ChevronDown} />
            <span>더보기</span>
          </span>
        </button>
      </div>
    </>
  );
};

export default DatatablePage;
