// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { LOCATION_CHANGE } from 'react-router-redux';

// import * as selectors from './selector';
import * as types from './constant';

const initialState = {
  currentBill: [],
  columns: [
    {
      label: '아이디',
      field: '아이디',
    },
    {
      label: '회사명',
      field: '회사명',
    },
    {
      label: '프로젝트',
      field: '프로젝트',
    },
    {
      label: '가입일',
      field: 'inDate',
    },
    {
      label: '현재요금(원)',
      field: 'currentChargingPrice',
    },
    {
      label: '총납부요금(원)',
      field: 'totalPaidAmount',
    },
  ],
  detailColumns: [
    {
      label: '이용내역',
    },
    {
      label: '이용금액',
    },
    {
      label: '무료제공',
    },
    {
      label: '합산금액',
    },
    {
      label: '요금할인',
    },
    {
      label: '청구금액',
    },
  ],

  accountBill: [
    {
      billPlan: '수익쉐어',
      beforeDiscount: 20000,
      discount: 10000,
      actualCharge: 10000,
    },
    {
      billPlan: 'DB요금',
      beforeDiscount: 20000,
      discount: 10000,
      actualCharge: 10000,
    },
  ],
  detail: { clickedId: '', chargeDetail: false },
  completeCharge: true,
  sumResults: undefined,
  searchKeywords: {
    status: '이달',
  },
  gameBill: {
    list: {},
    costSum: 0,
  },
  isLast: false,
  offset: 0,
};

function currentCharge(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return initialState;
    case types.INIT_CURRENT_CHARGE_PAYPERUSE_LIST:
      return {
        ...state,
        currentBill: [],
      };
    case types.GET_COLUMNS:
      return {
        ...state,
        columns: action.columns,
      };

    case types.CLICK_CURRENT_CHARGE_PAYPERUSE_ROW:
      return {
        ...state,
        detail: {
          clickedId: action.id,
          chargeDetail: true,
          accountInfo: action.arr,
        },
      };
    case types.GET_BACK_TO_PAYPERUSE_MAIN_TABLE:
      return {
        ...state,
        detail: { clickedId: '', chargeDetail: false },
        searchKeywords: {
          ...state.searchKeywords,
          status: '이달',
        },
      };
    case types.HANDLE_OPEN_PAYPERUSE_LAYERD_MODAL:
      return {
        ...state,
        isLayerdModalOpen: true,
      };
    case types.GET_CURRENT_PAYPERUSE_BILL:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_CURRENT_PAYPERUSE_BILL_SUCCESS:
      const { AccountList, isLast } = action.rows;

      return {
        ...state,
        currentBill: action.newData
          ? [...state.currentBill, ...AccountList]
          : AccountList,
        isLast: isLast,
        isLoading: false,
        offset: state.offset + AccountList.length,
      };

    case types.SORT_ASC_CURRENT_PAYPERUSE_ACCOUNT_LIST:
      return {
        ...state,
        currentBill: action.rows,
        sortingParam: true,
      };

    case types.SORT_DESC_CURRENT_PAYPERUSE_ACCOUNT_LIST:
      return {
        ...state,
        currentBill: action.rows,
        sortingParam: false,
      };

    case types.GET_CURRENT_ACCOUNT_BILL_PAYPERUSE_SUCCESS:
      return {
        ...state,
        accountBill: action.accountBill,
      };
    case types.GET_CURRENT_GAME_BILL_PAYPERUSE_SUCCESS:
      return {
        ...state,
        gameBill: action.gameBill,
        gameTitle: action.gameTitle,
      };
    case types.GET_SEARCH_KEYWORDS_CURRENT_PAYPERUSE:
      return {
        ...state,
        searchKeywords: {
          ...state.searchKeywords,
          ...action.obj,
        },
        offset: 0,
      };
    case types.GET_ACCOUNT_STATUS_PAYPERUSE_SUCCESS:
      return {
        ...state,
        status: action.status,
      };

    // ******************************
    case types.HANDLE_CLOSE_CHARGE_MODAL:
      return {
        ...state,
        detail: { clickedId: '', chargeDetail: false },
      };

    case types.CONFIRM_CHANGE_STATUS:
      return {
        ...state,
        detail: { clickedId: '', chargeDetail: false },
      };
    case types.SAVE_INPUT:
      return {
        ...state,
        reasonToBeAdded: action.value,
      };

    default:
      return state;
  }
}

export default currentCharge;
