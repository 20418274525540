/*
 * Copyright 2013-2022 AFI, Inc. All Rights Reserved.
 */

import React, { useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';

import ExcelExport from './ExcelExport';
import ButtonToPrintOut from './ButtonToPrintOut';
import CsvUploadButton from './csvUploadButton';

const ButtonsGroup = ({
  csvData,
  csvFileName,
  pdfData,
  columns,
  customClassName,
  ids,
  handleCreateDiscount,
  handleCreateCredit,
  idData,
  idFileName,
  // dashboard only
  turnoverAccount,
  turnoverGame,
}) => {
  //   console.log(csvData, "csvData");
  const [isClicked, handleClick] = useState({
    print: false,
    csv: false,
    pdf: false,
  });

  const setStateForClick = (str) => {
    handleClick({ ...isClicked, [str]: !isClicked[str] });
  };

  return (
    <ButtonGroup
      className={`m--margin-bottom-20 float-right ${customClassName}`}
    >
      {/* {credit && <CsvUploadButton credit={credit} />} */}
      {handleCreateDiscount ? (
        <Button onClick={handleCreateDiscount}>할인 적용</Button>
      ) : null}
      {handleCreateCredit ? (
        <Button onClick={handleCreateCredit}>크레딧 지급</Button>
      ) : null}
      {ids && <ExcelExport ids={ids} csvData={idData} fileName={idFileName} />}
      <ButtonToPrintOut
        columns={columns}
        // columns={[...columns.map(obj => obj.label)]}
        pdfData={pdfData}
      />
      <ExcelExport
        turnoverAccount={turnoverAccount}
        turnoverGame={turnoverGame}
        csvData={csvData}
        fileName={csvFileName}
      />
      <Button
        onClick={() => setStateForClick('print')}
        active={isClicked.print === true}
      >
        PDF
      </Button>
    </ButtonGroup>
  );
};

export default ButtonsGroup;
