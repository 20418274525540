// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import ButtonsGroup from '../../../fnComponents/buttonGroup';
import DataTable from '../../../fnComponents/_DataTable';
import SearchForm from '../../../fnComponents/SearchForm';

import * as actions from './store/action';
import * as selectors from './store/selector';

const Retention = ({
  // state props
  retention,
  retentionCSV,
  projects,
  searchKeywords,
  // action props
  onGetPJTRetention,
  onGetRetention,
  onGetSearchKeywords,
}) => {
  const columns = [
    {
      label: '일자',
      field: 'date',
    },
    {
      label: '가입자',
      field: '',
    },
    {
      label: '+1일',
      field: '',
    },
    {
      label: '+2일',
      field: '',
    },
    {
      label: '+3일',
      field: '',
    },
    {
      label: '+4일',
      field: '',
    },
    {
      label: '+5일',
      field: '',
    },
    {
      label: '+6일',
      field: '',
    },
    {
      label: '+7일',
      field: '',
    },
    {
      label: '+15일',
      field: '',
    },
    {
      label: '+30일',
      field: '',
    },
  ];

  const [projectColumns, setProjectColumns] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (projects) {
      setProjectColumns([
        { label: '프로젝트', field: 'project' },
        { label: '아이디(이메일)', field: 'id' },
        { label: '회사명', field: 'company' },
        { label: '생성일', field: 'inDate' },
      ]);
    } else setProjectColumns(null);
  }, [projects]);

  const handleClickPJT = (a, arr) => {
    const found = arr.find((obj) => {
      const _key = Object.keys(obj)[0];
      return _key === 'projectId';
    });
    // console.log('found', found.projectId.value);
    // console.log('found', searchKeywords);
    onGetPJTRetention(
      found.projectId.value,
      searchKeywords.inDate
        ? moment(searchKeywords.inDate).format('YYYY-MM-DD')
        : moment().subtract(1, 'day').format('YYYY-MM-DD')
    );
  };

  const handleOnGetRetention = () => {
    // console.log('searchKeywords', searchKeywords);
    const { title, id } = searchKeywords;
    if (!title && !id) {
      setErrors('프로젝트명이나 아이디를 입력해주세요.');
      return;
    }
    onGetRetention();
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title">통계 - 리텐션</h3>
              </div>
            </div>
          </div>
          {/* m-content 부터 */}
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <SearchForm
                      initDateValue={
                        searchKeywords.inDate
                          ? moment(searchKeywords.inDate)
                          : moment().subtract(1, 'day')
                      }
                      keywords={['프로젝트', '아이디', '일자']}
                      mainlyRequiredAction={handleOnGetRetention}
                      initAction={() => {}}
                      onGetSearchKeywords={onGetSearchKeywords}
                    />
                    <div className="error visible message">{errors}</div>
                    <ButtonsGroup
                      // customClassName="btn-group-in-row"
                      columns={columns}
                      pdfData={retentionCSV}
                      csvData={retentionCSV}
                      csvFileName={`retention_${moment().format(
                        'YYYY-MM-DD HH:mm'
                      )}`}
                    />
                    <DataTable
                      clickableIndex={projects ? [0] : null}
                      requiredAction={projects ? handleClickPJT : null}
                      customClassName={
                        projects ? 'statistics-projects' : 'retention-table'
                      }
                      columns={projectColumns || columns}
                      rows={projects || retention}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  retention: selectors.retentionSelector,
  retentionCSV: selectors.retentionCSVSelector,
  searchKeywords: selectors.searchKeywordsSelector,
  projects: selectors.projectsSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onGetRetention: (payload) => dispatch(actions.getRetention(payload)),
  onGetSearchKeywords: (payload) =>
    dispatch(actions.getSearchKeywords(payload)),
  onGetPJTRetention: (gameId, date) =>
    dispatch(actions.getPJTRetention(gameId, date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Retention);
