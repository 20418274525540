// import { LOCATION_CHANGE } from "react-router-redux";

// import * as selectors from './selector';
import * as types from "./constant";

const initialState = {
  isLogined: false
};

function login(state = initialState, action) {
  switch (action.type) {
    // case LOCATION_CHANGE:
    //   return {
    //     ...initialState
    //   };
    case types.HANDLE_LOGIN_SUCCESS:
      return {
        ...state,
        isLogined: true
      };
    case types.GET_USERNAME_SUCCESS:
      return {
        ...state,
        userName: action.userName
      };
    case types.HANDLE_LOGIN:
      return {
        ...state,
        name: action.loginInfo.id
      };
    case types.HANDLE_LOGOUT:
      return {
        ...state,
        isLogined: false
      };
    case types.HANDLE_LOGIN_ERROR:
      return {
        ...state,
        errMsg: action.errMsg
      };

    default:
      return state;
  }
}

export default login;
