import { createSelector } from 'reselect';

import aws from 'aws-sdk';
import moment from 'moment';

export const clickedIdSelector = createSelector(
	(state) => state.emailList.detail,
	(detail) => detail
);

export const columnsSelector = createSelector(
	(state) => state.emailList.columns,
	(columns) => columns
);

export const lastEvaluatedKeySelector = createSelector(
	(state) => state.emailList.lastEvaluatedKey,
	(lastEvaluatedKey) => lastEvaluatedKey
);

export const isAdminSelector = createSelector(
	(state) => state.emailList.isAdmin,
	(isAdmin) => isAdmin
);

export const selectedInDateSelector = createSelector(
	(state) => state.emailList.selectedInDate,
	(selectedInDate) => selectedInDate
);

export const _lastEvaluatedKeySelector = createSelector(
	(state) => state.emailList._lastEvaluatedKey,
	(_lastEvaluatedKey) => _lastEvaluatedKey
);

export const _emailListSelector = createSelector(
	(state) => state.emailList._emailList,
	(_emailList) => _emailList
);

export const deleteSentMailSuccessSelector = createSelector(
	(state) => state.emailList.deleteSentMailSuccess,
	(deleteSentMailSuccess) => deleteSentMailSuccess
);

const handleSendToValue = (sendTo, sendToValue, sendCount) => {
	if (sendTo === 'total') {
		return `전체 (${sendCount})`;
	}
	if (sendTo === 'email') {
		return `${sendToValue}`;
	}
	if (sendTo === 'upload') {
		return `업로드 (${sendCount})`;
	}
	if (sendTo === 'adAgree') {
		return `수신동의 (${sendCount})`;
	}
};

export const emailListForViewSelector = createSelector(
	(state) => state.emailList,
	({ _emailList, isAdmin }) => {
		let arr = [];
		if (isAdmin) {
			Object.keys(_emailList).forEach((objKey) => {
				arr = [
					...arr,
					..._emailList[objKey].map((email, i) => {
						const temp = aws.DynamoDB.Converter.unmarshall(email);
						return {
							title: temp.title,
							sendToValue: handleSendToValue(
								temp.sendTo,
								temp.sendToValue,
								temp.sendCount
							),
							inDate: moment(temp.sendTime).format('YYYY.MM.DD HH:mm'),
							param: {
								noDisplay: true,
								inDate: temp.inDate,
							},
							successCount: `${temp.successCount} / ${temp.failCount}`,
							isSent: temp.isSent ? '발송완료' : '발송대기',
							deleteBtn: true,
						};
					}),
				];
			});
		} else {
			Object.keys(_emailList).forEach((objKey) => {
				arr = [
					...arr,
					..._emailList[objKey].map((email, i) => {
						const temp = aws.DynamoDB.Converter.unmarshall(email);
						return {
							title: temp.title,
							sendToValue: temp.sendToValue,
							inDate: moment(temp.inDate).format('YYYY.MM.DD HH:mm'),
							param: {
								noDisplay: true,
								inDate: temp.inDate,
							},
							// successCount: `${temp.successCount} / ${temp.failCount}`,
							isRead: temp.isRead
								? moment(temp.isRead).format('YYYY.MM.DD HH:mm')
								: '읽지 않음',
							isSent: temp.isSent ? '발송완료' : '발송대기',
							deleteBtn: true,
						};
					}),
				];
			});
		}

		// console.log('emailListForViewSelector', arr);
		return arr;
	}
);

export const emailListSelector = createSelector(
	(state) => state.emailList._emailList,
	(_emailList) => {
		let arr = [];
		Object.keys(_emailList).forEach((key) => {
			const list = _emailList[key];
			const unmarshalled = list.map((email) =>
				aws.DynamoDB.Converter.unmarshall(email)
			);
			arr = [...arr, ...unmarshalled];
		});

		return arr;
	}
);

export const sentMailDetailsSelector = createSelector(
	(state) => state.emailList.sentMailDetails,
	(sentMailDetails) => {
		console.log('SELECT 할 때', sentMailDetails);
		const { details } = sentMailDetails;
		let obj = {
			...sentMailDetails,
		};
		Object.keys(details).forEach((key) => {
			const list = details[key];
			const unmarshalled = list.map((item) =>
				aws.DynamoDB.Converter.unmarshall(item)
			);
			obj = {
				...sentMailDetails,
				details: {
					...obj.details,
					[key]: unmarshalled,
				},
			};
		});
		return obj;
	}
);
