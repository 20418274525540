// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { LOCATION_CHANGE } from 'react-router-redux';

// import * as selectors from './selector';
import * as types from './constant';

const initialState = {
  whereTo: '',
  columns: [
    {
      label: '아이디',
      field: 'ID',
      sort: 'asc',
      width: 150,
    },
    {
      label: '회원아이디',
      field: 'account_id',
      sort: 'asc',
      width: 150,
    },
    {
      label: '회사명',
      field: 'company',
      sort: 'asc',
      width: 270,
    },
    {
      label: '국가',
      field: 'country',
      sort: 'asc',
      width: 270,
    },
    {
      label: '프로젝트',
      field: 'project',
      sort: 'asc',
      width: 100,
    },
    {
      label: '관리자계정',
      field: 'admin',
      sort: 'asc',
      width: 150,
    },
    {
      label: '크레딧',
      field: 'credit',
      sort: 'asc',
      width: 100,
    },
    {
      label: '총납부요금(원)',
      field: 'chargedTotal',
      sort: 'asc',
      width: 100,
    },
    {
      label: '가입일',
      field: 'signUpDate',
      sort: 'asc',
      width: 100,
    },
    {
      label: '최종 접속일',
      field: 'lastLoginDate',
      sort: 'asc',
      width: 100,
    },
    {
      label: '고객규모',
      field: 'is100mClub',
      sort: 'asc',
      width: 100,
    },
    {
      label: '상태',
      field: 'status',
      sort: 'asc',
      width: 100,
    },
    {
      label: '콘솔',
      field: 'console',
      sort: 'asc',
      width: 100,
    },
  ],
  subMenuOpen: '',
  isLanding: 'console',
};

function leftAsideMenuReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
      };
    case types.MOVE_TO_DELETE_ACCOUNT:
      return action.deleteAccount
        ? {
            ...state,
            whereTo: action.deleteAccount ? 'deleteAccount' : '',
            columns: [
              { label: '아이디', field: 'id', sort: 'asc', width: 100 },
              {
                label: '탈퇴일시',
                field: 'deletedDate',
                sort: 'asc',
                width: 100,
              },
              { label: '구분', field: 'which', sort: 'asc', width: 100 },
              { label: '관리자', field: 'admin', sort: 'asc', width: 100 },
              { label: '사유', field: 'reason', sort: 'asc', width: 100 },
            ],
          }
        : {
            ...state,
            whereTo: '',
            columns: [
              { label: '아이디', field: 'ID', sort: 'asc', width: 100 },
              { label: '회사명', field: 'company', sort: 'asc', width: 100 },
              { label: '프로젝트', field: 'project', sort: 'asc', width: 100 },
              { label: '관리자계정', field: 'admin', sort: 'asc', width: 100 },
              { label: '크레딧', field: 'credit', sort: 'asc', width: 100 },
              {
                label: '총납부요금(원)',
                field: 'chargedTotal',
                sort: 'asc',
                width: 100,
              },
              { label: '가입일', field: 'signUpDate', sort: 'asc', width: 100 },
              {
                label: '최종접속일',
                field: 'lastLogin',
                sort: 'asc',
                width: 100,
              },
              { label: '상태', field: 'status', sort: 'asc', width: 100 },
            ],
          };
    case types.OPEN_SUB_MENU:
      return {
        ...state,
        subMenuOpen: action.obj,
        clickedSubMenu: undefined,
      };
    case types.HANDLE_CLICK_SUBMENU:
      return {
        ...state,
        clickedSubMenu: action.obj,
      };
    case types.MOVE_TO_LANDING_MENU:
      return {
        ...state,
        isLanding: 'landing',
      };
    case types.MOVE_TO_CONSOLE_MENU:
      return {
        ...state,
        isLanding: 'console',
      };
    case types.MOVE_TO_DEVELOPER_MENU:
      return {
        ...state,
        isLanding: 'developer',
      };
    default:
      return state;
  }
}

export default leftAsideMenuReducer;
