import { LOCATION_CHANGE } from "react-router-redux";

// import * as selectors from './selector';
import * as types from "./constant";

const initialState = {
 inquiriesList: [],
 lastEvaluatedKey: null
};

function inquiry(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...initialState
      };

      case types.GET_INQUIRIES_LIST_SUCCESS:
        let inquiriesList = null;

        if(action.isSearching && action.isNext) {
            inquiriesList = [...state.inquiriesList, ...action.data]
        }
        if(action.isSearching && !action.isNext) {
            inquiriesList = action.data
        }
        if(!action.isSearching && action.isNext) {
            inquiriesList = [...state.inquiriesList, ...action.data]
        }
        if(!action.isSearching && !action.isNext) {
            inquiriesList = action.data
        }
      return {
        ...state,
        // inquiriesList: action.isSearching && !action.isNext ? action.data : [...state.inquiriesList, ...action.data],
        inquiriesList,
        isSearching: action.isSearching,
        isNext: action.isNext,
      };
      case types.REQUEST_LIST_SUCCESS:
      return {
        ...state,
        lastEvaluatedKey: action.lastEvaluatedKey
      };

      case types.DELETE_INQUIRY:
      return {
        ...state,
        inquiriesList: state.inquiriesList.filter((inquiry, i) => inquiry.inDate !== action.inDate)
      };

    //   case types.SEND_RESPONSE:
    //   return {
    //     ...state,
    //     inquiriesList: state.inquiriesList.map((inquiry, i) => {
    //         if(action.inDate === inquiry.inDate) {
    //             return {
    //                 ...inquiry,
    //                 status: 'y'
    //             }
    //         }
    //         return inquiry
    //     })
    //   };

    default:
      return state;
  }
}

export default inquiry;
