// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from 'reactstrap';
import { IoMdArrowDropright, IoMdArrowDropdown } from 'react-icons/io';
import _ from 'lodash';
import moment from 'moment';

import DatePicker from '../../../fnComponents/SingleDatePicker.1';
import DateRangePicker from '../../../fnComponents/DateRangePicker';

import * as actions from './store/action';
import { numberToString } from '../../../shared/common';
import categoryMapper from '../../../static/categorymapper.json';
import keyMapper from '../../../static/keymapper.json';

const PayPerUseDetailModal = ({
  isOpen,
  handleOpenModal,
  gameTitle,
  searchKeywords,
  billType,
  gameBill,
  gameId,
}) => {
  const _fromFirstToNow = () => {
    if (searchKeywords && searchKeywords.date) {
      const { date } = searchKeywords;
      const firstOfTheMonth = moment(date)
        .subtract(1, 'month')
        .set({ date: 1 });

      const endOfTheMonth = firstOfTheMonth.endOf('month').format('YYYY.MM.DD');

      setStartDate(firstOfTheMonth.format('YYYY.MM.DD'));
      setEndDate(endOfTheMonth);

      return `${firstOfTheMonth.format('YYYY.MM.DD')} - ${endOfTheMonth}`;
    }
  };

  const [hasSubCategory, setHasSubCategory] = useState([]);

  /**
   * @description 분류 및 항복별 단위를 리턴하는 함수
   * @param {*} key 항목 이름 (예. 호출수,사용시간 등)
   * @param {*} param 출력 구분 (사용량/무료제공/이용요금)
   * @param {*} strings 출력에 필요한 리턴될 t 함수의 파라미터
   */
  const unitHandler = useCallback(
    (key, param, strings) => {
      if (param === 'usedPrice') {
        return `${strings}원`;
      }
      if (billType === 'base') {
        if (param === 'rate') {
          return `${strings}원/호출`;
        }
        if (param === 'limitCount') {
          return `${strings}호출/월`;
        }
        return `${strings}호출`;
      }
      if (billType === 'db') {
        if (param === 'rate') {
          if (key === 'readDB' || key === 'writeDB')
            return `${strings}원/처리량`;
          return `${strings}원/GB`;
        }
        if (key === 'readDB' || key === 'writeDB')
          return param === 'limitCount'
            ? `${strings}처리량/월`
            : `${strings}처리량`;
        return param === 'limitCount' ? `${strings}GB/일` : `${strings}GB`;
      }
      if (billType === 'function') {
        if (key === 'duration' && param === 'rate') return `${strings}원/분`;
        if (key === 'callCount' && param === 'rate') return `${strings}원/호출`;
        if (key === 'duration')
          return param === 'limitCount' ? `${strings}분/월` : `${strings}분`;

        return param === 'limitCount' ? `${strings}호출/월` : `${strings}호출`;
      }
      if (billType === 'push') {
        if (param === 'rate') return `${strings}원/건`;
        if (param === 'limitCount') return `${strings}건/월`;
        return `${strings}건`;
      }
      return null;
    },
    [billType]
  );

  const makeRows = () => {
    if (gameBill) {
      const { list, costSum } = gameBill;
      const arr = [];
      Object.keys(list).forEach((key, index) => {
        const {
          callCount,
          cost,
          freeCost,
          limitCount,
          price,
          subCategory,
          usedPrice,
        } = list[key];

        const subs = list[key].subCategory
          ? Object.keys(list[key].subCategory)
          : [];
        const row = (
          <>
            <tr>
              <td>
                {hasSubCategory.includes(index) ? (
                  <IoMdArrowDropdown
                    className="clickable"
                    onClick={() => {
                      const cloned = _.clone(hasSubCategory);

                      setHasSubCategory(
                        cloned.filter((item) => index !== item)
                      );
                    }}
                  />
                ) : (
                  <IoMdArrowDropright
                    className="clickable"
                    onClick={() => {
                      const cloned = _.clone(hasSubCategory);
                      cloned.push(index);
                      setHasSubCategory(cloned);
                    }}
                  />
                )}
                {categoryMapper[key]}
              </td>
              <td>
                {unitHandler(key, null, numberToString(callCount, 6, true))}
              </td>
              {isSelected ? null : (
                <td>
                  {unitHandler(
                    key,
                    'limitCount',
                    numberToString(limitCount, 6, true)
                  )}
                </td>
              )}
              <td>{unitHandler(key, 'rate', price)}</td>
              <td>
                {unitHandler(
                  key,
                  'usedPrice',
                  numberToString(usedPrice, 6, true)
                )}
              </td>
              {isSelected ? null : (
                <td>
                  {unitHandler(key, 'usedPrice', numberToString(cost, 6, true))}
                </td>
              )}
            </tr>

            {subs.map((sub) => (
              <tr
                className={
                  hasSubCategory.includes(index)
                    ? 'show-subCategory'
                    : 'no-subCategory'
                }
              >
                <td className="padd-left-10">{keyMapper[sub]}</td>
                <td>{unitHandler(key, null, list[key].subCategory[sub])}</td>
              </tr>
            ))}
          </>
        );
        arr.push(row);
      });
      return arr.length ? (
        arr
      ) : (
        <tr>
          <td>사용 내역이 없습니다.</td>
        </tr>
      );
    }
  };
  const makeColumsRow = () => {
    return (
      isSelected
        ? ['항목', '사용량', '요금단가', '이용금액']
        : ['항목', '사용량', '무료제공', '요금단가', '이용금액', '청구금액']
    ).map((column, i) => {
      const forKey = i;
      return <th key={forKey}>{column}</th>;
    });
  };

  const [isSelected, setIsSelected] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState('');

  const [focusByIcon, setFocus] = useState(null);
  const initInputObj = {
    id: '',
    company: '',
    signUpDate: '',
    lastLoginDate: '',
    status: '',
    init: false,
  };
  const [inputObj, handleInput] = useState(initInputObj);

  const dispatch = useDispatch();

  const fromFirstToNow = useMemo(() => {
    if (searchKeywords && searchKeywords.date) {
      const { date } = searchKeywords;
      const firstOfTheMonth = moment(date).subtract(1, 'month');

      const endOfTheMonth = moment(date).subtract(1, 'month').endOf('month');

      // .set({ date: 1 })

      setStartDate(firstOfTheMonth.set({ date: 1 }));
      setEndDate(endOfTheMonth);

      return `${firstOfTheMonth.format('YYYY.MM.DD')} - ${endOfTheMonth.format(
        'YYYY.MM.DD'
      )}`;
    }
  }, [searchKeywords]);

  const onGetSearchKeywords = ({ inDate }) => {
    dispatch(
      actions.getGamePPUBill(gameId, billType, gameTitle, moment(inDate).date())
    );
  };
  console.log('billType-->', billType);
  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalHeader>
        {gameTitle}
        <div>{fromFirstToNow}</div>
      </ModalHeader>
      <ModalBody>
        <div
          className="m-radio-inline"
          style={{ marginBottom: '15px', marginLeft: '42px' }}
        >
          <label className="m-radio">
            <input
              onChange={(e) => {
                setIsSelected(false);
                dispatch(actions.getGamePPUBill(gameId, billType, gameTitle));
              }}
              type="radio"
              checked={!isSelected}
            />
            전체
            <span />
          </label>
          <label className="m-radio">
            <input
              onChange={(e) => {
                setIsSelected(true);
                onGetSearchKeywords({ inDate: endDate.format('YYYY-MM-DD') });
              }}
              type="radio"
              checked={isSelected}
            />
            선택
            <span />
          </label>

          <div style={{ width: '26%', display: 'inline-block' }}>
            {isSelected ? (
              <DatePicker
                disabled={!isSelected}
                customDate={endDate}
                focusedInput={focusedInput}
                setFocusedInput={setFocusedInput}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                initInputObj={initInputObj}
                initSearchFilter={inputObj.init}
                searchParam="signUpDate"
                onGetSearchItem={onGetSearchKeywords}
                setFocus={setFocus}
                focusByIcon={focusByIcon && focusByIcon.singleDatePicker1}
              />
            ) : (
              <DateRangePicker
                disabled
                isOutsideRange={(a) => {
                  return a.isBefore(startDate) || a.isAfter(endDate);
                }}
                displayFormat="YYYY.MM.DD"
                startDate={startDate}
                endDate={endDate}
                focusedInput={focusedInput}
                setFocusedInput={setFocusedInput}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                initInputObj={initInputObj}
                initSearchFilter={inputObj.init}
                searchParam="withdrawalDate"
                setFocus={setFocus}
                focusByIcon={focusByIcon && focusByIcon.singleDatePicker1}
                onGetSearchItem={onGetSearchKeywords}
              />
            )}
          </div>
        </div>

        <Table className="main-table">
          <thead className={isSelected ? 'column-4-payperuse' : 'column-6'}>
            <tr>{makeColumsRow()}</tr>
          </thead>
          <tbody className={isSelected ? 'column-4' : 'column-6'}>
            {makeRows()}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            handleOpenModal(false);
            setIsSelected(false);
            setHasSubCategory([]);
          }}
        >
          닫기
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PayPerUseDetailModal;
