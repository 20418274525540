export const GET_SENT_MAILS = 'GET_SENT_MAILS';
export const GET_SENT_MAILS_SUCCESS = 'GET_SENT_MAILS_SUCCESS';
export const GET_SENT_MAIL_DETAILS = 'GET_SENT_MAIL_DETAILS';
export const GET_SENT_MAIL_DETAILS_SUCCESS = 'GET_SENT_MAIL_DETAILS_SUCCESS';
export const DELETE_SENT_MAIL = 'DELETE_SENT_MAIL';
export const DELETE_SENT_MAIL_SUCCESS = 'DELETE_SENT_MAIL_SUCCESS';
export const HANDLE_IS_ADMIN = 'HANDLE_IS_ADMIN';
export const HANDLE_IS_ADMIN_SUCCESS = 'HANDLE_IS_ADMIN_SUCCESS';
export const SEARCH_SENT_MAIL_DETAILS = 'SEARCH_SENT_MAIL_DETAILS';
export const SEARCH_SENT_MAIL_DETAILS_SUCCESS =
	'SEARCH_SENT_MAIL_DETAILS_SUCCESS';
