import { LOCATION_CHANGE } from 'react-router-redux';

// import * as selectors from './selector';
import * as types from './constant';

const initialState = {
	sleepingCandidatesList: {
		accountList: [],
		isLast: null,
	},
	isMakeSleepingCandidateNotSuccess: false,
	errType: null,
	columns: [
		{
			label: '아이디',
			field: 'id',
			sort: 'asc',
			width: 10,
		},
		{
			label: '회사명',
			field: 'companyName',
			sort: 'asc',
			width: 270,
		},
		{
			label: '가입일',
			field: 'inDate',
			sort: 'asc',
			width: 270,
		},
		{
			label: '최종접속일',
			field: 'latestConnect',
			sort: 'asc',
			width: 100,
		},
		{
			label: '휴면예정일',
			field: 'sleeperDue',
			sort: 'asc',
			width: 150,
		},
		{
			label: '',
			field: '',
			sort: 'asc',
			width: 100,
		},
	],

	detail: { clickedId: '', projectDetail: false },
	sortingParam: false,
	searchKeywords: {},
	offset: 0,
	searchingOffset: 0,
};

function projectManaging(state = initialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return {
				...initialState,
			};

		case types.INIT_SLEEPING_ACCOUNTS:
			return {
				...state,
				isSleepingAccounts: true,
			};

		case types.INIT_SLEEPING_CANDIDATES:
			return {
				...state,
				isSleepingAccounts: false,
			};

		case types.SET_SEARCHING:
			return {
				...state,
				offset: initialState.offset,
				searchingOffset: initialState.searchingOffset,
			};

		case types.GET_SLEEPING_CANDIDATES_SUCCESS:
			if (action.isPaging) {
				if (action.isSearching) {
					return {
						...state,
						sleepingCandidatesList: {
							accountList: [
								...state.sleepingCandidatesList.accountList,
								...action.data.accountList,
							],
						},
						searchingOffset: !action.data.isLast
							? state.searchingOffset + 25
							: state.searchingOffset,
						isLast: action.data.isLast,
					};
				}
				return {
					...state,
					sleepingCandidatesList: {
						accountList: [
							...state.sleepingCandidatesList.accountList,
							...action.data.accountList,
						],
					},
					isLast: action.data.isLast,
					offset: !action.data.isLast ? state.offset + 25 : state.offset,
				};
			} else {
				if (action.isSearching) {
					return {
						...state,
						sleepingCandidatesList: action.data,
						searchingOffset: !action.data.isLast
							? state.searchingOffset + 25
							: state.searchingOffset,
						isLast: action.data.isLast,
					};
				}
				return {
					...state,
					sleepingCandidatesList: action.data,
					offset: !action.data.isLast ? state.offset + 25 : state.offset,
					isLast: action.data.isLast,
				};
			}

		case types.SORT_ASC_SLEEPING_CANDIDATES:
			return {
				...state,
				sleepingCandidatesList: {
					...state.sleepingCandidatesList,
					accountList: action.rows,
				},
				sortingParam: true,
			};
		case types.SORT_DESC_SLEEPING_CANDIDATES:
			return {
				...state,
				sleepingCandidatesList: {
					...state.sleepingCandidatesList,
					accountList: action.rows,
				},
				sortingParam: false,
			};
		case types.GET_SEARCH_KEYWORDS:
			return {
				...state,
				searchKeywords: {
					...state.searchKeywords,
					...action.obj,
				},
			};

		default:
			return state;
	}
}

export default projectManaging;
