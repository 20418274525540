// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { createSelector } from 'reselect';

export const isErrSelector = createSelector(
	(state) => state,
	(state) => state.globalStore.isErr
);
export const errMsgSelector = createSelector(
	(state) => state,
	(state) => state.globalStore.errMsg
);
export const errCodeSelector = createSelector(
	(state) => state,
	(state) => state.globalStore.errCode
);

export const isLoadingSelector = createSelector(
	(state) => state,
	(state) => state.globalStore.isLoading
);

export const csvRowsSelector = createSelector(
	(state) => state.globalStore.csv,
	(csv) => csv
);
