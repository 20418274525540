import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  Popover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Octicon, { Search, X, ChevronDown } from '@primer/octicons-react';
import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import ButtonsGroup from '../../../fnComponents/buttonGroup';
import DataTable from '../../../fnComponents/_DataTable';

import ButtonDropdown from '../../../fnComponents/ButtonDropdown';

import * as actions from './store/action';
import * as selectors from './store/selector';

import DateRangePicker from '../../../fnComponents/DateRangePicker';

import SpinnerCompo from '../../../fnComponents/Spinner';

const Projects = ({
  columns,
  projects,
  monthlyStats,
  dailyStats,
  onHandleSearchProject,
  onChangeStatType,
  statType,
  onClickProjectRow,
  actualLastItem,
  onGetDailyStatisticsProjects,
  onGetMonthlyStatisticsProjects,
  searchedProject,
  onSortAscRows,
  onSortDescRows,
  actualMonthlyStats,
  actualDailyStats,
  sortingParam,
  onInitDataTables,
  isLoading,
  dailyStatsLastItem,
  isLast,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState('');
  const [inputSet, handleInputChange] = useState({ title: '' });
  const [errMsg, handleError] = useState('');
  const [scrollDone, setScrollState] = useState(false);

  const initInputObj = {
    type: '',
    init: '월별',
  };
  const dropdownItems = [
    {
      name: '월별',
    },
    {
      name: '일별',
    },
  ];
  const [inputObj, handleInput] = useState(initInputObj);
  // console.log(inputObj, 'inputObj');
  const handleInitDatePicker = (e) => {
    e.preventDefault();
    setStartDate(null);
    setEndDate(null);
    handleInputChange({ title: '' });
    onInitDataTables();
  };

  const onHandleInputChange = (e) => {
    // console.log(e.target.value, e.target.name);
    handleInputChange({ ...inputSet, [e.target.name]: e.target.value });
  };

  const handleSearchProject = (e) => {
    e.preventDefault();
    handleError('');

    const { title, gameId } = inputSet;
    if (!title && !gameId)
      return handleError('프로젝트명 또는 아이디를 입력해 주세요.');
    onHandleSearchProject(
      title,
      {
        startDate,
        endDate,
      },
      { isSearching: true },
      gameId
    );
  };

  const changeStatType = (v) => {
    handleError('');
    onChangeStatType(v);
  };

  const [revenue, setRevenue] = useState(false);

  const handlePopOver = (arr) => {
    // target example : clickable-td 2,418,900

    let obj = {};

    arr.forEach((item) => {
      obj = {
        ...obj,
        ...item,
      };
    });
    // console.log(obj);
    const {
      Revenue,
      specificRevenue: { iOSRevenue, AndroidRevenue, id },
    } = obj;
    setRevenue({
      Revenue: Revenue.replace(/,/g, ''),
      iOSRevenue,
      AndroidRevenue,
      id,
    });
    handleOverlay('block');
    setPopOver(true);
  };

  const [isPopOverOpen, setPopOver] = useState(false);
  const toggle = () => {
    setPopOver(!isPopOverOpen);
  };
  const handleOverlay = (param) => {
    document.querySelector('div.overlay').style.display = param;
  };
  const showPopOver = () => (
    <Popover
      fade={false}
      placement="left"
      isOpen={isPopOverOpen}
      target={`R${revenue.Revenue}${revenue.id}`}
      toggle={toggle}
    >
      <PopoverHeader>거래액정보</PopoverHeader>
      <PopoverBody>
        구글 : {revenue.AndroidRevenue}
        <br />
        애플 : {revenue.iOSRevenue}
      </PopoverBody>
    </Popover>
  );

  const handleOnGetDailyStatisticsProjects = () => {
    const { gameId } = inputSet;
    const { Date } = actualLastItem.dailyStats;
    onGetDailyStatisticsProjects(gameId || searchedProject.projectId, {
      endDate: Date,
    });
  };
  const handleOnGetMonthlyStatisticsProjects = () => {
    const { gameId } = inputSet;
    const { Date } = actualLastItem.monthlyStats;
    onGetMonthlyStatisticsProjects(gameId || searchedProject.projectId, {
      endDate: Date,
    });
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title">통계 - 프로젝트</h3>
              </div>
            </div>
          </div>
          {/* m-content 부터 */}
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <form className="m-form m-form--fit m--margin-bottom-20">
                      <div className="row m--margin-bottom-20">
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>프로젝트</label>
                          <input
                            value={inputSet.title}
                            name="title"
                            onChange={onHandleInputChange}
                            className="form-control m-input"
                          />
                          <div className="error message visible">{errMsg}</div>
                        </div>
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>아이디</label>
                          <input
                            value={inputSet.gameId}
                            name="gameId"
                            onChange={onHandleInputChange}
                            className="form-control m-input"
                          />
                        </div>
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>구분</label>
                          {/* <input className="form-control m-input" /> */}
                          <ButtonDropdown
                            initSearchFilter={inputObj.init}
                            initInputObj={initInputObj}
                            handleInput={handleInput}
                            onChangeBtnDropdown={changeStatType}
                            // onGetSearchKeywords={onGetSearchKeywords}
                            initItem="월별"
                            dropdownItems={dropdownItems}
                          />
                        </div>
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>기간</label>
                          <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            focusedInput={focusedInput}
                            setFocusedInput={setFocusedInput}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            disabled={statType && statType.status === '월별'}
                          />
                        </div>
                      </div>
                      <div className="row m--margin-bottom-20">
                        <div className="col-lg-12">
                          <button
                            onClick={handleSearchProject}
                            className="btn btn-brand m-btn m-btn--icon"
                            id="m_search"
                          >
                            <span>
                              <Octicon icon={Search} />
                              <span>검색</span>
                            </span>
                          </button>
                          &nbsp;&nbsp;
                          <button
                            className="btn btn-secondary m-btn m-btn--icon"
                            id="m_reset"
                            onClick={handleInitDatePicker}
                          >
                            <span>
                              <Octicon icon={X} />
                              <span>초기화</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </form>
                    <ButtonsGroup
                      csvData={
                        statType && statType.status === '월별'
                          ? monthlyStats
                          : dailyStats
                      }
                      csvFileName={`${inputSet.title}_${
                        statType && statType.status
                      }stat_${moment().format('YYYY-MM-DD HH:mm')}`}
                    />
                    {isPopOverOpen && showPopOver()}
                    <div
                      onClick={() => {
                        handleOverlay('none');
                        setPopOver(false);
                      }}
                      className="overlay"
                      style={{
                        width: '100%',
                        height: '100%',
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        zIndex: '100',
                        opacity: '0.5',
                        backgroundColor: 'rgb(0,0,0)',
                        display: 'none',
                      }}
                    ></div>
                    {projects && projects.length > 1 ? (
                      <DataTable
                        clickableIndex={[0]}
                        requiredAction={onClickProjectRow}
                        customClassName="statistics-projects"
                        rows={projects}
                        columns={[
                          { label: '프로젝트', field: 'project' },
                          { label: '아이디(이메일)', field: 'id' },
                          { label: '회사명', field: 'company' },
                          { label: '생성일', field: 'inDate' },
                        ]}
                      />
                    ) : (
                      <DataTable
                        clickableIndex={[1]}
                        indexMethodArr={[
                          {
                            index: 1,
                            method: handlePopOver,
                          },
                        ]}
                        isLast={isLast}
                        requestParams={{
                          type: 'statType',
                          params: {
                            statType,
                            id: searchedProject
                              ? searchedProject.projectId
                              : null,
                          },
                        }}
                        actualLastItem={
                          statType &&
                          statType.status === '월별' &&
                          actualLastItem
                            ? actualLastItem.monthlyStats
                            : actualLastItem.dailyStats
                        }
                        searchedProject={searchedProject}
                        requestAction={{
                          statType,
                          method:
                            statType && statType.status === '월별'
                              ? handleOnGetMonthlyStatisticsProjects
                              : handleOnGetDailyStatisticsProjects,
                        }}
                        sortingParam={sortingParam}
                        customClassName={
                          statType && statType.status === '월별'
                            ? 'column-8 statistics-project table-scrollable'
                            : 'column-more-than-10 table-scrollable'
                        }
                        rows={
                          statType && statType.status === '월별'
                            ? monthlyStats
                            : dailyStats
                        }
                        columns={columns}
                        tableType={statType && statType.status !== '월별'}
                        onSortAsc={onSortAscRows}
                        onSortDesc={onSortDescRows}
                        actualData={
                          statType && statType.status === '월별'
                            ? actualMonthlyStats
                            : actualDailyStats
                        }
                        sortingColumns={[
                          '일자',
                          '거래액(원)',
                          'MAU',
                          'WAU',
                          'DAU',
                          'NRU',
                          'RU',
                          'Stickiness',
                          'Revenue(원)',
                          'PU',
                          'ARPPU',
                          'PR',
                          'ARPU',
                          'P.R(%)',
                        ]}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  columns: selectors.columnsSelector,
  projects: selectors.projectsSelector,
  monthlyStats: selectors.monthlyStatsSelectorForView,
  dailyStats: selectors.dailyStatsSelectorForView,
  statType: selectors.statTypeSelector,
  actualLastItem: selectors.actualLastItemSelector,
  dailyStatsLastItem: selectors.dailyStatsLastItemSelector,
  searchedProject: selectors.searchedProjectSelector,
  actualMonthlyStats: selectors.monthlyStatsSelector,
  actualDailyStats: selectors.dailyStatsSelector,
  sortingParam: selectors.sortingParamSelector,
  isLoading: selectors.isLoadingSelector,
  isLast: selectors.isLastSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onHandleSearchProject: (title, date, opt, gameId) =>
    dispatch(actions.handleSearchProject(title, date, opt, gameId)),
  onChangeStatType: (v) => dispatch(actions.changeStatType(v)),
  onClickProjectRow: (key, arr) => dispatch(actions.clickProjectRow(key, arr)),
  onGetDailyStatisticsProjects: (projectId, date, opt) =>
    dispatch(actions.getDailyStatisticsProjects(projectId, date, opt)),
  onGetMonthlyStatisticsProjects: (projectId, date, opt) =>
    dispatch(actions.getMonthlyStatisticsProjects(projectId, date, opt)),
  onSortAscRows: (rows, isSearched, tableType) =>
    dispatch(actions.sortAscRows(rows, isSearched, tableType)),
  onSortDescRows: (rows, isSearched, tableType) =>
    dispatch(actions.sortDescRows(rows, isSearched, tableType)),
  onInitDataTables: () => dispatch(actions.initDataTables()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
