// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import { requestPost, requestGet, requestDelete } from '../../utils/request';
import Qs from 'qs';

import BaseUrl from '../../shared/baseUrl';

export const getInquiriesList = (params) => {
  const url = `${BaseUrl.LANDING_API}/inquiry/v2`;

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  return requestGet(url, options);
};

export const sendResponse = (inDate, data) => {
  const url = `${BaseUrl.LANDING_API}/inquiry/v2/${inDate}`;

  return requestPost(url, data);
};

export const deleteInquiry = (inDate) => {
  const url = `${BaseUrl.LANDING_API}/inquiry/v2/${inDate}`;

  return requestDelete(url);
};
