// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Octicon, { RepoClone, Check } from '@primer/octicons-react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import Toggle from 'react-bootstrap-toggle';
import ProjectStatusManaging from './ProjectStatusManaging';
import ProjectDetail from './ProjectDetail';
import DiscountModal from './DiscountModal';
import Header from '../../view/header';
import LeftAsideMunu from '../../view/leftAsideMenu';
import ButtonsGroup from '../../fnComponents/buttonGroup';
import DataTable from '../../fnComponents/_DataTable';
import DateRangePicker from '../../fnComponents/DateRangePicker';
import ButtonDropdown from '../../fnComponents/ButtonDropdown';
import MsgModal from '../../fnComponents/MsgModal';

import * as actions from './store/action';
import * as selectors from './store/selector';

const ProjectsManaging = ({
  columns,
  projectList,
  detail: { projectDetail },
  releaseInfo,
  releasedRowInfo,
  count,
  isLast,
  sortingParam,
  originalProjectList,
  initIndicator,
  generalProjectCount,
  ineternalProjectCount,
  enterpriseInfo,
  onClickProjectRow,
  onToggleAppReleaseInfo,
  onSortAscProjectList,
  onSortDescProjectList,
  onGetSearchKeywords,
  onGetProjectList,
  onInitProjectManaging,
  onInitCurrentProjectList,
  onUpdatePayPlan,
  onInitProjectList,
  onGetEnterpriseInfo,
  onInitEnterpriseInfo,
  onGetProject,
  onUpdateGameReleasingData,
  selectOpts,
  pjtListIsLoaded,
  searchKeywords,
  projectData,
  deletedProjects,
}) => {
  const projectRatePlan = useSelector(selectors.projectRatePlanSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      const { pathname } = window.location;
      const path = pathname.split('/')[1];
      if (path !== 'projects') {
        onInitProjectManaging();
      }
    };
  }, [initIndicator, onInitProjectManaging]);

  const [isPopOverOpen, setPopOver] = useState(false);
  const [isPopOver2Open, setPopOver2] = useState(false);
  const [isPopOver3Open, setPopOver3] = useState(false);

  useEffect(() => {
    if (pjtListIsLoaded) setPopOver2(false);
  }, [pjtListIsLoaded, setPopOver2]);

  const [shouldUpdatePlanModalOpen, handleUpdatePayPlan] = useState(false);
  const [payPlan, getPayPlan] = useState({});
  const [error, handleError] = useState('');
  const [radioBtn, handleChange] = useState('n');
  const [inputs, handleInput] = useState({});
  const [exception, handleException] = useState(false);
  const [errors, handleErrors] = useState({});
  const [checkRow, setCheckRow] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState('');
  // 삭제
  const [deleteStartDate, setDeleteStartDate] = useState(null);
  const [deleteEndDate, setDeleteEndDate] = useState(null);
  const [deleteFocusedInput, setDeleteFocusedInput] = useState('');

  const [focusByIcon, setFocus] = useState(null);
  //
  const [chatChecked, setChatChecked] = useState(false);
  const [matchChecked, setMatchChecked] = useState(false);
  const [chatLogOption, setChatLogOption] = useState('0');
  const [matchLogOption, setMatchLogOption] = useState('0');

  const [msgModalOpen, setMsgModalOpen] = useState(false);

  const initInputObj = {
    id: '',
    company: '',
    signUpDate: '',
    lastLoginDate: '',
    status: '',
    init: false,
  };
  const dropdownItems = [{ name: '전체' }, { name: '정상' }, { name: '정지' }];
  const dropdownItemsStore = [
    { name: '전체' },
    { name: '등록' },
    { name: '미등록' },
    { name: '오류' },
  ];
  const dropdownItemsReleasing = [
    { name: '전체' },
    { name: '출시' },
    { name: '미출시' },
    { name: '미사용' },
  ];
  const dropdownItemsPayPlan = [
    { name: '전체' },
    { name: '개발 모드', value: 'develop' },
    { name: '베이직', value: 'basic' },
    { name: '엔터프라이즈', value: 'enterprise' },
  ];

  const dropdownItemsChatUsing = [
    { name: '전체' },
    { name: '활성', value: 'active' },
    { name: '비활성', value: 'inactive' },
  ];
  // game.chatStatus = isExistChat > 0 ? 'active' : 'inactive';
  const [inputObj, handleBtnDropdownInput] = useState(initInputObj);

  const onHandleCloseMsgModal = () => {
    handleUpdatePayPlan(false);
    handleInput({});
    onInitEnterpriseInfo();
    handleErrors({});
  };

  useEffect(() => {
    if (payPlan.plan === '개발 모드') {
      handleChange('develop');
    }
    if (payPlan.plan === '개발 모드 Plus') {
      handleChange('developPlus');
    }
    if (payPlan.plan === '베이직') {
      handleChange('basic');
    }
    if (payPlan.plan === '엔터프라이즈') {
      handleChange('enterprise');
      onGetEnterpriseInfo(payPlan.id);
    }
  }, [payPlan, onGetEnterpriseInfo, handleChange]);

  useEffect(() => {
    if (projectRatePlan) {
      const { match, chat } = projectRatePlan;
      setChatChecked(chat.business);
      setMatchChecked(match.business);
      setChatLogOption(`${chat.logRetention}`);
      setMatchLogOption(`${match.logRetention}`);
    }
  }, [
    projectRatePlan,
    setChatChecked,
    setMatchChecked,
    setChatLogOption,
    setMatchLogOption,
  ]);

  const handleToggleActive = () => {
    handleException(!exception);
  };

  const [isUnlimited, setIsUnlimited] = useState(false);

  const [enterpriseRate, setEnterpriseRate] = useState(null);
  const [changingRateLimit, setChangingRateLimit] = useState(null);
  const [changingRate, setChangingRate] = useState(null);
  const [minimumFee, setMinimumFee] = useState(null);
  const [basicFee, setBasicFee] = useState(null);
  const [dBfreeLimit, setDBfreeLimit] = useState(null);

  useEffect(() => {
    if (enterpriseInfo) {
      // ---
      setEnterpriseRate(
        enterpriseInfo && enterpriseInfo.enterpriseRate
          ? enterpriseInfo.enterpriseRate
          : 0
      );
      setChangingRateLimit(
        enterpriseInfo && enterpriseInfo.changingRateLimit
          ? enterpriseInfo.changingRateLimit
          : 0
      );
      setChangingRate(
        enterpriseInfo && enterpriseInfo.changingRate
          ? enterpriseInfo.changingRate
          : 0
      );
      setMinimumFee(
        enterpriseInfo && enterpriseInfo.minimumFee
          ? enterpriseInfo.minimumFee
          : 0
      );
      setBasicFee(
        enterpriseInfo && enterpriseInfo.basicFee ? enterpriseInfo.basicFee : 0
      );
      setDBfreeLimit(
        enterpriseInfo &&
          enterpriseInfo.DBfreeLimit &&
          enterpriseInfo.DBfreeLimit < 0 // DBfreeLimit 값이 -1이면 DB 무제한 ()
          ? ''
          : enterpriseInfo.DBfreeLimit
      );
      // ---
      handleException(enterpriseInfo && enterpriseInfo.exception);
      setIsUnlimited(
        enterpriseInfo.DBfreeLimit && enterpriseInfo.DBfreeLimit < 0
      );
    }
  }, [enterpriseInfo, inputs, handleInput, handleException, setIsUnlimited]);

  // OLD hook and func

  const handleDBFreeUnlimit = () => {
    setIsUnlimited(!isUnlimited);
  };

  const handleRadioBtnChange = (e) => {
    handleError('');
    handleChange(e.target.id);
  };

  const handleOnCheck = (e) => {
    if (e.currentTarget.id === 'chat') {
      setChatChecked(!chatChecked);
    } else {
      setMatchChecked(!matchChecked);
    }
  };

  const chatLogsOptions = Array(31)
    .fill(true)
    .map((item, i) => ({ name: `${i}` }));
  const matchLogsOptions = Array(31)
    .fill(true)
    .map((item, i) => ({ name: `${i}` }));

  const handleButtonDropdown = ({ chat, match }) => {
    if (chat) {
      setChatLogOption(chat);
    }
    if (match) {
      setMatchLogOption(match);
    }
  };

  const newPayPlanTable = (
    <>
      {/* 라벨 */}
      <div className="form-group m-form__group row">
        <label htmlFor="email-contents" className="col-lg-2 col-form-label">
          프로젝트
        </label>
        <div className="col-lg-3 form-view">{payPlan.title}</div>
      </div>
      {/* 라디오 */}
      <div className="form-group m-form__group row">
        <label htmlFor="email-contents" className="col-lg-2 col-form-label">
          요금제
        </label>
        <div className="col-lg-9 form-view radio-inline-wrapper">
          <div className="m-radio-inline">
            <label className="m-radio">
              <input
                id="develop"
                onChange={(e) => handleRadioBtnChange(e)}
                type="radio"
                name="status-radio"
                checked={radioBtn === 'develop'}
              />
              개발 모드
              <span />
            </label>
            <label className="m-radio">
              <input
                id="developPlus"
                onChange={(e) => handleRadioBtnChange(e)}
                type="radio"
                name="status-radio"
                checked={radioBtn === 'developPlus'}
              />
              개발 모드 Plus
              <span />
            </label>
            <label className="m-radio">
              <input
                id="basic"
                onChange={(e) => handleRadioBtnChange(e)}
                type="radio"
                name="status-radio"
                checked={radioBtn === 'basic'}
              />
              베이직
              <span />
            </label>
            <label className="m-radio">
              <input
                id="enterprise"
                onChange={(e) => handleRadioBtnChange(e)}
                type="radio"
                name="status-radio"
                checked={radioBtn === 'enterprise'}
              />
              엔터프라이즈
              <span />
            </label>
          </div>
        </div>
      </div>

      <>
        {radioBtn === 'enterprise' ? (
          <>
            <div className="form-group m-form__group row">
              <label
                htmlFor="enterpriseRate"
                className="col-lg-2 col-form-label"
              >
                * 적용요율
              </label>
              <div className="col-lg-9">
                <input
                  onChange={
                    (e) => setEnterpriseRate(e.target.value)
                    // handleInput({ ...inputs, [e.target.id]: e.target.value })
                  }
                  type="type"
                  className="form-control update-pay-plan-input"
                  id="enterpriseRate"
                  value={enterpriseRate}
                  required
                />
                <div
                  htmlFor="changingRate"
                  className="col-lg-2 col-form-label update-pay-plan-label"
                >
                  %
                </div>
                <div className="error message visible">
                  {errors && errors.enterpriseRate}
                </div>
              </div>
            </div>
            <div className="form-group m-form__group row">
              <label htmlFor="changingRate" className="col-lg-2 col-form-label">
                변동요율
              </label>
              <div className="col-update-pay-plan update-pay-plan-changingRate">
                <input
                  onChange={(e) => setChangingRateLimit(e.target.value)}
                  type="type"
                  className="form-control update-pay-plan-input"
                  id="changingRateLimit"
                  value={changingRateLimit}
                />
                <div className="error message visible">
                  {errors && errors.changingRateLimit}
                </div>
              </div>
              <div
                // htmlFor="changingRateLimit"
                className="update-pay-plan-changingRate changingRateLimit"
              >
                원 초과시
              </div>
              <div className="col-lg-4 update-pay-plan-changingRate">
                <input
                  onChange={(e) => setChangingRate(e.target.value)}
                  type="type"
                  className="form-control update-pay-plan-input"
                  id="changingRate"
                  value={changingRate}
                />
                <div className="col-lg-2 col-form-label update-pay-plan-label">
                  %
                </div>
                <div className="error message visible">
                  {errors && errors.changingRate}
                </div>
              </div>
            </div>
            <div className="form-group m-form__group row">
              <label htmlFor="minimumFee" className="col-lg-2 col-form-label">
                최저요금
              </label>
              <div className="col-lg-9">
                <input
                  onChange={(e) => setMinimumFee(e.target.value)}
                  type="type"
                  className="form-control update-pay-plan-input"
                  id="minimumFee"
                  required
                  value={minimumFee}
                />
                <div className="col-lg-2 col-form-label update-pay-plan-label">
                  원
                </div>
                <div className="error message visible">
                  {errors && errors.minimumFee}
                </div>
              </div>
            </div>
            <div className="form-group m-form__group row">
              <label htmlFor="basicFee" className="col-lg-2 col-form-label">
                기본 요금
              </label>
              <div className="col-lg-9">
                <input
                  onChange={(e) => setBasicFee(e.target.value)}
                  type="number"
                  className="form-control update-pay-plan-input"
                  id="basicFee"
                  required
                  value={basicFee}
                />
                <div className="col-lg-2 col-form-label update-pay-plan-label">
                  원
                </div>
                <div className="error message visible">
                  {errors && errors.basicFee}
                </div>
              </div>
            </div>
            <div className="form-group m-form__group row">
              <label htmlFor="dBfreeLimit" className="col-lg-2 col-form-label">
                * DB무료요금
              </label>
              <div className="col-lg-9">
                <input
                  disabled={isUnlimited}
                  onChange={(e) => setDBfreeLimit(e.target.value)}
                  type="type"
                  className="form-control update-pay-plan-input"
                  id="dBfreeLimit"
                  required
                  value={dBfreeLimit}
                />
                <div className="col-lg-2 col-form-label update-pay-plan-label">
                  원
                </div>
                <div className="isImportant">
                  <input
                    checked={isUnlimited}
                    type="checkbox"
                    name="isImportant"
                    onChange={handleDBFreeUnlimit}
                  />
                  무제한
                  <span />
                </div>
                <div className="error message visible">
                  {errors && errors.dBfreeLimit}
                </div>
              </div>
            </div>
            <div className="form-group m-form__group row">
              <label htmlFor="minimumFee" className="col-lg-2 col-form-label">
                스토어 정보 등록 예외
              </label>
              <div className="col-lg-9 store-exception">
                <Toggle
                  id="exception"
                  onClick={handleToggleActive}
                  on=" "
                  off=" "
                  width="null"
                  height="null"
                  size="xs"
                  offstyle="danger"
                  active={exception}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {/* 체크박스 */}
            <div className="form-group m-form__group row">
              <label
                htmlFor="email-contents"
                className="col-lg-2 col-form-label"
              >
                뒤끝챗
              </label>
              <div className="col-lg-3 form-view checkbox-wrapper">
                <div
                  id="chat"
                  onClick={handleOnCheck}
                  className="isImportant clickable"
                >
                  <input checked={chatChecked} type="checkbox" readOnly />
                  비즈니스
                </div>
              </div>
            </div>
            {/* 셀렉트 */}
            <div className="form-group m-form__group row">
              <label
                htmlFor="email-contents"
                className="col-lg-2 col-form-label"
              >
                뒤끝챗 로그
              </label>
              <div className="col-lg-3 form-view form-view-no-padd-top">
                <ButtonDropdown
                  initSearchFilter
                  customKey="chat"
                  onChangeBtnDropdown={handleButtonDropdown}
                  initItem={chatLogOption} // 서버의 데이터 주입 시에도 사용가능
                  dropdownItems={chatLogsOptions}
                />
              </div>
            </div>
            {/* 체크박스 */}
            <div className="form-group m-form__group row">
              <label
                htmlFor="email-contents"
                className="col-lg-2 col-form-label"
              >
                뒤끝매치
              </label>
              <div className="col-lg-3 form-view checkbox-wrapper">
                <div
                  id="match"
                  onClick={handleOnCheck}
                  className="isImportant clickable"
                >
                  <input checked={matchChecked} type="checkbox" readOnly />
                  비즈니스
                </div>
              </div>
            </div>
            {/* 셀렉트 */}
            <div className="form-group m-form__group row">
              <label
                htmlFor="email-contents"
                className="col-lg-2 col-form-label"
              >
                뒤끝매치 로그
              </label>
              <div className="col-lg-3 form-view form-view-no-padd-top">
                <ButtonDropdown
                  initSearchFilter
                  customKey="match"
                  onChangeBtnDropdown={handleButtonDropdown}
                  initItem={matchLogOption} // 서버의 데이터 주입 시에도 사용가능
                  dropdownItems={matchLogsOptions}
                />
              </div>
            </div>
          </>
        )}
      </>
    </>
  );

  // NOTICE: -------- enterprise만 임시로 사용하는 함수 --------
  const updatePayPlan = () => {
    const { id } = payPlan;

    let errors = {};
    let isErr = false;

    if (!enterpriseRate) {
      isErr = true;
      errors.enterpriseRate = '필수 입력 항목입니다.';
    }
    if (enterpriseRate && (enterpriseRate < 1 || enterpriseRate > 100)) {
      console.log('ERROR enterpriseRate limit');
      isErr = true;
      errors.enterpriseRate = '1-100 까지 입력 가능합니다.';
    }
    if (changingRate && (changingRate < 1 || changingRate > 100)) {
      console.log('ERROR changingRate ');
      isErr = true;
      errors.changingRate = '1-100 까지 입력 가능합니다.';
    }
    if (
      changingRateLimit &&
      (changingRateLimit < 1 || changingRateLimit > 1000000000)
    ) {
      console.log('ERROR changingRateLimit ');
      isErr = true;
      errors.changingRateLimit = '1-1,000,000,000 까지 입력 가능합니다.';
    }
    if (minimumFee && (minimumFee < 1 || minimumFee > 1000000000)) {
      console.log('ERROR minimum ');
      isErr = true;
      errors.minimumFee = '1-1,000,000,000 까지 입력 가능합니다.';
    }

    if (basicFee && (basicFee < 0 || basicFee > 1000000000)) {
      console.log('ERROR basicFee ');
      isErr = true;
      errors.basicFee = '0-1,000,000,000 까지 입력 가능합니다.';
    }
    if (!dBfreeLimit && !isUnlimited) {
      console.log('ERROR dBfreeLimit ');
      isErr = true;
      errors.dBfreeLimit = '필수 입력 항목입니다.';
    }
    if (
      !isUnlimited &&
      ((dBfreeLimit && (dBfreeLimit < 0 || dBfreeLimit > 1000000000)) ||
        (!dBfreeLimit && dBfreeLimit !== 0))
    ) {
      console.log('ERROR dBfreeLimit ');
      isErr = true;
      errors.dBfreeLimit = '0-1,000,000,000 까지 입력 가능합니다.';
    }

    if (isErr) {
      handleErrors(errors);
      return;
    }

    const obj = {
      paymentPlan: 'E',
      enterpriseRate: enterpriseRate,
      changingRate: changingRate,
      changingRateLimit: changingRateLimit,
      minimumFee: minimumFee,
      exception,
      basicFee: basicFee,
      DBfreeLimit: isUnlimited ? -1 : dBfreeLimit,
    };

    for (const key in obj) {
      if (obj[key] === undefined || obj[key] === null) delete obj[key];
    }

    onUpdatePayPlan(id, obj);
    handleUpdatePayPlan(false);
    handleErrors({});
    // onInitCurrentProjectList();
    // onInitProjectList();
  };

  const handlePutRatePlan = () => {
    if (radioBtn === 'enterprise') {
      updatePayPlan();
    } else {
      const body = {
        plan: radioBtn,
        chatChecked,
        chatLogOption,
        matchChecked,
        matchLogOption,
      };
      dispatch(actions.putRatePlan({ gameId: payPlan.id, body }));

      handleUpdatePayPlan(false);
    }
  };

  const handleKeyDown = async (e) => {
    if (e.keyCode === 13) {
      await e.preventDefault();
      await onGetProjectList('zeroOffset'); // await onGetProjectList();
      await onInitCurrentProjectList(); // await onInitCurrentProjectList();
    }
  };

  const handleClickProject = (arr) => {
    onGetProject(arr);
  };

  // 아래 2개 hook은 prop으로 전달하여 이벤트 대상을 파람으로 전달 받아야 함
  const [_clientAppId, setClientAppId] = useState();
  const [marketName, setMarketName] = useState();
  const [discountName, setDiscountName] = useState();
  const [copyState, setCopyState] = useState(false);
  const [discountRate, setDiscountRate] = useState({});

  const handleOverlay = (param) => {
    document.querySelector('div.overlay').style.display = param;
  };

  const toggle = () => {
    setPopOver(!isPopOverOpen);
  };

  const toggle2 = () => {
    setPopOver2(!isPopOver2Open);
  };

  const toggle3 = () => {
    setPopOver3(!isPopOver3Open);
  };

  const handleClickCAID = (arr) => {
    handleOverlay('block');
    const { clientAppId } = arr.find((obj) => {
      return Object.keys(obj).find((key) => key === 'clientAppId');
    });
    setClientAppId(clientAppId.props.id);
    toggle2();
  };

  const handleClickDiscountRate = (arr) => {
    handleOverlay('block');
    const { id } = arr.find((obj) =>
      Object.keys(obj).find((key) => key === 'id')
    );
    const project = originalProjectList.find((obj) => obj.id === id);
    setDiscountName(`discount${id}`);
    setDiscountRate(project.discountRate || {});
    toggle3();
  };

  const handleClickGoogle = (arr) => {
    handleOverlay('block');
    const { id } = arr.find((obj) => {
      return Object.keys(obj).find((key) => key === 'id');
    });
    setMarketName(`google${id}`);
    onToggleAppReleaseInfo('google', arr);
    setPopOver(true);
  };

  const handleClickApple = (arr) => {
    onToggleAppReleaseInfo('apple', arr);
    handleOverlay('block');
    const { id } = arr.find((obj) => {
      return Object.keys(obj).find((key) => key === 'id');
    });
    setPopOver(true);
    setMarketName(`apple${id}`);
  };

  const _handleUpdatePayPlan = (arr) => {
    const obj = {};
    arr.forEach((item) => {
      const key = Object.keys(item)[0];
      obj[key] = item[key];
    });

    if (!deleteEndDate) {
      const { plan, id, title } = obj;
      handleUpdatePayPlan(true);
      getPayPlan({ plan, id, title });
      dispatch(actions.getRatePlan(id));
    }
  };

  const [radioBtnInPopup, setRadioBtnInPopup] = useState('');
  useEffect(() => {
    if (releaseInfo && releaseInfo.googleReleaseInfo) {
      const { googleReleased } = releaseInfo.googleReleaseInfo;
      setRadioBtnInPopup(googleReleased);
    }
    if (releaseInfo && releaseInfo.appleReleaseInfo) {
      const { appleReleased } = releaseInfo.appleReleaseInfo;
      setRadioBtnInPopup(appleReleased);
    }
  }, [releaseInfo, setRadioBtnInPopup]);

  const handleRadioBtnChangeInPopup = (e) => {
    setRadioBtnInPopup(e.target.id);
    let body = null;
    if (releaseInfo && releaseInfo.googleReleaseInfo) {
      body = {
        googleRelease: Number(e.target.id),
      };
    }
    if (releaseInfo && releaseInfo.appleReleaseInfo) {
      body = {
        appleRelease: Number(e.target.id),
      };
    }
    onUpdateGameReleasingData(body);
    toggle();
    handleOverlay('none');
  };

  const showPopOver = () => (
    <Popover
      fade={false}
      placement="left"
      isOpen={isPopOverOpen}
      target={marketName}
      toggle={toggle}
    >
      <PopoverHeader>출시정보</PopoverHeader>
      <PopoverBody>
        <p>
          {releaseInfo && releaseInfo.googleReleaseInfo
            ? moment(releaseInfo.googleReleaseInfo.googleReleasedIndate).format(
                'YYYY.MM.DD'
              )
            : moment(releaseInfo.appleReleaseInfo.appleReleasedIndate).format(
                'YYYY.MM.DD'
              )}
        </p>
        <p>
          {releaseInfo && releaseInfo.googleReleaseInfo ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={releaseInfo.googleReleaseInfo.googleReleasedUrl}
            >
              구글 플레이 바로가기
            </a>
          ) : (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={releaseInfo.appleReleaseInfo.appleReleasedUrl}
            >
              앱 스토어 바로가기
            </a>
          )}
        </p>
        <div className="m-radio-inline">
          <label className="m-radio">
            <input
              id="1"
              onChange={handleRadioBtnChangeInPopup}
              type="radio"
              name="credit-radio"
              checked={radioBtnInPopup === '1' || radioBtnInPopup === 1}
            />
            출시
            <span />
          </label>
          <label className="m-radio">
            <input
              id="-1"
              onChange={handleRadioBtnChangeInPopup}
              type="radio"
              name="credit-radio"
              checked={radioBtnInPopup === '-1' || radioBtnInPopup === -1}
            />
            미사용
            <span />
          </label>
        </div>
      </PopoverBody>
    </Popover>
  );

  const showPopOver2 = () => (
    <Popover
      fade={false}
      className="caId"
      placement="left"
      isOpen={isPopOver2Open}
      target={_clientAppId}
      toggle={toggle2}
    >
      <PopoverHeader>Client App ID</PopoverHeader>
      <PopoverBody>
        <CopyToClipboard
          text={_clientAppId && _clientAppId.slice(1)}
          onCopy={() => {
            setCopyState(true);
            setTimeout(() => {
              setCopyState(false);
              setPopOver2(false);
            }, 750);
          }}
        >
          <span>
            {_clientAppId && _clientAppId.slice(1)}
            &nbsp;&nbsp;
            {copyState ? (
              <Octicon icon={Check} />
            ) : (
              <Octicon className="clickable icon" icon={RepoClone} />
            )}
          </span>
        </CopyToClipboard>
      </PopoverBody>
    </Popover>
  );

  const showPopOver3 = () => (
    <Popover
      fade={false}
      placement="left"
      isOpen={isPopOver3Open}
      target={discountName}
      toggle={toggle3}
    >
      <PopoverHeader>할인율</PopoverHeader>
      <PopoverBody>
        {Object.keys(discountRate)
          .sort()
          .map((date) => (
            <Fragment key={date}>
              {`${date}: ${discountRate[date]}`}
              <br />
            </Fragment>
          ))}
      </PopoverBody>
    </Popover>
  );

  const handleCreateDiscount = () => dispatch(actions.toggleDiscountOpen());

  return (
    <>
      {projectData ? (
        <ProjectDetail />
      ) : (
        <div className="m-grid m-grid--hor m-grid--root m-page">
          {isPopOverOpen && showPopOver()}
          {isPopOver2Open && showPopOver2()}
          {isPopOver3Open && showPopOver3()}
          <div
            onClick={() => {
              handleOverlay('none');
              setPopOver(false);
              setPopOver2(false);
              setPopOver3(false);
            }}
            className="overlay"
            style={{
              width: '100%',
              height: '100%',
              position: 'fixed',
              top: '0',
              left: '0',
              zIndex: '100',
              opacity: '0.5',
              backgroundColor: 'rgb(0,0,0)',
              display: 'none',
            }}
          ></div>
          <Header />
          <MsgModal
            isMsgModalOpen={shouldUpdatePlanModalOpen}
            onHandleCloseMsgModal={onHandleCloseMsgModal}
            onConfirmAction={() => {
              setMsgModalOpen(true);
            }}
            confirmOrCancel
            contentsObject
            header="요금제 관리"
            content={newPayPlanTable}
            size="lg"
          ></MsgModal>
          <MsgModal
            isMsgModalOpen={msgModalOpen}
            onHandleCloseMsgModal={() => setMsgModalOpen(false)}
            onConfirmAction={() => {
              handlePutRatePlan();
              setMsgModalOpen(false);
            }}
            confirmOrCancel
            header="요금제 정보를 변경 하시겠습니까?"
          />
          <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
            <button
              className="m-aside-left-close m-aside-left-close--skin-dark"
              id="m_aside_left_close_btn"
            >
              <i className="la la-close" />
            </button>
            <LeftAsideMunu />
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
              <div className="m-subheader">
                <div className="d-flex align-items-center">
                  <div className="mr-auto">
                    <h3 className="m-subheader__title ">프로젝트 관리</h3>
                  </div>
                </div>
              </div>
              <div className="m-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="m-portlet">
                      <div className="m-portlet__body">
                        {!deletedProjects ? (
                          <form className="m-form m-form--fit m--margin-bottom-20">
                            <div className="row m--margin-bottom-20">
                              <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile flex-20">
                                <label>프로젝트</label>
                                <input
                                  onKeyDown={handleKeyDown}
                                  onChange={(e) =>
                                    onGetSearchKeywords({
                                      title: e.target.value,
                                    })
                                  }
                                  type="text"
                                  className="form-control m-input"
                                  placeholder=""
                                  data-col-index="0"
                                />
                              </div>
                              <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile flex-20">
                                <label>아이디</label>
                                <input
                                  onKeyDown={handleKeyDown}
                                  onChange={(e) =>
                                    onGetSearchKeywords({ id: e.target.value })
                                  }
                                  type="text"
                                  className="form-control m-input"
                                  placeholder=""
                                  data-col-index="1"
                                />
                              </div>
                              <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile flex-20">
                                <label>회사명</label>
                                <input
                                  onKeyDown={handleKeyDown}
                                  onChange={(e) =>
                                    onGetSearchKeywords({
                                      companyName: e.target.value,
                                    })
                                  }
                                  type="text"
                                  className="form-control m-input"
                                  placeholder=""
                                  data-col-index="2"
                                />
                              </div>
                              <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile flex-20">
                                <label>클라이언트 앱 아이디</label>
                                <input
                                  onKeyDown={handleKeyDown}
                                  onChange={(e) =>
                                    onGetSearchKeywords({
                                      clientAppId: e.target.value,
                                    })
                                  }
                                  type="text"
                                  className="form-control m-input"
                                  placeholder=""
                                  data-col-index="2"
                                />
                              </div>
                              <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile flex-20">
                                <label>할인율</label>
                                <input
                                  onKeyDown={handleKeyDown}
                                  onChange={(e) =>
                                    onGetSearchKeywords({
                                      discountRate: e.target.value,
                                    })
                                  }
                                  type="text"
                                  className="form-control m-input"
                                  placeholder=""
                                  data-col-index="2"
                                />
                              </div>
                            </div>
                            <div className="row m--margin-bottom-20">
                              <div className="col-lg-2 m--margin-bottom-10-tablet-and-mobile flex-20">
                                <label>생성일</label>
                                <div
                                  className="input-group pull-right"
                                  id="m_daterangepicker1"
                                >
                                  <DateRangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    focusedInput={focusedInput}
                                    setFocusedInput={setFocusedInput}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    initInputObj={initInputObj}
                                    initSearchFilter={inputObj.init}
                                    searchParam="signUpDate"
                                    onGetSearchItem={onGetSearchKeywords}
                                    setFocus={setFocus}
                                    focusByIcon={
                                      focusByIcon &&
                                      focusByIcon.singleDatePicker1
                                    }
                                    // className="form-control m-input"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-2 m--margin-bottom-10-tablet-and-mobile flex-20">
                                <label>요금제</label>
                                <ButtonDropdown
                                  payPlan
                                  initSearchFilter={inputObj.init}
                                  initInputObj={initInputObj}
                                  handleInput={handleBtnDropdownInput}
                                  onChangeBtnDropdown={onGetSearchKeywords}
                                  initItem="전체"
                                  dropdownItems={dropdownItemsPayPlan}
                                />
                              </div>
                              <div className="col-lg-2 m--margin-bottom-10-tablet-and-mobile flex-20">
                                <label>스토어 정보</label>
                                <ButtonDropdown
                                  additionalDropDown
                                  initSearchFilter={inputObj.init}
                                  initInputObj={initInputObj}
                                  handleInput={handleBtnDropdownInput}
                                  onChangeBtnDropdown={onGetSearchKeywords}
                                  initItem="전체"
                                  dropdownItems={dropdownItemsStore}
                                />
                              </div>
                              <div className="col-lg-2 m--margin-bottom-10-tablet-and-mobile flex-20">
                                <label>출시 정보</label>
                                <ButtonDropdown
                                  customKey="release"
                                  initSearchFilter={inputObj.init}
                                  initInputObj={initInputObj}
                                  handleInput={handleBtnDropdownInput}
                                  onChangeBtnDropdown={onGetSearchKeywords}
                                  initItem="전체"
                                  dropdownItems={dropdownItemsReleasing}
                                />
                              </div>
                              <div className="col-lg-2 m--margin-bottom-10-tablet-and-mobile flex-20">
                                <label>상태</label>
                                <ButtonDropdown
                                  customKey="status"
                                  initSearchFilter={inputObj.init}
                                  initInputObj={initInputObj}
                                  handleInput={handleBtnDropdownInput}
                                  onChangeBtnDropdown={onGetSearchKeywords}
                                  initItem="전체"
                                  dropdownItems={
                                    selectOpts
                                      ? selectOpts.status.options
                                      : dropdownItems
                                  }
                                />
                              </div>
                              <div className="col-lg-2 m--margin-bottom-10-tablet-and-mobile flex-20">
                                <label>채팅</label>
                                <ButtonDropdown
                                  customKey="chatStatus"
                                  initSearchFilter={inputObj.init}
                                  initInputObj={initInputObj}
                                  handleInput={handleBtnDropdownInput}
                                  onChangeBtnDropdown={onGetSearchKeywords}
                                  initItem="전체"
                                  dropdownItems={dropdownItemsChatUsing}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12">
                                <button
                                  onClick={async (e) => {
                                    await e.preventDefault();
                                    await onGetProjectList('zeroOffset');
                                    await onInitCurrentProjectList();
                                  }}
                                  className="btn btn-brand m-btn m-btn--icon"
                                  id="m_search"
                                >
                                  <span>
                                    <i className="la la-search" />
                                    <span>검색</span>
                                    {/* 검색 버튼 클릭 시에는 항상 offset = 0 */}
                                  </span>
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  className="btn btn-secondary m-btn m-btn--icon"
                                  id="m_reset"
                                >
                                  <span>
                                    <i className="la la-close" />
                                    <span>초기화</span>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </form>
                        ) : (
                          // 삭제 프로젝트 검색
                          <form className="m-form m-form--fit m--margin-bottom-20">
                            <div className="row m--margin-bottom-20">
                              <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                                <label>프로젝트</label>
                                <input
                                  onKeyDown={handleKeyDown}
                                  onChange={(e) =>
                                    onGetSearchKeywords({
                                      title: e.target.value,
                                    })
                                  }
                                  type="text"
                                  className="form-control m-input"
                                  placeholder=""
                                  data-col-index="0"
                                />
                              </div>
                              <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                                <label>아이디</label>
                                <input
                                  onKeyDown={handleKeyDown}
                                  onChange={(e) =>
                                    onGetSearchKeywords({ id: e.target.value })
                                  }
                                  type="text"
                                  className="form-control m-input"
                                  placeholder=""
                                  data-col-index="1"
                                />
                              </div>
                              <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                                <label>회사명</label>
                                <input
                                  onKeyDown={handleKeyDown}
                                  onChange={(e) =>
                                    onGetSearchKeywords({
                                      companyName: e.target.value,
                                    })
                                  }
                                  type="text"
                                  className="form-control m-input"
                                  placeholder=""
                                  data-col-index="2"
                                />
                              </div>
                              <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                                <label>요금제</label>
                                <ButtonDropdown
                                  payPlan
                                  initSearchFilter={inputObj.init}
                                  initInputObj={initInputObj}
                                  handleInput={handleBtnDropdownInput}
                                  onChangeBtnDropdown={onGetSearchKeywords}
                                  initItem="전체"
                                  dropdownItems={dropdownItemsPayPlan}
                                />
                              </div>
                            </div>
                            <div className="row m--margin-bottom-20">
                              <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                                <label>생성일</label>
                                <div
                                  className="input-group pull-right"
                                  id="m_daterangepicker1"
                                >
                                  <DateRangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    focusedInput={focusedInput}
                                    setFocusedInput={setFocusedInput}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    initInputObj={initInputObj}
                                    initSearchFilter={inputObj.init}
                                    searchParam="signUpDate"
                                    onGetSearchItem={onGetSearchKeywords}
                                    setFocus={setFocus}
                                    focusByIcon={
                                      focusByIcon &&
                                      focusByIcon.singleDatePicker1
                                    }
                                    // className="form-control m-input"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                                <label>삭제일</label>
                                <div
                                  className="input-group pull-right"
                                  id="m_daterangepicker1"
                                >
                                  <DateRangePicker
                                    deletedProjects
                                    startDate={deleteStartDate}
                                    endDate={deleteEndDate}
                                    focusedInput={deleteFocusedInput}
                                    setFocusedInput={setDeleteFocusedInput}
                                    setStartDate={setDeleteStartDate}
                                    setEndDate={setDeleteEndDate}
                                    onGetSearchItem={onGetSearchKeywords}
                                    focusByIcon={
                                      focusByIcon &&
                                      focusByIcon.singleDatePicker1
                                    }
                                    // className="form-control m-input"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile"></div>
                              <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile"></div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12">
                                <button
                                  onClick={async (e) => {
                                    await e.preventDefault();
                                    await onGetProjectList('zeroOffset');
                                    await onInitCurrentProjectList();
                                  }}
                                  className="btn btn-brand m-btn m-btn--icon"
                                  id="m_search"
                                >
                                  <span>
                                    <i className="la la-search" />
                                    <span>검색</span>
                                    {/* 검색 버튼 클릭 시에는 항상 offset = 0 */}
                                  </span>
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  className="btn btn-secondary m-btn m-btn--icon"
                                  id="m_reset"
                                >
                                  <span>
                                    <i className="la la-close" />
                                    <span>초기화</span>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </form>
                        )}
                        <div className="row row-btn-group">
                          {deletedProjects ? (
                            <>
                              <label className="float-left number-of-count">
                                삭제 프로젝트 수 : {count}{' '}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </label>
                            </>
                          ) : (
                            <>
                              <label className="float-left number-of-count">
                                총 프로젝트 수 : {count}{' '}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|
                              </label>
                              <label className="float-left number-of-count">
                                일반 프로젝트 : {generalProjectCount}{' '}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|
                              </label>
                              <label className="float-left number-of-count">
                                테스트 프로젝트 : {ineternalProjectCount}
                              </label>
                            </>
                          )}
                          <ButtonsGroup
                            csvData={projectList}
                            csvFileName={`project-list ${moment().format(
                              'YYYY-MM-DD HH:mm'
                            )}`}
                            customClassName="btn-group-in-row"
                            handleCreateDiscount={
                              !deletedProjects ? handleCreateDiscount : null
                            }
                          />
                        </div>
                        <DataTable
                          setClientAppId={setClientAppId}
                          setMarketName={setMarketName}
                          indexMethodArr={[
                            { index: 1, method: handleClickProject },
                            { index: 3, method: handleClickCAID },
                            { index: 7, method: _handleUpdatePayPlan },
                            { index: 8, method: handleClickDiscountRate },
                            { index: 10, method: handleClickGoogle },
                            { index: 12, method: handleClickApple },
                            { index: 16, method: onClickProjectRow },
                          ]}
                          isLast={isLast}
                          sortingColumns={
                            !deletedProjects
                              ? ['아이디', '생성일시', '평균DAU', '평균MAU']
                              : ['아이디', '생성일시', '삭제일시']
                          }
                          requestAction={onGetProjectList}
                          onSortAsc={onSortAscProjectList}
                          onSortDesc={onSortDescProjectList}
                          sortingParam={sortingParam}
                          canTdPopup={!deletedProjects && releasedRowInfo}
                          questionMark={!deletedProjects}
                          releaseInfo={!deletedProjects && releaseInfo}
                          projectRelease={!deletedProjects}
                          modifyRow={!deletedProjects}
                          rows={projectList}
                          actualData={originalProjectList}
                          columns={columns}
                          customClassName={
                            !deletedProjects
                              ? projectList.length > 13
                                ? 'projectList-table table-scrollable'
                                : 'projectList-table'
                              : projectList.length > 13
                              ? 'column-6 table-scrollable'
                              : 'column-6'
                          }
                          clickableIndex={
                            !deletedProjects ? [1, 3, 7, 8, 10, 12, 16] : []
                          }
                          nonClickableIndicator={['미출시']}
                          onUpdateGameReleasingData={onUpdateGameReleasingData}
                          onCheck={!deletedProjects ? setCheckRow : null}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {projectDetail ? (
              <ProjectStatusManaging projectDetail={projectDetail} />
            ) : null}
          </div>

          <DiscountModal rows={originalProjectList} checkRow={checkRow} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  columns: selectors.columnsSelector,
  detail: selectors.clickedIdSelector,
  projectList: selectors.projectListSelectorForView,
  originalProjectList: selectors.projectListSelector,
  releaseInfo: selectors.releaseInfoSelector,
  releasedRowInfo: selectors.releasedRowIdSelector,
  sortingParam: selectors.sortingParamSelector,
  initIndicator: selectors.initIndicatorSelector,
  count: selectors.countSelector,
  isLast: selectors.isLastSelector,
  generalProjectCount: selectors.generalProjectCountSelector,
  ineternalProjectCount: selectors.ineternalProjectCountSelector,
  enterpriseInfo: selectors.enterpriseInfoSelector,
  pjtListIsLoaded: selectors.pjtListIsLoadedSelector,
  searchKeywords: selectors.searchKeywordsSelector,
  projectData: selectors.projectDataSelector,
  deletedProjects: selectors.deletedProjectsSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onClickProjectRow: (id, arr) => dispatch(actions.clickProjectRow(id, arr)),
  onToggleAppReleaseInfo: (id, arr) =>
    dispatch(actions.toggleAppReleaseInfo(id, arr)),
  onSortAscProjectList: (rows, isSearched) =>
    dispatch(actions.sortAscProjectList(rows, isSearched)),
  onSortDescProjectList: (rows, isSearched) =>
    dispatch(actions.sortDescProjectList(rows, isSearched)),
  onGetSearchKeywords: (obj) => dispatch(actions.getSearchKeywords(obj)),
  onGetProjectList: (option) => dispatch(actions.getProjectList(option)),
  onInitProjectManaging: () => dispatch(actions.initProjectManaging()),
  onInitProjectList: () => dispatch(actions.initProjectList()),
  onInitCurrentProjectList: () => dispatch(actions.initCurrentProjectList()),
  onUpdatePayPlan: (id, obj) => dispatch(actions.handleUpdatePayPlan(id, obj)),
  onGetEnterpriseInfo: (id) => dispatch(actions.getEnterpriseInfo(id)),
  onInitEnterpriseInfo: () => dispatch(actions.initEnterpriseInfo()),
  onGetProject: (arr) => dispatch(actions.getProject(arr)),
  onUpdateGameReleasingData: (arr) =>
    dispatch(actions.updateGameReleasingData(arr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsManaging);
