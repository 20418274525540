// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import AccountManaging from '../containers/account/general';
import Sleeping from '../containers/account/sleeping';
import DeleteAccount from '../containers/account/deleteAccount';
import ProjectsManaging from '../containers/projects';
import ProjectDetail from '../containers/projects/ProjectDetail';
import Coupon from '../containers/coupon';
import CurrentCharge from '../containers/payment/CurrentCharge';
import CurrentChargePayPerUse from '../containers/payment/CurrentChargePayPerUse';
import ChargedBill from '../containers/payment/ChargedBill';
import OverdueBill from '../containers/payment/OverdueBill';
import Notice from '../containers/notice';
import ConsolePopup from '../containers/popup';
import Emails from '../containers/emails';
import SendEmail from '../containers/sendEmail';
import EmailList from '../containers/emailList';
import Customers from '../containers/stat/customers';
import Projects from '../containers/stat/projects';
import Sales from '../containers/stat/sales';
import TheBackendSales from '../containers/stat/theBackendSales';
import Retention from '../containers/stat/retention';
import Login from '../containers/login';
import Showcase from '../containers/landing/showcase';
import Popup from '../containers/landing/popup';
import Terms from '../containers/landing/terms';
import Privacy from '../containers/landing/privacy';
import Inquiry from '../containers/landing/inquiry';
import InquiryGDC from '../containers/landing/inquiryGDC';
import GetStarted from '../containers/developer/getStarted';
import ConsoleGuide from '../containers/developer/consoleGuide';
import SdkDocs from '../containers/developer/sdkDocs';
import VersionManaging from '../containers/developer/versionManaging';
import Kibana from '../containers/kibana/Kibana';
import Dashboard from '../containers/dashboard';
import Sla from '../containers/landing/sla';
import DataExtraction from './../containers/dataExtraction/DataExtraction';
import Indicators from './../containers/indicators';

export default {
  Login,
  AccountManaging,
  Sleeping,
  ProjectsManaging,
  ProjectDetail,
  CurrentCharge,
  CurrentChargePayPerUse,
  ChargedBill,
  OverdueBill,
  Coupon,
  Notice,
  ConsolePopup,
  Emails,
  SendEmail,
  EmailList,
  Customers,
  Projects,
  DeleteAccount,
  Sales,
  TheBackendSales,
  Showcase,
  Popup,
  Terms,
  Privacy,
  GetStarted,
  ConsoleGuide,
  SdkDocs,
  VersionManaging,
  Inquiry,
  InquiryGDC,
  Retention,
  Kibana,
  Dashboard,
  DataExtraction,
  Sla,
  Indicators,
};
