import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { browserHistory } from 'react-router';

import * as actions from './store/action';
import * as selectors from './store/selector';

import monoLogo from '../../images/logos/logo_mono.png';

const Login = ({
  onHandleLogin,
  errMsg,
  onHandleLoginError,
  isLogined,
  // onCheckToSaveId
}) => {
  const [id, changeId] = useState(null);
  const [pw, changePw] = useState(null);
  const [isChecked, checkToSaveIdUseState] = useState(false);

  useEffect(
    (param1, param2) => {
      if (isLogined) browserHistory.push('/dashboard');
    },
    [isLogined]
  );

  const checkToSaveId = () => {
    checkToSaveIdUseState(!isChecked);
  };

  const handleLogin = () => {
    onHandleLogin(id, pw, isChecked);
  };
  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <div
        className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2"
        id="m_login"
        // style="background-image: url(/images/bg_login.jpg);"
      >
        <div className="m-grid__item m-grid__item--fluid	m-login__wrapper">
          <div className="m-login__container">
            <div className="m-login__logo">
              <a href="#">
                <img src={monoLogo} />
              </a>
            </div>
            <div className="m-login__signin">
              <div className="m-login__head">
                <h3 className="m-login__title">뒤끝 관리자 - 로그인</h3>
              </div>
              <form className="m-login__form m-form" action="">
                <div className="form-group m-form__group">
                  <input
                    autoFocus
                    onChange={(e) => changeId(e.target.value)}
                    className="form-control m-input"
                    type="text"
                    placeholder="아이디"
                    name="id"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group m-form__group">
                  <input
                    onChange={(e) => changePw(e.target.value)}
                    className="form-control m-input m-login__form-input--last"
                    type="password"
                    placeholder="비밀번호"
                    name="password"
                  />
                </div>
                <div className="row m-login__form-sub">
                  <div className="col m--align-left m-login__form-left">
                    <label className="m-checkbox  m-checkbox--focus">
                      <input
                        onChange={checkToSaveId}
                        type="checkbox"
                        name="remember"
                      />
                      아이디 기억하기
                      <span></span>
                    </label>
                  </div>
                </div>
                <div className="m-login__form-action">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      onHandleLoginError({ errMsg: '' });
                      handleLogin(id, pw);
                    }}
                    id="m_login_signin_submit"
                    className="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                  >
                    로그인
                  </button>
                </div>
                <div className="row-login-err">
                  {errMsg && (
                    <div className="error message visible">{errMsg}</div>
                  )}
                </div>
              </form>
            </div>
            <div className="m-login__account">
              <span className="m-login__account-msg">
                &copy; AFI, Inc. All rights reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default Login;
const mapStateToProps = createStructuredSelector({
  errMsg: selectors.errMsgSelector,
  isLogined: selectors.isLoginedSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onHandleLogin: (id, pw, type) =>
    dispatch(actions.handleLogin({ id, pw }, type)),
  onHandleLoginError: (errMsg) => dispatch(actions.handleLoginError(errMsg)),
  //   onCheckToSaveId: () => dispatch(actions.checkToSaveId())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
