// Copyright 2013-2022 AFI, Inc. All Rights Reserved.
import { requestPost, requestGet, requestPut } from '../../utils/request';
import Qs from 'qs';

import BaseUrl from '../../shared/baseUrl';

export const indicatorsGetter = (params) => {
	const url = `${BaseUrl.CONSOLE_API}ratePlan/indicatorsGetter/${params}`;
	return requestGet(url);
};

export const indicatorsSetter = (params) => {
	const url = `${BaseUrl.CONSOLE_API}ratePlan/indicatorsSetter`;
	return requestPost(url, params);
};

export const indicatorsPutter = (params) => {
	const url = `${BaseUrl.CONSOLE_API}ratePlan/indicatorsSetter`;
	return requestPut(url, params);
};
