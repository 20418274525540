import React, { useState } from 'react';

import { Button, TextArea } from 'semantic-ui-react';
import Header from '../../view/header';
import LeftAsideMunu from '../../view/leftAsideMenu';
import lz from 'lzutf8';
import { isArray } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { saveDecompressed } from './store/actions';
import { decodedStrSelector } from './store/selector';
import { useEffect } from 'react';

const Kibana = () => {
  const dispatch = useDispatch();
  const [input, setInput] = useState('');

  const decodedStr = useSelector(decodedStrSelector);

  const [output, setOutput] = useState('');

  useEffect(() => {
    console.log('check decodedStr', decodedStr);
    setOutput(decodedStr.join(`,\n`));
  }, [decodedStr]);

  const decompressAsync = (param) => {
    return lz.decompressAsync(
      param,
      {
        inputEncoding: 'StorageBinaryString',
        outputEncoding: 'String',
      },
      (res, error) => {
        console.log('check result', res);

        if (res) {
          let obj = JSON.parse(res);
          let text = JSON.stringify(obj, undefined, 2);
          dispatch(saveDecompressed(text));
        } else if (error) console.log('ERROR', error);
      }
    );
  };

  return (
    <>
      <div className="m-grid m-grid--hor m-grid--root m-page">
        <Header />
        <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
          <button
            className="m-aside-left-close m-aside-left-close--skin-dark"
            id="m_aside_left_close_btn"
          ></button>
          <LeftAsideMunu />
          <div className="textArea-wrapper">
            <TextArea
              value={input}
              onChange={(e) => {
                let split = null;
                if (e.target.value.includes('\n')) {
                  split = e.target.value.split('\n').map((s) => `${s}\n`);
                } else split = [e.target.value];

                setInput(split);
              }}
              className="textArea"
            />
          </div>
          <div className="btn-wrapper-In-textArea-wrapper">
            <Button
              onClick={() => {
                if (isArray(input)) {
                  input.map((str) => {
                    return decompressAsync(str);
                  });
                } else decompressAsync(input);
              }}
            >
              Decode
            </Button>
            <Button
              onClick={() => {
                dispatch(saveDecompressed());
                setInput('');
              }}
            >
              Clear
            </Button>
          </div>
          <div className="textArea-wrapper">
            <TextArea readOnly value={output} className="textArea" />
          </div>
        </div>
      </div>
      <script src="./decoder.js"></script>
    </>
  );
};

export default Kibana;
