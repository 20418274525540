import { LOCATION_CHANGE } from "react-router-redux";

// import * as selectors from './selector';
import * as types from "./constant";

const initialState = {
  popupList: [],
  selectedPopup: null,
  errors: null,
};

function popup(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...initialState,
      };

    case types.GET_POPUP_LIST_SUCCESS:
      return {
        ...state,
        popupList: action.rows,
        lastEvaluatedKey: action.lastEvaluatedKey,
        insertDone: false,
      };

    case types.INSERT_POPUP:
      return {
        ...state,
        insertDone: false,
      };
    case types.INSERT_POPUP_SUCCESS:
      return {
        ...state,
        insertDone: true,
      };

    case types.UPDATE_POPUP:
      return {
        ...state,
        insertDone: false,
      };
    case types.UPDATE_POPUP_SUCCESS:
      return {
        ...state,
        insertDone: true,
        selectedPopup: null,
        errors: null,
      };

    case types.HANDLE_POPUP_ERRORS:
      return {
        ...state,
        errors: action.err,
      };

    case types.GET_POPUP:
      const {
        _inDate: { inDate },
      } = action.arr[2];

      return {
        ...state,
        selectedPopup: state.popupList.filter(
          (popup) => popup.inDate === inDate
        )[0],
      };

    default:
      return state;
  }
}

export default popup;
