import { call, put, takeLatest, select } from "redux-saga/effects";
import { LOCATION_CHANGE } from "react-router-redux";
import { browserHistory } from "react-router";
// import moment from 'moment';

import * as types from "./constant";

import * as actions from "./action";
import { openSubMenu, handleClickSubMenu } from "../../../../view/store/action";
import * as projectsActions from "../../../projects/store/action";
import * as selectors from "./selector";
import * as api from "../../../../service/API";
import moment from "moment";

function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split("/");

    if (pathArray[1] === "customers") {
      yield put(openSubMenu("statistics"));
      yield put(handleClickSubMenu("totalStat"));
      yield put(actions.getMonthlyStatistics());
    }
  } catch (err) {
    console.log("saga err", err);
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* getMonthlyStatistics() {
  try {
    const {
      data: { monthlyStat, isLast }
    } = yield call(api.customers.getMonthlyStatistics);
    const _data = monthlyStat.map(row => ({ ...row, Date: moment(row.Date) }));
    yield put(actions.getMonthlyStatisticsSuccess(_data, isLast));
  } catch (error) {
    console.log(error);
    console.log(error && error.response);
  }
}

export function* watcherGetMonthlyStatistics() {
  yield takeLatest(types.GET_MONTHLY_STAT, getMonthlyStatistics);
}

function* getDailyStatistics({ date, isSearching }) {
  try {
    const {
      data: { dailyStat, isLast }
    } = yield call(api.customers.getDailyStatistics, date);
    const _data = dailyStat.map(row => ({ ...row, Date: moment(row.Date) }));

    if (isSearching)
      return yield put(
        actions.getSearchedDailyStatisticsSuccess(_data, isLast)
      );

    yield put(actions.getDailyStatisticsSuccess(_data, isLast));
  } catch (error) {
    console.log(error);
    console.log(error && error.response);
  }
}

export function* watcherGetDailyStatistics() {
  yield takeLatest(types.GET_DAILY_STAT, getDailyStatistics);
}

function* changeStatType({ data, date }) {
  // try {
  // 	// console.log(data, '종합지표 구분 data');
  // 	// console.log(date, '종합지표 구분 date ');
  // 	if (data === '일별') {
  // 		// const { data } = yield call(api.customers.getDailyStatistics);
  // 		// yield put(actions.getDailyStatisticsSuccess(data));
  // 		yield put(actions.getDailyStatistics());
  // 		return;
  // 	}
  // 	yield put(actions.getMonthlyStatistics());
  // } catch (error) {}
}

export function* watcherChangeStatType() {
  yield takeLatest(types.CHANGE_STAT_TYPE_GENERAL_STAT, changeStatType);
}

function* searchStatistics({ date }) {
  try {
    const isSearching = true;

    const statType = yield select(selectors.statTypeSelector);
    const { status } = statType;
    if (status === "월별") yield put(actions.getMonthlyStatistics());
    if (status === "일별")
      yield put(actions.getDailyStatistics(null, date, isSearching));
  } catch (error) {}
}

export function* watcherSearchStatistics() {
  yield takeLatest(types.SEARCH_STATISTICS, searchStatistics);
}

function* showSpecificProjectInfo({ pjtName }) {
  try {
    yield browserHistory.push("/projects");
    yield put(projectsActions.getSearchKeywords({ title: pjtName }));
  } catch (error) {}
}

export function* watcherShowSpecificProjectInfo() {
  yield takeLatest(types.SHOW_SPEC_PROJECT_INFO, showSpecificProjectInfo);
}

// function* getOverdueManageRow({ id, arr }) {
// 	const accountList = yield select(selectors.accountListSelector);
// 	const filtered = accountList.filter(account => account.ID === arr[0].ID)[0];
// 	yield put(actions.getOverdueManageRowSuccess(filtered));
// }

// export function* watcherGetOverdueManageRow() {
// 	yield takeLatest(types.CLICK_OVERDUE_BILL_ROW, getOverdueManageRow);
// }
