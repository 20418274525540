// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { select, call, put, takeLatest, race } from 'redux-saga/effects';
import { LOCATION_CHANGE, push } from 'react-router-redux';

// import moment from 'moment';
import { openSubMenu, handleClickSubMenu } from '../../../../view/store/action';
import * as types from './constant';

import * as actions from './action';
import * as globalActions from '../../../globalStore/action';
import * as selectors from './selector';
import * as api from '../../../../service/API';
import { delay } from 'redux-saga';

function* initCurrentChargeList({ payload }) {
  try {
    const { pathname } = payload;

    const pathArr = pathname.split('/');

    if (pathArr[1] === 'currentCharge') {
      //   yield put(actions.getCurrentBill());
      yield put(openSubMenu('payment'));
      yield put(handleClickSubMenu('currentCharge'));
    }
  } catch (error) {}
}

export function* watcherInitCurrentChargeList() {
  yield takeLatest(LOCATION_CHANGE, initCurrentChargeList);
}

function* getCurrentBill({ obj }) {
  try {
    const searchingKeywords = yield select(selectors.searchKeywordsSelector);
    const offset = yield select(selectors.offsetSelector);

    const isSearching =
      Object.keys(searchingKeywords).length > 1 || obj.isSearching;

    const { id, companyName, status } = searchingKeywords;

    const isLastMonth = !(status === '이달');

    const {
      res: { data },
      timeout,
    } = yield race({
      res: call(api.payment.getCurrentBill, {
        email: id,
        companyName,
        offset: isSearching ? undefined : offset,
        isLastMonth,
      }),
      timeout: call(delay, 1000 * 60 * 5),
    });

    if (timeout) {
      yield put(
        globalActions.globalErrorHandling({
          errMsg: '응답 시간이 초과되었습니다.',
          errCode: 'timeout',
        })
      );
      throw {};
    }

    const _data = {
      AccountList: data.AccountList.map((row) => ({
        ...row,
        id: { id: row.id, noDisplay: true },
      })),
      isLast: data.isLast,
    };
    // console.log(test)

    yield put(
      actions.getCurrentBillSuccess(_data, isSearching ? undefined : true)
    );
  } catch (error) {
    console.log('ERR: GET_CURRENT_BILL', error && error.response);
    yield put(actions.getCurrentBillSuccess({ AccountList: [] }));
  }
}

export function* watcherGetCurrentBill() {
  yield takeLatest(types.GET_CURRENT_BILL, getCurrentBill);
}

function* clickCurrentChargeRow({ id, arr }) {
  try {
    const { id } = arr[1].id;
    const {
      data: {
        accountInfo: { status },
      },
    } = yield call(api.account.getAccountInfo, id);
    yield put(actions.getAccountStatusSuccess(status));
    yield put(actions.getCurrentAccountBill(id));
  } catch (error) {
    console.log('ERR: CLICK_CURRENT_CHARGE_ROW', error && error.response);
  }
}

export function* watcherClickCurrentChargeRow() {
  yield takeLatest(types.CLICK_CURRENT_CHARGE_ROW, clickCurrentChargeRow);
}

function* getCurrentAccountBill({ id }) {
  try {
    const searchingKeywords = yield select(selectors.searchKeywordsSelector);
    const { status } = searchingKeywords;

    const isLastMonth = !(status === '이달');
    const params = { isLastMonth };
    const { data } = yield call(api.payment.getCurrentAccountBill, id, params);
    yield put(actions.getCurrentAccountBillSuccess(data));
  } catch (error) {
    console.log('ERR :GET_CURRENT_ACCOUNT_BILL', error && error.response);
  }
}

export function* watcherGetCurrentAccountBill() {
  yield takeLatest(types.GET_CURRENT_ACCOUNT_BILL, getCurrentAccountBill);
}

function* getCurrentGameBill({ id, billType, gameTitle }) {
  try {
    const searchingKeywords = yield select(selectors.searchKeywordsSelector);
    const { status } = searchingKeywords;
    const isLastMonth = !(status === '이달');
    const params = { isLastMonth };

    const { data } = yield call(api.payment.getCurrentGameBill, id, params);
    console.log(data, 'chk data share');
    // billType ( dau / share / db) 분기해서 리턴 값 처리
    let obj;
    for (let key in data) {
      console.log(key, 'data json 안 key들');
      if (billType === key) obj = data[key];
    }
    console.log(obj, 'key');

    yield put(actions.getCurrentGameBillSuccess(obj, gameTitle));
  } catch (error) {}
}

export function* watcherGetCurrentGameBill() {
  yield takeLatest(types.GET_CURRENT_GAME_BILL, getCurrentGameBill);
}

function* addExtraBill({ obj }) {
  try {
    const searchingKeywords = yield select(selectors.searchKeywordsSelector);

    const { status } = searchingKeywords;
    const isLastMonth = !(status === '이달');

    yield call(api.payment.addExtraBill, {
      ...obj,
      isLastMonth,
    });
    yield put(actions.addExtraBillSuccess());
    const accountId = yield select(selectors.accountIdSelector);

    yield put(actions.getCurrentAccountBill(accountId));
  } catch (error) {
    console.log('ADD_EXTRA_BILL_ERROR', error);
    console.log('ADD_EXTRA_BILL_ERROR', error.response);
  }
}

export function* watcherAddExtraBill() {
  yield takeLatest(types.ADD_EXTRA_BILL, addExtraBill);
}

function* updateExtraBill({ gameId, inDate, obj }) {
  try {
    yield call(api.payment.updateExtraBill, gameId, inDate, obj);
    yield put(actions.updateExtraBillSuccess());
    const accountId = yield select(selectors.accountIdSelector);

    yield put(actions.getCurrentAccountBill(accountId));
  } catch (error) {
    console.log('UPDATE_EXTRA_BILL_ERROR', error);
    console.log('UPDATE_EXTRA_BILL_ERROR', error.response);
  }
}

export function* watcherUpdateExtraBill() {
  yield takeLatest(types.UPDATE_EXTRA_BILL, updateExtraBill);
}

function* deleteExtraBill({ gameId, inDate }) {
  try {
    yield call(api.payment.deleteExtraBill, gameId, inDate);
    yield put(actions.deleteExtraBillSuccess());
    const accountId = yield select(selectors.accountIdSelector);
    yield put(actions.getCurrentAccountBill(accountId));
  } catch (error) {
    console.log('DELETE_EXTRA_BILL_ERROR', error);
    console.log('DELETE_EXTRA_BILL_ERROR', error.response);
  }
}

export function* watcherDeleteExtraBill() {
  yield takeLatest(types.DELETE_EXTRA_BILL, deleteExtraBill);
}

// function* initDeletedAccountList({ gameId }) {
// 	try {

//     } catch (error) {

//     }
// }

// export function* watcherInitDeletedAccountList() {
// 	yield takeLatest(types., initDeletedAccountList);
// }
