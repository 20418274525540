import { call, put, takeLatest, select } from "redux-saga/effects";

import * as selectors from "./selector";
import * as types from "./constant";
import * as actions from "./action";
import { LOCATION_CHANGE } from "react-router-redux";
import { openSubMenu, handleClickSubMenu } from "../../../../view/store/action";
import { openSubMenuSelector } from "../../../../view/store/selector";
import aws from "aws-sdk";

import * as api from "../../../../service/API";
import moment from "moment";
import { ap } from "ramda";

function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split("/");
    console.log(pathArray, "*** pathArray ***");
    if (pathArray[1] === "developer" && pathArray[2] === "versionManaging") {
      yield put(openSubMenu("versionManaging"));
      yield put(actions.initVersionList());
    }
  } catch (err) {
    console.log("LOCATION_CHANGE", err);
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* initVersionList({ sdkType }) {
  try {
    yield put(actions.getVersionList(sdkType));
  } catch (error) {
    console.log("INIT_VERSION_LIST : ", error);
  }
}

export function* watcherInitVersionList() {
  yield takeLatest(types.INIT_VERSION_LIST, initVersionList);
}

function* getVersionList({ sdkType }) {
  try {
    const { data } = yield call(api.getStarted.getVersionList,sdkType);
    yield put(actions.getVersionListSuccess(data));
  } catch (error) {
    console.log("GET_VERSION_LIST", error);
  }
}

export function* watcherGetVersionList() {
  yield takeLatest(types.GET_VERSION_LIST, getVersionList);
}

function* makeVersion({ obj }) {
  try {
    yield call(api.getStarted.makeVersion, obj);
    yield put(actions.makeVersionSuccess());
    yield put(actions.initVersionList());
  } catch (error) {
    console.log("MAKE_VERSION", error);
  }
}

export function* watcherMakeVersion() {
  yield takeLatest(types.MAKE_VERSION, makeVersion);
}

function* updateVersion({ inDate, obj }) {
  try {
    yield call(api.getStarted.updateVersion, inDate, obj);
    yield put(actions.updateVersionSuccess());
    yield put(actions.initVersionList());
  } catch (error) {
    console.log("UPDATE_VERSION", error);
  }
}

export function* watcherUpdateVersion() {
  yield takeLatest(types.UPDATE_VERSION, updateVersion);
}

function* deleteVersion({ version }) {
  try {
    yield call(api.getStarted.deleteVersion, version);
    yield put(actions.deleteVersionSuccess());
    yield put(actions.initVersionList());
  } catch (error) {
    console.log("DELETE_VERSION", error);
  }
}

export function* watcherDeleteVersion() {
  yield takeLatest(types.DELETE_VERSION, deleteVersion);
}
