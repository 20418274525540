// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button } from 'reactstrap';
import moment from 'moment';

import CouponDetailModal from './CouponDetailModal';
import CouponIssueModal from './CouponIssueModal';
import Header from '../../view/header';
import LeftAsideMunu from '../../view/leftAsideMenu';
import ButtonsGroup from '../../fnComponents/buttonGroup';
import DataTable from '../../fnComponents/_DataTable';
import MsgModal from '../../fnComponents/MsgModal';
import SearchForm from '../../fnComponents/SearchForm';

import * as actions from './store/action';
import * as selectors from './store/selector';

const Coupon = ({
  columns,
  couponList,
  couponListForView,
  onHandleCloseCouponDetailModal,
  onClickCouponDetail,
  onClickCouponDelete,
  isCouponDetailModalOpen,
  onGetCouponList,
  onSortAscCouponList,
  onSortDescCouponList,
  isLast,
  sortingParam,
  detail: { couponDetail },
  errorsObj,
  onDeleteCoupon,
  onGetSearchKeywords,
}) => {
  const [isCouponIssueModalOpen, handleOpenCouponIssueModal] = useState(false);
  const [toBeDeleted, setCouponNameToBeDeleted] = useState({
    couponName: '',
    isMsgModalOpen: false,
  });

  console.log(errorsObj);
  //   const [dropdownOpen, toggleSelectBox] = useState(false);
  // const [selected, handleItemChange] = useState('');
  // const handleToggle = () => toggleSelectBox(!dropdownOpen);

  const toggle = (bool) => {
    handleOpenCouponIssueModal(bool);
  };

  const handleCouponDelete = (key, arr) => {
    console.log(key, arr);
    const id = arr[1].id.id;
    setCouponNameToBeDeleted({ id, isMsgModalOpen: true }); // 삭제할 쿠폰네임 임시 저장
  };

  const onHandleDelete = () => {
    const { id } = toBeDeleted;
    onDeleteCoupon(id);
    handleCloseMsgModal();
  };

  const handleCloseMsgModal = () =>
    setCouponNameToBeDeleted({
      couponName: '',
      isMsgModalOpen: false,
    });

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title">쿠폰 관리</h3>
              </div>
            </div>
          </div>
          {/* m-content 부터 */}
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <SearchForm
                      keywords={['쿠폰명', '발행일', '작성자']}
                      mainlyRequiredAction={onGetCouponList}
                      initAction={() => {}}
                      onGetSearchKeywords={onGetSearchKeywords}
                    />
                    <div className="row">
                      <div className="col-lg-12">
                        <Button onClick={() => toggle(true)} color="secondary">
                          쿠폰발행
                        </Button>
                      </div>
                    </div>

                    <ButtonsGroup
                      columns={columns}
                      pdfData={couponListForView}
                      csvData={couponListForView}
                      csvFileName={`coupon_list_${moment().format(
                        'YYYY-MM-DD HH:mm'
                      )}`}
                    />
                    <DataTable
                      isLast={isLast}
                      sortingColumns={[
                        '크레딧',
                        '발행수',
                        '사용수',
                        '회수율',
                        '발행일',
                      ]}
                      requestAction={onGetCouponList}
                      onSortAsc={onSortAscCouponList}
                      onSortDesc={onSortDescCouponList}
                      sortingParam={sortingParam}
                      //   modifyRow
                      deleteRow
                      requiredAction={onClickCouponDetail}
                      secondRequiredAction={handleCouponDelete}
                      rows={couponListForView}
                      columns={columns}
                      actualData={couponList}
                      customClassName={
                        couponList.length > 13
                          ? 'column-8 couponList-table table-scrollable'
                          : 'column-8 couponList-table'
                      }
                      clickableIndex={[0]}
                    />
                    <MsgModal
                      onHandleDelete={onHandleDelete}
                      onHandleCloseMsgModal={handleCloseMsgModal}
                      isMsgModalOpen={toBeDeleted.isMsgModalOpen}
                      contentsObject
                      content={
                        <p>
                          해당 쿠폰을 삭제하시겠습니까?
                          <br /> 이미 사용된 쿠폰은 삭제되지 않습니다.
                        </p>
                      }
                    />
                    {couponDetail && (
                      <CouponDetailModal
                        onClickCouponDetail={onClickCouponDetail}
                        onHandleCloseCouponDetailModal={
                          onHandleCloseCouponDetailModal
                        }
                        isCouponDetailModalOpen={couponDetail}
                      />
                    )}
                    <CouponIssueModal
                      //   errorsObj={errorsObj}
                      handleOpenCouponIssueModal={handleOpenCouponIssueModal}
                      isCouponIssueModalOpen={isCouponIssueModalOpen}
                    />
                    {/* <PaginationBox /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  columns: selectors.columnsSelector,
  detail: selectors.clickedIdSelector,
  couponList: selectors.couponMetaSelector,
  couponListForView: selectors.couponMetaSelectorForView,
  isLast: selectors.isLastSelector,
  sortingParam: selectors.sortingParamSelector,
  errorsObj: selectors.errorsSelector,
  // manageOverdueBill: selectors.manageOverdueBillSelector,
  // chargedBillDetail: selectors.chargedBillDetailSelector,
});

const mapDispatchToProps = (dispatch) => ({
  // onClickAccountRow: key => dispatch(actions.clickAccountRow(key)),
  onClickCouponDetail: (key, arr, param) =>
    dispatch(actions.clickCouponDetail(key, arr, param)),
  onHandleCloseCouponDetailModal: () =>
    dispatch(actions.handleCloseCouponDetailModal()),
  onClickCouponDelete: (key, arr) =>
    dispatch(actions.clickCouponDelete(key, arr)),
  onGetCouponList: (key, arr) => dispatch(actions.getCouponList(key, arr)),
  onSortAscCouponList: (key, arr) =>
    dispatch(actions.sortAscCouponList(key, arr)),
  onSortDescCouponList: (key, arr) =>
    dispatch(actions.sortDescCouponList(key, arr)),
  onDeleteCoupon: (id) => dispatch(actions.deleteCoupon(id)),
  onGetSearchKeywords: (obj) => dispatch(actions.getSearchKeywords(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
