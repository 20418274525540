import Qs from 'qs';
import { requestGet } from '../../utils/request';

export const getProjects = (params) => {
	//   const url = "/project";
	const url = 'statistics/project';

	const options = {
		params,
		paramsSerializer(serializingParams) {
			return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
		},
	};

	return requestGet(url, options);
};

export const getDailyStatistics = (params) => {
	const url = 'statistics/total/daily';

	const options = {
		params,
		paramsSerializer(serializingParams) {
			return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
		},
	};

	return requestGet(url, options);
};

export const getMonthlyStatisticsProjects = (projectId) => {
	const url = `statistics/project/v1.2/${projectId}/monthly`;

	return requestGet(url);
};

export const getDailyStatisticsProjects = (projectId, params) => {
	const url = `statistics/project/v1.2/${projectId}/daily`;
	const options = {
		params,
		paramsSerializer(serializingParams) {
			return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
		},
	};

	return requestGet(url, options);
};
