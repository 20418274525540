import { call, put, takeLatest, select } from "redux-saga/effects";
import { delay } from "redux-saga";


import * as types from "./constant";
import * as actions from "./action";
import * as selectors from "./selector";
import { LOCATION_CHANGE } from "react-router-redux";

import aws from 'aws-sdk';
import { openSubMenu, handleClickSubMenu } from "../../../../view/store/action";

import * as api from "../../../../service/API";


function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split("/");
    if (pathArray[1] === "landing" && pathArray[2] === "inquiry") {
      yield put(openSubMenu("inquiry"));
      yield put(handleClickSubMenu("inquiry"));
      yield put(actions.getInquiriesList());
    }
  } catch (err) {
    console.log('LOCATION_CHANGE', err)
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}


function* getInquiriesList({ search, isNext }) {
  try {

    let lastEvaluatedKey = null
    if(isNext) {
    lastEvaluatedKey = yield select(selectors.lastEvaluatedKeySelector)
    }

    const { data: { Items, LastEvaluatedKey } } = yield call(api.inquiry.getInquiriesList, {
        ...search,
        LastEvaluatedKey: lastEvaluatedKey
    });

    const isSearching = search ? true : false

    yield put(actions.getInquiriesListSuccess(Items.map(obj => aws.DynamoDB.Converter.unmarshall(obj)), isSearching, isNext))
    yield put(actions.requestListSuccess(LastEvaluatedKey))
  } catch (error) {
    console.log('GET_INQUIRIES_LIST_ERROR', error)
  }
}

export function* watcherGetInquiriesList() {
  yield takeLatest(types.GET_INQUIRIES_LIST, getInquiriesList);
}


function* deleteInquiry({ inDate }) {
  try {

    yield call(api.inquiry.deleteInquiry, inDate);

    yield put(actions.deleteInquirySuccess());
    // yield put(actions.getInquiriesList());

  } catch (error) {
    console.log('DELETE_INQUIRY_SUCCESS', error)
  }
}

export function* watcherDeleteInquiry() {
  yield takeLatest(types.DELETE_INQUIRY, deleteInquiry);
}


function* sendResponse({ inDate, response }) {
  try {

    yield call(api.inquiry.sendResponse, inDate, response);

    yield put(actions.sendResponseSuccess())
    yield call(delay, 1000)
    yield put(actions.getInquiriesList(null, false))
  } catch (error) {
    console.log('SEND_RESPONSE', error)
  }
}

export function* watcherSendResponse() {
  yield takeLatest(types.SEND_RESPONSE, sendResponse);
}


