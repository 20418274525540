import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from "reactstrap";

import Octicon, { X, CircleSlash } from "@primer/octicons-react";

import moment from "moment";

const MsgModal = ({
  content,
  isOpen,
  onHandleCloseMsgModal,
  onlyCanClose,
  confirmOrCancel,
  requestParams,
  requestAction,
  requiredAction,
  additionalParam,
  additionalParamIndex,
  searchedProject,
  actualLastItem,
  deleteRow,
  modifyRow,
  projectRelease,
  secondRequiredAction,
  selectedId,
  accountDetail,
  rows,
  date,
  onShowSpecificProjectInfo
}) => {
  const [scrollDone, setScrollState] = useState(false);
  const [test, testMethod] = useState(false);

  const scrollOnDataTable = e => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop === 0) return;
    if (scrollHeight > scrollTop + clientHeight) setScrollState(false);
    if (scrollHeight === scrollTop + clientHeight) {
      setScrollState(true);
    }
  };

  const makeRows = rows => {
    if (!rows.length)
      return (
        <tr>
          <td>데이터가 없습니다.</td>
        </tr>
      );
    if (rows.length) {
      const rowsSet = rows.map(row => {
        let tempArr = [];
        for (let key in row) {
          tempArr = [...tempArr, { [key]: row[key] }];
        }

        if (deleteRow)
          tempArr.push({
            deleteRow: (
              <span>
                <Octicon icon={X} />
              </span>
            )
          });
        if (modifyRow)
          tempArr.push({
            modifyRow: (
              <span>
                <Octicon icon={CircleSlash} />
              </span>
            )
          });
        return tempArr.map((key, i, arr) => {
          const forKey = i;

          // key가 객체일 때 예외처리 -- 추후에 수정할 필요성이 발생할 수 있음 ㅜㅜㅜ
          if (!Object.values(key)[0])
            return <td key={forKey}>{Object.values(key)[0]}</td>;
          if (Object.values(key)[0] && !Object.values(key)[0].noDisplay)
            return (
              <td
                key={forKey}
                className={!i ? "clickable-td" : null}
                onClick={e => {
                  window.open(`/projects?title=${e.target.innerText}`)
                }}
              >
                {Object.values(key)[0]}
              </td>
            );
        });
      });

      return rowsSet.map((row, i) => {
        const forKey = i;
        return <tr key={forKey}>{row}</tr>;
      });
    }
  };

  return (
    <Modal size='lg' isOpen={isOpen}>
      <ModalHeader>{date} 출시 프로젝트</ModalHeader>
      <ModalBody>
        <Table
          onScroll={scrollOnDataTable}
        //   className="released-projects column-5"
          className="column-4"
          id="table_dataTable"
        >
          <thead className="column-4">
            <tr>
              <th>프로젝트</th>
              <th>회사명</th>
              <th>스토어</th>
            </tr>
          </thead>
          <tbody className="column-4" id="tableBody">
            {rows && makeRows(rows)}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onHandleCloseMsgModal}>
          확인
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MsgModal;
