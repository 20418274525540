// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

export const GET_ACCOUNTS_DETAIL = 'GET_ACCOUNTS_DETAIL';
export const GET_BACK_TO_LIST = 'GET_BACK_TO_LIST';

export const GET_ADMIN_ACCOUNTS = 'GET_ADMIN_ACCOUNTS';
export const GET_ADMIN_ACCOUNTS_SUCCESS = 'GET_ADMIN_ACCOUNTS_SUCCESS';

export const GET_ACCOUNTS_ROWS = 'GET_ACCOUNTS_ROWS';
export const GET_ACCOUNTS_ROWS_SUCCESS = 'GET_ACCOUNTS_ROWS_SUCCESS';

export const GET_ACCOUNT_INFO_SUCCESS = 'GET_ACCOUNT_INFO_SUCCESS';
export const GET_CREDIT_INFO = 'GET_CREDIT_INFO';
export const DELETE_CREDIT = 'DELETE_CREDIT';
export const GET_CREDIT_INFO_SUCCESS = 'GET_CREDIT_INFO_SUCCESS';

export const GET_ACCOUNT_STATUS = 'GET_ACCOUNT_STATUS';
export const GET_ACCOUNT_STATUS_SUCCESS = 'GET_ACCOUNT_STATUS_SUCCESS';

export const INIT_ACCOUNT_INFO = 'INIT_ACCOUNT_INFO';
export const INIT_ACCOUNT_LIST = 'INIT_ACCOUNT_LIST';
export const _INIT_ACCOUNT_LIST = '_INIT_ACCOUNT_LIST';
export const INIT_DELETED_ACCOUNT_LIST = 'INIT_DELETED_ACCOUNT_LIST';

export const GET_DELETED_ACCOUNT_LIST_SUCCESS =
  'GET_DELETED_ACCOUNT_LIST_SUCCESS';
export const GET_DELETED_ACCOUNT_LIST = 'GET_DELETED_ACCOUNT_LIST';

export const GET_COLUMNS = 'GET_COLUMNS';
export const CLICK_ACCOUNT_ROW = 'CLICK_ACCOUNT_ROW';

export const GET_MEMO_LIST = 'GET_MEMO_LIST';
export const GET_MEMO_LIST_SUCCESS = 'GET_MEMO_LIST_SUCCESS';
export const CHANGE_INPUT_MEMO = 'CHANGE_INPUT_MEMO';
export const CREATE_MEMO = 'CREATE_MEMO';
export const DELETE_ROW = 'DELETE_ROW';

export const HANDLE_OPEN_MODAL = 'HANDLE_OPEN_MODAL';
export const CONFIRM_DELETE_ACCOUNT = 'CONFIRM_DELETE_ACCOUNT';
export const SAVE_INPUT = 'SAVE_INPUT';
export const SEARCH_DELETED_ACCOUNT = 'SEARCH_DELETED_ACCOUNT';
export const GET_SEARCH_ITEM = 'GET_SEARCH_ITEM';
export const GET_SEARCH_KEYWORDS = 'GET_SEARCH_KEYWORDS';
export const GET_SEARCHED_ACCOUNTS_ROWS_SUCCESS =
  'GET_SEARCHED_ACCOUNTS_ROWS_SUCCESS';
export const GET_SEARCHED_DEL_ACCOUNTS_ROWS_SUCCESS =
  'GET_SEARCHED_DEL_ACCOUNTS_ROWS_SUCCESS';

export const SEARCH_ACCOUNTS = 'SEARCH_ACCOUNTS';
export const SEARCH_DEL_ACCOUNT = 'SEARCH_DEL_ACCOUNT';
export const INIT_SEARCH_RESULT = 'INIT_SEARCH_RESULT';
export const INIT_TO_SEARCH_ACCOUNTS = 'INIT_TO_SEARCH_ACCOUNTS';
export const INIT_CURRENT_ACCOUNT_LIST = 'INIT_CURRENT_ACCOUNT_LIST';

export const SORT_ASC_NORMAL_ACCOUNTS = 'SORT_ASC_NORMAL_ACCOUNTS';
export const SORT_DESC_NORMAL_ACCOUNTS = 'SORT_DESC_NORMAL_ACCOUNTS';

export const HANDLE_ERRORS_ACCOUNT = 'HANDLE_ERRORS_ACCOUNT';
export const INIT_ERRORS = 'INIT_ERRORS';

export const GET_LAST_EVALUATED_KEY_SUCCESS = 'GET_LAST_EVALUATED_KEY_SUCCESS';
export const UPDATE_INTERNAL_ACCOUNT = 'UPDATE_INTERNAL_ACCOUNT';
export const UPDATE_AUTO_PAY = 'UPDATE_AUTO_PAY';
export const HANDLE_POST_CREDIT = 'HANDLE_POST_CREDIT';
export const HANDLE_POST_CREDIT_SUCCESS = 'HANDLE_POST_CREDIT_SUCCESS';

export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN';
export const PUT_ACCOUNT_TYPE = 'PUT_ACCOUNT_TYPE';
export const GET_ACCOUNT_TYPE = 'GET_ACCOUNT_TYPE';
export const GET_ACCOUNT_TYPE_SUCCESS = 'GET_ACCOUNT_TYPE_SUCCESS';
