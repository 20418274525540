// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import axios from 'axios';
import Cookies from 'js-cookie';
import BaseUrl from '../shared/baseUrl';
import * as globalActions from '../containers/globalStore/action';
import store from '../configureStore';

class DefaultAxios {
  // 싱글톤으로 생성
  constructor() {
    if (!DefaultAxios.instance) {
      console.log('*** interceptors.response !DefaultAxios doesnt exist ***');
      DefaultAxios.instance = axios.create();

      DefaultAxios.instance.defaults.baseURL = BaseUrl.CONSOLE_API;
      DefaultAxios.instance.defaults.headers.common.access_token =
        Cookies.get('access_token');

      DefaultAxios.instance.interceptors.response.use(null, (err) => {
        if (err && err.response) {
          console.log('000---1err is truthy', err.response);
          store.dispatch(
            globalActions.globalErrorHandling(
              (err && err.response
                ? {
                    errMsg: err.response.data.message,
                    errCode: err.response.data.statusCode,
                  }
                : err) || '에러 발생 : UNDEFINED'
            )
          );
        }
        if (err.response && err.response.status === 401) {
          console.log('000---2err is 401');
          axios.get(`${BaseUrl.CONSOLE_API}account/`).catch(async (err) => {
            if (err.response && err.response.status === 401) {
              try {
                await Cookies.remove('access_token');
                await Cookies.remove('refreshToken');
                await Cookies.remove('authorization');
                window.location.href = await '/login';
                store.dispatch(
                  globalActions.globalErrorHandling(
                    (err && err.response
                      ? {
                          errMsg: err.response.data.message,
                          errCode: err.response.data.statusCode,
                        }
                      : err) || '에러 발생 : UNDEFINED'
                  )
                );
              } catch (error) {
                console.log('async auth failed');
              }
            }
          });
        }
        console.log('HERE', err);
        return Promise.reject(err);
      });

      DefaultAxios.instance.init = (access_token) => {
        DefaultAxios.instance.defaults.headers.common.access_token =
          access_token;
      };
    }
    console.log('*** interceptors.response DefaultAxios Exists ***');

    return DefaultAxios.instance;
  }
}

const instance = new DefaultAxios();

export default instance;
