// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import Qs from 'qs';

import {
  requestGet,
  requestPost,
  requestPut,
  requestDelete,
} from '../../utils/request';

export const getAccountList = (params) => {
  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  const url = 'account/v1.2';

  return requestGet(url, options);
};

export const getAccountInfo = (id) => {
  console.log('GET ACCOUNT INFO');
  const url = `account/v1.2/${id}`;
  return requestGet(url);
};

// memo
export const getMemoList = (id) => {
  console.log('GET MEMO');
  const url = `account/${id}/memo`;
  return requestGet(url);
};

export const createMemo = (id, memo) => {
  console.log('CREATE MEMO');
  const url = `account/${id}/memo`;
  return requestPost(url, memo);
};

export const deleteMemo = (id, inDate) => {
  console.log('CREATE MEMO');
  const url = `account/${id}/memo/${inDate}`;
  return requestDelete(url);
};

export const getAdminAccounts = (id) => {
  //   console.log("GET ADMIN ACCOUNTS");
  const url = `account/${id}/gm`;
  return requestGet(url);
};

//Credit
export const getCreditList = (id) => {
  const url = `account/${id}/credit`;
  return requestGet(url);
};

export const issueCredit = (id, data) => {
  const url = `account/${id}/credit`;
  return requestPost(url, data);
};

export const deleteCredit = (id, usagehistoryId) => {
  const url = `account/${id}/credit/${usagehistoryId}`;
  return requestDelete(url);
};

// Credit ends here

export const getAccountStatus = (id) => {
  console.log('GET ACCOUNT STATUS');
  const url = `account/${id}/status`;
  return requestGet(url);
};

export const updateStatus = (id, value) => {
  console.log('GET ACCOUNT STATUS');
  const url = `account/v1.2/${id}/status`;
  return requestPost(url, value);
};

export const getAccountCount = () => {
  const url = `account/count`;
  return requestGet(url);
};

// export const deleteAccount = id => {
// 	// console.log('DELETE ACCOUNT');
// 	const url = `account/${id}`;
// 	return requestDelete(url);
// };

// 탈퇴계정
export const getWithdrawedAccounts = (params) => {
  const url = `account/withdrawed`;

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  return requestGet(url, options);
};

// 자동결제 및 테스트 계정
export const updateInternalAccount = (id, internalAccount) => {
  const url = `account/${id}/internal/${internalAccount}`;

  return requestPost(url);
};

export const updateAutoPay = (id, isAutoPay) => {
  const url = `account/${id}/autoPay/${isAutoPay}`;

  return requestPost(url);
};

// 휴면 예정
export const getSleepingCandidates = (params) => {
  const url = `account/sleepingCandidates`;

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  return requestGet(url, options);
};
export const makeSleepingCandidateNot = (accountId, data) => {
  const url = `account/sleepingCandidates/${accountId}`;

  return requestPost(url, data);
};
// 휴면 계정
export const getSleepingAccounts = (params) => {
  const url = `account/sleeping`;

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  return requestGet(url, options);
};
export const makeSleepingAccountNot = (accountId, data) => {
  const url = `account/sleeping/${accountId}`;

  return requestPost(url, data);
};

export const handlePostCredit = (params) => {
  const url = `account/credit/multiple`;

  return requestPost(url, params);
};

export const postAccessToken = (data) => {
  const url = `account/v1.1/token`;
  return requestPost(url, data);
};

export const putAccountType = ({ accountId, accountType }) => {
  const url = `account/${accountId}/type`;
  return requestPut(url, { accountType });
};

export const getAccountType = (accountId) => {
  const url = `account/${accountId}/type`;
  return requestGet(url);
};
