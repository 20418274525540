export const CLICK_COUPON_DETAIL_ROW = "CLICK_COUPON_DETAIL_ROW";
export const CLICK_COUPON_DELETE = "CLICK_COUPON_DELETE";
export const GET_OVERDUE_MANAGE_ROW_SUCCESS = "GET_OVERDUE_MANAGE_ROW_SUCCESS";
export const HANDLE_CLOSE_COUPON_DETAIL_MODAL =
  "HANDLE_CLOSE_COUPON_DETAIL_MODAL";

export const HANDLE_CLOSE_MSG_MODAL = "HANDLE_CLOSE_MSG_MODAL";
export const CONFIRM_CHANGE_STATUS = "CONFIRM_CHANGE_STATUS";
export const GET_ACCOUNTS_ROWS = "GET_ACCOUNTS_ROWS";
export const GET_ACCOUNTS_ROWS_SUCCESS = "GET_ACCOUNTS_ROWS_SUCCESS";
export const INIT_ACCOUNT_LIST = "INIT_ACCOUNT_LIST";
export const INIT_DELETED_ACCOUNT_LIST = "INIT_DELETED_ACCOUNT_LIST";
export const GET_COLUMNS = "GET_COLUMNS";
export const CLICK_MANAGE_BILL = "CLICK_MANAGE_BILL";
export const HANDLE_OPEN_MODAL = "HANDLE_OPEN_MODAL";
export const CONFIRM_DELETE_ACCOUNT = "CONFIRM_DELETE_ACCOUNT";
export const SAVE_INPUT = "SAVE_INPUT";

// actions in use
export const INIT_COUPON_LIST = "INIT_COUPON_LIST";
export const INIT_COUPON_LIST_SUCCESS = "INIT_COUPON_LIST_SUCCESS";
export const GET_COUPON_LIST = "GET_COUPON_LIST";
export const GET_COUPON_LIST_SUCCESS = "GET_COUPON_LIST_SUCCESS";
export const ISSUE_COUPON = "ISSUE_COUPON";
export const ISSUE_COUPON_SUCCESS = "ISSUE_COUPON_SUCCESS";
export const GET_COUPON_DETAILS = "GET_COUPON_DETAILS";
export const GET_COUPON_DETAILS_SUCCESS = "GET_COUPON_DETAILS_SUCCESS";
export const DELETE_COUPON = "DELETE_COUPON";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const SORT_ASC_COUPON_LIST = "SORT_ASC_COUPON_LIST";
export const SORT_DESC_COUPON_LIST = "SORT_DESC_COUPON_LIST";
export const HANDLE_ERRORS = "HANDLE_ERRORS";
export const GET_SEARCH_KEYWORDS = "GET_SEARCH_KEYWORDS";
