import * as types from "./constant";

export function initTopics() {
  return {
    type: types.INIT_TOPICS_SDK,
  };
}
export function initSdkDocs() {
  return {
    type: types.INIT_SDK_DOCS,
  };
}
export function getTopics(param) {
  return {
    type: types.GET_TOPICS_SDK,
    param,
  };
}
export function getVersionList() {
  return {
    type: types.GET_VERSION_LIST,
  };
}
export function getVersionListSuccess(obj) {
  return {
    type: types.GET_VERSION_LIST_SUCCESS,
    obj,
  };
}
export function getTopicsSuccess(data) {
  return {
    type: types.GET_TOPICS_SDK_SUCCESS,
    data,
  };
}
export function getList(param, listIndex) {
  return {
    type: types.GET_LIST_SDK,
    param,
    listIndex,
  };
}
export function getListSuccess(data, listIndex) {
  return {
    type: types.GET_LIST_SDK_SUCCESS,
    data,
    listIndex,
  };
}
export function getClassParentList(param) {
  return {
    type: types.GET_CLASS_PARENT_LIST_SDK,
    param,
  };
}
export function getClassParentListSuccess(data) {
  return {
    type: types.GET_CLASS_PARENT_LIST_SDK_SUCCESS,
    data,
  };
}

export function getDocument(inDate) {
  return {
    type: types.GET_DOCUMENT_SDK,
    inDate,
  };
}
export function getDocumentSuccess(data) {
  return {
    type: types.GET_DOCUMENT_SDK_SUCCESS,
    data,
  };
}

export function makeTopic(obj) {
  return {
    type: types.MAKE_TOPIC_SDK,
    obj,
  };
}
export function makeTopicSuccess() {
  return {
    type: types.MAKE_TOPIC_SDK_SUCCESS,
  };
}
export function makeClass(obj) {
  return {
    type: types.MAKE_CLASS_SDK,
    obj,
  };
}
export function makeClassSuccess(obj) {
  return {
    type: types.MAKE_CLASS_SDK_SUCCESS,
    obj,
  };
}
export function makeDocument(obj) {
  return {
    type: types.MAKE_DOCUMENT_SDK,
    obj,
  };
}
export function makeDocumentSuccess(obj) {
  return {
    type: types.MAKE_DOCUMENT_SDK_SUCCESS,
    obj,
  };
}
// export function makeVersion(obj) {
//   return {
//     type: types.MAKE_VERSION_SDK,
//     obj,
//   };
// }
// export function makeVersionSuccess(obj) {
//   return {
//     type: types.MAKE_VERSION_SDK_SUCCESS,
//     obj,
//   };
// }
// UPDATE METHOD
// export function updateVersion(obj) {
//   return {
//     type: types.UPDATE_VERSION_SDK,
//     obj,
//   };
// }
// export function updateVersionSuccess(obj) {
//   return {
//     type: types.UPDATE_VERSION_SDK_SUCCESS,
//     obj,
//   };
// }
export function updateTopic(inDate, topic) {
  return {
    type: types.UPDATE_TOPIC_SDK,
    inDate,
    topic,
  };
}
export function updateTopicSuccess(obj) {
  return {
    type: types.UPDATE_TOPIC_SDK_SUCCESS,
    obj,
  };
}
export function updateClass(obj, inDate) {
  return {
    type: types.UPDATE_CLASS_SDK,
    obj,
    inDate,
  };
}
export function updateClassSuccess(obj) {
  return {
    type: types.UPDATE_CLASS_SDK_SUCCESS,
    obj,
  };
}
export function updateDocument(obj, inDate) {
  return {
    type: types.UPDATE_DOCUMENT_SDK,
    obj,
    inDate,
  };
}
export function updateDocumentSuccess(obj) {
  return {
    type: types.UPDATE_DOCUMENT_SDK_SUCCESS,
    obj,
  };
}
export function updateOrder(inDate, upDown) {
  return {
    type: types.UPDATE_ORDER_SDK,
    inDate,
    upDown,
  };
}
export function updateOrderSuccess(obj) {
  return {
    type: types.UPDATE_ORDER_SDK_SUCCESS,
    obj,
  };
}
// DELETE METHOD
export function deleteVersion(obj) {
  return {
    type: types.DELETE_VERSION,
    obj,
  };
}
export function deleteVersionSuccess(obj) {
  return {
    type: types.DELETE_VERSION_SUCCESS,
    obj,
  };
}
export function deleteTopic(obj) {
  return {
    type: types.DELETE_TOPIC_SDK,
    obj,
  };
}
export function deleteTopicSuccess(obj) {
  return {
    type: types.DELETE_TOPIC_SDK_SUCCESS,
    obj,
  };
}
export function deleteClass(inDate) {
  return {
    type: types.DELETE_CLASS_SDK,
    inDate,
  };
}
export function deleteClassSuccess(obj) {
  return {
    type: types.DELETE_CLASS_SDK_SUCCESS,
    obj,
  };
}
export function deleteDocument(inDate) {
  return {
    type: types.DELETE_DOCUMENT_SDK,
    inDate,
  };
}
export function deleteDocumentSuccess(obj) {
  return {
    type: types.DELETE_DOCUMENT_SDK_SUCCESS,
    obj,
  };
}
export function initGetListKey() {
  return {
    type: types.INIT_GET_LIST_KEY_SDK,
  };
}

// **************

// export function initGameStat(gameId, date, year) {
// 	return {
// 		type: types.INIT_GAME_STAT,
// 		gameId,
// 		date,
// 		year,
// 		MAU: false,
// 	};
// }

export function makeDocumentErr(obj) {
  return {
    type: types.MAKE_DOCUMENT_ERROR,
    obj,
  };
}
