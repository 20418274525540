// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { createSelector } from 'reselect';

// import aws from 'aws-sdk';
import moment from 'moment';

export const columnsSelector = createSelector(
  (state) => state.customers.columns,
  (columns) => columns
);

export const customersListSelectorForView = createSelector(
  // monthlyStat
  (state) => state.customers.customersList,
  (customersList) => {
    // console.log('20210531 : customersList', customersList);
    const rows = customersList.map((obj) => ({
      Date: obj.Date.format('YYYY.MM'),
      Revenue: (obj.Revenue || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      MAU: (obj.MAU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      specificRevenue: {
        noDisplay: true,
        id: obj.Date.format('YYYY-MM'),
        iOSRevenue: obj.iOSRevenue,
        AndroidRevenue: obj.AndroidRevenue,
      },
      NRU: (obj.NRU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      DBRead: (obj.DBRead || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      DBWrite: (obj.DBWrite || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      NewCustomerCount: (obj.NewCustomerCount || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      NewProjectCount: (obj.NewProjectCount || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      releasedProject: {
        noDisplay: true,
        releasedProject: obj.releasedProject,
      },
      releasedProjectCount: obj.releasedProjectCount,
      GivenCredit: (obj.GivenCredit || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      UsedCredit: (obj.UsedCredit || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    }));
    return rows;
  }
);

export const customersListSelector = createSelector(
  // monthlyStat
  (state) => state.customers.customersList,
  (customersList) => {
    const rows = customersList.map((obj) => ({
      Date: obj.Date,
      Revenue: obj.Revenue,
      MAU: obj.MAU,
      NRU: obj.NRU,
      DBRead: obj.DBRead || '-',
      DBWrite: obj.DBWrite || '-',
      NewCustomerCount: obj.NewCustomerCount,
      NewProjectCount: obj.NewProjectCount,
      GivenCredit: obj.GivenCredit,
      UsedCredit: obj.UsedCredit,
    }));
    return rows;
  }
);

export const _customersListSelectorForView = createSelector(
  // dailyStat
  (state) => state.customers._customersList,
  (_customersList) => {
    // console.log('20210531 : _customersList', _customersList);
    const rows = _customersList.map((obj) => {
      return {
        Date: obj.Date.format('YYYY.MM.DD'),
        Revenue: (obj.Revenue || '')
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        DAU: (obj.DAU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        specificRevenue: {
          noDisplay: true,
          id: obj.Date.format('YYYY-MM-DD'),
          iOSRevenue: obj.iOSRevenue,
          AndroidRevenue: obj.AndroidRevenue,
        },
        NRU: (obj.NRU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        DBRead: (obj.DBRead || '')
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        DBWrite: (obj.DBWrite || '')
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        NewCustomerCount: (obj.NewCustomerCount || '')
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        NewProjectCount: (obj.NewProjectCount || '')
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        releasedProject: {
          noDisplay: true,
          releasedProject: obj.releasedProject,
        },
        releasedProjectCount: obj.releasedProjectCount,
        GivenCredit: (obj.GivenCredit || '')
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        UsedCredit: (obj.UsedCredit || '')
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      };
    });
    return rows;
  }
);

export const _customersListSelector = createSelector(
  // dailyStat
  (state) => state.customers._customersList,
  (_customersList) => _customersList
);

export const actualLastItemSelector = createSelector(
  (state) => state.customers,
  (customers) => {
    // console.log(customers.customersList, 'in selector');
    // console.log(customers._customersList, 'in selector');
    return {
      customersList:
        customers.customersList[customers.customersList.length - 1],
      _customersList:
        customers._customersList[customers._customersList.length - 1],
    };
  }
);

export const monthlyStatsLastItemSelector = createSelector(
  (state) => state.customers,
  ({ monthlyStatsLastItem }) => {
    if (monthlyStatsLastItem) {
      return moment(monthlyStatsLastItem.Date).set({ hour: 0 }).toISOString();
    }
  }
);
export const dailyStatsLastItemSelector = createSelector(
  (state) => state.customers,
  ({ dailyStatsLastItem }) => {
    if (dailyStatsLastItem) {
      return moment(dailyStatsLastItem.Date).set({ hour: 0 }).toISOString();
    }
  }
);
export const sortingParamSelector = createSelector(
  (state) => state.customers.sortingParam,
  (sortingParam) => sortingParam
);

export const statTypeSelector = createSelector(
  (state) => state.customers.statType,
  (statType) => statType
);

export const isLastSelector = createSelector(
  (state) => state.customers.isLast,
  (isLast) => isLast
);

export const releasedPjtsSelector = createSelector(
  (state) => state.customers,
  ({ releasedPjts, isReleasedPjtsModalOpen, date }) => ({
    releasedPjts,
    isReleasedPjtsModalOpen,
    date: date ? date.Date : null,
  })
);
