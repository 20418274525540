// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { LOCATION_CHANGE } from 'react-router-redux';

import * as types from './constant';

const initialState = {
  projectList: [],
  statusList: [],
  columns: [
    {
      label: '',
      field: 'check',
      sort: 'asc',
      width: 100,
    },
    {
      label: '프로젝트',
      field: 'project',
      sort: 'asc',
      width: 10,
    },
    {
      label: '아이디',
      field: 'id',
      sort: 'asc',
      width: 270,
    },
    {
      label: 'CA ID',
      field: 'id',
      sort: 'asc',
      width: 270,
    },
    {
      label: '회사명',
      field: 'company',
      sort: 'asc',
      width: 100,
    },
    {
      label: '생성일시',
      field: 'inDate',
      sort: 'asc',
      width: 150,
    },
    {
      label: '평균DAU (7일)',
      field: 'avgDAU',
      sort: 'asc',
      width: 100,
    },
    {
      label: '요금제',
      field: 'plan',
      sort: 'asc',
      width: 100,
    },
    {
      label: '할인율',
      field: 'discountRate',
      sort: 'asc',
      width: 100,
    },
    {
      label: '스토어(G/A)',
      field: 'plan',
      sort: 'asc',
      width: 100,
    },
    {
      label: '구글',
      field: 'google',
      sort: 'asc',
      width: 100,
    },
    {
      label: '애플',
      field: 'apple',
      sort: 'asc',
      width: 100,
    },
    {
      label: '상태',
      field: 'status',
      sort: 'asc',
      width: 100,
    },
    {
      label: '채팅',
      field: 'chat',
      sort: 'asc',
      width: 100,
    },
    {
      label: '',
      field: '',
      sort: 'asc',
      width: 100,
    },
  ],
  detail: { clickedId: '', projectDetail: false },
  completeCharge: true,
  sortingParam: false,
  searchKeywords: {},
  offset: 0,
  searchingOffset: 0,
  initIndicator: null,
  projectData: null,
  discountModalOpen: false,
};

function projectManaging(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...initialState,
      };

    case types.MODIFY_PROJECT_ROW:
      return {
        ...state,
        detail: {
          clickedId: action.arr[2].id,
          projectDetail: true,
          title: action.arr[1].title,
          status: action.arr[14].status,
        },
      };

    case types.TOGGLE_APP_RELEASE_INFO:
      return {
        ...state,
        releasedRowId: action.arr[2].id,
        releaseInfo: action.id === 'google' ? action.arr[11] : action.arr[13],
        gameId: action.arr[2].id,
      };

    case types.GET_STATUS_LIST_SUCCESS:
      return {
        ...state,
        statusList: action.data,
      };

    case types.GET_PROJECTS_COUNT_SUCCESS:
      return {
        ...state,
        projectCount: action.data,
      };

    case types.HANDLE_CLOSE_PROJECT_MODAL: // action 명칭 변경 필요
      return {
        ...state,
        detail: { clickedId: '', projectDetail: false },
      };

    case types.INIT_PROJECT_LIST:
      return {
        ...state,
        projectList: initialState.projectList,
        offset: initialState.offset,
      };

    case types.GET_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        projectList: action.toRefresh
          ? action.data.projectList
          : [...state.projectList, ...action.data.projectList], // state.projectList 초기화 시점을 정의해야 함 (ex. 초기화버튼 클릭, 메뉴 이동 시 등등)
        isLast: action.data.isLast,
        offset: !action.data.isLast ? state.offset + 25 : state.offset,
        offsetToRefresh: action.toRefresh
          ? state.offsetToRefresh
          : state.offset,
        projectCount: action.data.totalCount,
        generalProjectCount: action.data.generalProjectCount,
        ineternalProjectCount: action.data.ineternalProjectCount,
        enterpriseInfo: null,
        isLoaded: true,
      };

    case types.INIT_CURRENT_PROJECT_LIST:
      return {
        ...state,
        projectList: [],
        offset: 0,
        offsetToRefresh: 0,
        searchingOffset: 0,
      };

    case types.GET_SEARCHED_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        projectList: action.toRefresh
          ? action.data.projectList
          : [...state.projectList, ...action.data.projectList],
        searchingOffset:
          action.data.isLast === false
            ? state.searchingOffset + 25
            : state.searchingOffset,
        searchingOffsetToRefresh: state.searchingOffset,
        isLast: action.data.isLast,
        projectCount: action.data.totalCount,
      };

    case types.SORT_ASC_PROJECT_LIST:
      return {
        ...state,
        projectList: action.rows,
        sortingParam: true,
      };

    case types.SORT_DESC_PROJECT_LIST:
      return {
        ...state,
        projectList: action.rows,
        sortingParam: false,
      };

    case types.GET_SEARCH_KEYWORDS_PROJECTS:
      return {
        ...state,
        searchKeywords: {
          ...state.searchKeywords,
          ...action.obj,
        },
        isLoaded: true,
      };

    case types.INIT_PROJECT_MANAGING:
      return initialState;

    case types.UNMOUNT_PROJECT_LIST:
      return {
        ...state,
        initIndicator: true,
      };

    case types.HANDLE_UPDATE_PAY_PLAN:
      return {
        ...state,
        // shouldUpdatePayPlan: action.isOpen,
      };

    case types.GET_ENTERPRISE_INFO:
      return {
        ...state,
        enterpriseInfo: state.projectList.filter(
          (item) => item.id === action.id
        )[0],
      };

    case types.INIT_ENTERPRISE_INFO:
      return {
        ...state,
        enterpriseInfo: initialState.enterpriseInfo,
      };

    case types.GET_PROJECT_SUCCESS:
      return {
        ...state,
        projectData: action.data,
      };

    case types.GET_DELETED_PROJECT_SUCCESS:
      return {
        ...state,
        deletedProjects: action.data,
      };

    case types.GET_RATE_PLAN_SUCCESS:
      return {
        ...state,
        projectRatePlan: action.payload,
      };

    case types.TOGGLE_DISCOUNT_OPEN:
      return {
        ...state,
        discountModalOpen: !state.discountModalOpen,
      };

    case types.POST_DISCOUNT_SUCCESS:
      return {
        ...state,
        discountModalOpen: false,
      };

    default:
      return state;
  }
}

export default projectManaging;
