import { put, takeLatest, call } from 'redux-saga/effects';
import { LOCATION_CHANGE, push } from 'react-router-redux';

import Cookies from 'js-cookie';

import { browserHistory } from 'react-router';

import DefaultAxios from '../../../utils/DefaultAxios';

import * as types from './constant';

import * as actions from './action';
// import * as selectors from './selector';
import * as api from '../../../service/API';

function* locationChange({ payload }) {
	try {
		const { pathname } = payload;

		const path = pathname.split('/')[1];

		if (path === 'login') {
			const userName = Cookies.get('username');
			yield put(actions.getUserNameSuccess(userName));
		}
	} catch (error) {}
}

export function* watcherLocationChange(params) {
	yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* handleLogin({ loginInfo, isChecked }) {
	try {
		const { data } = yield call(api.login.handleLogin, {
			username: loginInfo.id,
			password: loginInfo.pw,
			grant_type: 'password',
		});
		// console.log(data);

		const accessToken = data.access_token;
		const refreshToken = data.refresh_token;

		yield Cookies.remove('authorization');

		yield Cookies.set('access_token', accessToken);
		yield Cookies.set('usernameToDisplay', loginInfo.id);
		yield Cookies.set('refreshToken', refreshToken);

		if (isChecked) {
			yield Cookies.set('username', loginInfo.id, { expires: 31 });
		} else {
			// yield Cookies.remove("username");
		}

		yield DefaultAxios.init(accessToken);
		// yield DefaultAxios.init(access_token);
		yield browserHistory.push('/dashboard');
		yield put(actions.loginSuccess(data.name));
	} catch (error) {
		console.log(error && error.response.status);
		if (error && error.response.status === 404) {
			yield put(
				actions.handleLoginError({
					errMsg: '아이디 또는 비밀번호를 확인해주세요.',
				})
			);
		}
		if (error && error.response.status === 401) {
			yield put(actions.getUserNameSuccess(''));
			yield Cookies.remove('username');
		}
	}
}

export function* watcherHandleLogin() {
	yield takeLatest(types.HANDLE_LOGIN, handleLogin);
}

function* handleLogout() {
	try {
		yield Cookies.remove('access_token');
		yield Cookies.remove('refreshToken');
		console.log(
			'^^^^^^^^^^^^^^^^^^*******************************************586'
		);
		yield browserHistory.push('/login');
	} catch (error) {}
}

export function* watcherHandleLogout() {
	yield takeLatest(types.HANDLE_LOGOUT, handleLogout);
}
