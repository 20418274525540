// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

export const GET_INQUIRIES_LIST = 'GET_INQUIRIES_LIST_GDC';
export const GET_INQUIRIES_LIST_SUCCESS = 'GET_INQUIRIES_LIST_SUCCESS_GDC';
export const REQUEST_LIST_SUCCESS = 'REQUEST_LIST_SUCCESS_GDC';

export const SEND_RESPONSE = 'SEND_RESPONSE_GDC';
export const SEND_RESPONSE_SUCCESS = 'SEND_RESPONSE_SUCCESS_GDC';

export const DELETE_INQUIRY = 'DELETE_INQUIRY_GDC';
export const DELETE_INQUIRY_SUCCESS = 'DELETE_INQUIRY_SUCCESS_GDC';
