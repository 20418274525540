export const INIT_SLEEPING_CANDIDATES = 'INIT_SLEEPING_CANDIDATES';
export const GET_SLEEPING_CANDIDATES = 'GET_SLEEPING_CANDIDATES';
export const GET_SLEEPING_CANDIDATES_SUCCESS =
	'GET_SLEEPING_CANDIDATES_SUCCESS';
export const MAKE_SLEEPING_CANDIDATE_NOT = 'MAKE_SLEEPING_CANDIDATE_NOT';
export const MAKE_SLEEPING_ACCOUNT_NOT = 'MAKE_SLEEPING_ACCOUNT_NOT';
export const MAKE_SLEEPING_ACCOUNT_NOT_SUCCESS =
	'MAKE_SLEEPING_ACCOUNT_NOT_SUCCESS';
export const MAKE_SLEEPING_CANDIDATE_NOT_SUCCESS =
	'MAKE_SLEEPING_CANDIDATE_NOT_SUCCESS';
export const SORT_ASC_SLEEPING_CANDIDATES = 'SORT_ASC_SLEEPING_CANDIDATES';
export const SORT_DESC_SLEEPING_CANDIDATES = 'SORT_DESC_SLEEPING_CANDIDATES';
export const GET_SEARCH_KEYWORDS = 'GET_SEARCH_KEYWORDS';

export const INIT_SLEEPING_ACCOUNTS = 'INIT_SLEEPING_ACCOUNTS';
export const GET_SLEEPING_ACCOUNTS = 'GET_SLEEPING_ACCOUNTS';
export const SET_SEARCHING = 'SET_SEARCHING';
