// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

export const HANDLE_CLOSE_PROJECT_MODAL = 'HANDLE_CLOSE_PROJECT_MODAL';
export const CONFIRM_CHANGE_STATUS = 'CONFIRM_CHANGE_STATUS';
export const INIT_PROJECT_LIST = 'INIT_PROJECT_LIST';
export const GET_PROJECT_LIST = 'GET_PROJECT_LIST';
export const GET_PROJECT_LIST_SUCCESS = 'GET_PROJECT_LIST_SUCCESS';

export const GET_ACCOUNTS_ROWS = 'GET_ACCOUNTS_ROWS';
export const GET_ACCOUNTS_ROWS_SUCCESS = 'GET_ACCOUNTS_ROWS_SUCCESS';
export const INIT_ACCOUNT_LIST = 'INIT_ACCOUNT_LIST';
export const INIT_DELETED_ACCOUNT_LIST = 'INIT_DELETED_ACCOUNT_LIST';
export const GET_COLUMNS = 'GET_COLUMNS';
export const MODIFY_PROJECT_ROW = 'MODIFY_PROJECT_ROW';
export const TOGGLE_APP_RELEASE_INFO = 'TOGGLE_APP_RELEASE_INFO';
export const HANDLE_OPEN_MODAL = 'HANDLE_OPEN_MODAL';
export const CONFIRM_DELETE_ACCOUNT = 'CONFIRM_DELETE_ACCOUNT';
export const SAVE_INPUT = 'SAVE_INPUT';
export const GET_STATUS_LIST_SUCCESS = 'GET_STATUS_LIST_SUCCESS';
export const HANDLE_UPDATE_PAY_PLAN = 'HANDLE_UPDATE_PAY_PLAN';

export const SORT_ASC_PROJECT_LIST = 'SORT_ASC_PROJECT_LIST';
export const SORT_DESC_PROJECT_LIST = 'SORT_DESC_PROJECT_LIST';

export const GET_SEARCH_KEYWORDS_PROJECTS = 'GET_SEARCH_KEYWORDS_PROJECTS';
export const INIT_PROJECT_MANAGING = 'INIT_PROJECT_MANAGING';
export const INIT_CURRENT_PROJECT_LIST = 'INIT_CURRENT_PROJECT_LIST';
export const UNMOUNT_PROJECT_LIST = 'UNMOUNT_PROJECT_LIST';
export const GET_PROJECTS_COUNT_SUCCESS = 'GET_PROJECTS_COUNT_SUCCESS';
export const GET_SEARCHED_PROJECT_LIST_SUCCESS =
  'GET_SEARCHED_PROJECT_LIST_SUCCESS';
export const GET_ENTERPRISE_INFO = 'GET_ENTERPRISE_INFO';
export const INIT_ENTERPRISE_INFO = 'INIT_ENTERPRISE_INFO';
export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_DELETED_PROJECT = 'GET_DELETED_PROJECT';
export const GET_DELETED_PROJECT_SUCCESS = 'GET_DELETED_PROJECT_SUCCESS';
export const UPDATE_GAME_RELEASING_DATA = 'UPDATE_GAME_RELEASING_DATA';
export const UPDATE_GAME_RELEASING_DATA_SUCCESS =
  'UPDATE_GAME_RELEASING_DATA_SUCCESS';
export const GET_RATE_PLAN = 'GET_RATE_PLAN';
export const GET_RATE_PLAN_SUCCESS = 'GET_RATE_PLAN_SUCCESS';
export const PUT_RATE_PLAN = 'PUT_RATE_PLAN';
export const PUT_RATE_PLAN_SUCCESS = 'PUT_RATE_PLAN_SUCCESS';

export const TOGGLE_DISCOUNT_OPEN = 'TOGGLE_DISCOUNT_OPEN';
export const POST_DISCOUNT = 'POST_DISCOUNT';
export const POST_DISCOUNT_SUCCESS = 'POST_DISCOUNT_SUCCESS';
