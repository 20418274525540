import moment from 'moment';

const sortingDates = (rows) => {
	//   중간에 추가되는 메소드이므로, 기존 로직을 보호하기 위해서 정렬을 위해 Date 값을 moment객체로 바꾼 후 다시 원래 string으로 바꾼다
	const arrIncludingMoment = rows.map((row) => {
		let _row = {};

		for (const key in row) {
			if (key.includes('Date')) {
				_row = {
					..._row,
					[key]: moment(row[key]),
				};
			} else {
				_row = {
					..._row,
					[key]: row[key],
				};
			}
		}
		return _row;
	});

	return arrIncludingMoment
		.sort((a, b) => {
			if (a.inDate && a.inDate.isAfter(b.inDate)) {
				//inDate 또는 Date
				return -1;
			}
			if (a.inDate && !a.inDate.isAfter(b.inDate)) {
				//inDate 또는 Date
				return 1;
			}
			if (a.joinDate && a.joinDate.isAfter(b.joinDate)) {
				//inDate 또는 Date
				return -1;
			}
			if (a.joinDate && !a.joinDate.isAfter(b.joinDate)) {
				//inDate 또는 Date
				return 1;
			}
			if (a.Date && a.Date.isAfter(b.Date)) {
				//inDate 또는 Date
				return -1;
			}
			if (a.Date && !a.Date.isAfter(b.Date)) {
				//inDate 또는 Date
				return 1;
			}
		})
		.map((row) => {
			let _row = {};

			for (const key in row) {
				if (key.includes('Date')) {
					_row = {
						..._row,
						[key]: row[key].format('YYYY.MM.DD HH:mm'),
					};
				} else {
					_row = {
						..._row,
						[key]: row[key],
					};
				}
			}
			return _row;
		});
};

export default sortingDates;
