import {
  requestGet,
  requestPost,
  requestPut,
  requestDelete
} from "../../utils/request";
import Qs from "qs";

export const getNoticeList = (params) => {
  // const url = 'notice';
  const url = 'notice/v1.2';

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  return requestGet(url, options);
};

export const createNotice = (data) => {
  // const url = 'notice/v1.1';
  const url = 'notice/v1.2';

  return requestPut(url, data); // 수정은 post
};

export const updateNotice = (data, noticeId) => {
  // const url = 'notice';
  const url = `notice/v1.2/${noticeId}`;

  return requestPost(url, data); // 수정은 post
};

export const deleteNotice = (id) => {
  const url = `notice/v1.2/${id}`;
  return requestDelete(url);
};
