// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import DataTable from '../../../fnComponents/_DataTable';
import MsgModal from '../../../fnComponents/MsgModal';

import * as actions from './store/action';
import * as selectors from './store/selector';

const Showcase = ({
  showcaseList,
  showcase,
  insertDone,
  lastEvaluatedKey,
  showcaseInDate,
  errObj,
  onGetShowcase,
  onGetMoreShowcaseList,
  onDeleteShowcase,
  onUpdateShowcaseOrder,
  onInsertShowcase,
  onUpdateShowcase,
  onUpdateShowcaseSuccess,
  onInitErrObj,
}) => {
  const [isShowcaseModalOpen, handleOpen] = useState(false);
  const [isMsgModalOpen, handleMsgModal] = useState(false);

  const [_content, setModalContent] = useState(null);

  useEffect(() => {
    if (errObj) {
      if (errObj.gameErr) setModalContent('게임을 찾을 수 없습니다.');
      if (errObj.imageErr) setModalContent('이미지를 찾을 수 없습니다.');

      handleMsgModal(true);
    }
  }, [errObj]);

  const [errors, handleErrors] = useState({
    gameTitle: null,
    providers: null,
    gameGenre: null,
    image: null,
    descriptions: null,
    url: null,
    gameId: null,
  });

  const [inputs, handleInputs] = useState({
    gameTitle: null,
    providers: null,
    gameGenre: null,
    image: null,
    descriptions: null,
    googleURI: null,
    appleURI: null,
    gameId: null,
  });

  const [radioBtn, handleRadio] = useState({ isPublic: 'y' });
  const [imgSrcFromLocal, handleImg] = useState('');
  const [inDateToDelete, setInDateToDelete] = useState('');

  useEffect(() => {
    if (showcase) {
      handleInputs({
        gameTitle: showcase.gameTitle,
        providers: showcase.providers,
        gameGenre: showcase.gameGenre,
        descriptions: showcase.descriptions,
        googleURI: showcase.googleURI,
        appleURI: showcase.appleURI,
        gameId: showcase.gameId,
      });
      handleOpen(true);
      handleRadio({ isPublic: showcase.isPublic });
      handleImg({ image: showcase.image });
    }
  }, [showcase]);

  useEffect(() => {
    if (insertDone) {
      handleOpen(false);
      handleInputs({
        gameTitle: null,
        providers: null,
        gameGenre: null,
        image: null,
        descriptions: null,
        googleURI: null,
        appleURI: null,
        gameId: null,
      });
      handleImg('');
    }
  }, [insertDone]);

  const handleInputChange = (e) => {
    handleInputs({
      ...inputs,
      [e.target.id]: e.target.value,
    });
  };

  const handleRadioBtnChange = (e) => {
    handleRadio({
      isPublic: e.target.id,
    });
  };

  const handlePostShowcase = () => {
    const {
      gameTitle,
      providers,
      gameGenre,
      descriptions,
      googleURI,
      appleURI,
      gameId,
    } = inputs;

    const { image } = imgSrcFromLocal;

    const errors = {
      gameTitle: null,
      providers: null,
      gameGenre: null,
      image: null,
      descriptions: null,
      url: null,
      gameId: null,
    };

    if (!gameTitle) errors.gameTitle = '게임명을 입력해주세요.';
    if (!gameId) errors.gameId = '게임아이디를 입력해주세요.';
    if (!providers)
      errors.providers =
        '제공자(개발사/유통사)를 입력해주세요. (유통사가 있을 경우 유통사 입력)';
    if (!gameGenre) errors.gameGenre = '장르를 입력해주세요.';
    // if (!image) errors.image = "이미지를 등록해주세요.";
    if (!descriptions) errors.descriptions = '설명을 입력해주세요.';
    if (!googleURI && !appleURI)
      errors.url = '스토어 URL은 1개 이상 입력해야 합니다.';

    if (googleURI && appleURI === '') inputs.appleURI = null;
    if (appleURI && googleURI === '') inputs.googleURI = null;

    const isErr = Object.values(errors).filter((err) => !!err).length;
    if (isErr) return handleErrors(errors);
    else console.log('정상');

    if (showcase) {
      // updateShowcase
      // console.log(showcase.image === image)
      // console.log(inputs)

      onUpdateShowcase(
        showcaseInDate,
        showcase.image === image
          ? { ...inputs, ...radioBtn }
          : { ...inputs, ...radioBtn, image }
      );
      handleOpen(false);
      handleInputs({
        gameTitle: null,
        providers: null,
        gameGenre: null,
        image: null,
        descriptions: null,
        googleURI: null,
        appleURI: null,
        gameId: null,
      });
      handleImg('');
      return;
    }
    onInsertShowcase({ ...inputs, image, ...radioBtn });
    handleOpen(false);
    handleInputs({
      gameTitle: null,
      providers: null,
      gameGenre: null,
      image: null,
      descriptions: null,
      googleURI: null,
      appleURI: null,
      gameId: null,
    });
    handleImg('');
  };

  const onHandleCloseMsgModal = () => {
    handleMsgModal(false);
    onInitErrObj();
  };

  const handleShowcaseModal = (e) => {
    if (e.target.id === 'open') return handleOpen(true);
    else {
      onUpdateShowcaseSuccess();
      handleOpen(false);
      handleInputs({
        gameTitle: null,
        providers: null,
        gameGenre: null,
        image: null,
        descriptions: null,
        googleURI: null,
        appleURI: null,
        gameId: null,
      });
      handleImg('');
      handleErrors({
        gameTitle: null,
        providers: null,
        gameGenre: null,
        image: null,
        descriptions: null,
        googleURI: null,
        appleURI: null,
        gameId: null,
      });
    }
  };

  const handleDeleteShowcase = (a, arr, c, d) => {
    console.log(arr[5]._inDate.inDate);
    const { inDate } = arr[5]._inDate;
    handleMsgModal(true);
    setInDateToDelete(inDate);
  };

  const deleteShowcase = () => {
    console.log(inDateToDelete, 'in deleteShowcase func');
    onDeleteShowcase(inDateToDelete);
    handleMsgModal(false);
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title ">쇼케이스 관리</h3>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <Modal isOpen={isShowcaseModalOpen} size="lg">
                      <ModalHeader>쇼케이스 등록</ModalHeader>
                      <ModalBody>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="title"
                            className="col-lg-2 col-form-label"
                          >
                            * 게임아이디
                          </label>
                          <div className="col-lg-10">
                            <input
                              onChange={handleInputChange}
                              type="text"
                              className="form-control"
                              id="gameId"
                              required
                              value={inputs.gameId}
                            />
                            <div className="error message visible">
                              {errors.gameId}
                            </div>
                          </div>
                        </div>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="title"
                            className="col-lg-2 col-form-label"
                          >
                            * 게임명
                          </label>
                          <div className="col-lg-10">
                            <input
                              onChange={handleInputChange}
                              type="text"
                              className="form-control"
                              id="gameTitle"
                              required
                              value={inputs.gameTitle}
                            />
                            <div className="error message visible">
                              {errors.gameTitle}
                            </div>
                          </div>
                        </div>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="providers"
                            className="col-lg-2 col-form-label"
                          >
                            * 제공자
                          </label>
                          <div className="col-lg-10">
                            <input
                              onChange={handleInputChange}
                              type="type"
                              className="form-control"
                              id="providers"
                              required
                              value={inputs.providers}
                            />
                            <div className="error message visible">
                              {errors.providers}
                            </div>
                          </div>
                        </div>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="gameGenre"
                            className="col-lg-2 col-form-label"
                          >
                            * 장르
                          </label>
                          <div className="col-lg-10">
                            <input
                              onChange={handleInputChange}
                              type="type"
                              className="form-control"
                              id="gameGenre"
                              required
                              value={inputs.gameGenre}
                            />
                            <div className="error message visible">
                              {errors.gameGenre}
                            </div>
                          </div>
                        </div>
                        {/* <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="image"
                            className="col-lg-2 col-form-label"
                          >
                            * 이미지
                          </label>
                          <ImageUploadBox
                            handleImage={handleImage}
                            onUpload={handleImgUpload}
                            src={imgSrcFromLocal.image || inputs.image}
                            // isLoading={this.props.isLoading}
                            // onDelete={this.props.onDeleteProjectImg}
                            error={errors.image}
                          />
                        </div> */}
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="descriptions"
                            className="col-lg-2 col-form-label"
                          >
                            * 설명
                          </label>
                          <div className="col-lg-10">
                            <textarea
                              onChange={handleInputChange}
                              className="form-control"
                              id="descriptions"
                              required
                              value={inputs.descriptions}
                            />
                            <div className="error message visible">
                              {errors.descriptions}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-form-label">
                          * 스토어 URL
                        </div>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="googleURI"
                            className="col-lg-2 col-form-label"
                          >
                            구글
                          </label>
                          <div className="col-lg-10">
                            <input
                              onChange={handleInputChange}
                              type="type"
                              className="form-control"
                              id="googleURI"
                              required
                              value={inputs.googleURI}
                            />
                          </div>
                        </div>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="appleURI"
                            className="col-lg-2 col-form-label"
                          >
                            애플
                          </label>
                          <div className="col-lg-10">
                            <input
                              onChange={handleInputChange}
                              type="text"
                              className="form-control"
                              id="appleURI"
                              required
                              value={inputs.appleURI}
                            />
                            <div className="error message visible">
                              {errors.url}
                            </div>
                          </div>
                        </div>

                        <div className="form-group m-form__group row mt-30">
                          <label
                            for="statusReason"
                            className="col-lg-2 col-form-label"
                          >
                            노출
                          </label>
                          <div className="col-lg-10">
                            <div className="m-radio-inline">
                              <label className="m-radio">
                                <input
                                  id="y"
                                  onChange={handleRadioBtnChange}
                                  type="radio"
                                  name="credit-radio"
                                  checked={radioBtn.isPublic === 'y'}
                                />
                                공개
                                <span />
                              </label>
                              <label className="m-radio">
                                <input
                                  id="n"
                                  onChange={handleRadioBtnChange}
                                  type="radio"
                                  name="credit-radio"
                                  checked={radioBtn.isPublic === 'n'}
                                />
                                비공개
                                <span />
                              </label>
                            </div>

                            {/* <div className="error message visible">{errMsg && errMsg}</div> */}
                          </div>
                        </div>
                        {/* </div> */}
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={handlePostShowcase}>
                          확인
                        </Button>
                        <Button
                          id="close"
                          color="primary"
                          onClick={handleShowcaseModal}
                        >
                          취소
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <Button id="open" onClick={handleShowcaseModal}>
                      등록
                    </Button>
                    <DataTable
                      isLast={!lastEvaluatedKey}
                      requestAction={onGetMoreShowcaseList}
                      // onSortAsc={onSortAscProjectList}
                      // onSortDesc={onSortDescProjectList}
                      // sortingParam={sortingParam}
                      requestParams={lastEvaluatedKey}
                      deleteRow
                      reorder
                      onUpdateShowcaseOrder={onUpdateShowcaseOrder}
                      requiredAction={onGetShowcase}
                      secondRequiredAction={handleDeleteShowcase}
                      rows={showcaseList}
                      // actualData={originalProjectList}
                      columns={[
                        { label: '' },
                        { label: '' },
                        { label: '게임명' },
                        { label: '제공자', field: 'creditPrice' },
                        { label: '등록일시', field: 'numberOfCoupon' },
                        { label: '상태', field: 'numberOfUsed' },
                        { label: '' },
                      ]}
                      customClassName={
                        showcaseList.length > 13
                          ? 'showcase-table column-6 table-scrollable'
                          : 'showcase-table column-6'
                      }
                      clickableIndex={[0, 1, 2]}
                    />
                    <MsgModal
                      onlyCanClose={!!errObj}
                      content={errObj ? _content : '삭제하시겠습니까?'}
                      isMsgModalOpen={isMsgModalOpen}
                      onHandleCloseMsgModal={
                        // errObj ? null :
                        onHandleCloseMsgModal
                      }
                      onHandleDelete={errObj ? null : deleteShowcase}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  showcaseList: selectors.showcaseListSelectorForView,
  showcase: selectors.showcaseSelector,
  insertDone: selectors.insertDoneSelector,
  lastEvaluatedKey: selectors.lastEvaluatedKeySelector,
  showcaseInDate: selectors.showcaseInDateSelector,
  errObj: selectors.errObjSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onGetShowcase: (title, arr) => dispatch(actions.getShowcase(title, arr)),
  onGetMoreShowcaseList: (lastEvaluatedKey) =>
    dispatch(actions.getMoreShowcaseList(lastEvaluatedKey)),
  onDeleteShowcase: (title, arr) =>
    dispatch(actions.deleteShowcase(title, arr)),
  onUpdateShowcaseOrder: (title, arr) =>
    dispatch(actions.updateShowcaseOrder(title, arr)),
  onInsertShowcase: (obj) => dispatch(actions.insertShowcase(obj)),
  onUpdateShowcase: (inDate, data) =>
    dispatch(actions.updateShowcase(inDate, data)),
  onUpdateShowcaseSuccess: () => dispatch(actions.updateShowcaseSuccess()),
  onInitErrObj: () => dispatch(actions.initErrObj()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Showcase);
