// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { LOCATION_CHANGE } from 'react-router-redux';

import * as types from './constant';

const initialState = {
  isErr: false,
  errMsg: '',
  errCode: '',
  isLoading: false,
};

function global(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state;
    case types.GLOBAL_ERROR_HANDLING:
      return {
        ...state,
        shouldOpenModal: action.params.shouldOpenModal,
        isErr: true,
        errMsg:
          (action.params && action.params.errMsg) ||
          (action.params && action.params.message),
        errCode:
          action.params && action.params.errCode ? action.params.errCode : '',
        errType: action.params && action.params.type ? action.params.type : '',
      };
    case types.GLOBAL_ERROR_HANDLING_DONE:
      return initialState;
    case types.GLOBAL_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case types.GLOBAL_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case types.UPLOAD_CSV_FILE:
      return {
        ...state,
        csv: action.params,
        errMsg: initialState.errMsg,
      };
    case types.GLOBAL_MSG_MODAL_OPEN:
      return {
        ...state,
        ...action.params,
      };

    default:
      return state;
  }
}

export default global;
