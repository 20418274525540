// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextArea } from 'semantic-ui-react';
import BigNumber from 'bignumber.js';
import { clone, cloneDeep, omitBy } from 'lodash';

import EditModal from './EditModal';
import Header from '../../view/header';
import LeftAsideMunu from '../../view/leftAsideMenu';
import DataTable from '../../fnComponents/_DataTable';
import MsgModal from '../../fnComponents/MsgModal';

import * as actions from './store/actions';
import {
  baseIndicatorsSelector,
  functionIndicatorsSelector,
  chatIndicatorsSelector,
  matchIndicatorsSelector,
  dbIndicatorsSelector,
  isSetterSuccessSelector,
} from './store/selector';
import categoryIndicators from './categoryIndicators.json';
import indicatorsMapper from './indicatorsMapper.json';
import DefaultAxios from '../../utils/DefaultAxios';
import BASE_URL from '../../shared/baseUrl';

const Indicators = () => {
  const dispatch = useDispatch();
  const baseIndicators = useSelector(baseIndicatorsSelector);
  const functionIndicators = useSelector(functionIndicatorsSelector);
  const chatIndicators = useSelector(chatIndicatorsSelector);
  const matchIndicators = useSelector(matchIndicatorsSelector);
  const dbIndicators = useSelector(dbIndicatorsSelector);
  const isSetterSuccess = useSelector(isSetterSuccessSelector);

  const [urlByCategory, setUrlByCategory] = useState('');
  const [isMsgModalOpen, setIsMsgModalOpen] = useState(false);
  const [newIndicators, setNewIndicators] = useState(null);

  const [errObj, setErrObj] = useState({});

  useEffect(() => {
    dispatch(actions.indicatorsGetter('all'));
  }, []);

  useEffect(() => {
    if (isSetterSuccess) {
      dispatch(actions.indicatorsGetter('all'));
      setIsMsgModalOpen(false);
    }
  }, [isSetterSuccess]);

  useEffect(() => {});

  const handleSendToFileChange = (e) => {
    const reader = new FileReader();

    reader.onload = (res) => {
      const arr = reader.result.split('\n');
      const column = arr[0].split(',');
      let resObj = {};
      arr.forEach((row, i) => {
        if (i && row) {
          let obj = {};
          column.forEach((col, i) => {
            obj[col] = row.split(',')[i];
          });

          resObj[obj.resource_name] = {
            category: obj.category,
            key: obj.key,
          };
        }
      });

      setUrlByCategory(JSON.stringify(resObj, null, 4));
    };
    reader.readAsText(e.target.files[0]);
  };
  const handleSetUrlCategory = () => {
    const newJson = {};
    categoryIndicators.forEach((obj) => {
      const { category, resource_name, key } = obj;
      // 예) category === 'gameUser'
      newJson[resource_name] = {
        category,
        key,
      };
    });
    delete newJson.head;
    return newJson;
  };

  const handleIndicatorsSetter = ({ type, value }) => {
    // 현재는 순수 json을 전송해야 함 -> 서버에서 json stringify를 수행하지 않도록 바꾸면?
    // String -> json parse 해보기
    //
    let data;
    if (type === 'category') {
      data = handleSetUrlCategory();
    } else {
      data = JSON.parse(value);
    }

    DefaultAxios.post(`${BASE_URL.CONSOLE_API}ratePlan/indicatorsSetter`, {
      data,
      type,
    });
  };

  const handleChange = (e) => {
    setNewIndicators({
      ...newIndicators,
      [e.target.name]: e.target.value,
    });
  };

  const handleDBChange = (e) => {
    const obj = cloneDeep(newIndicators);
    obj[e.target.id][e.target.name] = Number(e.target.value);
    setNewIndicators(obj);
  };

  // 각 기능 유형 별 데이터테이블 값 생성 start
  const baseData = useMemo(() => {
    if (baseIndicators) {
      return Object.keys(baseIndicators).map((item) => ({
        // 0번 인덱스에 기능 유형 파라미터를 삽입
        featureType: { noDisplay: true, value: 'base', item },
        title: indicatorsMapper[item],
        limitCount: `${
          baseIndicators[item].limitCount
            ? baseIndicators[item].limitCount.toLocaleString()
            : 0
        } 호출`,
        price: `${baseIndicators[item].price || 0} 원`,
      }));
    }
  }, [baseIndicators]);

  const functionData = useMemo(() => {
    if (functionIndicators) {
      return Object.keys(functionIndicators).map((type, i) => ({
        // 0번 인덱스에 기능 유형 파라미터를 삽입
        featureType: { noDisplay: true, value: 'function', item: type },
        type: indicatorsMapper[type],
        callLimit: `${
          functionIndicators[type].callLimit
            ? functionIndicators[type].callLimit.toLocaleString()
            : 0
        } 호출`,
        callPrice: `${functionIndicators[type].callPrice || 0} 원`,
        durationLimit: `${
          functionIndicators[type].durationLimit
            ? functionIndicators[type].durationLimit.toLocaleString()
            : 0
        } 원`,
        durationPrice: `${functionIndicators[type].durationPrice} 원`,
      }));
    }
  }, [functionIndicators]);

  const chatData = useMemo(() => {
    if (chatIndicators) {
      return Object.keys(chatIndicators).map((type, i) => ({
        // 0번 인덱스에 기능 유형 파라미터를 삽입
        featureType: { noDisplay: true, value: 'chat', item: type },
        type: indicatorsMapper[type],
        fee: `${
          chatIndicators[type].fee
            ? chatIndicators[type].fee.toLocaleString()
            : 0
        } 원`,
        trafficLimit: `${
          chatIndicators[type].trafficLimit
            ? chatIndicators[type].trafficLimit
            : 0
        } GB`,
        trafficPrice: `${
          chatIndicators[type].trafficPrice
            ? chatIndicators[type].trafficPrice.toLocaleString()
            : 0
        } 원`,
        logPrice: `${
          chatIndicators[type].logPrice
            ? chatIndicators[type].logPrice.toLocaleString()
            : 0
        } 원`,
      }));
    }
  });
  const matchData = useMemo(() => {
    if (matchIndicators) {
      return Object.keys(matchIndicators).map((type, i) => ({
        // 0번 인덱스에 기능 유형 파라미터를 삽입
        featureType: { noDisplay: true, value: 'match', item: type },
        type: indicatorsMapper[type],
        fee: `${
          matchIndicators[type].fee
            ? matchIndicators[type].fee.toLocaleString()
            : 0
        } 원`,
        matchCountLimit: `${
          matchIndicators[type].matchCountLimit
            ? matchIndicators[type].matchCountLimit.toLocaleString()
            : 0
        } 건`,
        matchCountPrice: `${
          matchIndicators[type].matchCountPrice
            ? matchIndicators[type].matchCountPrice.toLocaleString()
            : 0
        } 원`,
        durationLimit: `${
          matchIndicators[type].durationLimit
            ? matchIndicators[type].durationLimit.toLocaleString()
            : 0
        } 시간`,
        durationPrice: `${
          matchIndicators[type].durationPrice
            ? matchIndicators[type].durationPrice.toLocaleString()
            : 0
        } 원`,
        logPrice: `${
          matchIndicators[type].logPrice
            ? matchIndicators[type].logPrice.toLocaleString()
            : 0
        } 원`,
      }));
    }
  });

  const handleDBLimitCount = (title, limitCount) => {
    if (title === 'read' || title === 'write') {
      if (limitCount) {
        return `${limitCount.toLocaleString()} 건`;
      }
      return '0 건';
    } else {
      if (limitCount) {
        return `${limitCount.toLocaleString()} GB`;
      }
      return '0 GB';
    }
  };

  const dbData = useMemo(() => {
    if (dbIndicators) {
      return Object.keys(dbIndicators).map((item) => ({
        // 0번 인덱스에 기능 유형 파라미터를 삽입
        featureType: { noDisplay: true, value: 'db', item },
        title: indicatorsMapper[item],
        limitCount: handleDBLimitCount(item, dbIndicators[item].limitCount),
        price: `${dbIndicators[item].price || 0} 원`,
      }));
    }
  });
  // 각 기능 유형 별 데이터테이블 값 생성 end

  const handleArray = (arr) => {
    const objet = {};
    arr.forEach((item) => {
      const key = Object.keys(item)[0];
      objet[key] = item[key];
    });
    const obj = {
      baseIndicators,
      chatIndicators,
      functionIndicators,
      dbIndicators,
      matchIndicators,
    };
    const {
      featureType: { value, item },
      title,
      type,
    } = objet;

    const feature = obj[`${value}Indicators`][item];

    return { ...feature, value, item, title, type };
  };

  const modifyIndicator = (arr) => {
    const {
      title, // base only!
      value,
      item,
      // function requirements
      type,
      callLimit,
      callPrice,
      durationLimit,
      durationPrice,
      matchCountLimit,
      matchCountPrice,
      fee,
      trafficLimit,
      trafficPrice,
      logPrice,
    } = handleArray(arr);

    let price;
    let limitCount;

    switch (value) {
      case 'base':
        price = baseIndicators[item].price;
        limitCount = baseIndicators[item].limitCount;
      default:
        break;
    }

    setNewIndicators({
      price,
      limitCount,
      value,
      title,
      type,
      item,
      callPrice,
      durationPrice,
      callLimit,
      durationLimit,
      fee,
      trafficLimit,
      trafficPrice,
      logPrice,
      matchCountLimit,
      matchCountPrice,
    });

    setIsMsgModalOpen(true);
    /**
     * 모달 활성화
     * 모달 헤더 동적으로 생성 -> 생성 함수 호출 -> 처리 결과를 ref 참조 변수에 할당
     * */
  };

  const modifyDBIndicator = async () => {
    // console.log('chhk dbIndicators', dbIndicators);
    const { data } = await DefaultAxios.get(
      `${BASE_URL.CONSOLE_API}ratePlan/indicatorsGetter/db`
    );

    setIsMsgModalOpen(true);
    setNewIndicators({ ...data, value: 'db' });
  };

  const putIndicator = async () => {
    const { value, item, limitCount, price, backup, storage, read, write } =
      newIndicators;

    if (!backup) {
      console.log('backup', backup);
    }
    if (!storage) {
      console.log('storage', storage);
    }
    if (!read) {
      console.log('read', read);
    }
    if (!write) {
      console.log('write', write);
    }

    if (!limitCount) {
      setErrObj({ limitCount: '무료 제공량을 입력해주세요.' });
    }
    if (!price) {
      setErrObj({ price: '단가를 입력해주세요.' });
    }

    /**
     * @description
     * value : 뒤끝 기능 종류 (base or function / chat / match / db, 푸시는 베이스에 포함되어 있음-별도 처리 필요)
     * title : 한글 항목 명 (base only)
     * item : 영문 항목 명 (including basic or business)
     * type : 요금제 종류 (베이직 or 비즈니스)
     */

    let data;
    let newType;
    // 뒤끝 기능별 분기 처리
    newType = value;
    switch (value) {
      case 'base':
        baseIndicators[item] = {
          limitCount: parseInt(limitCount, 10),
          price: parseInt(price, 10),
        };
        if (item === 'push') {
          data = baseIndicators.push;
          newType = 'push';
        } else {
          data = baseIndicators;
        }
        data = { limitCount, price, item };
        break;
      case 'function':
        data = omitBy(newIndicators, true);
        break;
      case 'chat':
        data = omitBy(newIndicators, true);
        break;
      case 'match':
        data = omitBy(newIndicators, true);
        break;
      case 'db':
        newIndicators.backup.freePrice = new BigNumber(
          newIndicators.backup.price
        )
          .multipliedBy(newIndicators.backup.limitCount)
          .toNumber();
        newIndicators.storage.freePrice = new BigNumber(
          newIndicators.storage.price
        )
          .multipliedBy(newIndicators.storage.limitCount)
          .toNumber();
        newIndicators.read.freePrice = new BigNumber(newIndicators.read.price)
          .multipliedBy(newIndicators.read.limitCount)
          .toNumber();
        newIndicators.write.freePrice = new BigNumber(newIndicators.write.price)
          .multipliedBy(newIndicators.write.limitCount)
          .toNumber();
        delete newIndicators.value;
        data = newIndicators;
        break;

      default:
        break;
    }
    //

    dispatch(actions.indicatorsSetter({ type: newType, data }));
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        ></button>
        <LeftAsideMunu />
        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title">요금제 관리</h3>
              </div>
            </div>
          </div>

          <EditModal
            errObj={errObj}
            handleDBChange={handleDBChange}
            handleChange={handleChange}
            newIndicators={newIndicators}
            isMsgModalOpen={isMsgModalOpen}
            putIndicator={putIndicator}
            setIsMsgModalOpen={setIsMsgModalOpen}
          />

          <p className="payment subCategory">뒤끝베이스</p>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <DataTable
                      indexMethodArr={[
                        {
                          index: 1,
                          method: modifyIndicator,
                        },
                      ]}
                      customClassName="indicators"
                      clickableIndex={[1]}
                      rows={baseData}
                      columns={[
                        { label: '항목' },
                        { label: '무료 제공' },
                        { label: '단가' },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="payment subCategory">뒤끝펑션</p>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <DataTable
                      indexMethodArr={[
                        {
                          index: 1,
                          method: modifyIndicator,
                        },
                      ]}
                      clickableIndex={[1]}
                      customClassName="indicators-func"
                      rows={functionData}
                      columns={[
                        { label: '요금제' },
                        { label: '호출수 무료' },
                        { label: '호출 단가' },
                        { label: '사용 시간 무료' },
                        { label: '사용 시간 단가/1분' },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 챗 */}
          <p className="payment subCategory">뒤끝챗</p>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <DataTable
                      indexMethodArr={[
                        {
                          index: 1,
                          method: modifyIndicator,
                        },
                      ]}
                      clickableIndex={[1]}
                      customClassName="indicators-chat"
                      rows={chatData}
                      columns={[
                        { label: '요금제' },
                        { label: '기본료' },
                        { label: '네트워크 전송량 무료' },
                        { label: '네트워크 전송량 단가/1MB' },
                        { label: '채팅 로그 단가/1GB' },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 매치 */}
          <p className="payment subCategory">뒤끝매치</p>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <DataTable
                      indexMethodArr={[
                        {
                          index: 1,
                          method: modifyIndicator,
                        },
                      ]}
                      clickableIndex={[1]}
                      customClassName="indicators-match"
                      rows={matchData}
                      columns={[
                        { label: '요금제' },
                        { label: '기본료' },
                        { label: '매치메이킹 건수 무료' },
                        { label: '매치메이킹 건수 단가' },
                        { label: '룸 유지시간 무료' },
                        { label: '룸 유지시간 단가/1분' },
                        { label: '채팅 로그 단가/1GB' },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* DB 요금 */}
          <p
            className="payment subCategory clickable"
            onClick={modifyDBIndicator}
          >
            DB 요금
          </p>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <DataTable
                      customClassName="indicators"
                      rows={dbData}
                      columns={[
                        { label: '항목' },
                        { label: '무료 제공' },
                        { label: '단가' },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Indicators;
