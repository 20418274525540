import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from 'reactstrap';

import Octicon, { Plus } from '@primer/octicons-react';
import MsgModal from '../../../fnComponents/MsgModal';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { handleErrors } from '../../account/store/action';

const ShareChargeDetailModal = ({
  isOpen,
  handleOpenDetailModal,
  gameBill,
  billType,
  gameTitle,
  paidPeriod,
  isUpdateGameChargingPriceDone,
  isPay,
  onUpdateGameChargingPrice,
}) => {
  // console.log(gameBill, 'in shareChargeDetail')
  const detailColumns = [
    '날짜',
    'Android DAU',
    'iOS DAU',
    '기타 DAU',
    '무료 DAU',
    '청구 DAU',
    '금액',
  ];
  const shareDetailColumns = [
    '날짜',
    'Android 구매',
    'Android 환불',
    'iOS 구매',
    'iOS 환불',
    '합계',
  ];
  const dbDetailColumns = [
    '날짜',
    '읽기 처리량',
    '쓰기 처리량',
    '읽기 요금',
    '쓰기 요금',
    '합계',
  ];

  function rand(p_start, p_end) {
    var v_result = null;
    var v_size = p_end - p_start + 1;

    if (p_start < 0 || p_end < 0) return v_result;
    if (v_size < 2) return v_result;

    v_result = p_start + parseInt(Math.random() * v_size);

    return v_result;
  }
  function dummy(size) {
    var result = '';
    if (!size) size = 10;
    //0~9 : 48~57
    //a~z : 97~122
    //A~Z : 65~90
    result += String.fromCharCode(rand(48, 57));
    return Number(result);
  }

  const makeDummyDetailList = () => {
    let result = [];
    for (let i = 0; i < 31; i++) {
      result = [
        ...result,
        {
          inDate:
            i < 9
              ? `2019-08-0${i + 1}T00:00:00.000Z`
              : `2019-08-${i + 1}T00:00:00.000Z`,
          androidDau: dummy(1),
          etcDau: dummy(1),
          freeDau: dummy(1),
          iosDau: dummy(1),
        },
      ];
    }
    return result;
  };

  const makeColumsRow = (columns) => {
    if (columns.length)
      return columns.map((column, i) => {
        const forKey = i;
        return <th key={forKey}>{column}</th>;
      });
  };

  const [currencyModalData, handleCurrencyModalData] = useState({ data: [] });

  const makeRows = (gameBill) => {
    const { results } = gameBill;
    const handleClickGameBillRow = (obj, type) => {
      //   console.log(obj, type, "!!!");
      let arr = [];

      if (type.includes('Amount')) {
        for (const key in obj) {
          const _obj = {
            currency: key,
            amount: obj[key].amount,
            korAmount: obj[key].currency * obj[key].amount,
            rate: obj[key].currency,
          };
          arr = [...arr, _obj];
        }
      }
      if (type.includes('Refund')) {
        for (const key in obj) {
          const _obj = {
            currency: key,
            amount: obj[key].refund,
            korAmount: obj[key].currency * obj[key].refund,
            rate: obj[key].currency,
          };
          arr = [...arr, _obj];
        }
      }

      //   console.log(arr, "!!!");

      handleCurrencyModalData({
        isOpen: true,
        data: arr,
      });
    };

    if (results.length) {
      return results.map((row, i) => {
        /**
        etc: 0
        android: 0
        inDate: "2020-01-01T00:00:00.000Z"
        dau: 0
        ios: 0
        date: "2020-01-01"
        chargePrice: 0
        chargeDAU: 0
        freeDAU: 0
         *
         */
        if (billType === 'dau') {
          const { android, ios, etc, freeDAU } = row;
          const forKey = i;
          return (
            <tr key={forKey}>
              <td>{moment(row.inDate).format('DD')}일</td>
              <td>{android && android.toLocaleString()}</td>
              <td>{ios && ios.toLocaleString()}</td>
              <td>{etc && etc.toLocaleString()}</td>
              <td>
                {freeDAU ? '-' : null} {freeDAU && freeDAU.toLocaleString()}
              </td>
              <td>
                {(
                  Number(android) +
                  Number(ios) +
                  Number(etc) -
                  Number(freeDAU)
                ).toLocaleString()}
              </td>
              <td>
                {(
                  (Number(android) +
                    Number(ios) +
                    Number(etc) -
                    Number(freeDAU)) *
                  6
                ).toLocaleString()}
                원
              </td>
            </tr>
          );
        }
        if (billType === 'share') {
          const {
            androidSum,
            iosRefund,
            androidRefund,
            inDate,
            iosSum,
            iosAmount,
            androidAmount,
            totalSum,
            android,
          } = row;
          const forKey = i;
          return (
            <tr key={forKey}>
              <td>{moment(inDate).format('DD')}일</td>
              <td
                className="td-clickable-currency"
                onClick={() => handleClickGameBillRow(android, 'androidAmount')}
              >
                {androidAmount && androidAmount.toLocaleString()}원
              </td>
              <td>{androidRefund && androidRefund.toLocaleString()}원</td>
              <td>{iosAmount && iosAmount.toLocaleString()}원</td>
              <td>{iosRefund && iosRefund.toLocaleString()}원</td>
              <td>{totalSum && totalSum.toLocaleString()}원</td>
            </tr>
          );
        }
        if (billType === 'db') {
          const {
            date,
            readValue,
            readPrice,
            writeValue,
            writePrice,
            totalPrice,
          } = row;
          const forKey = i;
          return (
            <tr key={forKey}>
              <td>{moment(date).format('DD')}일</td>
              <td>{readValue && readValue.toLocaleString()}</td>
              <td>{writeValue && writeValue.toLocaleString()}</td>
              <td>{readPrice && readPrice.toLocaleString()}원</td>
              <td>{writePrice && writePrice.toLocaleString()}원</td>
              <td>
                {totalPrice &&
                  fixedNumToLocaleString(Number(totalPrice).toFixed(6))}
                원
              </td>
            </tr>
          );
        }
      });
    }
  };

  const fixedNumToLocaleString = (value) => {
    const test = Number(value);
    return test.toLocaleString();
  };

  const [gameChargingPrice, setGameChargingPrice] = useState('');
  useEffect(() => {
    if (isUpdateGameChargingPriceDone) handleControlMsgModal(false);
  }, [isUpdateGameChargingPriceDone]);
  const handleOnChangeUpdateGameChargingPrice = (e) => {
    // console.log("handle onChange UPDATE GAME-CHARGING PRICE", e.target.value);
    setGameChargingPrice(e.target.value);
  };

  const [shouldUpdateGameChargingPriceOpen, handleControlMsgModal] =
    useState(false);
  const [shouldMsgModalOpen, setMsgModal] = useState({});
  const [_isPay, set_isPay] = useState(false);

  useEffect(() => {
    if (isPay === '완납') set_isPay(true);
  }, [isPay]);

  const handlePlusGameBill = () => {
    // console.log("HERE isPay", isPay);
    if (_isPay)
      return setMsgModal({
        ...shouldMsgModalOpen,
        isPay: '완납 처리되어 청구 금액을 변경할 수 없습니다.',
      });
    handleControlMsgModal(true);
  };

  const onHandleCloseMsgModal = () => {
    handleControlMsgModal(false);
  };

  const handleCloseMsgModal = () => {
    setMsgModal({ ...shouldMsgModalOpen, isPay: '' });
  };

  const [errors, setErrors] = useState('');
  const handleErrors = () => {
    setErrors('청구 금액을 입력해주세요.');
  };

  const handleUpdateGameChargingPrice = () => {
    const billDate =
      moment().date() >= 10
        ? moment().format('YYYY-MM')
        : moment().subtract(1, 'month').format('YYYY-MM');
    if (!gameChargingPrice) handleErrors();
    onUpdateGameChargingPrice(billDate, gameChargingPrice);
  };

  const mapDataToRow = (data) => {
    return data.map((row, i) => {
      const forKey = i;
      const { currency, amount, rate, korAmount } = row;
      return (
        <tr key={forKey}>
          <td>{currency.toLocaleString()}</td>
          <td>{amount.toLocaleString()}</td>
          <td>{rate.toLocaleString()}</td>
          <td>{korAmount.toLocaleString()}</td>
        </tr>
      );
    });
  };

  const chargedBillTable = (
    <React.Fragment>
      <div className="form-group m-form__group row">
        <label className="col-lg-2 col-form-label">청구년월</label>
        <div className="col-lg-10">
          <span className="form_text">
            {moment().date() >= 10
              ? moment().format('YYYY년 MM월')
              : moment().subtract(1, 'month').format('YYYY년 MM월')}
          </span>
        </div>
      </div>
      <div className="form-group m-form__group row">
        <label className="col-lg-2 col-form-label">청구금액</label>
        <div className="col-lg-9">
          <input
            onChange={handleOnChangeUpdateGameChargingPrice}
            type="number"
            className="form-control"
            id="gameChargingPrice"
            value={gameChargingPrice}
            required
          />
          <div className="error message visible">{errors}</div>
        </div>
      </div>
    </React.Fragment>
  );

  const {
    androidSum,
    iosSum,
    totalSum,
    type,
    freePrice,
    isExceed,
    chargePrice,
    readValue,
    writeValue,
    readPrice,
    writePrice,
    totalPrice,
    chargePercent,
    minimumFee,
    changingRate,
    changingRateLimit,
    exception,
    basicFee,
  } = gameBill && gameBill.sumResults;
  return (
    <Modal size="lg" isOpen={isOpen}>
      <ModalHeader>
        {gameTitle}
        <div>{paidPeriod()}</div>
      </ModalHeader>
      <ModalBody>
        {/* 환율 모달 */}
        <Modal isOpen={currencyModalData.isOpen}>
          <ModalHeader>상세 내역</ModalHeader>
          <ModalBody>
            <Table>
              <thead className="table-header-group">
                <tr>
                  <th>통화</th>
                  <th>금액</th>
                  <th>환율</th>
                  <th>원화</th>
                </tr>
              </thead>
              <tbody>
                {currencyModalData.data.length ? (
                  mapDataToRow(currencyModalData.data)
                ) : (
                  <tr>
                    <td colSpan="4">해당일 요금이 0원 입니다.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() =>
                handleCurrencyModalData({ isOpen: false, data: [] })
              }
            >
              닫기
            </Button>
          </ModalFooter>
        </Modal>
        {/* 환율 모달 ends here */}
        <MsgModal
          isMsgModalOpen={shouldUpdateGameChargingPriceOpen}
          onHandleCloseMsgModal={onHandleCloseMsgModal}
          onConfirmAction={handleUpdateGameChargingPrice}
          confirmOrCancel
          contentsObject
          header="청구금액 입력"
          content={chargedBillTable}
          size="lg"
        ></MsgModal>
        <MsgModal
          isMsgModalOpen={!!shouldMsgModalOpen.isPay}
          onHandleCloseMsgModal={handleCloseMsgModal}
          // onConfirmAction={handleUpdateGameChargingPrice}
          // confirmOrCancel
          header={shouldMsgModalOpen.isPay}
          onlyCanClose
        />
        <Table
          className={
            billType === 'dau'
              ? `main-table bill-detail-modal dau-table`
              : billType === 'share'
              ? `main-table bill-detail-modal share-table`
              : billType === 'db'
              ? `main-table bill-detail-modal db-table`
              : null
          }
        >
          <thead className="column-6">
            {/* <tr>{makeColumsRow(detailColumns)}</tr> */}
            {billType === 'dau' && <tr>{makeColumsRow(detailColumns)}</tr>}
            {billType === 'share' && (
              <tr>{makeColumsRow(shareDetailColumns)}</tr>
            )}
            {billType === 'db' && <tr>{makeColumsRow(dbDetailColumns)}</tr>}
          </thead>
          <tbody className="column-6">{makeRows(gameBill)}</tbody>
        </Table>
        <br />
        <br />
        <Table
          className={
            billType === 'dau'
              ? `main-table bill-detail-modal-sub-table sub-dau-table`
              : billType === 'share'
              ? `main-table bill-detail-modal-sub-table sub-share-table`
              : billType === 'db'
              ? `main-table bill-detail-modal-sub-table sub-db-table`
              : null
          }
        >
          {billType === 'dau' && (
            <tbody>
              <tr>
                <td>Android DAU</td>
                <td>
                  {gameBill &&
                    gameBill.sumResults.android &&
                    gameBill.sumResults.android.toLocaleString()}
                </td>
                <td>iOS DAU</td>
                <td>
                  {gameBill &&
                    gameBill.sumResults.ios &&
                    gameBill.sumResults.ios.toLocaleString()}
                </td>
                <td>기타 DAU</td>
                <td>
                  {gameBill &&
                    gameBill.sumResults.etc &&
                    gameBill.sumResults.etc.toLocaleString()}
                </td>
              </tr>
              <tr>
                <td>무료 DAU</td>
                <td>
                  {gameBill && gameBill.sumResults.freeDAU ? '-' : null}{' '}
                  {gameBill &&
                    gameBill.sumResults.freeDAU &&
                    gameBill.sumResults.freeDAU.toLocaleString()}
                </td>
                <td>청구 DAU</td>
                <td>
                  {gameBill &&
                    gameBill.sumResults.chargeDAU &&
                    gameBill.sumResults.chargeDAU.toLocaleString()}
                </td>
                <td>청구금액</td>
                <td>
                  {gameBill &&
                    gameBill.sumResults.chargePrice &&
                    gameBill.sumResults.chargePrice.toLocaleString()}
                  원
                </td>
              </tr>
            </tbody>
          )}
          {billType === 'share' && (
            <tbody>
              {type !== 'Enterprise' ? (
                <React.Fragment>
                  <tr>
                    <td>Android 수익</td>
                    <td>{androidSum && androidSum.toLocaleString()}원</td>
                    <td>iOS 수익</td>
                    <td>{iosSum && iosSum.toLocaleString()}원</td>
                    <td>총 수익</td>
                    <td>{totalSum && totalSum.toLocaleString()}원</td>
                  </tr>
                  <tr>
                    <td>적용요율</td>
                    <td>
                      {type === 'Silver'
                        ? '7%'
                        : type === 'Gold'
                        ? '4%'
                        : type === 'Diamond'
                        ? '2%'
                        : '-'}
                    </td>
                    <td>무료/과금 여부</td>
                    <td>{isExceed === 'n' ? '무료' : '과금'}</td>
                    {/* 롤백 예정 */}
                    <td>청구금액</td>
                    {/* <td>청구금액
                <button onClick={handlePlusGameBill} className="plus-gameBill">
                    <Octicon className="plus-gameBill" size='small' verticalAlign='middle' icon={Plus} ariaLabel="Add new item" />
                  </button>
                </td> */}
                    <td>{chargePrice && chargePrice.toLocaleString()}원</td>
                  </tr>
                </React.Fragment>
              ) : null}
              {type === 'Enterprise' ? (
                <React.Fragment>
                  <tr>
                    <td>Android 수익</td>
                    <td>{androidSum && androidSum.toLocaleString()}원</td>
                    <td>iOS 수익</td>
                    <td>{iosSum && iosSum.toLocaleString()}원</td>
                    <td>총 수익</td>
                    <td>{totalSum && totalSum.toLocaleString()}원</td>
                  </tr>
                  <tr>
                    <td>적용요율</td>
                    <td>{chargePercent}%</td>
                    <td>무료/과금 여부</td>
                    <td>{isExceed === 'n' ? '무료' : '과금'}</td>
                    <td>최저요금</td>
                    <td>{minimumFee && minimumFee.toLocaleString()}원</td>
                  </tr>
                  <tr className="last-row-enterprise-sumResult-table">
                    <td>변동요율</td>
                    <td>{changingRate}%</td>
                    <td>변동기준</td>
                    <td>
                      {changingRateLimit && changingRateLimit.toLocaleString()}
                      원
                    </td>
                    <td>기본요금</td>
                    <td>{basicFee && basicFee.toLocaleString()}원</td>
                    <td>
                      청구금액
                      {/* <button onClick={handlePlusGameBill} className="plus-gameBill">
                    <Octicon className="plus-gameBill" size='small' verticalAlign='middle' icon={Plus} ariaLabel="Add new item" />
                  </button> */}
                      {/* 롤백 예정 */}
                      {exception && (
                        <button
                          onClick={handlePlusGameBill}
                          className="plus-gameBill"
                        >
                          <Octicon
                            className="plus-gameBill"
                            size="small"
                            verticalAlign="middle"
                            icon={Plus}
                            ariaLabel="Add new item"
                          />
                        </button>
                      )}
                    </td>
                    <td>{chargePrice && chargePrice.toLocaleString()}원</td>
                  </tr>
                </React.Fragment>
              ) : null}
            </tbody>
          )}
          {billType === 'db' && (
            <tbody>
              <tr>
                <td>
                  읽기
                  <br />
                  처리량
                </td>
                <td>{readValue && readValue.toLocaleString()}</td>
                <td>
                  쓰기
                  <br />
                  처리량
                </td>
                <td>{writeValue && writeValue.toLocaleString()}</td>
                <td>
                  읽기
                  <br />
                  요금
                </td>
                <td>
                  {/* {Number(readPrice)
                    .toFixed(6)
                    .toLocaleString()} */}
                  {fixedNumToLocaleString(Number(readPrice).toFixed(6))}원
                </td>
                <td>
                  쓰기
                  <br />
                  요금
                </td>
                <td>
                  {/* {Number(writePrice)
                    .toFixed(6)
                    .toLocaleString()} */}
                  {fixedNumToLocaleString(Number(writePrice).toFixed(6))}원
                </td>
              </tr>
              <tr>
                <td>
                  요금
                  <br />
                  합계
                </td>
                <td>
                  {/* {Number(totalPrice).toFixed(6)} */}
                  {fixedNumToLocaleString(Number(totalPrice).toFixed(6))}원
                </td>
                <td>
                  원 미만
                  <br />
                  절사
                </td>
                <td>{totalPrice && parseInt(totalPrice).toLocaleString()}원</td>
                {/* <td>{totalPrice && parseInt(totalPrice.toString())}</td> */}
                <td>
                  무료
                  <br />
                  제공
                </td>
                <td>
                  {freePrice ? '-' : null}{' '}
                  {freePrice && freePrice.toLocaleString()}원
                </td>
                <td>
                  청구
                  <br />
                  금액
                </td>
                <td>{chargePrice && chargePrice.toLocaleString()}원</td>
              </tr>
            </tbody>
          )}
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => handleOpenDetailModal(false)}>
          닫기
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ShareChargeDetailModal;
