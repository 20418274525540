/*
 * Copyright 2013-2022 AFI, Inc. All Rights Reserved.
 */

// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Octicon, { TriangleLeft, TriangleRight } from '@primer/octicons-react';
import defaults from 'lodash/defaults';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import range from 'lodash/range';
import classNames from 'classnames';

import MsgModal from '../../fnComponents/MsgModal';

import * as actions from './store/action';
import * as selectors from './store/selector';

import Datatable from '../../fnComponents/_DataTable';
import moment from 'moment';
import { globalMsgModalOpen } from '../globalStore/action';
import BigNumber from 'bignumber.js';

const DiscountModal = ({ rows, checkRow }) => {
  const discountModalOpen = useSelector(selectors.discountModalOpenSelector);
  const dispatch = useDispatch();

  const today = new Date();

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth() + 1);

  const [discountData, setDiscountData] = useState({});

  const defaultdiscountData = useMemo(
    () => ({
      [`${year}-01`]: 0,
      [`${year}-02`]: 0,
      [`${year}-03`]: 0,
      [`${year}-04`]: 0,
      [`${year}-05`]: 0,
      [`${year}-06`]: 0,
      [`${year}-07`]: 0,
      [`${year}-08`]: 0,
      [`${year}-09`]: 0,
      [`${year}-10`]: 0,
      [`${year}-11`]: 0,
      [`${year}-12`]: 0,
    }),
    [year, checkRow]
  );

  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    if (discountModalOpen && checkRow.length === 1) {
      // 대상 프로젝트가 하나일 땐 기존값을 보여줌
      setDiscountData(
        mapValues(
          rows[checkRow[0]].discountRate || defaultdiscountData,
          (value) => value * 100
        )
      );
    } else if (discountModalOpen && checkRow.length > 1) {
      const filtered = rows.filter((row, i) => checkRow.includes(i));

      // 이동
      if (yearMonths) {
        const arr = [];
        const res = filtered.map((row, i) => {
          const res2 = {
            title: row.title,
          };
          yearMonths.forEach((month, i) => {
            const rate = row.discountRate && row.discountRate[month.value];

            res2[month.value] = rate
              ? new BigNumber(rate).multipliedBy(100).toNumber()
              : 0;
            if (rate) {
              arr.push({ [month.value]: rate });
            }
          });

          newDiscountData.current[row.id] = res2;
          return res2;
        });
        setFiltered(res);
      }
    } else {
      setDiscountData({});
    }
  }, [discountModalOpen, rows, checkRow, defaultdiscountData]);

  const yearMonths = Array(12)
    .fill(1)
    .map((one, i) => ({
      label: `${one + i}월`,
      value: `${year}-${one + i < 10 ? `0${one + i}` : `${one + i}`}`,
    }));

  // const allDiscounts = useRef([]);
  const newDiscountData = useRef({});

  // const memoFiltered = useMemo(() => {
  //   if (yearMonths) {
  //     return filtered.map(
  //       (row, i) => {
  //         const res = {
  //           title: row.title,
  //         };
  //         yearMonths.forEach((month, i) => {
  //           console.log('err chk 1', row.discountRate);
  //           console.log('err chk 1', month);
  //           const rate = row.discountRate[month.value];
  //           res[month.value] = rate
  //             ? `${new BigNumber(rate).multipliedBy(100)}`
  //             : '0';
  //           // allDiscounts.current = rate
  //           //   ? [...allDiscounts.current, month.value]
  //           //   : allDiscounts.current;
  //         });

  //         newDiscountData.current[row.id] = res;
  //         return res;
  //       },
  //       [filtered]
  //     );
  //   }
  // });

  const toggleDiscountOpen = () => dispatch(actions.toggleDiscountOpen());

  const handleSubmit = () => {
    let filteredDiscountData = {};
    let hasMultiple = false;
    const rowIds = checkRow.map((index) => rows[index].id);

    if (checkRow.length > 1) {
      Object.entries(newDiscountData.current)
        .map(([gameId, org]) => ({
          [gameId]: mapValues(
            defaults(
              mapValues(
                pickBy(discountData, (value, key) => key.startsWith(year)),
                (value) => value
              ),
              org
            ),
            (val, key) => {
              if (key === 'title') return;
              return val / 100;
            }
          ),
        }))
        .forEach((item) => {
          filteredDiscountData = { ...filteredDiscountData, ...item };
        });
      hasMultiple = true;
    } else {
      // 모달에 보이는 연도에 대해서만 필터링
      filteredDiscountData = defaults(
        mapValues(
          pickBy(discountData, (value, key) => key.startsWith(year)),
          (value) => value / 100
        ),
        defaultdiscountData
      );
    }

    dispatch(
      actions.postDiscount({
        gameIds: rowIds,
        discountRate: filteredDiscountData,
        hasMultiple,
      })
    );
    toggleDiscountOpen();
    setYear(today.getFullYear());
    setMonth(today.getMonth() + 1);
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;

    setDiscountData((prevDiscountData) => {
      const newDiscountData = { ...prevDiscountData };
      newDiscountData[name] = parseInt(value, 10);
      return newDiscountData;
    });
  };

  if (!checkRow.length) {
    return (
      <MsgModal
        isMsgModalOpen={discountModalOpen}
        onHandleCloseMsgModal={toggleDiscountOpen}
        onlyCanClose
        contentsObject
        content="할인 적용할 프로젝트를 선택해 주세요."
        size="lg"
      />
    );
  }

  if (checkRow.length > 1) {
    return (
      <MsgModal
        size="lg"
        onHandleCloseMsgModal={toggleDiscountOpen}
        onConfirmAction={() =>
          dispatch(
            globalMsgModalOpen({
              globalMsgModalOpen: true,
              globalMessage: '할인을 적용 하시겠습니까?',
              confirmAction: handleSubmit,
            })
          )
        }
        confirmOrCancel
        contentsObject
        header="할인 적용"
        isMsgModalOpen={discountModalOpen}
        content={
          <>
            <div className="form-group m-form__group row">
              <label className="col-lg-2 col-form-label">대상 프로젝트</label>
              <div className="col-lg-3 form-view">{checkRow.length}</div>
            </div>

            <div>
              <Datatable
                customClassName="year-table"
                isLast
                columns={[{ label: '프로젝트' }, ...yearMonths]}
                rows={filtered}
              />
            </div>

            <div className="flex-container year-month">
              <div className="flex-item year-month">
                <span
                  onClick={
                    year > today.getFullYear()
                      ? () => setYear((prevYear) => prevYear - 1)
                      : null
                  }
                >
                  <Octicon
                    className={classNames('octicon', {
                      disabled: year <= today.getFullYear(),
                    })}
                    icon={TriangleLeft}
                    size={24}
                  />
                </span>
                <h4 className="discountModalYear">{year}년</h4>
                <span onClick={() => setYear((prevYear) => prevYear + 1)}>
                  <Octicon icon={TriangleRight} size={24} />
                </span>
              </div>

              <div className="flex-item year-month">
                <span
                  onClick={() => {
                    if (
                      year === today.getFullYear() &&
                      month <= today.getMonth() + 1
                    )
                      return null;
                    else if (month < 2) {
                      setYear((prev) => prev - 1);
                      setMonth(12);
                      return;
                    }
                    setMonth((prevMonth) => prevMonth - 1);
                  }}
                >
                  <Octicon
                    className={classNames('octicon', {
                      disabled:
                        month <= today.getMonth() + 1 &&
                        year === today.getFullYear(),
                    })}
                    icon={TriangleLeft}
                    size={24}
                  />
                </span>
                <h4 className="discountModalYear">{month}월</h4>
                <span
                  onClick={() =>
                    setMonth((prevMonth) => {
                      if (month < 12) return prevMonth + 1;
                      else if (month === 12) {
                        setYear((prev) => prev + 1);
                      }
                      return (prevMonth + 1) % 12;
                    })
                  }
                >
                  <Octicon icon={TriangleRight} size={24} />
                </span>
              </div>

              <div className="flex-item year-month">
                <input
                  name={`${year}-${month.toString().padStart(2, '0')}`}
                  type="number"
                  min={0}
                  max={100}
                  onChange={handleChange}
                />
              </div>
            </div>
          </>
        }
      />
    );
  }

  return (
    <MsgModal
      isMsgModalOpen={discountModalOpen}
      onHandleCloseMsgModal={toggleDiscountOpen}
      onConfirmAction={() =>
        dispatch(
          globalMsgModalOpen({
            globalMsgModalOpen: true,
            globalMessage: '할인을 적용 하시겠습니까?',
            confirmAction: handleSubmit,
          })
        )
      }
      confirmOrCancel
      contentsObject
      header="할인 적용"
      content={
        <>
          <div className="form-group m-form__group row">
            <label className="col-lg-2 col-form-label">대상 프로젝트</label>
            <div className="col-lg-3 form-view">{checkRow.length}</div>
          </div>

          <div>
            <span
              onClick={
                year > today.getFullYear()
                  ? () => setYear((prevYear) => prevYear - 1)
                  : null
              }
            >
              <Octicon
                className={classNames('octicon', {
                  disabled: year <= today.getFullYear(),
                })}
                icon={TriangleLeft}
                size={24}
              />
            </span>
            <h4 className="discountModalYear">{year}년</h4>
            <span onClick={() => setYear((prevYear) => prevYear + 1)}>
              <Octicon icon={TriangleRight} size={24} />
            </span>
          </div>

          <form className="discountModalMonthForm">
            {range(12).map((i) => {
              const dateStr = `${year}-${(i + 1).toString().padStart(2, '0')}`;

              return (
                <div key={i} className="form-group m-form__group row">
                  <label className="col-lg-2 col-form-label" htmlFor={dateStr}>
                    {i + 1}월
                  </label>
                  <div className="col-lg-3 form-view">
                    <input
                      id={dateStr}
                      name={dateStr}
                      type="number"
                      value={discountData[dateStr] || 0}
                      min={0}
                      max={100}
                      disabled={
                        year < today.getFullYear() ||
                        (year === today.getFullYear() && i < today.getMonth())
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
              );
            })}
          </form>
        </>
      }
      size="lg"
    />
  );
};

export default DiscountModal;
