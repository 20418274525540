import { LOCATION_CHANGE } from 'react-router-redux';

// import * as selectors from './selector';
import * as types from './constant';

const initialState = {
	emailList: [],
	_emailList: {},
	columns: [
		{ label: '제목' },
		{ label: '발송대상' },
		{ label: '발송일시' },
		{ label: '성공/실패' },
		{ label: '발송상태' },
		{ label: '' },
	],
	isNotAdminColumns: [
		{ label: '제목' },
		{ label: '발송대상' },
		{ label: '발송일시' },
		{ label: '수신 상태' },
		{ label: '발송상태' },
		{ label: '' },
	],
	page: 1,
	_lastEvaluatedKey: {
		0: null,
	},
	isAdmin: true,
	sentMailDetails: {
		details: {},
		_lastEvaluatedKey: {
			0: null,
		},
		page: 0,
	},
};

function emailList(state = initialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return {
				...initialState,
			};

		case types.HANDLE_IS_ADMIN:
			return {
				...state,
				isAdmin: action.payload,
				columns: action.payload
					? initialState.columns
					: initialState.isNotAdminColumns,
				_emailList: initialState._emailList,
			};

		case types.HANDLE_IS_ADMIN_SUCCESS:
			return {
				...state,
				_emailList: {
					[initialState.page]: action.payload.Items,
				},
				lastEvaluatedKey: action.payload.LastEvaluatedKey,
				_lastEvaluatedKey: {
					[initialState.page]: action.payload.LastEvaluatedKey,
				},
			};

		case types.GET_SENT_MAILS:
			return {
				...state,
				toRefresh: action.toRefresh,
			};
		case types.GET_SENT_MAILS_SUCCESS:
			// toRefresh 밸류가 true이면 특정 페이지(action.page)를 갱신해야 한다
			if (state.toRefresh) {
				return {
					...state,
					_emailList: {
						...state._emailList,
						[action.page]: action.payload.Items,
					},
					deleteSentMailSuccess: false,
				};
			}
			return {
				...state,
				_emailList: { ...state._emailList, [state.page]: action.payload.Items },
				page: state.page + 1,
				lastEvaluatedKey: action.payload.LastEvaluatedKey,
				_lastEvaluatedKey: {
					...state._lastEvaluatedKey,
					[state.page]: action.payload.LastEvaluatedKey,
				},
				deleteSentMailSuccess: false,
			};

		case types.GET_SENT_MAIL_DETAILS:
			return {
				...state,
				sentMailDetails: action.query
					? state.sentMailDetails
					: initialState.sentMailDetails,
				selectedInDate: action.inDate,
			};

		case types.SEARCH_SENT_MAIL_DETAILS:
			return {
				...state,
				sentMailDetails: initialState.sentMailDetails,
				// selectedInDate: action.inDate,
			};

		case types.GET_SENT_MAIL_DETAILS_SUCCESS:
			// action.paging
			return {
				...state,
				sentMailDetails: {
					details: {
						[action.paging && action.paging.prev
							? state.sentMailDetails.page - 1
							: state.sentMailDetails.page + 1]: action.payload.Items,
					},
					_lastEvaluatedKey:
						action.paging && action.paging.prev
							? state.sentMailDetails._lastEvaluatedKey
							: {
									...state.sentMailDetails._lastEvaluatedKey,
									[state.sentMailDetails.page + 1]:
										action.payload.LastEvaluatedKey,
							  },
					page:
						action.paging && action.paging.prev
							? state.sentMailDetails.page - 1
							: state.sentMailDetails.page + 1,
				},
			};

		case types.SEARCH_SENT_MAIL_DETAILS_SUCCESS:
			return {
				...state,
				sentMailDetails: {
					details: {
						[state.sentMailDetails.page + 1]: action.payload.Items,
					},
					_lastEvaluatedKey: {
						...state.sentMailDetails._lastEvaluatedKey,
						[state.sentMailDetails.page + 1]: action.payload.LastEvaluatedKey,
					},
					page: state.sentMailDetails.page + 1,
				},
			};

		case types.DELETE_SENT_MAIL_SUCCESS:
			return {
				...state,
				deleteSentMailSuccess: true,
			};
		default:
			return state;
	}
}

export default emailList;
