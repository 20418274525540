import { call, put, takeLatest, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import clone from 'ramda/src/clone';
import moment from 'moment';

import * as types from './constant';

import * as actions from './action';
import { openSubMenu, handleClickSubMenu } from '../../../../view/store/action';
import * as selectors from './selector';
import * as api from '../../../../service/API';

function* locationChange({ payload }) {
	try {
		const { pathname } = yield payload;
		const pathArray = pathname.split('/');

		if (pathArray[1] === 'retention') {
			yield put(openSubMenu('statistics'));
			yield put(handleClickSubMenu('retention'));
			yield put(actions.initRetention());
			// yield call(api.retention.getRetention, gameId, date)
		}
	} catch (err) {
		console.log('LOCATION_CHANGE err', err);
	}
}

export function* watcherLocationChange() {
	yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* getRetention({ payload }) {
	try {
		// const { gameId, date } = payload;
		const searchKeywords = yield select(selectors.searchKeywordsSelector);

		console.log(searchKeywords);
		const { title, id, inDate } = searchKeywords;
		// inDate는 항상 존재해야 함 (선택 안 했을 때는 오늘 날짜 기준)
		let gameId = '';
		let date = '';
		// 프로젝트명으로만 검색 --> gameId를 리턴
		if (title && !id) {
			const { data } = yield call(api.projects.getProjects, { title });
			console.log(data);
			if (data.projectList.length === 1) {
				gameId = data.projectList[0].projectId;
			} else if (data.projectList.length > 1) {
				yield put(actions.getProjectsSuccess(data));
				return;
			}
		}
		if (!title && id) {
			gameId = id;
		}

		if (!inDate) {
			date = moment().format('YYYY-MM-DD');
		} else {
			date = moment(inDate).format('YYYY-MM-DD');
		}

		const { data } = yield call(api.retention.getRetention, gameId, date);
		yield put(actions.getRetentionSuccess(data));
	} catch (err) {
		console.log('GET_RETENTION err', err);
	}
}

export function* watcherGetRetention() {
	yield takeLatest(types.GET_RETENTION, getRetention);
}

function* getPJTRetention({ gameId, date }) {
	try {
		const { data } = yield call(api.retention.getRetention, gameId, date);
		yield put(actions.getRetentionSuccess(data, 'specificPJT'));
	} catch (err) {
		console.log('GET_PROJECT_RETENTION err', err);
	}
}

export function* watcherGetPJTRetention() {
	yield takeLatest(types.GET_PROJECT_RETENTION, getPJTRetention);
}
