// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import { fork, all } from 'redux-saga/effects';

import accountManagingSaga from '../containers/account/store/saga';
import * as sleepingSaga from '../containers/account/sleeping/store/saga';
import * as chargedBillSaga from '../containers/payment/ChargedBill/store/saga';
import * as overdueBillSaga from '../containers/payment/OverdueBill/store/saga';
import * as loginSaga from '../containers/login/store/saga';
import * as customersSaga from '../containers/stat/customers/store/saga';
import * as projectsSaga from '../containers/stat/projects/store/saga';
import * as salesSaga from '../containers/stat/sales/store/saga';
import * as theBackendSalesSaga from '../containers/stat/theBackendSales/store/saga';
import * as retentionSaga from '../containers/stat/retention/store/saga';
import * as projectListSaga from '../containers/projects/store/saga';
import * as noticeListSaga from '../containers/notice/store/saga';
import * as consolePopupSaga from '../containers/popup/store/saga';
import * as emailsSaga from '../containers/emails/store/saga';
import * as sendEmailSaga from '../containers/sendEmail/store/saga';
import * as emailListSaga from '../containers/emailList/store/saga';
import * as currentChargeSaga from '../containers/payment/CurrentCharge/store/saga';
import * as currentChargePayPerUseSaga from '../containers/payment/CurrentChargePayPerUse/store/saga';
import * as couponSaga from '../containers/coupon/store/saga';
import * as showcaseSaga from '../containers/landing/showcase/store/saga';
import * as popupSaga from '../containers/landing/popup/store/saga';
import * as termsSaga from '../containers/landing/terms/store/saga';
import * as privacySaga from '../containers/landing/privacy/store/saga';
import * as slaSaga from '../containers/landing/sla/store/saga';
import * as inquirySaga from '../containers/landing/inquiry/store/saga';
import * as inquiryGDCSaga from '../containers/landing/inquiryGDC/store/saga';
import developerSaga from '../containers/developer/store/saga';
import * as getStartedSaga from '../containers/developer/getStarted/store/saga';
import * as consoleGuide from '../containers/developer/consoleGuide/store/saga';
import * as sdkDocs from '../containers/developer/sdkDocs/store/saga';
import * as versionManaging from '../containers/developer/versionManaging/store/saga';
import * as indicators from '../containers/indicators/store/saga';
import dashboardSaga from '../containers/dashboard/store/saga';
import dataExtractionSaga from '../containers/dataExtraction/store/saga';

export default function* rootSaga() {
  yield all([
    // 로그인 처리
    fork(loginSaga.watcherHandleLogin),
    fork(loginSaga.watcherHandleLogout),
    fork(loginSaga.watcherLocationChange),
    // 대시보드
    // fork(dashboardSaga.watcherLocationChange),
    ...dashboardSaga,
    ...dataExtractionSaga,
    fork(accountManagingSaga),
    // 청구 내역
    // fork(chargedBillSaga.watcherGetChargedBillRow),
    // 연체 내역
    fork(overdueBillSaga.watcherGetOverdueBill),
    fork(overdueBillSaga.watcherInitOverdueBillList),
    // 		통계 - 종합지표,
    fork(customersSaga.watcherLocationChange),
    fork(customersSaga.watcherGetMonthlyStatistics),
    fork(customersSaga.watcherGetDailyStatistics),
    fork(customersSaga.watcherChangeStatType),
    fork(customersSaga.watcherSearchStatistics),
    fork(customersSaga.watcherShowSpecificProjectInfo),
    // 		통계 - 프로젝트
    fork(projectsSaga.watcherLocationChange),
    fork(projectsSaga.watcherHandleSearchProject),
    fork(projectsSaga.watcherGetDailyStatisticsProjects),
    fork(projectsSaga.watcherGetMonthlyStatisticsProjects),
    fork(projectsSaga.watcherClickProjectRow),
    // 통계 - 거래액
    fork(salesSaga.WatcherGetDailyStatByPjt),
    fork(salesSaga.watcherLocationChange),
    // 통계 - 매출
    fork(theBackendSalesSaga.WatcherGetTheBackendSales),
    fork(theBackendSalesSaga.watcherLocationChange),
    fork(theBackendSalesSaga.WatcherGetNextTheBackendSales),
    // 통계 - 리텐션
    fork(retentionSaga.watcherLocationChange),
    fork(retentionSaga.watcherGetRetention),
    fork(retentionSaga.watcherGetPJTRetention),
    // 프로젝트
    fork(projectListSaga.watcherLocationChange),
    fork(projectListSaga.watcherInitProjectList),
    fork(projectListSaga.watcherGetProjectList),
    fork(projectListSaga.watcherGetProjectStatus),
    fork(projectListSaga.watcherConfirmChangeStatus),
    fork(projectListSaga.watcherHandleUpdatePayPlan),
    fork(projectListSaga.watcherGetProject),
    fork(projectListSaga.watcherGetDeletedProjects),
    fork(projectListSaga.watcherUpdateGameReleasingData),
    fork(projectListSaga.watcherGetRatePlan),
    fork(projectListSaga.watcherPutRatePlan),
    fork(projectListSaga.watcherPostDiscount),
    // 공지사항 관리
    fork(noticeListSaga.watcherLocationChange),
    fork(noticeListSaga.watcherGetNoticeList),
    fork(noticeListSaga.watcherPostCreateNotice),
    fork(noticeListSaga.watcherClickNoticeDelete),
    // 팝업 관리
    fork(consolePopupSaga.watcherLocationChange),
    fork(consolePopupSaga.watcherInitPopupList),
    fork(consolePopupSaga.watcherGetPopupList),
    fork(consolePopupSaga.watcherInsertPopup),
    fork(consolePopupSaga.watcherUpdatePopup),
    fork(consolePopupSaga.watcherDeletePopup),
    // 이메일 관리
    fork(emailsSaga.watcherLocationChange),
    fork(emailsSaga.watcherGetEmailList),
    fork(emailsSaga.watcherUpdateEmailUsing),
    fork(emailsSaga.watcherGetEmail),
    fork(emailsSaga.watcherUpdateEmail),
    fork(sendEmailSaga.watcherLocationChange),
    fork(sendEmailSaga.watcherGetSendToCount),
    fork(sendEmailSaga.watcherSendEmail),
    fork(sendEmailSaga.watcherSendTestEmail),
    fork(sendEmailSaga.watcherReadFiles),
    fork(emailListSaga.watcherLocationChange),
    fork(emailListSaga.watcherGetSentMails),
    fork(emailListSaga.watcherGetSentMailDetails),
    fork(emailListSaga.watcherDeleteSentMail),
    fork(emailListSaga.watcherHandleIsAdmin),
    fork(emailListSaga.watcherSearchSentMailDetails),
    // 현재요금 - 수익쉐어
    fork(currentChargeSaga.watcherInitCurrentChargeList),
    fork(currentChargeSaga.watcherGetCurrentBill),
    fork(currentChargeSaga.watcherGetCurrentAccountBill),
    fork(currentChargeSaga.watcherClickCurrentChargeRow),
    fork(currentChargeSaga.watcherGetCurrentGameBill),
    fork(currentChargeSaga.watcherAddExtraBill),
    fork(currentChargeSaga.watcherUpdateExtraBill),
    fork(currentChargeSaga.watcherDeleteExtraBill),
    // 현재요금 - 수익쉐어
    fork(currentChargePayPerUseSaga.watcherInitCurrentChargeList),
    fork(currentChargePayPerUseSaga.watcherGetCurrentBill),
    fork(currentChargePayPerUseSaga.watcherGetCurrentAccountBill),
    fork(currentChargePayPerUseSaga.watcherClickCurrentChargeRow),
    fork(currentChargePayPerUseSaga.watcherGetCurrentGameBill),
    fork(currentChargePayPerUseSaga.watcherAddExtraBill),
    fork(currentChargePayPerUseSaga.watcherUpdateExtraBill),
    fork(currentChargePayPerUseSaga.watcherDeleteExtraBill),
    // 청구내역
    fork(chargedBillSaga.watcherInitChargedBillList),
    fork(chargedBillSaga.watcherGetBill),
    fork(chargedBillSaga.watcherClickChargedBillRow),
    fork(chargedBillSaga.watcherGetAccountBill),
    fork(chargedBillSaga.watcherGetGameBill),
    fork(chargedBillSaga.watcherGetGamePPUBill),
    fork(chargedBillSaga.watcherUpdateAccountBill),
    fork(chargedBillSaga.watcherUpdateGameChargingPrice),
    // 쿠폰
    fork(couponSaga.watcherLocationChange),
    fork(couponSaga.watcherGetCouponList),
    fork(couponSaga.watcherGetCouponDetails),
    fork(couponSaga.watcherIssueCoupon),
    fork(couponSaga.watcherInitCouponList),
    fork(couponSaga.watcherDeleteCoupon),
    // 쇼케이스
    fork(showcaseSaga.watcherLocationChange),
    fork(showcaseSaga.watcherGetShowcaseList),
    fork(showcaseSaga.watcherGetMoreShowcaseList),
    fork(showcaseSaga.watcherUpdateShowcase),
    fork(showcaseSaga.watcherUpdateShowcaseOrder),
    fork(showcaseSaga.watcherInsertShowcase),
    fork(showcaseSaga.watcherDeleteShowcase),
    fork(showcaseSaga.watcherInitShowcaseList),
    // 팝업
    fork(popupSaga.watcherLocationChange),
    fork(popupSaga.watcherGetPopupList),
    fork(popupSaga.watcherUpdatePopup),
    fork(popupSaga.watcherInsertPopup),
    fork(popupSaga.watcherDeletePopup),
    fork(popupSaga.watcherInitPopupList),
    // 이용약관
    fork(termsSaga.watcherLocationChange),
    fork(termsSaga.watcherGetTermsPolicy),
    fork(termsSaga.watcherUpdateTermsPolicy),
    // 개인정보보호
    fork(privacySaga.watcherLocationChange),
    fork(privacySaga.watcherGetPrivacyPolicy),
    fork(privacySaga.watcherUpdatePrivacyPolicy),
    // SLA
    fork(slaSaga.watcherLocationChange),
    fork(slaSaga.watcherGetSLAPolicy),
    fork(slaSaga.watcherUpdateSLAPolicy),
    // 문의 관리
    fork(inquirySaga.watcherLocationChange),
    fork(inquirySaga.watcherGetInquiriesList),
    fork(inquirySaga.watcherSendResponse),
    fork(inquirySaga.watcherDeleteInquiry),
    fork(inquiryGDCSaga.watcherLocationChange),
    fork(inquiryGDCSaga.watcherGetInquiriesList),
    fork(inquiryGDCSaga.watcherSendResponse),
    fork(inquiryGDCSaga.watcherDeleteInquiry),
    fork(developerSaga),
    // 개발자 어드민 - 시작하기
    fork(getStartedSaga.watcherLocationChange),
    fork(getStartedSaga.watcherInitTopics),
    fork(getStartedSaga.watcherGetTopics),
    fork(getStartedSaga.watcherMakeClass),
    fork(getStartedSaga.watcherUpdateClass),
    fork(getStartedSaga.watcherDeleteClass),
    fork(getStartedSaga.watcherMakeDocument),
    fork(getStartedSaga.watcherGetList),
    fork(getStartedSaga.watcherGetClassParentList),
    fork(getStartedSaga.watcherUpdateOrder),
    fork(getStartedSaga.watcherGetDocument),
    fork(getStartedSaga.watcherUpdateDocument),
    fork(getStartedSaga.watcherDeleteDocument),
    fork(getStartedSaga.watcherMakeTopic),
    fork(getStartedSaga.watcherUpdateTopic),
    fork(getStartedSaga.watcherDeleteTopic),
    // 개발자 어드민 - 콘솔 가이드
    fork(consoleGuide.watcherLocationChange),
    fork(consoleGuide.watcherInitTopics),
    fork(consoleGuide.watcherGetTopics),
    fork(consoleGuide.watcherMakeClass),
    fork(consoleGuide.watcherUpdateClass),
    fork(consoleGuide.watcherDeleteClass),
    fork(consoleGuide.watcherMakeDocument),
    fork(consoleGuide.watcherGetList),
    fork(consoleGuide.watcherGetClassParentList),
    fork(consoleGuide.watcherUpdateOrder),
    fork(consoleGuide.watcherGetDocument),
    fork(consoleGuide.watcherUpdateDocument),
    fork(consoleGuide.watcherDeleteDocument),
    fork(consoleGuide.watcherMakeTopic),
    fork(consoleGuide.watcherUpdateTopic),
    fork(consoleGuide.watcherDeleteTopic),
    // 개발자 어드민 - SDk문서
    fork(sdkDocs.watcherLocationChange),
    fork(sdkDocs.watcherInitTopics),
    fork(sdkDocs.watcherGetTopics),
    fork(sdkDocs.watcherMakeClass),
    fork(sdkDocs.watcherUpdateClass),
    fork(sdkDocs.watcherUpdateTopic),
    fork(sdkDocs.watcherDeleteClass),
    fork(sdkDocs.watcherMakeDocument),
    fork(sdkDocs.watcherGetList),
    fork(sdkDocs.watcherGetClassParentList),
    fork(sdkDocs.watcherUpdateOrder),
    fork(sdkDocs.watcherGetDocument),
    fork(sdkDocs.watcherUpdateDocument),
    fork(sdkDocs.watcherDeleteDocument),
    fork(sdkDocs.watcherMakeTopic),
    // 업데이트노트
    fork(versionManaging.watcherLocationChange),
    fork(versionManaging.watcherInitVersionList),
    fork(versionManaging.watcherGetVersionList),
    fork(versionManaging.watcherMakeVersion),
    fork(versionManaging.watcherUpdateVersion),
    fork(versionManaging.watcherDeleteVersion),
    //
    fork(sleepingSaga.watcherLocationChange),
    fork(sleepingSaga.watcherInitSleepingCandidates),
    fork(sleepingSaga.watcherGetSleepingCandidates),
    fork(sleepingSaga.watcherInitSleepingAccounts),
    fork(sleepingSaga.watcherGetSleepingAccounts),
    fork(sleepingSaga.watcherMakeSleepingCandidateNot),
    fork(sleepingSaga.watcherMakeSleepingAccountNot),
    // indicators
    fork(indicators.watcherIndicatorsGetter),
    fork(indicators.watcherIndicatorsSetter),
    fork(indicators.watcherIndicatorsPutter),
    fork(indicators.watcherLocationChange),
  ]);
}
