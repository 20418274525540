import * as types from "./constant";

// export function initGameStat(gameId, date, year) {
// 	return {
// 		type: types.INIT_GAME_STAT,
// 		gameId,
// 		date,
// 		year,
// 		MAU: false,
// 	};
// }

export function clickCouponDetail(id, arr, param) {
  return {
    type: types.CLICK_COUPON_DETAIL_ROW,
    id,
    arr,
    param,
  };
}

export function handleCloseCouponDetailModal() {
  return {
    type: types.HANDLE_CLOSE_COUPON_DETAIL_MODAL,
  };
}

export function clickCouponDelete(id, arr) {
  return {
    type: types.CLICK_COUPON_DELETE,
    id,
    arr,
  };
}

export function getOverdueManageRowSuccess(overdueBillInfo) {
  return {
    type: types.GET_OVERDUE_MANAGE_ROW_SUCCESS,
    overdueBillInfo,
  };
}

export function handleCloseMsgModal() {
  return {
    type: types.HANDLE_CLOSE_MSG_MODAL,
  };
}

export function confirmChangeStatus(value) {
  return {
    type: types.CONFIRM_CHANGE_STATUS,
    value,
  };
}

export function initAccountList(gameId, date) {
  return {
    type: types.INIT_ACCOUNT_LIST,
    gameId,
    date,
  };
}
export function initDeletedAccountList(gameId, date) {
  return {
    type: types.INIT_DELETED_ACCOUNT_LIST,
    gameId,
    date,
  };
}

export function confirmDeleteAccount(id) {
  return {
    type: types.CONFIRM_DELETE_ACCOUNT,
    id,
  };
}

export function saveInput(value) {
  return {
    type: types.SAVE_INPUT,
    value,
  };
}

// ********************** actions in use


export function initCouponList(value) {
  return {
    type: types.INIT_COUPON_LIST,
    value,
  };
}
export function getCouponList(value) {
  return {
    type: types.GET_COUPON_LIST,
    value,
  };
}
export function getCouponListSuccess(rows, isLast,offset) {
  return {
    type: types.GET_COUPON_LIST_SUCCESS,
    rows,
    isLast,offset
  };
}
export function initCouponListSuccess(rows, isLast,offset) {
  return {
    type: types.INIT_COUPON_LIST_SUCCESS,
    rows,
    isLast,offset
  };
}
export function issueCoupon(value) {
  return {
    type: types.ISSUE_COUPON,
    value,
  };
}
export function issueCouponSuccess(value) {
  return {
    type: types.ISSUE_COUPON_SUCCESS,
    value,
  };
}
export function getCouponDetails() {
  return {
    type: types.GET_COUPON_DETAILS,
  };
}
export function getCouponDetailsSuccess(rows, isLast) {
  return {
    type: types.GET_COUPON_DETAILS_SUCCESS,
    rows,
    isLast,
  };
}
export function deleteCoupon(id) {
  return {
    type: types.DELETE_COUPON,
    id,
  };
}
export function deleteCouponSuccess(obj) {
  return {
    type: types.DELETE_COUPON_SUCCESS,
    obj,
  };
}
export function sortAscCouponList(rows) {
  return {
    type: types.SORT_ASC_COUPON_LIST,
    rows,
  };
}
export function sortDescCouponList(rows) {
  return {
    type: types.SORT_DESC_COUPON_LIST,
    rows,
  };
}

export function handleErrors(obj) {
  return {
    type: types.HANDLE_ERRORS,
    obj,
  };
}

export function getSearchKeywords(obj) {
  return {
    type: types.GET_SEARCH_KEYWORDS,
    obj,
  };
}
