import { call, put, takeLatest, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import moment from 'moment';

import * as types from './constant';

import * as actions from './action';
import * as globalActions from '../../../globalStore/action';
import { openSubMenu, handleClickSubMenu } from '../../../../view/store/action';
import * as selectors from './selector';
import * as api from '../../../../service/API';

function* handleError(type, err) {
	console.error(`err occured in ${type}`, err.response);

	if (err && err.response) {
		yield put(globalActions.globalErrorHandling({ ...err.response, type }));
	} else {
		console.log('@err is :', err);
		yield put(
			globalActions.globalErrorHandling({ message: err.message, type })
		);
	}
}

function* locationChange({ payload }) {
	try {
		const { pathname } = yield payload;

		if (pathname.includes('theBackendSales')) {
			yield put(openSubMenu('statistics'));
			yield put(handleClickSubMenu('theBackendSales'));

			yield put(actions.getTheBackendSales());
		}
	} catch (err) {
		yield call(handleError, 'LOCATION_CHANGE', err);
	}
}

export function* watcherLocationChange() {
	yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* getTheBackendSales({ params }) {
	try {
		const { data } = yield call(api.theBackendSales.getTheBackendSales, params);
		yield put(actions.getTheBackendSalesSuccess(data));
	} catch (err) {
		yield call(handleError, 'GET_THEBACKEND_SALES', err);
	}
}

export function* WatcherGetTheBackendSales() {
	yield takeLatest(types.GET_THEBACKEND_SALES, getTheBackendSales);
}

function* getNextTheBackendSales({ params }) {
	try {
		const endDate = yield select((state) => {
			if (state.theBackendSales.salesList.length)
				return state.theBackendSales.salesList[
					state.theBackendSales.salesList.length - 1
				].Date.subtract(1, 'month').format('YYYY-MM');
		});
		// console.log('$$ 0802 1426 state:', endDate);
		const { data } = yield call(api.theBackendSales.getTheBackendSales, {
			endDate,
		});
		yield put(actions.getNextTheBackendSalesSuccess(data));
	} catch (err) {
		yield call(handleError, 'GET_NEXT_THEBACKEND_SALES', err);
	}
}

export function* WatcherGetNextTheBackendSales() {
	yield takeLatest(types.GET_NEXT_THEBACKEND_SALES, getNextTheBackendSales);
}
