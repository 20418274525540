import { LOCATION_CHANGE } from 'react-router-redux';

// import * as selectors from './selector';
import * as types from './constant';

const initialState = {
	columns: [
		{ label: '일자', field: 'Date' },
		{ label: '거래액(원)', field: 'Revenue' },
		{ label: 'MAU', field: 'MAU' },
		{ label: 'NRU', field: 'NRU' },
		{ label: 'RU', field: 'RU' },
		{ label: 'PU', field: 'PU' },
		{ label: 'ARPU', field: 'ARPU' },
		{ label: 'ARPPU', field: 'ARPPU' },
		{ label: 'P.R(%)', field: 'PR' },
	],
	projects: [],
	dailyStats: [],
	monthlyStats: [],
	statType: { status: '월별' },
	//   statType: "월별",
	sortingParam: false, // , true 오름차순 정렬 후, false 내림차순,
	project: undefined,
	isLoading: false,
	dailyStatsLastItem: undefined,
	monthlyStatsLastItem: undefined,
};

function notice(state = initialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return {
				...initialState,
			};
		case types.CHANGE_STAT_TYPE_PROJECTS:
			return {
				...state,
				statType: action.data,
				columns:
					action.data.status === '월별'
						? initialState.columns
						: [
								{ label: '일자', field: 'Date' },
								{ label: '거래액(원)', field: 'Revenue' },
								{ label: 'DAU', field: 'DAU' },
								{ label: 'WAU', field: 'WAU' },
								{ label: 'MAU', field: 'MAU' },
								{ label: 'NRU', field: 'NRU' },
								{ label: 'RU', field: 'RU' },
								{ label: 'Stickiness', field: 'Stickiness' },
								{ label: 'PU', field: 'PU' },
								{ label: 'ARPDAU', field: 'ARPDAU' },
								{ label: 'ARPPU', field: 'ARPPU' },
								{ label: 'P.R(%)', field: 'PR' },
						  ], // monthlyStat
				dailyStats: [],
				monthlyStats: [],
			};

		case types.HANDLE_SEARCH_PROJECT_SUCCESS:
			// desc. 검색결과 프로젝트가 1개만 존재할 때
			return {
				...state,
				project: action.rows[0],
				dailyStats: initialState.dailyStats,
				monthlyStats: initialState.monthlyStats,
			};
		case types.HANDLE_SEARCH_PROJECT:
			// desc. 검색결과 프로젝트가 2개 이상일 때 기존 검색결과를 초기화
			return {
				...state,
				dailyStats: initialState.dailyStats,
				searching: {
					title: action.title,
					date: action.date,
					opt: action.opt,
				},
			};
		case types.HANDLE_MULTIPLE_SEARCH:
			return {
				...state,
				projects: action.rows.map((row, i) => ({
					no: { noDisplay: true, no: i },
					...row,
				})),
			};
		case types.GET_DAILY_STAT_PROJECTS:
			return {
				...state,
				isLoading: true,
			};
		case types.GET_MONTHLY_STAT_PROJECTS:
			return {
				...state,
				isLoading: true,
			};
		case types.GET_DAILY_STAT_PROJECTS_SUCCESS:
			console.log(action.dateChk, 'date check');
			return {
				...state,
				isLoading: false,
				dailyStats: [...state.dailyStats, ...action.rows],
				dailyStatsLastItem: action.rows[action.rows.length - 1],
				isLast: action.isLast,
			};
		case types.GET_MONTHLY_STAT_PROJECTS_SUCCESS:
			if (action.opt) {
				const { selected, isSearching } = action.opt;

				// 다수의 프로젝트 존재 시 하나를 선택하는 경우
				// 검색 조건을 변경해서 검색버튼을 클릭 하는 경우
				// 기존 리스트를 모두 변경
				if (selected || isSearching) {
					return {
						...state,
						isLoading: false,
						monthlyStats: action.rows,
						monthlyStatsLastItem: action.rows[action.rows.length - 1],
						isLast: action.isLast,
					};
				}
			}
			// 스크롤 액션으로 이전 데이터를 요청하는 경우
			// 기존 데이터에 추가
			return {
				...state,
				isLoading: false,
				monthlyStats: [...state.monthlyStats, ...action.rows],
				monthlyStatsLastItem: action.rows[action.rows.length - 1],
				isLast: action.isLast,
			};
		case types.CLICK_PROJECT_ROW_SUCCESS:
			return {
				...state,
				projects: [],
				project: state.projects.filter(
					(pjt) => pjt.projectId === action.projectId
				)[0],
				columns:
					state.statType === '월별' ||
					(state.statType && state.statType.status === '월별')
						? initialState.columns
						: [
								{ label: '일자', field: 'Date' },
								{ label: '거래액(원)', field: 'Revenue' },
								{ label: 'DAU', field: 'DAU' },
								{ label: 'WAU', field: 'WAU' },
								{ label: 'MAU', field: 'MAU' },
								{ label: 'NRU', field: 'NRU' },
								{ label: 'RU', field: 'RU' },
								{ label: 'Stickiness', field: 'Stickiness' },
								{ label: 'PU', field: 'PU' },
								{ label: 'ARPDAU', field: 'ARPDAU' },
								{ label: 'ARPPU', field: 'ARPPU' },
								{ label: 'P.R(%)', field: 'PR' },
						  ],
			};
		case types.SORT_ASC_ROWS_PROJECTS_STAT:
			return {
				...state,
				monthlyStats: !action.tableType ? action.rows : [],
				dailyStats: action.tableType ? action.rows : [],
				sortingParam: true,
			};

		case types.SORT_DESC_ROWS_PROJECTS_STAT:
			return {
				...state,
				monthlyStats: !action.tableType ? action.rows : [],
				dailyStats: action.tableType ? action.rows : [],
				sortingParam: false,
			};
		case types.INIT_DATA_TABLES_PROJECTS:
			return {
				...initialState,
				columns: state.columns,
				statType: state.statType,
				dailyStatsLastItem: undefined,
			};
		default:
			return state;
	}
}

export default notice;
