export const INIT_VERSION_LIST = "INIT_VERSION_LIST";
export const GET_VERSION_LIST = "GET_VERSION_LIST";
export const GET_VERSION_LIST_SUCCESS = "GET_VERSION_LIST_SUCCESS";
export const MAKE_VERSION = "MAKE_VERSION";
export const MAKE_VERSION_SUCCESS = "MAKE_VERSION_SUCCESS";
export const UPDATE_VERSION = "UPDATE_VERSION";
export const UPDATE_VERSION_SUCCESS = "UPDATE_VERSION_SUCCESS";
export const DELETE_VERSION = "DELETE_VERSION";
export const DELETE_VERSION_SUCCESS = "DELETE_VERSION_SUCCESS";
export const OPEN_VERSION_MODAL = "OPEN_VERSION_MODAL";

// ******************
