import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// import MsgModal from '../../../fnComponents/MsgModal';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from './store/action';
import * as selectors from './store/selector';

const CouponIssueModal = ({
  isCouponIssueModalOpen,
  handleOpenCouponIssueModal,
  onIssueCoupon,
  onHandleErrors,
  errorsObj,
  //onHandleCloseMsgModal,
  //isMsgModalOpen,
}) => {
  const [radioBtn, handleChange] = useState({ validPeriod: '12' });
  const [custom, _handleChange] = useState(undefined);
  const [userInputs, handleInputs] = useState({});

  const [errors, setErrors] = useState();

  const validateInputs = async (userInputs, errExist) => {
    const { couponTitle, numberOfCoupon, creditPrice } = await userInputs;
    await console.log(couponTitle, numberOfCoupon, creditPrice);
    let errObj = {};

    const pattern = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9]/gi;
    if (pattern.test(couponTitle)) {
      errExist = true;
      errObj = { ...errObj, couponTitle: '한글, 영문, 숫자를 사용해주세요.' };
    }

    if (!couponTitle) {
      errExist = true;
      errObj = { ...errObj, couponTitle: '쿠폰명을 입력해주세요.' };
    }
    if (!numberOfCoupon) {
      errExist = true;
      errObj = { ...errObj, numberOfCoupon: '발행수를 입력해주세요.' };
    }
    if (!creditPrice) {
      errExist = true;
      errObj = { ...errObj, creditPrice: '크레딧을 입력해주세요.' };
    }
    if (radioBtn.custom && !custom) {
      errExist = true;
      errObj = { ...errObj, validPeriod: '유효기간을 입력해주세요.' };
    }
    if (radioBtn.custom && custom) {
      if (Number(custom) > 99 || Number(custom) <= 0) {
        errExist = true;
        errObj = { ...errObj, validPeriod: '1-99까지 입력 가능합니다.' };
      }
    }
    if (
      numberOfCoupon &&
      (Number(numberOfCoupon) > 1000 || Number(numberOfCoupon) <= 0)
    ) {
      errExist = true;
      errObj = { ...errObj, numberOfCoupon: '1-1,000개 까지 발행 가능합니다.' };
    }
    if (
      creditPrice &&
      (Number(creditPrice) > 10000000 || Number(creditPrice) <= 9999)
    ) {
      errExist = true;
      errObj = {
        ...errObj,
        creditPrice: '10,000-10,000,000개 까지 발행 가능합니다.',
      };
    }

    if (errExist) return errObj;
  };

  const handleIssueCoupon = async () => {
    let errExist;
    errExist = await validateInputs(userInputs, errExist);

    const validPeriod = custom || radioBtn.validPeriod;

    console.log('errExist', errExist);

    if (!errExist) {
      await onIssueCoupon({ ...userInputs, validPeriod });
      await handleOpenCouponIssueModal(false);
      await handleInputs({});
      await handleChange({ validPeriod: '12' });
      await setErrors();
    } else {
      setErrors(errExist);
    }
  };

  const handleInputChange = (e) => {
    if (e.target.id === 'couponTitle') {
      if (!e.target.value)
        return setErrors({
          ...errors,
          couponTitle: '쿠폰명을 입력해주세요.',
        });
      setErrors({
        ...errors,
        couponTitle: '',
      });
    }

    console.log(e.target.value, 'userInput');
    handleInputs({ ...userInputs, [e.target.id]: e.target.value });
  };

  const handleRadioBtnChange = (e, _custom) => {
    if (_custom) {
      return handleChange({ custom: true });
    }
    _handleChange('');
    handleChange({ [e.target.id]: e.target.value });
  };

  const handleRadioInput = (e) => {
    _handleChange(e.target.value);
  };

  return (
    <Modal size="lg" isOpen={isCouponIssueModalOpen}>
      <ModalHeader>크레딧 지급</ModalHeader>
      <ModalBody>
        {/* a row for INPUT */}
        <div className="form-group m-form__group row">
          <label for="credit-name" className="col-lg-2 col-form-label">
            쿠폰명 *
          </label>
          <div className="col-lg-10">
            <input
              onChange={handleInputChange}
              type="type"
              className="form-control"
              id="couponTitle"
              required
            />
            <div className="error message visible">
              {errors && errors.couponTitle}
            </div>
          </div>
        </div>
        <div className="form-group m-form__group row">
          <label for="expiration" className="col-lg-2 col-form-label">
            유효기간
          </label>
          <div className="col-lg-10">
            <div className="m-radio-inline">
              <label className="m-radio">
                <input
                  value="3"
                  onChange={(e) => handleRadioBtnChange(e)}
                  type="radio"
                  id="validPeriod"
                  checked={radioBtn.validPeriod === '3'}
                />
                3개월
                <span />
              </label>
              <label className="m-radio">
                <input
                  value="6"
                  onChange={(e) => handleRadioBtnChange(e)}
                  type="radio"
                  id="validPeriod"
                  checked={radioBtn.validPeriod === '6'}
                />
                6개월
                <span />
              </label>
              <label className="m-radio">
                <input
                  value="12"
                  onChange={(e) => handleRadioBtnChange(e)}
                  type="radio"
                  id="validPeriod"
                  checked={radioBtn.validPeriod === '12'}
                />
                12개월
                <span />
              </label>
              <label className="m-radio">
                <input
                  value="24"
                  onChange={(e) => handleRadioBtnChange(e)}
                  type="radio"
                  id="validPeriod"
                  checked={radioBtn.validPeriod === '24'}
                />
                24개월
                <span />
              </label>
              <label className="m-radio">
                <input
                  value="36"
                  onChange={(e) => handleRadioBtnChange(e)}
                  type="radio"
                  id="validPeriod"
                  checked={radioBtn.validPeriod === '36'}
                />
                36개월
                <span />
              </label>
              <label className="m-radio">
                <input
                  value="custom"
                  onChange={(e) => handleRadioBtnChange(e, 'custom')}
                  type="radio"
                  id="validPeriod"
                  checked={radioBtn.custom}
                />
                직접입력
                <span />
              </label>
              <input
                className="credit-custom-month"
                id="validPeriod"
                onChange={handleRadioInput}
                value={custom}
                required
                disabled={!radioBtn.custom}
              />
              개월
              <div className="error message visible">
                {errors && errors.validPeriod}
              </div>
            </div>
          </div>
        </div>
        <div className="form-group m-form__group row">
          <label for="credit-reason" className="col-lg-2 col-form-label">
            크레딧 *
          </label>
          <div className="col-lg-10">
            <input
              onChange={handleInputChange}
              type="number"
              className="form-control"
              id="creditPrice"
              required
            />
            <div className="error message visible">
              {errors && errors.creditPrice}
            </div>
          </div>
        </div>
        <div className="form-group m-form__group row">
          <label for="credit-reason" className="col-lg-2 col-form-label">
            발행수 *
          </label>
          <div className="col-lg-10">
            <input
              onChange={handleInputChange}
              type="number"
              className="form-control"
              id="numberOfCoupon"
              required
            />
            <div className="error message visible">
              {errors && errors.numberOfCoupon}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleIssueCoupon}>
          발행
        </Button>{' '}
        <Button
          color="primary"
          onClick={() => {
            setErrors();
            handleOpenCouponIssueModal(false);
          }}
        >
          취소
        </Button>{' '}
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  //   isMsgModalOpen: selectors.isMsgModalOpenSelector,
  errorsObj: selectors.errorsSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onIssueCoupon: (obj) => dispatch(actions.issueCoupon(obj)),
  onHandleCloseMsgModal: () => dispatch(actions.handleCloseMsgModal()),
  onHandleErrors: (errors) => dispatch(actions.handleErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponIssueModal);
