/*
 * Copyright 2013-2022 AFI, Inc. All Rights Reserved.
 */

import React from 'react';
import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import ButtonsGroup from '../../../fnComponents/buttonGroup';
import DataTable from '../../../fnComponents/_DataTable';
import SearchForm from '../../../fnComponents/SearchForm';

import { Modal, ModalBody } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from './store/action';
import * as selectors from './store/selector';
import CurrentChargeDetail from './CurrentChargeDetail';
import SpinnerCompo from '../../../fnComponents/Spinner';

const CurrentCharge = ({
  columns,
  accountList,
  detail: { chargeDetail },
  isLoading,
  sortingParam,
  _accountList,
  isLast,
  searchKeywords,
  //
  onClickCurrentChargeRow,
  onGetBackToMainTable,
  onGetCurrentBill,
  onInitCurrentChargeList,
  onGetSearchKeywords,
  onSortAscCurrentAccountList,
  onSortDescCurrentAccountList,
}) => {
  const handleSearch = () => {
    onGetCurrentBill({ isSearching: true });
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />
        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title m-subheader__title--separator">
                  결제 관리
                </h3>
                <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
                  <li className="m-nav__item m-nav__item--home">
                    <a href="dummy" className="m-nav__link m-nav__link--icon">
                      <i className="m-nav__link-icon la la-home" />
                    </a>
                  </li>
                  <li className="m-nav__separator">-</li>
                  <li className="m-nav__item">
                    <a href="dummy" className="m-nav__link">
                      <span className="m-nav__link-text">결제 관리</span>
                    </a>
                  </li>
                  <li className="m-nav__separator">-</li>
                  <li className="m-nav__item">
                    <a href="dummy" className="m-nav__link">
                      <span className="m-nav__link-text">현재 요금</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {!chargeDetail ? (
            <div className="m-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="m-portlet">
                    <div className="m-portlet__body">
                      <SearchForm
                        disabled={
                          !searchKeywords.id && !searchKeywords.companyName
                        }
                        keywords={['아이디', '회사명', '구분']}
                        mainlyRequiredAction={handleSearch}
                        initAction={onInitCurrentChargeList}
                        onGetSearchKeywords={onGetSearchKeywords}
                      />
                      <ButtonsGroup
                        columns={columns}
                        pdfData={accountList}
                        csvData={accountList}
                        csvFileName={`current_charge_list_${moment().format(
                          'YYYY-MM-DD HH:mm'
                        )}`}
                      />
                      <DataTable
                        requiredAction={onClickCurrentChargeRow}
                        rows={accountList}
                        columns={columns}
                        isLast={isLast}
                        sortingColumns={[
                          '현재요금(원)',
                          '가입일',
                          '총납부요금(원)',
                        ]}
                        requestAction={onGetCurrentBill}
                        onSortAsc={onSortAscCurrentAccountList}
                        onSortDesc={onSortDescCurrentAccountList}
                        sortingParam={sortingParam}
                        // secondRequiredAction={onClickProjectRow}
                        actualData={_accountList}
                        customClassName={
                          accountList && accountList.length > 13
                            ? 'currentCharge-table table-scrollable'
                            : 'currentCharge-table'
                        }
                        clickableIndex={[0]}
                        nonClickableIndicator={['미출시']}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CurrentChargeDetail onGetBackToMainTable={onGetBackToMainTable} />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  columns: selectors.columnsSelector,
  detail: selectors.clickedIdSelector,
  accountList: selectors.accountListSelector,
  _accountList: selectors._accountListSelector,
  isLoading: selectors.isLoadingSelector,
  sortingParam: selectors.sortingParamSelector,
  isLast: selectors.isLastSelector,
  accountIdSelector: selectors.accountIdSelector,
  searchKeywords: selectors.searchKeywordsSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onClickCurrentChargeRow: (id, arr) =>
    dispatch(actions.clickCurrentChargeRow(id, arr)),
  onGetBackToMainTable: () => dispatch(actions.getBackToMainTable()),
  onInitCurrentChargeList: () => dispatch(actions.initCurrentChargeList()),
  onGetCurrentBill: (obj) => dispatch(actions.getCurrentBill(obj)),
  onGetCurrentAccountBill: (id) => dispatch(actions.getCurrentAccountBill(id)),
  onGetSearchKeywords: (obj) => dispatch(actions.getSearchKeywords(obj)),
  onSortAscCurrentAccountList: (obj) =>
    dispatch(actions.sortAscCurrentAccountList(obj)),
  onSortDescCurrentAccountList: (obj) =>
    dispatch(actions.sortDescCurrentAccountList(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentCharge);
