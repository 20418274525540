// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { call, put, takeLatest, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import * as selectors from './selector';
import * as types from './constant';
import * as actions from './action';
import { openSubMenu, handleClickSubMenu } from '../../../../view/store/action';
import * as api from '../../../../service/API';

function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split('/');
    // console.log(pathArray, "*** pathArray ***");
    if (
      pathArray[1] === 'developer' &&
      pathArray[2] === 'sdkDocs' &&
      pathArray[3] === 'backend'
    ) {
      yield put(openSubMenu('sdkDocs'));
      yield put(handleClickSubMenu('backend'));
      yield put(actions.initTopics());
    }
    if (
      pathArray[1] === 'developer' &&
      pathArray[2] === 'sdkDocs' &&
      pathArray[3] === 'backendFunction'
    ) {
      yield put(openSubMenu('sdkDocs'));
      yield put(handleClickSubMenu('backendFunction'));
      yield put(actions.initTopics());
    }
  } catch (err) {
    console.log('LOCATION_CHANGE', err);
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* initTopics() {
  try {
    // 뒤끝SDK / 펑션SDK 분기하기 위해서 선택된 서브메뉴를 참조하자
    const clickedSubMenu = yield select(selectors.clickedSubMenuSelector);
    // 해당 서브메뉴명 참조값으로 쿼리
    // 뒤끝 === 0, 펑션 === 1
    const { data } = yield call(
      api.getStarted.getVersionList,
      clickedSubMenu === 'backend' ? { versionType: 0 } : { versionType: 1 }
    );
    yield put(actions.getVersionListSuccess(data));

    const versionlist = yield select(selectors.versionListSelector);
    let param;

    // console.log(clickedSubMenu, "clickedSubMenu in sdkDocs saga");
    // console.log(versionlist, "in sdkDocs saga");
    const selectedVersion = yield select(selectors.selectedVersionSelector);
    console.log('@@@ selectedVersion:', selectedVersion, ' @@@');

    // latest version
    const version = selectedVersion || versionlist[0].version.S;
    // or selected version

    switch (clickedSubMenu) {
      case 'backend':
        param = { title: '뒤끝 SDK', version };
        break;
      case 'backendFunction':
        param = { title: '뒤끝펑션 SDK', version };
        break;

      default:
        break;
    }
    yield put(actions.getTopics(param));
  } catch (error) {
    console.log('INIT_TOPICS_SDK : ', error);
  }
}

export function* watcherInitTopics() {
  yield takeLatest(types.INIT_TOPICS_SDK, initTopics);
}

function* getTopics({ param }) {
  try {
    const { data } = yield call(api.getStarted.getTopics, param);

    // console.log(data, "1027");
    let listData = {};
    let classParentList = [];

    if (data && data.length) {
      for (let i = 0; i < data.length; i++) {
        // test code

        yield put(actions.initGetListKey());
        const _listData = yield call(api.getStarted.getList, {
          topicIndate: data[i].inDate,
        });
        // console.log(_listData.data, "0709 _listData");
        listData = yield {
          ...listData,
          [_listData.data.title]: {
            ..._listData.data,
            children: _listData.data.children.map((child1) => ({
              ...child1,
              depth: 1,
              children: child1.children.length
                ? child1.children.map((child2) => ({
                    ...child2,
                    depth: 2,
                    children: child2.children.length
                      ? child2.children.map((child3) => ({
                          ...child3,
                          depth: 3,
                          children: child3.children,
                          //   children: child3.children.length ? child3.children : ['endOfTree']
                        }))
                      : [],
                  }))
                : [],
            })),
            //   depth: 1
          },
        };

        // api : getClassParentList 토픽별 호출
        const classParents = yield call(
          api.getStarted.getClassParentList,
          data[i].inDate
        );
        // console.log(i, classParents, 'index별 클래스 페어런트')
        const _classParents = classParents.data.filter(
          (_class) => _class.title
        );
        classParentList = [
          ...classParentList,
          {
            // title: _listData.data.title,
            title: '없음',
            inDate: _listData.data.inDate,
            header: _listData.data.title,
            // header: true
          },
          ..._classParents,
        ];
      }
    }

    yield put(actions.getListSuccess(listData));
    yield put(actions.getClassParentListSuccess(classParentList));

    yield put(actions.getTopicsSuccess(data));
  } catch (error) {
    console.log('GET_TOPICS_SDK', error);
  }
}

export function* watcherGetTopics() {
  yield takeLatest(types.GET_TOPICS_SDK, getTopics);
}

function* updateTopic({ inDate, topic }) {
  try {
    yield call(api.getStarted.updateTopic, inDate, topic);
    yield put(actions.updateTopicSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('UPDATE_TOPIC', error);
  }
}

export function* watcherUpdateTopic() {
  yield takeLatest(types.UPDATE_TOPIC_SDK, updateTopic);
}

function* getList({ param, listIndex }) {
  try {
    const { data } = yield call(api.getStarted.getList, param);

    console.log(data, 'GET_LIST_SDK_SUCCESS');
    yield put(actions.getListSuccess(data, listIndex));
  } catch (error) {
    console.log('GET_LIST_SDK', error);
  }
}

export function* watcherGetList() {
  yield takeLatest(types.GET_LIST_SDK, getList);
}
function* getClassParentList({ param }) {
  try {
    const { data } = yield call(api.getStarted.getClassParentList, param);

    console.log(data, 'GET_CLASS_PARENT_LIST_SDK_SUCCESS');
    yield put(actions.getClassParentListSuccess(data));
  } catch (error) {
    console.log('GET_CLASS_PARENT_LIST_SDK', error);
  }
}

export function* watcherGetClassParentList() {
  yield takeLatest(types.GET_CLASS_PARENT_LIST_SDK, getClassParentList);
}

function* makeClass({ obj }) {
  try {
    yield call(api.getStarted.makeClass, obj);

    yield put(actions.makeClassSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('MAKE_CLASS_SDK', error);
  }
}

export function* watcherMakeClass() {
  yield takeLatest(types.MAKE_CLASS_SDK, makeClass);
}

function* makeDocument({ obj }) {
  try {
    yield call(api.getStarted.makeDocument, obj);

    yield put(actions.makeDocumentSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('MAKE_DOCUMENT_SDK', error);
  }
}

export function* watcherMakeDocument() {
  yield takeLatest(types.MAKE_DOCUMENT_SDK, makeDocument);
}

function* updateOrder({ inDate, upDown }) {
  try {
    yield call(api.getStarted.updateOrder, inDate, upDown);
    yield put(actions.updateOrderSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('UPDATE_ORDER_SDK', error);
  }
}

export function* watcherUpdateOrder() {
  yield takeLatest(types.UPDATE_ORDER_SDK, updateOrder);
}

function* updateClass({ obj, inDate }) {
  try {
    yield call(api.getStarted.updateClass, inDate, obj);
    yield put(actions.updateClassSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('UPDATE_CLASS_SDK', error);
  }
}

export function* watcherUpdateClass() {
  yield takeLatest(types.UPDATE_CLASS_SDK, updateClass);
}

function* deleteClass({ obj, inDate }) {
  try {
    yield call(api.getStarted.deleteClass, inDate, obj);
    yield put(actions.deleteClassSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('DELETE_CLASS_SDK', error);
  }
}

export function* watcherDeleteClass() {
  yield takeLatest(types.DELETE_CLASS_SDK, deleteClass);
}

function* getDocument({ inDate }) {
  try {
    const { data } = yield call(api.getStarted.getDocument, inDate);
    yield put(actions.getDocumentSuccess(data));
  } catch (error) {
    console.log('GET_DOCUMENT_SDK', error);
  }
}

export function* watcherGetDocument() {
  yield takeLatest(types.GET_DOCUMENT_SDK, getDocument);
}

function* updateDocument({ obj, inDate }) {
  try {
    yield call(api.getStarted.updateDocument, obj, inDate);
    yield put(actions.updateDocumentSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('UPDATE_DOCUMENT_SDK', error);
  }
}

export function* watcherUpdateDocument() {
  yield takeLatest(types.UPDATE_DOCUMENT_SDK, updateDocument);
}

function* deleteDocument({ inDate }) {
  try {
    yield call(api.getStarted.deleteDocument, inDate);
    yield put(actions.deleteDocumentSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('DELETE_DOCUMENT_SDK', error);
  }
}

export function* watcherDeleteDocument() {
  yield takeLatest(types.DELETE_DOCUMENT_SDK, deleteDocument);
}

function* makeTopic({ obj }) {
  try {
    yield call(api.getStarted.makeTopic, obj);
    yield put(actions.makeTopicSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('MAKE_TOPIC_SDK', error);
  }
}

export function* watcherMakeTopic() {
  yield takeLatest(types.MAKE_TOPIC_SDK, makeTopic);
}
