import * as types from "./constant";

export function initShowcaseList() {
  return {
    type: types.INIT_SHOWCASE_LIST,
  };
}

export function getShowcaseList(lastEvaluatedKey) {
  return {
    type: types.GET_SHOWCASE_LIST,
    lastEvaluatedKey,
  };
}

export function getMoreShowcaseList(lastEvaluatedKey) {
  return {
    type: types.GET_MORE_SHOWCASE_LIST,
    lastEvaluatedKey,
  };
}

export function getMoreShowcaseListSuccess(rows, lastEvaluatedKey) {
  return {
    type: types.GET_MORE_SHOWCASE_LIST_SUCCESS,
    rows,
    lastEvaluatedKey,
  };
}

export function getShowcaseListSuccess(rows, lastEvaluatedKey) {
  return {
    type: types.GET_SHOWCASE_LIST_SUCCESS,
    rows,
    lastEvaluatedKey,
  };
}

export function updateShowcaseOrder(updown, inDate) {
  return {
    type: types.UPDATE_SHOWCASE_ORDER,
    inDate,
    updown,
  };
}

export function insertShowcase(obj) {
  return {
    type: types.INSERT_SHOWCASE,
    obj,
  };
}

export function insertShowcaseError(obj) {
  return {
    type: types.INSERT_SHOWCASE_ERROR,
    obj,
  };
}

export function initErrObj() {
  return {
    type: types.INIT_INSERT_SHOWCASE_ERROR,
  };
}

export function insertShowcaseSuccess() {
  return {
    type: types.INSERT_SHOWCASE_SUCCESS,
  };
}
export function updateShowcase(inDate, data) {
  return {
    type: types.UPDATE_SHOWCASE,
    inDate,
    data,
  };
}
export function updateShowcaseSuccess() {
  return {
    type: types.UPDATE_SHOWCASE_SUCCESS,
  };
}

export function deleteShowcase(inDate) {
  return {
    type: types.DELETE_SHOWCASE,
    inDate,
  };
}

export function getShowcase(title, arr) {
  return {
    type: types.GET_SHOWCASE,
    title,
    arr,
  };
}

// export function initGameStat(gameId, date, year) {
// 	return {
// 		type: types.INIT_GAME_STAT,
// 		gameId,
// 		date,
// 		year,
// 		MAU: false,
// 	};
// }
