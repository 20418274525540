import { LOCATION_CHANGE } from "react-router-redux";

// import * as selectors from './selector';
import * as types from "./constant";

const initialState = {
  topics: [],
  list: [],
  document: undefined,
  versionList: [],
  classParentList: [],
  isClassDone: false,
  isDeleteClassDone: false,
  isDeleteVersionDone: false,
  isMakeVersionDone: false,
  isUpdateVersionDone: false,
  selectedVersion: null
};

function getStarted(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...initialState,
      };

    case types.GET_VERSION_LIST_SUCCESS:
      return {
        ...state,
        versionList: action.obj,
        isMakeVersionDone: initialState.isMakeVersionDone,
        isDeleteVersionDone: initialState.isDeleteVersionDone,
        isUpdateVersionDone: initialState.isUpdateVersionDone,
        selectedVersion: initialState.selectedVersion
      };
    case types.MAKE_VERSION_SUCCESS:
      return {
        ...state,
        isMakeVersionDone: true
      };
    case types.UPDATE_VERSION_SUCCESS:
      return {
        ...state,
        isUpdateVersionDone: true
      };
    case types.DELETE_VERSION_SUCCESS:
      return {
        ...state,
        isDeleteVersionDone: true
      };

    case types.OPEN_VERSION_MODAL:

      const filtered = state.versionList.filter(version => {
        return version.version.S === action.version
      })[0]

      return {
        ...state,
        selectedVersion: filtered
      };

    //   **********************

    default:
      return state;
  }
}

export default getStarted;
