// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { delay } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import moment from 'moment';

import * as types from './constant';
import * as actions from './action';
import * as selectors from './selector';
import { openSubMenu, handleClickSubMenu } from '../../../view/store/action';
import {
  globalErrorHandling,
  globalMsgModalOpen,
} from '../../globalStore/action';
import * as api from '../../../service/API';

function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split('/');

    if (payload && payload.query && payload.query.title) {
      yield put(actions.getSearchKeywords({ title: payload.query.title }));
    }

    if (payload && payload.query && payload.query.accountId) {
      yield put(
        actions.getSearchKeywords({ accountId: payload.query.accountId })
      );
    }

    if (pathArray[1] === 'projects') {
      yield put(openSubMenu('projects'));
      yield put(handleClickSubMenu('projects'));
      yield put(actions.initProjectList());
      const {
        data: { count },
      } = yield call(api.projectsList.getProjectsCount);
      yield put(actions.getProjectsCountSuccess(count));
    }
    if (pathArray[1] === 'deletedProjects') {
      yield put(openSubMenu('projects'));
      yield put(handleClickSubMenu('deletedProjects'));
      yield put(actions.getDeletedProjects());
      //   const {
      //     data: { count },
      //   } = yield call(api.projectsList.getProjectsCount);
    }
  } catch (err) {
    console.log('saga err', err);
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* initProjectList({ params }) {
  try {
    yield put(actions.getProjectList());
  } catch (error) {}
}

export function* watcherInitProjectList() {
  yield takeLatest(types.INIT_PROJECT_LIST, initProjectList);
}

function* getProjectList({ zeroOffset, toRefresh }) {
  try {
    const searchKeywords = yield select(selectors.searchKeywordsSelector);
    const deletedProject = yield select(selectors.deletedProjectsSelector);
    const _projectList = yield select(selectors._projectListSelector);

    const checkIfSearching = Object.keys(searchKeywords).length ? true : false;
    // 검색 상태일 때 offset 사용을 위한 키 (case: 검색결과 내에서 더보기 클릭)
    // case: 검색 버튼 클릭인 경우에 zeroOffset = true
    const offset = checkIfSearching
      ? zeroOffset
        ? 0
        : Number.parseInt(yield select(selectors.searchingOffsetSelector))
      : yield select(selectors.offsetSelector);

    const _searchKeywords = {
      ...searchKeywords,
      startDate: searchKeywords.startDate
        ? moment(searchKeywords.startDate).set({ hour: 0 }).toISOString()
        : null,
      endDate: searchKeywords.endDate
        ? moment(searchKeywords.endDate).set({ hour: 24 }).toISOString()
        : null,
      status: searchKeywords.status
        ? searchKeywords.status === '정상'
          ? 'n'
          : 's'
        : null,
      store: searchKeywords.store
        ? searchKeywords.store === '등록'
          ? 'registered'
          : searchKeywords.store === '미등록'
          ? 'notRegistered'
          : searchKeywords.store === '오류'
          ? 'error'
          : null
        : null,
      deleteEndDate: searchKeywords.deleteEndDate
        ? moment(searchKeywords.deleteEndDate).set({ hour: 24 }).toISOString()
        : null,
      deleteStartDate: searchKeywords.deleteStartDate
        ? moment(searchKeywords.deleteStartDate).set({ hour: 0 }).toISOString()
        : null,
      release:
        searchKeywords.release === '출시'
          ? 'released'
          : searchKeywords.release === '미출시'
          ? 'notReleased'
          : searchKeywords.release === '미사용'
          ? 'notUsing'
          : '',
      chatStatus:
        searchKeywords.chatStatus === '활성'
          ? 'active'
          : searchKeywords.chatStatus === '비활성'
          ? 'inactive'
          : null,
    };
    const offsetToRefresh = checkIfSearching
      ? yield select(selectors.searchingOffsetToRefreshSelector)
      : yield select(selectors.offsetToRefreshSelector);
    const params = {
      offset: toRefresh ? offsetToRefresh : offset,
      ..._searchKeywords,
    };

    let _data = null;

    if (deletedProject) {
      const { data } = yield call(api.projectsList.getDeletedProjects, params);
      _data = data;
    } else {
      const { data } = yield call(api.projectsList.getProjects, params);
      _data = data;
    }

    if (toRefresh && offsetToRefresh > 0) {
      const sliced = _projectList.slice(0, Number(offsetToRefresh));

      _data = {
        ..._data,
        projectList: sliced.concat(_data.projectList),
      };
    }

    if (checkIfSearching) {
      return yield put(actions.getSearchedProjectListSuccess(_data, toRefresh));
    }
    yield put(actions.getProjectListSuccess(_data, toRefresh));
  } catch (error) {
    console.log(error, 'GET PROJECT LIST ERROR');
  }
}

export function* watcherGetProjectList() {
  yield takeLatest(types.GET_PROJECT_LIST, getProjectList);
}

function* getProjectStatus({ arr }) {
  try {
    const { id } = arr.find((item) => Object.keys(item)[0] === 'id');

    // console.log(id);
    const { data } = yield call(api.projectsList.getStatusList, id);
    yield put(actions.getStatusListSuccess(data));
  } catch (error) {
    console.log('getProjectStatus err --> ', error);
  }
}

export function* watcherGetProjectStatus() {
  yield takeLatest(types.MODIFY_PROJECT_ROW, getProjectStatus);
}

function* confirmChangeStatus({ value }) {
  try {
    const { clickedId } = yield select(selectors.clickedIdSelector);

    yield call(api.projectsList.updateStatus, clickedId, value);

    yield put(actions.handleCloseProjectModal());
    yield put(actions.initCurrentProjectList());
    // last key를 select해서 getProjectList를 호출하자
    yield call(delay, 300);
    yield put(actions.getProjectList());
  } catch (error) {}
}

export function* watcherConfirmChangeStatus() {
  yield takeLatest(types.CONFIRM_CHANGE_STATUS, confirmChangeStatus);
}

function* handleUpdatePayPlan({ id, obj }) {
  try {
    yield call(api.projectsList.updatePayPlan, id, obj);
    yield call(delay, 750);
    yield put(actions.getProjectList(null, true));

    // init하지 않도록 변경
  } catch (error) {}
}

export function* watcherHandleUpdatePayPlan() {
  yield takeLatest(types.HANDLE_UPDATE_PAY_PLAN, handleUpdatePayPlan);
}

function* getProject({ arr }) {
  try {
    const gameId = arr[2].id;

    const { data } = yield call(api.projectsList.getProject, gameId);
    yield call(delay, 500);
    yield put(actions.getProjectSuccess(data));
  } catch (error) {}
}

export function* watcherGetProject() {
  yield takeLatest(types.GET_PROJECT, getProject);
}

function* getDeletedProjects() {
  try {
    const { data } = yield call(api.projectsList.getDeletedProjects);
    yield call(delay, 500);
    yield put(actions.getDeletedProjectsSuccess(true));
    yield put(actions.getProjectListSuccess(data));
  } catch (error) {
    console.log('GET_DELETED_PROJECT', error);
  }
}

export function* watcherGetDeletedProjects() {
  yield takeLatest(types.GET_DELETED_PROJECT, getDeletedProjects);
}

function* updateGameReleasingData({ data }) {
  try {
    const gameId = yield select(selectors.gameIdSelector);
    const offsetToRefresh = yield select(selectors.offsetToRefreshSelector);

    yield call(api.projectsList.updateGameReleasingData, gameId, data);

    yield call(delay, 500);
    yield put(actions.updateGameReleasingDataSuccess(true));
    yield put(actions.getProjectList(null, true));
  } catch (error) {
    console.log('UPDATE_GAME_RELEASING_DATA', error);
  }
}

export function* watcherUpdateGameReleasingData() {
  yield takeLatest(types.UPDATE_GAME_RELEASING_DATA, updateGameReleasingData);
}
function* getRatePlan({ payload }) {
  try {
    const { data } = yield call(api.projectsList.getRatePlan, payload);
    yield put(actions.getRatePlanSuccess(data));
  } catch (error) {
    console.log('GET_RATE_PLAN', error);
    globalErrorHandling(error);
  }
}

export function* watcherGetRatePlan() {
  yield takeLatest(types.GET_RATE_PLAN, getRatePlan);
}

function* putRatePlan({ payload: { gameId, body } }) {
  try {
    yield call(api.projectsList.putRatePlan, gameId, body);
    yield put(actions.putRatePlanSuccess());
    yield put(actions.getProjectList(false, true));
  } catch (error) {
    console.log('PUT_RATE_PLAN', error);
    globalErrorHandling(error);
  }
}

export function* watcherPutRatePlan() {
  yield takeLatest(types.PUT_RATE_PLAN, putRatePlan);
}

function* postDiscount({ payload: data }) {
  try {
    yield call(api.projectsList.postDiscount, data);
    yield put(actions.postDiscountSuccess());
    yield put(
      globalMsgModalOpen({
        globalMessage: '',
        globalMsgModalOpen: false,
        confirmAction: null,
      })
    );

    yield put(actions.getProjectList(false, true));
  } catch (error) {
    globalErrorHandling(error);
  }
}

export function* watcherPostDiscount() {
  yield takeLatest(types.POST_DISCOUNT, postDiscount);
}
