import * as types from './constant';

export function getDashboard(params) {
	return {
		type: types.GET_DASHBOARD,
		params,
	};
}

export function getDashboardSuccess(payload) {
	return {
		type: types.GET_DASHBOARD_SUCCESS,
		payload,
	};
}
