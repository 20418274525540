import React, { useState } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Table,
} from 'reactstrap';
import Octicon, { Search, X } from '@primer/octicons-react';
import moment from 'moment';

const ShareChargeDetailModal = ({
	isOpen,
	handleOpenDetailModal,
	gameBill,
	billType,
	gameTitle,
	searchKeywords,
}) => {
	const [currencyModalData, handleCurrencyModalData] = useState({ data: [] });

	const detailColumns = [
		'날짜',
		'Android DAU',
		'iOS DAU',
		'기타 DAU',
		'무료 DAU',
		'청구 DAU',
		'금액',
	];
	const shareDetailColumns = [
		'날짜',
		'Android 구매',
		'Android 환불',
		'iOS 구매',
		'iOS 환불',
		'합계',
	];
	const dbDetailColumns = [
		'날짜',
		'읽기 처리량',
		'쓰기 처리량',
		'읽기 요금',
		'쓰기 요금',
		'합계',
	];

	const makeColumsRow = (columns) => {
		if (columns.length)
			return columns.map((column, i) => {
				const forKey = i;
				return <th key={forKey}>{column}</th>;
			});
	};

	const makeRows = (gameBill) => {
		const { results } = gameBill;

		const handleClickGameBillRow = (obj, type) => {
			//   console.log(obj, type, "!!!");
			let arr = [];

			if (type.includes('Amount')) {
				for (const key in obj) {
					const _obj = {
						currency: key,
						amount: obj[key].amount,
						korAmount: obj[key].currency * obj[key].amount,
						rate: obj[key].currency,
					};
					arr = [...arr, _obj];
				}
			}
			if (type.includes('Refund')) {
				for (const key in obj) {
					const _obj = {
						currency: key,
						amount: obj[key].refund,
						korAmount: obj[key].currency * obj[key].refund,
						rate: obj[key].currency,
					};
					arr = [...arr, _obj];
				}
			}

			//   console.log(arr, "!!!");

			handleCurrencyModalData({
				isOpen: true,
				data: arr,
			});
		};

		if (results.length) {
			return results.map((row, i) => {
				if (billType === 'dau') {
					const { android, ios, etc, freeDAU, date } = row;
					const forKey = i;
					return (
						<tr key={forKey}>
							<td>{moment(date).format('DD')}일</td>
							<td>{android && fixedNumToLocaleString(android)}</td>
							<td>{ios && fixedNumToLocaleString(ios)}</td>
							<td>{etc && fixedNumToLocaleString(etc)}</td>
							<td>
								{freeDAU === '0'
									? '0'
									: freeDAU && `- ${fixedNumToLocaleString(freeDAU)}`}
							</td>
							<td>{(android + ios + etc - freeDAU).toLocaleString()}</td>
							<td>
								{((android + ios + etc - freeDAU) * 6).toLocaleString()}원
							</td>
						</tr>
					);
				}
				if (billType === 'share') {
					const {
						androidSum,
						iosRefund,
						androidRefund,
						inDate,
						iosSum,
						iosAmount,
						androidAmount,
						totalSum,
						android,
						ios,
					} = row;
					const forKey = i;
					return (
						<tr key={forKey}>
							<td>{moment(inDate).format('DD')}일</td>
							<td
								className="td-clickable-currency"
								onClick={() =>
									handleClickGameBillRow(android, 'androidAmount')
								}>
								{androidAmount === '-'
									? '-'
									: `${floorPlusToLocaleString(androidAmount)}원`}
							</td>
							<td
								className="td-clickable-currency"
								onClick={() =>
									handleClickGameBillRow(android, 'androidRefund')
								}>
								{androidRefund === '-'
									? '-'
									: `${floorPlusToLocaleString(androidRefund)}원`}
							</td>
							<td
								className="td-clickable-currency"
								onClick={() => handleClickGameBillRow(ios, 'iosAmount')}>
								{iosAmount === '-'
									? '-'
									: `${floorPlusToLocaleString(iosAmount)}원`}
							</td>
							<td
								className="td-clickable-currency"
								onClick={() => handleClickGameBillRow(ios, 'iosRefund')}>
								{iosRefund === '-'
									? '-'
									: `${floorPlusToLocaleString(iosRefund)}원`}
							</td>
							<td>
								{totalSum === '-'
									? '-'
									: `${floorPlusToLocaleString(totalSum)}원`}
							</td>
						</tr>
					);
				}
				if (billType === 'db') {
					const {
						date,
						readValue,
						readPrice,
						writeValue,
						writePrice,
						totalPrice,
					} = row;
					const forKey = i;
					return (
						<tr key={forKey}>
							<td>{moment(date).format('DD')}일</td>
							<td>{fixedNumToLocaleString(readValue)}</td>
							<td>{fixedNumToLocaleString(writeValue)}</td>
							<td>{fixedNumToLocaleString(readPrice)}원</td>
							<td>{fixedNumToLocaleString(writePrice)}원</td>
							<td>{fixedNumToLocaleString(Number(totalPrice).toFixed(6))}원</td>
						</tr>
					);
				}
			});
		}
	};
	const mapDataToRow = (data) => {
		return data.map((row, i) => {
			const forKey = i;
			const { currency, amount, rate, korAmount } = row;
			return (
				<tr key={forKey}>
					<td>{currency.toLocaleString()}</td>
					<td>{amount.toLocaleString()}</td>
					<td>{rate.toLocaleString()}</td>
					<td>{korAmount.toLocaleString()}</td>
				</tr>
			);
		});
	};
	const fromFirstToNow = () => {
		// console.log('searchKeywords in 상세 모달', searchKeywords);
		if (
			searchKeywords &&
			searchKeywords.status &&
			searchKeywords.status === '이달'
		) {
			const firstOfThisMonth = moment('01', 'DD').format('YYYY.MM.DD');
			const atThisMoment = moment().format('YYYY.MM.DD');
			return `${firstOfThisMonth} - ${atThisMoment}`;
		}
		if (
			searchKeywords &&
			searchKeywords.status &&
			searchKeywords.status === '지난달'
		) {
			const firstOfTheMonth = moment().subtract(1, 'month');
			const endOfTheMonth = firstOfTheMonth.endOf('month').format('YYYY.MM.DD');

			return `${firstOfTheMonth
				.set({ date: 1 })
				.format('YYYY.MM.DD')} - ${endOfTheMonth}`;
		}
	};

	const floorPlusToLocaleString = (value) => {
		const test = Math.floor(value);
		return test.toLocaleString();
	};

	const fixedNumToLocaleString = (value) => {
		const test = Number(value);
		return test.toLocaleString();
	};

	/**
   *

    beforeDC: 429186
    freePrice: 156000
    : 273186
   *
   */

	const {
		androidSum,
		android,
		iosSum,
		ios,
		etc,
		freeDAU,
		chargeDAU,
		chargePrice,
		totalSum,
		type,
		freePrice,
		isExceed,
		// chargePrice,
		readValue,
		writeValue,
		readPrice,
		writePrice,
		totalPrice,
		chargePercent,
	} = gameBill && gameBill.sumResults;

	return (
		<Modal size="lg" isOpen={isOpen}>
			<ModalHeader>
				{gameTitle}
				<div>{fromFirstToNow()}</div>
			</ModalHeader>
			<ModalBody>
				{/* 환율 모달 */}
				<Modal isOpen={currencyModalData.isOpen}>
					<ModalHeader>상세 내역</ModalHeader>
					<ModalBody>
						<Table>
							<thead className="table-header-group">
								<tr>
									<th>통화</th>
									<th>금액</th>
									<th>환율</th>
									<th>원화</th>
								</tr>
							</thead>
							<tbody>
								{currencyModalData.data.length ? (
									mapDataToRow(currencyModalData.data)
								) : (
									<tr>
										<td colSpan="4">해당일 요금이 0원 입니다.</td>
									</tr>
								)}
							</tbody>
						</Table>
					</ModalBody>
					<ModalFooter>
						<Button
							color="primary"
							onClick={() =>
								handleCurrencyModalData({ isOpen: false, data: [] })
							}>
							닫기
						</Button>
					</ModalFooter>
				</Modal>
				{/* 환율 모달 ends here */}
				<Table
					className={
						billType === 'dau'
							? `main-table bill-detail-modal dau-table`
							: billType === 'share'
							? `main-table bill-detail-modal share-table`
							: billType === 'db'
							? `main-table bill-detail-modal db-table`
							: null
					}>
					<thead className="column-6">
						{/* <tr>{makeColumsRow(detailColumns)}</tr> */}
						{billType === 'dau' && <tr>{makeColumsRow(detailColumns)}</tr>}
						{billType === 'share' && (
							<tr>{makeColumsRow(shareDetailColumns)}</tr>
						)}
						{billType === 'db' && <tr>{makeColumsRow(dbDetailColumns)}</tr>}
					</thead>
					<tbody className="column-6">{makeRows(gameBill)}</tbody>
				</Table>
				<br />
				<br />
				<Table className="bill-detail-modal-sub-table">
					{billType === 'dau' && (
						<tbody>
							<tr>
								<td>Android DAU</td>
								<td>{android && android.toLocaleString()}</td>
								<td>iOS DAU</td>
								<td>{ios && ios.toLocaleString()}</td>
								<td>기타 DAU</td>
								<td>{etc && etc.toLocaleString()}</td>
							</tr>
							<tr>
								<td>무료 DAU</td>
								<td>
									{freeDAU === '0'
										? '0'
										: freeDAU && `- ${freeDAU.toLocaleString()}`}
								</td>
								<td>청구 DAU</td>
								<td>{chargeDAU && chargeDAU.toLocaleString()}</td>
								<td>청구금액</td>
								<td>{chargePrice && chargePrice.toLocaleString()}원</td>
							</tr>
						</tbody>
					)}
					{billType === 'share' && (
						<tbody>
							<tr>
								<td>Android 수익</td>
								<td>
									{androidSum === '-'
										? '-'
										: `${floorPlusToLocaleString(androidSum)}원`}
								</td>
								<td>iOS 수익</td>
								<td>
									{iosSum === '-'
										? '-'
										: `${floorPlusToLocaleString(iosSum)}원`}
								</td>
								<td>총 수익</td>
								<td>
									{totalSum === '-'
										? '-'
										: `${floorPlusToLocaleString(totalSum)}원`}
								</td>
							</tr>
							<tr>
								<td>적용요율</td>
								<td>
									{type === 'Silver'
										? '7%'
										: type === 'Gold'
										? '4%'
										: type === 'Dia'
										? '2%'
										: chargePercent}
								</td>
								<td>무료/과금 여부</td>
								<td>{isExceed === 'n' ? '무료' : '과금'}</td>
								<td>청구금액</td>
								<td>{floorPlusToLocaleString(chargePrice)}원</td>
							</tr>
						</tbody>
					)}
					{billType === 'db' && (
						<tbody>
							<tr>
								<td>읽기 처리량</td>
								{/* <td>{readValue}</td> */}
								<td>{fixedNumToLocaleString(readValue)}</td>
								<td>쓰기 처리량</td>
								<td>{fixedNumToLocaleString(writeValue)}</td>
								<td>읽기 요금</td>
								<td>
									{fixedNumToLocaleString(Number(readPrice).toFixed(6))}원
								</td>
								<td>쓰기 요금</td>
								<td>
									{fixedNumToLocaleString(Number(writePrice).toFixed(6))}원
								</td>
							</tr>
							<tr>
								<td>요금합계</td>
								<td>
									{fixedNumToLocaleString(Number(totalPrice).toFixed(6))}원
								</td>
								<td>원 미만 절사</td>
								<td>{totalPrice && floorPlusToLocaleString(totalPrice)}원</td>
								<td>무료제공</td>
								<td>- {fixedNumToLocaleString(freePrice)}원</td>
								<td>청구금액</td>
								<td>{fixedNumToLocaleString(chargePrice)}원</td>
							</tr>
						</tbody>
					)}
				</Table>
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={() => handleOpenDetailModal(false)}>
					닫기
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ShareChargeDetailModal;
