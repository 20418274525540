import { createSelector } from "reselect";

import moment from "moment";

export const topicsSelector = createSelector(
  (state) => state.getStarted.topics,
  (topics) => {
    console.log(topics, "topics in selector");
    return topics;
  }
);
export const listSelector = createSelector(
  // partition: class 또는 document를 포함하는 리스트
  (state) => state.getStarted.list,
  (list) => list
);
export const classParentListSelector = createSelector(
  (state) => state.getStarted.classParentList,
  (classParentList) => {
    console.log("0703 returnArr", classParentList);
    let returnArr;
    returnArr = classParentList.map((obj) => ({
      ...obj,
      name: obj.title,
      key: obj.inDate,
    }));
    // ({
    //   name: obj.title,
    //   key: obj.inDate,
    // }));
    // returnArr.unshift({ name: "없음" });
    // console.log('0703 returnArr', returnArr)
    return returnArr;
  }
);

export const documentSelector = createSelector(
  (state) => state.getStarted.document,
  (document) => document
);

export const versionListSelector = createSelector(
  (state) => state.getStarted.versionList,
  (versionList) => versionList
);

export const isClassDoneSelector = createSelector(
  (state) => state.getStarted.isClassDone,
  (isClassDone) => isClassDone
);

export const isDocDoneSelector = createSelector(
  (state) => state.getStarted.isDocDone,
  (isDocDone) => isDocDone
);

export const isDeleteClassDoneSelector = createSelector(
  (state) => state.getStarted.isDeleteClassDone,
  (isDeleteClassDone) => isDeleteClassDone
);

export const isDeleteTopicDoneSelector = createSelector(
  (state) => state.getStarted.isDeleteTopicDone,
  (isDeleteTopicDone) => isDeleteTopicDone
);

export const isUpdateDocDoneSelector = createSelector(
  (state) => state.getStarted.isUpdateDocDone,
  (isUpdateDocDone) => isUpdateDocDone
);

export const isDeleteDocDoneSelector = createSelector(
  (state) => state.getStarted.isDeleteDocDone,
  (isDeleteDocDone) => isDeleteDocDone
);

export const isMakeTopicDoneSelector = createSelector(
  (state) => state.getStarted.isMakeTopicDone,
  (isMakeTopicDone) => isMakeTopicDone
);

export const eachListSelector = createSelector(
  (state) => state.getStarted.test,
  (test) => {
    console.log(test, "0709 eachList selector");

    return test;
  }
);

export const isNewEachListSelector = createSelector(
  (state) => state.getStarted.isNewEachList,
  (isNewEachList) => isNewEachList
);

export const errObjSelector = createSelector(
  (state) => state.getStarted.errObj,
  (errObj) => errObj
);

export const testSelector = createSelector(
  (state) => state.getStarted,
  (getStarted) => {
    console.log(getStarted.isNewEachList, "in testSelector");
    console.log(getStarted.test, "in testSelector");
  }
);
