import { requestGet, requestPost, requestDelete } from "../../utils/request";
import Qs from "qs";

export const getCouponList = params => {
  const url = "coupon";

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: "brackets" });
    }
  };

  return requestGet(url, options);
};

export const getCouponDetails = (id, params) => {
  const url = `coupon/${id}`;

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: "brackets" });
    }
  };

  return requestGet(url, options);
};

export const issueCoupon = data => {
  const url = `coupon`;
  return requestPost(url, data);
};

export const deleteCoupon = id => {
  const url = `coupon/${id}`;
  return requestDelete(url);
};
