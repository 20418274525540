// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import { createSelector } from 'reselect';
import moment from 'moment';

export const deleteAccountSelector = createSelector(
  (state) => state.leftAsideMenu.whereTo,
  (whereTo) => whereTo
);

export const accountListSelector = createSelector(
  (state) => state.accountManaging.accountList,
  (accountList) => {
    return accountList;
  }
);
const checkAccountStatus = (status, stopScope) => {
  if (stopScope === 'all') return '전체 정지';
  if (status === 's') return '계정 정지';
  if (status === 'c') return '카드 미등록 정지';

  return '정상';
};
export const accountListSelectorForView = createSelector(
  (state) => state.accountManaging.accountList,
  (accountList) =>
    accountList.map((account) => {
      const chargedTotal = account.chargedTotal
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      return {
        ...account,
        // 화폐단위 정규식
        credit: account.credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        chargedTotal:
          account.country !== 'South Korea'
            ? `${chargedTotal} USD`
            : `${chargedTotal} KRW`,
        signUpDate: account.signUpDate
          ? moment(account.signUpDate).format('YYYY.MM.DD')
          : '-',
        lastLoginDate: account.lastLoginDate
          ? moment(account.lastLoginDate).format('YYYY.MM.DD')
          : '-',
        status: checkAccountStatus(account.status, account.stopScope.value),
        remoteSupportAgreement:
          account.remoteSupportAgreement === 'y' ? '조회' : '-',
      };
    })
);

export const searchedAccountListSelectorForView = createSelector(
  (state) => state.accountManaging.searchedAccountList,
  (searchedAccountList) => {
    if (searchedAccountList)
      return searchedAccountList.map((account) => {
        const chargedTotal = account.chargedTotal
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return {
          ...account,
          // 화폐단위 정규식
          credit: account.credit
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          chargedTotal:
            account.country !== 'South Korea'
              ? `${chargedTotal} USD`
              : `${chargedTotal} KRW`,
          signUpDate: account.signUpDate.isAfter(moment('2015-01-01'))
            ? moment(account.signUpDate).format('YYYY.MM.DD')
            : '-',
          lastLoginDate: account.lastLoginDate.isAfter(moment('2015-01-01'))
            ? moment(account.lastLoginDate).format('YYYY.MM.DD')
            : '-',
          status: checkAccountStatus(account.status, account.stopScope.value),
          remoteSupportAgreement:
            account.remoteSupportAgreement === 'y' ? '조회' : '-',
        };
      });
  }
);

export const memoSelector = createSelector(
  (state) => state.accountManaging.memo,
  (memo) => memo
);

export const adminAccountsSelector = createSelector(
  (state) => state.accountManaging.detail.adminAccounts,
  (adminAccounts) => adminAccounts
);

export const accountStatusSelector = createSelector(
  (state) => state.accountManaging.accountStatus,
  (accountStatus) => accountStatus
);
export const accountStatusSelectorForView = createSelector(
  (state) => state.accountManaging.accountStatus,
  (accountStatus) => {
    // console.log(accountStatus, "accountStatus 확인");
    if (accountStatus && accountStatus.length)
      return accountStatus.map((acc) => ({
        ...acc,
        status: acc.status === 'n' ? '정상' : '정지',
      }));
    return accountStatus;
    // return accountStatus;
  }
);

export const creditInfoSelector = createSelector(
  (state) => state.accountManaging.detail.creditInfo,
  (creditInfo) => creditInfo
);

export const clickedIdSelector = createSelector(
  (state) => state.accountManaging.detail,
  (detail) => detail
);

export const selectedAccountSelector = createSelector(
  (state) => state.accountManaging.detail,
  (detail) => detail.accountInfo
);

export const selectedAccountSelectorForView = createSelector(
  (state) => state.accountManaging.detail,
  (detail) => {
    const { accountInfo } = detail;
    if (accountInfo)
      return {
        ...accountInfo,
        chargedTotal: accountInfo.chargedTotal
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        credit: accountInfo.credit
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        totalDelayedPayments: accountInfo.totalDelayedPayments
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      };
  }
);

export const columnsSelector = createSelector(
  (state) => state.leftAsideMenu.columns,
  (columns) => columns
);

export const deletedAccountListSelectorForView = createSelector(
  //ForView
  (state) => state.accountManaging.deletedAccountList,
  (deletedAccountList) => {
    if (deletedAccountList && deletedAccountList.length)
      return deletedAccountList.map((acc) => {
        return {
          //   account_id: acc.account_id,
          account_id: acc.email,
          inDate: moment(acc.inDate).format('YYYY.MM.DD HH:mm'),
          actualInDate: { actualInDate: acc.inDate, noDisplay: true },
          type: acc.type,
          admin_Id: acc.admin_name,
          reason: acc.details.split(',').map((str) => `${str}\n`),
        };
      });
    if (deletedAccountList && !deletedAccountList.length) return [];
  }
);
export const deletedAccountListSelector = createSelector(
  // 원본 데이터
  (state) => state.accountManaging.actualDeletedAccountList,
  (actualDeletedAccountList) => actualDeletedAccountList
);

// 검색 프로토타입 - 탈퇴 계정
export const searchItemSelector = createSelector(
  (state) => state.accountManaging.searchItem,
  (searchItem) => searchItem
);

// 검색 프로토타입 - 일반 계정
export const searchKeywordsSelector = createSelector(
  (state) => state.accountManaging.searchKeywords,
  (searchKeywords) => searchKeywords
);

// 검색 프로토타입 - 일반 계정에서 검색 시도
export const searchedAccountListSelector = createSelector(
  (state) => state.accountManaging.searchedAccountList,
  (searchedAccountList) => searchedAccountList
);

// 검색 프로토타입 - 탈퇴 계정에서 검색 시도
export const searchedDelAccountListSelector = createSelector(
  //ForView
  (state) => state.accountManaging.searchedDelAccountList,
  (searchedDelAccountList) => searchedDelAccountList
);

export const actualSearchedDelAccountListSelector = createSelector(
  //ForView
  (state) => state.accountManaging.originalSearchedDelAccountList,
  (originalSearchedDelAccountList) => originalSearchedDelAccountList
);

export const errorsSelector = createSelector(
  (state) => state.accountManaging.errors,
  (errors) => errors
);

export const sortingParamSelector = createSelector(
  (state) => state.accountManaging.sortingParam,
  (sortingParam) => sortingParam
);

export const isLastSelector = createSelector(
  (state) => state.accountManaging.isLast,
  (isLast) => isLast
);

export const countSelector = createSelector(
  (state) => state.accountManaging.count,
  (count) => count
);

export const offsetSelector = createSelector(
  (state) => state.accountManaging.offsetInfo,
  (offsetInfo) => offsetInfo
);

export const LastEvaluatedKeySelector = createSelector(
  (state) => state.accountManaging.LastEvaluatedKey,
  (LastEvaluatedKey) => LastEvaluatedKey
);

export const accountTypeSelector = createSelector(
  (state) => state.accountManaging.accountType,
  (accountType) => accountType[0]?.account_type ?? 0
  // desc. 0이 일반 규모, 1 = 1억클럽, 10 = 10억..
  // TODO : 변경 이력을 보여줘야 할 수도 있음
);
