// Copyright 2013-2022 AFI, Inc. All Rights Reserved.
export const numberToString = (num, fractionDigits, overrideFraction) => {
  if (typeof num !== 'number') {
    return num ? num.toString() : null;
  }
  if (fractionDigits === 0) {
    // -0은 0으로 처리
    return Math.floor(Object.is(num, -0) ? -num : num).toLocaleString();
  }
  // -0은 0으로 처리
  return (Object.is(num, -0) ? -num : num).toLocaleString(undefined, {
    minimumFractionDigits: overrideFraction ? null : fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
};
