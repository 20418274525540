import { LOCATION_CHANGE, routerActions } from "react-router-redux";

// import * as selectors from './selector';
import * as types from "./constant";

const initialState = {
  couponMeta: [],
  columns: [
    { label: "쿠폰명" },
    { label: "크레딧", field: "creditPrice" },
    { label: "발행수", field: "numberOfCoupon" },
    { label: "사용수", field: "numberOfUsed" },
    { label: "회수율", field: "recovery" },
    { label: "발행일", field: "issuedDate" },
    { label: "작성자" },
    { label: "" },
  ],
  /**
   * : 20000
: 10
: 7
: "2020-03-13T01:00:30.000Z"
admin: "minjikim"
: 70
   */
  detailColumns: [
    { label: "쿠폰코드" },
    { label: "사용자" },
    { label: "등록일" },
    { label: "유효기간" },
  ],
  couponDetails: [],
  detail: { clickedId: "", couponDetail: false },
  isErr: undefined,
  errors: undefined,
  searchKeywords: {},
  isLast: undefined,
  sortingParam: false,
  detailOffset: 0,
  couponListOffset: 0,
  isSearching: false
};

function coupon(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...initialState,
      };
    case types.GET_COLUMNS:
      return {
        ...state,
        columns: action.columns,
      };

    case types.CLICK_COUPON_DETAIL_ROW:
      return {
        ...state,
        couponTitle: action.id ? action.id.couponTitle : state.couponTitle,
        id: action.arr,
        detail: { clickedId: "", couponDetail: true },
      };

    case types.HANDLE_CLOSE_COUPON_DETAIL_MODAL:
      const { detailOffset, couponDetails } = initialState;
      return {
        ...state,
        detail: { clickedId: "", couponDetail: false },
        detailOffset: detailOffset,
        couponDetails: couponDetails,
      };

    case types.GET_OVERDUE_MANAGE_ROW_SUCCESS:
      return {
        ...state,
        overdueBillInfo: action.overdueBillInfo,
      };

    case types.HANDLE_CLOSE_MSG_MODAL:
      return {
        ...state,
        isMsgModalOpen: false,
      };

    case types.CONFIRM_CHANGE_STATUS:
      return {
        ...state,
        detail: { clickedId: "", billManage: false },
      };
    // actions in use
    case types.GET_COUPON_LIST_SUCCESS:
      return {
        ...state,
        couponMeta:
          action.rows && action.rows.length
            ? state.isSearching ? action.rows : [...state.couponMeta, ...action.rows]
            : state.couponDetails,
        isLast: action.isLast,
        couponListOffset: state.couponListOffset + 15,
        isSearching: false
      };

    case types.INIT_COUPON_LIST:
      return {
        ...state,
        couponListOffset: 0,
        isSearching: false
      };
    case types.INIT_COUPON_LIST_SUCCESS:
      return {
        ...state,
        couponMeta: action.rows,
        isLast: action.isLast,
        couponListOffset: 15,
        isSearching: false
      };

    case types.GET_COUPON_DETAILS_SUCCESS:
      return {
        ...state,
        couponDetails:
          action.rows && action.rows.length
            ? [...state.couponDetails, ...action.rows]
            : state.couponDetails,
        isDetailLast: action.isLast,
        detailOffset: state.detailOffset + 10,
      };
    case types.HANDLE_ERRORS:
      return {
        ...state,
        errors: action.obj,
        isErr: true,
      };
    case types.GET_SEARCH_KEYWORDS:
      return {
        ...state,
        searchKeywords: {
          ...state.searchKeywords,
          ...action.obj,
        },
        isSearching: true
      };
    case types.SORT_ASC_COUPON_LIST:
      return {
        ...state,
        couponMeta: action.rows,
        sortingParam: !state.sortingParam,
      };
    case types.SORT_DESC_COUPON_LIST:
      return {
        ...state,
        couponMeta: action.rows,
        sortingParam: !state.sortingParam,
      };

    default:
      return state;
  }
}

export default coupon;
