import { createSelector } from "reselect";

import moment from "moment";

export const contentSelector = createSelector(
  // 원본
  state => state.terms.contents,
  contents => ({content: contents})
);

export const currentContentsSelector = createSelector(
  // 수정된 내용을 셀렉트
  state => state.terms.currentContents,
  currentContents => ({content: currentContents})
);

export const lastModifiedSelector = createSelector(
  // 수정된 내용을 셀렉트
  state => state.terms.lastModified,
  lastModified => {
    return moment(lastModified).format('YYYY.MM.DD HH:mm')
  }
);

// export const termsSelector = createSelector(
//   // 생성시 내용을 셀렉트
//   state => state.terms.terms,
//   terms => {
//     const { contents, inDate } = terms[0];
//     return {
//       contents, inDate
//     }
//   }
// );