import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import ButtonsGroup from '../../../fnComponents/buttonGroup';
import CreditModal from './CreditModal';
import DataTable from '../../../fnComponents/_DataTable';
import DefaultAxios from '../../../utils/DefaultAxios';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Toggle from 'react-bootstrap-toggle';

import * as actions from '../store/action';
import * as selectors from '../store/selector';

const AccountDetail = ({
  memo,
  accountStatus,
  selectedAccount,
  adminAccounts,
  onClickAccountRow,
  onSaveInput,
  onGetBackToList,
  onGetAdminAccounts,
  onGetCreditInfo,
  onGetAccountStatus,
  onDeleteRow,
  errors,
  onInitErrors,
  onHandleErrors,
  onInitAccountList,
  _onInitAccountList,
  onUpdateInternalAccount,
  onUpdateAutoPay,
}) => {
  const [isOpen, handleOpenModal] = useState(false);
  const [isAdminAccountModalOpen, handleOpenAdminAccount] = useState(false);
  const [isCreditModalOpen, _handleOpenCreditModal] = useState(false);
  const [isAccountManageModalOpen, handleOpenAccountManageModal] =
    useState(false);
  const [radioBtn, handleBtnChange] = useState({
    normal: false,
    isDirty: false,
    // blocked: false,
  });
  const [rowStatus, setRowStatus] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const [inputChange, saveInputChange] = useState({
    memoInput: '',
    statusReason: '',
  });

  const [errMsg, handleError] = useState('');
  const [using, handleUsing] = useState({ internalAccount: '', isAutoPay: '' });

  useEffect(() => {
    if (selectedAccount) {
      saveInputChange('');
      setRowStatus(
        accountStatus &&
          accountStatus[0] &&
          accountStatus[0].stopScope.value === 'all'
          ? 'all'
          : selectedAccount.status
      );
    }
  }, [selectedAccount, accountStatus]);

  const confirmDeleteAccount = () => {
    if (!inputChange.withdrawReason) {
      console.log('ERROR', errMsg);
      handleError('noReason');
      return;
    }

    DefaultAxios({
      method: 'delete',
      url: `account/${selectedAccount.ID}`,
      data: { details: inputChange.withdrawReason },
    }).then((res) => {
      handleOpenModal(false);
      onInitAccountList(null, null, true);
      onGetBackToList();
      handleError('');
    });

    // onConfirmDeleteAccount();
  };

  const handleAccountManage = () => {
    handleOpenAccountManageModal(true);
    onGetAccountStatus(selectedAccount.ID);
  };

  const handleAccountStatus = (status) => {
    setRowStatus(status);
    setIsDisabled(false);
  };

  const handleInputChange = (e) => {
    onInitErrors();
    saveInputChange({ ...inputChange, [e.target.id]: e.target.value });
  };

  const handleAdminAccount = () => {
    handleOpenAdminAccount(true);
    onGetAdminAccounts(selectedAccount.ID);
  };

  const handleOpenCreditModal = (bool) => {
    _handleOpenCreditModal(bool);
    if (bool) onGetCreditInfo(selectedAccount.ID);
    if (!bool) onClickAccountRow(selectedAccount.ID);
  };

  const getBackToList = () => {
    onGetBackToList();
    _onInitAccountList();
  };

  const handleToggleActive = (e, obj) => {
    let _using = {};

    if (obj.id === 'isAutoPay') {
      if (using.isAutoPay === 'y') _using.isAutoPay = 'n';
      if (using.isAutoPay === 'n') _using.isAutoPay = 'y';

      handleUsing({ ...using, ..._using });
      console.log(_using);
      onUpdateAutoPay(selectedAccount.ID, _using.isAutoPay);
    }
    if (obj.id === 'internalAccount') {
      if (using.internalAccount === 'y') _using.internalAccount = 'n';
      if (using.internalAccount === 'n') _using.internalAccount = 'y';
      handleUsing({ ...using, ..._using });
      onUpdateInternalAccount(selectedAccount.ID, _using.internalAccount);
    }
  };

  useEffect(() => {
    if (selectedAccount) {
      handleUsing({
        ...using,
        internalAccount: selectedAccount.internalAccount,
        isAutoPay: selectedAccount.isAutoPay,
      });
    }
  }, [selectedAccount]);

  return (
    <div className="m-grid__item m-grid__item--fluid m-wrapper">
      <div className="m-content">
        <div className="row">
          <div className="col-md-12">
            <div className="m-portlet">
              <div className="m-portlet__head">
                <div className="m-portlet__head-tools m--pull-left">
                  <span className="m-portlet__head-icon m--hide">
                    <i className="la la-gear" />
                  </span>
                  <h3 className="m-portlet__head-text">계정 상세 정보</h3>
                </div>
                <div className="m-portlet__head-tools m--pull-right">
                  <button
                    type="button"
                    onClick={() => handleOpenModal(true)}
                    className="btn btn-danger btn-sm cm_withdraw mr-3"
                  >
                    <i className="la la-warning" /> 강제 탈퇴
                  </button>
                  <button
                    type="button"
                    onClick={getBackToList}
                    className="btn btn-danger btn-sm cm_withdraw"
                  >
                    <i className="la" /> 목록
                  </button>
                </div>
              </div>

              {isOpen && selectedAccount.totalDelayedPayments === '0' ? (
                <Modal isOpen={isOpen}>
                  <ModalHeader>강제탈퇴</ModalHeader>
                  <ModalBody>
                    정말 강제탈퇴 처리 하시겠습니까?
                    <br />
                    탈퇴 처리된 회원정보는 모두 삭제됩니다.
                    <div className="form-group m-form__group row mt-30">
                      <label
                        for="statusReason"
                        className="col-lg-2 col-form-label"
                      >
                        * 사유
                      </label>
                      <div className="col-lg-10">
                        <input
                          onChange={handleInputChange}
                          type="type"
                          className="form-control"
                          id="withdrawReason"
                          required
                        />
                        {errMsg && (
                          <div className="error message visible">
                            사유는 필수 입력 항목입니다.
                          </div>
                        )}
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={confirmDeleteAccount}>
                      확인
                    </Button>{' '}
                    <Button
                      color="secondary"
                      onClick={() => {
                        handleError('');
                        handleOpenModal(false);
                      }}
                    >
                      취소
                    </Button>
                  </ModalFooter>
                </Modal>
              ) : (
                <Modal isOpen={isOpen}>
                  <ModalHeader> </ModalHeader>
                  <ModalBody>
                    미납요금 또는 연체요금이 존재합니다.
                    <br />
                    정말 강제탈퇴 처리 하시겠습니까?
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={confirmDeleteAccount}>
                      확인
                    </Button>{' '}
                    <Button
                      color="secondary"
                      onClick={() => handleOpenModal(false)}
                    >
                      취소
                    </Button>
                  </ModalFooter>
                </Modal>
              )}

              {isAdminAccountModalOpen && (
                <Modal size="lg" isOpen={isAdminAccountModalOpen}>
                  <ModalHeader>관리자 계정 상세 </ModalHeader>
                  <ModalBody>
                    <ButtonsGroup />
                    <DataTable
                      customClassName="column-5 table-scrollable"
                      columns={[
                        { label: '아이디' },
                        { label: '닉네임' },
                        { label: '이름' },
                        { label: '생성일시' },
                        { label: '최종접속일시' },
                      ]}
                      rows={adminAccounts}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={() => handleOpenAdminAccount(false)}
                    >
                      닫기
                    </Button>{' '}
                  </ModalFooter>
                </Modal>
              )}

              <form className="m-form m-form--fit m-form--label-align-right m-form--group-seperator">
                <div className="m-portlet__body">
                  <div className="form-group m-form__group row">
                    <label className="col-lg-1 col-form-label">아이디</label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.email}
                    </div>
                    <label className="col-lg-1 col-form-label">닉네임</label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.nickname}
                    </div>
                    <label className="col-lg-1 col-form-label">계정 상태</label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.status === 's'
                        ? selectedAccount.stopScope === 'all'
                          ? '전체 정지'
                          : '계정 정지'
                        : selectedAccount && selectedAccount.status === 'c'
                        ? '카드 미등록 정지'
                        : '정상'}
                      <button
                        onClick={handleAccountManage}
                        type="button"
                        className="btn btn-sm btn-outline-brand"
                        data-toggle="modal"
                        data-target="#modal_status"
                      >
                        관리
                      </button>
                    </div>
                  </div>
                  {isAccountManageModalOpen && (
                    <Modal size="lg" isOpen={isAccountManageModalOpen}>
                      <ModalHeader>계정 상태 관리 </ModalHeader>
                      <ModalBody>
                        <div className="form-group m-form__group row">
                          <label
                            for="username"
                            className="col-lg-2 col-form-label"
                          >
                            계정
                          </label>
                          <div className="col-lg-10">
                            <span className="form_text">
                              {selectedAccount && selectedAccount.email}
                            </span>
                          </div>
                        </div>
                        <div className="form-group m-form__group row">
                          <label
                            for="email-contents"
                            className="col-lg-2 col-form-label"
                          >
                            상태
                          </label>
                          <div className="col-lg-10">
                            <div className="m-radio-inline">
                              <label className="m-radio">
                                <input
                                  onChange={() => {
                                    handleAccountStatus('n');
                                  }}
                                  type="radio"
                                  name="credit-radio"
                                  checked={rowStatus === 'n'}
                                />
                                정상
                                <span />
                              </label>
                              <label className="m-radio">
                                <input
                                  onChange={() => {
                                    handleAccountStatus('s');
                                  }}
                                  type="radio"
                                  name="credit-radio"
                                  checked={rowStatus === 's'}
                                />
                                계정 정지
                                <span />
                              </label>
                              <label className="m-radio">
                                <input
                                  onChange={() => {
                                    handleAccountStatus('c');
                                  }}
                                  type="radio"
                                  name="credit-radio"
                                  checked={rowStatus === 'c'}
                                />
                                카드 미등록 정지
                                <span />
                              </label>
                              <label className="m-radio">
                                <input
                                  onChange={() => {
                                    handleAccountStatus('all');
                                  }}
                                  type="radio"
                                  name="credit-radio"
                                  checked={rowStatus === 'all'}
                                />
                                전체 정지
                                <span />
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* a row for INPUT */}
                        <div className="form-group m-form__group row">
                          <label
                            for="statusReason"
                            className="col-lg-2 col-form-label"
                          >
                            * 사유
                          </label>
                          <div className="col-lg-10">
                            <input
                              onChange={handleInputChange}
                              type="type"
                              className="form-control"
                              id="statusReason"
                              required
                              disabled={isDisabled}
                              value={inputChange.statusReason}
                            />
                            <div className="error message visible">
                              {errors && errors.accountStatus}
                            </div>
                          </div>
                        </div>
                        <DataTable
                          customClassName="column-4 table-scrollable"
                          columns={[
                            { label: '일자' },
                            { label: '구분' },
                            { label: '사유' },
                            { label: '관리자' },
                          ]}
                          rows={accountStatus}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          disabled={isDisabled}
                          color="primary"
                          onClick={async () => {
                            if (!inputChange.statusReason)
                              return onHandleErrors({
                                accountStatus: '사유는 필수 입력 항목입니다.',
                              });
                            await onSaveInput(selectedAccount.ID, {
                              type: 'accountStatus',
                              details: inputChange.statusReason,
                              status: rowStatus === 'all' ? 's' : rowStatus,
                              stop_scope: rowStatus === 'all' ? 'all' : null,
                            });
                            await saveInputChange({ statusReason: '' });
                            await handleOpenAccountManageModal(false);
                          }}
                        >
                          적용
                        </Button>{' '}
                        <Button
                          color="secondary"
                          onClick={() => {
                            handleBtnChange({
                              normal:
                                selectedAccount.status === 'n' ? true : false,
                              isDirty: false,
                            });
                            handleOpenAccountManageModal(false);
                          }}
                        >
                          취소
                        </Button>
                      </ModalFooter>
                    </Modal>
                  )}

                  <div className="form-group m-form__group row">
                    <label className="col-lg-1 col-form-label">회사명</label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.company}
                    </div>
                    <label className="col-lg-1 col-form-label">연락처</label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.phoneNumber}
                    </div>
                    <label className="col-lg-1 col-form-label">결제정보</label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.payment
                        ? '등록'
                        : '미등록'}
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <label className="col-lg-1 col-form-label">가입일</label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.signUpDate}
                    </div>
                    <label className="col-lg-1 col-form-label">
                      최종 접속일
                    </label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.lastLoginDate}
                    </div>
                    <label className="col-lg-1 col-form-label">프로젝트</label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.project}
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <label className="col-lg-1 col-form-label">
                      관리자 계정
                    </label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.admin}
                      <button
                        onClick={handleAdminAccount}
                        type="button"
                        className="btn btn-sm btn-outline-brand"
                        data-toggle="modal"
                        data-target="#modal_subadmin"
                      >
                        상세
                      </button>
                    </div>
                    <label className="col-lg-1 col-form-label">크레딧</label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.credit}
                      <button
                        onClick={() => handleOpenCreditModal(true)}
                        type="button"
                        className="btn btn-sm btn-outline-brand"
                        data-toggle="modal"
                        data-target="#modal_credit"
                      >
                        지급
                      </button>
                    </div>
                    <label className="col-lg-1 col-form-label">자동결제</label>
                    <div className="col-lg-3 form-view">
                      <Toggle
                        id="isAutoPay"
                        onClick={handleToggleActive}
                        on=" "
                        off=" "
                        width="null"
                        height="null"
                        size="xs"
                        offstyle="danger"
                        active={using.isAutoPay === 'y' ? true : false}
                      />
                    </div>
                  </div>
                  {isCreditModalOpen && (
                    <CreditModal
                      selectedAccount={selectedAccount}
                      onSaveInput={onSaveInput}
                      isCreditModalOpen={isCreditModalOpen}
                      handleOpenCreditModal={handleOpenCreditModal}
                    />
                  )}
                  <div className="form-group m-form__group row">
                    <label className="col-lg-1 col-form-label">
                      총 납부요금
                    </label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.chargedTotal} 원
                    </div>
                    <label className="col-lg-1 col-form-label">연체요금</label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.totalDelayedPayments}{' '}
                      원
                    </div>
                    <label className="col-lg-1 col-form-label">
                      테스트 계정
                    </label>
                    <div className="col-lg-3 form-view">
                      <Toggle
                        id="internalAccount"
                        onClick={handleToggleActive}
                        on=" "
                        off=" "
                        width="null"
                        height="null"
                        size="xs"
                        offstyle="danger"
                        active={using.internalAccount === 'y' ? true : false}
                      />
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <label className="col-lg-1 col-form-label">메일수신</label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.mailAdAgreement}
                    </div>
                    <label className="col-lg-1 col-form-label">문자수신</label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.smsAdAgreement}
                    </div>
                    <label className="col-lg-1 col-form-label">유입매체</label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.acquisition}
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <label className="col-lg-1 col-form-label">고객규모</label>
                    <div className="col-lg-3 form-view">
                      {selectedAccount && selectedAccount.is100mClub}
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <label className="col-lg-1 col-form-label tline">
                      메모
                    </label>
                    <div className="col-lg-11">
                      <div className="input-group m--margin-bottom-15">
                        <input
                          id="memo"
                          type="text"
                          className="form-control"
                          placeholder="입력하세요."
                          onChange={handleInputChange}
                          value={inputChange.memo}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                              console.log(e.keyCode);
                              onSaveInput(selectedAccount.ID, {
                                type: 'memo',
                                memo: inputChange.memo,
                              });
                              saveInputChange({
                                ...inputChange,
                                memo: '',
                              });
                            }
                          }}
                        />
                        <div className="input-group-append">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              onSaveInput(selectedAccount.ID, {
                                type: 'memo',
                                memo: inputChange.memo,
                              });
                              saveInputChange({
                                ...inputChange,
                                memo: '',
                              });
                            }}
                            className="btn btn-primary"
                            type="button"
                          >
                            입력
                          </button>
                        </div>
                      </div>
                      <DataTable
                        customClassName="column-4 account-detail-memo table-scrollable"
                        selectedId={selectedAccount && selectedAccount.ID}
                        secondRequiredAction={onDeleteRow}
                        deleteRow
                        rows={memo}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  memo: selectors.memoSelector,
  accountStatus: selectors.accountStatusSelectorForView,
  searchKeywords: selectors.searchKeywordsSelector,
  selectedAccount: selectors.selectedAccountSelectorForView,
  adminAccounts: selectors.adminAccountsSelector,
  errors: selectors.errorsSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onClickAccountRow: (id, type) => dispatch(actions.clickAccountRow(id, type)),
  onConfirmDeleteAccount: (id, withdrawReason) =>
    dispatch(actions.confirmDeleteAccount(id, withdrawReason)),
  onSaveInput: (id, value) => dispatch(actions.saveInput(id, value)),
  onGetBackToList: () => dispatch(actions.getBackToList()),
  onGetAdminAccounts: (id) => dispatch(actions.getAdminAccounts(id)),
  onGetCreditInfo: (id) => dispatch(actions.getCreditInfo(id)),
  onGetAccountStatus: (id) => dispatch(actions.getAccountStatus(id)),
  onDeleteRow: (inDate, arr, id) =>
    dispatch(actions.deleteRow(inDate, arr, id)),
  onInitErrors: () => dispatch(actions.initErrors()),
  onHandleErrors: (errors) => dispatch(actions.handleErrors(errors)),
  onInitAccountList: (date, isSearching, isInit) =>
    dispatch(actions.initAccountList(date, isSearching, isInit)),
  _onInitAccountList: () => dispatch(actions._initAccountList()),
  onUpdateInternalAccount: (id, internalAccount) =>
    dispatch(actions.updateInternalAccount(id, internalAccount)),
  onUpdateAutoPay: (id, isAutoPay) =>
    dispatch(actions.updateAutoPay(id, isAutoPay)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetail);
