// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { browserHistory } from 'react-router';
import Octicon, { Search, X } from '@primer/octicons-react';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Cookies from 'js-cookie';

import AccountDetail from './AccountDetail';
import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import ButtonsGroup from '../../../fnComponents/buttonGroup';
import DataTable from '../../../fnComponents/_DataTable';
import DateRangePicker from '../../../fnComponents/DateRangePicker';
import ButtonDropdown from '../../../fnComponents/ButtonDropdown';
import MsgModal from '../../../fnComponents/MsgModal';
import CsvUploadButton from '../../../fnComponents/csvUploadButton';

import * as actions from '../store/action';
import * as globalAction from '../../globalStore/action';
import * as selectors from '../store/selector';
import {
  errMsgSelector,
  errCodeSelector,
  csvRowsSelector,
} from '../../globalStore/selector';
import DefaultAxios from '../../../utils/DefaultAxios';

const AccountManaging = ({
  whereTo,
  columns,
  accountList,
  accountListForView,
  searchedAccountList,
  searchedAccountListForView,
  searchKeywords,
  onClickAccountRow,
  detail,
  onSortAscNormalAccounts,
  onSortDescNormalAccounts,
  onGetSearchKeywords,
  onSearchAccounts,
  onInitSearchResult,
  sortingParam,
  onInitAccountList,
  isLast,
  count,
  onInitCurrentAccountList,
  onInitToSearchAccounts,
  onGetAccessToken,
}) => {
  const csvRows = useSelector(csvRowsSelector);
  const errMsg = useSelector(errMsgSelector);
  const errCode = useSelector(errCodeSelector);
  const _accountType = useSelector(selectors.accountTypeSelector);
  const dispatch = useDispatch();
  const usernameToDisplay = Cookies.get('usernameToDisplay');

  useEffect(() => {
    const {
      joinEndDate,
      joinStartDate,
      status,
      signUpDate,
      lastLoginDate,
      email,
      companyName,
    } = searchKeywords;

    if (signUpDate) {
      handleInput({
        ...inputObj,
        signUpDate,
      });
    }
    if (lastLoginDate) {
      handleInput({
        ...inputObj,
        lastLoginDate,
      });
    }

    if (window.location.href.split('/')[3] !== 'accountManaging')
      return () => {
        //   초기화
        onInitSearchResult();
      };
  }, []); // dependency를 갖는 배열의 값이 바뀌면 useEffect 호출됨

  const initInputObj = {
    id: '',
    company: '',
    signUpDate: '',
    lastLoginDate: '',
    status: '',
    init: false,
  };
  const initInputObjForChannels = {
    init: false,
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState('');

  const [focusByIcon, setFocus] = useState(null);
  const [inputObj, handleInput] = useState(initInputObj);
  const [inputObjForChannels, handleInputForChannels] = useState(
    initInputObjForChannels
  );

  const [isSearching, setSearchFilter] = useState(false);

  const dropdownItems = [
    {
      name: '전체',
    },
    {
      name: '정상',
    },
    {
      name: '정지',
    },
    {
      name: '카드 미등록 정지',
    },
  ];

  const dropdownItemsForChannels = [
    {
      name: '전체',
    },
    {
      name: '자연유입',
    },
    {
      name: '구글애즈',
    },
  ];

  const getAccountList = () => {
    if (isSearching && startDate && endDate)
      return onSearchAccounts({ startDate, endDate });
    if (isSearching) return onSearchAccounts();
    onInitAccountList();
  };

  const handleDownloadIdList = async () => {
    const { data } = await DefaultAxios({
      url: `account/marketing`,
      method: 'get',
    });

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const gameIndate = '프로젝트 생성일';
    const googleReleasedIndate = '구글 출시일';
    const appleReleasedIndate = '애플 출시일';
    const lastLoginDate = '최종 접속일';

    const ws = XLSX.utils.json_to_sheet(
      data.map((row) => ({
        아이디: row.email,
        회사명: row.companyName,
        가입일: row.inDate ? moment(row.inDate).format('YYYY-MM-DD HH:mm') : '',
        게임명: row.gameTitle,
        [gameIndate]: row.gameIndate
          ? moment(row.gameIndate).format('YYYY-MM-DD HH:mm')
          : '',
        [googleReleasedIndate]: row.googleReleasedIndate
          ? moment(row.googleReleasedIndate).format('YYYY-MM-DD HH:mm')
          : '',
        [appleReleasedIndate]: row.appleReleasedIndate
          ? moment(row.appleReleasedIndate).format('YYYY-MM-DD HH:mm')
          : '',
        [lastLoginDate]: moment(row.latestConnect).format('YYYY-MM-DD HH:mm'),
      }))
    );
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const _data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(
      _data,
      `ID-list-${moment().format('YYYY.MM.DD HH:mm')}` + fileExtension
    );
  };

  const handleKeyDown = async (e) => {
    if (e.keyCode === 13) {
      await onInitToSearchAccounts();
      await e.preventDefault();
      await onSearchAccounts();
      setSearchFilter(true);
    }
  };

  const common = {
    entriesFunc: (param, toReturn) => {
      let object = {};
      param.forEach((obj, i) => {
        const [arr] = Object.entries(obj);
        object[arr[0]] = arr[1];
      });
      return object[toReturn];
    },
  }; // TODO: common functions 분리 필요

  const handleClickAccountRow = (param) => {
    const id = common.entriesFunc(param, '_account_id');
    onClickAccountRow(id);
  };

  const handleClickProjects = (param) => {
    const id = common.entriesFunc(param, '_account_id');

    browserHistory.push({
      pathname: '/projects',
      search: `?accountId=${id}`,
    });
  };

  const handleRemoteSupportAgreementClick = (row) => {
    // TODO: 일단 하드코딩으로 특정 계정만 콘솔 조회를 사용할 수 있도록함. 쿠키에 저장된 닉네임이 기준이라 취약함
    if (
      ['admin', 'llh8815', 'reia', 'kimhassan', 'sunny', 'twins48'].includes(
        usernameToDisplay
      )
    ) {
      const accountId = common.entriesFunc(row, '_account_id');
      onGetAccessToken(accountId);
    } else {
      alert('허가된 계정만 사용할 수 있습니다.');
    }
  };

  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);

  useEffect(() => {
    if (errMsg) {
      if (!errCode) setErrorStatus(errMsg);
    } else if (csvRows) setErrorStatus(null);
  }, [errMsg, csvRows]);

  const handlePostCredit = () => {
    if (!csvRows) {
      setErrorStatus('대상을 업로드해 주세요.');
    } else {
      dispatch(actions.handlePostCredit({ csvRows }));
      setErrorStatus(null);
      setShouldOpenModal(false);
      dispatch(globalAction.uploadCsvFile(null));
    }
  };

  const [accountTypeModal, setAccountTypeModal] = useState(null);
  const [accountType, setAccountType] = useState(null);

  useEffect(() => {
    if (_accountType >= 0) setAccountType(_accountType);
  }, [_accountType]);

  const handlePostIs100mClub = () => {
    dispatch(
      actions.putAccountType({
        accountId: accountTypeModal.accountId,
        accountType,
      })
    );
    setAccountTypeModal(null);
    setAccountType(null);
  };

  const handleIs100mClub = (param) => {
    const obj = {};
    param.forEach((item) => {
      const aK = Object.keys(item)[0];

      obj.email = item.ID ? item.ID : obj.email;
      obj.company = item.company ? item.company : obj.company;
      obj.accountId = item._account_id ? item._account_id : obj.accountId;
    });
    // GET_고객타입 액션 디스패치
    dispatch(actions.getAccountType(obj.accountId));
    setAccountTypeModal(obj);
  };

  const is100mClubForm = (
    <form>
      <div className="form-group m-form__group row">
        <label htmlFor="email-contents" className="col-lg-2 col-form-label">
          계정 *
        </label>
        <div className="col-lg-3 form-view">
          {accountTypeModal ? accountTypeModal.email : null}
        </div>
      </div>
      <div className="form-group m-form__group row">
        <label htmlFor="email-contents" className="col-lg-2 col-form-label">
          회사명 *
        </label>
        <div className="col-lg-3 form-view">
          {accountTypeModal ? accountTypeModal.company : null}
        </div>
      </div>
      <div className="form-group m-form__group row">
        <label htmlFor="email-contents" className="col-lg-2 col-form-label">
          고객규모 *
        </label>
        <div className="col-lg-6 form-view">
          <div className="m-radio-inline" style={{ marginTop: 0 }}>
            <label className="m-radio">
              <input
                checked={accountType === 0}
                id="normal"
                onChange={(e) => {
                  setAccountType(0);
                }}
                type="radio"
                name="credit-radio"
              />
              일반
              <span />
            </label>
            <label className="m-radio">
              <input
                checked={accountType === 1}
                onChange={(e) => setAccountType(1)}
                type="radio"
                name="credit-radio"
              />
              1억클럽
              <span />
            </label>
            <label className="m-radio">
              <input
                checked={accountType === 10}
                onChange={(e) => setAccountType(10)}
                type="radio"
                name="credit-radio"
              />
              10억클럽
              <span />
            </label>
            <label className="m-radio">
              <input
                checked={accountType === 30}
                onChange={(e) => setAccountType(30)}
                type="radio"
                name="credit-radio"
              />
              30억클럽
              <span />
            </label>
          </div>
        </div>
      </div>
    </form>
  );

  const clickableIndex = [0, 5, 11, 13];
  const indexMethodArr = [
    { index: 0, method: handleClickAccountRow },
    { index: 5, method: handleClickProjects },
    {
      index: 11,
      method: (param) => handleIs100mClub(param),
    },
    {
      index: 13,
      method: handleRemoteSupportAgreementClick,
    },
  ];

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <Octicon icon={X} />
        </button>
        <LeftAsideMunu />
        {!detail.clickedId ? (
          <div className="m-grid__item m-grid__item--fluid m-wrapper">
            <div className="m-subheader">
              <div className="d-flex align-items-center">
                <div className="mr-auto">
                  <h3 className="m-subheader__title m-subheader__title--separator">
                    {/* {whereTo !== 'deleteAccount' ? '일반 계정' : '탈퇴 계정'} */}
                    일반 계정
                  </h3>
                  <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
                    <li className="m-nav__item m-nav__item--home">
                      <a href="dummy" className="m-nav__link m-nav__link--icon">
                        <i className="m-nav__link-icon la la-home" />
                      </a>
                    </li>
                    <li className="m-nav__separator">-</li>
                    <li className="m-nav__item">
                      <a href="dummy" className="m-nav__link">
                        <span className="m-nav__link-text">계정 관리</span>
                      </a>
                    </li>
                    <li className="m-nav__separator">-</li>
                    <li className="m-nav__item">
                      <a href="dummy" className="m-nav__link">
                        <span className="m-nav__link-text">일반 계정</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* 탈퇴 회원 컴포넌트는 조건부 렌더링 - 탈퇴계정 서브메뉴 클릭 시 ACTION 호출해서 조건부 렌더링 */}
            {whereTo !== 'deleteAccount' ? (
              <div className="m-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="m-portlet">
                      <div className="m-portlet__body">
                        <form className="m-form m-form--fit m--margin-bottom-20">
                          <div className="row m--margin-bottom-20">
                            <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                              <label>아이디</label>
                              <input
                                id="id"
                                onKeyDown={handleKeyDown}
                                onChange={(e) => {
                                  handleInput({
                                    ...inputObj,
                                    [e.target.id]: e.target.value,
                                    init: false,
                                  });
                                  onGetSearchKeywords({
                                    email: e.target.value,
                                  });
                                }}
                                value={inputObj.id}
                                type="text"
                                className="form-control m-input"
                                placeholder=""
                                data-col-index="0"
                              />
                            </div>
                            <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                              <label>회원아이디</label>
                              <input
                                id="accountId"
                                onKeyDown={handleKeyDown}
                                onChange={(e) => {
                                  handleInput({
                                    ...inputObj,
                                    [e.target.id]: e.target.value,
                                    init: false,
                                  });
                                  onGetSearchKeywords({
                                    accountId: e.target.value,
                                  });
                                }}
                                value={inputObj.accountId}
                                type="text"
                                className="form-control m-input"
                                placeholder=""
                                data-col-index="0"
                              />
                            </div>
                            <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                              <label>회사명</label>
                              <input
                                id="company"
                                onKeyDown={handleKeyDown}
                                onChange={(e) => {
                                  handleInput({
                                    ...inputObj,
                                    [e.target.id]: e.target.value,
                                    init: false,
                                  });
                                  onGetSearchKeywords({
                                    companyName: e.target.value,
                                  });
                                }}
                                value={inputObj.company}
                                type="text"
                                className="form-control m-input"
                                placeholder=""
                                data-col-index="1"
                              />
                            </div>
                            <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                              <label>연락처</label>
                              <input
                                type="tel"
                                id="phoneNumber"
                                onKeyDown={handleKeyDown}
                                onChange={(e) => {
                                  handleInput({
                                    ...inputObj,
                                    [e.target.id]: e.target.value,
                                    init: false,
                                  });
                                  onGetSearchKeywords({
                                    phoneNumber: e.target.value,
                                  });
                                }}
                                value={inputObj.phoneNumber}
                                className="form-control m-input"
                                placeholder=""
                                data-col-index="1"
                              />
                            </div>
                            <div className="empty-row-bar"> </div>
                            <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                              <label>가입일</label>
                              <div
                                className="input-group pull-right"
                                id="m_daterangepicker1"
                              >
                                <DateRangePicker
                                  displayFormat="YYYY.MM.DD"
                                  startDate={startDate}
                                  endDate={endDate}
                                  focusedInput={focusedInput}
                                  setFocusedInput={setFocusedInput}
                                  setStartDate={setStartDate}
                                  setEndDate={setEndDate}
                                  initInputObj={initInputObj}
                                  initSearchFilter={inputObj.init}
                                  searchParam="signUpDate"
                                  onGetSearchItem={onGetSearchKeywords}
                                  setFocus={setFocus}
                                  focusByIcon={
                                    focusByIcon && focusByIcon.singleDatePicker1
                                  }
                                  // className="form-control m-input"
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                              <label>유입매체</label>
                              <ButtonDropdown
                                customKey="channels"
                                initSearchFilter={inputObjForChannels.init}
                                initInputObj={initInputObjForChannels}
                                handleInput={handleInputForChannels}
                                onGetSearchKeywords={onGetSearchKeywords}
                                initItem="전체"
                                dropdownItems={dropdownItemsForChannels}
                              />
                            </div>
                            <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                              <label>고객규모</label>
                              <ButtonDropdown
                                onChangeBtnDropdown={({ status }) => {
                                  const accountType = {
                                    '1억클럽': 1,
                                    '10억클럽': 10,
                                    '30억클럽': 30,
                                  };
                                  onGetSearchKeywords({
                                    accountType: accountType[status],
                                  });
                                }}
                                dropdownItems={[
                                  {
                                    name: '전체',
                                  },
                                  {
                                    name: '일반',
                                  },
                                  {
                                    name: '1억클럽',
                                  },
                                  {
                                    name: '10억클럽',
                                  },
                                  {
                                    name: '30억클럽',
                                  },
                                ]}
                              />
                            </div>
                            <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                              <label>상태</label>
                              <ButtonDropdown
                                initSearchFilter={inputObj.init}
                                initInputObj={initInputObj}
                                handleInput={handleInput}
                                onGetSearchKeywords={onGetSearchKeywords}
                                initItem="전체"
                                dropdownItems={dropdownItems}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <button
                                onClick={async (e) => {
                                  e.preventDefault();
                                  await onInitToSearchAccounts();
                                  await onInitCurrentAccountList();
                                  let date;
                                  if (startDate && endDate)
                                    date = { startDate, endDate };
                                  await setSearchFilter(true);
                                  await onSearchAccounts(date);
                                }}
                                className="btn btn-brand m-btn m-btn--icon"
                                id="m_search"
                              >
                                <span>
                                  <Octicon icon={Search} />
                                  <span>검색</span>
                                </span>
                              </button>
                              &nbsp;&nbsp;
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSearchFilter(false);
                                  setStartDate(null);
                                  setEndDate(null);
                                  handleInput({ ...initInputObj, init: true });
                                  onInitSearchResult();
                                  onInitAccountList();
                                }}
                                className="btn btn-secondary m-btn m-btn--icon"
                                id="m_reset"
                              >
                                <span>
                                  <Octicon icon={X} />
                                  <span>초기화</span>
                                </span>
                              </button>
                            </div>
                          </div>
                        </form>
                        <div className="row row-btn-group">
                          <label className="float-left number-of-count">
                            총 계정 수 : {count && count.totalCount}{' '}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|
                          </label>
                          <label className="float-left number-of-count">
                            일반 계정 : {count && count.generalAccountCount}{' '}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|
                          </label>
                          <label className="float-left number-of-count">
                            테스트 계정 : {count && count.internalAccountCount}
                          </label>
                          <MsgModal
                            onHandleCloseMsgModal={() => {
                              setShouldOpenModal(false);
                              const csvUploadButton =
                                document.getElementById('csvUploadButton');

                              csvUploadButton.value = null;
                              dispatch(globalAction.uploadCsvFile(null));
                            }}
                            onConfirmAction={handlePostCredit}
                            confirmOrCancel
                            isMsgModalOpen={shouldOpenModal}
                            size="lg"
                            contentsObject
                            header="크레딧 지급"
                            content={
                              <form>
                                <div className="form-group m-form__group row">
                                  <label
                                    htmlFor="email-contents"
                                    className="col-lg-2 col-form-label"
                                  >
                                    지급 대상 *
                                  </label>
                                  <div className="col-lg-3 form-view form-view-no-padd-top">
                                    <CsvUploadButton />
                                    {'   '}
                                    {errMsg
                                      ? 0
                                      : csvRows
                                      ? csvRows.length
                                      : 0}{' '}
                                    개
                                    <div className="error message visible">
                                      {errorStatus}
                                    </div>
                                  </div>
                                </div>
                              </form>
                            }
                          />

                          <MsgModal
                            onHandleCloseMsgModal={() => {
                              setAccountTypeModal(null);
                            }}
                            onConfirmAction={handlePostIs100mClub}
                            confirmOrCancel
                            isMsgModalOpen={!!accountTypeModal}
                            size="lg"
                            contentsObject
                            header="고객규모 관리"
                            content={is100mClubForm}
                          />

                          <ButtonsGroup
                            handleCreateCredit={() => setShouldOpenModal(true)}
                            ids={handleDownloadIdList}
                            customClassName="btn-group-in-row"
                            columns={columns}
                            pdfData={
                              !isSearching &&
                              searchedAccountList &&
                              !searchedAccountList.length
                                ? accountListForView
                                : searchedAccountListForView
                            }
                            csvData={
                              !isSearching &&
                              searchedAccountList &&
                              !searchedAccountList.length
                                ? accountListForView
                                : searchedAccountListForView
                            }
                            csvFileName={`account_list_${moment().format(
                              'YYYY-MM-DD HH:mm'
                            )}`}
                          />
                        </div>
                        {!isSearching &&
                        searchedAccountList &&
                        !searchedAccountList.length ? ( // 검색결과가 아닌 일반 테이블
                          <DataTable
                            isLast={isLast}
                            requestAction={getAccountList}
                            sortingParam={sortingParam}
                            customClassName={
                              accountListForView.length > 13
                                ? 'column-10 general-account-table table-scrollable'
                                : 'column-10 general-account-table'
                            }
                            accountDetail
                            rows={accountListForView}
                            actualData={accountList}
                            columns={columns}
                            onSortAsc={onSortAscNormalAccounts}
                            onSortDesc={onSortDescNormalAccounts}
                            sortingColumns={[
                              '프로젝트',
                              '관리자계정',
                              '크레딧',
                              '총납부요금(원)',
                              '가입일',
                              '최종 접속일',
                            ]}
                            clickableIndex={clickableIndex}
                            indexMethodArr={indexMethodArr}
                          />
                        ) : (
                          <DataTable // 검색결과 테이블
                            clickableIndex={clickableIndex}
                            indexMethodArr={indexMethodArr}
                            isLast={isLast}
                            requestAction={getAccountList}
                            sortingParam={sortingParam}
                            customClassName={
                              searchedAccountListForView &&
                              searchedAccountListForView.length > 13
                                ? 'column-10 general-account-table table-scrollable'
                                : 'column-10 general-account-table'
                            }
                            isSearched={!!searchedAccountList}
                            accountDetail
                            rows={searchedAccountListForView}
                            actualData={searchedAccountList}
                            columns={columns}
                            onSortAsc={onSortAscNormalAccounts}
                            onSortDesc={onSortDescNormalAccounts}
                            sortingColumns={[
                              '프로젝트',
                              '관리자계정',
                              '크레딧',
                              '총납부요금(원)',
                              '가입일',
                              '최종 접속일',
                            ]}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : detail && detail.accountDetail ? (
          <AccountDetail accountInfo={detail.accountInfo} />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  whereTo: selectors.deleteAccountSelector,
  columns: selectors.columnsSelector,
  accountListForView: selectors.accountListSelectorForView,
  accountList: selectors.accountListSelector,
  // sortedAccountList: selectors.sortedAccountListSelector,
  detail: selectors.clickedIdSelector,
  searchedAccountList: selectors.searchedAccountListSelector,
  searchedAccountListForView: selectors.searchedAccountListSelectorForView,
  searchKeywords: selectors.searchKeywordsSelector,
  sortingParam: selectors.sortingParamSelector,
  isLast: selectors.isLastSelector,
  count: selectors.countSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onClickAccountRow: (id, type) => dispatch(actions.clickAccountRow(id, type)),
  onSortAscNormalAccounts: (rows, isSearched) =>
    dispatch(actions.sortAscNormalAccounts(rows, isSearched)),
  onSortDescNormalAccounts: (rows, isSearched) =>
    dispatch(actions.sortDescNormalAccounts(rows, isSearched)),
  onGetSearchKeywords: (obj) => dispatch(actions.getSearchKeywords(obj)),
  onSearchAccounts: (date) => dispatch(actions.searchAccounts(date)),
  onInitSearchResult: () => dispatch(actions.initSearchResult()),
  onInitAccountList: () => dispatch(actions.initAccountList()),
  onInitCurrentAccountList: () => dispatch(actions.initCurrentAccountList()),
  onInitToSearchAccounts: () => dispatch(actions.initToSearchAccounts()),
  onGetAccessToken: (accountId) =>
    dispatch(actions.getAccessToken({ accountId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountManaging);
