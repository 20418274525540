// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { saveAs } from 'file-saver';

import * as types from './constant';
import * as globalActions from '../../globalStore/action';
import * as api from '../../../service/API';

function* getDownloadTopic({ payload: { inDate, filename } }) {
  try {
    yield put(globalActions.globalLoading());

    const { data } = yield call(api.getStarted.getDownloadTopic, inDate);

    saveAs(`data:application/zip;base64,${data}`, `${filename}.zip`);

    yield put(globalActions.globalLoadingSuccess());
  } catch (error) {
    yield put(globalActions.globalErrorHandling(error));
  }
}

export function* watcherGetDownloadTopic() {
  yield takeLatest(types.GET_DOWNLOAD_TOPIC, getDownloadTopic);
}

function* sagas() {
  yield all([fork(watcherGetDownloadTopic)]);
}

export default sagas;
