// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { createSelector } from 'reselect';

// import aws from 'aws-sdk';
import moment from 'moment';

export const columnsSelector = createSelector(
  (state) => state.projects.columns,
  (columns) => columns
);

// 동일 프로젝트명이 다수 존재할 때
export const projectsSelector = createSelector(
  (state) => state.projects.projects,
  (projects) =>
    projects.map((pjt) => ({
      title: pjt.title,
      email: pjt.email,
      companyName: pjt.companyName,
      inDate: moment(pjt.inDate).format('YYYY.MM.DD'),
      projectId: { noDisplay: true, projectId: pjt.projectId },
    }))
);

export const statisticsSelector = createSelector(
  (state) => state.projects.statOfProjects,
  (statOfProjects) => statOfProjects
);

export const monthlyStatsSelectorForView = createSelector(
  (state) => state.projects.monthlyStats,
  (monthlyStats) => {
    // console.log('20210531 : monthlyStats', monthlyStats);
    const rows = monthlyStats.map((obj) => ({
      Date: obj.Date.format('YYYY.MM'),
      Revenue: (obj.Revenue || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      MAU: (obj.MAU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      specificRevenue: {
        noDisplay: true,
        id: obj.Date.format('YYYY-MM'),
        iOSRevenue: obj.iOSRevenue,
        AndroidRevenue: obj.AndroidRevenue,
      },
      NRU: (obj.NRU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      RU: (obj.RU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      PU: (obj.PU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      ARPU: (obj.ARPU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      ARPPU: (obj.ARPPU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      PR: (obj.PR || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    }));
    return rows;
  }
);

export const monthlyStatsSelector = createSelector(
  (state) => state.projects.monthlyStats,
  (monthlyStats) => monthlyStats
);

export const dailyStatsSelectorForView = createSelector(
  (state) => state.projects.dailyStats,
  (dailyStats) => {
    // console.log('20210531 : dailyStats', dailyStats);
    const rows = dailyStats.map((obj) => ({
      Date: obj.Date.format('YYYY.MM.DD'),
      Revenue: (obj.Revenue || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      DAU: (obj.DAU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      specificRevenue: {
        noDisplay: true,
        id: obj.Date.format('YYYY-MM-DD'),
        iOSRevenue: obj.iOSRevenue,
        AndroidRevenue: obj.AndroidRevenue,
      },
      WAU: (obj.WAU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      MAU: (obj.MAU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      NRU: (obj.NRU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      RU: (obj.RU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      Stickiness: obj.Stickiness,
      PU: (obj.PU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      ARPDAU: (obj.ARPDAU || '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      ARPPU: (obj.ARPPU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      PR: (obj.PR || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    }));
    return rows;
  }
);

export const dailyStatsSelector = createSelector(
  (state) => state.projects.dailyStats,
  (dailyStats) => dailyStats
);
export const statTypeSelector = createSelector(
  (state) => state.projects.statType,
  (statType) => statType
);

export const actualLastItemSelector = createSelector(
  (state) => state.projects,
  (projects) =>
    // console.log(projects.monthlyStats, "in selector");
    // console.log(projects.dailyStats, "in selector");
    ({
      monthlyStats: projects.monthlyStats[projects.monthlyStats.length - 1],
      dailyStats: projects.dailyStats[projects.dailyStats.length - 1],
    })
);

export const dailyStatsLastItemSelector = createSelector(
  (state) => state.projects.dailyStatsLastItem,
  (dailyStatsLastItem) => dailyStatsLastItem
);

export const searchedProjectSelector = createSelector(
  (state) => state.projects.project,
  (project) => project
);

export const sortingParamSelector = createSelector(
  (state) => state.projects.sortingParam,
  (sortingParam) => sortingParam
);

export const isLoadingSelector = createSelector(
  (state) => state.projects.isLoading,
  (isLoading) => isLoading
);

export const isLastSelector = createSelector(
  (state) => state.projects.isLast,
  (isLast) => isLast
);

export const searchingSelector = createSelector(
  (state) => state.projects.searching,
  (searching) => searching
);
