import * as types from './constant';

export function initRetention() {
	return {
		type: types.INIT_RETENTION,
	};
}

export function getRetention(gameId, date) {
	return {
		type: types.GET_RETENTION,
		gameId,
		date,
	};
}

export function getPJTRetention(gameId, date) {
	return {
		type: types.GET_PROJECT_RETENTION,
		gameId,
		date,
	};
}

export function getRetentionSuccess(payload, option) {
	return {
		type: types.GET_RETENTION_SUCCESS,
		payload,
		option,
	};
}

export function getProjectsSuccess(payload) {
	return {
		type: types.GET_PROJECTS_FOR_RETENTION_SUCCESS,
		payload,
	};
}

export function getSearchKeywords(payload) {
	return {
		type: types.GET_SEARCH_KEYWORDS,
		payload,
	};
}
