import React, { useState, useEffect } from 'react';

import {
  Modal,
  ModalBody,
  Popover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Octicon, { Search, X, ChevronDown } from '@primer/octicons-react';
import moment from 'moment';
import clone from 'ramda/src/clone';
import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import ButtonsGroup from '../../../fnComponents/buttonGroup';
import DataTable from '../../../fnComponents/_DataTable';

import DateRangePicker from '../../../fnComponents/DateRangePicker';
import ButtonDropdown from '../../../fnComponents/ButtonDropdown';

import * as actions from './store/action';
import * as selectors from './store/selector';

import SpinnerCompo from '../../../fnComponents/Spinner';

import MsgModal from './MsgModal';

const Customers = ({
  // columns,
  customersList,
  actualCustomersList,
  monthlyStatsLastItem,
  dailyStatsLastItem,
  _customersList,
  _actualCustomersList,
  onChangeStatType,
  onGetDailyStatistics,
  onGetMonthlyStatistics,
  onSearchStatistics,
  onSortAscRows,
  onSortDescRows,
  actualLastItem,
  onInitDataTables,
  isLoading,
  sortingParam,
  isLast,
  onHandleOpenReleasedProjectModal,
  onHandleCloseReleasedProjectModal,
  releasedPjts: { releasedPjts, isReleasedPjtsModalOpen, date },
  onShowSpecificProjectInfo,
}) => {
  const _columns = [
    { label: '일자', field: 'Date' },
    { label: '거래액(원)', field: 'Revenue' },
    { label: 'MAU', field: 'MAU' },
    { label: 'NRU', field: 'NRU' },
    { label: 'DB읽기', field: 'DBRead' },
    { label: 'DB쓰기', field: 'DBWrite' },
    { label: '신규 가입사', field: 'NewCustomerCount' },
    { label: '신규 프로젝트', field: 'NewProjectCount' },
    { label: '출시 프로젝트', field: 'releasedProjectCount' },
    { label: '지급 크레딧', field: 'GivenCredit' },
    { label: '사용 크레딧', field: 'UsedCredit' },
  ];

  const [columns, setColumns] = useState(_columns);

  const initInputObj = {
    type: 'MAU',
    init: '월별',
  };
  const [inputObj, handleInput] = useState(initInputObj);

  // useEffect(() => {
  // 	handleInput({ ...inputObj, type: columns[2].label });
  // }, [columns, inputObj]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState('');

  const handleInitDatePicker = (e) => {
    e.preventDefault();
    setStartDate(null);
    setEndDate(null);
    onInitDataTables();
  };

  const handleOnSearchStatistics = (e) => {
    e.preventDefault();
    let date;
    if (startDate && endDate) {
      const _endDate = clone(endDate);

      date = {
        startDate: startDate.set({ hour: 0 }).toISOString(),
        endDate: _endDate
          .set({ hour: 24 })
          //   .set({ hour: 23, minute: 59, seconds: 59 })
          //   .add(1, "days")
          .toISOString(),
      };
    }
    onSearchStatistics(date);
  };

  const dropdownItems = [
    {
      name: '월별',
    },
    {
      name: '일별',
    },
  ];

  const handleOnChangeStatType = (param) => {
    // console.log(param);
    const { status } = param;
    if (status === '월별') {
      setColumns(columns);
      handleInput({ ...inputObj, type: 'MAU' });
    } else {
      setColumns([
        { label: '일자', field: 'Date' },
        { label: '거래액(원)', field: 'Revenue' },
        { label: 'DAU', field: 'DAU' },
        { label: 'NRU', field: 'NRU' },
        { label: 'DB읽기', field: 'DBRead' },
        { label: 'DB쓰기', field: 'DBWrite' },
        { label: '신규 가입사', field: 'NewCustomerCount' },
        { label: '신규 프로젝트', field: 'NewProjectCount' },
        { label: '출시 프로젝트', field: 'releasedProjectCount' },
        { label: '지급 크레딧', field: 'GivenCredit' },
        { label: '사용 크레딧', field: 'UsedCredit' },
      ]);
      handleInput({ ...inputObj, type: 'DAU' });
    }
    onChangeStatType(param);
  };

  const [revenue, setRevenue] = useState(false);

  const handlePopOver = (arr) => {
    // target example : clickable-td 2,418,900

    let obj = {};

    arr.forEach((item) => {
      obj = {
        ...obj,
        ...item,
      };
    });
    // console.log('20210531 : obj', obj);
    const {
      Revenue,
      specificRevenue: { iOSRevenue, AndroidRevenue, id },
    } = obj;
    setRevenue({
      Revenue: Revenue.replace(/,/g, ''),
      iOSRevenue,
      AndroidRevenue,
      id,
    });
    handleOverlay('block');
    setPopOver(true);
  };

  const [isPopOverOpen, setPopOver] = useState(false);
  const toggle = () => {
    setPopOver(!isPopOverOpen);
  };
  const handleOverlay = (param) => {
    document.querySelector('div.overlay').style.display = param;
  };
  const showPopOver = () => (
    <Popover
      fade={false}
      placement="left"
      isOpen={isPopOverOpen}
      target={`R${revenue.Revenue}${revenue.id}`}
      toggle={toggle}
    >
      <PopoverHeader>거래액정보</PopoverHeader>
      <PopoverBody>
        구글 : {revenue.AndroidRevenue}
        <br />
        애플 : {revenue.iOSRevenue}
      </PopoverBody>
    </Popover>
  );

  const handleOpenReleasedProjectModal = (arr) => {
    console.log(arr);
    let obj = {
      date: '',
      releasedProject: '',
    };
    arr.forEach((item) => {
      if (Object.keys(item)[0] === 'Date') {
        obj = {
          ...obj,
          date: item.Date,
        };
      }
      if (Object.keys(item)[0] === 'releasedProject') {
        obj = {
          ...obj,
          releasedProject: item.releasedProject.releasedProject,
        };
      }
    });
    const { releasedProject, date } = obj;
    onHandleOpenReleasedProjectModal(releasedProject, date);
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title">통계 - 종합지표</h3>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <form className="m-form m-form--fit m--margin-bottom-20">
                      <div className="row m--margin-bottom-20">
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>구분</label>
                          {/* <input className="form-control m-input" /> */}
                          <ButtonDropdown
                            initSearchFilter={inputObj.init}
                            initInputObj={initInputObj}
                            handleInput={handleInput}
                            onChangeBtnDropdown={handleOnChangeStatType}
                            // onGetSearchKeywords={onGetSearchKeywords}
                            initItem="월별"
                            dropdownItems={dropdownItems}
                          />
                        </div>
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>기간</label>
                          <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            focusedInput={focusedInput}
                            setFocusedInput={setFocusedInput}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            disabled={inputObj && inputObj.type === 'MAU'}
                          />
                        </div>
                      </div>
                      <div className="row m--margin-bottom-20">
                        <div className="col-lg-12">
                          <button
                            onClick={handleOnSearchStatistics}
                            className="btn btn-brand m-btn m-btn--icon"
                            id="m_search"
                          >
                            <span>
                              <Octicon icon={Search} />
                              <span>검색</span>
                            </span>
                          </button>
                          &nbsp;&nbsp;
                          <button
                            className="btn btn-secondary m-btn m-btn--icon"
                            id="m_reset"
                            onClick={handleInitDatePicker}
                          >
                            <span>
                              <Octicon icon={X} />
                              <span>초기화</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </form>
                    <ButtonsGroup
                      csvData={
                        inputObj.type === 'MAU' ? customersList : _customersList
                      }
                      csvFileName={`${
                        inputObj.type === 'MAU' ? '월별' : '일별'
                      }_stat_${moment().format('YYYY-MM-DD HH:mm')}`}
                    />
                    {isPopOverOpen && showPopOver()}
                    <div
                      onClick={() => {
                        handleOverlay('none');
                        setPopOver(false);
                      }}
                      className="overlay"
                      style={{
                        width: '100%',
                        height: '100%',
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        zIndex: '100',
                        opacity: '0.5',
                        backgroundColor: 'rgb(0,0,0)',
                        display: 'none',
                      }}
                    ></div>
                    <DataTable
                      indexMethodArr={[
                        {
                          index: 1,
                          method: handlePopOver,
                        },
                        {
                          index: 10,
                          method: handleOpenReleasedProjectModal,
                        },
                      ]}
                      isLast={isLast}
                      requestParams={{
                        type: 'statType',
                        params:
                          inputObj.type === 'MAU'
                            ? { statType: { status: '월별' } }
                            : { statType: { status: '일별' } },
                      }}
                      actualLastItem={
                        inputObj.type === 'MAU'
                          ? monthlyStatsLastItem
                          : dailyStatsLastItem
                      }
                      requestAction={{
                        statType:
                          inputObj.type === 'MAU'
                            ? { status: '월별' }
                            : { status: '일별' },
                        method:
                          inputObj.type === 'MAU'
                            ? onGetMonthlyStatistics
                            : onGetDailyStatistics,
                      }}
                      customClassName="statistics-indicator table-scrollable"
                      sortingParam={sortingParam}
                      tableType={inputObj.type !== 'MAU'}
                      onSortAsc={onSortAscRows}
                      onSortDesc={onSortDescRows}
                      actualData={
                        inputObj && inputObj.type === 'MAU'
                          ? actualCustomersList
                          : _actualCustomersList
                      }
                      id="table_dataTable_header"
                      rows={
                        inputObj && inputObj.type === 'MAU'
                          ? customersList
                          : _customersList
                      }
                      columns={columns}
                      sortingColumns={[
                        '일자',
                        '거래액(원)',
                        'MAU',
                        'DAU',
                        'NRU',
                        'DB읽기',
                        'DB쓰기',
                        '신규 가입사',
                        '신규 프로젝트',
                        '출시 프로젝트',
                        '지급 크레딧',
                        '사용 크레딧',
                      ]}
                      // requiredAction={onHandleOpenReleasedProjectModal}
                      additionalParam="releasedProjectCount"
                      additionalParamIndex="8" // 데이터가 있는 column's index
                      clickableIndex={[1, 10]}
                    />
                    {isReleasedPjtsModalOpen && (
                      <MsgModal
                        onShowSpecificProjectInfo={onShowSpecificProjectInfo}
                        rows={releasedPjts}
                        onHandleCloseMsgModal={
                          onHandleCloseReleasedProjectModal
                        }
                        isOpen={isReleasedPjtsModalOpen}
                        date={date}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  // columns: selectors.columnsSelector,
  customersList: selectors.customersListSelectorForView,
  actualCustomersList: selectors.customersListSelector,
  _customersList: selectors._customersListSelectorForView,
  _actualCustomersList: selectors._customersListSelector,
  actualLastItem: selectors.actualLastItemSelector,
  monthlyStatsLastItem: selectors.monthlyStatsLastItemSelector,
  dailyStatsLastItem: selectors.dailyStatsLastItemSelector,
  sortingParam: selectors.sortingParamSelector,
  isLast: selectors.isLastSelector,
  releasedPjts: selectors.releasedPjtsSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeStatType: (v) => dispatch(actions.changeStatType(v)),
  onGetDailyStatistics: (id, date, isSearching) =>
    dispatch(actions.getDailyStatistics(id, date, isSearching)),
  onGetMonthlyStatistics: (v) => dispatch(actions.getMonthlyStatistics(v)),
  onSearchStatistics: (v) => dispatch(actions.searchStatistics(v)),
  onSortAscRows: (rows, isSearched, tableType) =>
    dispatch(actions.sortAscRows(rows, isSearched, tableType)),
  onSortDescRows: (rows, isSearched, tableType) =>
    dispatch(actions.sortDescRows(rows, isSearched, tableType)),
  onInitDataTables: () => dispatch(actions.initDataTables()),
  onHandleOpenReleasedProjectModal: (pjts, date) =>
    dispatch(actions.handleOpenReleasedProjectModal(pjts, date)),
  onHandleCloseReleasedProjectModal: (pjts) =>
    dispatch(actions.handleCloseReleasedProjectModal(pjts)),
  onShowSpecificProjectInfo: (pjtName) =>
    dispatch(actions.showSpecificProjectInfo(pjtName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
