// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React from 'react';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-light.css';

export default class Demo extends React.Component {
  mdParser = null;
  constructor(props) {
    super(props);
    this.mdParser = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
      highlight: function (str, lang) {
        if (lang && hljs.getLanguage(lang)) {
          console.log(lang, str, '0710 lang str');
          try {
            return hljs.highlight(lang, str).value;
          } catch (__) {}
        }
        return ''; // use external default escaping
      },
    });
    this.state = {
      html: '',
      text: '',
    };
  }
  handleEditorChange({ html, text }) {
    console.log('handleEditorChange', html, text);
    // this.setState({ html, text });
    // const { handleChange } = this.props;
    // handleChange(html);
  }

  handleImageUpload(file, callback) {
    const reader = new FileReader();
    reader.onload = () => {
      console.log('reader.result', reader.result);
      setTimeout(() => callback(reader.result), 1000);
    };
    reader.readAsDataURL(file);
  }

  render() {
    const {
      _content: { content },
      handleChange,
    } = this.props;
    // console.log(content, "!!!");
    const sth = '';

    const customHtmlStyleElem =
      document.getElementsByClassName('custom-html-style')[0];

    if (customHtmlStyleElem && customHtmlStyleElem.className) {
      customHtmlStyleElem.className = '';
    }

    return (
      <div style={{ height: '500px' }}>
        <MdEditor
          onImageUpload={this.handleImageUpload}
          value={content || sth}
          renderHTML={(text) => {
            return this.mdParser.render(text);
          }}
          onChange={({ html, text }) => {
            handleChange(text);
          }}
          config={{
            view: {
              menu: true,
              md: true,
              html: true,
              fullScreen: true,
              hideMenu: true,
            },
          }}
        />
      </div>
    );
  }
}
