// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button } from 'reactstrap';

import NoticeCreateModal from './NoticeCreateModal';
import Header from '../../view/header';
import LeftAsideMunu from '../../view/leftAsideMenu';
import ButtonsGroup from '../../fnComponents/buttonGroup';
import DataTable from '../../fnComponents/_DataTable';
import SearchForm from '../../fnComponents/SearchForm';
import MsgModal from '../../fnComponents/MsgModal';

import * as actions from './store/action';
import * as selectors from './store/selector';

const Coupon = ({
  columns,
  noticeList,
  noticeListForView,
  onGetNoticeList,
  onClickNoticeEdit,
  onClickNoticeDelete,
  onSortAscNotice,
  onSortDescNotice,
  detail: { editable },
  sortingParam,
  isNoticeCreateModalClose,
  isEditMode,
  isDeleteDone,
  onSetCreateMode,
  onHandleOpenMsgModal,
  importantNoticeList,
  onGetSearchKeywords,
  importantNoticeListForView,
  lastEvaluatedKey,
}) => {
  const [isNoticeCreateModalOpen, handleOpenNoticeCreateModal] =
    useState(false);
  const [toBeDeleted, setNoticeTitleToBeDeleted] = useState({
    notice: '',
    isMsgModalOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isNoticeCreateModalClose) {
      handleOpenNoticeCreateModal(!isNoticeCreateModalOpen);
      dispatch(actions.setIsNoticeCreateModalClose(false));
    }
  }, [isNoticeCreateModalClose, isNoticeCreateModalOpen, dispatch]);

  useEffect(() => {
    if (isDeleteDone)
      setNoticeTitleToBeDeleted({ notice: '', isMsgModalOpen: false });
  }, [isDeleteDone]);

  const toggle = (bool) => {
    handleOpenNoticeCreateModal(bool);
    onSetCreateMode();
  };

  const handleCloseMsgModal = () =>
    setNoticeTitleToBeDeleted({
      notice: '',
      isMsgModalOpen: false,
    });

  const handleClickNoticeEdit = (key, arr) => {
    toggle(true);
    onClickNoticeEdit(key, arr);
  };

  const handleClickNoticeDelete = (key, arr) => {
    onHandleOpenMsgModal();
    setNoticeTitleToBeDeleted({
      notice: arr,
      isMsgModalOpen: true,
    });
  };

  const confirmNoticeDelete = () => {
    onClickNoticeDelete(null, toBeDeleted.notice);
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title">공지사항 관리</h3>
              </div>
            </div>
          </div>
          {/* m-content 부터 */}
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <SearchForm
                      keywords={[
                        '제목',
                        '작성자',
                        '등록일',
                        '상태',
                        '분류',
                        '언어',
                      ]}
                      selectOpts={{
                        status: {
                          name: '상태',
                          options: [
                            { name: '전체' },
                            { name: '공개' },
                            { name: '비공개' },
                          ],
                        },
                        type: {
                          name: '분류',
                          options: [
                            { name: '전체' },
                            { name: '공지' },
                            { name: '긴급공지' },
                            { name: '업데이트' },
                            { name: '이벤트' },
                            { name: '안내' },
                          ],
                        },
                        language: {
                          name: '언어',
                          options: [
                            { name: '전체' },
                            { name: '국문' },
                            { name: '영문' },
                          ],
                        },
                      }}
                      mainlyRequiredAction={onGetNoticeList}
                      initAction={() => {}}
                      onGetSearchKeywords={onGetSearchKeywords}
                    />

                    {/* 공지등록 버튼 */}
                    <div className="row">
                      <div className="col-lg-12">
                        <Button onClick={() => toggle(true)} color="secondary">
                          공지등록
                        </Button>
                        <NoticeCreateModal
                          isEditMode={isEditMode}
                          isNoticeCreateModalOpen={isNoticeCreateModalOpen}
                          handleOpenNoticeCreateModal={
                            handleOpenNoticeCreateModal
                          }
                        />
                      </div>
                    </div>
                    <ButtonsGroup />
                    <DataTable
                      requestParams="더보기"
                      isLast={!lastEvaluatedKey}
                      requestAction={onGetNoticeList}
                      importantListLength={
                        importantNoticeList && importantNoticeList.length
                      }
                      sortingParam={sortingParam}
                      onSortAsc={onSortAscNotice}
                      onSortDesc={onSortDescNotice}
                      customClassName={
                        noticeListForView.length > 13
                          ? 'notice-list-table table-scrollable'
                          : 'notice-list-table'
                      }
                      requiredAction={handleClickNoticeEdit}
                      secondRequiredAction={handleClickNoticeDelete}
                      deleteRow
                      rows={[
                        ...importantNoticeListForView,
                        ...noticeListForView,
                      ]}
                      columns={columns}
                      sortingColumns={['등록일시', '조회']}
                      actualData={noticeList}
                      clickableIndex={[1]}
                      additionalParam="title"
                      additionalParamIndex="2"
                    />
                    <MsgModal
                      onHandleDelete={confirmNoticeDelete}
                      onHandleCloseMsgModal={handleCloseMsgModal}
                      isMsgModalOpen={toBeDeleted.isMsgModalOpen}
                      content="삭제하시겠습니까?"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  columns: selectors.columnsSelector,
  detail: selectors.clickedIdSelector,
  noticeList: selectors.noticeListSelector,
  noticeListForView: selectors.noticeListSelectorForView,
  sortingParam: selectors.sortingParamSelector,
  isNoticeCreateModalClose: selectors.isNoticeCreateModalCloseSelector,
  isEditMode: selectors.isEditModeSelector,
  isDeleteDone: selectors.isDeleteDoneSelector,
  importantNoticeList: selectors.importantNoticeListSelector,
  importantNoticeListForView: selectors.importantNoticeListSelectorForView,
  lastEvaluatedKey: selectors.lastEvaluatedKeySelector,
});

const mapDispatchToProps = (dispatch) => ({
  onGetNoticeList: (data) => dispatch(actions.getNoticeList(data)),
  onInitNoticeList: () => dispatch(actions.initNoticeList()),
  onClickNoticeEdit: (content, arr) =>
    dispatch(actions.clickNoticeEdit(content, arr)),
  onClickNoticeDelete: (key, arr) =>
    dispatch(actions.clickNoticeDelete(key, arr)),
  onSortAscNotice: (rows, isSearched) =>
    dispatch(actions.sortAscNotice(rows, isSearched)),
  onSortDescNotice: (rows, isSearched) =>
    dispatch(actions.sortDescNotice(rows, isSearched)),
  onSetCreateMode: () => dispatch(actions.setCreateMode()),
  onHandleOpenMsgModal: () => dispatch(actions.handleOpenMsgModal()),
  onGetSearchKeywords: (obj) => dispatch(actions.getSearchKeywords(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
