// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import xlsx from 'xlsx';
import Octicon, { X } from '@primer/octicons-react';

import Header from '../../view/header';
import LeftAsideMunu from '../../view/leftAsideMenu';
import MsgModal from '../../fnComponents/MsgModal';

import * as actions from './store/action';
import * as selectors from './store/selector';
import MarkDownEditor from './MarkDownEditor';

const Emails = ({
  // state props
  sendToCount,
  sendEmailSuccess,
  sendTestEmailSuccess,
  files,
  errors,
  // action props
  onSendMail,
  onReadFiles,
  onResetFiles,
  onSendTestEmail,
  onInitSendEmailSuccess,
  onInitSendTestEmailSuccess,
  onDeleteReadFiles,
}) => {
  const [shouldTestEmailModalOpen, setShouldTestEmailModalOpen] =
    useState(false);
  const [attachments, setAttachments] = useState([]);
  const [sendToUpload, setSendToUpload] = useState([]);
  const [email, setEmail] = useState({});
  const [emailList, setEmailList] = useState([]);
  const [html, setHTML] = useState('');
  const [shouldMsgModalOpen, setShouldMsgModalOpen] = useState(false);
  const [_content, setContent] = useState({
    contents: '',
    title: '',
  });

  const [radioObj, setRadioObj] = useState({
    sendTime: 'now',
    sendTo: 'email',
    usingTemplet: 2, // apply applyReject noApply
  });

  const [shouldEmailSuccessModalOpen, setShouldEmailSuccessModalOpen] =
    useState(false);

  useEffect(() => {
    if (sendEmailSuccess) {
      setRadioObj({
        sendTime: 'now',
        sendTo: 'email',
        usingTemplet: 2,
      });
      setContent({
        contents: '',
        title: '',
      });
      setAttachments([]);
      setSendToUpload([]);
      setEmail({
        ...email,
        email: '',
      });
      setShouldMsgModalOpen(false);
      onInitSendEmailSuccess(false);
      setShouldEmailSuccessModalOpen(true);
    }
  }, [sendEmailSuccess, email, onInitSendEmailSuccess]);

  useEffect(() => {
    if (sendTestEmailSuccess) {
      setEmail({
        ...email,
        testEmail: '',
      });
      setShouldTestEmailModalOpen(false);
      onInitSendTestEmailSuccess(false);
      setShouldEmailSuccessModalOpen(true);
    }
  }, [email, sendTestEmailSuccess, onInitSendTestEmailSuccess]);

  useEffect(() => {
    if (errors) setErrorObj(errors);
  }, [errors]);

  const initialErrorObj = {
    sendTime: '',
    sendTo: '',
    title: '',
    contents: '',
    errMsg: '',
    shouldMsgModalOpen: false,
  };
  const [errorObj, setErrorObj] = useState(initialErrorObj);

  useEffect(() => {
    if (files && !files.errMsg) {
      setAttachments(files);
    } else {
      console.log('files', files);
    }
  }, [files, errorObj]);

  const handleChange = (contents) => {
    if (errorObj.contents) {
      setErrorObj({
        ...errorObj,
        contents: '',
      });
    }
    setContent({
      ..._content,
      contents,
    });
  };

  const getHTML = (html) => {
    // console.log(html)
    setHTML(html);
  };

  const handleTest = () => {
    setShouldTestEmailModalOpen(true);
  };

  const handleMsgModal = () => {
    setShouldMsgModalOpen(!shouldMsgModalOpen);
  };

  const checkValidation = (e) => {
    // 발송 또는 테스트 (발송)으로 분기
    let isErr = false;
    const errObj = {};
    const { contents, title } = _content;

    if (!contents) {
      console.error('내용 없음', _content);
      isErr = true;
      errObj.contents = '내용을 입력해 주세요.';
    }
    if (!title) {
      console.error('제목 없음', _content);
      isErr = true;
      errObj.title = '제목을 입력해 주세요.';
    }

    if (radioObj.sendTo === 'email') {
      const regExp =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

      if (!email.email || !regExp.test(email.email)) {
        console.error('이메일 직접 입력 필요');
        isErr = true;
        errObj.sendTo = '이메일 주소를 정확히 입력해 주세요.';
      }
    }

    if (isErr) {
      setErrorObj({
        ...errorObj,
        ...errObj,
      });
    } else if (e.target.id === 'email') {
      handleMsgModal();
    } else handleTest();
  };

  const handleSendToFileChange = (e) => {
    try {
      const file = e.target.files[0];
      const reader = new FileReader();

      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          setErrorObj({
            ...errorObj,
            errMsg: '최대 5MB 까지 업로드 가능합니다.',
            shouldMsgModalOpen: true,
          });
          return;
        }
      }

      reader.onload = (evt) => {
        let rows = [];
        let _emailList = [];
        const workBook = xlsx.read(evt.target.result, { type: 'binary' });
        workBook.SheetNames.forEach((sheetName) => {
          rows = xlsx.utils.sheet_to_json(workBook.Sheets[sheetName], {
            header: 'A',
          });

          // console.log(rows, 'row2 확인');

          const regExp =
            /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

          rows.forEach((row) => {
            if (!regExp.test(row.A)) {
              setErrorObj({
                ...errorObj,
                errMsg: '문서 형식이 올바르지 않습니다.',
                shouldMsgModalOpen: true,
              });
              throw {};
            } else {
              _emailList = [..._emailList, row];
            }
          });
          // console.log(JSON.stringify(rows));
          // console.log('rows', _emailList);
          // setSendToCount(rows.length)
          setEmailList(_emailList);
        });
        setSendToUpload([
          ...sendToUpload,
          { file: evt.target.result, key: file.name },
        ]);
      };
      reader.readAsBinaryString(file);
    } catch (error) {
      console.error('에러 발생:', error);
    }
  };

  const handleFileChange = (e) => {
    console.log('$0804$', e.target.files);
    let arr = [];
    for (let i = 0; i < e.target.files.length; i++) {
      arr = [
        ...arr,
        {
          file: e.target.files[i],
        },
      ];
    }
    if (arr.length) onReadFiles(arr);
  };

  const today = moment();
  const [sendTime, setSendTime] = useState(today.format('YYYY-MM-DDTHH:mm'));

  const handleCloseMsgModal = () => {
    if (errorObj.shouldMsgModalOpen && errorObj.filesLoaded) {
      // onResetFiles();
    }
    // onResetFiles();
    setErrorObj(initialErrorObj);
    setShouldMsgModalOpen(false);
    setShouldTestEmailModalOpen(false);
    setShouldEmailSuccessModalOpen(false);
  };

  const handleDateChange = (e) => {
    // console.log(e.target.value)
    const now = moment();
    if (now.isAfter(moment(e.target.value))) {
      // console.error('isAFTER')
      setErrorObj({
        ...errorObj,
        sendTime: '현재보다 이전 시각으로 선택할 수 없습니다.',
      });
      setSendTime(now.format('YYYY-MM-DDTHH:mm'));
    } else {
      setErrorObj({
        ...errorObj,
        sendTime: '',
      });
      setSendTime(e.target.value);
    }
  };
  const handleInputChange = (e) => {
    if (errorObj.title) {
      setErrorObj({
        ...errorObj,
        title: '',
      });
    }
    setContent({
      ..._content,
      title: e.target.value,
    });
  };
  const handleEmailInputChange = (e) => {
    if (errorObj.sendTo) {
      setErrorObj({
        ...errorObj,
        sendTo: '',
      });
    }
    if (errorObj.testSendTo) {
      setErrorObj({
        ...errorObj,
        testSendTo: '',
      });
    }

    setEmail({
      ...email,
      [e.target.id]: e.target.value,
    });
  };
  const handleSubmit = () => {
    let payload = {};

    if (sendToUpload.length) {
      payload = {
        sendToValue: emailList.map((email) => email.A),
      };
    }
    if (email && email.email) {
      payload = {
        sendToValue: email.email,
      };
    }

    if (radioObj.sendTime === 'reservation') {
      payload = {
        ...payload,
        ..._content,
        ...radioObj,
        sendTime: moment(sendTime).utc().toISOString(),
        attachments,
        contents: html,
      };
    } else {
      payload = {
        ...payload,
        ..._content,
        ...radioObj,
        attachments,
        contents: html,
      };
    }

    onSendMail(payload);
  };

  const handleTestSubmit = () => {
    const { testEmail } = email;
    let isErr = false;
    const errObj = {};

    const regExp =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (!testEmail || !regExp.test(testEmail)) {
      console.error('테스트이메일 직접 입력 필요');
      isErr = true;
      errObj.testSendTo = '이메일 주소를 정확히 입력해 주세요.';
    }

    if (isErr) {
      setErrorObj({
        ...errorObj,
        ...errObj,
      });
      return;
    }

    let payload = {
      email: email.testEmail,
    };
    if (payload.email) {
      const { usingTemplet } = radioObj;

      payload = {
        ...payload,
        ..._content,
        attachments,
        usingTemplet,
        contents: html,
      };
      onSendTestEmail(payload);
    } else {
      // 에러처리
    }
  };

  const handleDeleteFile = (e, originalArr, hook) => {
    // console.log('radioObj', e.currentTarget.id)
    // console.log('originalArr', originalArr)
    const filtered = originalArr.filter(
      (file) => file.key !== e.currentTarget.id
    );
    // console.log('originalArr', filtered)
    hook(filtered);
    onDeleteReadFiles(e.currentTarget.id);
  };

  const handleRadioBtnChange = (e) => {
    setRadioObj({
      ...radioObj,
      [e.target.name]: e.target.id,
    });
  };

  const handleSendToRadioBtnChange = (e) => {
    if (e.target.id === 'total' || e.target.id === 'adAgree') {
      setRadioObj({
        ...radioObj,
        [e.target.name]: e.target.id,
        usingTemplet: 1,
      });
    } else {
      setRadioObj({
        ...radioObj,
        [e.target.name]: e.target.id,
        usingTemplet: 2,
      });
    }
  };
  const handleUsingTempletRadioBtnChange = (e) => {
    console.log('handleUsingTempletRadioBtnChange', e.target.name, e.target.id);
    // sendTo === total || sendTo === 'adAgree'
    setRadioObj({
      ...radioObj,
      [e.target.name]: +e.target.id,
    });
  };

  const testEmail = (
    <div className="form-group m-form__group row">
      <label htmlFor="credit-reason" className="col-lg-2 col-form-label">
        * 이메일 주소
      </label>
      <div className="col-lg-9">
        <input
          onChange={handleEmailInputChange}
          type="email"
          className="form-control"
          id="testEmail"
          required
          value={email && email.testEmail}
        />
        <div className="error message visible">{errorObj.testSendTo}</div>
      </div>
    </div>
  );

  const msgContent = '메일을 발송 하시겠습니까?';

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title">메일 발송</h3>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    {/* radio sample */}
                    <div className="form-group m-form__group row">
                      <label
                        htmlFor="email-contents"
                        className="col-lg-2 col-form-label"
                      >
                        발송일시
                      </label>
                      <div className="col-lg-10">
                        <div className="m-radio-inline">
                          <label className="m-radio">
                            <input
                              id="now"
                              onChange={handleRadioBtnChange}
                              type="radio"
                              name="sendTime"
                              checked={radioObj.sendTime === 'now'}
                            />
                            즉시 발송
                            <span />
                          </label>
                          <label className="m-radio">
                            <input
                              id="reservation"
                              onChange={handleRadioBtnChange}
                              type="radio"
                              name="sendTime"
                              checked={radioObj.sendTime !== 'now'}
                            />
                            예약 발송
                            <span />
                          </label>
                          {radioObj.sendTime === 'reservation' ? (
                            <input
                              onChange={handleDateChange}
                              type="datetime-local"
                              id="start"
                              name="sendTime"
                              value={sendTime}
                              // min={today.format('YYYY-MM-DDTHH:mm')}
                              max="2030-12-31"
                            />
                          ) : null}
                          <span className="error message validity">
                            {errorObj.sendTime}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group m-form__group row">
                      <label
                        htmlFor="email-contents"
                        className="col-lg-2 col-form-label"
                      >
                        발송대상
                      </label>
                      <div className="col-lg-10">
                        <div className="m-radio-inline">
                          <label className="m-radio">
                            <input
                              id="email"
                              onChange={handleSendToRadioBtnChange}
                              type="radio"
                              name="sendTo"
                              checked={radioObj.sendTo === 'email'}
                            />
                            직접 입력
                            <span />
                          </label>
                          <label className="m-radio">
                            <input
                              id="upload"
                              onChange={handleSendToRadioBtnChange}
                              type="radio"
                              name="sendTo"
                              checked={radioObj.sendTo === 'upload'}
                            />
                            업로드 {`(${emailList.length})`}
                            <span />
                          </label>
                          <label className="m-radio">
                            <input
                              id="total"
                              onChange={handleSendToRadioBtnChange}
                              type="radio"
                              name="sendTo"
                              checked={radioObj.sendTo === 'total'}
                            />
                            전체 ({sendToCount && sendToCount.total}
                            )
                            <span />
                          </label>
                          <label className="m-radio">
                            <input
                              id="adAgree"
                              onChange={handleSendToRadioBtnChange}
                              type="radio"
                              name="sendTo"
                              checked={radioObj.sendTo === 'adAgree'}
                            />
                            수신 동의 ({sendToCount && sendToCount.mailAgrees}
                            )
                            <span />
                          </label>
                        </div>
                      </div>
                    </div>
                    {radioObj.sendTo === 'upload' ? (
                      <div className="form-group m-form__group row">
                        <label
                          htmlFor="credit-reason"
                          className="col-lg-2 col-form-label"
                        >
                          {' '}
                        </label>
                        <div className="col-lg-9 file-uploader">
                          <ul>
                            {sendToUpload.map((file) => (
                              <li
                                id={file.key}
                                key={`file-send-to-${file.key}`}
                              >
                                {file.key}
                                &nbsp;&nbsp;&nbsp;
                              </li>
                            ))}
                          </ul>
                          <button
                            htmlFor="sendTouploads"
                            type="button"
                            className="float-right btn btn-primary"
                          >
                            <label
                              className="clickable"
                              htmlFor="sendTouploads"
                            >
                              찾아보기
                            </label>
                          </button>
                          <input
                            onChange={handleSendToFileChange}
                            type="file"
                            id="sendTouploads"
                            name="sendTouploads"
                            accept=".xls, .xlsx, .csv"
                            multiple
                          />
                        </div>
                      </div>
                    ) : null}
                    {radioObj.sendTo === 'email' ? (
                      <>
                        <div className="form-group m-form__group row">
                          <label
                            htmlFor="credit-reason"
                            className="col-lg-2 col-form-label"
                          >
                            * 이메일
                          </label>
                          <div className="col-lg-9">
                            <input
                              onChange={handleEmailInputChange}
                              type="email"
                              className="form-control"
                              id="email"
                              required
                              value={email && email.email}
                            />
                            <div className="error message visible">
                              {errorObj.sendTo}
                            </div>
                          </div>
                        </div>
                        <br />
                      </>
                    ) : null}
                    {/* errmsgModalOpen */}
                    <MsgModal
                      content={errorObj.errMsg}
                      isMsgModalOpen={errorObj.shouldMsgModalOpen}
                      onHandleCloseMsgModal={handleCloseMsgModal}
                      onlyCanClose
                      size
                    />
                    {/* msgModalOpen */}
                    <MsgModal
                      content={msgContent}
                      isMsgModalOpen={shouldMsgModalOpen}
                      onHandleCloseMsgModal={handleCloseMsgModal}
                      confirmOrCancel
                      onConfirmAction={handleSubmit}
                    />
                    {/* send test mail */}
                    <MsgModal
                      header="테스트 발송"
                      content={testEmail}
                      isMsgModalOpen={shouldTestEmailModalOpen}
                      onHandleCloseMsgModal={handleCloseMsgModal}
                      confirmOrCancel
                      onConfirmAction={handleTestSubmit}
                      size="lg"
                      contentsObject
                    />
                    {/* send mail success */}
                    <MsgModal
                      header="발송 완료"
                      content="메일 발송이 완료되었습니다."
                      isMsgModalOpen={shouldEmailSuccessModalOpen}
                      onHandleCloseMsgModal={handleCloseMsgModal}
                      onlyCanClose
                      // size="lg"
                      // contentsObject
                    />
                    {/* a row for INPUT */}
                    <div className="form-group m-form__group row">
                      <label
                        htmlFor="credit-reason"
                        className="col-lg-2 col-form-label"
                      >
                        * 제목
                      </label>
                      <div className="col-lg-9">
                        <input
                          onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          id="title"
                          required
                          value={_content.title}
                        />
                        <div className="error message visible">
                          {errorObj.title}
                        </div>
                      </div>
                    </div>
                    <br />
                    <MarkDownEditor
                      handleChange={handleChange}
                      getHTML={getHTML}
                      _content={_content}
                    />
                    <div className="error message visible">
                      {errorObj.contents}
                    </div>
                    <br />
                    <div className="form-group m-form__group row">
                      <label
                        htmlFor="email-contents"
                        className="col-lg-2 col-form-label"
                      >
                        템플릿
                      </label>
                      {radioObj.sendTo === 'email' ||
                      radioObj.sendTo === 'upload' ? (
                        <div className="col-lg-10">
                          <div className="m-radio-inline">
                            <label className="m-radio">
                              <input
                                id={0}
                                onChange={handleUsingTempletRadioBtnChange}
                                type="radio"
                                name="usingTemplet"
                                checked={radioObj.usingTemplet === 0}
                              />
                              적용
                              <span />
                            </label>
                            <label className="m-radio">
                              <input
                                id={1}
                                onChange={handleUsingTempletRadioBtnChange}
                                type="radio"
                                name="usingTemplet"
                                checked={radioObj.usingTemplet === 1}
                              />
                              적용[수신거부]
                              <span />
                            </label>
                            <label className="m-radio">
                              <input
                                id={2}
                                onChange={handleUsingTempletRadioBtnChange}
                                type="radio"
                                name="usingTemplet"
                                checked={radioObj.usingTemplet === 2}
                              />
                              미적용
                              <span />
                            </label>
                          </div>
                        </div>
                      ) : radioObj.sendTo === 'total' ||
                        radioObj.sendTo === 'adAgree' ? (
                        <div className="col-lg-10">
                          <div className="m-radio-inline">
                            <label className="m-radio">
                              <input
                                id={1}
                                onChange={handleUsingTempletRadioBtnChange}
                                type="radio"
                                name="usingTemplet"
                                checked={radioObj.usingTemplet === 1}
                              />
                              적용[수신거부]
                              <span />
                            </label>
                            {console.log('radioObj 검사', radioObj)}
                            <label className="m-radio">
                              <input
                                id={2}
                                onChange={handleUsingTempletRadioBtnChange}
                                type="radio"
                                name="usingTemplet"
                                checked={radioObj.usingTemplet === 2}
                              />
                              미적용
                              <span />
                            </label>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group m-form__group row">
                      <label
                        htmlFor="credit-reason"
                        className="col-lg-2 col-form-label"
                      >
                        첨부파일
                      </label>
                      <div className="col-lg-9 file-uploader">
                        <ul>
                          {attachments.map((file) => (
                            <li
                              onClick={(e) =>
                                handleDeleteFile(e, attachments, setAttachments)
                              }
                              id={file.key}
                              key={file.key}
                            >
                              {file.key}
                              <Octicon
                                className="octicon border-required"
                                verticalAlign="middle"
                                icon={X}
                              />
                            </li>
                          ))}
                        </ul>
                        <button
                          htmlFor="uploads"
                          type="button"
                          className="float-right btn btn-primary"
                        >
                          <label className="clickable" htmlFor="uploads">
                            찾아보기
                          </label>
                        </button>
                        <input
                          onChange={handleFileChange}
                          type="file"
                          id="uploads"
                          name="uploads"
                          accept=""
                          value=""
                          // multiple
                        />
                      </div>
                    </div>
                    <div className="form-group m-form__group row div-justfy-content">
                      <button
                        id="email"
                        onClick={checkValidation}
                        type="button"
                        className="float-right btn btn-primary"
                      >
                        발송
                      </button>
                      <button
                        id="testEmail"
                        type="button"
                        onClick={checkValidation}
                        className="float-right btn "
                      >
                        테스트
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  sendToCount: selectors.sendToCountSelector,
  sendEmailSuccess: selectors.sendEmailSuccessSelector,
  sendTestEmailSuccess: selectors.sendTestEmailSuccessSelector,
  files: selectors.filesSelector,
  errors: selectors.errorsSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onSendMail: (payload) => dispatch(actions.sendEmail(payload)),
  onReadFiles: (payload) => dispatch(actions.readFiles(payload)),
  onResetFiles: () => dispatch(actions.resetFiles()),
  onSendTestEmail: (payload) => dispatch(actions.sendTestEmail(payload)),
  onInitSendEmailSuccess: (payload) =>
    dispatch(actions.initSendEmailSuccess(payload)),
  onInitSendTestEmailSuccess: (payload) =>
    dispatch(actions.initSendTestEmailSuccess(payload)),
  onDeleteReadFiles: (payload) => dispatch(actions.deleteReadFiles(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Emails);
