import { put, takeLatest, select, call } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import moment from 'moment';
import Cookies from 'js-cookie';

import * as types from './constant';

import * as actions from './action';
import * as globalActions from '../../globalStore/action';
import { openSubMenu } from '../../../view/store/action';
import * as selectors from './selector';
import * as api from '../../../service/API';

function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split('/');

    if (pathArray[1] === 'notice') {
      yield put(openSubMenu('notice'));
      yield put(actions.getNoticeList());
    }
  } catch (err) {
    console.log('saga err', err);
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* initNoticeList({ payload }) {
  try {
    yield put(actions.getNoticeList());
  } catch (err) {
    console.log('saga err', err);
  }
}

export function* watcherInitNoticeList() {
  yield takeLatest(types.INIT_NOTICE_LIST, initNoticeList);
}

function* getNoticeList({ data }) {
  // data = paging options
  try {
    const searchKeywords = yield select(selectors.searchKeywordsSelector);
    const lastEvaluatedKey = yield select(selectors.lastEvaluatedKeySelector);

    const isSearching = Object.keys(searchKeywords);

    let _searchKeywords = {
      LastEvaluatedKey: data === 'notPaging' ? undefined : lastEvaluatedKey,
      //   isImportantLast: data ? false : undefined
    };

    //
    // 검색 시도일 때 분기처리 --> 저장되어 있는 state.noticeList 를 초기화 하고 서버에서 리턴된 검색 결과를 저장해야 함z
    // 더보기를 한번 이상 클릭 후, lastEvaluatedKey가 저장되어 있는 상태에서 검색을 시도하면 lastEvaluatedKey를 생략 후 요청을 보내야함

    if (isSearching.length) {
      yield put(actions.initNoticeListBeforeSearching());
      _searchKeywords = {
        title: searchKeywords.title,
        startDate:
          searchKeywords.startDate &&
          moment(searchKeywords.startDate).set({ hour: 0 }).toISOString(),
        endDate:
          searchKeywords.endDate &&
          moment(searchKeywords.endDate)
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString(),
        isPublic:
          searchKeywords.status && searchKeywords.status !== '전체'
            ? searchKeywords.status === '공개'
              ? 'y'
              : 'n'
            : null,
        type: searchKeywords.type
          ? searchKeywords.type !== '전체'
            ? searchKeywords.type
            : undefined
          : undefined,
        admin: searchKeywords.admin,
        language: searchKeywords.language,
      };
    }

    const {
      data: { importantList, noticeList, LastEvaluatedKey },
    } = yield call(api.notice.getNoticeList, _searchKeywords);

    yield put(
      actions.getNoticeListSuccess(
        importantList,
        noticeList,
        LastEvaluatedKey,
        data
      )
    );
  } catch (error) {
    console.log('error :', error);
  }
}

export function* watcherGetNoticeList() {
  yield takeLatest(types.GET_NOTICE_LIST, getNoticeList);
}
// title, content, type, isPublic, isImportant
function* postCreateNotice({ isEditMode }) {
  try {
    const content = yield select(selectors.contentSelector);
    const _content = yield select(selectors._contentSelector);
    const adminName = Cookies.get('usernameToDisplay');

    const { title } = _content;
    let isErr;
    if (!title) {
      yield put(actions.handleErrors({ title: '제목을 입력해주세요.' }));
      isErr = true;
    }

    if (!content) {
      yield put(actions.handleErrors({ content: '내용을 입력해주세요.' }));
      isErr = true;
    }
    if (isErr) return;

    if (isEditMode)
      yield call(
        api.notice.updateNotice,
        { ..._content, content, isHTML: true },
        _content.id
      );
    if (!isEditMode)
      yield call(api.notice.createNotice, {
        ..._content,
        content,
        isHTML: true,
        name: adminName,
      });
    yield put(actions.postCreateNoticeSuccess());
    yield put(actions.getNoticeList('notPaging'));
  } catch (error) {
    switch (error.response?.status) {
      case 429:
        yield put(
          globalActions.globalErrorHandling({
            errMsg: '상단 고정은 최대 3개까지 등록 가능합니다.',
            errCode: 429,
            type: 'POST_CREATE_NOTICE',
          })
        );
        break;

      default:
        yield put(globalActions.globalErrorHandling(error));
        break;
    }
  }
}

export function* watcherPostCreateNotice() {
  yield takeLatest(types.POST_CREATE_NOTICE, postCreateNotice);
}

function* clickNoticeDelete({ arr }) {
  try {
    const { id } = arr[11].id;
    yield call(api.notice.deleteNotice, id);
    yield put(actions.getNoticeList('notPaging'));
    yield put(actions.deleteNoticeSuccess());
  } catch (error) {
    console.log('Error in CLICK_NOTICE_DELETE', error || error.response);
  }
}

export function* watcherClickNoticeDelete() {
  yield takeLatest(types.CLICK_NOTICE_DELETE, clickNoticeDelete);
}

// function* getOverdueManageRow({ id, arr }) {
// try {
// } catch (error) {
// }
// }

// export function* watcherGetOverdueManageRow() {
// 	yield takeLatest(types.CLICK_OVERDUE_BILL_ROW, getOverdueManageRow);
// }
