/*
 * Copyright 2013-2022 AFI, Inc. All Rights Reserved.
 */

import React, { useState, useEffect } from 'react';
import {
	ButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import Octicon, { TriangleDown } from '@primer/octicons-react';

const _ButtonDropdown = ({
	dropdownItems,
	initItem,
	onGetSearchKeywords,
	handleInput,
	initInputObj,
	initSearchFilter,
	onChangeBtnDropdown,
	additionalDropDown,
	customKey,
	payPlan,
	disabled,
}) => {
	useEffect(() => {
		if (initSearchFilter) handleItemChange(initItem);
	}, [initSearchFilter, initItem]);

	const [dropdownOpen, toggleSelectBox] = useState(false);
	const [selected, handleItemChange] = useState(initItem);
	const handleToggle = () => {
		if (disabled) return;
		toggleSelectBox(!dropdownOpen);
	};

	return (
		<ButtonDropdown
			isOpen={dropdownOpen}
			toggle={handleToggle}
			className="selectBox wrapper">
			<DropdownToggle className="selectBox button">
				{dropdownItems &&
					dropdownItems.length &&
					dropdownItems.filter(
						(item) =>
							item.inDate === selected ||
							item.name === selected ||
							item.key === selected
					)[0].name}
				&nbsp;&nbsp;
				<Octicon className="icon float-right" icon={TriangleDown} />
			</DropdownToggle>
			<DropdownMenu
				onClick={(e, obj) => {
					if (handleInput) handleInput(initInputObj);
					handleItemChange(e.target.name);
					if (payPlan)
						return onChangeBtnDropdown({
              plan:
                e.target.name === 'DAU'
                  ? 'dau'
                  : e.target.name === '실버패스'
                  ? 'silver'
                  : e.target.name === '골드패스'
                  ? 'gold'
                  : e.target.name === '다이아패스'
                  ? 'diamond'
                  : e.target.name === '엔터프라이즈'
                  ? 'enterprise'
                  : e.target.name === '베이직'
                  ? 'basic'
                  : e.target.name === '개발 모드'
                  ? 'develop'
                  : null,
            });
					if (additionalDropDown)
						return onChangeBtnDropdown({ store: e.target.name });
					if (onGetSearchKeywords) {
            onGetSearchKeywords(
              customKey === 'channels'
                ? {
                    acquisition: e.target.name,
                  }
                : {
                    status:
                      e.target.name === '정상'
                        ? 'n'
                        : e.target.name === '전체'
                        ? 'all'
                        : e.target.name === '정지'
                        ? 's'
                        : 'c',
                  }
            );
          }
          if (onChangeBtnDropdown) {
            if (customKey === 'release')
              return onChangeBtnDropdown({ release: e.target.name });
            // if (customKey === 'type')
            //   return onChangeBtnDropdown({ type: e.target.name });
            if (customKey === 'isPublic')
              return onChangeBtnDropdown({ isPublic: e.target.name });
            if (customKey === 'billDate')
              return onChangeBtnDropdown({
                key: e.target.id,
                date: e.target.name,
              });
            if (customKey === 'isPay')
              return onChangeBtnDropdown({ isPay: e.target.name });
            if (customKey === 'couponDetails')
              return onChangeBtnDropdown(e.target.id);
            if (customKey === 'classParentList')
              return onChangeBtnDropdown(e.target.id);
            if (customKey === 'chargeExtraFee')
              return onChangeBtnDropdown(e.target.id);
            if (customKey === 'chat')
              return onChangeBtnDropdown({ chat: e.target.name });
            if (customKey === 'chatStatus')
              return onChangeBtnDropdown({ chatStatus: e.target.name });
            if (customKey === 'match')
              return onChangeBtnDropdown({ match: e.target.name });
            onChangeBtnDropdown({ [customKey || 'status']: e.target.name });
          }
				}}>
				{dropdownItems.map((item, i) => (
					<React.Fragment key={i}>
						{item.header ? (
							<DropdownItem header>{item.header}</DropdownItem>
						) : null}
						<DropdownItem
							key={i}
							id={item && item.key}
							name={item && item.name}>
							{item && item.name}
						</DropdownItem>
					</React.Fragment>
				))}
			</DropdownMenu>
		</ButtonDropdown>
	);
};

export default _ButtonDropdown;
