/*
 * Copyright 2013-2022 AFI, Inc. All Rights Reserved.
 */

import React, { useState } from 'react';

import DateRangePicker from './DateRangePicker';
import SingleDatePicker from './SingleDatePicker.1';
import ButtonDropdown from './ButtonDropdown';

const SearchForm = ({
  disabled,
  mainlyRequiredAction,
  initAction,
  onGetSearchKeywords,
  keywords,
  selectOpts,
  initItemForChargedBill,
  initDateValue,
}) => {
  const initInputObj = {
    id: '',
    company: '',
    signUpDate: '',
    lastLoginDate: '',
    status: '',
    init: false,
  };

  const dropdownItems = [
    {
      name: '전체',
    },
    {
      name: '정상',
    },
    {
      name: '정지',
    },
  ];
  const dropdownItemsStore = [
    {
      name: '전체',
    },
    {
      name: '등록',
    },
    {
      name: '미등록',
    },
    {
      name: '오류',
    },
  ];

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState('');

  const [focusByIcon, setFocus] = useState(null);
  const [inputObj, handleInput] = useState(initInputObj);

  const handleKeyDown = async (e) => {
    if (e.keyCode === 13) {
      await e.preventDefault();
      await mainlyRequiredAction(); // await onGetProjectList();
      await initAction(); // await onInitCurrentProjectList();
    }
  };

  return (
    <form className="m-form m-form--fit m--margin-bottom-20">
      <div className="row m--margin-bottom-20">
        {keywords && keywords.includes('쿠폰명') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>쿠폰명</label>
            <input
              onKeyDown={handleKeyDown}
              onChange={(e) =>
                onGetSearchKeywords({ couponTitle: e.target.value })
              }
              type="text"
              className="form-control m-input"
              placeholder=""
              data-col-index="0"
            />
          </div>
        ) : null}
        {keywords && keywords.includes('청구년월') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>청구년월</label>
            <ButtonDropdown
              customKey="billDate"
              //   customKey="isPublic"
              initSearchFilter={inputObj.init}
              initInputObj={initInputObj}
              handleInput={handleInput}
              onChangeBtnDropdown={onGetSearchKeywords}
              initItem={initItemForChargedBill}
              dropdownItems={
                selectOpts ? selectOpts.billDate.options : dropdownItems
              }
            />
          </div>
        ) : null}
        {keywords && keywords.includes('프로젝트') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>프로젝트</label>
            <input
              onKeyDown={handleKeyDown}
              onChange={(e) => onGetSearchKeywords({ title: e.target.value })}
              type="text"
              className="form-control m-input"
              placeholder=""
              data-col-index="0"
            />
          </div>
        ) : null}
        {keywords && keywords.includes('제목') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>제목</label>
            <input
              onKeyDown={handleKeyDown}
              onChange={(e) => onGetSearchKeywords({ title: e.target.value })}
              type="text"
              className="form-control m-input"
              placeholder=""
              data-col-index="0"
            />
          </div>
        ) : null}
        {keywords && keywords.includes('아이디') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>아이디</label>
            <input
              onKeyDown={handleKeyDown}
              onChange={(e) => onGetSearchKeywords({ id: e.target.value })}
              type="text"
              className="form-control m-input"
              placeholder=""
              data-col-index="1"
            />
          </div>
        ) : null}
        {keywords && keywords.includes('일자') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>일자</label>
            <SingleDatePicker
              value={initDateValue}
              focusByIcon={focusByIcon && focusByIcon.singleDatePicker1}
              setFocus={setFocus}
              onGetSearchItem={onGetSearchKeywords}
              searchParam="date"
              initSearchFilter={inputObj.init}
              initInputObj={initInputObj}
            />
          </div>
        ) : null}
        {keywords && keywords.includes('발행일') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>발행일</label>
            <div className="input-group pull-right" id="m_daterangepicker1">
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                focusedInput={focusedInput}
                setFocusedInput={setFocusedInput}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                initInputObj={initInputObj}
                initSearchFilter={inputObj.init}
                searchParam="signUpDate"
                onGetSearchItem={onGetSearchKeywords}
                setFocus={setFocus}
                focusByIcon={focusByIcon && focusByIcon.singleDatePicker1}
              />
            </div>
          </div>
        ) : null}
        {keywords && keywords.includes('작성자') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>작성자</label>
            <input
              onKeyDown={handleKeyDown}
              onChange={(e) => onGetSearchKeywords({ admin: e.target.value })}
              type="text"
              className="form-control m-input"
              placeholder=""
              data-col-index="1"
            />
          </div>
        ) : null}
        {keywords && keywords.includes('회사명') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>회사명</label>
            <input
              onKeyDown={handleKeyDown}
              onChange={(e) =>
                onGetSearchKeywords({
                  companyName: e.target.value,
                })
              }
              type="text"
              className="form-control m-input"
              placeholder=""
              data-col-index="2"
            />
          </div>
        ) : null}
        {keywords && keywords.includes('구분') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>구분</label>
            <ButtonDropdown
              customKey="status"
              //   customKey="isPublic"
              initSearchFilter={inputObj.init}
              initInputObj={initInputObj}
              handleInput={handleInput}
              onChangeBtnDropdown={onGetSearchKeywords}
              initItem="이달"
              dropdownItems={[
                { key: 0, name: '이달' },
                { key: 1, name: '지난달' },
              ]}
            />
          </div>
        ) : null}
        {keywords && keywords.includes('연체발생일') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>연체발생일</label>
            <div className="input-group pull-right" id="m_daterangepicker1">
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                focusedInput={focusedInput}
                setFocusedInput={setFocusedInput}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                initInputObj={initInputObj}
                initSearchFilter={inputObj.init}
                searchParam="signUpDate"
                onGetSearchItem={onGetSearchKeywords}
                setFocus={setFocus}
                focusByIcon={focusByIcon && focusByIcon.singleDatePicker1}
              />
            </div>
          </div>
        ) : null}
        {keywords && keywords.includes('처리상태') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>처리상태</label>
            <ButtonDropdown
              customKey="status"
              //   customKey="isPublic"
              initSearchFilter={inputObj.init}
              initInputObj={initInputObj}
              handleInput={handleInput}
              onChangeBtnDropdown={onGetSearchKeywords}
              initItem="전체"
              dropdownItems={
                selectOpts ? selectOpts.status.options : dropdownItems
              }
            />
          </div>
        ) : null}
        {keywords && keywords.includes('납부상태') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>납부상태</label>
            <ButtonDropdown
              customKey="isPay"
              initSearchFilter={inputObj.init}
              initInputObj={initInputObj}
              onChangeBtnDropdown={onGetSearchKeywords}
              handleInput={handleInput}
              initItem="전체"
              dropdownItems={
                selectOpts ? selectOpts.isPay.options : dropdownItems
              }
            />
          </div>
        ) : null}
        {keywords && keywords.includes('고객규모') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>고객규모</label>
            <ButtonDropdown
              customKey="is100mClub"
              initSearchFilter={inputObj.init}
              initInputObj={initInputObj}
              handleInput={handleInput}
              onChangeBtnDropdown={(obj) =>
                onGetSearchKeywords({
                  is100mClub:
                    obj.status === '1억클럽'
                      ? 'y'
                      : obj.status === '일반'
                      ? 'n'
                      : null,
                })
              }
              initItem="전체"
              dropdownItems={
                selectOpts ? selectOpts.is100mClub.options : dropdownItems
              }
            />
          </div>
        ) : null}

        {keywords && keywords.includes('등록일') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>등록일</label>
            <div className="input-group pull-right" id="m_daterangepicker1">
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                focusedInput={focusedInput}
                setFocusedInput={setFocusedInput}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                initInputObj={initInputObj}
                initSearchFilter={inputObj.init}
                searchParam="signUpDate"
                onGetSearchItem={onGetSearchKeywords}
                setFocus={setFocus}
                focusByIcon={focusByIcon && focusByIcon.singleDatePicker1}
              />
            </div>
          </div>
        ) : null}
        {keywords && keywords.includes('분류') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>분류</label>
            <ButtonDropdown
              customKey="type"
              initSearchFilter={inputObj.init}
              initInputObj={initInputObj}
              handleInput={handleInput}
              onChangeBtnDropdown={onGetSearchKeywords}
              initItem="전체"
              dropdownItems={
                selectOpts ? selectOpts.type.options : dropdownItems
              }
            />
          </div>
        ) : null}

        {keywords && keywords.includes('생성일') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>생성일</label>
            <div className="input-group pull-right" id="m_daterangepicker1">
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                focusedInput={focusedInput}
                setFocusedInput={setFocusedInput}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                initInputObj={initInputObj}
                initSearchFilter={inputObj.init}
                searchParam="signUpDate"
                onGetSearchItem={onGetSearchKeywords}
                setFocus={setFocus}
                focusByIcon={focusByIcon && focusByIcon.singleDatePicker1}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="row m--margin-bottom-20">
        {keywords && keywords.includes('스토어 정보') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>스토어 정보</label>
            <ButtonDropdown
              additionalDropDown
              initSearchFilter={inputObj.init}
              initInputObj={initInputObj}
              handleInput={handleInput}
              onChangeBtnDropdown={onGetSearchKeywords}
              initItem="전체"
              dropdownItems={dropdownItemsStore}
            />
          </div>
        ) : null}
        {keywords && keywords.includes('상태') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>상태</label>
            <ButtonDropdown
              customKey="status"
              initSearchFilter={inputObj.init}
              initInputObj={initInputObj}
              handleInput={handleInput}
              onChangeBtnDropdown={onGetSearchKeywords}
              initItem="전체"
              dropdownItems={
                selectOpts ? selectOpts.status.options : dropdownItems
              }
            />
          </div>
        ) : null}

        {keywords && keywords.includes('언어') ? (
          <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
            <label>언어</label>
            <ButtonDropdown
              customKey="language"
              initSearchFilter={inputObj.init}
              initInputObj={initInputObj}
              handleInput={handleInput}
              onChangeBtnDropdown={onGetSearchKeywords}
              initItem="전체"
              dropdownItems={
                selectOpts ? selectOpts.language.options : dropdownItems
              }
            />
          </div>
        ) : null}
      </div>
      <div className="row">
        <div className="col-lg-12">
          <button
            disabled={disabled}
            onClick={async (e) => {
              await e.preventDefault();
              await mainlyRequiredAction();
              await initAction();
            }}
            className="btn btn-brand m-btn m-btn--icon"
            id="m_search"
          >
            <span>
              <i className="la la-search" />
              <span>검색</span>
            </span>
          </button>
          &nbsp;&nbsp;
          <button className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
            <span>
              <i className="la la-close" />
              <span>초기화</span>
            </span>
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchForm;
