// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import { all, fork, call, put, select, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { LOCATION_CHANGE } from 'react-router-redux';
import moment from 'moment';
import aws from 'aws-sdk';

import * as types from './constant';
import * as actions from './action';
import { openSubMenu, handleClickSubMenu } from '../../../view/store/action';
import * as globalActions from '../../globalStore/action';
import * as selectors from './selector';
import * as api from '../../../service/API';
import BaseUrl from '../../../shared/baseUrl';
import { getCountryName } from '../../../utils/helper';

function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split('/');

    if (pathArray[1] === 'accountManaging') {
      yield put(openSubMenu('account'));
      yield put(handleClickSubMenu('generalAccount'));
      yield put(actions.initAccountInfo());
      yield put(actions.initAccountList());
    }
    if (pathArray[1] === 'deleteAccount') {
      yield put(openSubMenu('account'));
      yield put(handleClickSubMenu('deleteAccount'));
      yield put(actions.initDeletedAccountList());
    }
  } catch (err) {
    console.log('saga err', err);
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

const accountType = {
  y: '1억클럽',
  1: '1억클럽',
  10: '10억클럽',
  30: '30억클럽',
};

function* initAccountList({ date, isSearching, isInit }) {
  try {
    const offset = yield select(selectors.offsetSelector);
    // 검색 결과에 대해서 isLast false일 때 offset 증가 처리 필요
    const {
      data: {
        accountInfoList,
        isLast,
        generalAccountCount,
        internalAccountCount,
        totalCount,
      },
    } = yield call(api.account.getAccountList, {
      ...date,
      offset: isInit
        ? null
        : !isSearching
        ? offset.accountInfoListOffset
        : offset.searchingAccountInfoListOffset,
    });

    const _accountInfoList = accountInfoList.map((account) => ({
      ID: account.email,
      account_id: { account_id: account.account_id, noDisplay: true },
      _account_id: account.account_id,
      company: account.companyName,
      country: getCountryName(account.country),
      project: account.projectCount,
      admin: account.gmCount,
      credit: account.credit,
      chargedTotal: account.accountTotalPayments,
      signUpDate: account.joinDate
        ? moment(account.joinDate)
        : moment('1900-01-01'),
      lastLoginDate: account.latestConnect
        ? moment(account.latestConnect)
        : moment('1900-01-01'),
      is100mClub: accountType[account.accountType] ?? '일반',
      status: account.status,
      remoteSupportAgreement: account.remoteSupportAgreement,
      stopScope: { value: account.stop_scope, noDisplay: true },
    }));

    if (isSearching)
      yield put(
        actions.getSearchedAccountListSuccess(_accountInfoList, isLast)
      );
    if (!isSearching)
      yield put(
        actions.getAccountListSuccess(
          _accountInfoList,
          { generalAccountCount, internalAccountCount, totalCount },
          isLast
        )
      );
  } catch (error) {
    console.log(error, '');
    console.log(error && error.response, '');
  }
}

export function* watcherInitAccountList() {
  yield takeLatest(types.INIT_ACCOUNT_LIST, initAccountList);
}

function* _initAccountList() {
  try {
    const {
      data: {
        accountInfoList,
        isLast,
        generalAccountCount,
        internalAccountCount,
        totalCount,
      },
    } = yield call(api.account.getAccountList, {
      offset: 0,
    });

    const _accountInfoList = accountInfoList.map((account) => ({
      ID: account.email,
      account_id: { account_id: account.account_id, noDisplay: true },
      _account_id: account.account_id,
      company: account.companyName,
      country: getCountryName(account.country),
      project: account.projectCount,
      admin: account.gmCount,
      credit: account.credit,
      chargedTotal: account.accountTotalPayments,
      is100mClub: accountType[account.is100mClub] ?? '일반',
      status: account.status,
      remoteSupportAgreement: account.remoteSupportAgreement,
      stopScope: { value: account.stop_scope, noDisplay: true },
    }));
    yield put(
      actions.getAccountListSuccess(
        _accountInfoList,
        { generalAccountCount, internalAccountCount, totalCount },
        isLast
      )
    );
  } catch (error) {
    console.log(error && error.response, '_INIT_ACCOUNT_LIST');
  }
}

export function* watcher_InitAccountList() {
  yield takeLatest(types._INIT_ACCOUNT_LIST, _initAccountList);
}

function* getAccountInfo({ id }) {
  try {
    const {
      data: { accountInfo },
    } = yield call(api.account.getAccountInfo, id);
    yield put(actions.getMemoList(id));
    const _accountInfo = {
      ID: accountInfo.account_id,
      email: accountInfo.email,
      nickname: accountInfo.nickname,
      company: accountInfo.companyName,
      project: accountInfo.projectCount,
      admin: accountInfo.gmCount,
      credit: accountInfo.credit,
      chargedTotal: accountInfo.accountTotalPayments,
      signUpDate: moment(accountInfo.joinDate).format('YYYY.MM.DD HH:mm'),
      lastLoginDate: moment(accountInfo.latestConnect).format(
        'YYYY.MM.DD HH:mm'
      ),
      status: accountInfo.status,
      phoneNumber: accountInfo.phoneNumber,
      payment: accountInfo.payment,
      totalDelayedPayments: accountInfo.totalDelayedPayments,
      internalAccount: accountInfo.internalAccount,
      isAutoPay: accountInfo.isAutoPay,
      mailAdAgreement:
        accountInfo.mailAdAgreement === 'y'
          ? '동의'
          : accountInfo.mailAdAgreement === 'n'
          ? '미동의'
          : '-',
      smsAdAgreement:
        accountInfo.smsAdAgreement === 'y'
          ? '동의'
          : accountInfo.smsAdAgreement === 'n'
          ? '미동의'
          : '-',
      acquisition:
        accountInfo.acquisition === 'organic'
          ? '자연유입'
          : accountInfo.acquisition === 'googleAds'
          ? '광고유입'
          : accountInfo.acquisition,
      is100mClub: accountType[accountInfo.is100mClub] ?? '일반',
      stopScope: accountInfo.stop_scope,
    };

    yield put(actions.getAccountInfoSuccess(_accountInfo)); // 리턴된 데이터를 store로
  } catch (error) {}
}

export function* watcherGetAccountInfo() {
  yield takeLatest(types.CLICK_ACCOUNT_ROW, getAccountInfo);
}

function* getAdminAccounts({ id }) {
  try {
    const {
      data: { gmList },
    } = yield call(api.account.getAdminAccounts, id);

    const _gmList = gmList.map((gm) => ({
      gm_id: gm.gm_id,
      nickname: gm.nickname,
      name: gm.name,
      joinDate: moment(gm.joinDate).format('YYYY.MM.DD HH:mm'),
      latestConnect: gm.latestConnect
        ? moment(gm.latestConnect).format('YYYY.MM.DD HH:mm')
        : '-',
    }));

    yield put(actions.getAdminAccountsSuccess(_gmList)); // 리턴된 데이터를 store로
  } catch (error) {}
}

export function* watcherGetAdminAccounts() {
  yield takeLatest(types.GET_ADMIN_ACCOUNTS, getAdminAccounts);
}

function* getCreditInfo({ id }) {
  try {
    const {
      data: { rows },
    } = yield call(api.account.getCreditList, id);

    const _rows = rows.map((row) => ({
      id: {
        noDisplay: true,
        value: row.id,
      },
      inDate: row.inDate,
      credit: row.credit,
      details: row.details,
      admin_name: row.admin_name,
    }));

    yield put(actions.getCreditInfoSuccess(_rows));
  } catch (error) {}
}

export function* watcherGetCreditInfo() {
  yield takeLatest(types.GET_CREDIT_INFO, getCreditInfo);
}

function* deleteCredit({ params: { id, usagehistoryId } }) {
  try {
    yield call(api.account.deleteCredit, id, usagehistoryId);
    yield put(actions.getCreditInfo(id));
  } catch (error) {
    yield put(globalActions.globalErrorHandling(error));
  }
}

export function* watcherDeleteCredit() {
  yield takeLatest(types.DELETE_CREDIT, deleteCredit);
}

function* getAccountStatus({ id }) {
  try {
    const { data } = yield call(api.account.getAccountStatus, id);

    const _unmarshalled = data.map((obj) => {
      const _obj = aws.DynamoDB.Converter.unmarshall(obj);
      return {
        date: moment(_obj.inDate).format('YYYY.MM.DD HH:mm'),
        status: _obj.status,
        reason: _obj.details,
        admin: _obj.admin_name,
        stopScope: { value: _obj.stop_scope, noDisplay: true },
      };
    });

    yield put(
      actions.getAccountStatusSuccess(data && data.length ? _unmarshalled : [])
    );
  } catch (error) {}
}

export function* watcherGetAccountStatus() {
  yield takeLatest(types.GET_ACCOUNT_STATUS, getAccountStatus);
}

function deleteAccount({ id, reason }) {
  try {
    // yield call(api.account.deleteAccount, id,reason);
    // yield put(actions.getBackToList());
    // yield put(actions.initAccountList());
  } catch (error) {}
}

export function* watcherDeleteAccount() {
  yield takeLatest(types.CONFIRM_DELETE_ACCOUNT, deleteAccount);
}

function* initDeletedAccountList() {
  try {
    yield put(actions.getDeletedAccountList());
  } catch (error) {
    console.log('INIT_DELETED_ACCOUNT_LIST ERR', error);
  }
}

export function* watcherInitDeletedAccountList() {
  yield takeLatest(types.INIT_DELETED_ACCOUNT_LIST, initDeletedAccountList);
}

function* getDeletedAccountList({ param }) {
  try {
    const _LastEvaluatedKey = yield select(selectors.LastEvaluatedKeySelector);

    const {
      data: { Items, LastEvaluatedKey },
    } = yield call(api.account.getWithdrawedAccounts, {
      offset: _LastEvaluatedKey,
    });
    const _data = Items.map((obj) => {
      const unmarshalled = aws.DynamoDB.Converter.unmarshall(obj);
      return unmarshalled;
    });
    yield put(
      actions.getDeletedAccountListSuccess(
        _data,
        Items.map((d) => {
          const unmarshalled = aws.DynamoDB.Converter.unmarshall(d);
          return {
            ...unmarshalled,
            inDate: moment(unmarshalled.inDate),
          };
        }),
        param
      )
    );
    yield put(actions.getLastEvaluatedKeySuccess(LastEvaluatedKey));
  } catch (error) {
    console.log('GET_DELETED_ACCOUNT_LIST ERR', error);
  }
}

export function* watcherGetDeletedAccountList() {
  yield takeLatest(types.GET_DELETED_ACCOUNT_LIST, getDeletedAccountList);
}

function* saveInput({ value, id }) {
  try {
    const { type } = yield value;

    switch (type) {
      case 'accountStatus':
        const { status, stop_scope } = value;
        yield call(api.account.updateStatus, id, {
          status,
          details: value.details,
          stop_scope,
        });
        yield put(actions.getAccountStatus(id));
        yield put(actions.clickAccountRow(id));
        break;

      case 'credit':
        const { expirationDuration, creditAmount, details } = value;
        yield call(api.account.issueCredit, id, {
          expirationDuration: Number(expirationDuration),
          creditAmount: Number(creditAmount),
          details,
        });
        yield put(actions.clickAccountRow(id));
        break;

      case 'memo':
        const { memo } = yield value;
        yield call(api.account.createMemo, id, {
          memo,
        });
        yield put(actions.clickAccountRow(id));
        break;

      default:
        break;
    }
  } catch (error) {
    console.log(error && error.response.status);
    if (error.response.status === 404)
      yield put(
        actions.handleErrors({ accountStatus: '사유는 필수 입력 항목입니다.' })
      );
  }
}

export function* watcherSaveInput() {
  yield takeLatest(types.SAVE_INPUT, saveInput);
}

function* deleteRow({ id, arr }) {
  try {
    const inDate = arr[1].actualDate.actualDate;
    yield call(api.account.deleteMemo, id, inDate);
    yield put(actions.getMemoList(id));
  } catch (error) {}
}

export function* watcherDeleteRow() {
  yield takeLatest(types.DELETE_ROW, deleteRow);
}

function* getMemoList({ id }) {
  try {
    const {
      data: { Items },
    } = yield call(api.account.getMemoList, id);

    const _Items = Items.map((item) => {
      const unmarshalled = aws.DynamoDB.Converter.unmarshall(item);
      return {
        date: moment(unmarshalled.inDate).format('YYYY.MM.DD HH:mm'),
        actualDate: { actualDate: unmarshalled.inDate, noDisplay: true },
        content: unmarshalled.memo_contents,
        admin: unmarshalled.admin_name,
      };
    });

    yield put(actions.getMemoListSuccess(_Items)); // 리턴된 데이터를 store로
  } catch (error) {}
}

export function* watcherGetMemoList(params) {
  yield takeLatest(types.GET_MEMO_LIST, getMemoList);
}

function* searchDeletedAccount() {
  try {
    const searchItems = yield select(selectors.searchItemSelector); // 검색어
    // console.log(searchItems, "검색 키워드");

    const options = {
      withdrawStartDate: searchItems.startDate
        ? searchItems.startDate.set({ hour: 0 }).toISOString()
        : null,
      withdrawEndDate: searchItems.endDate
        ? searchItems.endDate.set({ hour: 24 }).toISOString()
        : null,
      status: searchItems.status,
      email: searchItems.email,
      admin: searchItems.admin,
      details: searchItems.reason,
      // offset: LastEvaluatedKey
    };

    const {
      data: { Items, LastEvaluatedKey },
    } = yield call(api.account.getWithdrawedAccounts, options);

    const _Items = Items.map((obj) => {
      const unmarshalled = aws.DynamoDB.Converter.unmarshall(obj);
      return {
        email: unmarshalled.email,
        inDate: moment(unmarshalled.inDate).format('YYYY.MM.DD HH:mm'),
        status: unmarshalled.type,
        admin: unmarshalled.admin_name,
        reason: unmarshalled.details,
      };
    });

    yield put(
      actions.getSearchedDelAccountListSuccess(
        _Items,
        _Items.map((d) => {
          return {
            ...d,
            inDate: moment(d.inDate),
          };
        })
      )
    );
    yield put(actions.getLastEvaluatedKeySuccess(LastEvaluatedKey));
  } catch (error) {
    console.log(error);
  }
}

export function* watcherSearchDeletedAccount(params) {
  yield takeLatest(types.SEARCH_DEL_ACCOUNT, searchDeletedAccount);
}

function* searchAccounts({ date }) {
  try {
    const searchKeywords = yield select(selectors.searchKeywordsSelector); // 검색어

    let joinStartDate;
    let joinEndDate;
    if (date) {
      joinStartDate = date.startDate.set({ hour: 0 }).toISOString();
      joinEndDate = date.endDate
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
        .toISOString();
    }
    // const { startDate, endDate } = date;
    const {
      companyName,
      status,
      email,
      acquisition,
      accountId,
      phoneNumber,
      accountType,
    } = searchKeywords;

    yield put(
      actions.initAccountList(
        {
          accountId,
          acquisition:
            acquisition === '자연유입'
              ? 'organic'
              : acquisition === '구글애즈'
              ? 'googleAds'
              : null,
          companyName,
          status: status === 'all' ? null : status,
          email,
          joinStartDate,
          joinEndDate,
          phoneNumber,
          accountType,
        },
        true
      )
    );
  } catch (error) {
    console.log(error);
  }
}

export function* watcherSearchAccounts(params) {
  yield takeLatest(types.SEARCH_ACCOUNTS, searchAccounts);
}

function* updateInternalAccount({ id, internalAccount }) {
  try {
    yield call(api.account.updateInternalAccount, id, internalAccount);
    yield put(actions.clickAccountRow(id));
  } catch (error) {
    console.log('UPDATE_INTERNAL_ACCOUNT ERROR : ', error);
  }
}

export function* watcherUpdateInternalAccount() {
  yield takeLatest(types.UPDATE_INTERNAL_ACCOUNT, updateInternalAccount);
}

function* updateAutoPay({ id, isAutoPay }) {
  try {
    yield call(api.account.updateAutoPay, id, isAutoPay);
    yield put(actions.clickAccountRow(id));
  } catch (error) {
    console.log('UPDATE_AUTO_PAY ERROR : ', error);
  }
}

export function* watcherUpdateAutoPay() {
  yield takeLatest(types.UPDATE_AUTO_PAY, updateAutoPay);
}

function* handlePostCredit({ params }) {
  try {
    yield call(api.account.handlePostCredit, params);
    yield put(actions.handlePostCreditSuccess());
    yield call(delay, 2000);

    yield put(actions.initToSearchAccounts());
    yield put(actions.searchAccounts());
  } catch (error) {
    console.log('HANDLE_POST_CREDIT : ', error);
  }
}

export function* watcherHandlePostCredit() {
  yield takeLatest(types.HANDLE_POST_CREDIT, handlePostCredit);
}

function* getAccessToken({ payload: data }) {
  try {
    yield put(globalActions.globalLoading());

    const {
      data: { accessToken },
    } = yield call(api.account.postAccessToken, data);

    window.open(`${BaseUrl.CONSOLE}/login?token=${accessToken}`);

    yield put(globalActions.globalLoadingSuccess());
  } catch (error) {
    yield put(globalActions.globalErrorHandling(error));
  }
}

export function* watcherGetAccessToken() {
  yield takeLatest(types.GET_ACCESS_TOKEN, getAccessToken);
}
function* putAccountType({ payload }) {
  try {
    yield put(globalActions.globalLoading());

    yield call(api.account.putAccountType, payload);
    yield put(globalActions.globalLoadingSuccess());

    yield put(actions.initCurrentAccountList());
    yield put(actions.initAccountList(null, null, true));
  } catch (error) {
    yield put(globalActions.globalErrorHandling(error));
  }
}

export function* watcherPutIs100mClub() {
  yield takeLatest(types.PUT_ACCOUNT_TYPE, putAccountType);
}

function* getAccountType({ payload }) {
  try {
    yield put(globalActions.globalLoading());

    const { data } = yield call(api.account.getAccountType, payload);
    yield put(actions.getAccountTypeSuccess(data));
  } catch (error) {
    yield put(globalActions.globalErrorHandling(error));
  }
}

export function* watcherGetAccountType() {
  yield takeLatest(types.GET_ACCOUNT_TYPE, getAccountType);
}

function* sagas() {
  yield all([
    fork(watcherLocationChange),
    fork(watcherInitAccountList),
    fork(watcher_InitAccountList),
    fork(watcherGetAccountInfo),
    fork(watcherGetAdminAccounts),
    fork(watcherGetCreditInfo),
    fork(watcherDeleteCredit),
    fork(watcherGetAccountStatus),
    fork(watcherDeleteAccount),
    fork(watcherInitDeletedAccountList),
    fork(watcherGetDeletedAccountList),
    fork(watcherSaveInput),
    fork(watcherDeleteRow),
    fork(watcherGetMemoList),
    fork(watcherSearchDeletedAccount),
    fork(watcherSearchAccounts),
    fork(watcherUpdateInternalAccount),
    fork(watcherUpdateAutoPay),
    fork(watcherHandlePostCredit),
    fork(watcherGetAccessToken),
    fork(watcherPutIs100mClub),
    fork(watcherGetAccountType),
  ]);
}

export default sagas;
