import React from 'react';
import DataTable from '../../fnComponents/_DataTable';

import Header from '../../view/header';
import LeftAsideMunu from '../../view/leftAsideMenu';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from './store/action';
import * as selectors from './store/selector';
import EmailCreateModal from './EmailCreateModal';

const Emails = ({
  mailingConditions,
  columns,
  onUpdateEmailUsing,
  onGetEmail,
  detail: { emailCreate },
  onToggle,
}) => {
  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title">메일 관리</h3>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <DataTable
                      customClassName="mails"
                      clickableIndex={[0]}
                      updateEmailUsing
                      requiredAction={onGetEmail}
                      secondRequiredAction={onUpdateEmailUsing}
                      // secondRequiredAction={handleClickNoticeDelete}
                      rows={mailingConditions}
                      columns={columns}
                      onToggle={onToggle}
                    />
                    {emailCreate && (
                      <EmailCreateModal isEmailCreateModalOpen={emailCreate} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  columns: selectors.columnsSelector,
  detail: selectors.clickedIdSelector,
  mailingConditions: selectors.mailingConditionsSelector,
  // manageOverdueBill: selectors.manageOverdueBillSelector,
  // chargedBillDetail: selectors.chargedBillDetailSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onGetEmail: (key, arr) => dispatch(actions.getEmail(key, arr)),
  onUpdateEmailUsing: (key, arr, selectedId) =>
    dispatch(actions.updateEmailUsing(key, arr, selectedId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Emails);
