// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { createSelector } from 'reselect';

// import aws from 'aws-sdk';
import moment from 'moment';

export const clickedIdSelector = createSelector(
  (state) => state.currentChargePayPerUse.detail,
  (detail) => detail
);

export const idSelector = createSelector(
  (state) => state.currentChargePayPerUse.detail,
  (detail) => {
    const {
      clickedId: { email },
    } = detail;
    return email;
  }
);

export const columnsSelector = createSelector(
  (state) => state.currentChargePayPerUse.columns,
  (columns) => columns
);

export const detailColumnsSelector = createSelector(
  (state) => state.currentChargePayPerUse.detailColumns,
  (detailColumns) => detailColumns
);

export const gameTitleSelector = createSelector(
  (state) => state.currentChargePayPerUse.gameTitle,
  (gameTitle) => gameTitle
);

export const accountListSelector = createSelector(
  (state) => state.currentChargePayPerUse.currentBill,
  (currentBill) => {
    if (currentBill && currentBill.length)
      return currentBill.map((row) => ({
        email: row.email,
        id: row.id,
        companyName: row.companyName,
        gameCount: row.gameCount,
        inDate: moment(row.inDate).format('YYYY.MM.DD'),
        currentChargingPrice:
          row.currentChargingPrice && row.currentChargingPrice.toLocaleString(),
        totalPaidAmount:
          row.totalPaidAmount && row.totalPaidAmount.toLocaleString(),
      }));
    else return [];
  }
);
export const _accountListSelector = createSelector(
  (state) => state.currentChargePayPerUse.currentBill,
  (currentBill) => {
    if (currentBill && currentBill.length) {
      console.log(currentBill, '!!!');
      return currentBill.map((row) => ({
        email: row.email,
        id: { ...row.id },
        // id: { id: row.id, noDisplay: true },
        companyName: row.companyName,
        gameCount: row.gameCount,
        inDate: moment(row.inDate),
        // inDate: moment(row.inDate).format("YYYY.MM.DD"),
        currentChargingPrice: row.currentChargingPrice,
        totalPaidAmount: row.totalPaidAmount,
      }));
    }
  }
);

export const projectChargeListSelector = createSelector(
  // for VIEW
  (state) => state.currentChargePayPerUse.accountBill,
  (accountBill) => {
    const { bills, sumResults, billIssued } = accountBill;

    if (bills) {
      const arr = bills.map((bill) => {
        let obj = {
          gameTitle: bill.gameTitle,
          beforeDC: bill.beforeDC,
          freePrice: bill.freePrice,
          chargePrice: bill.chargePrice,
          chargeDetail: [
            //   noDisplay: true,
            {
              [bill.dau ? 'dau' : null]: {
                beforeDC: bill.dau ? bill.dau.beforeDC : null,
                freePrice: bill.dau ? bill.dau.freePrice : null,
                chargePrice: bill.dau ? bill.dau.chargePrice : null,
              },
            },
            {
              [bill.share ? 'share' : null]: {
                beforeDC: bill.share ? bill.share.beforeDC : null,
                freePrice: bill.share ? bill.share.freePrice : null,
                chargePrice: bill.share ? bill.share.chargePrice : null,
                type: bill.share ? bill.share.type : null,
              },
            },
            {
              [bill.db ? 'db' : null]: {
                beforeDC: bill.db ? bill.db.beforeDC : null,
                freePrice: bill.db ? bill.db.freePrice : null,
                chargePrice: bill.db ? bill.db.chargePrice : null,
              },
            },
            bill.extra
              ? {
                  extra: bill.extra.rows.map((row) => ({
                    details: row.details,
                    inDate: row.inDate,
                    chargePrice: row.chargePrice,
                  })),
                }
              : null,
            // }))} : {}
          ],
          gameId: bill.gameId,
        };
        obj = {
          ...obj,
          chargeDetail: obj.chargeDetail.map((detail) => {
            if (detail && !detail.null) return detail;
          }),
        };
        return obj;
      });

      return { arr, sumResults, billIssued };
    }
  }
);

export const currentChargeSelector = createSelector(
  (state) => state.currentChargePayPerUse.accountBill,
  (accountBill) => {
    const { list, month, usedPriceSum, freeCostSum, costSum, calculatedSum } =
      accountBill;
    if (list) {
      return {
        list: list.map((game) => ({
          gameTitle: game.title,
          beforeDC: game.usedPrice,
          freePrice: game.freeCost,
          chargePrice: game.cost,
          gameId: game.gameId,
          plan: game.plan,
          discountRate: game.discountRate,
          base: game.base,
          db: game.db,
          function: game.function,
          push: game.push,
          calculated: game.calculated,
          extraBillSum: game.extraBillSum,
          extraItems: game.extraItems,
        })),
        month,
        beforeDC: usedPriceSum,
        freePrice: freeCostSum,
        chargePrice: costSum,
        calculatedSum,
      };
    }
    return {
      list: [],
      beforeDC: 0,
      freePrice: 0,
      chargePrice: 0,
    };
  }
);

export const projectListForDropdownSelector = createSelector(
  // for VIEW
  (state) => state.currentChargePayPerUse.accountBill,
  (accountBill) => {
    const { list } = accountBill;

    if (list && list.length) {
      const _arr = list.map((game) => ({
        ...game,
        name: game.title,
        key: game.gameId,
      }));

      _arr.unshift({
        name: '',
      });

      return _arr;
    }
  }
);

export const gameBillSelector = createSelector(
  (state) => state.currentChargePayPerUse.gameBill,
  (gameBill) => gameBill
);

export const statusSelector = createSelector(
  (state) => state.currentChargePayPerUse.status,
  (status) => status
);

export const searchKeywordsSelector = createSelector(
  (state) => state.currentChargePayPerUse.searchKeywords,
  (searchKeywords) => searchKeywords
);
export const isLoadingSelector = createSelector(
  (state) => state.currentChargePayPerUse.isLoading,
  (isLoading) => isLoading
);

export const sortingParamSelector = createSelector(
  (state) => state.currentChargePayPerUse.sortingParam,
  (sortingParam) => sortingParam
);

export const isLastSelector = createSelector(
  (state) => state.currentChargePayPerUse.isLast,
  (isLast) => isLast
);

export const offsetSelector = createSelector(
  (state) => state.currentChargePayPerUse.offset,
  (offset) => offset
);

// 계정의 ID 불러오기
export const accountIdSelector = createSelector(
  (state) => state.currentChargePayPerUse.detail,
  ({ accountInfo }) => {
    console.log('accountInfo', accountInfo);
    if (accountInfo && accountInfo.length) return accountInfo[1].id.id;
  }
);

/**
export const accountBillSelector = createSelector(
  // 요금 서브 디테일 for VIEW ----> 삭제 할 것
  state => state.currentChargePayPerUse.accountBill,
  accountBill => {
    const { bills } = accountBill;
    let share = {};
    let dau = {};
    let db = {};
    return bills.map(bill => {
      console.log(bill, "빌 테스트");
      share = {};
      dau = {};
      db = {};
      if (bill.dau) {
        dau = {
          beforeDC: bill.dau.beforeDC,
          freePrice: bill.dau.freePrice,
          chargePrice: bill.dau.chargePrice
        };
      }
      if (bill.share) {
        share = {
          beforeDC: bill.share.beforeDC,
          freePrice: bill.share.freePrice,
          chargePrice: bill.share.chargePrice,
          type: bill.share.type
        };
      }
      if (bill.db) {
        db = {
          beforeDC: bill.db ? bill.db.beforeDC : null,
          freePrice: bill.db ? bill.db.freePrice : null,
          chargePrice: bill.db ? bill.db.chargePrice : null
        };
      }

      const checkingObj = {
        isDauTrue: !!Object.keys(dau).length,
        isShareTrue: !!Object.keys(share).length,
        isDbTrue: !!Object.keys(db).length
      };

      const { isDauTrue, isShareTrue, isDbTrue } = checkingObj;

      const obj = {
        gameTitle: bill.gameTitle,
        [isDauTrue ? "dau" : null]: dau,
        [isShareTrue ? "share" : null]: share,
        [isDbTrue ? "db" : null]: db
      };
      delete obj.null;
      console.log(obj, "객체 재생성");
      return obj;
    });
  }
);
*/
