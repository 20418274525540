// 공통모듈
import React, { useState, useEffect } from 'react';
import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as actions from './store/action';
import * as selectors from './store/selector';

//
import { Button } from 'reactstrap';

import MsgModal from '../../../fnComponents/MsgModal';
import MarkDownEditor from './MarkDownEditor';

const Policy = ({
  _content, // 원본
  currentContents, // 현재 버전
  lastModified,
  onHandleOnChange,
  onUpdateTermsPolicy,
  onInitTermsPolicyState,
}) => {
  const [msgModal, handleMsgModal] = useState({
    isModalOpen: false,
    msg: null,
    func: null,
  });

  const handleSubmit = () => {
    const { content } = currentContents;
    onUpdateTermsPolicy({ contents: content });
  };

  const handleControlModal = () => {
    handleMsgModal({
      isModalOpen: false,
      msg: null,
      func: null,
    });
  };

  const handleButtons = (e) => {
    if (e.target.id === 'submit')
      return handleMsgModal({
        isModalOpen: true,
        msg: '수정 사항을 적용 하시겠습니까?',
        func: () => {
          handleSubmit();
          handleControlModal();
        },
      });
    else if (e.target.id === 'initialize')
      return handleMsgModal({
        isModalOpen: true,
        msg: '수정 사항을 초기화 하시겠습니까?',
        func: () => {
          onInitTermsPolicyState();
          handleControlModal();
        },
      });
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />
        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title ">정책 관리</h3>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <h4>개인정보보호정책</h4>
                    <br />
                    <h5>
                      최종 업데이트{' '}
                      {lastModified.includes('Invalid') ? '' : lastModified}
                    </h5>
                    <br />
                    <MarkDownEditor
                      _content={currentContents}
                      onHandleOnChange={onHandleOnChange}
                    />
                    <div className="terms-button-group" onClick={handleButtons}>
                      <Button id="submit">적용</Button>
                      <Button id="initialize" className="lt-margin-30">
                        초기화
                      </Button>
                    </div>
                    <MsgModal
                      content={msgModal.msg}
                      isMsgModalOpen={msgModal.isModalOpen}
                      onHandleCloseMsgModal={handleControlModal}
                      onConfirmAction={msgModal.func}
                      confirmOrCancel
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  _content: selectors.contentSelector,
  currentContents: selectors.currentContentsSelector,
  lastModified: selectors.lastModifiedSelector,
});

const mapDispatchToProps = (dispatch) => ({
  // onGetPopupList : (title,arr) => dispatch(actions.getPopupList (title,arr)),
  onGetTermsPolicy: (title, arr) =>
    dispatch(actions.getPrivacyPolicy(title, arr)),
  onUpdateTermsPolicy: (data) => dispatch(actions.updatePrivacyPolicy(data)),
  onInitTermsPolicyState: () => dispatch(actions.initPrivacyPolicyState()),
  onHandleOnChange: (value) => dispatch(actions.handleOnChange(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Policy);
