// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import * as types from './types';

export function indicatorsGetter(params) {
	return {
		type: types.INDICATORS_GETTER,
		params,
	};
}
export function indicatorsGetterSuccess(params) {
	return {
		type: types.INDICATORS_GETTER_SUCCESS,
		params,
	};
}
export function indicatorsSetter(params) {
	return {
		type: types.INDICATORS_SETTER,
		params,
	};
}
export function indicatorsSetterSuccess(params) {
	return {
		type: types.INDICATORS_SETTER_SUCCESS,
		params,
	};
}
export function indicatorsPutter(params) {
	return {
		type: types.INDICATORS_PUTTER,
		params,
	};
}
export function indicatorsPutterSuccess(params) {
	return {
		type: types.INDICATORS_PUTTER_SUCCESS,
		params,
	};
}
