import * as types from "./constant";

// export function initGameStat(gameId, date, year) {
// 	return {
// 		type: types.INIT_GAME_STAT,
// 		gameId,
// 		date,
// 		year,
// 		MAU: false,
// 	};
// }

export function initOverdueBill() {
  return {
    type: types.INIT_OVERDUE_BILL_LIST
  };
}
export function clickChargedBillRow(id, arr) {
  return {
    type: types.CLICK_CHARGED_BILL_ROW,
    id,
    arr
  };
}
export function getBackToMainTable() {
  return {
    type: types.GET_BACK_TO_MAIN_TABLE
  };
}
export function handleOpenLayerdModal() {
  return {
    type: types.HANDLE_OPEN_LAYERD_MODAL
  };
}
export function handleCloseLayerdModal() {
  return {
    type: types.HANDLE_CLOSE_LAYERD_MODAL
  };
}

export function getSearchKeywords(obj) {
  return {
    type: types.GET_SEARCH_KEYWORDS_OVERDUE_BILL,
    obj
  };
}

export function getAccountStatusSuccess(status) {
  return {
    type: types.GET_ACCOUNT_STATUS_SUCCESS,
    status
  };
}

export function handleManageBillModal(id, value) {
  return {
    type: types.HANDLE_MANAGE_BILL_MODAL,
    id,
    value
  };
}

export function handlePostBillData(obj) {
  return {
    type: types.HANDLE_POST_BILL_DATA,
    obj
  };
}

// overdue
export function getOverdueBill(obj) {
  return {
    type: types.GET_OVERDUE_BILL,
    obj
  };
}
export function getOverdueBillSuccess(rows, lastEvaluatedKey) {
  return {
    type: types.GET_OVERDUE_BILL_SUCCESS,
    rows,
    lastEvaluatedKey
  };
}

// export function initDeletedAccountList(gameId, date) {
//   return {
//     type: types.INIT_DELETED_ACCOUNT_LIST,
//     gameId,
//     date
//   };
// }

// export function confirmDeleteAccount(id) {
//   return {
//     type: types.CONFIRM_DELETE_ACCOUNT,
//     id
//   };
// }

// export function saveInput(value) {
//   return {
//     type: types.SAVE_INPUT,
//     value
//   };
// }
