import {
	requestPost,
	requestGet,
	requestDelete,
	requestPut,
} from '../../utils/request';
import Qs from 'qs';

import BaseUrl from '../../shared/baseUrl';

export const getTheBackendSales = (params) => {
	const url = `${BaseUrl.CONSOLE_API}statistics/thebackend`;

	const options = {
		params,
		paramsSerializer(serializingParams) {
			return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
		},
	};

	return requestGet(url, options);
};
