import React, { useState } from 'react';
import Header from '../../view/header';
import LeftAsideMunu from '../../view/leftAsideMenu';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import Octicon, { Question } from '@primer/octicons-react';

import * as selectors from './store/selector';
import * as actions from './store/action';

const LabelColumn = ({ children, paymentType, chatStatus }) => (
  <div className="col-lg-3 form-view">
    {paymentType === 2 || chatStatus === 'inactive' ? '-' : children}
  </div>
);

const ProjectDetail = ({ onGetProjectSuccess, projectData }) => {
  const getBackToList = () => {
    onGetProjectSuccess(null);
  };

  const handleCreationCriteria = (creationCriteria) => {
    const arr = JSON.parse(creationCriteria);
    if (arr.length) {
      return JSON.stringify(Object.values(arr[0]));
    } else return JSON.stringify(Object.values(arr));
  };

  const handleServerStatus = (serverStatus) => {
    if (serverStatus === 0) return '온라인';
    if (serverStatus === 1) return '오프라인';
    if (serverStatus === 2) return '점검';
  };

  const handleAutoConnectionTerminate = (autoConnectionTerminate) => {
    const time = Number(autoConnectionTerminate) / 60;
    return time;
  };

  const handleConditionalThing = (param, isLiapp) => {
    if (isLiapp) {
      if (param === 'y' || param === 'true' || param === true) return '사용';
      if (param === 'n' || param === 'false' || param === false)
        return '미사용';
    } else {
      if (param === 'y' || param === 'true' || param === true) return '활성';
      if (param === 'n' || param === 'false' || param === false)
        return '비활성';
    }
    return null;
  };

  const [isPopOverOpen, setPopOver] = useState(false);
  const toggle = () => {
    setPopOver(!isPopOverOpen);
  };

  const showPopOver = (backendFunctionToken) => {
    return (
      <Popover
        placement="left"
        isOpen={isPopOverOpen}
        target="down"
        toggle={toggle}
      >
        <PopoverHeader>인증키</PopoverHeader>
        <PopoverBody>
          <p>{backendFunctionToken}</p>
        </PopoverBody>
      </Popover>
    );
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />
        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__head">
                    <div className="m-portlet__head-tools m--pull-left">
                      <span className="m-portlet__head-icon m--hide">
                        <i className="la la-gear" />
                      </span>
                      <h3 className="m-portlet__head-text">
                        프로젝트 상세 정보
                      </h3>
                    </div>
                    <div className="m-portlet__head-tools m--pull-right">
                      <button
                        type="button"
                        onClick={getBackToList}
                        className="btn btn-danger btn-sm cm_withdraw"
                      >
                        <i className="la" /> 목록
                      </button>
                    </div>
                  </div>
                  {projectData ? (
                    <form className="m-form m-form--fit m-form--label-align-right m-form--group-seperator">
                      <div className="m-portlet__body">
                        <h4 className="h4 project-detail">서버 설정</h4>
                        <div className="form-group m-form__group row">
                          <label className="col-lg-1 col-form-label">
                            프로젝트 상태
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData &&
                              handleServerStatus(
                                projectData.serverSettings.serverStatus
                              )}
                          </div>
                          <label className="col-lg-1 col-form-label">
                            Android Package Name
                          </label>
                          <div className="col-lg-3 form-view">
                            {(projectData &&
                              projectData.serverSettings.googlePackageName) ||
                              '없음'}
                          </div>
                          <label className="col-lg-1 col-form-label">
                            Facebook App ID
                          </label>
                          <div className="col-lg-3 form-view">
                            {(projectData &&
                              projectData.serverSettings.facebookAppId) ||
                              '없음'}
                          </div>
                        </div>
                        <div className="form-group m-form__group row">
                          <label className="col-lg-1 col-form-label">
                            출시 설정
                          </label>
                          <div className="col-lg-3 form-view">
                            구글 플레이 -{' '}
                            {projectData &&
                            projectData.serverSettings.googleMarketLaunch ===
                              'y'
                              ? '라이브'
                              : '테스트'}
                            <br />앱 스토어 -{' '}
                            {projectData &&
                            projectData.serverSettings.appleMarketLaunch === 'y'
                              ? '라이브'
                              : '테스트'}
                          </div>
                          <label className="col-lg-1 col-form-label">
                            iOS Bundle Identifier
                          </label>
                          <div className="col-lg-3 form-view">
                            {(projectData &&
                              projectData.serverSettings.appleAppId) ||
                              '없음'}
                          </div>
                          <label className="col-lg-1 col-form-label">
                            Facebook App Secret
                          </label>
                          <div className="col-lg-3 form-view">
                            {(projectData &&
                              projectData.serverSettings.facebookAppSecret) ||
                              '없음'}
                          </div>
                        </div>
                        <div className="form-group m-form__group row">
                          <label className="col-lg-1 col-form-label">
                            구글 해시키
                          </label>
                          <div className="col-lg-3 form-view">
                            {(projectData &&
                              projectData.serverSettings.googleHashDebugKey) ||
                              '없음'}
                            <br />
                            {(projectData &&
                              projectData.serverSettings
                                .googleHashReleaseKey) ||
                              '없음'}
                            <br />
                            {(projectData &&
                              projectData.serverSettings.googleHashDebugKey2) ||
                              '없음'}
                            <br />
                            {(projectData &&
                              projectData.serverSettings
                                .googleHashReleaseKey2) ||
                              '없음'}
                          </div>
                          <label className="col-lg-1 col-form-label">
                            푸시키 등록
                          </label>
                          <div className="col-lg-3 form-view">
                            Android -{' '}
                            {projectData &&
                            projectData.serverSettings.androidPush
                              ? '등록'
                              : '미등록'}
                            <br />
                            iOS[Production] -{' '}
                            {projectData &&
                            projectData.serverSettings.iosProductionPush
                              ? '등록'
                              : '미등록'}
                            <br />
                            iOS[Development] -{' '}
                            {projectData &&
                            projectData.serverSettings.iosDevelopmentPush
                              ? '등록'
                              : '미등록'}
                          </div>
                          <label className="col-lg-1 col-form-label"> </label>
                          <div className="col-lg-3 form-view"></div>
                        </div>
                        <h4 className="h4 project-detail">소셜 설정</h4>
                        <div className="form-group m-form__group row">
                          <label className="col-lg-1 col-form-label">
                            친구 최대 보유수
                          </label>
                          <div className="col-lg-3 form-view">
                            {(projectData &&
                              projectData.socialSettings.maxCountFriend) ||
                              '미설정'}
                          </div>
                          <label className="col-lg-1 col-form-label">
                            쪽지 최대 보유수
                          </label>
                          <div className="col-lg-3 form-view">
                            {(projectData &&
                              projectData.socialSettings.maxCountMessage) ||
                              '미설정'}
                          </div>
                          <label className="col-lg-1 col-form-label">
                            쪽지 글자수 제한
                          </label>
                          <div className="col-lg-3 form-view">
                            {(projectData &&
                              projectData.socialSettings.maxLengthLetter) ||
                              '미설정'}
                          </div>
                        </div>
                        <div className="form-group m-form__group row">
                          <label className="col-lg-1 col-form-label">
                            길드 생성 조건
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData &&
                            projectData.socialSettings.creationCriteria
                              ? handleCreationCriteria(
                                  projectData.socialSettings.creationCriteria
                                )
                              : '미설정'}
                          </div>
                          <label className="col-lg-1 col-form-label">
                            길드 가입 조건
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData &&
                            projectData.socialSettings.registrationCriteria
                              ? handleCreationCriteria(
                                  projectData.socialSettings
                                    .registrationCriteria
                                )
                              : '미설정'}
                          </div>
                          <label className="col-lg-1 col-form-label"> </label>
                          <div className="col-lg-3 form-view"></div>
                        </div>
                        <h4 className="h4 project-detail">활성화 정보</h4>
                        <div className="form-group m-form__group row">
                          <label className="col-lg-1 col-form-label">
                            뒤끝챗
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData &&
                            projectData.activations.chatServer === 'y'
                              ? '활성'
                              : '비활성'}
                          </div>
                          <label className="col-lg-1 col-form-label">
                            자동접속 종료
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData &&
                            (projectData.activations.autoConnectionTerminate ===
                              '0' ||
                              !projectData.activations.autoConnectionTerminate)
                              ? '비활성'
                              : `${handleAutoConnectionTerminate(
                                  projectData.activations
                                    .autoConnectionTerminate
                                )}분`}
                          </div>
                          <label className="col-lg-1 col-form-label">
                            도배 방지
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData && projectData.activations.antiPlaster
                              ? `${projectData.activations.antiPlaster.duration} 초 이내
                    ${projectData.activations.antiPlaster.repetition} 회 이상 ${projectData.activations.antiPlaster.ban} 초 금지`
                              : '비활성'}
                          </div>
                        </div>
                        <div className="form-group m-form__group row">
                          <label className="col-lg-1 col-form-label">
                            뒤끝매치
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData &&
                              handleConditionalThing(
                                projectData.activations.realtime
                              )}
                          </div>
                          <label className="col-lg-1 col-form-label">
                            실시간 랭킹
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData &&
                              handleConditionalThing(
                                projectData.activations.realtimeRank
                              )}
                          </div>
                          <label className="col-lg-1 col-form-label">
                            실시간 알림
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData &&
                              handleConditionalThing(
                                projectData.activations.realtimeNoti
                              )}
                          </div>
                        </div>
                        <div className="form-group m-form__group row">
                          <label className="col-lg-1 col-form-label tline">
                            뒤끝펑션
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData &&
                              handleConditionalThing(
                                projectData.activations
                                  .backendFunctionTokenActive
                              )}
                          </div>
                          <label className="col-lg-1 col-form-label tline">
                            함수 호출 횟수
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData &&
                              projectData.activations
                                .backendFunctionInvocations}{' '}
                            /{' '}
                            {projectData &&
                              projectData.activations.invocationLimit}
                          </div>
                          <label className="col-lg-1 col-form-label tline">
                            인증키
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData &&
                            projectData.activations.backendFunctionToken ? (
                              <span
                                className="clickable icon"
                                id="down"
                                onClick={() => {
                                  setPopOver(!isPopOverOpen);
                                }}
                              >
                                <Octicon
                                  id="down"
                                  verticalAlign="text-top"
                                  icon={Question}
                                ></Octicon>
                              </span>
                            ) : (
                              '비활성'
                            )}
                            {isPopOverOpen &&
                              showPopOver(
                                projectData.activations.backendFunctionToken
                              )}
                          </div>
                        </div>
                        <div className="form-group m-form__group row">
                          <label className="col-lg-1 col-form-label tline">
                            리앱
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData &&
                              handleConditionalThing(
                                projectData.activations.liappUse,
                                'liapp'
                              )}
                          </div>
                          <label className="col-lg-1 col-form-label tline">
                            {' '}
                          </label>
                          <div className="col-lg-3 form-view"></div>
                          <label className="col-lg-1 col-form-label tline">
                            {' '}
                          </label>
                          <div className="col-lg-3 form-view"></div>
                        </div>

                        {/* Chat */}
                        <h4 className="h4 project-detail">채팅 정보</h4>
                        <div className="form-group m-form__group row">
                          <label className="col-lg-1 col-form-label">
                            상태
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData.activations.chatStatus === 'active'
                              ? '활성화'
                              : '비활성화'}
                          </div>

                          <label className="col-lg-1 col-form-label">
                            요금제
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData.activations.chatStatus === 'active'
                              ? projectData.activations.paymentType === 1
                                ? 'Free'
                                : projectData.activations.paymentType === 2
                                ? 'Basic'
                                : 'Business'
                              : '-'}
                            {projectData.activations.ccuScailing ? '+' : null}
                          </div>
                        </div>
                        <div className="form-group m-form__group row">
                          <label className="col-lg-1 col-form-label">
                            서버 버전
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData.activations.serverVersion ?? '-'}
                          </div>
                          <label className="col-lg-1 col-form-label">
                            메시지 최대 길이
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData.activations.limit_message_size
                              ? `${projectData.activations.limit_message_size} byte`
                              : '-'}
                          </div>
                          <label className="col-lg-1 col-form-label">
                            도배 제한
                          </label>
                          <div className="col-lg-3 form-view">
                            {projectData.activations.spam_interval &&
                            projectData.activations.spam_limit_count === 0 &&
                            projectData.activations.spam_penalty === 0
                              ? `도배 예방하기 (메시지 전송 후 ${projectData.activations.spam_interval}초 동안 전속 막기)`
                              : null}

                            {projectData.activations.spam_interval &&
                            projectData.activations.spam_limit_count > 0 &&
                            projectData.activations.spam_penalty > 0
                              ? `도배 시 패널티 적용하기 (
                            ${projectData.activations.spam_interval}초 동안{' '}
                            ${projectData.activations.spam_limit_count}번 보다
                            많이 입력하면 ${projectData.activations.spam_penalty}
                            초 동안 채팅 금지하기)`
                              : null}
                          </div>
                        </div>
                        <div className="form-group m-form__group row">
                          <label className="col-lg-1 col-form-label">
                            메시지 저장 기간
                          </label>
                          <LabelColumn
                            chatStatus={projectData.activations.chatStatus}
                            paymentType={projectData.activations.paymentType}
                          >
                            {
                              projectData.activations.proOptions
                                .save_log_period_days
                            }
                            일
                          </LabelColumn>

                          <label className="col-lg-1 col-form-label">
                            채널 입장시 최근 대화 내역 보여주기
                          </label>
                          <LabelColumn
                            chatStatus={projectData.activations.chatStatus}
                            paymentType={projectData.activations.paymentType}
                          >
                            {projectData.activations.proOptions
                              .recent_history_days > 0 &&
                            projectData.activations.proOptions
                              .recent_history_count > 0
                              ? `활성 (최대 ${projectData.activations.proOptions.recent_history_days}일 및 최대 ${projectData.activations.proOptions.recent_history_count}개)`
                              : '비활성'}
                          </LabelColumn>

                          <label className="col-lg-1 col-form-label">
                            부적절 표현 필터 방식
                          </label>
                          <LabelColumn
                            chatStatus={projectData.activations.chatStatus}
                            paymentType={projectData.activations.paymentType}
                          >
                            {projectData.activations.proOptions
                              .filter_method === 'n'
                              ? '메시지 보내지 않기'
                              : `${projectData.activations.proOptions.filter_method}로 대체하기`}
                          </LabelColumn>
                        </div>
                        <div className="form-group m-form__group row">
                          <label className="col-lg-1 col-form-label tline">
                            신고 사유
                          </label>
                          <LabelColumn
                            chatStatus={projectData.activations.chatStatus}
                            paymentType={projectData.activations.paymentType}
                          >
                            언어{' '}
                            {
                              projectData.activations.proOptions.report_reasons
                                .languageLength
                            }
                            개 및 언어별 신고사유 최대{' '}
                            {
                              projectData.activations.proOptions.report_reasons
                                .keyLength
                            }
                            개
                          </LabelColumn>

                          <label className="col-lg-1 col-form-label tline">
                            신고 횟수 제한
                          </label>
                          <LabelColumn
                            chatStatus={projectData.activations.chatStatus}
                            paymentType={projectData.activations.paymentType}
                          >
                            하루에 최대{' '}
                            {
                              projectData.activations.proOptions
                                .today_report_max_count
                            }
                            번
                          </LabelColumn>

                          <label className="col-lg-1 col-form-label tline">
                            신고 기간 제한
                          </label>
                          <LabelColumn
                            chatStatus={projectData.activations.chatStatus}
                            paymentType={projectData.activations.paymentType}
                          >
                            전송된 지{' '}
                            {
                              projectData.activations.proOptions
                                .limit_report_message_days
                            }
                            일 이내 메시지만 신고 허용하기
                          </LabelColumn>
                        </div>
                      </div>
                    </form>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  projectData: selectors.projectDataSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onGetProjectSuccess: (param) => dispatch(actions.getProjectSuccess(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);
