export const INIT_SHOWCASE_LIST = "INIT_SHOWCASE_LIST";
export const GET_SHOWCASE_LIST = "GET_SHOWCASE_LIST";
export const GET_MORE_SHOWCASE_LIST = "GET_MORE_SHOWCASE_LIST";
export const GET_MORE_SHOWCASE_LIST_SUCCESS = "GET_MORE_SHOWCASE_LIST_SUCCESS";
export const GET_SHOWCASE_LIST_SUCCESS = "GET_SHOWCASE_LIST_SUCCESS";

export const GET_SHOWCASE = "GET_SHOWCASE";
export const DELETE_SHOWCASE = "DELETE_SHOWCASE";
export const INSERT_SHOWCASE = "INSERT_SHOWCASE";
export const INSERT_SHOWCASE_ERROR = "INSERT_SHOWCASE_ERROR";
export const INIT_INSERT_SHOWCASE_ERROR = "INIT_INSERT_SHOWCASE_ERROR";
export const INSERT_SHOWCASE_SUCCESS = "INSERT_SHOWCASE_SUCCESS";
export const UPDATE_SHOWCASE = "UPDATE_SHOWCASE";
export const UPDATE_SHOWCASE_SUCCESS = "UPDATE_SHOWCASE_SUCCESS";
export const UPDATE_SHOWCASE_ORDER = "UPDATE_SHOWCASE_ORDER";
