import { LOCATION_CHANGE } from 'react-router-redux';

// import * as selectors from './selector';
import * as types from './constant';

const initialState = {
  popupList: [],
  selectedPopup: null,
  errors: null,
};

function consolePopup(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...initialState,
      };

    case types.GET_CONSOLE_POPUP_LIST_SUCCESS:
      return {
        ...state,
        popupList: action.popupList,
        totalCount: action.totalCount,
        insertDone: false,
      };

    case types.INSERT_CONSOLE_POPUP:
      return {
        ...state,
        insertDone: false,
      };
    case types.INSERT_CONSOLE_POPUP_SUCCESS:
      return {
        ...state,
        insertDone: true,
      };

    case types.UPDATE_CONSOLE_POPUP:
      return {
        ...state,
        insertDone: false,
      };
    case types.UPDATE_CONSOLE_POPUP_SUCCESS:
      return {
        ...state,
        insertDone: true,
        selectedPopup: null,
        errors: null,
      };

    case types.HANDLE_CONSOLE_POPUP_ERRORS:
      return {
        ...state,
        errors: action.err,
      };

    case types.GET_CONSOLE_POPUP:
      const {
        _indate: { indate },
      } = action.arr[4];

      return {
        ...state,
        selectedPopup: state.popupList.filter(
          (popup) => popup.indate === indate
        )[0],
      };

    default:
      return state;
  }
}

export default consolePopup;
