import * as types from './constant';

export function getSLAPolicy(params) {
	return {
		type: types.GET_SLA_POLICY,
		params,
	};
}
export function getSLAPolicySuccess(payload) {
	return {
		type: types.GET_SLA_POLICY_SUCCESS,
		payload,
	};
}
export function updateSLAPolicy(params) {
	return {
		type: types.UPDATE_SLA_POLICY,
		params,
	};
}
export function updateSLAPolicySuccess(payload) {
	return {
		type: types.UPDATE_SLA_POLICY_SUCCESS,
		payload,
	};
}

export function catchHTML(payload) {
	return {
		type: types.CATCH_HTML,
		payload,
	};
}
