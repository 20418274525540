// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { takeLatest, select, put, call } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import * as types from './types';
import * as actions from './actions';
import * as selectors from './selector';
import * as api from '../../../service/API';
import { openSubMenu, handleClickSubMenu } from '../../../view/store/action';
import { globalErrorHandling } from './../../globalStore/action';

function* locationChange({ payload }) {
	try {
		const { pathname } = yield payload;
		const pathArray = pathname.split('/');
		if (pathArray[1] === 'indicators') {
			yield put(openSubMenu('indicators'));
			yield put(handleClickSubMenu('indicators'));
			yield put(actions.indicatorsGetter('all'));
		}
	} catch (error) {
		yield put(globalErrorHandling(error));
	}
}

export function* watcherLocationChange() {
	yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* indicatorsGetter({ params }) {
	try {
		const { data } = yield call(api.indicators.indicatorsGetter, params);
		yield put(actions.indicatorsGetterSuccess(data));
	} catch (error) {
		yield put(globalErrorHandling(error));
	}
}

export function* watcherIndicatorsGetter() {
	yield takeLatest(types.INDICATORS_GETTER, indicatorsGetter);
}

function* indicatorsSetter({ params }) {
	try {
		yield call(api.indicators.indicatorsSetter, params);
		yield put(actions.indicatorsSetterSuccess());
	} catch (error) {
		yield put(globalErrorHandling(error));
	}
}

export function* watcherIndicatorsSetter() {
	yield takeLatest(types.INDICATORS_SETTER, indicatorsSetter);
}

function* indicatorsPutter({ params }) {
	try {
		yield call(api.indicators.indicatorsPutter, params);
		yield put(actions.indicatorsPutterSuccess());
	} catch (error) {
		yield put(globalErrorHandling(error));
	}
}

export function* watcherIndicatorsPutter() {
	yield takeLatest(types.INDICATORS_PUTTER, indicatorsPutter);
}
