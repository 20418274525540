import { createSelector } from 'reselect';

export const sendToCountSelector = createSelector(
	(state) => state.sendEmail.sendToCount,
	(sendToCount) => sendToCount
);

export const sendEmailSuccessSelector = createSelector(
	(state) => state.sendEmail.sendEmailSuccess,
	(sendEmailSuccess) => sendEmailSuccess
);

export const sendTestEmailSuccessSelector = createSelector(
	(state) => state.sendEmail.sendTestEmailSuccess,
	(sendTestEmailSuccess) => sendTestEmailSuccess
);

export const filesSelector = createSelector(
	(state) => state.sendEmail.files,
	(files) => files
);

export const errorsSelector = createSelector(
	(state) => state.sendEmail.errors,
	(errors) => errors
);
