import { call, put, takeLatest, select } from 'redux-saga/effects';

import * as types from './constant';
import * as actions from './action';
import { LOCATION_CHANGE } from 'react-router-redux';

import aws from 'aws-sdk';
import { openSubMenu, handleClickSubMenu } from '../../../../view/store/action';

import * as api from '../../../../service/API';

function* locationChange({ payload }) {
	try {
		const { pathname } = yield payload;
		const pathArray = pathname.split('/');
		if (pathArray[1] === 'landing' && pathArray[2] === 'sla') {
			yield put(openSubMenu('policy'));
			yield put(handleClickSubMenu('sla'));
			yield put(actions.getSLAPolicy());
		}
	} catch (err) {
		console.log('LOCATION_CHANGE error', err);
	}
}

export function* watcherLocationChange() {
	yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* getSLAPolicy() {
	try {
		const { data } = yield call(api.sla.getSLAPolicy);

		yield put(
			actions.getSLAPolicySuccess(
				data.map((row) => aws.DynamoDB.Converter.unmarshall(row))
			)
		);
	} catch (error) {
		console.log('GET_SLA_POLICY', error);
	}
}

export function* watcherGetSLAPolicy() {
	yield takeLatest(types.GET_SLA_POLICY, getSLAPolicy);
}

function* updateSLAPolicy() {
	try {
		const { contents } = yield select((state) => state.sla);

		yield call(api.sla.updateSLAPolicy, { contents });
		yield put(actions.updateSLAPolicySuccess());
		yield put(actions.getSLAPolicy());
	} catch (error) {
		console.log('UPDATE_SLA_POLICY error', error);
	}
}

export function* watcherUpdateSLAPolicy() {
	yield takeLatest(types.UPDATE_SLA_POLICY, updateSLAPolicy);
}
