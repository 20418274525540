import { LOCATION_CHANGE } from 'react-router-redux';

// import * as selectors from './selector';
import * as types from './constant';

const initialState = {
	customersList: [], // monthlyStat
	_customersList: [], // dailyStat
	detail: { clickedId: '', editable: false },
	isLoading: false,
	sortingParam: false,
	isReleasedPjtsModalOpen: false,
	releasedPjts: null,
};

function notice(state = initialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return {
				...initialState,
			};
		case types.GET_COLUMNS:
			return {
				...state,
				columns: action.columns,
			};

		case types.GET_MONTHLY_STAT_SUCCESS:
			return {
				...state,
				isLoading: false,
				customersList: [...state.customersList, ...action.data], // monthlyStat or dailyStat// 분기필요.. 스크롤 발생 필요시
				monthlyStatsLastItem: action.data[action.data.length - 1],
				isLast: action.isLast,
			};
		case types.GET_DAILY_STAT:
			return {
				...state,
				isLoading: true,
			};
		case types.GET_MONTHLY_STAT:
			return {
				...state,
				isLoading: true,
			};
		case types.GET_DAILY_STAT_SUCCESS:
			return {
				...state,
				isLoading: false,
				_customersList: [...state._customersList, ...action.data], // monthlyStat or dailyStat
				dailyStatsLastItem: action.data[action.data.length - 1],
				isLast: action.isLast,
			};

		case types.GET_SEARCHED_DAILY_STAT_SUCCESS:
			return {
				...state,
				_customersList: action.rows,
				isLast: action.isLast,
				isLoading: false,
				dailyStatsLastItem: action.rows[action.rows.length - 1],
			};

		case types.CHANGE_STAT_TYPE_GENERAL_STAT:
			return {
				...state,
				statType: action.data,
				customersList: [],
				_customersList: [],
			};
		// rows, isSearched, tableType
		case types.SORT_ASC_ROWS:
			return {
				...state,
				customersList: !action.tableType ? action.rows : [],
				_customersList: action.tableType ? action.rows : [],
				sortingParam: true,
			};

		case types.SORT_DESC_ROWS:
			return {
				...state,
				customersList: !action.tableType ? action.rows : [],
				_customersList: action.tableType ? action.rows : [],
				sortingParam: false,
			};

		case types.INIT_DATA_TABLES_ALL_STAT:
			return {
				...state,
				customersList: [],
				_customersList: [],
			};

		case types.SEARCH_STATISTICS:
			return {
				...state,
				isLoading: true,
			};

		case types.HANDLE_OPEN_RELEASED_PJT_MODAL:
			return {
				...state,
				// releasedPjts: action.pjts.releasedProject.releasedProject,
				releasedPjts: action.pjts,
				isReleasedPjtsModalOpen: true,
				date: action.date,
			};
		case types.HANDLE_CLOSE_RELEASED_PJT_MODAL:
			return {
				...state,
				releasedPjts: null,
				isReleasedPjtsModalOpen: false,
			};

		default:
			return state;
	}
}

export default notice;
