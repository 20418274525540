import { LOCATION_CHANGE } from 'react-router-redux';

// import * as selectors from './selector';
import * as types from './constant';

const initialState = {
	sendToCount: null,
	files: [],
	sendTestEmailSuccess: false,
	sendEmailSuccess: false,
	errors: null,
};

function sendEmail(state = initialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return {
				...initialState,
			};

		case types.GET_SEND_TO_COUNT_SUCCESS:
			return {
				...state,
				sendToCount: action.payload,
			};
		case types.SEND_EMAIL_SUCCESS:
			return {
				...state,
				sendEmailSuccess: true,
				files: initialState.files,
			};
		case types.READ_FILES_SUCCESS:
			return {
				...state,
				files: action.payload,
			};
		case types.RESET_FILES:
			return {
				...state,
				files: initialState.files,
			};
		case types.DELETE_READ_FILES:
			return {
				...state,
				files: state.files.filter((file) => file.key !== action.payload),
			};
		case types.SEND_TEST_EMAIL_SUCCESS:
			return {
				...state,
				sendTestEmailSuccess: true,
			};
		case types.INIT_SEND_EMAIL_SUCCESS:
			return {
				...state,
				sendEmailSuccess: initialState.sendEmailSuccess,
			};
		case types.INIT_SEND_TEST_EMAIL_SUCCESS:
			return {
				...state,
				sendTestEmailSuccess: initialState.sendTestEmailSuccess,
			};
		case types.HANDLE_FILE_ERRORS:
			return {
				...state,
				errors: action.payload,
			};

		default:
			return state;
	}
}

export default sendEmail;
