import Qs from 'qs';
import { requestGet } from '../../utils/request';

export const getDailyStatisticsByProject = (params) => {
	const url = 'statistics/revenue/v1.2';

	const options = {
		params,
		paramsSerializer(serializingParams) {
			return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
		},
	};

	return requestGet(url, options);
};
