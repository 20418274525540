export const CLICK_NOTICE_EDIT = "CLICK_NOTICE_EDIT";
export const CLICK_NOTICE_DELETE = "CLICK_NOTICE_DELETE";
export const POST_CREATE_NOTICE = "POST_CREATE_NOTICE";
export const SET_TRY_POST_TRUE = "SET_TRY_POST_TRUE";

export const HANDLE_CLOSE_COUPON_DETAIL_MODAL =
  "HANDLE_CLOSE_COUPON_DETAIL_MODAL";

export const HANDLE_CLOSE_MSG_MODAL = "HANDLE_CLOSE_MSG_MODAL";
export const CONFIRM_CHANGE_STATUS = "CONFIRM_CHANGE_STATUS";
export const GET_ACCOUNTS_ROWS = "GET_ACCOUNTS_ROWS";
export const GET_ACCOUNTS_ROWS_SUCCESS = "GET_ACCOUNTS_ROWS_SUCCESS";
export const INIT_ACCOUNT_LIST = "INIT_ACCOUNT_LIST";
export const INIT_DELETED_ACCOUNT_LIST = "INIT_DELETED_ACCOUNT_LIST";
export const GET_COLUMNS = "GET_COLUMNS";
export const CLICK_MANAGE_BILL = "CLICK_MANAGE_BILL";
export const HANDLE_OPEN_MODAL = "HANDLE_OPEN_MODAL";
export const CONFIRM_DELETE_ACCOUNT = "CONFIRM_DELETE_ACCOUNT";
export const SAVE_INPUT = "SAVE_INPUT";
export const GET_MONTHLY_STAT = "GET_MONTHLY_STAT";
export const GET_MONTHLY_STAT_SUCCESS = "GET_MONTHLY_STAT_SUCCESS";
export const GET_DAILY_STAT = "GET_DAILY_STAT";
export const GET_DAILY_STAT_SUCCESS = "GET_DAILY_STAT_SUCCESS";
export const CHANGE_STAT_TYPE_GENERAL_STAT = "CHANGE_STAT_TYPE_GENERAL_STAT";
export const SEARCH_STATISTICS = "SEARCH_STATISTICS";
export const SORT_ASC_ROWS = "SORT_ASC_ROWS";
export const SORT_DESC_ROWS = "SORT_DESC_ROWS";
export const INIT_DATA_TABLES_ALL_STAT = "INIT_DATA_TABLES_ALL_STAT";
export const GET_SEARCHED_DAILY_STAT_SUCCESS =
  "GET_SEARCHED_DAILY_STAT_SUCCESS";
export const HANDLE_OPEN_RELEASED_PJT_MODAL = "HANDLE_OPEN_RELEASED_PJT_MODAL";
export const HANDLE_CLOSE_RELEASED_PJT_MODAL =
  "HANDLE_CLOSE_RELEASED_PJT_MODAL";

export const SHOW_SPEC_PROJECT_INFO = "SHOW_SPEC_PROJECT_INFO";
