import { put, takeLatest, select, call } from "redux-saga/effects";
import { LOCATION_CHANGE, push } from "react-router-redux";

import aws from "aws-sdk";

import * as types from "./constant";

import * as actions from "./action";
import * as selectors from "./selector";
import * as api from "../../../service/API";

function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split("/");

    if (pathArray[1] === "email") yield put(actions.getEmailList());
  } catch (err) {
    console.log("saga err", err);
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* getEmailList() {
  try {
    const {
      data: { Items }
    } = yield call(api.emails.getEmailList);
    yield put(
      actions.getEmailListSuccess(
        Items.map(item => aws.DynamoDB.Converter.unmarshall(item))
      )
    );
  } catch (error) {}
}

export function* watcherGetEmailList() {
  yield takeLatest(types.GET_EMAIL_LIST, getEmailList);
}
function* getEmail({ arr }) {
  try {
    let indexOfEmailInDate;
    yield arr.forEach((obj, i) => {
      if (Object.keys(obj)[0] === 'emailInDate') indexOfEmailInDate = i;
    });
    const {
      emailInDate: { emailInDate },
    } = arr[indexOfEmailInDate];

    const {
      data: { type, title, contents },
    } = yield call(api.emails.getEmail, emailInDate);
    yield put(actions.getEmailSuccess({ type, title, contents, emailInDate }));
  } catch (error) {}
}

export function* watcherGetEmail() {
  yield takeLatest(types.GET_EMAIL, getEmail);
}

function* updateEmailUsing({ arr }) {
  try {
    const { active } = arr[3].modifyRow.props;

    let indexOfEmailInDate;
    yield arr.forEach((obj, i) => {
      if (Object.keys(obj)[0] === 'emailInDate') indexOfEmailInDate = i;
    });
    const {
      emailInDate: { emailInDate },
    } = arr[indexOfEmailInDate];

    yield call(api.emails.updateEmailUsing, emailInDate, { using: !active });
    yield put(actions.getEmailList());
  } catch (error) {
    console.log('error : ', error);
  }
}

export function* watcherUpdateEmailUsing() {
  yield takeLatest(types.UPDATE_EMAIL_USING, updateEmailUsing);
}

function* updateEmail() {
  try {
    const contents = yield select(selectors.contentsSelector);
    const etc = yield select(selectors.emailEtcSelector);

    const { title, emailInDate } = etc;
    yield call(api.emails.updateEmail, emailInDate, { contents, title });

    yield put(actions.updateEmailSuccess());
  } catch (error) {}
}

export function* watcherUpdateEmail() {
  yield takeLatest(types.UPDATE_EMAIL, updateEmail);
}

// function* getOverdueManageRow({ id, arr }) {
// 	const accountList = yield select(selectors.accountListSelector);
// 	const filtered = accountList.filter(account => account.ID === arr[0].ID)[0];
// 	yield put(actions.getOverdueManageRowSuccess(filtered));
// }

// export function* watcherGetOverdueManageRow() {
// 	yield takeLatest(types.CLICK_OVERDUE_BILL_ROW, getOverdueManageRow);
// }
