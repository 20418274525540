import * as types from './constant';

export function getSentMails(payload, isAdmin, toRefresh) {
	return {
		type: types.GET_SENT_MAILS,
		lastEvaluatedKey: payload,
		isAdmin,
		toRefresh,
	};
}

export function getSentMailsSuccess(payload, page) {
	return {
		type: types.GET_SENT_MAILS_SUCCESS,
		payload,
		page, // 갱신할 페이지 인덱스
	};
}

export function getSentMailDetails(inDate, query, paging) {
	return {
		type: types.GET_SENT_MAIL_DETAILS,
		inDate,
		query,
		paging,
	};
}

export function searchSentMailDetails(inDate, query, paging) {
	return {
		type: types.SEARCH_SENT_MAIL_DETAILS,
		inDate,
		query,
		paging,
	};
}

export function searchSentMailDetailsSuccess(payload) {
	return {
		type: types.SEARCH_SENT_MAIL_DETAILS_SUCCESS,
		payload,
	};
}

export function getSentMailDetailsSuccess(payload, paging) {
	return {
		type: types.GET_SENT_MAIL_DETAILS_SUCCESS,
		payload,
		paging,
	};
}

export function deleteSentMail(inDate) {
	return {
		type: types.DELETE_SENT_MAIL,
		inDate,
	};
}

export function deleteSentMailSuccess(payload) {
	return {
		type: types.DELETE_SENT_MAIL_SUCCESS,
		payload,
	};
}

export function handleIsAdmin(payload) {
	return {
		type: types.HANDLE_IS_ADMIN,
		payload,
	};
}

export function handleIsAdminSuccess(payload) {
	return {
		type: types.HANDLE_IS_ADMIN_SUCCESS,
		payload,
	};
}
