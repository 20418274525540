import { LOCATION_CHANGE } from "react-router-redux";

// import * as selectors from './selector';
import * as types from "./constant";

const initialState = {
  showcaseList: [],
  selectedShowcase: null,
  error: null
};

function showcase(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...initialState,
      };

    case types.GET_SHOWCASE_LIST_SUCCESS:
      return {
        ...state,
        showcaseList: action.rows,
        lastEvaluatedKey: action.lastEvaluatedKey,
      };
    case types.GET_MORE_SHOWCASE_LIST_SUCCESS:
      return {
        ...state,
        showcaseList: [...state.showcaseList, ...action.rows],
        lastEvaluatedKey: action.lastEvaluatedKey,
      };

    case types.INSERT_SHOWCASE:
      return {
        ...state,
        insertDone: false,
      };
    case types.INSERT_SHOWCASE_SUCCESS:
      return {
        ...state,
        insertDone: true,
      };

    case types.UPDATE_SHOWCASE_SUCCESS:
      return {
        ...state,
        selectedShowcase: null,
      };
    
    case types.INSERT_SHOWCASE_ERROR:
      return {
        ...state,
        error: action.obj,
      };

    case types.INIT_INSERT_SHOWCASE_ERROR:
      return {
        ...state,
        error: null,
      };

    case types.GET_SHOWCASE:
      const { arr } = action;

      return {
        ...state,
        selectedShowcase: state.showcaseList.filter(
          (showcase) => showcase.inDate === arr
        )[0],
      };

    default:
      return state;
  }
}

export default showcase;
