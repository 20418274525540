// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useState, useEffect } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import Octicon, { Calendar } from '@primer/octicons-react';

const _SingleDatePicker = ({
  focusByIcon,
  disabled,
  setFocus,
  onGetSearchItem,
  onGetSearchKeywords,
  searchParam,
  initSearchFilter,
  handleInput,
  initInputObj,
  where, // 버전관리 컴포넌트
  value, // 버전관리 컴포넌트, 모달 활성화시 데이터 injection
  customDate,
}) => {
  const [date, setDate] = useState(moment(customDate));
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (value) {
      setDate(moment(value));
    }
  }, [value]);

  useEffect(() => {
    if (initSearchFilter) setDate(null);
  }, [initSearchFilter]);

  // 배포일이 오늘일 경우 === 날짜 선택을 하지 않을 경우, 오늘 날짜를 상위 컴포넌트에 넘겨 주기 위함
  useEffect(() => {
    if (onGetSearchKeywords && where && !value)
      onGetSearchKeywords({ [searchParam]: date.format('YYYY-MM-DD') });
    // if (initSearchFilter) setDate(null);
  }, [date, onGetSearchKeywords, searchParam, value, where]);

  // console.log(date);
  // console.log(focused, 'focused');

  return (
    <SingleDatePicker
      disabled={disabled}
      displayFormat="YYYY.MM.DD"
      monthFormat="YYYY[년] MM[월]"
      placeholder="검색일"
      customInputIcon={<Octicon icon={Calendar} />}
      isOutsideRange={() => false}
      enableOutsideDays
      date={date} // momentPropTypes.momentObj or null
      onDateChange={(date) => {
        // console.log(date, 'date test 1204')
        if (handleInput) handleInput(initInputObj);
        setDate(date);
        if (onGetSearchItem)
          onGetSearchItem({ inDate: date.format('YYYY.MM.DD') });
        if (onGetSearchKeywords)
          onGetSearchKeywords({ [searchParam]: date.format('YYYY-MM-DD') });
      }}
      focused={focusByIcon || focused} // PropTypes.bool
      onFocusChange={({ focused }) => {
        setFocused(focused);
        if (focusByIcon) setFocus(!focusByIcon);
      }}
      id="your_unique_id"
    />
  );
};

export default _SingleDatePicker;
