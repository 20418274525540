export const INIT_TOPICS_SDK = "INIT_TOPICS_SDK";
export const INIT_SDK_DOCS = "INIT_SDK_DOCS";
export const GET_TOPICS_SDK = "GET_TOPICS_SDK";
export const GET_TOPICS_SDK_SUCCESS = "GET_TOPICS_SDK_SUCCESS";
export const MAKE_CLASS_SDK = "MAKE_CLASS_SDK";
export const MAKE_CLASS_SDK_SUCCESS = "MAKE_CLASS_SDK_SUCCESS";
export const MAKE_TOPIC_SDK = "MAKE_TOPIC_SDK";
export const MAKE_TOPIC_SDK_SUCCESS = "MAKE_TOPIC_SDK_SUCCESS";
export const MAKE_DOCUMENT_SDK = "MAKE_DOCUMENT_SDK";
export const MAKE_DOCUMENT_SDK_SUCCESS = "MAKE_DOCUMENT_SDK_SUCCESS";
export const MAKE_VERSION = "MAKE_VERSION";
export const MAKE_VERSION_SUCCESS = "MAKE_VERSION_SUCCESS";
export const GET_LIST_SDK = "GET_LIST_SDK";
export const GET_CLASS_PARENT_LIST_SDK = "GET_CLASS_PARENT_LIST_SDK";
export const GET_DOCUMENT_SDK = "GET_DOCUMENT_SDK";
export const GET_VERSION_LIST = "GET_VERSION_LIST";
export const GET_VERSION_LIST_SUCCESS = "GET_VERSION_LIST_SUCCESS";
export const GET_LIST_SDK_SUCCESS = "GET_LIST_SDK_SUCCESS";
export const GET_CLASS_PARENT_LIST_SDK_SUCCESS =
  "GET_CLASS_PARENT_LIST_SDK_SUCCESS";
export const GET_DOCUMENT_SDK_SUCCESS = "GET_DOCUMENT_SDK_SUCCESS";

export const UPDATE_VERSION = "UPDATE_VERSION";
export const UPDATE_VERSION_SUCCESS = "UPDATE_VERSION_SUCCESS";
export const UPDATE_TOPIC_SDK = "UPDATE_TOPIC_SDK";
export const UPDATE_TOPIC_SDK_SUCCESS = "UPDATE_TOPIC_SDK_SUCCESS";
export const UPDATE_CLASS_SDK = "UPDATE_CLASS_SDK";
export const UPDATE_CLASS_SDK_SUCCESS = "UPDATE_CLASS_SDK_SUCCESS";
export const UPDATE_DOCUMENT_SDK = "UPDATE_DOCUMENT_SDK";
export const UPDATE_DOCUMENT_SDK_SUCCESS = "UPDATE_DOCUMENT_SDK_SUCCESS";
export const UPDATE_ORDER_SDK = "UPDATE_ORDER_SDK";
export const UPDATE_ORDER_SDK_SUCCESS = "UPDATE_ORDER_SDK_SUCCESS";
export const DELETE_VERSION = "DELETE_VERSION";
export const DELETE_VERSION_SUCCESS = "DELETE_VERSION_SUCCESS";
export const DELETE_TOPIC_SDK = "DELETE_TOPIC_SDK";
export const DELETE_TOPIC_SDK_SUCCESS = "DELETE_TOPIC_SDK_SUCCESS";
export const DELETE_CLASS_SDK = "DELETE_CLASS_SDK";
export const DELETE_CLASS_SDK_SUCCESS = "DELETE_CLASS_SDK_SUCCESS";
export const DELETE_DOCUMENT_SDK = "DELETE_DOCUMENT_SDK";
export const DELETE_DOCUMENT_SDK_SUCCESS = "DELETE_DOCUMENT_SDK_SUCCESS";
export const INIT_GET_LIST_KEY_SDK = "INIT_GET_LIST_KEY_SDK";
export const MAKE_DOCUMENT_ERROR = "MAKE_DOCUMENT_ERROR";

// ******************
