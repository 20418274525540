import aws from 'aws-sdk';

import DefaultAxios from './DefaultAxios';
import * as globalActions from '../containers/globalStore/action';
import store from '../configureStore';

const { unmarshall } = aws.DynamoDB.Converter;
const dynamodbUnmarshall = ({ data }) => {
	const { rows } = data;
	if (rows) {
		const filteredRows = rows.map(unmarshall);
		const filteredData = {
			...data,
			rows: filteredRows,
		};
		return filteredData;
	}
	return data;
};

// 랭킹 전용 unmarshll
const dynamodbUnmarshallRanking = ({ data }) => {
	const { rows } = data;

	if (rows) {
		const filteredRows = rows.map((row) => {
			if (
				row.extraData &&
				Object.keys(row.extraData)[0] === 'N' &&
				Object.values(row.extraData)[0] === ''
			) {
				row = {
					...row,
					extraData: '',
				};
			}
			return unmarshall(row);
		});

		const filteredData = {
			...data,
			rows: filteredRows,
		};

		return filteredData;
	}
	return data;
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
	if (response.status === 204 || response.status === 205) {
		return null;
	}
	return response;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
	if (response.status >= 200 && response.status < 300) {
		store.dispatch(globalActions.globalLoadingSuccess());
		return response;
	}

	const error = new Error(response.statusText);
	error.response = response;
	throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */

export function requestGet(url, options) {
	store.dispatch(globalActions.globalLoading());
	return DefaultAxios.get(url, options).then(checkStatus).then(parseJSON);
}

export function requestPost(url, data) {
	return DefaultAxios.post(url, data).then(checkStatus).then(parseJSON);
}

export function requestPut(url, data) {
	return DefaultAxios.put(url, data).then(checkStatus).then(parseJSON);
}

export function requestDelete(url, data) {
	return DefaultAxios.delete(url, data).then(checkStatus).then(parseJSON);
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function requestGetDynamoDbUnmarshall(url, options) {
	return DefaultAxios.get(url, options)
		.then(checkStatus)
		.then(dynamodbUnmarshall);
}

export function requestGetDynamoDbUnmarshallRanking(url, options) {
	return DefaultAxios.get(url, options)
		.then(checkStatus)
		.then(dynamodbUnmarshallRanking);
}
