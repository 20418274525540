import * as types from './constant';

export function getTheBackendSales(gameId) {
	return {
		type: types.GET_THEBACKEND_SALES,
		gameId,
	};
}

export function getTheBackendSalesSuccess(data) {
	return {
		type: types.GET_THEBACKEND_SALES_SUCCESS,
		data,
	};
}

export function getNextTheBackendSales(gameId) {
	return {
		type: types.GET_NEXT_THEBACKEND_SALES,
		gameId,
	};
}

export function getNextTheBackendSalesSuccess(data) {
	return {
		type: types.GET_NEXT_THEBACKEND_SALES_SUCCESS,
		data,
	};
}

export function sortAscRows(data) {
	return {
		type: types.SORT_ASC_ROWS_THEBACKEND_SALES,
		data,
	};
}

export function sortDescRows(data) {
	return {
		type: types.SORT_DESC_ROWS_THEBACKEND_SALES,
		data,
	};
}
