// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

export const HANDLE_CLOSE_CHARGE_MODAL = 'HANDLE_CLOSE_CHARGE_MODAL';
export const CONFIRM_CHANGE_STATUS = 'CONFIRM_CHANGE_STATUS';

export const GET_ACCOUNTS_ROWS = 'GET_ACCOUNTS_ROWS';
export const GET_ACCOUNTS_ROWS_SUCCESS = 'GET_ACCOUNTS_ROWS_SUCCESS';
export const INIT_ACCOUNT_LIST = 'INIT_ACCOUNT_LIST';
export const INIT_DELETED_ACCOUNT_LIST = 'INIT_DELETED_ACCOUNT_LIST';
export const GET_COLUMNS = 'GET_COLUMNS';
//
export const CLICK_CHARGED_BILL_ROW = 'CLICK_CHARGED_BILL_ROW';
export const GET_BACK_TO_MAIN_TABLE = 'GET_BACK_TO_MAIN_TABLE';
export const HANDLE_OPEN_LAYERD_MODAL = 'HANDLE_OPEN_LAYERD_MODAL';
export const HANDLE_CLOSE_LAYERD_MODAL = 'HANDLE_CLOSE_LAYERD_MODAL';
export const GET_SEARCH_KEYWORDS_BILL = 'GET_SEARCH_KEYWORDS_BILL';
export const GET_BILL = 'GET_BILL';
export const GET_BILL_ERROR = 'GET_BILL_ERROR';
export const GET_BILL_SUCCESS = 'GET_BILL_SUCCESS';
export const GET_GAME_BILL = 'GET_GAME_BILL';
export const GET_GAME_PPU_BILL = 'GET_GAME_PPU_BILL';
export const GET_GAME_PPU_BILL_SUCCESS = 'GET_GAME_PPU_BILL_SUCCESS';
export const GET_GAME_BILL_SUCCESS = 'GET_GAME_BILL_SUCCESS';
export const GET_ACCOUNT_BILL = 'GET_ACCOUNT_BILL';
export const GET_ACCOUNT_BILL_SUCCESS = 'GET_ACCOUNT_BILL_SUCCESS';

export const INIT_CHARGED_BILL_LIST = 'INIT_CHARGED_BILL_LIST';
export const GET_ACCOUNT_STATUS_SUCCESS = 'GET_ACCOUNT_STATUS_SUCCESS';
export const HANDLE_MANAGE_BILL_MODAL = 'HANDLE_MANAGE_BILL_MODAL';
export const UPDATE_ACCOUNT_BILL = 'UPDATE_ACCOUNT_BILL';
export const UPDATE_ACCOUNT_BILL_SUCCESS = 'UPDATE_ACCOUNT_BILL_SUCCESS';
export const SORT_ASC_CHARGED_BILL = 'SORT_ASC_CHARGED_BILL';
export const SORT_DESC_CHARGED_BILL = 'SORT_DESC_CHARGED_BILL';
export const GET_SOME_OF_BILL = 'GET_SOME_OF_BILL';
export const UPDATE_GAME_CHARGING_PRICE = 'UPDATE_GAME_CHARGING_PRICE';
export const UPDATE_GAME_CHARGING_PRICE_SUCCESS =
  'UPDATE_GAME_CHARGING_PRICE_SUCCESS';
//
export const HANDLE_OPEN_MODAL = 'HANDLE_OPEN_MODAL';
export const CONFIRM_DELETE_ACCOUNT = 'CONFIRM_DELETE_ACCOUNT';
export const SAVE_INPUT = 'SAVE_INPUT';
