import { call, put, takeLatest, select } from "redux-saga/effects";

import * as selectors from "./selector";
import * as types from "./constant";
import * as actions from "./action";
import { LOCATION_CHANGE } from "react-router-redux";
import { openSubMenu } from "../../../../view/store/action";
import aws from "aws-sdk";

import * as api from "../../../../service/API";
import moment from "moment";

function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split("/");
    if (pathArray[1] === "landing" && pathArray[2] === "popup") {
      yield put(openSubMenu("popup"));
      yield put(actions.initPopupList());
    }
  } catch (err) {
    console.log("LOCATION_CHANGE", err);
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* initPopupList() {
  try {
    yield put(actions.getPopupList());
  } catch (error) {
    console.log("INIT_POPUP_LIST", error);
  }
}

export function* watcherInitPopupList() {
  yield takeLatest(types.INIT_POPUP_LIST, initPopupList);
}

function* getPopupList() {
  try {
    const {
      data: { Items, LastEvaluatedKey },
    } = yield call(api.popup.getPopupList);

    yield put(
      actions.getPopupListSuccess(
        Items.map((item) => aws.DynamoDB.Converter.unmarshall(item)),
        LastEvaluatedKey
      )
    );
  } catch (error) {
    console.log("GET_POPUP_LIST", error);
  }
}

export function* watcherGetPopupList() {
  yield takeLatest(types.GET_POPUP_LIST, getPopupList);
}

function* insertPopup({ obj }) {
  try {
    yield call(api.popup.insertPopup, {
      ...obj,
      // startDate: obj.startDate.toISOString(),
      // endDate: obj.endDate.toISOString(),
    });
    yield put(actions.insertPopupSuccess());
    yield put(actions.initPopupList());
  } catch (error) {
    console.log("INSERT_POPUP", error);
    if (error && error.response.status === 412) {
      console.log("duration duplicated");
      yield put(
        actions.handlePopupErrors({
          duplicated: "선택한 기간과 중복되는 팝업이 존재합니다.",
        })
      );
    }
  }
}

export function* watcherInsertPopup() {
  yield takeLatest(types.INSERT_POPUP, insertPopup);
}

function* updatePopup({ inDate, data }) {
  try {
    yield call(api.popup.updatePopup, inDate, data);
    yield put(actions.updatePopupSuccess());
    yield put(actions.initPopupList());
  } catch (error) {
    console.log("UPDATE_POPUP", error);
    if (error && error.response.status === 412) {
      console.log("duration duplicated");
      yield put(
        actions.handlePopupErrors({
          duplicated: "선택한 기간과 중복되는 팝업이 존재합니다.",
        })
      );
    }
  }
}

export function* watcherUpdatePopup() {
  yield takeLatest(types.UPDATE_POPUP, updatePopup);
}

function* deletePopup({ inDate }) {
  try {
    yield call(api.popup.deletePopup, inDate);
    yield put(actions.initPopupList());
  } catch (error) {
    console.log("DELETE_POPUP", error);
  }
}

export function* watcherDeletePopup() {
  yield takeLatest(types.DELETE_POPUP, deletePopup);
}
