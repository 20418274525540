import { LOCATION_CHANGE } from "react-router-redux";

// import * as selectors from './selector';
import * as types from "./constant";
import {clone} from 'ramda'

const initialState = {
 contents:'',
 lastModified: ''
};

function terms(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...initialState
      };

      case types.HANDLE_ON_CHANGE:
      return {
        ...state,
        currentContents: action.value, // 수정본
      };

      case types.INIT_PRIVACY_POLICY_STATE:
        const cloned = clone(state.contents);
        console.log(cloned, 'cloned')
      return {
        ...state,
        currentContents: state.contents
      };

      case types.GET_PRIVACY_POLICY_SUCCESS:
      const {contents, lastModified } = action.data[0]
      return {
        ...state,
        contents, // 원본
        currentContents: contents,
        lastModified
      };

    default:
      return state;
  }
}

export default terms;
