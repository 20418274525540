export const UPDATE_EMAIL_USING = "UPDATE_EMAIL_USING";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";
export const HANDLE_CLOSE_MSG_MODAL = "HANDLE_CLOSE_MSG_MODAL";
export const HANDLE_OPEN_EMAIL_CREATE_MODAL = "HANDLE_OPEN_EMAIL_CREATE_MODAL";
export const GET_EMAIL = "GET_EMAIL";
export const GET_EMAIL_SUCCESS = "GET_EMAIL_SUCCESS";
export const GET_EMAIL_LIST = "GET_EMAIL_LIST";
export const GET_EMAIL_LIST_SUCCESS = "GET_EMAIL_LIST_SUCCESS";
export const HANDLE_ON_CHANGE = "HANDLE_ON_CHANGE";
