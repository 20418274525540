import * as types from "./constant";

// export function initGameStat(gameId, date, year) {
// 	return {
// 		type: types.INIT_GAME_STAT,
// 		gameId,
// 		date,
// 		year,
// 		MAU: false,
// 	};
// }

export function getSearchKeywords(obj) {
  return {
    type: types.GET_SEARCH_KEYWORDS_SALES,
  obj
  };
}
export function getDailyStatisticsByProject(obj) {
  return {
    type: types.GET_DAILY_STAT_BY_PJT,
  obj
  };
}
export function getDailyStatByPjtSuccess(obj,isLast) {
  return {
    type: types.GET_DAILY_STAT_BY_PJT_SUCCESS,
  obj,isLast
  };
}
// 하단 review 필요
export function clickNoticeEdit(id, arr) {
  return {
    type: types.CLICK_NOTICE_EDIT,
    id,
    arr
  };
}

export function handleCloseCouponDetailModal() {
  return {
    type: types.HANDLE_CLOSE_COUPON_DETAIL_MODAL
  };
}

export function clickNoticeDelete(id, arr) {
  return {
    type: types.CLICK_NOTICE_DELETE,
    id,
    arr
  };
}

export function handleCreateNotice(content) {
  return {
    type: types.POST_CREATE_NOTICE,
    content
  };
}

export function setTryPostTrue() {
  return {
    type: types.SET_TRY_POST_TRUE
  };
}

export function handleCloseMsgModal() {
  return {
    type: types.HANDLE_CLOSE_MSG_MODAL
  };
}

export function confirmChangeStatus(value) {
  return {
    type: types.CONFIRM_CHANGE_STATUS,
    value
  };
}

export function initAccountList(gameId, date) {
  return {
    type: types.INIT_ACCOUNT_LIST,
    gameId,
    date
  };
}
export function initDeletedAccountList(gameId, date) {
  return {
    type: types.INIT_DELETED_ACCOUNT_LIST,
    gameId,
    date
  };
}

export function confirmDeleteAccount(id) {
  return {
    type: types.CONFIRM_DELETE_ACCOUNT,
    id
  };
}

export function saveInput(value) {
  return {
    type: types.SAVE_INPUT,
    value
  };
}
// all actions above won't be required

export function handleSearchProject(title, date, opt) {
  return {
    type: types.HANDLE_SEARCH_PROJECT,
    title,
    date,
    opt
  };
}

export function handleSearchSuccess(rows) {
  return {
    type: types.HANDLE_SEARCH_PROJECT_SUCCESS,
    rows
  };
}

export function handleMultipleSearch(rows) {
  return {
    type: types.HANDLE_MULTIPLE_SEARCH,
    rows
  };
}

export function changeStatType(data) {
  return {
    type: types.CHANGE_STAT_TYPE_PROJECTS,
    data
  };
}
export function getDailyStatisticsProjects(projectId, date, opt) {
  return {
    type: types.GET_DAILY_STAT_PROJECTS,
    projectId,
    date,
    opt
  };
}

export function getMonthlyStatisticsProjects(projectId, date, opt) {
  return {
    type: types.GET_MONTHLY_STAT_PROJECTS,
    projectId,
    date,
    opt
  };
}
export function getSearchedDailyStatisticsProjectsSuccess(rows) {
  return {
    type: types.GET_SEARCHED_DAILY_STAT_PROJECTS_SUCCESS,
    rows
  };
}
export function getDailyStatisticsProjectsSuccess(rows, opt, isLast, dateChk) {
  return {
    type: types.GET_DAILY_STAT_PROJECTS_SUCCESS,
    rows,
    opt,
    isLast,
    dateChk
  };
}
export function getMonthlyStatisticsProjectsSuccess(rows, opt, isLast) {
  return {
    type: types.GET_MONTHLY_STAT_PROJECTS_SUCCESS,
    rows,
    opt,
    isLast
  };
}
export function clickProjectRow(key, arr) {
  return {
    type: types.CLICK_PROJECT_ROW,
    key,
    arr
  };
}
export function clickProjectRowSuccess(projectId) {
  return {
    type: types.CLICK_PROJECT_ROW_SUCCESS,
    projectId
  };
}
export function sortAscRows(rows, isSearched, tableType) {
  return {
    type: types.SORT_ASC_ROWS_SALES,
    rows,
    isSearched,
    tableType
  };
}
export function sortDescRows(rows, isSearched, tableType) {
  return {
    type: types.SORT_DESC_ROWS_SALES,
    rows,
    isSearched,
    tableType
  };
}

export function initDataTables() {
  return {
    type: types.INIT_DATA_TABLES_PROJECTS
  };
}
