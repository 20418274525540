export const INIT_CONSOLE_POPUP_LIST = 'INIT_CONSOLE_POPUP_LIST';
export const GET_CONSOLE_POPUP_LIST = 'GET_CONSOLE_POPUP_LIST';
export const GET_CONSOLE_POPUP_LIST_SUCCESS = 'GET_CONSOLE_POPUP_LIST_SUCCESS';

export const GET_CONSOLE_POPUP = 'GET_CONSOLE_POPUP';
export const DELETE_CONSOLE_POPUP = 'DELETE_CONSOLE_POPUP';
export const INSERT_CONSOLE_POPUP = 'INSERT_CONSOLE_POPUP';
export const INSERT_CONSOLE_POPUP_SUCCESS = 'INSERT_CONSOLE_POPUP_SUCCESS';
export const UPDATE_CONSOLE_POPUP = 'UPDATE_CONSOLE_POPUP';
export const UPDATE_CONSOLE_POPUP_SUCCESS = 'UPDATE_CONSOLE_POPUP_SUCCESS';

export const HANDLE_CONSOLE_POPUP_ERRORS = 'HANDLE_CONSOLE_POPUP_ERRORS';
