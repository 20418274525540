import { createSelector } from "reselect";

// import aws from 'aws-sdk';
// import moment from 'moment';

export const initGameStatSelector = createSelector(
  state => state,
  statOfDau => statOfDau
);

export const openSubMenuSelector = createSelector(
  state => state.leftAsideMenu.subMenuOpen,
  subMenuOpen => subMenuOpen
);

export const clickedSubMenuSelector = createSelector(
  state => state.leftAsideMenu.clickedSubMenu,
  clickedSubMenu => clickedSubMenu
);

export const isLandingSelector = createSelector(
  state => state.leftAsideMenu.isLanding,
  isLanding => isLanding
);
