// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import * as types from './constant';

export function handleErrors(errors) {
  return {
    type: types.HANDLE_ERRORS_ACCOUNT,
    errors,
  };
}

export function initErrors() {
  return {
    type: types.INIT_ERRORS,
  };
}

export function clickAccountRow(id) {
  return {
    type: types.CLICK_ACCOUNT_ROW,
    id,
  };
}
export function getBackToList() {
  return {
    type: types.GET_BACK_TO_LIST,
  };
}

export function getAccountInfoSuccess(data) {
  return {
    type: types.GET_ACCOUNT_INFO_SUCCESS,
    data,
  };
}

export function getAccountListSuccess(rows, count, isLast) {
  return {
    type: types.GET_ACCOUNTS_ROWS_SUCCESS,
    rows,
    count,
    isLast,
  };
}

export function getSearchedAccountListSuccess(rows, isLast) {
  return {
    type: types.GET_SEARCHED_ACCOUNTS_ROWS_SUCCESS,
    rows,
    isLast,
  };
}

export function getSearchedDelAccountListSuccess(rows, original) {
  return {
    type: types.GET_SEARCHED_DEL_ACCOUNTS_ROWS_SUCCESS,
    rows,
    original,
  };
}

export function getColumns(columns) {
  return {
    type: types.GET_COLUMNS,
    columns,
  };
}

export function initAccountList(date, isSearching, isInit) {
  return {
    type: types.INIT_ACCOUNT_LIST,
    date,
    isSearching,
    isInit,
  };
}
export function initToSearchAccounts() {
  return {
    type: types.INIT_TO_SEARCH_ACCOUNTS,
  };
}
export function initAccountInfo() {
  return {
    type: types.INIT_ACCOUNT_INFO,
  };
}
export function _initAccountList(date, isSearching) {
  return {
    type: types._INIT_ACCOUNT_LIST,
    date,
    isSearching,
  };
}
export function initDeletedAccountList() {
  return {
    type: types.INIT_DELETED_ACCOUNT_LIST,
  };
}

export function getDeletedAccountList(param) {
  return {
    type: types.GET_DELETED_ACCOUNT_LIST,
    param,
  };
}

export function getDeletedAccountListSuccess(data, actual, param) {
  return {
    type: types.GET_DELETED_ACCOUNT_LIST_SUCCESS,
    data,
    actual,
    param,
  };
}

export function confirmDeleteAccount(id, reason) {
  return {
    type: types.CONFIRM_DELETE_ACCOUNT,
    id,
    reason,
  };
}

export function saveInput(id, value) {
  return {
    type: types.SAVE_INPUT,
    id,
    value,
  };
}

// 계정 상세 정보 -> 메모 데이터
export function getMemoList(id) {
  return { type: types.GET_MEMO_LIST, id };
}

export function getMemoListSuccess(memo) {
  return { type: types.GET_MEMO_LIST_SUCCESS, memo };
}

// 메모 input store 처리 및 POST action
export function changeInputMemo(value) {
  return {
    type: types.CHANGE_INPUT_MEMO,
    value,
  };
}

export function createMemo(id) {
  return {
    type: types.CREATE_MEMO,
    id,
  };
}

export function deleteRow(inDate, arr, id) {
  return {
    type: types.DELETE_ROW,
    inDate,
    arr,
    id,
  };
}

// 계정 상세 정보 -> 관리자 계정 -> 상세
export function getAdminAccounts(id, date) {
  return {
    type: types.GET_ADMIN_ACCOUNTS,
    id,
    date,
  };
}

export function getAdminAccountsSuccess(data) {
  return {
    type: types.GET_ADMIN_ACCOUNTS_SUCCESS,
    data,
  };
}

// 계정 상세 정보 -> 크레딧 -> 지급
export function getCreditInfo(id) {
  return {
    type: types.GET_CREDIT_INFO,
    id,
  };
}

export function getCreditInfoSuccess(rows) {
  return {
    type: types.GET_CREDIT_INFO_SUCCESS,
    rows,
  };
}

// 크레딧 삭제
export function deleteCredit(params) {
  return {
    type: types.DELETE_CREDIT,
    params,
  };
}

// 계정 상세 정보 -> 계정 상태 -> 관리
export function getAccountStatus(id) {
  return {
    type: types.GET_ACCOUNT_STATUS,
    id,
  };
}

export function getAccountStatusSuccess(data) {
  return {
    type: types.GET_ACCOUNT_STATUS_SUCCESS,
    data,
  };
}

// DataTable 에서 정렬된 rows를 store에 재 저장하는 action
export function sortAscNormalAccounts(rows, isSearched, tableType) {
  return {
    type: types.SORT_ASC_NORMAL_ACCOUNTS,
    rows,
    isSearched,
    tableType,
  };
}

export function sortDescNormalAccounts(rows, isSearched, tableType) {
  return {
    type: types.SORT_DESC_NORMAL_ACCOUNTS,
    rows,
    isSearched,
    tableType,
  };
}

// 검색 키워드 처리
export function getSearchItem(obj) {
  return {
    type: types.GET_SEARCH_ITEM,
    obj,
  };
}
// 일반 계정 검색 키워드 처리
export function getSearchKeywords(obj) {
  return {
    type: types.GET_SEARCH_KEYWORDS,
    obj,
  };
}

export function searchDelAccount() {
  return {
    type: types.SEARCH_DEL_ACCOUNT,
  };
}

export function initCurrentAccountList() {
  return {
    type: types.INIT_CURRENT_ACCOUNT_LIST,
  };
}

export function searchAccounts(date) {
  return {
    type: types.SEARCH_ACCOUNTS,
    date,
  };
}

export function initSearchResult() {
  return {
    type: types.INIT_SEARCH_RESULT,
  };
}

export function getLastEvaluatedKeySuccess(LastEvaluatedKey) {
  return {
    type: types.GET_LAST_EVALUATED_KEY_SUCCESS,
    LastEvaluatedKey,
  };
}

export function updateInternalAccount(id, internalAccount) {
  return {
    type: types.UPDATE_INTERNAL_ACCOUNT,
    id,
    internalAccount,
  };
}

export function updateAutoPay(id, isAutoPay) {
  return {
    type: types.UPDATE_AUTO_PAY,
    id,
    isAutoPay,
  };
}

export function handlePostCredit(params) {
  return {
    type: types.HANDLE_POST_CREDIT,
    params,
  };
}

export function handlePostCreditSuccess() {
  return {
    type: types.HANDLE_POST_CREDIT_SUCCESS,
  };
}

export const getAccessToken = (payload) => ({
  type: types.GET_ACCESS_TOKEN,
  payload,
});

export const putAccountType = (payload) => ({
  type: types.PUT_ACCOUNT_TYPE,
  payload,
});

export const getAccountType = (payload) => ({
  type: types.GET_ACCOUNT_TYPE,
  payload,
});
export const getAccountTypeSuccess = (payload) => ({
  type: types.GET_ACCOUNT_TYPE_SUCCESS,
  payload,
});

