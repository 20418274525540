import moment from 'moment';
import { LOCATION_CHANGE } from 'react-router-redux';

// import * as selectors from './selector';
import * as types from './constant';

const initialState = {
	retention: [],
	searchKeywords: {
		date: '',
		title: '',
		id: '',
	},
};

function retention(state = initialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return {
				...initialState,
			};
		// case types.INIT_RETENTION:
		// 	return {
		// 		...initialState
		// 	};
		case types.GET_SEARCH_KEYWORDS:
			return {
				...state,
				searchKeywords: {
					...state.searchKeywords,
					...action.payload,
				},
			};
		case types.GET_RETENTION_SUCCESS:
			if (action.option === 'specificPJT')
				return {
					...state,
					retention: action.payload,
					projects: null,
					projectCount: null,
				};
			return {
				...state,
				retention: action.payload,
			};
		case types.GET_PROJECTS_FOR_RETENTION_SUCCESS:
			return {
				...state,
				projects: action.payload.projectList,
				projectCount: action.payload.totalCount,
			};

		default:
			return state;
	}
}

export default retention;
