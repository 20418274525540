import { createSelector } from "reselect";

import moment from "moment";

export const clickedSubMenuSelector = createSelector(
  (state) => state.leftAsideMenu.clickedSubMenu,
  (clickedSubMenu) => clickedSubMenu
);

export const versionListSelector = createSelector(
  (state) => state.sdkDocs.versionList,
  (versionList) => versionList
);

export const topicsSelector = createSelector(
  (state) => state.sdkDocs.topics,
  (topics) => topics
);

export const classParentListSelector = createSelector(
  (state) => state.sdkDocs.classParentList,
  (classParentList) => {
    let returnArr;
    returnArr = classParentList.map((obj) => ({
      ...obj,
      name: obj.title,
      key: obj.inDate,
    }));
    // returnArr.unshift({ name: "없음" });
    // console.log('0703 returnArr', returnArr)
    return returnArr;
  }
);

export const documentSelector = createSelector(
  (state) => state.sdkDocs.document,
  (document) => document
);

export const isClassDoneSelector = createSelector(
  (state) => state.sdkDocs.isClassDone,
  (isClassDone) => isClassDone
);

export const isDocDoneSelector = createSelector(
  (state) => state.sdkDocs.isDocDone,
  (isDocDone) => isDocDone
);

export const isDeleteClassDoneSelector = createSelector(
  (state) => state.sdkDocs.isDeleteClassDone,
  (isDeleteClassDone) => isDeleteClassDone
);

export const isUpdateDocDoneSelector = createSelector(
  (state) => state.sdkDocs.isUpdateDocDone,
  (isUpdateDocDone) => isUpdateDocDone
);

export const isDeleteDocDoneSelector = createSelector(
  (state) => state.sdkDocs.isDeleteDocDone,
  (isDeleteDocDone) => isDeleteDocDone
);

export const isMakeTopicDoneSelector = createSelector(
  (state) => state.sdkDocs.isMakeTopicDone,
  (isMakeTopicDone) => isMakeTopicDone
);

export const eachListSelector = createSelector(
  (state) => state.sdkDocs.list,
  (list) => {
    return list;
  }
);

export const isNewEachListSelector = createSelector(
  (state) => state.sdkDocs.isNewEachList,
  (isNewEachList) => isNewEachList
);

export const errObjSelector = createSelector(
  (state) => state.sdkDocs.errObj,
  (errObj) => errObj
);

export const isDeleteTopicDoneSelector = createSelector(
  (state) => state.sdkDocs.isDeleteTopicDone,
  (isDeleteTopicDone) => isDeleteTopicDone
);

export const selectedVersionSelector = createSelector(
  (state) => state.sdkDocs.selectedVersion,
  (selectedVersion) => {
    console.log('@@@ selectedVersion:', selectedVersion, ' @@@');
    return selectedVersion}
);
