import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../view/header';
import LeftAsideMunu from '../../view/leftAsideMenu';
import moment from 'moment';
import Octicon, { Search, X } from '@primer/octicons-react';
import {
  Statistic,
  Popup,
  Icon,
  Table,
  // Header,
  Rating,
} from 'semantic-ui-react';

import DateRangePicker from '../../fnComponents/DateRangePicker';
import ButtonDropdown from '../../fnComponents/ButtonDropdown';
import ButtonsGroup from '../../fnComponents/buttonGroup';
import * as actions from './store/action';

const Dashboard = () => {
  const { dashboards } = useSelector((state) => state.dashboards);
  // console.log('@@dashBoards', dashboards);

  const initInputObj = {
    id: '',
    company: '',
    signUpDate: '',
    lastLoginDate: '',
    status: '',
    init: false,
  };

  const dashboard = dashboards
    ? [
        {
          name: '가입(광고유입)',
          icon: '',
          value: `${dashboards.join.total.toLocaleString()} (${dashboards.join.googleAds.toLocaleString()})`,
        },
        {
          name: '카드 등록',
          icon: 'info circle',
          content: '최초로 결제정보(카드)를 등록한 계정수',
          value: dashboards.card.toLocaleString(),
        },
        {
          name: 'SDK 사용',
          icon: 'info circle',
          content: '최초로 DAU 발생한 계정수',
          value: dashboards.SDK.toLocaleString(),
        },
        {
          name: '출시(구글/애플)',
          icon: '',
          value: `${dashboards.release.google.toLocaleString()} / ${dashboards.release.apple.toLocaleString()}`,
        },
        {
          name: '문의',
          icon: 'info circle',
          content: '신규 등록된 문의 수(일반 문의 + 퍼블리싱 제안)',
          value: dashboards.inquiry.toLocaleString(),
        },
        {
          name: '거래액',
          icon: '',
          className: 'wider',
          value: `${dashboards.turnover.total.toLocaleString()}원`,
        },
        {
          name: '이탈(탈퇴/휴면)',
          icon: '',
          value: `${dashboards.churn.withdraw.toLocaleString()} / ${dashboards.churn.sleeper.toLocaleString()}`,
        },
        {
          name: 'DAU',
          icon: 'info circle',
          content: '전체 프로젝트 DAU 합계',
          value: dashboards.DAU.toLocaleString(),
        },
        {
          name: 'NRU',
          icon: 'info circle',
          content: '전체 프로젝트 신규유저 합계',
          value: dashboards.NRU.toLocaleString(),
        },
      ]
    : [];

  const today = moment();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const [focusedInput, setFocusedInput] = useState('');

  const handleFocusedInput = (obj) => {
    // console.log(obj, 'handleFocusedInput');
    setFocusedInput(obj);
  };

  const [focusByIcon, setFocus] = useState(null);
  const [inputObj, handleInput] = useState(initInputObj);

  const makeDashboard = () => {
    return dashboard.map((obj) => {
      return (
        <div className={`dashboard-stat-wrapper ${obj.className || null}`}>
          <Statistic size="small">
            <Statistic.Label>
              {obj.name}{' '}
              <Popup
                size="large"
                content={obj.content}
                trigger={<Icon size="tiny" name={obj.icon} />}
              />
            </Statistic.Label>
            <Statistic.Value>{obj.value}</Statistic.Value>
          </Statistic>
        </div>
      );
    });
  };

  const makeAccountTable = () => {
    if (
      dashboards &&
      dashboards.turnover &&
      dashboards.turnover.account &&
      dashboards.turnover.account.length
    ) {
      return dashboards.turnover.account.map((account, i) => (
        <Table.Row>
          <Table.Cell>{i + 1}</Table.Cell>
          <Table.Cell textAlign="right">
            {account.turnover.toLocaleString()}
          </Table.Cell>
          <Table.Cell>{account.companyName}</Table.Cell>
        </Table.Row>
      ));
    }
    return (
      <Table.Row>
        <Table.Cell>데이터가 존재하지 않습니다.</Table.Cell>
      </Table.Row>
    );
  };
  const makePJTTable = () => {
    if (
      dashboards &&
      dashboards.turnover &&
      dashboards.turnover.game &&
      dashboards.turnover.game.length
    )
      return dashboards.turnover.game.map((account, i) => (
        <Table.Row>
          <Table.Cell>{i + 1}</Table.Cell>
          <Table.Cell textAlign="right">
            {account.turnover.toLocaleString()}
          </Table.Cell>
          <Table.Cell>{account.title}</Table.Cell>
        </Table.Row>
      ));
    return (
      <Table.Row>
        <Table.Cell>데이터가 존재하지 않습니다.</Table.Cell>
      </Table.Row>
    );
  };

  const dispatch = useDispatch();

  const handleBtnDropdown = (obj) => {
    // console.log(obj, 'handleBtnDropdown');
    const { status } = obj;
    const todayStartDate = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    const todayEndDate = moment().set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });

    let _startDate;
    let _endDate;

    switch (status) {
      case '오늘':
        setStartDate(todayStartDate);
        setEndDate(todayEndDate);
        dispatch(
          actions.getDashboard({
            startDate: todayStartDate.toISOString(),
            endDate: todayEndDate.toISOString(),
          })
        );
        break;
      case '어제':
        _startDate = todayStartDate.subtract(1, 'day');
        _endDate = todayEndDate.subtract(1, 'day');
        setStartDate(_startDate);
        setEndDate(_endDate);
        dispatch(
          actions.getDashboard({
            startDate: _startDate.toISOString(),
            endDate: _endDate.toISOString(),
          })
        );
        break;
      case '지난주':
        _startDate = todayStartDate.subtract(7, 'day').day(1);
        _endDate = todayEndDate.subtract(7, 'day').day(7);
        setStartDate(_startDate);
        setEndDate(_endDate);
        dispatch(
          actions.getDashboard({
            startDate: _startDate.toISOString(),
            endDate: _endDate.toISOString(),
          })
        );
        break;
      case '지난달':
        _startDate = todayStartDate.subtract(1, 'month').date(1);
        _endDate = todayEndDate
          .subtract(1, 'month')
          .date(todayEndDate.endOf('month').date());
        setStartDate(_startDate);
        setEndDate(_endDate);
        dispatch(
          actions.getDashboard({
            startDate: _startDate.toISOString(),
            endDate: _endDate.toISOString(),
          })
        );
        break;
      case '최근 7일':
        _startDate = todayStartDate.subtract(7, 'day');
        _endDate = todayEndDate;
        setStartDate(_startDate);
        setEndDate(_endDate);
        dispatch(
          actions.getDashboard({
            startDate: _startDate.toISOString(),
            endDate: _endDate.toISOString(),
          })
        );
        break;
      case '최근 30일':
        _startDate = todayStartDate.subtract(30, 'day');
        _endDate = todayEndDate;
        setStartDate(_startDate);
        setEndDate(_endDate);
        dispatch(
          actions.getDashboard({
            startDate: _startDate.toISOString(),
            endDate: _endDate.toISOString(),
          })
        );
        break;

      default:
        break;
    }
  };

  const handleSearch = (obj) => {
    if (focusedInput === 'startDate') return;
    const { startDate, endDate } = obj;

    if (startDate && endDate) {
      dispatch(
        actions.getDashboard({
          startDate: moment(startDate)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toISOString(),
          endDate: moment(endDate)
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999,
            })
            .toISOString(),
        })
      );
    }
  };

  const csvData = dashboards
    ? [
        {
          [`가입(광고유입)`]: `${dashboards.join.total.toLocaleString()} (${dashboards.join.googleAds.toLocaleString()})`,
          [`카드 등록`]: dashboards.card.toLocaleString(),
          [`SDK 사용`]: dashboards.SDK.toLocaleString(),
          [`출시(구글/애플)`]: `${dashboards.release.google.toLocaleString()} / ${dashboards.release.apple.toLocaleString()}`,
          [`문의`]: dashboards.inquiry.toLocaleString(),
          [`거래액`]: `${dashboards.turnover.total.toLocaleString()}원`,
          [`이탈(탈퇴/휴면)`]: `${dashboards.churn.withdraw.toLocaleString()} / ${dashboards.churn.sleeper.toLocaleString()}`,
          [`DAU`]: dashboards.DAU.toLocaleString(),
          [`NRU`]: dashboards.NRU.toLocaleString(),
        },
      ]
    : [];

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <Octicon icon={X} />
        </button>
        <LeftAsideMunu />
        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title m-subheader__title--separator">
                  {/* {whereTo !== 'deleteAccount' ? '일반 계정' : '탈퇴 계정'} */}
                  대시보드
                </h3>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <form className="m-form m-form--fit m--margin-bottom-20">
                      <div className="row m--margin-bottom-20">
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <ButtonDropdown
                            // initSearchFilter={inputObj.init}
                            // initInputObj={initInputObj}
                            // handleInput={handleInput}
                            onChangeBtnDropdown={handleBtnDropdown}
                            initItem="오늘"
                            dropdownItems={[
                              {
                                name: '오늘',
                              },
                              {
                                name: '어제',
                              },
                              {
                                name: '지난주',
                              },
                              {
                                name: '지난달',
                              },
                              {
                                name: '최근 7일',
                              },
                              {
                                name: '최근 30일',
                              },
                            ]}
                          />
                        </div>
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <div
                            className="input-group pull-right"
                            id="m_daterangepicker1"
                          >
                            <DateRangePicker
                              displayFormat="YYYY.MM.DD"
                              startDate={startDate}
                              endDate={endDate}
                              focusedInput={focusedInput}
                              setFocusedInput={handleFocusedInput}
                              setStartDate={setStartDate}
                              setEndDate={setEndDate}
                              initInputObj={initInputObj}
                              initSearchFilter={inputObj.init}
                              searchParam="statDate"
                              onGetSearchItem={handleSearch}
                              setFocus={setFocus}
                              focusByIcon={
                                focusByIcon && focusByIcon.singleDatePicker1
                              }
                              className="form-control m-input"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    <ButtonsGroup
                      customClassName="btn-group-in-row"
                      columns={[]}
                      pdfData={null}
                      csvData={csvData}
                      turnoverAccount={
                        dashboards &&
                        dashboards.turnover &&
                        dashboards.turnover.account
                      }
                      turnoverGame={
                        dashboards &&
                        dashboards.turnover &&
                        dashboards.turnover.game
                      }
                      csvFileName={`dashboard_data_${moment().format(
                        'YYYY-MM-DD HH:mm'
                      )}`}
                    />
                    <div className="dashboard-stats-container">
                      {makeDashboard()}
                      {/* <div className="dashboard-stat-wrapper wider">
												<Statistic size="mini">
													<Statistic.Label>거래액 </Statistic.Label>
													<Statistic.Value>{turnOver} (</Statistic.Value>
												</Statistic>
											</div> */}
                    </div>
                    <div className="dashboard-table-container">
                      <span>거래액 TOP 10 (회사)</span>
                      <Table className="dashboard-table" celled padded>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>순위</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right">
                              거래액
                            </Table.HeaderCell>
                            <Table.HeaderCell>회사명</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>{makeAccountTable()}</Table.Body>
                      </Table>
                    </div>
                    <div className="dashboard-table-container">
                      <span>거래액 TOP 10 (프로젝트)</span>
                      <Table className="dashboard-table" celled padded>
                        <Table.Header>
                          <Table.Row colspan="3">
                            <Table.HeaderCell>순위</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right">
                              거래액
                            </Table.HeaderCell>
                            <Table.HeaderCell>프로젝트</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>{makePJTTable()}</Table.Body>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
