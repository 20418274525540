const errMsg = errRes => {
	let errMsg = { isErr: true };
	for (let key in errRes) {
		if (key !== 'isErr') {
			const filteredErr = errRes[key].filter(key => !!key);

			if (filteredErr && filteredErr.length)
				errMsg = {
					...errMsg,
					[key]: filteredErr[0],
				};
		}
	}
	return errMsg;
};

export default errMsg;
