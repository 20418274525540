import { createSelector } from "reselect";
import moment from "moment";

// import aws from 'aws-sdk';
// import moment from 'moment';

export const clickedIdSelector = createSelector(
  state => state.overdueBill.detail,
  detail => detail
);

export const idSelector = createSelector(
  state => state.overdueBill.detail,
  detail => {
    const {
      clickedId: { email }
    } = detail;
    return email;
  }
);

export const columnsSelector = createSelector(
  state => state.overdueBill.columns,
  columns => columns
);

export const detailColumnsSelector = createSelector(
  state => state.overdueBill.detailColumns,
  detailColumns => detailColumns
);

export const gameTitleSelector = createSelector(
  state => state.overdueBill.gameTitle,
  gameTitle => gameTitle
);

// 원본 from here
export const _accountListSelector = createSelector(
  state => state.overdueBill.currentBill,
  currentBill => {
    if (currentBill)
      return currentBill.map(row => ({
        email: row.email,
        id: { id: row.accountId, noDisplay: true },
        companyName: row.companyName,
        chargingPrice: row.chargingPrice,
        chargedPrice: row.chargedPrice,
        unChargedPrice: row.unChargedPrice,
        chargedDate: row.chargedDate,
        payMethod: row.payMethod,
        isPay:
          row.isPay === "u"
            ? "미청구"
            : row.isPay === "n"
            ? "미납"
            : row.isPay === "y"
            ? "완납"
            : row.isPay === "o"
            ? "연체"
            : null
      }));
  }
);
// 원본 to here
// test from here

// 원본 to here

export const projectChargeListSelector = createSelector(
  // for VIEW
  state => state.overdueBill.accountBill,
  accountBill => {
    const { bills } = accountBill;

    if (bills) {
      const arr = bills.map(bill => {
        let obj = {
          gameTitle: bill.gameTitle,
          beforeDC: bill.beforeDC,
          freePrice: bill.freePrice,
          chargePrice: bill.chargePrice,
          chargeDetail: [
            //   noDisplay: true,
            {
              [bill.dau ? "dau" : null]: {
                beforeDC: bill.dau ? bill.db.beforeDC : null,
                freePrice: bill.dau ? bill.db.freePrice : null,
                chargePrice: bill.dau ? bill.db.chargePrice : null
              }
            },
            {
              [bill.share ? "share" : null]: {
                beforeDC: bill.share ? bill.share.beforeDC : null,
                freePrice: bill.share ? bill.share.freePrice : null,
                chargePrice: bill.share ? bill.share.chargePrice : null,
                type: bill.share ? bill.share.type : null
              }
            },
            {
              [bill.db ? "db" : null]: {
                beforeDC: bill.db ? bill.db.beforeDC : null,
                freePrice: bill.db ? bill.db.freePrice : null,
                chargePrice: bill.db ? bill.db.chargePrice : null
              }
            }
          ],
          gameId: bill.gameId
        };
        obj = {
          ...obj,
          chargeDetail: obj.chargeDetail.map(detail => {
            if (!detail.null) return detail;
          })
        };
        return obj;
      });

      return arr;
    }
  }
);

export const gameBillSelector = createSelector(
  state => state.overdueBill.gameBill,
  gameBill => gameBill
);

export const statusSelector = createSelector(
  state => state.overdueBill.status,
  status => status
);

export const searchKeywordsSelector = createSelector(
  state => state.overdueBill.searchKeywords,
  searchKeywords => searchKeywords
);

export const chargingPriceSelector = createSelector(
  state => state.overdueBill.chargingPrice,
  chargingPrice => chargingPrice
);
export const manageBillSelector = createSelector(
  state => state.overdueBill,
  ({ rowData, manageBillModalOpen }) => ({
    rowData,
    manageBillModalOpen
  })
);

// overdue bill
export const overdueBillSelector = createSelector(
  state => state.overdueBill.currentBill,
  currentBill => {
    if (currentBill)
      return currentBill.map((row, i) => {
        return {
          ...row,
          overdueReleasedDate:
            row.overdueReleasedDate !== "-"
              ? moment(row.overdueReleasedDate).format("YYYY.MM.DD")
              : "-",
          overdueAmount: row.overdueAmount.toLocaleString()
        };
      });
  }
);

export const lastEvaluatedKeySelector = createSelector(
  state => state.overdueBill.lastEvaluatedKey,
  lastEvaluatedKey => {
    console.log("lastEvaluatedKeyArr");
    return lastEvaluatedKey;
  }
);
/**
export const accountBillSelector = createSelector(
  // 요금 서브 디테일 for VIEW ----> 삭제 할 것
  state => state.currentCharge.accountBill,
  accountBill => {
    const { bills } = accountBill;
    let share = {};
    let dau = {};
    let db = {};
    return bills.map(bill => {
      console.log(bill, "빌 테스트");
      share = {};
      dau = {};
      db = {};
      if (bill.dau) {
        dau = {
          beforeDC: bill.dau.beforeDC,
          freePrice: bill.dau.freePrice,
          chargePrice: bill.dau.chargePrice
        };
      }
      if (bill.share) {
        share = {
          beforeDC: bill.share.beforeDC,
          freePrice: bill.share.freePrice,
          chargePrice: bill.share.chargePrice,
          type: bill.share.type
        };
      }
      if (bill.db) {
        db = {
          beforeDC: bill.db ? bill.db.beforeDC : null,
          freePrice: bill.db ? bill.db.freePrice : null,
          chargePrice: bill.db ? bill.db.chargePrice : null
        };
      }

      const checkingObj = {
        isDauTrue: !!Object.keys(dau).length,
        isShareTrue: !!Object.keys(share).length,
        isDbTrue: !!Object.keys(db).length
      };

      const { isDauTrue, isShareTrue, isDbTrue } = checkingObj;

      const obj = {
        gameTitle: bill.gameTitle,
        [isDauTrue ? "dau" : null]: dau,
        [isShareTrue ? "share" : null]: share,
        [isDbTrue ? "db" : null]: db
      };
      delete obj.null;
      console.log(obj, "객체 재생성");
      return obj;
    });
  }
);
*/
