import * as types from './constant';

export function initPopupList() {
  return {
    type: types.INIT_CONSOLE_POPUP_LIST,
  };
}

export function getPopupList() {
  return {
    type: types.GET_CONSOLE_POPUP_LIST,
  };
}

export function getPopupListSuccess(popupList, totalCount) {
  return {
    type: types.GET_CONSOLE_POPUP_LIST_SUCCESS,
    popupList,
    totalCount,
  };
}

export function insertPopup(obj) {
  return {
    type: types.INSERT_CONSOLE_POPUP,
    obj,
  };
}

export function insertPopupSuccess() {
  return {
    type: types.INSERT_CONSOLE_POPUP_SUCCESS,
  };
}
export function updatePopup(id, data) {
  return {
    type: types.UPDATE_CONSOLE_POPUP,
    id,
    data,
  };
}
export function updatePopupSuccess() {
  return {
    type: types.UPDATE_CONSOLE_POPUP_SUCCESS,
  };
}

export function deletePopup(id) {
  return {
    type: types.DELETE_CONSOLE_POPUP,
    id,
  };
}

export function getPopup(title, arr) {
  return {
    type: types.GET_CONSOLE_POPUP,
    title,
    arr,
  };
}

export function handlePopupErrors(err) {
  return {
    type: types.HANDLE_CONSOLE_POPUP_ERRORS,
    err,
  };
}
