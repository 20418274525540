import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import DataTable from '../../fnComponents/_DataTable';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from './store/action';
import * as selectors from './store/selector';

const ProjectDetail = ({
  projectDetail,
  handleCloseProjectModal,
  onConfirmChangeStatus,
  clickedPjt,
  statusList,
}) => {
  const [radioBtn, handleChange] = useState('n');
  const [isRadioBtnChanged, checkChange] = useState(false);
  const [reasonInput, handleInput] = useState('');
  const [errMsg, handleError] = useState('');

  useEffect(() => {
    const { status } = clickedPjt;
    console.log('clickedPjt', clickedPjt);
    if (status) handleChange(status === '정상' ? 'n' : 's');
  }, [clickedPjt]);

  const handleInputChange = (e) => {
    handleError('');
    handleInput(e.target.value);
  };

  const handleRadioBtnChange = (e) => {
    handleError('');

    const { status } = clickedPjt;

    if (status === e.target.id) checkChange(false);
    else checkChange(true);
    handleChange(e.target.id);
  };

  const handleSubmit = () => {
    if (!reasonInput) return handleError('사유는 필수 입력 항목입니다.');
    const { status } = clickedPjt;
    const _status = status === '정상' ? 'n' : 's';
    // console.log(_status, radioBtn, "같은지 확인");
    if (_status === radioBtn) return handleError('상태를 변경해주세요.');
    onConfirmChangeStatus({ status: radioBtn, details: reasonInput });
  };
  return (
    <Modal size="lg" isOpen={projectDetail}>
      <ModalHeader>프로젝트 상태 관리</ModalHeader>
      <ModalBody>
        <div className="form-group m-form__group row">
          <label htmlFor="credit-reason" className="col-lg-2 col-form-label">
            대상
          </label>
          <div className="col-lg-10">
            <span className="form_text">{clickedPjt && clickedPjt.title}</span>
          </div>
        </div>
        <div className="form-group m-form__group row">
          <label htmlFor="email-contents" className="col-lg-2 col-form-label">
            상태
          </label>
          <div className="col-lg-10">
            <div className="m-radio-inline">
              <label className="m-radio">
                <input
                  id="n"
                  onChange={(e) => handleRadioBtnChange(e)}
                  type="radio"
                  name="status-radio"
                  checked={radioBtn === 'n'}
                />
                정상
                <span />
              </label>
              <label className="m-radio">
                <input
                  id="s"
                  onChange={(e) => handleRadioBtnChange(e)}
                  type="radio"
                  name="status-radio"
                  checked={radioBtn === 's'}
                />
                정지
                <span />
              </label>
            </div>
          </div>
        </div>
        {/* a row for INPUT */}
        <div className="form-group m-form__group row">
          <label htmlFor="credit-reason" className="col-lg-2 col-form-label">
            * 사유
          </label>
          <div className="col-lg-9">
            <input
              disabled={!isRadioBtnChanged}
              onChange={handleInputChange}
              type="type"
              className="form-control"
              id="credit-reason"
              required
            />
            {errMsg && <div className="error message visible">{errMsg}</div>}
          </div>
        </div>
        <div className="form-group m-form__group row">
          <label htmlFor="credit-reason" className="col-lg-2 col-form-label">
            내역
          </label>
          <DataTable
            customClassName="column-4"
            rows={statusList}
            columns={[
              {
                label: '적용일시',
                field: 'date',
                sort: 'asc',
                width: 10,
              },
              {
                label: '구분',
                field: 'type',
                sort: 'asc',
                width: 10,
              },
              {
                label: '사유',
                field: 'reason',
                sort: 'asc',
                width: 10,
              },
              {
                label: '관리자',
                field: 'admin',
                sort: 'asc',
                width: 10,
              },
            ]}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          적용하기
        </Button>{' '}
        <Button color="primary" onClick={handleCloseProjectModal}>
          닫기
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  clickedPjt: selectors.clickedIdSelector,
  statusList: selectors.statusListSelector,
});

const mapDispatchToProps = (dispatch) => ({
  handleCloseProjectModal: () => dispatch(actions.handleCloseProjectModal()),
  onConfirmChangeStatus: (value) =>
    dispatch(actions.confirmChangeStatus(value)),
  // onSaveInput: value => dispatch(actions.saveInput(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);
