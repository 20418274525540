import { requestPost, requestGet, requestDelete, requestPut } from "../../utils/request";
import Qs from "qs";

import BaseUrl from "../../shared/baseUrl";

export const getInquiriesList = params => {
  const url = `${BaseUrl.LANDING_API}/inquiry`;

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: "brackets" });
    }
  };

  return requestGet(url, options);
};

export const sendResponse = (inDate, data) => {
  const url = `${BaseUrl.LANDING_API}/inquiry/${inDate}`;

  return requestPost(url, data);
};

export const deleteInquiry = (inDate) => {
  const url = `${BaseUrl.LANDING_API}/inquiry/${inDate}`;

  return requestDelete(url);
};
