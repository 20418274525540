import React, { Component } from "react";
import { Button } from "reactstrap";

import DataTable from "../fnComponents/_DataTable copy";

import ReactToPrint from "react-to-print";

class ButtonToPrintOut extends Component {
  render() {
    let componentRef = undefined;

    const { pdfData, columns } = this.props;

    return (
      <React.Fragment>
        <ReactToPrint
          trigger={() => <Button>Print</Button>}
          content={() => componentRef}
        />
        <div className="table-no-display">
          <DataTable
            columns={columns}
            rows={pdfData}
            ref={el => (componentRef = el)}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default ButtonToPrintOut;
