import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const MsgModal = ({
  content,
  isMsgModalOpen,
  onHandleCloseMsgModal,
  onHandleDelete,
  onlyCanClose,
  confirmOrCancel,
  onConfirmAction,
  onDeleteAction,
  size,
  contentsObject,
  header,
  withDelete,
}) => {
  return (
    <Modal centered size={size} isOpen={isMsgModalOpen}>
      {header ? <ModalHeader>{header}</ModalHeader> : null}
      {content ? (
        <ModalBody>
          {contentsObject ? (
            content
          ) : content ? (
            <p className="msg-modal-description">{content}</p>
          ) : null}
        </ModalBody>
      ) : null}
      <ModalFooter>
        {withDelete ? (
          <React.Fragment>
            <Button color="primary" onClick={onConfirmAction}>
              확인
            </Button>
            <Button color="primary" onClick={onHandleCloseMsgModal}>
              취소
            </Button>
            <Button color="danger" onClick={onDeleteAction}>
              삭제
            </Button>
          </React.Fragment>
        ) : onlyCanClose ? (
          <Button color="primary" onClick={onHandleCloseMsgModal}>
            확인
          </Button>
        ) : confirmOrCancel ? (
          <React.Fragment>
            <Button color="primary" onClick={onConfirmAction}>
              확인
            </Button>
            <Button color="primary" onClick={onHandleCloseMsgModal}>
              취소
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button color="primary" onClick={onHandleDelete}>
              삭제
            </Button>
            <Button color="primary" onClick={onHandleCloseMsgModal}>
              취소
            </Button>
          </React.Fragment>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default MsgModal;
