import { createSelector } from 'reselect';

import moment from 'moment';

export const showcaseListSelectorForView = createSelector(
	(state) => state.showcase.showcaseList,
	(showcaseList) => {
		// console.log(showcaseList);
		return showcaseList.map((showcase) => ({
			gameTitle: showcase.gameTitle,
			providers: showcase.providers,
			inDate: moment(showcase.inDate).format('YYYY.MM.DD HH:mm'),
			_inDate: { noDisplay: true, inDate: showcase.inDate },
			isPublic: showcase.isPublic,
		}));
	}
);
export const showcaseSelector = createSelector(
	(state) => state.showcase.selectedShowcase,
	(selectedShowcase) => {
		if (selectedShowcase)
			return {
				gameId: selectedShowcase.gameId,
				gameTitle: selectedShowcase.gameTitle,
				providers: selectedShowcase.providers,
				gameGenre: selectedShowcase.gameGenre,
				image: selectedShowcase.image,
				descriptions: selectedShowcase.descriptions,
				googleURI: selectedShowcase.googleURI,
				appleURI: selectedShowcase.appleURI,
				isPublic: selectedShowcase.isPublic,
			};
		return null;
	}
);

export const showcaseInDateSelector = createSelector(
	(state) => state.showcase.selectedShowcase,
	(selectedShowcase) => {
		if (selectedShowcase) return selectedShowcase.inDate;
		return null;
	}
);

export const insertDoneSelector = createSelector(
	(state) => state.showcase.insertDone,
	(insertDone) => insertDone
);

export const lastEvaluatedKeySelector = createSelector(
	(state) => state.showcase.lastEvaluatedKey,
	(lastEvaluatedKey) => lastEvaluatedKey
);

export const errObjSelector = createSelector(
	(state) => state.showcase.error,
	(error) => error
);
