// import { LOCATION_CHANGE } from 'react-router-redux';

import * as types from './constant';
import sortingDates from '../../../fnComponents/SortingDates';
import { LOCATION_CHANGE } from 'react-router-redux';

const initialState = {
  accountList: [],
  columns: [],
  detail: { clickedId: '', accountDetail: false },
  memoInDetail: [],
  searchItem: {
    // only for deletedAccount
    email: undefined,
    status: undefined,
    reason: undefined,
    admin: undefined,
  },
  searchKeywords: {
    // only for general
    email: undefined,
    account_id: undefined,
    companyName: undefined,
    signUpDate: undefined,
    lastLoginDate: undefined,
    status: undefined,
  },
  searchedAccountList: [],
  sortingParam: false,
  isLast: undefined,
  offsetInfo: {
    accountInfoListOffset: 0,
    searchingAccountInfoListOffset: 0,
  },
  accountType: [],
};

function accountManaging(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return initialState;
    case types.GET_COLUMNS:
      return {
        ...state,
        columns: action.columns,
      };
    case types.INIT_ACCOUNT_INFO:
      return {
        ...initialState,
      };
    case types.INIT_ERRORS:
      return {
        ...state,
        errors: null,
      };
    case types.HANDLE_ERRORS_ACCOUNT:
      return {
        ...state,
        errors: action.errors,
      };

    case types.CLICK_ACCOUNT_ROW:
      return {
        ...state,
        detail: { clickedId: action.id, accountDetail: true },
      };

    case types.GET_ACCOUNTS_ROWS_SUCCESS:
      return {
        ...state,
        accountList: [...state.accountList, ...action.rows],
        detail: { clickedId: '', accountDetail: false },
        count: action.count,
        isLast: action.isLast,
        offsetInfo: {
          ...state.offsetInfo,
          accountInfoListOffset: !action.isLast
            ? state.offsetInfo.accountInfoListOffset + 25
            : state.offsetInfo.accountInfoListOffset,
        },
      };

    case types.GET_DELETED_ACCOUNT_LIST_SUCCESS:
      switch (action.param) {
        case 'getMore':
          return {
            ...state,
            deletedAccountList: [...state.deletedAccountList, ...action.data],
            actualDeletedAccountList: [
              ...state.actualDeletedAccountList,
              ...action.actual,
            ],
          };

        default:
          return {
            ...state,
            deletedAccountList: action.data,
            actualDeletedAccountList: action.actual,
          };
      }
    case types.SAVE_INPUT:
      return {
        ...state,
        reasonToBeAdded: action.value,
      };

    case types.GET_BACK_TO_LIST:
      return {
        ...state,
        detail: {},
      };

    case types.GET_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          accountInfo: action.data,
        },
      };

    case types.GET_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        accountStatus: action.data,
      };

    case types.GET_MEMO_LIST_SUCCESS:
      return {
        ...state,
        memo: action.memo,
      };

    case types.CHANGE_INPUT_MEMO:
      return {
        ...state,
        inputMemo: action.value,
      };

    case types.GET_ADMIN_ACCOUNTS_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          adminAccounts: sortingDates(action.data),
        },
      };

    case types.GET_CREDIT_INFO_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          creditInfo: sortingDates(action.rows),
        },
      };

    case types.SORT_ASC_NORMAL_ACCOUNTS:
      return {
        ...state,
        accountList:
          action.tableType || action.isSearched
            ? state.accountList
            : action.rows,
        searchedAccountList:
          !action.tableType && action.isSearched ? action.rows : [],
        deletedAccountList: action.tableType
          ? action.rows
          : state.deletedAccountList,
        sortingParam: true,
      };

    case types.SORT_DESC_NORMAL_ACCOUNTS:
      return {
        ...state,
        accountList:
          action.tableType || action.isSearched
            ? state.accountList
            : action.rows,
        searchedAccountList:
          !action.tableType && action.isSearched ? action.rows : [],
        deletedAccountList: action.tableType
          ? action.rows
          : state.deletedAccountList,
        sortingParam: false,
      };

    case types.GET_SEARCH_ITEM:
      return {
        ...state,
        searchItem: {
          ...state.searchItem,
          ...action.obj,
        },
      };

    case types.GET_SEARCH_KEYWORDS:
      return {
        ...state,
        searchKeywords: {
          ...state.searchKeywords,
          ...action.obj,
        },
      };

    case types.INIT_TO_SEARCH_ACCOUNTS:
      return {
        ...state,
        searchedAccountList: initialState.searchedAccountList,
        accountList: initialState.accountList,
        offsetInfo: initialState.offsetInfo,
      };

    case types.GET_SEARCHED_ACCOUNTS_ROWS_SUCCESS:
      return {
        ...state,
        searchedAccountList: [...state.searchedAccountList, ...action.rows],
        isLast: action.isLast,
        offsetInfo: {
          ...state.offsetInfo,
          searchingAccountInfoListOffset: !action.isLast
            ? state.offsetInfo.searchingAccountInfoListOffset + 25
            : state.offsetInfo.searchingAccountInfoListOffset,
        },
      };

    case types.GET_SEARCHED_DEL_ACCOUNTS_ROWS_SUCCESS:
      return {
        ...state,
        searchedDelAccountList: action.rows,
        originalSearchedDelAccountList: action.original,
      };

    case types.INIT_SEARCH_RESULT:
      return {
        ...state,
        searchedAccountList: [],
        searchKeywords: initialState.searchKeywords,
        offsetInfo: initialState.offsetInfo,
        accountList: [],
      };
    case types.INIT_CURRENT_ACCOUNT_LIST:
      return {
        ...state,
        searchedAccountList: [],
        accountList: [],
        offsetInfo: initialState.offsetInfo,
      };
    case types.GET_LAST_EVALUATED_KEY_SUCCESS:
      return {
        ...state,
        LastEvaluatedKey: action.LastEvaluatedKey,
      };
    case types.GET_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        accountType: action.payload,
      };

    default:
      return state;
  }
}

export default accountManaging;
