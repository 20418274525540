/*
 * Copyright 2013-2022 AFI, Inc. All Rights Reserved.
 */

import React, { useState, useEffect } from 'react';
import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import ButtonsGroup from '../../../fnComponents/buttonGroup';
import DataTable from '../../../fnComponents/_DataTable';

import SearchForm from '../../../fnComponents/SearchForm';
import MsgModal from '../../../fnComponents/MsgModal';
import moment from 'moment';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from 'reactstrap';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from './store/action';
import * as selectors from './store/selector';
import CurrentChargeDetail from './CurrentChargeDetail';

import { has } from 'lodash';

const CurrentCharge = ({
  columns,
  accountList,
  detail: { chargeDetail },
  manageBill,
  dateSearchKeywords,
  isErrMsgModalOpen,
  sortingParam,
  _accountList,
  isLast,
  totalAmount,
  //
  onClickChargedBillRow,
  onGetBackToMainTable,
  onGetBill,
  onInitCurrentChargeList,
  onGetSearchKeywords,
  onHandleManageBillModal,
  onUpdateAccountBill,
  onGetBillError,
  onSortAscChargedBill,
  onSortDescChargedBill,
  onGetSomeOfBill,
}) => {
  const [payingDate, setPayingDate] = useState(moment().format('YYYY-MM-DD'));

  const handleSetPayingDate = (e) => {
    setPayingDate(e.target.value);
  };
  const makeBillDate = () => {
    let arr = [];
    // let arr = [{ name: "전체" }];
    const lastBillDate = { name: moment('2018-06', 'YYYY-MM') };
    let currentDate = moment();

    for (let i = 0; true; i++) {
      // 당월 10일 기준으로 분기를 추가해야 함
      // 10일 이후이면
      if (!i && currentDate.date() >= 5) {
        arr = [
          ...arr,
          {
            key: currentDate.toISOString(),
            name: currentDate.format('YYYY-MM'),
          },
        ];
      } else if (
        !currentDate.isBefore(lastBillDate.name) &&
        currentDate.format('YYYY-MM') !== lastBillDate.name.format('YYYY-MM')
      ) {
        currentDate.subtract(1, 'month');
        arr = [
          ...arr,
          {
            key: currentDate.toISOString(),
            name: currentDate.format('YYYY-MM'),
          },
        ];
      } else {
        break;
      }
    }

    return arr;
  };

  useEffect(() => {
    // console.log(searchKeywords, "searchKeywords");
    const { rowData } = manageBill;
    if (rowData) {
      handleInput({
        ...userInput,
        isPay:
          rowData[8].isPay === '미납'
            ? 'n'
            : rowData[8].isPay === '부분납'
            ? 'p'
            : rowData[8].isPay === '완납'
            ? 'y'
            : rowData[8].isPay === '연체'
            ? 'o'
            : null,
      });
    }
  }, [manageBill]);

  useEffect(() => {
    if (dateSearchKeywords) {
      const billDate = moment(dateSearchKeywords, 'YYYY-MM').format('YYYY.MM');
      handleInput({
        ...userInput,
        billDate,
      });
    } else {
      const _today = moment();
      const today =
        _today.date() >= 5
          ? _today.format('YYYY.MM')
          : _today.subtract(1, 'month').format('YYYY.MM');
      handleInput({
        ...userInput,
        billDate: today,
      });
    }
  }, [dateSearchKeywords]);

  const [userInput, handleInput] = useState({ payMethod: 'etc' });
  const [errMsg, handleErrors] = useState({});
  const [shouldMsgModalOpen, handleMsgModalOpen] = useState({});

  const handleInputChange = (e) => {
    handleErrors({ ...errMsg, [e.target.id]: '' });

    handleInput({
      ...userInput,
      [e.target.id]: e.target.value,
    });
  };

  const findChargingPriceInRowData = () => {
    const { chargingPrice } = rowData.find((item) =>
      has(item, 'chargingPrice')
    );
    return chargingPrice;
  };

  const updateAccountBill = () => {
    let isErr;
    const { payingAmount, billDate, isPay } = userInput;
    const { rowData } = manageBill;

    const chargingPrice = findChargingPriceInRowData();

    const parsedInt = Number.parseInt(chargingPrice.replace(/,/g, ''));

    if (!payingAmount) {
      isErr = true;
      handleErrors({ ...errMsg, payingAmount: '납부금액을 입력해주세요.' });
    } else if (chargingPrice && Number(payingAmount) > parsedInt) {
      isErr = true;
      handleErrors({
        ...errMsg,
        payingAmount: '청구금액 이하로 입력해주세요.',
      });
    }

    if (isErr) return;
    // handleInput({ ...userInput, accountId: rowData[1].id });
    const { id } = rowData[1].id;
    onUpdateAccountBill({
      ...userInput,
      id,
      billDate,
      payingDate: moment(payingDate).format('YYYY-MM-DD 00:00'),
    });
  };

  const handleManageBillModal = (id, obj) => {
    const { isPay } = obj[8];

    if (isPay === '완납')
      return handleMsgModalOpen({ isOpen: true, complete: true });
    if (isPay === '미청구')
      return handleMsgModalOpen({ isOpen: true, notCharged: true });
    onHandleManageBillModal(id, obj);
  };

  const handleMsgModalClose = () => {
    handleMsgModalOpen({});
  };

  const { manageBillModalOpen, rowData } = manageBill;

  // const [initItemForChargedBill, setInitItemForChargedBill] = useState(_initItemForChargedBill())

  const _initItemForChargedBill = () => {
    const date =
      moment().date() >= 5
        ? moment().format('YYYY-MM')
        : moment().subtract(1, 'month').format('YYYY-MM');
    return date;
  };

  const initItemForChargedBill = _initItemForChargedBill();

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title m-subheader__title--separator">
                  결제 관리
                </h3>
                <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
                  <li className="m-nav__item m-nav__item--home">
                    <a href="dummy" className="m-nav__link m-nav__link--icon">
                      <i className="m-nav__link-icon la la-home" />
                    </a>
                  </li>
                  <li className="m-nav__separator">-</li>
                  <li className="m-nav__item">
                    <a href="dummy" className="m-nav__link">
                      <span className="m-nav__link-text">결제 관리</span>
                    </a>
                  </li>
                  <li className="m-nav__separator">-</li>
                  <li className="m-nav__item">
                    <a href="dummy" className="m-nav__link">
                      <span className="m-nav__link-text">현재 요금</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {!chargeDetail ? (
            <div className="m-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="m-portlet">
                    <div className="m-portlet__body">
                      <SearchForm
                        initItemForChargedBill={
                          initItemForChargedBill && initItemForChargedBill
                        }
                        keywords={[
                          '청구년월',
                          '아이디',
                          '회사명',
                          '납부상태',
                          '고객규모',
                        ]}
                        mainlyRequiredAction={onGetBill}
                        initAction={onInitCurrentChargeList}
                        onGetSearchKeywords={onGetSearchKeywords}
                        selectOpts={{
                          billDate: {
                            name: '청구년월',
                            options: makeBillDate(),
                          },
                          isPay: {
                            name: '납부상태',
                            options: [
                              { name: '전체' },
                              { name: '미납' },
                              { name: '완납' },
                              { name: '부분납' },
                              { name: '연체' },
                              { name: '미청구' },
                            ],
                          },
                          is100mClub: {
                            name: '고객규모',
                            options: [
                              { name: '전체' },
                              { name: '일반' },
                              { name: '1억클럽' },
                              { name: '10억클럽' },
                              { name: '30억클럽' },
                            ],
                          },
                        }}
                      />
                      {totalAmount ? (
                        <>
                          <div className="charge-summary-container1">
                            <div className="charge-summary-row1">
                              <div className="charge-summary">
                                <b>사용금액 :</b>{' '}
                                {totalAmount.totalPrice.toLocaleString()}원
                                &nbsp;&nbsp;
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <div className="charge-summary">
                                <b>이용금액 :</b>{' '}
                                {totalAmount.usedCost.toLocaleString()}원
                                &nbsp;&nbsp;
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <div className="charge-summary">
                                <b>크레딧 차감 :</b>{' '}
                                {totalAmount.credit.toLocaleString()}원
                                &nbsp;&nbsp;
                              </div>
                              &nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                          <div className="charge-summary-container2">
                            <div className="charge-summary-row2">
                              <div className="charge-summary">
                                <b>부가세 :</b>{' '}
                                {totalAmount.surtax.toLocaleString()}원
                                &nbsp;&nbsp;
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <div className="charge-summary">
                                <b>청구금액 :</b>{' '}
                                {totalAmount.chargingPrice.toLocaleString()}원
                                &nbsp;&nbsp;
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <div className="charge-summary">
                                <b>납부금액 :</b>{' '}
                                {totalAmount.chargedPrice.toLocaleString()}원
                                &nbsp;&nbsp;
                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <div className="charge-summary">
                                {' '}
                                <b>미납금액 :</b>{' '}
                                {totalAmount.unChargedPrice.toLocaleString()}원
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                      <ButtonsGroup
                        columns={columns}
                        pdfData={accountList}
                        csvData={accountList}
                        csvFileName={`bill_list_${moment().format(
                          'YYYY-MM-DD HH:mm'
                        )}`}
                      />
                      <DataTable
                        requiredAction={onClickChargedBillRow}
                        rows={accountList}
                        columns={columns}
                        isLast={isLast}
                        sortingColumns={[
                          '이용금액',
                          '크레딧 차감',
                          '청구금액',
                          '납부금액',
                          '미납금액',
                          '납부일자',
                        ]}
                        requestAction={onGetSomeOfBill}
                        onSortAsc={onSortAscChargedBill}
                        onSortDesc={onSortDescChargedBill}
                        sortingParam={sortingParam}
                        actualData={_accountList}
                        secondRequiredAction={handleManageBillModal}
                        modifyRow
                        gear
                        customClassName={
                          accountList && accountList.length > 13
                            ? 'chargedBill-table table-scrollable'
                            : 'chargedBill-table'
                        }
                        clickableIndex={[0, 11]}
                        shouldCovertToLocaleString
                      />
                      <Modal size="md" isOpen={shouldMsgModalOpen.isOpen}>
                        <ModalBody>
                          {shouldMsgModalOpen.complete ? (
                            <p className="msg-modal-description">
                              이미 '완납' 상태이므로 청구서 관리가 불가능합니다.
                            </p>
                          ) : (
                            <p className="msg-modal-description">
                              청구요금이 없어 청구서 관리가 불가능합니다.
                            </p>
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" onClick={handleMsgModalClose}>
                            확인
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                    <Modal size="lg" isOpen={manageBillModalOpen}>
                      <ModalHeader>청구서 관리</ModalHeader>
                      <ModalBody>
                        <div className="form-group m-form__group row">
                          <label
                            htmlFor="credit-reason"
                            className="col-lg-2 col-form-label"
                          >
                            아이디
                          </label>
                          <div className="col-lg-10">
                            <span className="form_text">
                              {rowData && rowData[0].email}
                            </span>
                          </div>
                        </div>
                        <div className="form-group m-form__group row">
                          <label
                            htmlFor="credit-reason"
                            className="col-lg-2 col-form-label"
                          >
                            청구년월
                          </label>
                          <div className="col-lg-10">
                            <span className="form_text">
                              {userInput.billDate}
                            </span>
                          </div>
                          
                        </div>
                        {/* a row for INPUT */}
                        <div className="form-group m-form__group row">
                          <label
                            htmlFor="credit-reason"
                            className="col-lg-2 col-form-label"
                          >
                            청구금액
                          </label>
                          <div className="col-lg-9">
                            <input
                              disabled
                              type="type"
                              className="form-control"
                              id="credit-reason"
                              value={
                                rowData ? findChargingPriceInRowData() : ''
                              }
                              required
                            />
                          </div>
                        </div>
                        {/* a row for INPUT */}
                        <div className="form-group m-form__group row">
                          <label
                            htmlFor="credit-reason"
                            className="col-lg-2 col-form-label"
                          >
                            납부금액 *
                          </label>
                          <div className="col-lg-9">
                            <input
                              onChange={handleInputChange}
                              type="number"
                              className="form-control"
                              id="payingAmount"
                              required
                            />
                            {errMsg && (
                              <div className="error message visible">
                                {errMsg.payingAmount}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* a row for INPUT */}
                        <div className="form-group m-form__group row">
                          <label
                            htmlFor="credit-reason"
                            className="col-lg-2 col-form-label"
                          >
                            미납금액
                          </label>
                          <div className="col-lg-9">
                            <input
                              disabled
                              //   onChange={handleInputChange}
                              type="type"
                              className="form-control"
                              id="credit-reason"
                              required
                              value={rowData ? rowData[5].unChargedPrice : ''}
                            />
                            {/* {errMsg && <div className="error message visible">{errMsg}</div>} */}
                          </div>
                        </div>
                        {userInput.isPay !== 'n' && userInput.isPay !== 'o' ? (
                          <div className="form-group m-form__group row">
                            <label
                              htmlFor="credit-reason"
                              className="col-lg-2 col-form-label"
                            >
                              납부일자
                            </label>
                            <div className="col-lg-9">
                              <input
                                onChange={handleSetPayingDate}
                                type="date"
                                className="form-control birthdaytime birthdaytime-mr-10"
                                id="birthdaytime-startDate"
                                name="birthdaytime"
                                max={moment().format('YYYY-MM-DD')}
                                value={payingDate}
                              />
                              {/* {errMsg && <div className="error message visible">{errMsg}</div>} */}
                            </div>
                          </div>
                        ) : null}
                        <div className="form-group m-form__group row">
                          <label
                            htmlFor="email-contents"
                            className="col-lg-2 col-form-label"
                          >
                            납부방법
                          </label>
                          <div className="col-lg-10">
                            <div className="m-radio-inline">
                              <label className="m-radio">
                                <input
                                  id="payMethod"
                                  value="c"
                                  onChange={handleInputChange}
                                  type="radio"
                                  name="method"
                                  checked={userInput.payMethod === 'c'}
                                />
                                카드
                                <span />
                              </label>
                              <label className="m-radio">
                                <input
                                  id="payMethod"
                                  value="v"
                                  onChange={handleInputChange}
                                  type="radio"
                                  name="method"
                                  checked={userInput.payMethod === 'v'}
                                />
                                무통장
                                <span />
                              </label>
                              <label className="m-radio">
                                <input
                                  id="payMethod"
                                  value="etc"
                                  onChange={handleInputChange}
                                  type="radio"
                                  name="method"
                                  checked={userInput.payMethod === 'etc'}
                                />
                                기타
                                <span />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group m-form__group row">
                          <label
                            htmlFor="email-contents"
                            className="col-lg-2 col-form-label"
                          >
                            납부상태
                          </label>
                          <div className="col-lg-10">
                            <div className="m-radio-inline">
                              <label className="m-radio">
                                <input
                                  id="isPay"
                                  value="n"
                                  onChange={handleInputChange}
                                  type="radio"
                                  name="status-radio"
                                  checked={userInput.isPay === 'n'}
                                />
                                미납
                                <span />
                              </label>
                              <label className="m-radio">
                                <input
                                  id="isPay"
                                  value="p"
                                  onChange={handleInputChange}
                                  type="radio"
                                  name="status-radio"
                                  checked={userInput.isPay === 'p'}
                                />
                                부분납
                                <span />
                              </label>
                              <label className="m-radio">
                                <input
                                  id="isPay"
                                  value="y"
                                  onChange={handleInputChange}
                                  type="radio"
                                  name="status-radio"
                                  checked={userInput.isPay === 'y'}
                                />
                                완납
                                <span />
                              </label>
                              <label className="m-radio">
                                <input
                                  id="isPay"
                                  value="o"
                                  onChange={handleInputChange}
                                  type="radio"
                                  name="status-radio"
                                  checked={userInput.isPay === 'o'}
                                />
                                연체
                                <span />
                              </label>
                            </div>
                          </div>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={updateAccountBill}>
                          적용
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => onHandleManageBillModal(false)}
                        >
                          닫기
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <MsgModal
                      onHandleCloseMsgModal={() => onGetBillError(false)}
                      isMsgModalOpen={isErrMsgModalOpen}
                      size="md"
                      onlyCanClose
                      content={
                        <div>
                          에러가 발생했습니다.
                          <br />
                          다시 시도해주세요.
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CurrentChargeDetail onGetBackToMainTable={onGetBackToMainTable} />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  columns: selectors.columnsSelector,
  detail: selectors.clickedIdSelector,
  accountList: selectors.accountListSelector,
  manageBill: selectors.manageBillSelector,
  dateSearchKeywords: selectors.dateSearchKeywordsSelector,
  isErrMsgModalOpen: selectors.isErrMsgModalOpenSelector,
  sortingParam: selectors.sortingParamSelector,
  _accountList: selectors._accountListSelector,
  isLast: selectors.isLastSelector,
  totalAmount: selectors.totalAmountSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onClickChargedBillRow: (id, arr) =>
    dispatch(actions.clickChargedBillRow(id, arr)),
  onGetBackToMainTable: () => dispatch(actions.getBackToMainTable()),
  onInitCurrentChargeList: () => dispatch(actions.initCurrentChargeList()),
  onGetBill: (obj) => dispatch(actions.getBill(obj)),
  onGetAccountBill: (id) => dispatch(actions.getAccountBill(id)),
  onGetSearchKeywords: (obj) => dispatch(actions.getSearchKeywords(obj)),
  onHandleManageBillModal: (id, obj) =>
    dispatch(actions.handleManageBillModal(id, obj)),
  onUpdateAccountBill: (obj) => dispatch(actions.updateAccountBill(obj)),
  onGetBillError: (obj) => dispatch(actions.getBillError(obj)),
  onSortAscChargedBill: (obj) => dispatch(actions.sortAscChargedBill(obj)),
  onSortDescChargedBill: (obj) => dispatch(actions.sortDescChargedBill(obj)),
  onGetSomeOfBill: (obj) => dispatch(actions.getSomeOfBill(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentCharge);
