export const INIT_NOTICE_LIST = "INIT_NOTICE_LIST";
export const INIT_NOTICE_LIST_BEFORE_SEARCHING =
  "INIT_NOTICE_LIST_BEFORE_SEARCHING";
export const GET_NOTICE_LIST = "GET_NOTICE_LIST";
export const GET_NOTICE_LIST_SUCCESS = "GET_NOTICE_LIST_SUCCESS";

export const CLICK_NOTICE_EDIT = "CLICK_NOTICE_EDIT";
export const CLICK_NOTICE_EDIT_SUCCESS = "CLICK_NOTICE_EDIT_SUCCESS";

export const CLICK_NOTICE_DELETE = "CLICK_NOTICE_DELETE";
export const CLICK_NOTICE_DELETE_SUCCESS = "CLICK_NOTICE_DELETE_SUCCESS";

export const POST_CREATE_NOTICE = "POST_CREATE_NOTICE";
export const POST_CREATE_NOTICE_SUCCESS = "POST_CREATE_NOTICE_SUCCESS";

export const GET_SEARCH_KEYWORDS = "GET_SEARCH_KEYWORDS";

export const SET_TRY_POST_TRUE = "SET_TRY_POST_TRUE";
export const SORT_ASC_NOTICE = "SORT_ASC_NOTICE";
export const SORT_DESC_NOTICE = "SORT_DESC_NOTICE";
export const HANDLE_ON_CHANGE = "HANDLE_ON_CHANGE";
export const SET_CREATE_MODE = "SET_CREATE_MODE";
export const SET_NOTICE_PARAMS = "SET_NOTICE_PARAMS";
export const DELETE_NOTICE_SUCCESS = "DELETE_NOTICE_SUCCESS";
export const HANDLE_OPEN_MSG_MODAL = "HANDLE_OPEN_MSG_MODAL";
export const GET_SEARCH_KEYWORDS_NOTICE = "GET_SEARCH_KEYWORDS_NOTICE";
export const HANDLE_ERRORS = "HANDLE_ERRORS";
export const SET_IS_NOTICE_CREATE_MODAL_CLOSE =
  'SET_IS_NOTICE_CREATE_MODAL_CLOSE';

// 사용??
export const HANDLE_CLOSE_MSG_MODAL = "HANDLE_CLOSE_MSG_MODAL";
export const CONFIRM_CHANGE_STATUS = "CONFIRM_CHANGE_STATUS";
export const GET_ACCOUNTS_ROWS = "GET_ACCOUNTS_ROWS";
export const GET_ACCOUNTS_ROWS_SUCCESS = "GET_ACCOUNTS_ROWS_SUCCESS";
export const INIT_ACCOUNT_LIST = "INIT_ACCOUNT_LIST";
export const INIT_DELETED_ACCOUNT_LIST = "INIT_DELETED_ACCOUNT_LIST";
export const GET_COLUMNS = "GET_COLUMNS";
export const HANDLE_OPEN_MODAL = "HANDLE_OPEN_MODAL";
export const CONFIRM_DELETE_ACCOUNT = "CONFIRM_DELETE_ACCOUNT";
export const SAVE_INPUT = "SAVE_INPUT";
