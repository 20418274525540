// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Octicon, { TriangleUp, TriangleDown } from '@primer/octicons-react';
import { GrDocumentText } from 'react-icons/gr';
import { FiMinusSquare, FiPlusSquare } from 'react-icons/fi';
import moment from 'moment';
import _ from 'lodash';
import clone from 'ramda/src/clone';

import MarkDownEditor from './MarkDownEditor';
import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import ButtonDropdown from '../../../fnComponents/ButtonDropdown';
import MsgModal from '../../../fnComponents/MsgModal';

import * as actions from './store/action';
import * as developerActions from '../store/action';
import * as selectors from './store/selector';

const GetStarted = ({
  // selector props
  topics,
  list,
  eachList,
  classParentList,
  document,
  errObj,
  versionList,
  isClassDone,
  isDocDone,
  isDeleteClassDone,
  isUpdateDocDone,
  isDeleteDocDone,
  isMakeTopicDone,
  isNewEachList,
  isDeleteTopicDone,
  // func props
  onDeleteClass,
  onGetDocument,
  onUpdateClass,
  onMakeDocumnet,
  onMakeDocumentErr,
  onUpdateOrder,
  onMakeClass,
  onUpdateDocument,
  onDeleteDocument,
  onMakeTopic,
  onDeleteTopic,
  onUpdateTopic,
  onGetDownloadTopic,
}) => {
  const [isClassModalOpen, setMakeClassModalOpen] = useState(false);
  const [isDocModalOpen, setMakeDocModalOpen] = useState(false);
  const [whichTopic, setClassModalToOpen] = useState(null);
  const [whichParent, setParent] = useState(null);
  const [inputs, setClassModalInput] = useState({});
  const [initItem, setInitItem] = useState('없음');
  const [docInitItem, setInitItemForDoc] = useState('없음');
  const [_content, setContent] = useState({
    title: '',
    content: '',
    url: '',
  });

  // const [isErr, setErrors] = useState(null)

  const [whichIsOpen, setWhichIsOpen] = useState(null);

  const handleClickTitle = (obj, topicsInDate, topicsTitle) => {
    // 수정 / 삭제 모달
    // 대분류 제목 클릭시 topicsTitle없음

    const {
      parentInDate,
      partition,
      title,
      inDate,
      withSubItems,
      description,
    } = obj;

    let filtered = undefined;
    let refiltered = undefined;

    if (topicsTitle) {
      filtered = _children[topicsTitle].filter((obj) => {
        return parentInDate === obj.inDate;
      }); // 상위 분류 필터링

      let secondClassArr = [];

      if (!filtered.length) {
        _children[topicsTitle].forEach((child) => {
          secondClassArr = [...secondClassArr, ...child.children];
        });
        refiltered = secondClassArr.filter(
          (obj) => obj.inDate === parentInDate
        );
      }
    }

    setWhichIsOpen({ title, partition, inDate, withSubItems }); // 클릭된 아이템이 소분류인지 문서인지 저장 --> 수정 모드로 진입
    switch (partition) {
      case 'topic':
        handleMakeTopicModal(true, rootTitle);
        setClassModalInput({
          title,
          description,
        });
        for (let key in eachList) {
          if (eachList[key].title === title) {
            setWhichIsOpen({
              title,
              partition,
              inDate,
              withSubItems,
              withClassesOrDocs: eachList[key].children.length ? true : false,
            });
          }
        }
        break;
      case 'class':
        setMakeClassModalOpen(true);
        setClassModalInput({ title });
        if (filtered.length) {
          setClassModalToOpen(parentInDate);
          setInitItem(filtered[0].title);
        } else if (!topicsInDate) {
          setClassModalToOpen(parentInDate);
        } else {
          setClassModalToOpen(topicsInDate);
        }
        break;
      case 'document':
        if (filtered.length) {
          setClassModalToOpen(parentInDate);
          setInitItemForDoc(filtered[0].title);
        } else if (refiltered.length) {
          setClassModalToOpen(parentInDate);
          setInitItemForDoc(refiltered[0].inDate);
        } else if (!topicsInDate) {
          setClassModalToOpen(parentInDate);
        } else setClassModalToOpen(topicsInDate);

        onGetDocument(inDate); // 문서의 inDate로 내용 로딩
        handleMakeDocModal(true, parentInDate);

        break;
      default:
        break;
    }
  };

  const [rootTitle, setRootTitle] = useState('시작하기');

  // 초기화 useEffect starts here

  useEffect(() => {
    if (isDeleteTopicDone) {
      setTopicModalOpen(false);
      setClassModalInput({});
      setWhichIsOpen(null);
      setErrors({});
      setModalsMsg({});
      setMsgModalOpen(false);
    }
  }, [isDeleteTopicDone]);

  useEffect(() => {
    if (isMakeTopicDone) {
      setTopicModalOpen(false);
      setClassModalInput({});
      setWhichIsOpen(null);
      setErrors({});
    }
  }, [isMakeTopicDone]);

  useEffect(() => {
    if (isClassDone) {
      // true일 때 소분류 생성 완료 --> 모달 비활성화 및 inDate 초기화
      setMakeClassModalOpen(false);
      setClassModalToOpen(null);
      setInitItem('없음');
      setWhichIsOpen(null);
      setErrors({});
    }
  }, [isClassDone]);

  useEffect(() => {
    if (isUpdateDocDone) {
      setMakeDocModalOpen(false);
      setContent({ title: '', content: '', url: '' });
      setInitItemForDoc('없음');
      setParent(null);
      setWhichIsOpen(null);
      setErrors({});
    }
  }, [isUpdateDocDone]);

  useEffect(() => {
    if (isDeleteDocDone) {
      setMsgModalOpen(false);
      setModalsMsg({});
      setMakeDocModalOpen(false);
      setContent({
        title: '',
        content: '',
        url: '',
      });
      setInitItemForDoc('없음');
      setParent(null);
      setClassModalToOpen(null);
      setWhichIsOpen(null);
    }
  }, [isDeleteDocDone]);

  useEffect(() => {
    if (isDocDone) {
      // 문서 등록 완료
      setMakeDocModalOpen(false);
      setContent({
        title: '',
        content: '',
        url: '',
      });
      setParent(null);
      setInitItemForDoc('없음');
      setClassModalToOpen(null);
      setWhichIsOpen(null);
      setErrors({});
      onMakeDocumentErr(null);
    }
  }, [isDocDone]);

  useEffect(() => {
    if (document) {
      setContent({
        ..._content,
        content: document.content,
        title: document.title,
        url: document.url,
      });
      setErrors({});
    }
  }, [document]);

  useEffect(() => {
    if (isDeleteClassDone) {
      setMsgModalOpen(false);
      // setClassModalToOpen(null);
      setModalsMsg({});
      setMakeClassModalOpen(false);
      setClassModalToOpen(null);
      setWhichIsOpen(null);
    }
  }, [isDeleteClassDone]);

  const handleUpAndDown = (e) => {
    const split = e.currentTarget.id.split('/');

    const inDate = split[1];
    const upDown = split[0];
    onUpdateOrder(inDate, upDown);
  };

  const [_children, setChildren] = useState([]);

  useEffect(() => {
    let tempObj = {};

    for (let key in eachList) {
      const { children } = eachList[key];

      const _cloned = clone(children);

      function rand(p_start, p_end) {
        var v_result = null;
        var v_size = p_end - p_start + 1;

        if (p_start < 0 || p_end < 0) return v_result;
        if (v_size < 2) return v_result;

        v_result = p_start + parseInt(Math.random() * v_size);

        return v_result;
      }
      function dummy(size) {
        var result = '';
        if (!size) size = 10;
        //0~9 : 48~57
        //a~z : 97~122
        //A~Z : 65~90
        for (var i = 0; i < size; i++) {
          var kind = rand(1, 3);
          switch (kind) {
            case 1:
              result += String.fromCharCode(rand(48, 57));
              break;
            case 2:
              result += String.fromCharCode(rand(65, 90));
              break;
            default:
              result += String.fromCharCode(rand(97, 122));
              break;
          }
        }
        return result;
      }

      if (_cloned) {
        const modArr = _cloned.map((obj, i) => {
          if (obj.children.length > 0) {
            // 특정 소분류의 서브 아이템에 대해서 hasParent 여부를 체크하는 로직 --> 해당 서브 아이템들은 항상 부모 요소?가 있는 상태이다
            const tempChildren = obj.children.map((_obj) => {
              return {
                ..._obj,
                children: _obj.children.map((_child) => ({
                  ..._child,
                  parentInDate: _obj.inDate,
                })),
                hasParent: true,
                parentInDate: obj.inDate,
                dummy: dummy(2),
              };
            });
            return {
              ...obj,
              withSubItems: true,
              toggle: false,
              i,
              hasParent: false,
              children: tempChildren,
              dummy: dummy(2),
            };
          } else return { ...obj, dummy: dummy(2) };
        });

        tempObj = {
          ...tempObj,
          [key]: modArr.map((obj, i) => {
            if (obj && obj.children.length) {
              const tempArr = obj.children.map((_child, i) => ({
                ..._child,
                parentInDate: obj.inDate,
                withSubItems: true,
                toggle: false,
                i,
                hasParent: true,
              }));

              return {
                ...obj,
                children: tempArr,
              };
            }
            return obj;
          }),
        };
        // setChildren({ ..._children, [key]: modArr });
      }
    }
    setChildren(tempObj);
  }, [eachList]);

  const handleClickIcons = (
    i,
    topicsTitle,
    childsInDate,
    _toggle,
    isClassDepth2
  ) => {
    // 클릭된 소분류의 인덱스를 알 수 있다
    // childsInDate === dummy

    const toggleDom = window.document.querySelector(
      `div#toggle-class-or-doc-${childsInDate}-${i}`
    );

    toggleDom.className = _toggle
      ? 'toggle-class-or-doc-hidden'
      : 'div.toggle-class-or-doc-display';

    if (isClassDepth2) {
      setChildren({
        ..._children,
        [topicsTitle]: _children[topicsTitle].map((child) => {
          if (child.title === isClassDepth2) {
            return {
              ...child,
              children: child.children.map((_child, index) => {
                if (index === i) {
                  return {
                    ..._child,
                    toggle: !_child.toggle,
                  };
                }
                return _child;
              }),
            };
          }
          return child;
        }),
      });
    } else
      setChildren({
        ..._children,
        [topicsTitle]: _children[topicsTitle].map((child, index) => {
          if (index === i) {
            return {
              ...child,
              toggle: !child.toggle,
            };
          } else return child;
        }),
      });

    //setSubItems({toggle: !isSubItems.toggle, i}) // 하위 소분류 또는 문서들 토글 키
  };

  const setIcons = (
    partition,
    withSubItems,
    i,
    toggle,
    childIndex,
    topicsTitle,
    childsInDate,
    isClassDepth2
  ) => {
    if (partition === 'document') return <GrDocumentText />;
    if (partition === 'class') {
      if (withSubItems) {
        if (toggle) {
          if (i === childIndex) {
            return (
              <FiMinusSquare
                onClick={() =>
                  handleClickIcons(
                    i,
                    topicsTitle,
                    childsInDate,
                    toggle,
                    isClassDepth2
                  )
                }
              />
            );
          } else {
            return (
              <FiPlusSquare
                onClick={() =>
                  handleClickIcons(
                    i,
                    topicsTitle,
                    childsInDate,
                    toggle,
                    isClassDepth2
                  )
                }
              />
            );
          }
        } else {
          return (
            <FiPlusSquare
              onClick={() =>
                handleClickIcons(
                  i,
                  topicsTitle,
                  childsInDate,
                  toggle,
                  isClassDepth2
                )
              }
            />
          );
        }
      } else {
        return (
          <FiMinusSquare
          // onClick={() => handleClickIcons(i, topicsTitle, childsInDate, 'test')}
          />
        );
      }
    }
  };

  const makeChildren = (arr, topicsTitle, upperClass) => {
    if (arr && arr.length) {
      return makeList2(
        arr.map((obj, i) => {
          if (obj.children.length > 0) {
            return {
              ...obj,
              i,
            };
          } else return obj;
        }),
        topicsTitle,
        upperClass
      );
    }
  };

  const makeList2 = (_children, topicsTitle, upperClass) => {
    // 소분류 하위의 서브 아이템 배열 (각 토픽 타이틀을 참조해서 setIcons로 보내줘야 함)
    return _children.map((child, i) => {
      const forKey = i;
      return (
        <div
          id="class-depth-2"
          key={forKey}
          className={
            child.withSubItems
              ? 'dev-getStarted-subItems clickable icon'
              : 'dev-getStarted-subItems'
          }
        >
          {setIcons(
            child.partition,
            child.withSubItems,
            i,
            child.toggle,
            child.i,
            topicsTitle,
            child.dummy,
            upperClass // depth 2 class를 식별하기 위한 키
          )}
          <span
            className={child.partition === 'document' ? 'clickable icon' : null}
            onClick={() => handleClickTitle(child, child.inDate, topicsTitle)}
            // onClick={() => handleClickTitle(child, null, topicsTitle)}
          >
            &nbsp;&nbsp;{child.title}
          </span>
          <div className="upAndDown-triangle">
            <span onClick={handleUpAndDown} id={`down/${child.inDate}`}>
              <Octicon
                id="down"
                verticalAlign="text-top"
                icon={TriangleDown}
              ></Octicon>
            </span>
            <span onClick={handleUpAndDown} id={`up/${child.inDate}`}>
              <Octicon
                // className={label}
                id="up"
                verticalAlign="text-top"
                icon={TriangleUp}
              ></Octicon>
            </span>
          </div>
          {/* + 아이콘 클릭 시 하위 소분류 또는 문서들이 토글될 위치 */}
          <div
            id={`toggle-class-or-doc-${child.dummy}-${i}`}
            // id={`toggle-class-or-doc-${topicsTitle}-${i}`}
            className="toggle-class-or-doc-hidden"
          >
            {makeChildren(child.children, topicsTitle, child.title)}
          </div>
        </div>
      );
    });
  };
  const makeList = (topicsInDate, title) => {
    if (_children)
      return _children[title].map((child, i) => {
        const forKey = i;
        return (
          <div id="class-depth-1" className="clickable icon" key={forKey}>
            {setIcons(
              child.partition,
              child.withSubItems,
              i,
              child.toggle,
              child.i,
              title,
              child.dummy
            )}
            <span
              className={
                child.partition === 'document' ? 'clickable icon' : null
              }
              onClick={() => handleClickTitle(child, topicsInDate, title)}
            >
              &nbsp;&nbsp;{child.title}
            </span>
            <div className="upAndDown-triangle">
              <span onClick={handleUpAndDown} id={`down/${child.inDate}`}>
                <Octicon
                  id="down"
                  verticalAlign="text-top"
                  icon={TriangleDown}
                ></Octicon>
              </span>
              <span onClick={handleUpAndDown} id={`up/${child.inDate}`}>
                <Octicon
                  // className={label}
                  id="up"
                  verticalAlign="text-top"
                  icon={TriangleUp}
                ></Octicon>
              </span>
            </div>
            {/* + 아이콘 클릭 시 하위 소분류 또는 문서들이 토글될 위치 */}
            <div
              id={`toggle-class-or-doc-${child.dummy}-${i}`}
              className="toggle-class-or-doc-hidden"
            >
              {makeChildren(child.children, title, child.title)}
            </div>
          </div>
        );
      });
  };

  const [isTopicModalOpen, setTopicModalOpen] = useState(false);

  const handleMakeTopicModal = (isOpen, rootTitle) => {
    setErrors({});
    setWhichIsOpen(null);
    setTopicModalOpen(isOpen);
  };

  const handleCloseMakeTopicModal = () => {
    setErrors({});
    setTopicModalOpen(false);
    setClassModalInput({});
  };
  const handleMakeTopic = () => {
    const { title, description } = inputs;

    if (!title) {
      setErrors({
        title: '분류명을 입력해주세요.',
      });
      return;
    }

    if (whichIsOpen) {
      const { inDate } = whichIsOpen;
      if (inDate) {
        onUpdateTopic(inDate, { title, description });
        //  onUpdateTopic(inDate, { rootTitle, title, description });
        setClassModalInput({}); // 초기화
        setErrors({});
        return;
      }
    }

    onMakeTopic({ rootTitle, title, description });

    setClassModalInput({}); // 초기화
    setErrors({});
  };

  const handleClassModalInput = (e) => {
    setClassModalInput({ ...inputs, [e.target.id]: e.target.value });
  };

  const handleMakeClassModal = (isOpen, inDate) => {
    setMakeClassModalOpen(isOpen);
    setClassModalToOpen(inDate);
    setWhichIsOpen(null);
    setInitItem('없음');
    setInitItemForDoc('없음');
    setClassModalInput({});
    setErrors({});
  };

  const handleMakeDocModal = (isOpen, _whichParent) => {
    setMakeDocModalOpen(true);
    setParent(_whichParent);
    setErrors({});
  };

  const handleDownloadClick = (inDate, filename) => {
    onGetDownloadTopic(inDate, filename);
  };

  const [errors, setErrors] = useState({});

  const handleValidateUserInput = (type) => {
    let isErr = false;
    let _errors = {};
    switch (type) {
      case 'class':
        if (!inputs.title) {
          setErrors({ class: '분류명을 입력해주세요.' });
          return true;
        }
        break;

      case 'document':
        if (!_content.title) {
          _errors = {
            ..._errors,
            documentTitle: '문서명을 입력해주세요.',
          };
          isErr = true;
        }
        if (!_content.content) {
          _errors = {
            ..._errors,
            documentContent: '내용을 입력해주세요.',
          };
          isErr = true;
        }
        if (!_content.url) {
          _errors = {
            ..._errors,
            documentUrl: 'URL을 입력해주세요.',
          };
          isErr = true;
        }
        if (_content.url) {
          const regExp = /^[/]/;
          const _test = regExp.test(_content.url);
          if (!_test) {
            _errors = {
              ..._errors,
              documentUrl: `URL은 " / "로 시작해야 합니다.`,
            };
            isErr = true;
          }
          const regExp2 = /[/]$/;
          if (_content.url.match(regExp2)) {
            _content.url = _.trimEnd(_content.url, '/');
          }
        }
        break;

      default:
        return false;
    }

    if (isErr) {
      setErrors(_errors);
      return isErr;
    }
  };

  const handleMakeClass = () => {
    // 사용자 입력 검증
    const isErr = handleValidateUserInput('class');

    if (isErr) return;

    // 수정 모드 일 때, 해당 소분류(클래스)의 inDate 필요
    if (whichIsOpen) {
      onUpdateClass(
        {
          title: inputs.title,
          parentInDate: whichTopic,
        },
        whichIsOpen.inDate
      );
      return;
    }

    // 상위분류 === 없음 일 때는 topic의 inDate를 사용하고 특정 소분류가 선택되었을 때는 해당 소분류의 inDate를 사용
    onMakeClass({ parentInDate: whichTopic, title: inputs.title });
  };

  const [isMsgModalOpen, setMsgModalOpen] = useState(false);
  const [modalsMsg, setModalsMsg] = useState({});

  const handleCloseMsgModal = () => {
    setMsgModalOpen(false);
  };

  const handleDeleteClassModal = async () => {
    // 하위분류 또는 문서 존재 여부 확인
    const { withSubItems, partition, withClassesOrDocs } = whichIsOpen;

    if (withSubItems) {
      setModalsMsg({
        msg: '하위분류 또는 문서가 존재해 삭제할 수 없습니다.',
        withSubItems,
      });
    } else if (withClassesOrDocs) {
      setModalsMsg({
        msg: '소분류 또는 문서가 존재해 삭제할 수 없습니다.',
        withSubItems: true,
      });
    } else if (partition === 'topic') {
      setModalsMsg({ msg: '삭제하시겠습니까 topic?', isTopic: true });
    } else {
      setModalsMsg({ msg: '삭제하시겠습니까?', withSubItems });
    }

    setMsgModalOpen(true);
  };

  const handleDeleteTopic = () => {
    onDeleteTopic(whichIsOpen.inDate);
  };

  const handleDeleteClass = () => {
    // 삭제 ACTION
    onDeleteClass(whichIsOpen.inDate); // 해당 소분류(클래스)의 inDate
  };

  const handleDeleteDocumentModal = () => {
    setModalsMsg({ msg: '삭제하시겠습니까?', isDocument: true });

    setMsgModalOpen(true);
  };

  const handleDeleteDocument = () => {
    // 삭제 ACTION
    onDeleteDocument(whichIsOpen.inDate); // 해당 소분류(클래스)의 inDate
  };

  const handleDocModalInput = (e) => {
    setContent({
      ..._content,
      [e.target.id]: e.target.value,
    });
  };

  const handleMakeDocument = () => {
    const isErr = handleValidateUserInput('document');
    // 선택되지 않았을 때 ('없음') falsy 값일 때 대분류의 inDate를 할당 (parentInDate: topic's inDate)
    if (isErr) return;

    if (whichIsOpen) {
      const { inDate } = whichIsOpen;

      onUpdateDocument({ ..._content, parentInDate: whichTopic }, inDate);
      return;
    }

    onMakeDocumnet({
      ..._content,
      parentInDate: whichTopic || whichParent,
    });
  };

  const handleChangeBtnDropdown = (parentInDate) => {
    setClassModalToOpen(parentInDate); // 기존의 topic의 inDate를 담던 useState 함수
  };

  const makeTopics = (data) => {
    return data.map((row, i) => {
      const forKey = i;
      return (
        // {/* topic 할당 엘리먼트 시작 */}
        <div key={forKey} className="m-portlet">
          <div className="m-portlet__body">
            <div
              className="clickable icon"
              onClick={() => handleClickTitle(row)}
            >
              <h4>{row.title}</h4>
            </div>
            {/*  */}
            {makeList(row.inDate, row.title)}
            {/* topic의 inDate를 저장하기 위한 파라미터 전달 */}
            {/*  */}
            <ButtonGroup>
              <Button
                id="open"
                onClick={() => handleMakeClassModal(true, row.inDate)}
              >
                소분류 등록
              </Button>
              <Button
                id="open"
                onClick={() => handleMakeDocModal(true, row.inDate)}
              >
                문서 등록
              </Button>
              <Button
                id="open"
                onClick={() => handleDownloadClick(row.inDate, row.title)}
              >
                다운로드
              </Button>
            </ButtonGroup>
          </div>
        </div>
        // {/* topic 할당 엘리먼트 끝 */}
      );
    });
  };

  const handleChange = (content) => {
    setContent({ ..._content, content });
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title ">시작하기</h3>
              </div>
            </div>
          </div>
          <div className="m-content clearfix">
            <Button
              className="float-right"
              color="primary"
              onClick={() => handleMakeTopicModal(true, rootTitle)}
            >
              대분류 등록
            </Button>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">{makeTopics(topics)}</div>
              {/* 확인 메시지 모달 */}
              <MsgModal
                content={modalsMsg.msg}
                isMsgModalOpen={isMsgModalOpen}
                onlyCanClose={modalsMsg.withSubItems}
                onHandleCloseMsgModal={handleCloseMsgModal}
                onHandleDelete={
                  !modalsMsg.withSubItems
                    ? modalsMsg.isDocument
                      ? handleDeleteDocument
                      : modalsMsg.isTopic
                      ? handleDeleteTopic
                      : handleDeleteClass
                    : null
                }
              />

              {/* 대분류 등록 모달 */}
              <Modal isOpen={isTopicModalOpen} size="lg">
                <ModalHeader>
                  {whichIsOpen && whichIsOpen.partition === 'topic'
                    ? '대분류 수정/삭제'
                    : '대분류 등록'}
                </ModalHeader>
                <ModalBody>
                  <div className="required form-group m-form__group row">
                    <label
                      htmlFor="creditAmount"
                      className="col-lg-2 col-form-label"
                    >
                      분류명 *
                    </label>
                    <div className="col-lg-9">
                      <input
                        onChange={handleClassModalInput} // 소분류 함수 사용
                        type="type"
                        className="form-control"
                        id="title"
                        required
                        value={inputs.title}
                      />
                      {errors && errors.title ? (
                        <div className="error message visible">
                          {errors.title}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="required form-group m-form__group row">
                    <label
                      htmlFor="creditAmount"
                      className="col-lg-2 col-form-label"
                    >
                      설명
                    </label>
                    <div className="col-lg-9">
                      <input
                        onChange={handleClassModalInput} // 소분류 함수 사용
                        type="type"
                        className="form-control"
                        id="description"
                        required
                        value={inputs.description}
                      />
                      {/* {isErr.err ? (
              <div className="error message visible">
                {isErr.errRes.creditAmount}
              </div>
            ) : null} */}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={handleMakeTopic}>
                    확인
                  </Button>
                  <Button
                    id="close"
                    color="primary"
                    onClick={() => handleCloseMakeTopicModal()}
                  >
                    취소
                  </Button>
                  {whichIsOpen && whichIsOpen.partition === 'topic' ? (
                    <Button
                      color="danger"
                      className="float-right"
                      onClick={handleDeleteClassModal}
                    >
                      삭제
                    </Button>
                  ) : null}
                </ModalFooter>
              </Modal>

              {/* 소분류 등록 모달 */}
              <Modal isOpen={isClassModalOpen} size="lg">
                <ModalHeader>
                  {whichIsOpen && whichIsOpen.partition === 'class'
                    ? '소분류 수정/삭제'
                    : '소분류 등록'}
                </ModalHeader>
                <ModalBody>
                  <div className="required form-group m-form__group row">
                    <label
                      htmlFor="creditAmount"
                      className="col-lg-2 col-form-label"
                    >
                      분류명
                    </label>
                    <div className="col-lg-9">
                      <input
                        onChange={handleClassModalInput}
                        type="type"
                        className="form-control"
                        id="title"
                        required
                        value={inputs.title}
                      />
                      {errors.class ? (
                        <div className="error message visible">
                          {errors.class}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="required form-group m-form__group row">
                    <label
                      htmlFor="creditAmount"
                      className="col-lg-2 col-form-label"
                    >
                      상위분류
                    </label>
                    {/* classParentList 필요 */}
                    <div className="col-lg-9">
                      <ButtonDropdown
                        customKey="classParentList"
                        initItem={initItem} // name을 전달?
                        dropdownItems={classParentList}
                        onChangeBtnDropdown={handleChangeBtnDropdown}
                      />
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={handleMakeClass}>
                    확인
                  </Button>
                  <Button
                    id="close"
                    color="primary"
                    onClick={() => handleMakeClassModal(false, null)}
                  >
                    취소
                  </Button>
                  {whichIsOpen && whichIsOpen.partition === 'class' ? (
                    <Button
                      color="danger"
                      className="float-right"
                      onClick={handleDeleteClassModal}
                    >
                      삭제
                    </Button>
                  ) : null}
                </ModalFooter>
              </Modal>

              {/* 문서 등록 모달 */}
              <Modal isOpen={isDocModalOpen} size="xl">
                <ModalHeader>
                  {whichIsOpen && whichIsOpen.partition === 'document'
                    ? '문서 수정/삭제'
                    : '문서 등록'}
                </ModalHeader>
                <ModalBody>
                  {/* {whichParent} */}
                  <div className="required form-group m-form__group row">
                    <label
                      htmlFor="creditAmount"
                      className="col-lg-2 col-form-label"
                    >
                      문서명
                    </label>
                    <div className="col-lg-7">
                      <input
                        onChange={handleDocModalInput}
                        type="type"
                        className="form-control"
                        id="title"
                        required
                        value={_content.title}
                      />
                      {errors.documentTitle ? (
                        <div className="error message visible">
                          {errors.documentTitle}
                        </div>
                      ) : null}
                    </div>
                    <label
                      htmlFor="creditAmount"
                      className="col-lg-2 col-form-label"
                    >
                      최종 수정일시 :{' '}
                      {document && document.lastModified
                        ? moment(document.lastModified).format(
                            'YYYY.MM.DD HH:mm'
                          )
                        : null}
                    </label>
                  </div>
                  <div className="required form-group m-form__group row">
                    <label
                      htmlFor="creditAmount"
                      className="col-lg-2 col-form-label"
                    >
                      분류
                    </label>
                    {/* classParentList 필요 */}
                    <div className="col-lg-9">
                      <ButtonDropdown
                        customKey="classParentList"
                        initItem={docInitItem}
                        dropdownItems={classParentList}
                        onChangeBtnDropdown={handleChangeBtnDropdown}
                      />
                    </div>
                  </div>

                  <MarkDownEditor
                    handleChange={handleChange}
                    _content={_content}
                  />
                  {errors.documentContent ? (
                    <div className="error message visible">
                      {errors.documentContent}
                    </div>
                  ) : null}
                  <br></br>
                  <div className="required form-group m-form__group row">
                    <label
                      htmlFor="creditAmount"
                      className="col-lg-2 col-form-label"
                    >
                      URL
                    </label>
                    <div className="col-lg-9">
                      <input
                        onChange={handleDocModalInput}
                        type="type"
                        className="form-control"
                        id="url"
                        required
                        value={_content.url}
                      />
                      {errors.documentUrl ? (
                        <div className="error message visible">
                          {errors.documentUrl}
                        </div>
                      ) : errObj && errObj.urlErr ? (
                        <div className="error message visible">
                          {errObj.errMsg}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={handleMakeDocument}>
                    확인
                  </Button>
                  <Button
                    id="close"
                    color="primary"
                    onClick={() => {
                      setContent({ title: '', content: '', url: '' });
                      setWhichIsOpen(null);
                      setMakeDocModalOpen(false);
                      setParent(null);
                      setClassModalToOpen(null);
                      onMakeDocumentErr(null);
                      setInitItemForDoc('없음');
                    }}
                  >
                    취소
                  </Button>
                  {whichIsOpen && whichIsOpen.partition === 'document' ? (
                    <Button
                      color="danger"
                      className="float-right"
                      onClick={handleDeleteDocumentModal}
                    >
                      삭제
                    </Button>
                  ) : null}
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  // showcaseList: selectors.showcaseListSelectorForView,
  topics: selectors.topicsSelector,
  list: selectors.listSelector,
  classParentList: selectors.classParentListSelector,
  document: selectors.documentSelector,
  versionList: selectors.versionListSelector,
  isClassDone: selectors.isClassDoneSelector,
  isDocDone: selectors.isDocDoneSelector,
  isDeleteClassDone: selectors.isDeleteClassDoneSelector,
  isUpdateDocDone: selectors.isUpdateDocDoneSelector,
  isDeleteDocDone: selectors.isDeleteDocDoneSelector,
  isMakeTopicDone: selectors.isMakeTopicDoneSelector,
  isDeleteTopicDone: selectors.isDeleteTopicDoneSelector,
  eachList: selectors.eachListSelector,
  isNewEachList: selectors.isNewEachListSelector,
  testSelector: selectors.testSelector,
  errObj: selectors.errObjSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onMakeClass: (data) => dispatch(actions.makeClass(data)),
  onUpdateClass: (obj, inDate) => dispatch(actions.updateClass(obj, inDate)),
  onDeleteClass: (inDate) => dispatch(actions.deleteClass(inDate)),
  onMakeDocumnet: (data) => dispatch(actions.makeDocument(data)),
  onUpdateDocument: (data, inDate) =>
    dispatch(actions.updateDocument(data, inDate)),
  onDeleteDocument: (inDate) => dispatch(actions.deleteDocument(inDate)),
  onGetDocument: (inDate) => dispatch(actions.getDocument(inDate)),
  onUpdateOrder: (inDate, upDown) =>
    dispatch(actions.updateOrder(inDate, upDown)),
  onMakeTopic: (data) => dispatch(actions.makeTopic(data)),
  onMakeDocumentErr: (data) => dispatch(actions.makeDocumentErr(data)),
  onDeleteTopic: (inDate) => dispatch(actions.deleteTopic(inDate)),
  onUpdateTopic: (inDate, data) => dispatch(actions.updateTopic(inDate, data)),
  onGetDownloadTopic: (inDate, filename) =>
    dispatch(developerActions.getDownloadTopic({ inDate, filename })),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted);
