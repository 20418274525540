import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';

import Header from '../../view/header';
import LeftAsideMunu from '../../view/leftAsideMenu';
import { getCsvData } from './store/action';

import json2csv, { download } from '../../utils/json2csv';

const DataExtraction = () => {
  const dispatch = useDispatch();

  const handleGetCsvData = (e, obj) => {
    const { name } = e.target;
    dispatch(getCsvData(name));
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <LeftAsideMunu />
        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title m-subheader__title--separator">
                  데이터 추출
                </h3>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <div className="data-extraction-container">
                      <div className="item">
                        <div>전체 계정 현황</div>

                        <Button name="totalAccount" onClick={handleGetCsvData}>
                          CSV 다운로드
                        </Button>
                      </div>
                      <div className="item">
                        <div>메일 수신 동의 계정</div>
                        <Button name="mail" onClick={handleGetCsvData}>
                          CSV 다운로드
                        </Button>
                      </div>
                      <div className="item">
                        <div>계정 퍼널 및 요금</div>
                        <Button name="account" onClick={handleGetCsvData}>
                          CSV 다운로드
                        </Button>
                      </div>
                      <div className="item">
                        <div>프로젝트 요금</div>
                        <Button name="game" onClick={handleGetCsvData}>
                          CSV 다운로드
                        </Button>
                      </div>
                      <div className="item">
                        <div>프로젝트 MAU</div>
                        <Button name="mau" onClick={handleGetCsvData}>
                          CSV 다운로드
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataExtraction;
