import { createSelector } from "reselect";

// import aws from 'aws-sdk';
// import moment from 'moment';

export const clickedIdSelector = createSelector(
  state => state.emails.detail,
  detail => detail
);

export const columnsSelector = createSelector(
  state => state.emails.columns,
  columns => columns
);

export const mailingConditionsSelector = createSelector(
  state => state.emails.emailList,
  emailList =>
    emailList.map(email => ({
      type: email.type,
      emailInDate: { noDisplay: true, emailInDate: email.inDate },
      condition: email.condition,
      using: email.using
    }))
);

export const contentsSelector = createSelector(
  state => state.emails.email,
  email => email.contents
);

export const emailEtcSelector = createSelector(
  state => state.emails.email,
  email => ({
    title: email.title,
    type: email.type,
    emailInDate: email.emailInDate
  })
);

export const isMsgModalOpenSelector = createSelector(
  state => state.coupon.isMsgModalOpen,
  isMsgModalOpen => isMsgModalOpen
);
