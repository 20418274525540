import { requestGet } from "../../utils/request";
import Qs from "qs";

export const getMonthlyStatistics = () => {
  console.log("getMonthlyStatistics");
  //   const url = "statistics/total/monthly";
  const url = "statistics/total/v1.1/monthly";
  return requestGet(url);
};

export const getDailyStatistics = params => {
  const url = "statistics/total/v1.1/daily";

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: "brackets" });
    }
  };

  return requestGet(url, options);
};
