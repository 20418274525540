const validationCheck = (obj, paramSet) => {
	try {
		delete obj.type;
		const { emptyCheck, rangeCheck } = paramSet;

		let validationRes = {
			errArr: [],
		};

		for (let key in obj) {
			validationRes = {
				...validationRes,
				[key]: [
					obj[key].rangeCheck
						? rangeCheck(obj[key][key], {
								startPoint: obj[key].startPoint,
								endPoint: obj[key].endPoint,
						  })
						: null,
					emptyCheck(obj[key][key]),
				],
			};
		}

		for (const key in validationRes) {
			if (key !== 'errArr') {
				const filtered = validationRes[key].filter((item) => !!item);
				validationRes.errArr = [...validationRes.errArr, !!filtered.length];
			}
		}

		if (validationRes.errArr.includes(true)) return validationRes;
	} catch (error) {
		console.log(error, '에러발생');
		throw error;
	}
};

export default validationCheck;
