import { select, call, put, takeLatest } from "redux-saga/effects";
import { LOCATION_CHANGE, push } from "react-router-redux";

import moment from "moment";
import { openSubMenu, handleClickSubMenu } from "../../../../view/store/action";
import * as types from "./constant";

import aws from "aws-sdk";

import * as actions from "./action";
import * as selectors from "./selector";
import * as api from "../../../../service/API";

function* initOverdueBillList({ payload }) {
  try {
    const { pathname } = payload;

    const pathArr = pathname.split("/");

    if (pathArr[1] === "overdueBill") {
      yield put(actions.getOverdueBill());
      yield put(openSubMenu("payment"));
      yield put(handleClickSubMenu("overdueBill"));
    }
  } catch (error) {}
}

export function* watcherInitOverdueBillList() {
  yield takeLatest(LOCATION_CHANGE, initOverdueBillList);
}

function* getOverdueBill() {
  try {
    const searchingKeywords = yield select(selectors.searchKeywordsSelector);
    const lastEvaluatedKey = yield select(selectors.lastEvaluatedKeySelector);
    console.log(searchingKeywords, "!!!searchingKeywords");
    const { id, companyName, status, startDate, endDate } = searchingKeywords;

    let _date;
    // const isSearching = Object.keys(searchingKeywords).includes("date");

    if (!startDate && !endDate) {
      _date = {
        startDate: undefined,
        endDate: undefined
      };
    } else {
      _date = {
        startDate: startDate.set({ hour: 0 }).toISOString(),
        endDate: endDate.toISOString()
      };
    }
    const {
      data: { LastEvaluatedKey, Items }
    } = yield call(
      api.payment.getOverdueBill,
      // _date,
      {
        ..._date,
        email: id,
        companyName: companyName,
        status:
          status === "연체중" ? "o" : status === "납부완료" ? "y" : undefined,
        LastEvaluatedKey: lastEvaluatedKey || undefined
      }
    );

    const rows = Items.map(item => {
      const _item = aws.DynamoDB.Converter.unmarshall(item);
      return {
        accountEmail: _item.accountEmail,
        accountCompanyName: _item.accountCompanyName,
        overdueAmount: _item.overdueAmount,
        inDate: moment(_item.inDate).format("YYYY.MM.DD"),
        overdueReleasedDate: _item.overdueReleasedDate,
        status:
          _item.status === "o" || _item.status === "p" ? "연체중" : "납부완료"
      };
    });

    yield put(actions.getOverdueBillSuccess(rows, LastEvaluatedKey));
  } catch (error) {
    console.log("ERR: GET_CURRENT_BILL", error && error.response);
    yield put(actions.getOverdueBillSuccess([]));
  }
}

export function* watcherGetOverdueBill() {
  yield takeLatest(types.GET_OVERDUE_BILL, getOverdueBill);
}

// function* initDeletedAccountList({ gameId }) {
// 	try {

//     } catch (error) {

//     }
// }

// export function* watcherInitDeletedAccountList() {
// 	yield takeLatest(types., initDeletedAccountList);
// }
