import { LOCATION_CHANGE } from "react-router-redux";

// import * as selectors from './selector';
import * as types from "./constant";

const initialState = {
  noticeList: [],
  columns: [
    { label: '분류', field: 'type' },
    { label: '제목', field: 'title' },
    { label: '작성자', field: 'admin_name' },
    { label: '등록일시', field: 'indate' },
    { label: '조회', field: 'views' },
    { label: '상태', field: 'status' },
    { label: '언어', field: 'language' },
    { label: '노출', field: 'display' },
    { label: '', field: 'delete' },
  ],
  detailColumns: [],
  detail: { clickedId: '', editable: false },
  sortingParam: false,
  searchKeywords: {},
  errMsg: {},
  importantNoticeList: [],
  lastEvaluatedKey: undefined,
  selectedNotice: {},
};

function notice(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...initialState,
      };

    case types.GET_COLUMNS:
      return {
        ...state,
        columns: action.columns,
      };

    case types.GET_NOTICE_LIST_SUCCESS:
      const { notPaging } = action;
      if (notPaging === 'notPaging') {
        return {
          ...state,
          noticeList: action.noticeList,
          importantNoticeList: action.importantList,
          isNoticeCreateModalClose: false,
          lastEvaluatedKey: action.lastEvaluatedKey,
        };
      }
      return {
        ...state,
        noticeList: [...state.noticeList, ...action.noticeList],
        importantNoticeList: action.importantList,
        isNoticeCreateModalClose: false,
        lastEvaluatedKey: action.lastEvaluatedKey,
      };

    case types.POST_CREATE_NOTICE:
      return {
        ...state,
        contentInput: action.content,
        tryPost: true,
      };
    case types.POST_CREATE_NOTICE_SUCCESS:
      return {
        ...state,
        isNoticeCreateModalClose: true,
        noticeList: [],
      };
    case types.SET_IS_NOTICE_CREATE_MODAL_CLOSE:
      return {
        ...state,
        isNoticeCreateModalClose: action.isNoticeCreateModalClose,
      };
    case types.HANDLE_ON_CHANGE:
      return {
        ...state,
        content: action.value,
        errMsg: {},
      };
    case types.SET_TRY_POST_TRUE:
      return {
        ...state,
        tryPost: true,
        noticeParams: action.obj,
      };

    case types.HANDLE_CLOSE_MSG_MODAL:
      return {
        ...state,
        isMsgModalOpen: false,
      };

    case types.CONFIRM_CHANGE_STATUS:
      return {
        ...state,
        detail: { clickedId: '', billManage: false },
      };
    case types.SORT_ASC_NOTICE:
      return {
        ...state,
        noticeList: action.rows,
        sortingParam: true,
      };
    case types.SORT_DESC_NOTICE:
      return {
        ...state,
        noticeList: action.rows,
        sortingParam: false,
      };

    case types.CLICK_NOTICE_EDIT:
      const { id } = action.arr[11].id;
      const { isImportant } = action.arr[3].isImportant;

      const list =
        isImportant === 'n' ? state.noticeList : state.importantNoticeList;

      return {
        ...state,
        selectedNotice: list.find((notice) => notice.id === id),
        isEditMode: true,
        content: action.content.content,
      };

    case types.SET_CREATE_MODE:
      return {
        ...state,
        isEditMode: false,
        selectedNotice: {
          content: '',
          title: '',
          isPublic: 'y',
          isImportant: false,
          type: '공지',
          language: '국문',
          isTest: 'y',
        },
      };
    case types.SET_NOTICE_PARAMS:
      return {
        ...state,
        errMsg: {},
        selectedNotice: {
          ...state.selectedNotice,
          ...action.obj,
        },
      };

    case types.DELETE_NOTICE_SUCCESS:
      return {
        ...state,
        isDeleteDone: true,
      };

    case types.HANDLE_OPEN_MSG_MODAL:
      return {
        ...state,
        isDeleteDone: false,
      };
    case types.GET_SEARCH_KEYWORDS_NOTICE:
      return {
        ...state,
        searchKeywords: {
          ...state.searchKeywords,
          ...action.obj,
        },
      };
    case types.HANDLE_ERRORS:
      return {
        ...state,
        errMsg: {
          ...state.errMsg,
          ...action.obj,
        },
      };
    case types.INIT_NOTICE_LIST_BEFORE_SEARCHING:
      return {
        ...state,
        noticeList: [],
        importantNoticeList: [],
        lastEvaluatedKey: undefined,
      };

    default:
      return state;
  }
}

export default notice;
