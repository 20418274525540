import * as types from "./constant";

export function handleLogin(params, isChecked) {
  return {
    type: types.HANDLE_LOGIN,
    loginInfo: params,
    isChecked
  };
}
export function handleLoginError({ errMsg }) {
  return {
    type: types.HANDLE_LOGIN_ERROR,
    errMsg
  };
}

export function loginSuccess(params) {
  return {
    type: types.HANDLE_LOGIN_SUCCESS,
    loginInfo: params
  };
}

export function handleLogout() {
  return {
    type: types.HANDLE_LOGOUT
    // loginInfo: params,
  };
}

export function checkToSaveId(isChecked) {
  return {
    type: types.CHECK_TO_SAVE_ID,
    isChecked
  };
}

export function getUserNameSuccess(userName) {
  return {
    type: types.GET_USERNAME_SUCCESS,
    userName
  };
}
