// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { requestGet, requestPost } from '../../utils/request';

export const getEmailList = () => {
  const url = 'email';

  return requestGet(url);
};

export const getEmail = (emailInDate) => {
  const url = `email/${emailInDate}`;

  return requestGet(url);
};

export const updateEmailUsing = (emailInDate, data) => {
  const url = `email/${emailInDate}/using`;

  return requestPost(url, data);
};

export const updateEmail = (emailInDate, data) => {
  const url = `email/${emailInDate}`;

  return requestPost(url, data);
};
