// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { createSelector } from 'reselect';
import aws from 'aws-sdk';
import moment from 'moment';
import BigNumber from 'bignumber.js';

export const clickedIdSelector = createSelector(
  (state) => state.projectManaging.detail,
  (detail) => detail
);

export const columnsSelector = createSelector(
  (state) => state.projectManaging,
  (projectManaging) => {
    if (projectManaging.deletedProjects) {
      return [
        {
          label: '프로젝트',
          field: 'project',
          sort: 'asc',
          width: 10,
        },
        {
          label: '아이디',
          field: 'id',
          sort: 'asc',
          width: 10,
        },
        {
          label: '회사명',
          field: 'company',
          sort: 'asc',
          width: 10,
        },
        {
          label: '생성일시',
          field: 'inDate',
          sort: 'asc',
          width: 10,
        },
        {
          label: '삭제일시',
          field: 'deletedDate',
          sort: 'asc',
          width: 10,
        },
        {
          label: '최종요금제',
          field: 'plan',
          sort: 'asc',
          width: 10,
        },
      ];
    }
    return projectManaging.columns;
  }
);

export const projectListSelectorForView = createSelector(
  //   (state) => state.projectManaging.projectList,
  (state) => state.projectManaging,
  ({ projectList, deletedProjects }) => {
    // console.log(projectList, "!@#");
    if (!deletedProjects)
      return projectList.map((pjt) => ({
        check: '',
        title: pjt.title,
        id: pjt.id,
        clientAppId: pjt.clientAppId,
        companyName: pjt.companyName,
        inDate: moment(pjt.inDate).format('YYYY.MM.DD HH:mm'),
        avgDAU: pjt.avgDAU,
        plan:
          pjt.plan !== 'dau'
            ? pjt.plan === 'silver'
              ? '실버패스'
              : pjt.plan === 'gold'
              ? '골드패스'
              : pjt.plan === 'diamond'
              ? '다이아패스'
              : pjt.plan === 'develop'
              ? '개발 모드'
              : pjt.plan === 'developPlus'
              ? '개발 모드 Plus'
              : pjt.plan === 'basic'
              ? '베이직'
              : pjt.plan === '미설정'
              ? '미설정'
              : pjt.plan === 'education'
              ? '교육용 요금제'
              : '엔터프라이즈'
            : 'DAU',
        discountRate: `${new BigNumber(
          (pjt.discountRate && pjt.discountRate[moment().format('YYYY-MM')]) ||
            0
        ).multipliedBy(100)}%`,
        store: `${
          pjt.googleStore === 'registered'
            ? '등록'
            : pjt.googleStore === 'notRegistered'
            ? '미등록'
            : '오류'
        } / ${
          pjt.appleStore === 'registered'
            ? '등록'
            : pjt.appleStore === 'notRegistered'
            ? '미등록'
            : '오류'
        }`,
        google:
          pjt.googleReleased === 1
            ? '출시'
            : pjt.googleReleased === -1
            ? '미사용'
            : '미출시',
        googleReleaseInfo: pjt.googleReleased
          ? {
              noDisplay: true,
              googleReleasedIndate: pjt.googleReleasedIndate,
              csvValue: pjt.googleReleasedIndate || '미출시',
              googleReleasedUrl: pjt.googleReleasedUrl,
              googleReleased: pjt.googleReleased,
            }
          : { noDisplay: true, csvValue: pjt.googleReleasedIndate || '미출시' },
        apple:
          pjt.appleReleased === 1
            ? '출시'
            : pjt.appleReleased === -1
            ? '미사용'
            : '미출시',
        appleReleaseInfo: pjt.appleReleased
          ? {
              noDisplay: true,
              appleReleasedIndate: pjt.appleReleasedIndate,
              csvValue: pjt.appleReleasedIndate || '미출시',
              appleReleasedUrl: pjt.appleReleasedUrl,
              appleReleased: pjt.appleReleased,
            }
          : { noDisplay: true, csvValue: pjt.appleReleasedIndate || '미출시' },
        status: pjt.status === 'n' ? '정상' : '정지',
        chatStatus: pjt.chatStatus === 'active' ? '활성' : '비활성',
      }));
    return projectList.map((pjt) => ({
      title: pjt.title,
      id: pjt.id,
      companyName: pjt.companyName,
      inDate: moment(pjt.inDate).format('YYYY.MM.DD HH:mm'),
      deletedDate:
        pjt.deletedDate && pjt.deletedDate !== '미확인'
          ? moment(pjt.deletedDate).format('YYYY.MM.DD HH:mm')
          : '미확인',
      plan: pjt.plan,
    }));
  }
);

export const projectListSelector = createSelector(
  (state) => state.projectManaging,
  ({ projectList, deletedProjects }) => {
    if (!deletedProjects)
      return projectList.map((pjt) => ({
        title: pjt.title,
        id: pjt.id,
        companyName: pjt.companyName,
        inDate: moment(pjt.inDate),
        avgDAU: pjt.avgDAU,
        avgMAU: pjt.avgMAU,
        plan: pjt.plan,
        discountRate: pjt.discountRate,
        google: pjt.googleReleased ? '출시' : '미출시',
        googleReleaseInfo: pjt.googleReleased
          ? {
              noDisplay: true,
              googleReleasedIndate: pjt.googleReleasedIndate,
              googleReleasedUrl: pjt.googleReleasedUrl,
            }
          : { noDisplay: true },
        apple: pjt.appleReleased ? '출시' : '미출시',
        appleReleaseInfo: pjt.appleReleased
          ? {
              noDisplay: true,
              appleReleasedIndate: pjt.appleReleasedIndate,
              appleReleasedUrl: pjt.appleReleasedUrl,
            }
          : { noDisplay: true },
        status: pjt.status === 'n' ? '정상' : '정지',
        //   action: {
        //     type: "handleOpen"
        //   }
      }));
    return projectList.map((pjt) => ({
      title: pjt.title,
      id: pjt.id,
      companyName: pjt.companyName,
      inDate: moment(pjt.inDate),
      deletedDate:
        pjt.deletedDate && pjt.deletedDate !== '미확인'
          ? moment(pjt.deletedDate)
          : '미확인',
      plan: pjt.plan,
    }));
  }
);

export const _projectListSelector = createSelector(
  (state) => state.projectManaging.projectList,
  (projectList) => projectList
);

export const statusListSelector = createSelector(
  (state) => state.projectManaging.statusList,
  (statusList) => {
    return statusList.map((stat) => {
      const unmarshalled = aws.DynamoDB.Converter.unmarshall(stat);
      return {
        inDate: moment(unmarshalled.inDate).format('YYYY.MM.DD HH:mm'),
        status: unmarshalled.status === 'n' ? '정상' : '정지',
        details: unmarshalled.details,
        admin_name: unmarshalled.admin_name,
      };
    });
  }
);

export const releaseInfoSelector = createSelector(
  (state) => state.projectManaging.releaseInfo,
  (releaseInfo) => releaseInfo
);

export const releasedRowIdSelector = createSelector(
  (state) => state.projectManaging.releasedRowId,
  (releasedRowId) => releasedRowId
);

export const sortingParamSelector = createSelector(
  (state) => state.projectManaging.sortingParam,
  (sortingParam) => sortingParam
);

export const offsetSelector = createSelector(
  (state) => state.projectManaging.offset,
  (offset) => offset
);

export const offsetToRefreshSelector = createSelector(
  (state) => state.projectManaging.offsetToRefresh,
  (offsetToRefresh) => offsetToRefresh
);

export const searchingOffsetSelector = createSelector(
  (state) => state.projectManaging.searchingOffset,
  (searchingOffset) => searchingOffset
);

export const searchingOffsetToRefreshSelector = createSelector(
  (state) => state.projectManaging.searchingOffsetToRefresh,
  (searchingOffsetToRefresh) => searchingOffsetToRefresh
);

export const searchKeywordsSelector = createSelector(
  (state) => state.projectManaging.searchKeywords,
  (searchKeywords) => searchKeywords
);

export const initIndicatorSelector = createSelector(
  (state) => state.projectManaging.initIndicator,
  (initIndicator) => initIndicator
);

export const countSelector = createSelector(
  (state) => state.projectManaging.projectCount,
  (projectCount) => projectCount
);

export const generalProjectCountSelector = createSelector(
  (state) => state.projectManaging.generalProjectCount,
  (generalProjectCount) => generalProjectCount
);

export const ineternalProjectCountSelector = createSelector(
  (state) => state.projectManaging.ineternalProjectCount,
  (ineternalProjectCount) => ineternalProjectCount
);

export const isLastSelector = createSelector(
  (state) => state.projectManaging.isLast,
  (isLast) => isLast
);

export const enterpriseInfoSelector = createSelector(
  (state) => state.projectManaging.enterpriseInfo,
  (enterpriseInfo) => enterpriseInfo
);

export const pjtListIsLoadedSelector = createSelector(
  (state) => state.projectManaging.isLoaded,
  (isLoaded) => isLoaded
);

export const projectDataSelector = createSelector(
  (state) => state.projectManaging.projectData,
  (projectData) => projectData
);

export const deletedProjectsSelector = createSelector(
  (state) => state.projectManaging.deletedProjects,
  (deletedProjects) => deletedProjects
);

export const gameIdSelector = createSelector(
  (state) => state.projectManaging.gameId,
  (gameId) => gameId
);

export const projectRatePlanSelector = createSelector(
  (state) => state.projectManaging.projectRatePlan,
  (projectRatePlan) => projectRatePlan
);

export const discountModalOpenSelector = createSelector(
  (state) => state.projectManaging.discountModalOpen,
  (discountModalOpen) => discountModalOpen
);
