export const HANDLE_CLOSE_CHARGE_MODAL = "HANDLE_CLOSE_CHARGE_MODAL";
export const CONFIRM_CHANGE_STATUS = "CONFIRM_CHANGE_STATUS";

export const GET_ACCOUNTS_ROWS = "GET_ACCOUNTS_ROWS";
export const GET_ACCOUNTS_ROWS_SUCCESS = "GET_ACCOUNTS_ROWS_SUCCESS";
export const INIT_ACCOUNT_LIST = "INIT_ACCOUNT_LIST";
export const INIT_DELETED_ACCOUNT_LIST = "INIT_DELETED_ACCOUNT_LIST";
export const GET_COLUMNS = "GET_COLUMNS";
//
export const CLICK_CHARGED_BILL_ROW = "CLICK_CHARGED_BILL_ROW";
export const GET_BACK_TO_MAIN_TABLE = "GET_BACK_TO_MAIN_TABLE";
export const HANDLE_OPEN_LAYERD_MODAL = "HANDLE_OPEN_LAYERD_MODAL";
export const HANDLE_CLOSE_LAYERD_MODAL = "HANDLE_CLOSE_LAYERD_MODAL";
export const GET_SEARCH_KEYWORDS_OVERDUE_BILL =
  "GET_SEARCH_KEYWORDS_OVERDUE_BILL";

export const GET_ACCOUNT_STATUS_SUCCESS = "GET_ACCOUNT_STATUS_SUCCESS";
export const HANDLE_MANAGE_BILL_MODAL = "HANDLE_MANAGE_BILL_MODAL";
export const HANDLE_POST_BILL_DATA = "HANDLE_POST_BILL_DATA";

//
export const HANDLE_OPEN_MODAL = "HANDLE_OPEN_MODAL";
export const CONFIRM_DELETE_ACCOUNT = "CONFIRM_DELETE_ACCOUNT";
export const SAVE_INPUT = "SAVE_INPUT";

// overdue bill

export const INIT_OVERDUE_BILL_LIST = "INIT_OVERDUE_BILL_LIST";
export const GET_OVERDUE_BILL = "GET_OVERDUE_BILL";
export const GET_OVERDUE_BILL_SUCCESS = "GET_OVERDUE_BILL_SUCCESS";
