// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { call, put, takeLatest, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import * as types from './constant';
import * as actions from './action';
import { openSubMenu } from '../../../../view/store/action';
import { openSubMenuSelector } from '../../../../view/store/selector';
import * as api from '../../../../service/API';

function* locationChange({ payload }) {
  try {
    // console.log('test 1027')
    const { pathname } = yield payload;
    const pathArray = pathname.split('/');
    // console.log(pathArray, "*** pathArray ***");
    if (pathArray[1] === 'developer' && pathArray[2] === 'consoleGuide') {
      yield put(openSubMenu('consoleGuide'));
      yield put(actions.initTopics());
    }
  } catch (err) {
    console.log('LOCATION_CHANGE', err);
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* initTopics() {
  try {
    const openSubMenu = yield select(openSubMenuSelector);
    let param;

    console.log(openSubMenu, 'in consoleGuide saga');
    switch (openSubMenu) {
      case 'consoleGuide':
        param = { title: '콘솔 가이드' };
        break;

      default:
        break;
    }
    yield put(actions.getTopics(param));
  } catch (error) {
    console.log('INIT_TOPICS_CONSOLE_GUIDE : ', error);
  }
}

export function* watcherInitTopics() {
  yield takeLatest(types.INIT_TOPICS_CONSOLE_GUIDE, initTopics);
}

function* getTopics({ param }) {
  try {
    const { data } = yield call(api.getStarted.getTopics, param);

    // console.log(data, '1027')
    let listData = {};
    let classParentList = [];

    if (data && data.length) {
      for (let i = 0; i < data.length; i++) {
        // test code

        yield put(actions.initGetListKey());
        const _listData = yield call(api.getStarted.getList, {
          topicIndate: data[i].inDate,
        });
        // console.log(_listData.data, "0709 _listData");
        listData = yield {
          ...listData,
          [_listData.data.title]: _listData.data,
        };

        // api : getClassParentList 토픽별 호출
        const classParents = yield call(
          api.getStarted.getClassParentList,
          data[i].inDate
        );
        // console.log(i, classParents, 'index별 클래스 페어런트')
        const _classParents = classParents.data.filter(
          (_class) => _class.title
        );
        classParentList = [
          ...classParentList,
          {
            // title: _listData.data.title,
            title: '없음',
            inDate: _listData.data.inDate,
            header: _listData.data.title,
            // header: true
          },
          ..._classParents,
        ];
      }

      // yield put(actions.getClassParentList(inDate));
    }

    yield put(actions.getListSuccess(listData));
    yield put(actions.getClassParentListSuccess(classParentList));

    yield put(actions.getTopicsSuccess(data));
  } catch (error) {
    console.log('GET_TOPICS_CONSOLE_GUIDE', error);
  }
}

export function* watcherGetTopics() {
  yield takeLatest(types.GET_TOPICS_CONSOLE_GUIDE, getTopics);
}

function* getList({ param, listIndex }) {
  try {
    const { data } = yield call(api.getStarted.getList, param);

    console.log(data, 'GET_LIST_CONSOLE_GUIDE_SUCCESS');
    yield put(actions.getListSuccess(data, listIndex));
  } catch (error) {
    console.log('GET_LIST_CONSOLE_GUIDE', error);
  }
}

export function* watcherGetList() {
  yield takeLatest(types.GET_LIST_CONSOLE_GUIDE, getList);
}
function* getClassParentList({ param }) {
  try {
    const { data } = yield call(api.getStarted.getClassParentList, param);

    console.log(data, 'GET_CLASS_PARENT_LIST_CONSOLE_GUIDE_SUCCESS');
    yield put(actions.getClassParentListSuccess(data));
  } catch (error) {
    console.log('GET_CLASS_PARENT_LIST_CONSOLE_GUIDE', error);
  }
}

export function* watcherGetClassParentList() {
  yield takeLatest(
    types.GET_CLASS_PARENT_LIST_CONSOLE_GUIDE,
    getClassParentList
  );
}

function* makeClass({ obj }) {
  try {
    yield call(api.getStarted.makeClass, obj);

    yield put(actions.makeClassSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('MAKE_CLASS_CONSOLE_GUIDE', error);
  }
}

export function* watcherMakeClass() {
  yield takeLatest(types.MAKE_CLASS_CONSOLE_GUIDE, makeClass);
}

function* makeDocument({ obj }) {
  try {
    yield call(api.getStarted.makeDocument, obj);

    yield put(actions.makeDocumentSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('MAKE_DOCUMENT_CONSOLE_GUIDE', error);
  }
}

export function* watcherMakeDocument() {
  yield takeLatest(types.MAKE_DOCUMENT_CONSOLE_GUIDE, makeDocument);
}

function* updateOrder({ inDate, upDown }) {
  try {
    yield call(api.getStarted.updateOrder, inDate, upDown);
    yield put(actions.updateOrderSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('UPDATE_ORDER_CONSOLE_GUIDE', error);
  }
}

export function* watcherUpdateOrder() {
  yield takeLatest(types.UPDATE_ORDER_CONSOLE_GUIDE, updateOrder);
}

function* updateClass({ obj, inDate }) {
  try {
    yield call(api.getStarted.updateClass, inDate, obj);
    yield put(actions.updateClassSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('UPDATE_CLASS_CONSOLE_GUIDE', error);
  }
}

export function* watcherUpdateClass() {
  yield takeLatest(types.UPDATE_CLASS_CONSOLE_GUIDE, updateClass);
}

function* deleteClass({ obj, inDate }) {
  try {
    yield call(api.getStarted.deleteClass, inDate, obj);
    yield put(actions.deleteClassSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('DELETE_CLASS_CONSOLE_GUIDE', error);
  }
}

export function* watcherDeleteClass() {
  yield takeLatest(types.DELETE_CLASS_CONSOLE_GUIDE, deleteClass);
}

function* getDocument({ inDate }) {
  try {
    const { data } = yield call(api.getStarted.getDocument, inDate);
    yield put(actions.getDocumentSuccess(data));
  } catch (error) {
    console.log('GET_DOCUMENT_CONSOLE_GUIDE', error);
  }
}

export function* watcherGetDocument() {
  yield takeLatest(types.GET_DOCUMENT_CONSOLE_GUIDE, getDocument);
}

function* updateDocument({ obj, inDate }) {
  try {
    yield call(api.getStarted.updateDocument, obj, inDate);
    yield put(actions.updateDocumentSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('UPDATE_DOCUMENT_CONSOLE_GUIDE', error);
  }
}

export function* watcherUpdateDocument() {
  yield takeLatest(types.UPDATE_DOCUMENT_CONSOLE_GUIDE, updateDocument);
}

function* deleteDocument({ inDate }) {
  try {
    yield call(api.getStarted.deleteDocument, inDate);
    yield put(actions.deleteDocumentSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('DELETE_DOCUMENT_CONSOLE_GUIDE', error);
  }
}

export function* watcherDeleteDocument() {
  yield takeLatest(types.DELETE_DOCUMENT_CONSOLE_GUIDE, deleteDocument);
}

function* makeTopic({ obj }) {
  try {
    yield call(api.getStarted.makeTopic, obj);
    yield put(actions.makeTopicSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('MAKE_TOPIC_CONSOLE_GUIDE', error);
  }
}

export function* watcherMakeTopic() {
  yield takeLatest(types.MAKE_TOPIC_CONSOLE_GUIDE, makeTopic);
}

function* updateTopic({ inDate, topic }) {
  try {
    yield call(api.getStarted.updateTopic, inDate, topic);
    yield put(actions.updateTopicSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('UPDATE_TOPIC', error);
  }
}

export function* watcherUpdateTopic() {
  yield takeLatest(types.UPDATE_TOPIC_CONSOLE_GUIDE, updateTopic);
}

function* deleteTopic({ inDate }) {
  try {
    yield call(api.getStarted.deleteTopic, inDate);
    yield put(actions.deleteTopicSuccess());
    yield put(actions.initTopics());
  } catch (error) {
    console.log('DELETE_TOPIC_CONSOLE_GUIDE', error);
  }
}

export function* watcherDeleteTopic() {
  yield takeLatest(types.DELETE_TOPIC_CONSOLE_GUIDE, deleteTopic);
}
