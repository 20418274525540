// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { put, takeLatest, call, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import * as types from './constant';
import * as actions from './action';
import * as globalActions from '../../globalStore/action';
import { openSubMenu, handleClickSubMenu } from '../../../view/store/action';
import * as selectors from './selector';
import * as api from '../../../service/API';

function* locationChange({ payload }) {
  try {
    const { pathname } = yield payload;
    const pathArray = pathname.split('/');

    if (pathArray[1] === 'sendEmail') {
      yield put(openSubMenu('email'));
      yield put(handleClickSubMenu('sendEmail'));

      const { data } = yield call(api.sendEmail.getSendToCount);
      yield put(actions.getSendToCountSuccess(data));
    }
  } catch (err) {
    console.log('saga err', err);
  }
}

export function* watcherLocationChange() {
  yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* getSendToCount() {
  try {
    const { data } = yield call(api.sendEmail.getSendToCount);
    yield put(actions.getSendToCountSuccess(data));
  } catch (error) {}
}

export function* watcherGetSendToCount() {
  yield takeLatest(types.GET_SEND_TO_COUNT, getSendToCount);
}

function* sendEmail({ payload }) {
  try {
    yield call(api.sendEmail.sendEmail, payload);
    yield put(actions.sendEmailSuccess());
    yield put(actions.getSendToCount());
  } catch (error) {
    console.error('SEND_EMAIL ERROR: ', error);
  }
}

export function* watcherSendEmail() {
  yield takeLatest(types.SEND_EMAIL, sendEmail);
}

function* sendTestEmail({ payload }) {
  try {
    yield call(api.sendEmail.sendTestEmail, payload);
    yield put(actions.sendTestEmailSuccess());
    // yield put(actions.getSendToCount())
  } catch (error) {
    console.error('SEND_EMAIL ERROR: ', error);
  }
}

export function* watcherSendTestEmail() {
  yield takeLatest(types.SEND_TEST_EMAIL, sendTestEmail);
}

function* readFiles({ payload }) {
  try {
    let errorObj = null;
    const alreadyFiles = yield select(selectors.filesSelector);
    console.log('alreadyFiles', alreadyFiles);

    let alreadyFilesSize = 0;
    alreadyFiles.forEach((file) => (alreadyFilesSize += file.size));

    const handleMakeFileList = async (e) => {
      let fileSize = 0;
      if (payload && payload.length + alreadyFiles.length > 5) {
        errorObj = {
          errMsg: '첨부 파일은 최대 5개 까지 업로드 가능합니다.',
          shouldMsgModalOpen: true,
          filesLoaded: true,
        };
        throw errorObj;
      }

      const promises = payload.map(
        ({ file }) =>
          new Promise((res) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            fileSize += file.size;

            if (fileSize + alreadyFilesSize > 8 * 1024 * 1024) {
              errorObj = {
                errMsg: '첨부 파일의 총합은 8MB를 초과할 수 없습니다.',
                shouldMsgModalOpen: true,
                filesLoaded: true,
              };
              throw errorObj;
            }

            const isExe = file.name.slice(file.name.length - 3);
            if (isExe === 'exe') {
              errorObj = {
                errMsg: '실행 파일(exe)은 업로드 할 수 없습니다.',
                shouldMsgModalOpen: true,
                filesLoaded: true,
              };
              throw errorObj;
            }

            reader.onload = (e) => {
              console.log(file);
              res({ file: e.target.result, key: file.name, size: file.size });
            };
          })
      );

      const bases = await Promise.all(promises)
        .then((res) => {
          console.log('Success');
          return res;
        })
        .catch((error) => {
          console.error('Fail', error);
          return error;
        });
      return bases;
    };
    const data = yield handleMakeFileList();
    if (data && data.errMsg) yield put(actions.handleFileErrors(data));
    else yield put(actions.readFilesSuccess([...data, ...alreadyFiles]));
  } catch (error) {
    console.log(error);
    if (error && error.errMsg) yield put(actions.handleFileErrors(error));
    else yield put(globalActions.globalErrorHandling(error));
  }
}

export function* watcherReadFiles() {
  yield takeLatest(types.READ_FILES, readFiles);
}
