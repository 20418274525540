import { requestPost, requestGet, requestDelete, requestPut } from "../../utils/request";
import Qs from "qs";

import BaseUrl from "../../shared/baseUrl";

export const getTermsPolicy = params => {
  const url = `${BaseUrl.LANDING_API}/policy/terms`;

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: "brackets" });
    }
  };

  return requestGet(url, options);
};

export const updateTermsPolicy = (data) => {
  const url = `${BaseUrl.LANDING_API}/policy/terms`;

  return requestPost(url, data);
};
