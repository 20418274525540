import { requestPost, requestGet, requestDelete, requestPut } from "../../utils/request";
import Qs from "qs";

import BaseUrl from "../../shared/baseUrl";

export const getShowcaseList = params => {
  const url = `${BaseUrl.LANDING_API}/showcase`;

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: "brackets" });
    }
  };

  return requestGet(url, options);
};

export const updateShowcaseOrder = (inDate,updown) => {
  const url = `${BaseUrl.LANDING_API}/showcase/${inDate}/${updown}`;

  return requestPost(url);
};

export const updateShowcase = (inDate,data) => {
  const url = `${BaseUrl.LANDING_API}/showcase/${inDate}/`;

  return requestPost(url, data);
};

export const insertShowcase = (data) => {
  const url = `${BaseUrl.LANDING_API}/showcase/v1.1`;

  return requestPost(url, data);
};

export const deleteShowcase = (inDate) => {
  const url = `${BaseUrl.LANDING_API}/showcase/${inDate}`;

  return requestDelete(url);
};
