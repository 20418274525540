// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { requestPost, requestGet } from '../../utils/request';
import Qs from 'qs';

import BaseUrl from '../../shared/baseUrl';

export const getSLAPolicy = (params) => {
  const url = `${BaseUrl.LANDING_API}/policy/sla`;

  const options = {
    params,
    paramsSerializer(serializingParams) {
      return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
    },
  };

  return requestGet(url, options);
};

export const updateSLAPolicy = (data) => {
  const url = `${BaseUrl.LANDING_API}/policy/sla`;

  return requestPost(url, data);
};
