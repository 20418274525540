import React from 'react';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import emoji from 'markdown-it-emoji';
import subscript from 'markdown-it-sub';
import superscript from 'markdown-it-sup';
import footnote from 'markdown-it-footnote';
import deflist from 'markdown-it-deflist';
import abbreviation from 'markdown-it-abbr';
import insert from 'markdown-it-ins';
import mark from 'markdown-it-mark';
import tasklists from 'markdown-it-task-lists';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-light.css';

import '../../css/toastui-editor-viewer.css';

export default class Demo extends React.Component {
  mdEditor = null;
  mdParser = null;

  constructor(props) {
    super(props);
    // initial a parser
    this.mdParser = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
      highlight: function (str, lang) {
        if (lang && hljs.getLanguage(lang)) {
          try {
            return hljs.highlight(lang, str).value;
          } catch (__) {}
        }
        return ''; // use external default escaping
      },
    })
      .use(emoji)
      .use(subscript)
      .use(superscript)
      .use(footnote)
      .use(deflist)
      .use(abbreviation)
      .use(insert)
      .use(mark)
      .use(tasklists, { enabled: this.taskLists });
    this.renderHTML = this.renderHTML.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  handleEditorChange({ html, text }) {
    const { onHandleOnChange } = this.props;
    onHandleOnChange(text);
  }
  handleImageUpload(file, callback) {
    const reader = new FileReader();
    reader.onload = () => {
      setTimeout(() => {
        callback(reader.result);
      }, 1000);
    };
    reader.readAsDataURL(file);
  }

  renderHTML(text) {
    return new Promise((resolve) => {
      resolve(
        `<div class="toastui-editor-contents">${this.mdParser.render(
          text
        )}</div>`
      );
    });
  }

  handleGetMdValue = () => {
    this.mdEditor && alert(this.mdEditor.getMdValue());
  };
  handleGetHtmlValue = () => {
    this.mdEditor && alert(this.mdEditor.getHtmlValue());
  };
  render() {
    const {
      content: { content },
    } = this.props;

    return (
      <div>
        <section style={{ height: '700px' }}>
          <MdEditor
            ref={(node) => (this.mdEditor = node)}
            value={content}
            style={{ height: '650px' }}
            renderHTML={this.renderHTML}
            config={{
              view: {
                menu: true,
                md: true,
                html: true,
              },
              //   imageUrl: 'https://octodex.github.com/images/minion.png'
            }}
            onChange={this.handleEditorChange}
            onImageUpload={this.handleImageUpload}
          />
        </section>
      </div>
    );
  }
}
