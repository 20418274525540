import { call, put, takeLatest, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import clone from 'ramda/src/clone';
import moment from 'moment';

import { yieldExpression } from '@babel/types';
import * as types from './constant';

import * as actions from './action';
import { openSubMenu, handleClickSubMenu } from '../../../../view/store/action';
import * as selectors from './selector';
import * as api from '../../../../service/API';

function* locationChange({ payload }) {
	try {
		const { pathname } = yield payload;
		const pathArray = pathname.split('/');
		// console.log(pathArray[1], "in projects saga");
		if (pathArray[1] === 'sales') {
			yield put(openSubMenu('statistics'));
			yield put(handleClickSubMenu('sales'));

			yield put(
				actions.getDailyStatisticsByProject({
					date: moment().subtract(2, 'day').format('YYYY-MM-DD'),
				})
			);
		}
	} catch (err) {
		console.log('saga err', err);
	}
}

export function* watcherLocationChange() {
	yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* getDailyStatByPjt({ obj }) {
	try {
		const date = yield select(selectors.searchingKeywordsSelector);

		const {
			data: { projectList, isLast },
		} = yield call(api.sales.getDailyStatisticsByProject, obj || date);
		yield put(actions.getDailyStatByPjtSuccess(projectList, isLast));
	} catch (error) {}
}

export function* WatcherGetDailyStatByPjt() {
	yield takeLatest(types.GET_DAILY_STAT_BY_PJT, getDailyStatByPjt);
}
