// 공통모듈
import React, { useState, useEffect } from 'react';
import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';

import DataTable from '../../../fnComponents/_DataTable';
import ButtonDropdown from '../../../fnComponents/ButtonDropdown';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as actions from './store/action';
import * as selectors from './store/selector';
import moment from 'moment';
import Octicon, { Search, X } from '@primer/octicons-react';

//
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import MsgModal from '../../../fnComponents/MsgModal';
import MarkDownEditor from './MarkDownEditor';
import { async } from 'regenerator-runtime';

const Policy = ({
  // state props
  inquiriesList,
  _inquiriesList,
  lastEvaluatedKey,
  // func props
  onGetInquiriesList,
  onSendResponse,
  onDeleteInquiry,
  onGetSearchKeywords,
  onin,
}) => {
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [selectedInquiry, setSelectedInquiry] = useState({});

  const [response, setResponse] = useState('');
  const handleOpenResponseModal = () => {};

  const handleChange = (e) => {
    // console.log(e);
    setResponse(e);
    setErrMsg('');
  };

  const [msgContent, setMsgContent] = useState('');
  const [msgType, setMsgType] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const handleResponseMsgModalOpen = () => {
    try {
      if (!response)
        throw {
          errMsg: '답변은 필수 입력 항목입니다.',
        };
      setIsMsgModalOpen(true);
      setMsgContent('답변을 이메일로 발송 하시겠습니까?');
      setMsgType('response');
    } catch (error) {
      console.log('에러:', error);
      setErrMsg(error.errMsg);
    }
  };

  const handleResponse = async () => {
    try {
      await onSendResponse(inDate, { response });
      await handleCloseResponseModal();
      await setIsMsgModalOpen(false);
    } catch (error) {
      console.log('에러:', error);
    }
  };
  const handleCloseResponseModal = () => {
    setResponseModalOpen(false);
  };

  const [inDate, setInDate] = useState('');
  const [status, setStatus] = useState('');

  const handleGetInquiry = (e, arr) => {
    // 답변 모달 활성화
    // console.log('답변 확인 arr', arr)
    const selectedInquiry = _inquiriesList.filter(
      (inquiry) => inquiry.inDate === arr[1].id.inDate
    )[0];
    setInDate(arr[1].id.inDate);
    setSelectedInquiry(selectedInquiry);
    setResponseModalOpen(!responseModalOpen);
    setStatus(arr[4].status);
    if (arr[4].status === '답변완료') {
      setResponse(selectedInquiry.response);
    }
  };

  const [isMsgModalOpen, setIsMsgModalOpen] = useState(false);

  const handleDeleteInquiry = (e, arr) => {
    setIsMsgModalOpen(true);
    setMsgContent('삭제하시겠습니까?');
    setMsgType('delete');
    setInDate(arr[1].id.inDate);
  };

  const handleCloseMsgModal = () => {
    setIsMsgModalOpen(false);
  };

  const handleDelete = () => {
    onDeleteInquiry(inDate);
    setIsMsgModalOpen(false);
  };

  const initInputObj = {
    // id: "",
    init: false,
  };
  const [inputObj, handleInput] = useState(initInputObj);
  const dropdownItems = [
    {
      name: '전체',
    },
    {
      name: '제목',
    },
    {
      name: '내용',
    },
    {
      name: '이름',
    },
    {
      name: '이메일',
    },
  ];

  const [searchFilter, setSearchFilter] = useState(false);
  const handleKeyDown = () => {};

  const [searchStatus, setSearchStatus] = useState('전체');
  const handleDropdown = (obj) => {
    // console.log('obj 확인', obj)
    const { status } = obj;
    setSearchStatus(status);

    handleInput({
      [status]: inputObj[searchStatus],
      init: false,
    });
  };

  const [searchingKey, setSearchingKey] = useState(null);
  const handleSearch = (e) => {
    e.preventDefault();
    setSearchFilter(true);
    // console.log("inputObj", inputObj)
    delete inputObj.init;

    const key = Object.keys(inputObj)[0];
    let obj = {};
    switch (key) {
      case '전체':
        obj = {
          all: inputObj[key],
        };
        break;
      case '제목':
        obj = {
          title: inputObj[key],
        };
        break;
      case '내용':
        obj = {
          contents: inputObj[key],
        };
        break;
      case '이름':
        obj = {
          name: inputObj[key],
        };
        break;
      case '이메일':
        obj = {
          email: inputObj[key],
        };
        break;

      default:
        break;
    }
    setSearchingKey(obj);
    onGetInquiriesList(obj);
  };

  const handleGetInquiriesList = (e) => {
    onGetInquiriesList(searchingKey, true);
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />
        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title ">일반 문의</h3>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    {/* 검색 폼 */}
                    <form className="m-form m-form--fit m--margin-bottom-20">
                      <div className="row m--margin-bottom-20">
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <ButtonDropdown
                            initSearchFilter={inputObj.init}
                            initInputObj={initInputObj}
                            onChangeBtnDropdown={handleDropdown}
                            initItem="전체"
                            dropdownItems={dropdownItems}
                          />
                        </div>
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <input
                            id="id"
                            onKeyDown={handleKeyDown}
                            onChange={(e) => {
                              handleInput({
                                ...inputObj,
                                [searchStatus]: e.target.value,
                                init: false,
                              });
                            }}
                            value={inputObj[searchStatus]}
                            type="text"
                            className="form-control m-input"
                            placeholder=""
                            data-col-index="0"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <button
                            onClick={handleSearch}
                            className="btn btn-brand m-btn m-btn--icon"
                            id="m_search"
                          >
                            <span>
                              <Octicon icon={Search} />
                              <span>검색</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </form>
                    <MsgModal
                      content={msgContent}
                      isMsgModalOpen={isMsgModalOpen}
                      onHandleCloseMsgModal={handleCloseMsgModal}
                      onHandleDelete={
                        msgType === 'delete' ? handleDelete : null
                      }
                      confirmOrCancel={msgType === 'response'}
                      onConfirmAction={
                        msgType === 'response' ? handleResponse : null
                      }
                    />
                    <DataTable
                      isLast={!lastEvaluatedKey}
                      requestAction={handleGetInquiriesList}
                      deleteRow
                      requiredAction={handleGetInquiry}
                      secondRequiredAction={handleDeleteInquiry}
                      rows={inquiriesList}
                      columns={[
                        { label: '제목', field: 'title' },
                        { label: '이름', field: 'name' },
                        { label: '등록일시', field: 'inDate' },
                        { label: '상태', field: 'status' },
                        { label: '' },
                      ]}
                      customClassName={
                        inquiriesList.length > 13
                          ? 'popupList-table column-5 table-scrollable'
                          : 'popupList-table column-5'
                      }
                      clickableIndex={[0]}
                    />
                    {/* 답변 모달 */}
                    <Modal isOpen={responseModalOpen} size="xl">
                      <ModalHeader>상세 내역</ModalHeader>
                      <ModalBody>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="title"
                            className="col-lg-2 col-form-label"
                          >
                            등록일시
                          </label>
                          <div className="col-lg-10">
                            <label
                              htmlFor="title"
                              className="col-lg-2 col-form-label label-view"
                            >
                              {moment(selectedInquiry.inDate).format(
                                'YYYY.MM.DD HH:mm'
                              )}
                            </label>
                            {status === '답변완료' ? (
                              <React.Fragment>
                                <label
                                  htmlFor="title"
                                  className="col-lg-2 col-form-label"
                                >
                                  답변일시
                                </label>
                                <label
                                  htmlFor="title"
                                  className="col-lg-2 col-form-label label-view"
                                >
                                  {moment(selectedInquiry.responseDate).format(
                                    'YYYY.MM.DD HH:mm'
                                  )}
                                </label>
                              </React.Fragment>
                            ) : null}
                          </div>
                          <label
                            htmlFor="title"
                            className="col-lg-2 col-form-label"
                          >
                            이름
                          </label>
                          <div className="col-lg-10">
                            <label
                              htmlFor="title"
                              className="col-lg-2 col-form-label label-view"
                            >
                              {selectedInquiry.name}
                            </label>
                            <label
                              htmlFor="title"
                              className="col-lg-2 col-form-label"
                            >
                              이메일
                            </label>
                            <label
                              htmlFor="title"
                              className="col-lg-2 col-form-label label-view"
                            >
                              {selectedInquiry.email}
                            </label>
                            <label
                              htmlFor="title"
                              className="col-lg-2 col-form-label"
                            >
                              연락처
                            </label>
                            <label
                              htmlFor="title"
                              className="col-lg-2 col-form-label label-view"
                            >
                              {selectedInquiry.phoneNumber}
                            </label>
                          </div>
                          <label
                            htmlFor="title"
                            className="col-lg-2 col-form-label"
                          >
                            제목
                          </label>
                          <div className="col-lg-10">
                            <label
                              htmlFor="title"
                              className="col-lg-2 col-form-label label-view"
                            >
                              {selectedInquiry.title}
                            </label>
                          </div>
                        </div>
                        <div className="form-group m-form__group row mt-30">
                          <label
                            htmlFor="title"
                            className="col-lg-2 col-form-label"
                          >
                            내용
                          </label>
                          <div className="col-lg-10">
                            <textarea
                              className="form-control landing-inquiry"
                              id="descriptions"
                              required
                              readOnly
                              value={selectedInquiry.contents}
                            />
                          </div>
                        </div>
                        {status === '답변완료' ? (
                          <div className="form-group m-form__group row mt-30">
                            <label
                              htmlFor="title"
                              className="col-lg-2 col-form-label"
                            >
                              답변 *
                            </label>
                            <div className="col-lg-10">
                              <div
                                id="descriptions"
                                className="form-control landing-inquiry"
                                dangerouslySetInnerHTML={{ __html: response }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="form-group m-form__group row mt-30">
                            <div className="col-lg-10">
                              <MarkDownEditor
                                onHandleOnChange={handleChange}
                                _content={response}
                              />
                              <div className="error message visible">
                                {errMsg}
                              </div>
                            </div>
                          </div>
                        )}
                      </ModalBody>
                      <ModalFooter>
                        {status === '답변대기' ? (
                          <React.Fragment>
                            <Button
                              color="primary"
                              onClick={handleResponseMsgModalOpen}
                            >
                              확인
                            </Button>
                            <Button
                              id="close"
                              color="primary"
                              onClick={handleCloseResponseModal}
                            >
                              취소
                            </Button>
                          </React.Fragment>
                        ) : (
                          <Button
                            id="close"
                            color="primary"
                            onClick={handleCloseResponseModal}
                          >
                            확인
                          </Button>
                        )}
                      </ModalFooter>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  inquiriesList: selectors.inquiriesListSelectorForView,
  _inquiriesList: selectors.inquiriesListSelector,
  lastEvaluatedKey: selectors.lastEvaluatedKeySelector,
});

const mapDispatchToProps = (dispatch) => ({
  onGetInquiriesList: (search, isNext) =>
    dispatch(actions.getInquiriesList(search, isNext)),
  onSendResponse: (inDate, response) =>
    dispatch(actions.sendResponse(inDate, response)),
  onDeleteInquiry: (inDate) => dispatch(actions.deleteInquiry(inDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Policy);
