import Qs from 'qs';
import { requestDelete, requestGet } from '../../utils/request';

export const getSentMails = (params) => {
	const url = 'email/byadmin/sent';

	const options = {
		params,
		paramsSerializer(serializingParams) {
			return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
		},
	};

	return requestGet(url, options);
};

export const getSentMailDetails = (inDate, params) => {
	const url = `email/byadmin/sent/${inDate}`;

	const options = {
		params,
		paramsSerializer(serializingParams) {
			return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
		},
	};

	return requestGet(url, options);
};

export const deleteSentMail = (params, inDate) => {
	const url = `email/byadmin/sent/${inDate}`;

	const options = {
		params,
		paramsSerializer(serializingParams) {
			return Qs.stringify(serializingParams, { arrayFormat: 'brackets' });
		},
	};

	return requestDelete(url, options);
};
