import React from "react";

import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";

export default class Demo extends React.Component {
  mdParser = null;
  constructor(props) {
    super(props);
    this.mdParser = new MarkdownIt({
      html: true
    });
    this.state = {
      html: "",
      text: ""
    };
  }
  handleEditorChange({ html, text }) {
    console.log("handleEditorChange", html, text);
    // this.setState({ html, text });
    // const { onHandleOnChange } = this.props;
    // onHandleOnChange(html);
  }

  handleImageUpload(file, callback) {
    const reader = new FileReader();
    reader.onload = () => {
      const convertBase64UrlToBlob = urlData => {
        let arr = urlData.split(","),
          mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      };

      const blob = convertBase64UrlToBlob(reader.result);
      const url = window.URL.createObjectURL(blob);
      console.log("reader.result", reader.result);
      setTimeout(() => callback(reader.result), 1000);
    };
    reader.readAsDataURL(file);
  }

  render() {
    const { html, text } = this.state;
    const {
      _content: { content },
      onHandleOnChange
    } = this.props;
    // console.log(content, "!!!");
    const sth = "";

    const customHtmlStyleElem = document.getElementsByClassName(
      "custom-html-style"
    )[0];

    if (customHtmlStyleElem && customHtmlStyleElem.className) {
      customHtmlStyleElem.className = "";
    }

    return (
      <div style={{ height: "500px" }}>
        <MdEditor
          onImageUpload={this.handleImageUpload}
          value={content || sth}
          renderHTML={text => {
            // console.log(text, "renderHTML");
            return this.mdParser.render(text);
          }}
          onChange={({ html, text }) => onHandleOnChange(html)}
        />
      </div>
    );
  }
}
