/*
 * Copyright 2013-2022 AFI, Inc. All Rights Reserved.
 */
import React from 'react';
import MsgModal from '../../fnComponents/MsgModal';
import { useEffect, useState } from 'react';

const EditModal = ({
  handleChange,
  handleDBChange,
  newIndicators,
  isMsgModalOpen,
  putIndicator,
  setIsMsgModalOpen,
  errObj,
}) => {
  const [contents, setContents] = useState(null);
  const [header, setHeader] = useState(null);

  const replaceSliced = (str, num) => {
    const sliced = str.slice(0, str.length - num);
    return parseInt(sliced.replace(/,/g, ''));
  };

  useEffect(() => {
    if (newIndicators) {
      const {
        item,
        limitCount,
        price,
        title,
        type,
        value,
        durationLimit,
        durationPrice,
        callLimit,
        callPrice,
        fee,
        trafficLimit,
        trafficPrice,
        logPrice,
        matchCountLimit,
        matchCountPrice,
        backup,
        read,
        storage,
        write,
      } = newIndicators;

      let header = '';
      let contents = '';

      switch (value) {
        case 'base':
          header = '뒤끝베이스 요금 수정';
          contents = (
            <>
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-no-flex"
                >
                  항목
                </label>
                <div className="col-lg-9">
                  <span className="form_text">{title}</span>
                </div>
              </div>
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-no-flex"
                >
                  무료 제공 *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="type"
                    className="form-control update-pay-plan-input"
                    name="limitCount"
                    value={newIndicators.limitCount}
                    required
                  />
                  <div
                    htmlFor="changingRate"
                    className="col-lg-2 col-form-label update-pay-plan-label"
                  >
                    건
                  </div>
                  <div className="error message visible">
                    {errObj.limitCount}
                  </div>
                </div>
              </div>
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-no-flex"
                >
                  단가(원) *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="type"
                    className="form-control update-pay-plan-input"
                    name="price"
                    value={newIndicators.price}
                    required
                  />
                  <div
                    htmlFor="changingRate"
                    className="col-lg-2 col-form-label update-pay-plan-label"
                  >
                    /건
                  </div>
                  <div className="error message visible">{errObj.price}</div>
                </div>
              </div>
            </>
          );
          break;
        case 'function':
          header = '뒤끝펑션 요금 수정';
          contents = (
            <>
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  요금제
                </label>
                <div className="col-lg-9">
                  <span className="form_text">{type}</span>
                </div>
              </div>
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  호출수 무료 제공 *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    value={callLimit}
                    name="callLimit"
                    required
                  />
                  <div className="col-lg-2 col-form-label update-pay-plan-label">
                    호출
                  </div>
                  <div className="error message visible">
                    {errObj.limitCount}
                  </div>
                </div>
              </div>
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  호출 단가(원) *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    name="callPrice"
                    value={callPrice}
                    required
                  />
                  <div
                    htmlFor="changingRate"
                    className="col-lg-2 col-form-label update-pay-plan-label"
                  >
                    /호출
                  </div>
                  <div className="error message visible">{errObj.price}</div>
                </div>
              </div>
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  사용 시간 무료 제공 *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    name="durationLimit"
                    value={durationLimit}
                    required
                  />
                  <div
                    htmlFor="changingRate"
                    className="col-lg-2 col-form-label update-pay-plan-label"
                  >
                    분
                  </div>
                  <div className="error message visible">
                    {errObj.limitCount}
                  </div>
                </div>
              </div>
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  사용 시간 단가(원) *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    name="durationPrice"
                    value={durationPrice}
                    required
                  />
                  <div
                    htmlFor="changingRate"
                    className="col-lg-2 col-form-label update-pay-plan-label"
                  >
                    /분
                  </div>
                  <div className="error message visible">{errObj.price}</div>
                </div>
              </div>
            </>
          );
          break;
        case 'chat':
          header = '뒤끝챗 요금 수정';
          contents = (
            <>
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  요금제
                </label>
                <div className="col-lg-9">
                  <span className="form_text">{type}</span>
                </div>
              </div>
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  기본료(원) *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    value={fee}
                    name="fee"
                    required
                  />
                  <div className="error message visible">{errObj.fee}</div>
                </div>
              </div>
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  전송량 무료 제공 *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    name="trafficLimit"
                    value={trafficLimit}
                    required
                  />
                  <div className="col-lg-2 col-form-label update-pay-plan-label">
                    GB
                  </div>
                  <div className="error message visible">
                    {errObj.limitCount}
                  </div>
                </div>
              </div>
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  전송량 단가(원) *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    name="trafficPrice"
                    value={trafficPrice}
                    required
                  />
                  <div className="col-lg-2 col-form-label update-pay-plan-label">
                    /MB
                  </div>
                  <div className="error message visible">{errObj.price}</div>
                </div>
              </div>
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  채팅 로그 단가(원) *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    name="logPrice"
                    value={logPrice}
                    required
                  />
                  <div className="col-lg-2 col-form-label update-pay-plan-label">
                    /GB
                  </div>
                  <div className="error message visible">{errObj.price}</div>
                </div>
              </div>
            </>
          );
          break;
        case 'match':
          header = '뒤끝매치 요금 수정';
          contents = (
            <>
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  요금제
                </label>
                <div className="col-lg-9">
                  <span className="form_text">{type}</span>
                </div>
              </div>
              {/* a row */}
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  기본료(원) *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    name="fee"
                    value={fee}
                    required
                  />
                  <div className="col-lg-2 col-form-label update-pay-plan-label">
                    {/* 호출 */}
                  </div>
                  <div className="error message visible">{errObj.fee}</div>
                </div>
              </div>
              {/* a row */}
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  매치메이킹 건수 무료 제공 *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    value={matchCountLimit}
                    name="matchCountLimit"
                    required
                  />
                  <div
                    htmlFor="changingRate"
                    className="col-lg-2 col-form-label update-pay-plan-label"
                  >
                    GB
                  </div>
                  <div className="error message visible">
                    {errObj.limitCount}
                  </div>
                </div>
              </div>
              {/* a row */}
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  매치메이킹 건수 단가(원) *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    value={matchCountPrice}
                    name="matchCountPrice"
                    required
                  />
                  <div className="col-lg-2 col-form-label update-pay-plan-label">
                    /MB
                  </div>
                  <div className="error message visible">{errObj.price}</div>
                </div>
              </div>
              {/* a row */}
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  룸 유지 시간 무료 제공 *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    value={durationLimit}
                    name="durationLimit"
                    required
                  />
                  <div className="col-lg-2 col-form-label update-pay-plan-label">
                    시간
                  </div>
                  <div className="error message visible">
                    {errObj.limitCount}
                  </div>
                </div>
              </div>
              {/* a row */}
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  룸 유지 시간 단가(원) *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    value={durationPrice}
                    name="durationPrice"
                    required
                  />
                  <div className="col-lg-2 col-form-label update-pay-plan-label">
                    /분
                  </div>
                  <div className="error message visible">{errObj.price}</div>
                </div>
              </div>
              {/* a row */}
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  채팅 로그 단가(원) *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    value={logPrice}
                    name="logPrice"
                    required
                  />
                  <div className="col-lg-2 col-form-label update-pay-plan-label">
                    /GB
                  </div>
                  <div className="error message visible">{errObj.price}</div>
                </div>
              </div>
            </>
          );
          break;
        case 'db':
          header = 'DB 요금 수정';
          contents = (
            <>
              {/* a row */}
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  읽기 무료 제공 *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleDBChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    id="read"
                    name="limitCount"
                    value={read ? read.limitCount : 0}
                    required
                  />
                  <div className="col-lg-2 col-form-label update-pay-plan-label">
                    처리
                  </div>
                  <div className="error message visible">
                    {errObj.readLimitCount}
                  </div>
                </div>
              </div>
              {/* a row */}
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  읽기 단가(원) *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleDBChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    id="read"
                    name="price"
                    value={read ? read.price : 0}
                    required
                  />
                  <div className="col-lg-2 col-form-label update-pay-plan-label">
                    /처리
                  </div>
                  <div className="error message visible">
                    {errObj.readPrice}
                  </div>
                </div>
              </div>
              {/* a row */}
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  쓰기 무료 제공 *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleDBChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    id="write"
                    name="limitCount"
                    value={write ? write.limitCount : 0}
                    required
                  />
                  <div
                    htmlFor="changingRate"
                    className="col-lg-2 col-form-label update-pay-plan-label"
                  >
                    처리
                  </div>
                  <div className="error message visible">
                    {errObj.writeLimitCount}
                  </div>
                </div>
              </div>
              {/* a row */}
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  쓰기 단가(원) *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleDBChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    id="write"
                    name="price"
                    value={write ? write.price : 0}
                    required
                  />
                  <div
                    htmlFor="changingRate"
                    className="col-lg-2 col-form-label update-pay-plan-label"
                  >
                    /처리
                  </div>
                  <div className="error message visible">
                    {errObj.writePrice}
                  </div>
                </div>
              </div>
              {/* a row */}
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  스토리지 무료 제공 *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleDBChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    id="storage"
                    name="limitCount"
                    value={storage ? storage.limitCount : 0}
                    required
                  />
                  <div className="col-lg-2 col-form-label update-pay-plan-label">
                    GB
                  </div>
                  <div className="error message visible">
                    {errObj.storageLimitCount}
                  </div>
                </div>
              </div>
              {/* a row */}
              <div className="form-group m-form__group row">
                <label className="col-lg-2 col-form-label col-lg-2-indicators">
                  스토리지 단가(원) *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleDBChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    value={storage ? storage.price : 0}
                    id="storage"
                    name="price"
                    required
                  />
                  <div className="col-lg-2 col-form-label update-pay-plan-label">
                    /GB
                  </div>
                  <div className="error message visible">
                    {errObj.storagePrice}
                  </div>
                </div>
              </div>
              {/* a row */}
              <div className="form-group m-form__group row">
                <label
                  htmlFor="enterpriseRate"
                  className="col-lg-2 col-form-label col-lg-2-indicators"
                >
                  백업 무료 제공 *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleDBChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    value={backup ? backup.limitCount : 0}
                    id="backup"
                    name="limitCount"
                    required
                  />
                  <div
                    htmlFor="changingRate"
                    className="col-lg-2 col-form-label update-pay-plan-label"
                  >
                    GB
                  </div>
                  <div className="error message visible">
                    {errObj.backupLimitCount}
                  </div>
                </div>
              </div>
              {/* a row */}
              <div className="form-group m-form__group row">
                <label className="col-lg-2 col-form-label col-lg-2-indicators">
                  백업 단가(원) *
                </label>
                <div className="col-lg-9">
                  <input
                    onChange={handleDBChange}
                    type="number"
                    className="form-control update-pay-plan-input"
                    value={backup ? backup.price : 0}
                    id="backup"
                    name="price"
                    required
                  />
                  <div className="col-lg-2 col-form-label update-pay-plan-label">
                    /GB
                  </div>
                  <div className="error message visible">
                    {errObj.backupPrice}
                  </div>
                </div>
              </div>
            </>
          );
          break;

        default:
          break;
      }
      setContents(contents);
      setHeader(header);
    }
  }, [newIndicators]);

  return (
    <MsgModal
      size="lg"
      confirmOrCancel
      onConfirmAction={putIndicator}
      onHandleCloseMsgModal={() => setIsMsgModalOpen(false)}
      isMsgModalOpen={isMsgModalOpen}
      contentsObject
      content={contents}
      header={header}
    />
  );
};

export default EditModal;
