// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import ButtonsGroup from '../../fnComponents/buttonGroup';
import DataTable from '../../fnComponents/_DataTable';
import ButtonDropdown from '../../fnComponents/ButtonDropdown';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from './store/action';
import * as selectors from './store/selector';

const OverdueManageModal = ({
  isCouponDetailModalOpen,
  onHandleCloseCouponDetailModal,
  detailColumns,
  detailCouponList,
  couponTitle,
  couponId,
  onClickCouponDetail,
  isDetailLast,
}) => {
  // const [radioBtn, handleChange] = useState({});
  const [btnDropdown, setBtnDropdown] = useState({
    couponCode: '',
  });

  const handleInputChange = (e) => {
    // console.log(btnDropdown, "btnDropdown");
    const key = Object.keys(btnDropdown)[0];

    setBtnDropdown({
      [key]: e.target.value,
    });
  };

  const onChangeBtnDropdown = (value) => {
    setBtnDropdown({ [value]: undefined });
  };

  const dropdownItems = [
    // { name: "전체" },
    {
      name: '쿠폰코드',
      key: 'couponCode',
    },
    {
      name: '사용자',
      key: 'accountEmail',
    },
  ];

  return (
    <Modal size="lg" isOpen={isCouponDetailModalOpen}>
      <ModalHeader>{couponTitle}</ModalHeader>
      <ModalBody>
        {/* a row for INPUT */}
        <div className="form-group m-form__group row">
          <label
            for="expiration"
            className="for-selectBox col-lg-2 col-form-label"
          >
            <ButtonDropdown
              onChangeBtnDropdown={onChangeBtnDropdown}
              initItem="쿠폰코드"
              dropdownItems={dropdownItems}
              customKey="couponDetails"
            />
          </label>
          <div className="col-lg-8">
            <input
              onChange={handleInputChange}
              type="type"
              className="form-control"
              id="credit-name"
              required
            />
          </div>
          <Button
            color="primary"
            onClick={() => onClickCouponDetail(null, couponId, btnDropdown)}
          >
            검색
          </Button>
        </div>
        <ButtonsGroup
          columns={detailColumns}
          pdfData={detailCouponList}
          csvFileName={couponTitle}
          csvData={detailCouponList}
        />
        <DataTable
          isLast={isDetailLast}
          customClassName={
            detailCouponList && detailCouponList.length > 9
              ? 'coupon-details-table table-scrollable'
              : 'coupon-details-table'
          }
          columns={detailColumns}
          rows={detailCouponList}
          requestAction={() => onClickCouponDetail(null, couponId, null)}
        />
        
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onHandleCloseCouponDetailModal}>
          닫기
        </Button>{' '}
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  detailColumns: selectors.detailColumnsSelector,
  detailCouponList: selectors.couponDetailsSelectorForView,
  couponTitle: selectors.couponTitleSelector,
  couponId: selectors.couponIdSelector,
  isDetailLast: selectors.isDetailLastSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onHandleCloseMsgModal: () => dispatch(actions.handleCloseMsgModal()),
  // onHandleCloseMsgModal: () => dispatch(actions.handleCloseMsgModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(OverdueManageModal);
