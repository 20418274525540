// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import * as types from './constant';

export function moveToDeleteAccount(type) {
  return {
    type: types.MOVE_TO_DELETE_ACCOUNT,
    deleteAccount: type,
  };
}

export function openSubMenu(obj) {
  return {
    type: types.OPEN_SUB_MENU,
    obj,
  };
}

export function handleClickSubMenu(obj) {
  return {
    type: types.HANDLE_CLICK_SUBMENU,
    obj,
  };
}

export function moveToLandingMenu(obj) {
  return {
    type: types.MOVE_TO_LANDING_MENU,
    obj,
  };
}

export function moveToConsoleMenu(obj) {
  return {
    type: types.MOVE_TO_CONSOLE_MENU,
    obj,
  };
}

export function moveToDeveloperMenu() {
  return {
    type: types.MOVE_TO_DEVELOPER_MENU,
  };
}
