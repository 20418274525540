// inquiry
export const GET_INQUIRIES_LIST = "GET_INQUIRIES_LIST"
export const GET_INQUIRIES_LIST_SUCCESS = "GET_INQUIRIES_LIST_SUCCESS"
export const REQUEST_LIST_SUCCESS = "REQUEST_LIST_SUCCESS"

export const SEND_RESPONSE = "SEND_RESPONSE"
export const SEND_RESPONSE_SUCCESS = "SEND_RESPONSE_SUCCESS"

export const DELETE_INQUIRY = "DELETE_INQUIRY"
export const DELETE_INQUIRY_SUCCESS = "DELETE_INQUIRY_SUCCESS"
