// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import { createSelector } from 'reselect';

export const columnsSelector = createSelector(
  (state) => state.sales.columns,
  (columns) => columns
);

export const isLastSelector = createSelector(
  (state) => state.sales.isLast,
  (isLast) => isLast
);

export const projectListSelector = createSelector(
  // forView
  (state) => state.sales.projectList,
  (projectList) => {
    if (projectList)
      return projectList.map((pjt) => ({
        title: pjt.title,
        companyName: pjt.companyName,
        Revenue: (pjt.Revenue || '')
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        specificRevenue: {
          noDisplay: true,
          iOSRevenue: pjt.iOSRevenue,
          AndroidRevenue: pjt.AndroidRevenue,
          id: pjt.id,
        },
        DAU: (pjt.DAU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        WAU: (pjt.WAU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        NRU: (pjt.NRU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        RU: (pjt.RU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        Stickiness: pjt.Stickiness,
        PU: (pjt.PU || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        ARPDAU: (pjt.ARPDAU || '')
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        ARPPU: (pjt.ARPPU || '')
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        PR: (pjt.PR || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      }));
  }
);

export const _projectListSelector = createSelector(
  // actual data
  (state) => state.sales.projectList,
  (projectList) => {
    if (projectList)
      return projectList.map((pjt) => ({
        title: pjt.title,
        companyName: pjt.companyName,
        Revenue: pjt.Revenue,
        DAU: pjt.DAU,
        WAU: pjt.WAU,
        NRU: pjt.NRU,
        RU: pjt.RU,
        Stickiness: pjt.Stickiness,
        PU: pjt.PU,
        ARPDAU: pjt.ARPDAU,
        ARPPU: pjt.ARPPU,
        PR: pjt.PR,
      }));
  }
);

export const searchingKeywordsSelector = createSelector(
  (state) => state.sales.searchingKeywords,
  (searchingKeywords) => searchingKeywords
);

export const sortingParamSelector = createSelector(
  (state) => state.sales.sortingParam,
  (sortingParam) => sortingParam
);

export const isLoadingSelector = createSelector(
  (state) => state.sales.isLoading,
  (isLoading) => isLoading
);
