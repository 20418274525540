// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import { LOCATION_CHANGE } from 'react-router-redux';

import * as types from './constant';

const initialState = {
  inquiriesList: [],
  lastEvaluatedKey: null,
};

function inquiryGDC(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...initialState,
      };

    case types.GET_INQUIRIES_LIST_SUCCESS:
      let inquiriesList = null;

      if (action.isSearching && action.isNext) {
        inquiriesList = [...state.inquiriesList, ...action.data];
      }
      if (action.isSearching && !action.isNext) {
        inquiriesList = action.data;
      }
      if (!action.isSearching && action.isNext) {
        inquiriesList = [...state.inquiriesList, ...action.data];
      }
      if (!action.isSearching && !action.isNext) {
        inquiriesList = action.data;
      }
      return {
        ...state,
        // inquiriesList: action.isSearching && !action.isNext ? action.data : [...state.inquiriesList, ...action.data],
        inquiriesList,
        isSearching: action.isSearching,
        isNext: action.isNext,
      };
    case types.REQUEST_LIST_SUCCESS:
      return {
        ...state,
        lastEvaluatedKey: action.lastEvaluatedKey,
      };

    case types.DELETE_INQUIRY:
      return {
        ...state,
        inquiriesList: state.inquiriesList.filter(
          (inquiry, i) => inquiry.inDate !== action.inDate
        ),
      };

    default:
      return state;
  }
}

export default inquiryGDC;
