// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Octicon, { Search, X } from '@primer/octicons-react';
import moment from 'moment';

import Header from '../../../view/header';
import LeftAsideMunu from '../../../view/leftAsideMenu';
import ButtonsGroup from '../../../fnComponents/buttonGroup';
import DataTable from '../../../fnComponents/_DataTable';
import DateRangePicker from '../../../fnComponents/DateRangePicker';
import MsgModal from '../../../fnComponents/MsgModal';

import * as actions from './store/action';
import * as selectors from './store/selector';

const Sleeping = ({
  columns,
  sortingParam,
  sleepingCandidatesListForView,
  sleepingCandidatesList,
  isSleepingAccounts,
  isLast,
  //
  onMakeSleepingCandidateNot,
  onSortAscSleepingCandidates,
  onSortDescSleepingCandidates,
  onGetSearchKeywords,
  onGetSleepingCandidates,
  onGetSleepingAccounts,
  onMakeSleepingAccountNot,
  onSetSearching,
  onInitSleepingAccounts,
  onInitSleepingCandidates,
}) => {
  const initInputObj = {
    id: '',
    company: '',
    signUpDate: '',
    lastLoginDate: '',
    status: '',
    init: false,
  };

  const [startDate1, setStartDate1] = useState(null);
  const [endDate1, setEndDate1] = useState(null);
  const [startDate2, setStartDate2] = useState(null);
  const [endDate2, setEndDate2] = useState(null);
  const [focusedInput1, setFocusedInput1] = useState('');
  const [focusedInput2, setFocusedInput2] = useState('');

  const [focusByIcon, setFocus] = useState(null);
  const [inputObj] = useState(initInputObj);

  const [isMsgModalOpen, setMsgModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(false);

  const handleMakeSleepingCandidateNotModal = (key, arr) => {
    // console.log("handleMakeSleepingCandidateNotModal", key, arr);
    setMsgModalOpen(true);
    setSelectedId(arr[0].id.id);
  };

  const handleCloseMsgModal = () => {
    setMsgModalOpen(false);
  };

  const handleMakeSleepingCandidateNot = () => {
    if (isSleepingAccounts) onMakeSleepingAccountNot(selectedId);
    else onMakeSleepingCandidateNot(selectedId);
    handleCloseMsgModal();
  };

  const handleTimes1 = (inDate) => {
    // console.log("1", inDate);
    const { startDate, endDate } = inDate;

    if (startDate && endDate)
      onGetSearchKeywords({
        joinStartDate: startDate.toISOString(),
        joinEndDate: endDate.toISOString(),
      });
  };
  const handleTimes2 = (sleeperDue) => {
    // console.log("2", sleeperDue);

    const { startDate, endDate } = sleeperDue;
    if (startDate && endDate)
      onGetSearchKeywords({
        sleeperDueStartDate: startDate.toISOString(),
        sleeperDueEndDate: endDate.toISOString(),
      });
  };

  const [isSearching, setSearching] = useState(false);

  const handleSearchAccounts = (e) => {
    // e.preventDefault();
    setSearching(true);
    onSetSearching();
    if (!isSleepingAccounts) onGetSleepingCandidates(null, true);
    else onGetSleepingAccounts(null, true);
  };

  const handleKeyDown = async (e) => {
    if (e.keyCode === 13) {
      await e.preventDefault();
      await handleSearchAccounts();
    }
  };

  const handleShowMore = () => {
    if (isSleepingAccounts) {
      onGetSleepingAccounts(null, isSearching, true);
    } else {
      onGetSleepingCandidates(null, isSearching, true);
    }
  };

  const handleInitThisPage = () => {
    window.location.reload();
    // if(isSleepingAccounts) {
    // 	onInitSleepingAccounts()
    // } else {
    // 	onInitSleepingCandidates()
    // }
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <MsgModal
        isMsgModalOpen={isMsgModalOpen}
        onHandleCloseMsgModal={handleCloseMsgModal}
        onConfirmAction={handleMakeSleepingCandidateNot}
        confirmOrCancel
        // contentsObject
        header={isSleepingAccounts ? '휴면 해제' : '휴면 예정 해제'}
        content={
          isSleepingAccounts
            ? '휴면 상태를 해제하시겠습니까?'
            : '휴면 전환 예정을 해제하시겠습니까?'
        }
        size="lg"
      ></MsgModal>
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <button
          className="m-aside-left-close m-aside-left-close--skin-dark"
          id="m_aside_left_close_btn"
        >
          <i className="la la-close" />
        </button>
        <LeftAsideMunu />

        <div className="m-grid__item m-grid__item--fluid m-wrapper">
          <div className="m-subheader">
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="m-subheader__title ">
                  {isSleepingAccounts ? '휴면 계정' : '휴면 예정 계정'}
                </h3>
              </div>
            </div>
          </div>
          <div className="m-content">
            <div className="row">
              <div className="col-md-12">
                <div className="m-portlet">
                  <div className="m-portlet__body">
                    <form className="m-form m-form--fit m--margin-bottom-20">
                      <div className="row m--margin-bottom-20">
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>아이디</label>
                          <input
                            onKeyDown={handleKeyDown}
                            onChange={(e) =>
                              onGetSearchKeywords({ email: e.target.value })
                            }
                            type="text"
                            className="form-control m-input"
                            placeholder=""
                            data-col-index="1"
                          />
                        </div>
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>회사명</label>
                          <input
                            onKeyDown={handleKeyDown}
                            onChange={(e) =>
                              onGetSearchKeywords({
                                companyName: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control m-input"
                            placeholder=""
                            data-col-index="1"
                          />
                        </div>
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>가입일</label>
                          <div
                            className="input-group pull-right"
                            id="m_daterangepicker1"
                          >
                            <DateRangePicker
                              displayFormat={'YYYY.MM.DD'}
                              startDate={startDate1}
                              endDate={endDate1}
                              focusedInput={focusedInput1}
                              setFocusedInput={setFocusedInput1}
                              setStartDate={setStartDate1}
                              setEndDate={setEndDate1}
                              initInputObj={initInputObj}
                              initSearchFilter={inputObj.init}
                              searchParam="signUpDate"
                              onGetSearchItem={handleTimes1}
                              setFocus={setFocus}
                              focusByIcon={
                                focusByIcon && focusByIcon.singleDatePicker1
                              }
                              // className="form-control m-input"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 m--margin-bottom-10-tablet-and-mobile">
                          <label>
                            {isSleepingAccounts ? '휴면전환일' : '휴면예정일'}
                          </label>
                          <div
                            className="input-group pull-right"
                            id="m_daterangepicker1"
                          >
                            <DateRangePicker
                              displayFormat={'YYYY.MM.DD'}
                              startDate={startDate2}
                              endDate={endDate2}
                              focusedInput={focusedInput2}
                              setFocusedInput={setFocusedInput2}
                              setStartDate={setStartDate2}
                              setEndDate={setEndDate2}
                              initInputObj={initInputObj}
                              initSearchFilter={inputObj.init}
                              searchParam="signUpDate"
                              onGetSearchItem={handleTimes2}
                              setFocus={setFocus}
                              focusByIcon={
                                focusByIcon && focusByIcon.singleDatePicker1
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleSearchAccounts();
                            }}
                            className="btn btn-brand m-btn m-btn--icon"
                            id="m_search"
                          >
                            <span>
                              <Octicon icon={Search} />
                              <span>검색</span>
                            </span>
                          </button>
                          &nbsp;&nbsp;
                          <button
                            onClick={handleInitThisPage}
                            className="btn btn-secondary m-btn m-btn--icon"
                            id="m_reset"
                          >
                            <span>
                              <Octicon icon={X} />
                              <span>초기화</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="row row-btn-group">
                      <ButtonsGroup
                        // csvData={projectList}
                        csvFileName={`project-list ${moment().format(
                          'YYYY-MM-DD HH:mm'
                        )}`}
                        customClassName="btn-group-in-row"
                      />
                    </div>
                    <DataTable
                      isLast={isLast}
                      sortingColumns={[
                        '가입일',
                        '최종접속일',
                        isSleepingAccounts ? '휴면전환일' : '휴면예정일',
                      ]}
                      requestAction={handleShowMore}
                      onSortAsc={onSortAscSleepingCandidates}
                      onSortDesc={onSortDescSleepingCandidates}
                      sortingParam={sortingParam}
                      secondRequiredAction={handleMakeSleepingCandidateNotModal}
                      rows={sleepingCandidatesListForView}
                      actualData={sleepingCandidatesList}
                      columns={columns}
                      customClassName={
                        // "column-6 sleeping-candidates-table"
                        sleepingCandidatesListForView.length > 13
                          ? 'column-6 sleeping-candidates-table table-scrollable'
                          : 'column-6 sleeping-candidates-table'
                      }
                      clickableIndex={[6]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  columns: selectors.columnsSelector,
  sortingParam: selectors.sortingParamSelector,
  sleepingCandidatesListForView:
    selectors.sleepingCandidatesListSelectorForView,
  sleepingCandidatesList: selectors.sleepingCandidatesListSelector,
  isSleepingAccounts: selectors.isSleepingAccountsSelector,
  isLast: selectors.isLastSelector,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSleepingCandidates: (param, isSearching, isPaging) =>
    dispatch(actions.getSleepingCandidates(param, isSearching, isPaging)),
  onGetSleepingAccounts: (param, isSearching, isPaging) =>
    dispatch(actions.getSleepingAccounts(param, isSearching, isPaging)),
  onMakeSleepingCandidateNot: (id, arr) =>
    dispatch(actions.makeSleepingCandidateNot(id, arr)),
  onMakeSleepingAccountNot: (id, arr) =>
    dispatch(actions.makeSleepingAccountNot(id, arr)),
  onSortAscSleepingCandidates: (rows, isSearched, tableType) =>
    dispatch(actions.sortAscSleepingCandidates(rows, isSearched, tableType)),
  onSortDescSleepingCandidates: (rows, isSearched, tableType) =>
    dispatch(actions.sortDescSleepingCandidates(rows, isSearched, tableType)),
  onGetSearchKeywords: (obj) => dispatch(actions.getSearchKeywords(obj)),
  onSetSearching: () => dispatch(actions.setSearching()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sleeping);
