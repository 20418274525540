export const HANDLE_CLOSE_CHARGE_MODAL = "HANDLE_CLOSE_CHARGE_MODAL";
export const CONFIRM_CHANGE_STATUS = "CONFIRM_CHANGE_STATUS";

export const GET_ACCOUNTS_ROWS = "GET_ACCOUNTS_ROWS";
export const GET_ACCOUNTS_ROWS_SUCCESS = "GET_ACCOUNTS_ROWS_SUCCESS";
export const INIT_ACCOUNT_LIST = "INIT_ACCOUNT_LIST";
export const INIT_DELETED_ACCOUNT_LIST = "INIT_DELETED_ACCOUNT_LIST";
export const GET_COLUMNS = "GET_COLUMNS";
//
export const CLICK_CURRENT_CHARGE_ROW = "CLICK_CURRENT_CHARGE_ROW";
export const GET_BACK_TO_MAIN_TABLE = "GET_BACK_TO_MAIN_TABLE";
export const HANDLE_OPEN_LAYERD_MODAL = "HANDLE_OPEN_LAYERD_MODAL";
export const HANDLE_CLOSE_LAYERD_MODAL = "HANDLE_CLOSE_LAYERD_MODAL";
export const GET_SEARCH_KEYWORDS_CURRENT = "GET_SEARCH_KEYWORDS_CURRENT";
export const GET_CURRENT_BILL = "GET_CURRENT_BILL";
export const GET_CURRENT_BILL_SUCCESS = "GET_CURRENT_BILL_SUCCESS";
export const GET_CURRENT_GAME_BILL = "GET_CURRENT_GAME_BILL";
export const GET_CURRENT_GAME_BILL_SUCCESS = "GET_CURRENT_GAME_BILL_SUCCESS";
export const GET_CURRENT_ACCOUNT_BILL = "GET_CURRENT_ACCOUNT_BILL";
export const GET_CURRENT_ACCOUNT_BILL_SUCCESS =
  "GET_CURRENT_ACCOUNT_BILL_SUCCESS";

export const INIT_CURRENT_CHARGE_LIST = "INIT_CURRENT_CHARGE_LIST";
export const GET_ACCOUNT_STATUS_SUCCESS = "GET_ACCOUNT_STATUS_SUCCESS";
export const SORT_ASC_CURRENT_ACCOUNT_LIST = "SORT_ASC_CURRENT_ACCOUNT_LIST";
export const SORT_DESC_CURRENT_ACCOUNT_LIST = "SORT_DESC_CURRENT_ACCOUNT_LIST";
export const ADD_EXTRA_BILL = "ADD_EXTRA_BILL";
export const UPDATE_EXTRA_BILL = "UPDATE_EXTRA_BILL";
export const DELETE_EXTRA_BILL = "DELETE_EXTRA_BILL";
export const ADD_EXTRA_BILL_SUCCESS = "ADD_EXTRA_BILL_SUCCESS";
export const UPDATE_EXTRA_BILL_SUCCESS = "UPDATE_EXTRA_BILL_SUCCESS";
export const DELETE_EXTRA_BILL_SUCCESS = "DELETE_EXTRA_BILL_SUCCESS";

//
export const HANDLE_OPEN_MODAL = "HANDLE_OPEN_MODAL";
export const CONFIRM_DELETE_ACCOUNT = "CONFIRM_DELETE_ACCOUNT";
export const SAVE_INPUT = "SAVE_INPUT";
