// Copyright 2013-2022 AFI, Inc. All Rights Reserved.

export const HANDLE_CLOSE_CHARGE_MODAL = 'HANDLE_CLOSE_CHARGE_MODAL';
export const CONFIRM_CHANGE_STATUS = 'CONFIRM_CHANGE_STATUS';

export const GET_ACCOUNTS_ROWS = 'GET_ACCOUNTS_ROWS';
export const GET_ACCOUNTS_ROWS_SUCCESS = 'GET_ACCOUNTS_ROWS_SUCCESS';
export const INIT_ACCOUNT_LIST = 'INIT_ACCOUNT_LIST';
export const INIT_DELETED_ACCOUNT_LIST = 'INIT_DELETED_ACCOUNT_LIST';
export const GET_COLUMNS = 'GET_COLUMNS';
//
export const CLICK_CURRENT_CHARGE_PAYPERUSE_ROW =
  'CLICK_CURRENT_CHARGE_PAYPERUSE_ROW';
export const GET_BACK_TO_PAYPERUSE_MAIN_TABLE =
  'GET_BACK_TO_PAYPERUSE_MAIN_TABLE';
export const HANDLE_OPEN_PAYPERUSE_LAYERD_MODAL =
  'HANDLE_OPEN_PAYPERUSE_LAYERD_MODAL';
export const HANDLE_CLOSE_PAYPERUSE_LAYERD_MODAL =
  'HANDLE_CLOSE_PAYPERUSE_LAYERD_MODAL';
export const GET_SEARCH_KEYWORDS_CURRENT_PAYPERUSE =
  'GET_SEARCH_KEYWORDS_CURRENT_PAYPERUSE';
export const GET_CURRENT_PAYPERUSE_BILL = 'GET_CURRENT_PAYPERUSE_BILL';
export const GET_CURRENT_PAYPERUSE_BILL_SUCCESS =
  'GET_CURRENT_PAYPERUSE_BILL_SUCCESS';
export const GET_CURRENT_GAME_BILL_PAYPERUSE =
  'GET_CURRENT_GAME_BILL_PAYPERUSE';
export const GET_CURRENT_GAME_BILL_PAYPERUSE_SUCCESS =
  'GET_CURRENT_GAME_BILL_PAYPERUSE_SUCCESS';
export const GET_CURRENT_ACCOUNT_BILL_PAYPERUSE =
  'GET_CURRENT_ACCOUNT_BILL_PAYPERUSE';
export const GET_CURRENT_ACCOUNT_BILL_PAYPERUSE_SUCCESS =
  'GET_CURRENT_ACCOUNT_BILL_PAYPERUSE_SUCCESS';

export const INIT_CURRENT_CHARGE_PAYPERUSE_LIST =
  'INIT_CURRENT_CHARGE_PAYPERUSE_LIST';
export const GET_ACCOUNT_STATUS_PAYPERUSE_SUCCESS =
  'GET_ACCOUNT_STATUS_PAYPERUSE_SUCCESS';
export const SORT_ASC_CURRENT_PAYPERUSE_ACCOUNT_LIST =
  'SORT_ASC_CURRENT_PAYPERUSE_ACCOUNT_LIST';
export const SORT_DESC_CURRENT_PAYPERUSE_ACCOUNT_LIST =
  'SORT_DESC_CURRENT_PAYPERUSE_ACCOUNT_LIST';
export const ADD_EXTRA_BILL_PAYPERUSE = 'ADD_EXTRA_BILL_PAYPERUSE';
export const UPDATE_EXTRA_BILL_PAYPERUSE = 'UPDATE_EXTRA_BILL_PAYPERUSE';
export const DELETE_EXTRA_BILL_PAYPERUSE = 'DELETE_EXTRA_BILL_PAYPERUSE';
export const ADD_EXTRA_BILL_PAYPERUSE_SUCCESS =
  'ADD_EXTRA_BILL_PAYPERUSE_SUCCESS';
export const UPDATE_EXTRA_BILL_PAYPERUSE_SUCCESS =
  'UPDATE_EXTRA_BILL_PAYPERUSE_SUCCESS';
export const DELETE_EXTRA_BILL_PAYPERUSE_SUCCESS =
  'DELETE_EXTRA_BILL_PAYPERUSE_SUCCESS';

//
export const HANDLE_OPEN_MODAL = 'HANDLE_OPEN_MODAL';
export const CONFIRM_DELETE_ACCOUNT = 'CONFIRM_DELETE_ACCOUNT';
export const SAVE_INPUT = 'SAVE_INPUT';
