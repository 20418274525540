import { put, takeLatest, call, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import { delay } from 'redux-saga';
import * as types from './constant';

import * as actions from './action';
import * as selectors from './selector';

import * as api from '../../../service/API';
import { openSubMenu, handleClickSubMenu } from '../../../view/store/action';

function* locationChange({ payload }) {
	try {
		const { pathname } = yield payload;
		const pathArray = pathname.split('/');

		if (pathArray[1] === 'emailList') {
			yield put(openSubMenu('email'));
			yield put(handleClickSubMenu('emailList'));

			const { data } = yield call(api.emailList.getSentMails, {
				isAdmin: true,
			});
			yield put(actions.getSentMailsSuccess(data));
		}
	} catch (err) {
		console.log('saga err', err);
	}
}

export function* watcherLocationChange() {
	yield takeLatest(LOCATION_CHANGE, locationChange);
}

function* getSentMails({ lastEvaluatedKey, isAdmin, toRefresh }) {
	try {
		const lastEvaluatedKeyObj = yield select(
			selectors._lastEvaluatedKeySelector
		);

		let _key = '';

		Object.keys(lastEvaluatedKeyObj).forEach((key) => {
			if (lastEvaluatedKey) {
				if (lastEvaluatedKeyObj[key]) {
					const { inDate } = lastEvaluatedKeyObj[key];
					if (inDate === lastEvaluatedKey.inDate) {
						console.log('found index', key);
						_key = Number(key) + 1;
						return;
					}
				}
				if (!lastEvaluatedKeyObj[key]) {
					console.log('it is null', key);
				}
			} else {
				console.log('it is in the first', key);
			}
		});

		const { data } = yield call(api.emailList.getSentMails, {
			lastEvaluatedKey,
			isAdmin,
		});
		yield put(actions.getSentMailsSuccess(data, _key || 1));
	} catch (error) {
		console.error(error);
	}
}

export function* watcherGetSentMails() {
	yield takeLatest(types.GET_SENT_MAILS, getSentMails);
}

function* getSentMailDetails({ inDate, query, paging }) {
	try {
		// const lastEvaluatedKey = yield select(selectors.)
		const { data } = yield call(
			api.emailList.getSentMailDetails,
			inDate,
			query
		);
		yield put(actions.getSentMailDetailsSuccess(data, paging));
	} catch (error) {
		console.error(error);
	}
}

export function* watcherGetSentMailDetails() {
	yield takeLatest(types.GET_SENT_MAIL_DETAILS, getSentMailDetails);
}

function* searchSentMailDetails({ inDate, query, paging }) {
	try {
		const { data } = yield call(
			api.emailList.getSentMailDetails,
			inDate,
			query
		);
		yield put(actions.searchSentMailDetailsSuccess(data, paging));
	} catch (error) {
		console.error(error);
	}
}

export function* watcherSearchSentMailDetails() {
	yield takeLatest(types.SEARCH_SENT_MAIL_DETAILS, searchSentMailDetails);
}

function* deleteSentMail({ inDate }) {
	try {
		const entireList = yield select(selectors._emailListSelector);
		const isAdmin = yield select(selectors.isAdminSelector);
		const lastEvaluatedKeyObject = yield select(
			selectors._lastEvaluatedKeySelector
		);

		let index = '';
		Object.keys(entireList).forEach((objKey) => {
			const found = entireList[objKey].find((item) => item.inDate.S === inDate);
			if (found) {
				index = objKey;
			}
		});

		console.log('found index', index);
		const lastEvaluatedKey = lastEvaluatedKeyObject[index - 1];

		yield call(api.emailList.deleteSentMail, { isAdmin }, inDate);
		yield put(actions.deleteSentMailSuccess());
		yield call(delay, 500);
		yield put(actions.getSentMails(lastEvaluatedKey, isAdmin, 'toRefresh'));
	} catch (error) {
		console.error(error);
	}
}

export function* watcherDeleteSentMail() {
	yield takeLatest(types.DELETE_SENT_MAIL, deleteSentMail);
}

function* handleIsAdmin({ payload }) {
	try {
		const { data } = yield call(api.emailList.getSentMails, {
			isAdmin: payload,
		});
		yield put(actions.handleIsAdminSuccess(data));
	} catch (error) {
		console.error('HANDLE_IS_ADMIN', error);
	}
}

export function* watcherHandleIsAdmin() {
	yield takeLatest(types.HANDLE_IS_ADMIN, handleIsAdmin);
}
